import React, {VFC} from 'react';
import styled from 'styled-components';
import CloseButtonIcon from '../../../../svgs/CloseButtonIcon';

export interface HCFilterBaseProps {
  title: string;
  children?: React.ReactNode;
  onToggle?: (id: string) => void;
  onClose?: () => void;
  selectedIds: string[];
}

export const Container = styled.div`
  width: 300px;
  border-radius: 8px;
  background: white;
  box-shadow: 0 18px 28px 0 #091e4226;
  border: 1px solid #d8d8d8;
  position: absolute;
`;

export const PopupTopContainer = styled.div`
  border-bottom: 1px solid #d8d8d8;
  padding: 8px;
  height: 40px;
  display: flex;
  justify-content: space-between;
`;

export const TitleContainer = styled.div`
  font-weight: 600;
  display: flex;
  align-items: center;
  font-family: 'Nunito Sans', serif;
  font-size: 17px;
  line-height: 24px;
`;

export const CloseContainer = styled.div`
  cursor: pointer;
`;

export const FooterContainer = styled.div`
  border-top: 1px solid #d8d8d8;
  padding: 8px 16px 8px 8px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 48px;
`;

export const OutlineLink = styled.a`
  color: #00859a;
  font-family: 'Nunito Sans', serif;
  user-select: none;
  height: 40px;
  display: flex;
  align-content: center;
  font-size: 17px;
  line-height: 24px;
`;

export const HCFilterBase: VFC<HCFilterBaseProps> = ({title, onClose, children}) => {
  return (
    <Container>
      <PopupTopContainer>
        <TitleContainer>{title}</TitleContainer>
        <CloseContainer onClick={onClose}>
          <CloseButtonIcon />
        </CloseContainer>
      </PopupTopContainer>
      {children}
    </Container>
  );
};
