import {OrganizationLoginMethods} from './Organization';
import {AuthPayloadDTO} from './Auth';

type UserAccountStatus = 'active' | 'shell' | 'inactive';

export enum CurrentLoginStep {
  STEP_1 = 1,
  STEP_2,
  STEP_3,
  STEP_4,
  STEP_5,
  STEP_6,
}

export enum WebViewCallBacks {
  ACCOUNTS_FETCHED = 'accountsFetched',
  SWITCH_REGION = 'switchRegion',
  REPORT_ERROR = 'reportError',
  DISMISS_WEB_VIEW = 'dismissWebView',
}

export enum OrgLoginMethods {
  PASSWORD = 'password',
  SSO = 'sso',
  OTP = 'otp',
}

export enum OrgViews {
  EMAIL = 'email',
  FIND_MY_ORGANIZATION = 'findMyOrganization',
}

export enum AccountStatus {
  ACTIVE = 'active',
  SHELL = 'shell',
  INACTIVE = 'inactive',
}
export interface BasicUser {
  id: string;
  firstName: string;
  lastName: string;
  avatar: {
    url: string;
  };
  accountStatus: UserAccountStatus;
  loginScopes: OrganizationLoginMethods[];
  ssoProfiles?: {
    organization: {
      name: string;
      url: string;
    };
    auth0Id: string;
    ssoId: string;
    ssoVendor: string;
    domain: string;
  }[];
  __typename: string;
}

export interface ValidateAddressVerificationResponse {
  response: {
    challengeId: string;
    addressType: string;
    status: string;
    address: string;
  };
}

export interface ExchangeOTPTokenForPartialAccessTokenResponse {
  access_token: string;
  token_type: string;
  expires_at: string;
  scope: string[];
  identity: {
    __typename: string;
    email: string;
  };
}

export interface TokenExchangeGrantRequestResponse {
  access_token: string;
  expires_at: string;
  identity: {
    __typename: string;
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    eulaStatus: boolean;
    workStatus: string;
  };
  issued_token_type: string;
  refresh_token: string;
  scope: string[];
  token_type: string;
  error?: string;
  error_description?: string;
}

export interface OrganizationAccountsCacheData {
  selectedOrganizationUrl: string;
  savedOrganizations: AuthPayloadDTO[];
}
