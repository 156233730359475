import Checkbox from '@material-ui/core/Checkbox';
import React from 'react';
import RoleNoteDetailPopper from 'src/pages/LocatingPage/locating/profile-section/notes-section/RoleNoteDetailPopper';
import NoteIcon from 'src/svgs/NoteIcon';
import styled from 'styled-components';
import store, {typedUseSelector} from 'src/redux';
import {actions} from 'src/redux/actions/scheduling';
import {Role} from 'src/types';
import {usePopper} from 'react-popper';

const RoleNoteWrapper = styled.div`
  display: flex;
  gap: 6px;
  justify-content: center;
  align-items: center;
`;
const RoleNoteIconWrapper = styled.span`
  width: 20px;
  height: 20px;
`;

const RoleNoteNameWrapper = styled.div`
  word-break: break-word;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const PreferredRolesListItemWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-left: -11px;
  margin-right: 16px;
`;

const triangleBoxBase = 1;
const triangleBoxSides = 0.8;
const triangleBase = 0.5;
export const FilterPreferredRolesListItemPopperContainer = styled.div`
  z-index: 1000;
  display: flex;
  padding: 16px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  margin-left: 8px;
  justify-content: center;
  .arrow {
    left: 0;
    margin-left: -${triangleBoxSides}em;
    height: ${triangleBoxBase}em;
    width: ${triangleBoxSides}em;
    &::before {
      border-width: ${triangleBase}em ${triangleBoxSides}em ${triangleBase}em 0;
      border-color: transparent white transparent transparent;
    }
  }
`;

export const FilterPreferredRolesListItemPopperArrow = styled.div`
  position: absolute;
  width: ${triangleBoxBase}em;
  height: ${triangleBoxSides}em;
  &::before {
    content: '';
    margin: auto;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
  }
`;

const FilterPreferredRolesListItem = ({isChecked, handleChange, role}) => {
  const [isRoleNoteIconHovered, setIsRoleNoteHovered] = React.useState(false);
  const [referenceElement, setReferenceElement] = React.useState(null);
  const [popperElement, setPopperElement] = React.useState(null);
  const [arrowElement, setArrowElement] = React.useState(null);
  const {styles, attributes} = usePopper(referenceElement, popperElement, {
    modifiers: [
      {
        name: 'arrow',
        options: {
          element: arrowElement,
        },
      },
    ],
    placement: 'right',
  });

  function handleClick(role: Role) {
    const {roleId, roleName} = role;

    roleNotes[role?.roleId]?.notes.length && store.dispatch(actions.setCurrentSelectedRole({roleId, roleName}));
  }

  const roleNotes = typedUseSelector((state) => state.scheduling.roleNotes);

  return (
    <PreferredRolesListItemWrapper>
      <Checkbox color="primary" checked={isChecked(role)} onChange={() => handleChange(role)} name={role.roleName} />
      <RoleNoteWrapper
        onMouseEnter={() => {
          setIsRoleNoteHovered(true);
          handleClick(role);
        }}
        onMouseLeave={() => setIsRoleNoteHovered(false)}
        ref={setReferenceElement}
      >
        <RoleNoteNameWrapper>{role.roleName} </RoleNoteNameWrapper>
        {roleNotes[role?.roleId]?.notes.length ? (
          <>
            <RoleNoteIconWrapper>
              <NoteIcon />
            </RoleNoteIconWrapper>
            {isRoleNoteIconHovered && (
              <FilterPreferredRolesListItemPopperContainer
                ref={setPopperElement}
                style={styles.popper}
                {...attributes.popper}
              >
                <RoleNoteDetailPopper />
                <FilterPreferredRolesListItemPopperArrow ref={setArrowElement} className="arrow" />
              </FilterPreferredRolesListItemPopperContainer>
            )}
          </>
        ) : null}
      </RoleNoteWrapper>
    </PreferredRolesListItemWrapper>
  );
};
export default FilterPreferredRolesListItem;
