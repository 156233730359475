import React, {useEffect} from 'react';
import {toast} from 'react-toastify';
import {Login} from 'src/types';

interface IAutoLoginProps {
  username: string;
  password: string;
  login: Login;
}

export const AutoLogin: React.FC<IAutoLoginProps> = ({username, password, login}) => {
  useEffect(() => {
    (async () => {
      try {
        const loginResult = await login(username, password);
        if (loginResult.success) {
          toast.success(`Account successfully created`);
        } else {
          throw {errScope: 'login'};
        }
      } catch (error) {
        console.error(error);
        if (error.errScope === 'login') {
          toast.warn('Account successfully verified but failed to login, please try to login again', {
            autoClose: false,
          });
          window.routerHistory.push(`/login`);
          return;
        }
      }
    })();
    // eslint-disable-next-line
  }, []);
  return null;
};
