import React from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import {StyledModal, CloseBtn, Header} from 'src/styles/styled-components/ModalStyleComponents';
import CircularProgress from '@material-ui/core/CircularProgress';
import {toast} from 'react-toastify';
import {ButtonsWrapper, ModalBodyWrapper} from 'src/styles/styled-components/SharedModalStyles';
import client from 'src/apollo';
import ActivateIndividualEscalationMutation from 'src/gql/mutation/ActivateIndividualEscalationMutation';
import {ActiveEscalation, User} from 'src/types';
import updateActiveEscalationsQueryList from 'src/utils/updateActiveEscalationsQueryList';
import sleep from 'src/utils/sleep';
import GetChatQuery from 'src/gql/query/GetChatQuery';

const ReasonText = styled.p`
  line-height: 1.5em;
  margin-bottom: 2em;
`;

const StyledHeader = styled(Header)`
  font-size: 24px;
`;

const StyledButtonsWrapper = styled(ButtonsWrapper)`
  button {
    width: 100px !important;
    text-transform: capitalize;
  }
`;

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  closeAll: () => void;
  chatTitle: string;
  messageId: string;
  chatId: string;
  targetUser: User;
}

interface PersonalEscalationResult {
  escalation: {
    activatePersonalEscalation: ActiveEscalation;
  };
}

const SwapDoubleConfirmationModal = ({
  isOpen,
  closeAll,
  closeModal,
  chatTitle,
  messageId,
  chatId,
  targetUser,
}: Props) => {
  const [isLoading, setLoading] = React.useState(false);

  const handleSendRequest = async () => {
    try {
      setLoading(true);

      const result = await client.mutate<PersonalEscalationResult>({
        mutation: ActivateIndividualEscalationMutation,
        variables: {
          userId: targetUser.id,
          activatedChatId: chatId,
          messageId: messageId,
        },
      });
      const resultChatId = result.data.escalation.activatePersonalEscalation.chat.id;
      const resultEscalationID = result.data.escalation.activatePersonalEscalation.id;

      updateActiveEscalationsQueryList(resultEscalationID);

      closeAll();

      window.routerHistory.push(`/messenger/${resultChatId}`);

      // TODO: remove this when backend get the socket working
      await sleep(5000);

      client.query({
        query: GetChatQuery,
        variables: {chatId: resultChatId},
        fetchPolicy: 'network-only',
      });
    } catch (e) {
      console.error(e);
      toast.error('Failed to start individual escalation, please check your internet connection and try again');
      setLoading(false);
    }
  };

  return (
    <StyledModal
      isOpen={isOpen}
      ariaHideApp={false}
      shouldCloseOnEsc={!isLoading}
      shouldCloseOnOverlayClick={!isLoading}
      onRequestClose={closeModal}
    >
      <StyledHeader>
        <span>Confirm individual escalation?</span>
        {!isLoading && <CloseBtn onClick={closeModal} />}
      </StyledHeader>
      <ModalBodyWrapper>
        <ReasonText>
          You are about to start an individual escalation with {chatTitle}. The action cannot be undone once the message
          is sent.
        </ReasonText>

        <StyledButtonsWrapper>
          <Button variant="contained" disabled={isLoading} disableTouchRipple onClick={closeModal}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleSendRequest}
            disabled={isLoading}
            color="secondary"
            disableTouchRipple
          >
            {isLoading ? <CircularProgress size={20} /> : <span>Confirm</span>}
          </Button>
        </StyledButtonsWrapper>
      </ModalBodyWrapper>
    </StyledModal>
  );
};

export default SwapDoubleConfirmationModal;
