import React, {useMemo, useState, VFC} from 'react';
import {SelectableFilterItem, FilterOptionsContainer, HCFilterOption, HCFilterSimpleProps} from './HCFilterSimple';
import styled from 'styled-components';
import {StyledInputBase} from 'src/components/StepFormPartials';
import {HCFilterClearableBase} from '../base/HCFilterClearableBase';

interface HCFilterSearchableProps extends HCFilterSimpleProps {
  onSearch: (query: string, option: HCFilterOption) => boolean;
  idToBucket?: Map<string, string>;
}

export const StyledInput = styled(StyledInputBase)`
  width: 100%;
  padding: 5px 5px 15px 5px;
  input {
    padding-left: 10px;
    padding-right: calc(1em + 80px);
    :placeholder-shown {
      padding-right: 2em;
    }
  }
`;

const TitleSection = styled.div`
  color: #4a4a4a;
`;

export const HCFilterSearchable: VFC<HCFilterSearchableProps> = (props) => {
  const [query, setQuery] = useState('');
  const selectedIds = new Set(props.selectedIds);
  const filteredData = useMemo(() => {
    return props.options.map((option) => ({
      ...option,
      options: option.options.filter((row) => props.onSearch(query, row)),
    }));
  }, [query, props.options, props.onSearch]);

  const renderData = (rows, noRowsString = 'No results found') =>
    rows.length > 0 ? (
      rows.map((row) => (
        <SelectableFilterItem
          onToggle={props.onToggle}
          option={row}
          optionRender={props.optionRender}
          isSelected={selectedIds.has(row.id)}
        />
      ))
    ) : (
      <div>{noRowsString}</div>
    );

  const isSecondSection = (data, index) => data.length > 1 && index > 0;

  const dropdownResults = () => {
    if (query.length === 0) {
      if (selectedIds.size <= 0) {
        return <div>Search to see results</div>;
      }

      return (
        <div>
          <div style={{paddingBottom: 15}}>Selected ({selectedIds.size})</div>
          {filteredData.map((option, index) => {
            const rows = option.options.filter((option) => selectedIds.has(option.id));
            if (rows.length === 0) {
              return null;
            }
            return (
              <div style={{marginTop: isSecondSection(filteredData, index) ? 10 : 0}}>
                {filteredData.length > 1 && <TitleSection>{option.title}</TitleSection>}
                {renderData(rows, '')}
              </div>
            );
          })}
        </div>
      );
    }

    if (query.length > 0 && filteredData.filter((options) => options.options.length > 0).length <= 0) {
      return <div>No results found</div>;
    }

    return (
      <div>
        {filteredData.map((option, index) => (
          <div style={{marginTop: isSecondSection(filteredData, index) ? 10 : 0}}>
            {filteredData.length > 1 && <TitleSection>{option.title}</TitleSection>}
            {option.options.length === 0 ? (
              <div style={{paddingBottom: 10}}>No results found</div>
            ) : (
              renderData(option.options.slice(0, filteredData.length > 1 ? 3 : 10))
            )}
          </div>
        ))}
      </div>
    );
  };

  return (
    <HCFilterClearableBase {...props}>
      <FilterOptionsContainer>
        <StyledInput placeholder="Search by name or role" onChange={(e) => setQuery(e.target.value)} value={query} />
        {dropdownResults()}
      </FilterOptionsContainer>
    </HCFilterClearableBase>
  );
};
