import React from 'react';
import {InvitedUser} from 'src/types';
import client from 'src/apollo';
import {getFullName} from 'src/utils/user';
import {CancelUserInvite} from 'src/gql/mutation/CancelInvite';
import AnalyticsManager, {EVENTS} from 'src/analytics/AnalyticsManager';

/**
 * A hook to cancel the invited user, preserves the loading state to show loader
 * before calling the fetch invite list again after cancellation of invite to refresh the list.
 */
const useCancelInvite = () => {
  const cancelInviteLoadingRef = React.useRef([]);
  const [cancelInviteLoadingList, setCancelInviteLoadingList] = React.useState<string[]>([]);

  const tryCancelInvite = React.useCallback(
    async (invitedUser: InvitedUser) => {
      try {
        let resultName: string;
        cancelInviteLoadingRef.current.push(invitedUser.id);
        setCancelInviteLoadingList([...cancelInviteLoadingRef.current]);

        await client.mutate({
          mutation: CancelUserInvite,
          fetchPolicy: 'no-cache',
          variables: {
            inviteId: invitedUser.id,
          },
        });

        AnalyticsManager.applyAnalytics({
          eventName: EVENTS.cancelInviteButtonPressed,
          params: {
            invite_id: invitedUser.id,
          },
        });

        if (invitedUser.address) resultName = invitedUser.address.address;
        else resultName = getFullName(invitedUser.user);

        return Promise.resolve(resultName);
      } catch (e) {
        return Promise.reject(e);
      } finally {
        cancelInviteLoadingRef.current = cancelInviteLoadingRef.current.filter((id) => id !== invitedUser.id);
        setCancelInviteLoadingList([...cancelInviteLoadingRef.current]);
      }
    },
    [cancelInviteLoadingRef],
  );

  return {
    cancelInviteLoadingList,
    tryCancelInvite,
  };
};

export default useCancelInvite;
