import * as React from 'react';
import appTheme from 'src/styles/theme';

export default ({styles = {}}: {styles?: any}) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" style={{...styles}}>
    <path
      d="M9 7V1C9 0.447715 8.55228 0 8 0V0C7.44772 0 7 0.447715 7 1V7H1C0.447715 7 0 7.44772 0 8V8C0 8.55228 0.447715 9 1 9H7V15C7 15.5523 7.44772 16 8 16V16C8.55228 16 9 15.5523 9 15V9H15C15.5523 9 16 8.55228 16 8V8C16 7.44772 15.5523 7 15 7H9Z"
      fill={styles?.color ? styles.color : appTheme.watermelon}
    />
  </svg>
);
