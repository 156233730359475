import {getParsedAuthRegion} from 'src/utils/localStorageHandler';
import {DEBUG} from 'src/constants/storageKeys';
import {CANADA_ABBREVIATION, EUROPE_ABBREVIATION, UNITED_STATES_ABBREVIATION} from 'src/constants/strings';

export default function getWsEndpoint(): string {
  const region = getParsedAuthRegion();
  const debugValues = JSON.parse(sessionStorage.getItem(DEBUG));

  let endPointUrl = '';

  if (debugValues) {
    endPointUrl = debugValues.socketURL;
  } else {
    switch (region) {
      case CANADA_ABBREVIATION:
        endPointUrl = process.env.REACT_APP_WS_URL;
        break;
      case UNITED_STATES_ABBREVIATION:
        endPointUrl = process.env.REACT_APP_WS_URL_US;
        break;
      case EUROPE_ABBREVIATION:
        endPointUrl = process.env.REACT_APP_WS_URL_EU;
        break;
    }
  }

  const port = process.env.REACT_APP_WS_PORT ? String(process.env.REACT_APP_WS_PORT) : '';
  const url = String(endPointUrl);
  return port ? `${url}:${port}` : url;
}
