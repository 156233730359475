import React from 'react';
import {CalenderViewDelegateProtocol} from '@hypercare/hc-web-sdk';
import SwapActionCalendarAssignees from 'src/pages/SchedulingPage/action-calendar/swap-section/SwapActionCalendarAssignees';
import {PUBLISHED} from 'src/constants/scheduler';
import {ScheduleViewModelData, ShiftRequestType} from 'src/types';
import MarketplaceCalendarAssignees from 'src/pages/SchedulingPage/action-calendar/marketplace-section/MarketplaceCalendarAssignees';

export class ScheduleCalendarDelegate extends CalenderViewDelegateProtocol {
  viewModelScheduleData = null;
  userToHexColorCode = null;
  type = null;

  constructor(
    viewModelScheduleData: ScheduleViewModelData,
    userToHexColorCode: {
      [userId: string]: string;
    },
    type: ShiftRequestType,
  ) {
    super(viewModelScheduleData.startDateISOstring);
    this.viewModelScheduleData = viewModelScheduleData;
    this.userToHexColorCode = userToHexColorCode;
    this.type = type;
  }

  renderData(): void {
    for (let i = 0; i < Object.keys(this.viewModelScheduleData['monthlyCalendar']).length; i++) {
      var shifts =
        this.viewModelScheduleData['monthlyCalendar'][Object.keys(this.viewModelScheduleData['monthlyCalendar'])[i]];
      if (this.type === 'swap') {
        if (this.viewModelScheduleData.scheduleState === PUBLISHED) {
          this.addDatatoCalender(
            i,
            <SwapActionCalendarAssignees
              shifts={shifts}
              userToColorCodes={this.userToHexColorCode}
              monthlyCalendar={this.viewModelScheduleData.monthlyCalendar}
            />,
          );
        }
      } else {
        this.addDatatoCalender(
          i,
          <MarketplaceCalendarAssignees shifts={shifts} userToColorCodes={this.userToHexColorCode} />,
        );
      }
    }
  }
}

export default ScheduleCalendarDelegate;
