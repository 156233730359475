import * as React from 'react';
import {Redirect, Route, RouteProps} from 'react-router-dom';
import {AuthContext} from 'src/auth';
import {IdentifyUser} from 'src/utils/FeatureFlagManager';
import {DESIRED_ROUTE} from 'src/constants/storageKeys';
interface Props {
  isLoggedIn: boolean;
  props: RouteProps;
}

const ProtectedRouteGate = ({isLoggedIn, props}: Props) => {
  sessionStorage.setItem(DESIRED_ROUTE, props.location.pathname);
  if (isLoggedIn) {
    IdentifyUser();
  }
  return isLoggedIn ? <Route {...props} /> : <Redirect to="/login" />;
};

export default (props: RouteProps) => (
  <AuthContext.Consumer>
    {({isLoggedIn}) => <ProtectedRouteGate isLoggedIn={isLoggedIn} props={props} />}
  </AuthContext.Consumer>
);
