import React from 'react';
import styled from 'styled-components';
import ProfilePic from 'src/components/ProfilePic';
import {User} from 'src/types';
import store from 'src/redux';
import GetOrganizationMember from 'src/gql/query/GetOrganizationMember';
import client from 'src/apollo';
import LoadingDiv from 'src/components/LoadingDiv';
import {IsFeatureFlagEnabled} from 'src/utils/FeatureFlagManager';
import {Layout, UserDescription, UserName, UserRole} from 'src/pages/MessengerPage/messenger/StyledUserList';

export const StyledLayout = styled(Layout)`
  max-width: 200px;
`;

interface Props {
  colleague: User;
  onClick?: (colleague: User) => void;
}

const UserListItemDetails = ({colleague, onClick}: Props) => {
  const {firstname, role, lastname} = colleague;
  const {organizationId} = store.getState().organization;
  const [isOrganizationMember, setIsOrganizationMember] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(false);
  const LOCKED = 'locked';

  const fetchUserOrganizationStatus = async () => {
    setIsLoading(true);
    const getOrgMemberResult = await client.query({
      query: GetOrganizationMember,
      variables: {
        userId: colleague.id,
        organizationId: organizationId,
      },
    });
    getOrgMemberResult?.data?.organizationQuery?.organization?.user?.__typename === 'PublicUser'
      ? setIsOrganizationMember(false)
      : setIsOrganizationMember(true);
    setIsLoading(false);
  };

  React.useEffect(() => {
    fetchUserOrganizationStatus();
  }, [colleague]);

  return (
    <StyledLayout onClick={() => onClick && onClick(colleague)}>
      {isLoading ? (
        <LoadingDiv />
      ) : (
        <ProfilePic users={[colleague]} status={!isOrganizationMember ? LOCKED : 'active'} />
      )}
      <UserDescription>
        <UserName>
          {firstname} {lastname}
        </UserName>
        <UserRole>{role}</UserRole>
      </UserDescription>
    </StyledLayout>
  );
};

export default UserListItemDetails;
