import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import ProfilePic from 'src/components/ProfilePic';
import styled from 'styled-components';
import getTodayByFormat from 'src/utils/locating/getTodayByFormat';
import moment from 'moment';
import isNextShiftWithinGivenHour from 'src/utils/locating/isNextShiftWithinGivenHour';
import {NEXT_SHIFT_DISPLAY_GAP} from 'src/constants/locating';
import RotatingPagerDropdownListener from 'src/pages/LocatingPage/locating/search-section/RotatingPagerDropdown';
import {getFullName} from 'src/utils/user';
import {LocatingRole, User} from 'src/types';
import NoteDetailsExpansionPanel from 'src/pages/LocatingPage/locating/profile-section/notes-section/NoteDetailsExpansionPanel';
import {IsFeatureFlagEnabled} from 'src/utils/FeatureFlagManager';
import {UPCOMING_SHIFT_DESCRIPTION, NO_ONE_IS_CURRENTLY_AVAILABLE, SHIFT_END_DESCRIPTION} from 'src/constants/strings';
import {HCLabelThree} from 'src/components/HypercareComponents';
import isEndTimeMinutesBeforeStartTime from '../../../../utils/scheduling/isEndTimeMinutesBeforeStartTime';

const CurrentShift = styled.div`
  display: flex;
  align-items: center;
  color: grey;
  height: 30px;
  flex-wrap: wrap;
`;

const UpcomingIndicator = styled.div`
  margin-left: 8px;
  font-size: 14px;
  font-family: Nunito;
  font-weight: 400;
  color: ${(props) => props.theme.androidGreen};
`;

const ShiftAssignee = styled.p`
  margin: 0;
  color: grey;
  font-size: 14px;
  padding-left: 0.5em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const RoleTitle = styled.div`
  font-weight: 600;
  word-break: break-word;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const RoleTimeRange = styled.div`
  font-size: 16px;
  line-height: 24px;
  width: fit-content;
  padding: 3px 8px;
  margin: 5px 0;
  background-color: ${(props) => props.theme.lightGrey};
  border-radius: 5px;
  white-space: nowrap;
  color: ${(props) => props.theme.greyishBrown};
  height: 28px;
`;

const RoleListContainer = styled.div`
  border-radius: 4px;
  border: 0.5px solid #d8d8d8;
  padding: 4px;
  margin: 10px;
`;

const RoleListItemContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1;
  text-align: left;
  padding: 8px 0;
  cursor: pointer;
`;

const RoleInformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;

const ShiftContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
`;
interface Props {
  role: LocatingRole;
  handleClickRole: (role: LocatingRole) => void;
  setFavourite: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, role: LocatingRole) => Promise<void>;
  isGivenRoleFavourite: (role: LocatingRole) => boolean;
  departmentId?: number;
}

const RoleListItems = ({role, handleClickRole, setFavourite, isGivenRoleFavourite, departmentId}: Props) => {
  const roleHiddenNotes = IsFeatureFlagEnabled('roleHiddenNotes');
  function formatRoleTime(roleTimeString: string) {
    return moment(new Date(`${todayDate}T${roleTimeString}Z`)).format('HH:mm');
  }

  function isEndTimeNextDay(roleStartTime: string, roleEndTime: string) {
    return (
      Date.parse(`${todayDate}T${formatRoleTime(roleStartTime)}`) >=
      Date.parse(`${todayDate}T${formatRoleTime(roleEndTime)}`)
    );
  }

  const todayDate = getTodayByFormat();

  const renderAssignee = (user: User) => {
    return (
      <React.Fragment>
        <ProfilePic size={25} fontSize={12} users={[user]} />
        <ShiftAssignee>{getFullName(user)}</ShiftAssignee>
      </React.Fragment>
    );
  };

  const isFavouritedRole = isGivenRoleFavourite(role);

  return (
    <RoleListContainer>
      <RoleListItemContainer onClick={() => handleClickRole(role)}>
        <IconButton style={{padding: '0 12px 12px 12px'}} aria-label="star" onClick={(e) => setFavourite(e, role)}>
          <i className="material-icons" style={{color: isFavouritedRole ? 'goldenrod' : 'lightgrey'}}>
            star
          </i>
        </IconButton>
        <RoleInformationContainer>
          {isFavouritedRole && <HCLabelThree>{role?.department?.name}</HCLabelThree>}
          <RoleTitle>
            <div style={{marginRight: '8px'}}>{role.name}</div>
            <div>{Boolean(role.pagerNumber) && <RotatingPagerDropdownListener role={role} />}</div>
          </RoleTitle>
          <RoleTimeRange>
            {`${formatRoleTime(role.startTime)} - ${formatRoleTime(role.endTime)}`}
            {isEndTimeNextDay(role.startTime, role.endTime) && <sup> +1</sup>}
          </RoleTimeRange>
          {role.currentShift?.user && (
            <ShiftContainer>
              <CurrentShift>
                {renderAssignee(role.currentShift.user)}{' '}
                <UpcomingIndicator>
                  {SHIFT_END_DESCRIPTION + moment(role.currentShift.endDate).format('HH:mm')}
                  {isEndTimeNextDay(role.currentShift.startDate, role.currentShift.endDate) && <sup>+1</sup>}
                </UpcomingIndicator>
              </CurrentShift>
            </ShiftContainer>
          )}
          {!role.currentShift?.user && NO_ONE_IS_CURRENTLY_AVAILABLE}
          {!role.currentShift?.user &&
            role.nextShift?.user &&
            isNextShiftWithinGivenHour(role.nextShift.startDate, NEXT_SHIFT_DISPLAY_GAP) && (
              <ShiftContainer>
                <CurrentShift>{renderAssignee(role.nextShift.user)}</CurrentShift>
                <UpcomingIndicator>
                  {UPCOMING_SHIFT_DESCRIPTION + moment(role.nextShift.startDate).format('HH:mm')}
                </UpcomingIndicator>
              </ShiftContainer>
            )}
        </RoleInformationContainer>
      </RoleListItemContainer>
      {!!roleHiddenNotes && !!departmentId ? (
        <NoteDetailsExpansionPanel
          role={role}
          departmentId={departmentId}
          isGivenRoleFavourite={isGivenRoleFavourite}
        />
      ) : null}
    </RoleListContainer>
  );
};

export default RoleListItems;
