import {FormikProps} from 'formik';
import {usernameSchema, passwordSchema} from 'src/utils/validations';
import ApiHelper from 'src/api';
import {toast} from 'react-toastify';
import {NO_PHONE_NUMBER, NO_SUCH_USER, INVALID_TOKEN, VULNERABLEPASSWORD} from 'src/constants/networkError';
import {ResetPasswordFormValues} from 'src/types';

class ResetPasswordController {
  public static stepController = (
    customProps: {
      activeStep: number;
      validationCodeArray: string[];
      setActiveStep: React.Dispatch<React.SetStateAction<number>>;
      setValidationErrorMsg: any;
      setValidationResultType: any;
      setLoading: (isLoading: boolean) => void;
    },
    formikProps: FormikProps<ResetPasswordFormValues>,
  ) => {
    const {
      values,
      touched,
      // handleSubmit,
      setFieldError,
      setFieldTouched,
    } = formikProps;

    const {activeStep, setActiveStep, setLoading, validationCodeArray, setValidationErrorMsg, setValidationResultType} =
      customProps;

    switch (activeStep) {
      case 0:
        usernameSchema
          .validate(values.username)
          .then(async () => {
            setLoading(true);
            const result = await ApiHelper.PublicEndpoints.requestValidationCodeForResetPassword({
              username: values.username,
            });
            if (result && result.success) {
              const {passwordReset} = result.data;
              setValidationResultType(passwordReset.requestAddress);
              setLoading(false);
              setActiveStep((prevActiveStep) => prevActiveStep + 1);
            } else {
              const response = result.error.response;
              if (!response)
                throw {
                  errors: ['Failed to request verification code, please check your internet connection and try again'],
                };
              const errorObj = response.data.errors[0];
              switch (errorObj.code) {
                case NO_PHONE_NUMBER:
                  throw {
                    errors: [
                      'There are no phone number associated to this username, please contact support@hypercare.com',
                    ],
                  };
                case NO_SUCH_USER:
                  throw {
                    errors: ['This username does not exist, please try again'],
                  };
                default:
                  throw {
                    errors: ['Unknown error occurred, failed to request verification code'],
                  };
              }
            }
          })
          .catch((err) => {
            setLoading(false);
            if (!touched.username) setFieldTouched('username', true);
            setFieldError('username', err.errors[0]);
          });
        break;

      case 1:
        (async () => {
          try {
            setValidationErrorMsg('');
            setLoading(true);
            const verificationCode = validationCodeArray.reduce((acc, current) => acc + current);
            const result = await ApiHelper.PublicEndpoints.verifyPasswordToken({
              username: values.username,
              token: verificationCode,
            });
            if (result && result.success) {
              setLoading(false);
              setActiveStep((prevActiveStep) => prevActiveStep + 1);
            } else {
              const response = result.error.response;
              if (!response)
                throw {
                  errors: ['Failed to verify code, please check your internet connection and try again'],
                };
              const errorObj = response.data.errors[0];
              switch (errorObj.code) {
                case INVALID_TOKEN:
                  throw {
                    errors: ['Incorrect verification code, please try again'],
                  };
                default:
                  throw {
                    errors: ['Unknown error occurred, failed to verify code'],
                  };
              }
            }
          } catch (err) {
            setLoading(false);
            setValidationErrorMsg(err.errors[0]);
          }
        })();
        break;

      case 2:
        passwordSchema
          .validate(values.password)
          .then(async () => {
            setLoading(true);
            const verificationCode = validationCodeArray.reduce((acc, current) => acc + current);
            const result = await ApiHelper.PublicEndpoints.resetPassword({
              username: values.username,
              token: verificationCode,
              newPassword: values.password,
            });
            if (result && result.success) {
              setLoading(false);
              setActiveStep((prevActiveStep) => prevActiveStep + 1);
            } else {
              const response = result.error.response;
              if (!response)
                throw {
                  errors: ['Failed to verify code, please check your internet connection and try again'],
                };
              const errorObj = response.data.errors[0];
              switch (errorObj.code) {
                case VULNERABLEPASSWORD:
                  throw {
                    errors: ['This password is unsafe, please use a stronger password.'],
                  };
                default:
                  throw {
                    errors: ['Unknown error occurred, please check your internet connection and try again'],
                  };
              }
            }
          })
          .catch((err) => {
            setLoading(false);
            if (!touched.password) setFieldTouched('password', true);
            setFieldError('password', err.errors[0]);
          });
        break;

      default:
        break;
    }
  };

  public static resendValidationCode = async ({username, setLoading, setValidationErrorMsg}) => {
    try {
      setLoading(true);
      const result = await ApiHelper.PublicEndpoints.requestValidationCodeForResetPassword({
        username,
      });
      if (result && result.success) {
        setLoading(false);
        toast.success(`Successfully resent verification code`);
      } else {
        throw {
          errors: ['Failed to resend verification code, please check your internet connection and try again'],
        };
      }
    } catch (err) {
      setLoading(false);
      setValidationErrorMsg(err.errors[0]);
    }
  };
}

export default ResetPasswordController;
