import React from 'react';
import SignupContainer from '../signup/SignupContainer';
import SignupHeader from '../signup/SignupHeader';
import styled from 'styled-components';
import HypercareLogoSVG from 'src/svgs/HypercareLogoSVG';

interface ICreateNewOrgProps {
  title?: string;
  isDisabled?: boolean;
  description?: string | JSX.Element;
  onBackButtonClick?: () => void;
  currentStep?: number;
  content?: React.ReactNode;
}

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 31.522px;
`;

const CreateNewOrg: React.FC<ICreateNewOrgProps> = ({onBackButtonClick, content, currentStep = 1}) => {
  return (
    <Container>
      <SignupContainer currentStep={currentStep}>
        <SignupHeader
          title={'Create a new org'}
          description={'What’s the name of your org? Choose something your colleagues can easily recognize!'}
          onBackButtonClick={onBackButtonClick}
        />
        {content}
      </SignupContainer>
    </Container>
  );
};

export default CreateNewOrg;
