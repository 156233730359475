import React from 'react';
import {withStyles, Theme, createStyles} from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import apptheme from 'src/styles/theme';

// DOC: https://material-ui.com/components/tabs/#customized-tabs

const AntTabs = withStyles({
  root: {
    padding: '0 20px',
    borderBottom: `1px solid ${apptheme.borderBottomLight}`,
  },
  indicator: {
    backgroundColor: apptheme.androidGreen,
  },
})(Tabs);

const AntTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: 'capitalize',
      minWidth: 72,
      paddingTop: 0,
      paddingBottom: 0,
      fontWeight: 400,
      marginRight: theme.spacing(3),
      fontFamily: 'Nunito',
      '&:hover': {
        color: apptheme.androidGreen,
        opacity: 1,
      },
      '&$selected': {
        color: apptheme.androidGreen,
        fontWeight: 500,
      },
      '&:focus': {
        color: apptheme.androidGreen,
      },
    },
    selected: {},
  }),
)((props: StyledTabProps) => <Tab disableRipple {...props} />);

interface StyledTabProps {
  label: string;
  value: string;
}

interface Props {
  value: string;
  values: string[];
  setValue: (value: string) => void;
}

const CustomizedTabs = ({value, values, setValue}: Props) => {
  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    newValue = newValue.replace(' ', '');
    setValue(newValue);
  };

  return (
    <AntTabs value={value} onChange={handleChange} aria-label="tabs">
      {values.map((currentValue) => {
        let currentValueWithoutSpace = currentValue.replace(' ', '');
        return <AntTab key={currentValueWithoutSpace} value={currentValueWithoutSpace} label={currentValue} />;
      })}
    </AntTabs>
  );
};

export default CustomizedTabs;
