import * as R from 'ramda';
import React, {Component} from 'react';
import {withRouter, RouteComponentProps} from 'react-router-dom';
import {Manager, Reference, Popper} from 'react-popper';
import {ApolloClient} from 'apollo-client';
import {ApolloConsumer} from 'react-apollo';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import TripleDot from 'src/svgs/TripleDot';
import {MenuButton, OptionsBox, Option} from 'src/styles/styled-components/PopoverMenuComponents';
import LeaveChatMutation from 'src/gql/mutation/LeaveChatMutation';
import {Chat} from 'src/types';
import GetChatsQuery, {getChats, chatsPath} from 'src/gql/query/GetChatsQuery';
import {toast} from 'react-toastify';
import AnalyticsManager, {EVENTS} from 'src/analytics/AnalyticsManager';
import {MESSENGER} from 'src/constants/routerPathName';
import styled from 'styled-components';

const OptionHolder = styled.div`
  padding: 10px 3px;
  cursor: pointer;
`;

const StyledTripleDot = styled(TripleDot)`
  transform: translateX(5px);
`;

interface Props extends RouteComponentProps<{}> {
  chat: Chat;
  client: ApolloClient<any>;
}

class ChatInformationOptionsButton extends Component<Props> {
  public state = {
    isMenuOpen: false,
  };

  public render() {
    const {isMenuOpen} = this.state;
    return (
      <ClickAwayListener onClickAway={this.clickedOutside}>
        <OptionHolder onClick={this.onClickMenu}>
          <Manager>
            <Reference>
              {({ref}) => (
                <MenuButton ref={ref}>
                  <StyledTripleDot />
                </MenuButton>
              )}
            </Reference>
            <Popper placement="left">
              {({ref, style, placement}) =>
                isMenuOpen && (
                  <OptionsBox ref={ref} style={style} data-placement={placement}>
                    <Option onClick={this.leaveChat}>Leave Chat</Option>
                  </OptionsBox>
                )
              }
            </Popper>
          </Manager>
        </OptionHolder>
      </ClickAwayListener>
    );
  }

  private clickedOutside = () => {
    this.setState({isMenuOpen: false});
  };

  private onClickMenu = () => {
    this.setState({isMenuOpen: !this.state.isMenuOpen});
  };

  private leaveChat = async () => {
    if (window.confirm('Are you sure you want to leave this chat?')) {
      const {client, chat, history} = this.props;
      try {
        await client.mutate({
          mutation: LeaveChatMutation,
          variables: {
            chatId: chat.id,
          },
          update: () => {
            const chatsQuery = client.cache.readQuery({
              query: GetChatsQuery,
            });
            if (chatsQuery) {
              const chats = getChats(chatsQuery);
              const newChats = R.reject((c) => chat.id === c.id, chats);
              const newQuery = R.assocPath(chatsPath, newChats, chatsQuery);
              client.writeQuery({
                query: GetChatsQuery,
                data: newQuery,
              });
              history.push(`/${MESSENGER}`);
            }
          },
        });

        AnalyticsManager.applyAnalytics({
          eventName: EVENTS.leaveChat,
          params: {
            chat_id: chat.id,
          },
        });
      } catch (e) {
        console.error('Unable to leave chat', e);
        toast.error('Unable to leave chat, please promote someone to admin first');
      }
    }
  };
}

const WithRouterComponent = withRouter(ChatInformationOptionsButton);

export default (props) => (
  <ApolloConsumer>{(client) => <WithRouterComponent {...props} client={client} />}</ApolloConsumer>
);
