import React from 'react';
import {ApolloQueryResult} from 'apollo-client';
import {FetchShiftsRequestsResult} from 'src/types';

export type RefetchRequestType = (
  variables?: Record<string, any>,
) => Promise<ApolloQueryResult<FetchShiftsRequestsResult>>;

export interface RequestRefetchContextInterface {
  refetch: RefetchRequestType;
}

export interface ScheduleQueryVariables {
  currentDepartmentId: number;
  startOfMonthUTC: string;
  endOfMonthUTC: string;
}

export const RequestRefetchContext = React.createContext<RequestRefetchContextInterface>({
  refetch: null,
});

const RequestRefetchProvider = (props: {refetch: RefetchRequestType; children: React.ReactElement}) => {
  const {refetch, children} = props;

  return <RequestRefetchContext.Provider value={{refetch}}>{children}</RequestRefetchContext.Provider>;
};

export default RequestRefetchProvider;
