import React from 'react';
import styled from 'styled-components';
import {FormikProps} from 'formik';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import {FormField} from '../../../../../components/@hc-ui/components/FormField/FormField';
import HypercareSelfProfileViewModel from '../../../viewModels/HypercareSelfProfileViewModel';
import {ADD_ADDRESS_MODAL_INSTRUCTIONS, SELECT} from 'src/constants/strings';

const FinalStepHelperTextHolder = styled.div`
  margin-top: 0.5em;
  margin-bottom: 1em;
`;

interface Props {
  isLoading: boolean;
  formikProps: FormikProps<any>;
  profileLabelAddressFeatureFlag: boolean;
}

const AddAddressStepTwo = ({isLoading, formikProps, profileLabelAddressFeatureFlag}: Props) => {
  const {values, handleBlur, handleChange} = formikProps;
  const viewModel = HypercareSelfProfileViewModel();

  const {data, error, loading} = viewModel.useGetOrganizationLabelOptions();

  const onLabelChange = (newValue: string) => {
    formikProps.setFieldValue('label', newValue);
  };
  return (
    <React.Fragment>
      <FinalStepHelperTextHolder>{ADD_ADDRESS_MODAL_INSTRUCTIONS}</FinalStepHelperTextHolder>
      {profileLabelAddressFeatureFlag && (
        <FormField
          fieldType={'dropdown'}
          fieldId={'addressLabelDropdown'}
          mode={'edit'}
          disabled={false}
          onChangeLabel={() => {}}
          isRequired={true}
          placeholder={SELECT}
          label={'Label'}
          labelOptions={['label']}
          isSynced={false}
          isManagedUser={false}
          onChange={(v) => onLabelChange(v)}
          value={formikProps.values.label}
          renderValue={(v) => v}
          options={data}
          onOptionClick={(v) => onLabelChange(v)}
          renderOptionContent={(v) => v}
          getOptionId={(v) => v}
          getIsOptionSelected={(v) => formikProps.values.label === v}
          onClickAway={() => {}}
        />
      )}
      <FormControl>
        <RadioGroup
          aria-label="set visibility"
          id="access"
          name="access"
          value={values.access}
          onBlur={handleBlur}
          onChange={handleChange}
        >
          <FormControlLabel
            value="private"
            disabled={isLoading}
            control={<Radio />}
            label="Only me and administrators (default)"
          />
          <FormControlLabel value="public" disabled={isLoading} control={<Radio />} label="Visible to my colleagues" />
        </RadioGroup>
      </FormControl>
    </React.Fragment>
  );
};

export default AddAddressStepTwo;
