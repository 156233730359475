import {Box, makeStyles, Theme, Typography} from '@material-ui/core';
import React from 'react';
import AnalyticsManager, {EVENTS} from 'src/analytics/AnalyticsManager';
import localTheme from 'src/styles/theme';
import {getBaseClientURL} from 'src/utils/getBaseClientUrl';

const useStyles = makeStyles((theme: Theme) => ({
  helpText: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
    color: '#4A4A4A',
  },
  recoverAccountText: {
    color: 'red',
    fontFamily: "'Open Sans', sans-serif",
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
  },
  contactSupportLink: {
    color: localTheme.primary,
    textDecoration: 'none',
    marginLeft: theme.spacing(),
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  container: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    gap: '5px',
    marginTop: '16px',
    marginBotton: '16px',
  },
}));

interface Props {
  recoverAccount?: boolean;
}

const NewStepFooter = ({recoverAccount}: Props) => {
  const classes = useStyles();

  const onContactSupport = () => {
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.signUpContactSupport,
    });
  };

  return (
    <Box className={classes.container}>
      {recoverAccount ? (
        <Typography variant="body1" color="textSecondary" className={classes.recoverAccountText}>
          Phone number is already in use
          <a
            href={`${getBaseClientURL()}/resetpassword`}
            target="_blank"
            rel="noopener noreferrer"
            className={classes.contactSupportLink}
            onClick={onContactSupport}
          >
            Recover account
          </a>
        </Typography>
      ) : null}
      <Typography variant="body1" color="textSecondary" className={classes.helpText}>
        Need help?
        <a
          href="https://www.hypercare.com/contact"
          target="_blank"
          rel="noopener noreferrer"
          className={classes.contactSupportLink}
          onClick={onContactSupport}
        >
          Contact support
        </a>
      </Typography>
    </Box>
  );
};

export default NewStepFooter;
