import React from 'react';
import {Formik, FormikProps} from 'formik';
import * as Yup from 'yup';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';
import {CustomLoader} from 'src/components/LoadingDiv';

const StyledButtonHolder = styled.div`
  text-align: right;
  margin-top: 1em;
  button {
    height: 36px;
    width: 100px;
  }
`;

export const ErrorMessage = styled.span`
  color: red;
  font-size: 14px;
`;

const FieldInputLabel = styled(InputLabel)`
  color: ${(props) => props.theme.greyishBrown} !important;
  margin-bottom: 5px;
  font-size: 14px !important;
  font-family: Open Sans !important;
  font-style: normal !important;
  font-weight: normal !important;
  line-height: 22px !important;
`;

const pageNumberValidationSchema = Yup.object().shape({
  pageNumber: Yup.number().typeError('Call back number is invalid').required('Call back number is required!'),
});

const CallBackPageNumberForm = ({handleModalFormSubmission}) => (
  <Formik
    initialValues={{
      pageNumber: '',
    }}
    validationSchema={pageNumberValidationSchema}
    onSubmit={(values, {resetForm, setFieldError, setSubmitting}) => {
      if (values.pageNumber.includes('+')) {
        setFieldError('pageNumber', 'Call back number is invalid');
        setSubmitting(false);
        return;
      }

      handleModalFormSubmission(values).catch((e) => {
        switch (e) {
          case 'unknown error':
            setSubmitting(false);
            setFieldError('general', 'failed to page user, please try again');
            break;
          default:
            resetForm();
        }
      });
    }}
  >
    {(props) => {
      const {
        values: {pageNumber},
        errors,
        isSubmitting,
        handleChange,
        handleSubmit,
      } = props as FormikProps<any>;

      const hasErrors = Object.keys(errors).length > 0;

      return (
        <form onSubmit={handleSubmit} className="modalForm">
          <FieldInputLabel htmlFor="pageNumber">CALL BACK NUMBER</FieldInputLabel>
          <TextField
            fullWidth
            autoFocus
            InputLabelProps={{
              shrink: true,
            }}
            id="pageNumber"
            name="pageNumber"
            placeholder="Enter call back number here"
            value={pageNumber}
            onChange={handleChange}
            variant="outlined"
            helperText={errors.pageNumber}
            error={Boolean(errors.pageNumber)}
          />

          <StyledButtonHolder>
            <Button
              type="submit"
              disabled={hasErrors || isSubmitting}
              disableTouchRipple
              variant="contained"
              color="secondary"
            >
              {isSubmitting ? <CustomLoader /> : 'Send'}
            </Button>
          </StyledButtonHolder>

          {Boolean(errors.general) && <ErrorMessage>{errors.general}</ErrorMessage>}
        </form>
      );
    }}
  </Formik>
);

export default CallBackPageNumberForm;
