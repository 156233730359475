import React from 'react';
import styled from 'styled-components';
import MenuItem from '@material-ui/core/MenuItem';
import {StyledFormControl, StyledInputBase} from 'src/components/StepFormPartials';
import Select from '@material-ui/core/Select';
import Locate from 'src/svgs/Locate';
import {SiteScope} from 'src/types';
import {HypercareOnCallActions} from 'src/redux/actions/onCall';
import store from 'src/redux';
import {MinimalSiteData} from '../../../../gql/query/FetchSitesForOrganization';

const StyledLocateIcon = styled(Locate)`
  position: absolute;
  left: 10px;
  z-index: 1;
  width: 1em;
  height: 1em;
`;

const StyledInputWithoutBorder = styled(StyledInputBase)`
  .MuiSelect-select {
    /* border: none; */
    padding-left: 2em;
  }
`;

const NoSiteMessageHolder = styled.p`
  padding: 3em 12px;
  text-align: center;
`;

interface Props {
  setSelectedSite: React.Dispatch<React.SetStateAction<MinimalSiteData>>;
  selectedSite: MinimalSiteData;
  siteList: MinimalSiteData[];
}

const SiteDropdown = ({setSelectedSite, selectedSite, siteList}: Props) => {
  const [siteId, setSiteId] = React.useState(selectedSite ? selectedSite.id : null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newSelectedSite = siteList.find((site) => site.id === parseInt(event.target.value));
    store.dispatch(HypercareOnCallActions.setOnCallDepartments([]));
    store.dispatch(HypercareOnCallActions.setCurrentSelectedSite(newSelectedSite));
    setSiteId(parseInt(event.target.value));
    setSelectedSite(newSelectedSite);
  };

  if (!siteId)
    return (
      <NoSiteMessageHolder>
        Please contact your organization administrator if you would like to create a department.
      </NoSiteMessageHolder>
    );

  return (
    <div>
      <StyledFormControl>
        <Select
          value={siteId}
          onChange={handleChange}
          input={<StyledInputWithoutBorder name="siteDropdown" startAdornment={<StyledLocateIcon />} />}
        >
          {siteList.map((site) => (
            <MenuItem value={site.id} key={site.id}>
              {site.name}
            </MenuItem>
          ))}
        </Select>
      </StyledFormControl>
    </div>
  );
};

export default SiteDropdown;
