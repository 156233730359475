import {TextField} from '@material-ui/core';
import styled from 'styled-components';
import theme from '../theme';

export const StyledTextField = styled(TextField)`
  && {
    input {
      caret-color: ${theme.watermelon}; // Change this to the desired color
    }
  }
  & label.Mui-focused {
    color: rgb(0, 133, 154);
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: rgb(0, 133, 154);
    }
  }
  & .MuiInputBase-input {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
  /* & .MuiFormHelperText-root.Mui-error {
    color: #ff3e55;
  }

  & .MuiFormLabel-root.Mui-error {
    color: #ff3e55;
  } */
`;
