import React from 'react';
import {MuiPickersUtilsProvider, DatePicker} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import {clone} from 'ramda';
import styled from 'styled-components';
import store from 'src/redux';
import {actions} from 'src/redux/actions/scheduling';

const MonthlyDateSwitcher = styled.div`
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  .MuiInputBase-root {
    border-radius: 0.5em;
    transition: box-shadow 0.2s ease-out;
    &:hover {
      box-shadow: lightgrey 0 0 0 0.1rem;
    }
  }
  input {
    cursor: pointer;
    font-weight: bold;
    text-align: center;
    font-size: 22px;
  }
  .MuiInput-underline {
    &::before,
    &::after {
      content: none;
    }
  }
`;

const IconHolder = styled.div`
  cursor: pointer;
  padding: 10px;
  .material-icons {
    transition: all 0.2s ease-in-out;
  }
  &:hover {
    .material-icons {
      transform: scale(1.5);
    }
  }
`;

interface Props {
  startDate: moment.Moment;
  onSelectMonth: (startTime: string, endTime: string) => void;
}

const MonthSwitcher = ({onSelectMonth, startDate}: Props) => {
  // adding 2 days to avoid utc timezone affect and shift month to previous month
  async function refetchByDate(startDate: moment.Moment) {
    const startOfMonthUTC = moment(startDate.add(2, 'days').format()).startOf('month').toISOString();
    const endOfMonthUTC = moment(startDate.add(2, 'days').format()).endOf('month').millisecond(0o00).toISOString();
    onSelectMonth(startOfMonthUTC, endOfMonthUTC);
  }

  async function handleDateChange(date: moment.Moment) {
    await refetchByDate(date);
  }

  async function handleForwardMonth() {
    const forwardMonthStartDate = startDate.clone().add(1, 'M');
    await refetchByDate(forwardMonthStartDate);
    store.dispatch(actions.setRoleNotes({}));
  }

  async function handleBackwardMonth() {
    const backwardMonthStartDate = startDate.clone().subtract(1, 'M');
    await refetchByDate(backwardMonthStartDate);
    store.dispatch(actions.setRoleNotes({}));
  }

  return (
    <MonthlyDateSwitcher>
      <IconHolder onClick={handleBackwardMonth}>
        <i className="material-icons">keyboard_arrow_left</i>
      </IconHolder>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <DatePicker
          variant="dialog"
          openTo="month"
          views={['year', 'month']}
          value={clone(startDate).add(2, 'days')}
          onChange={handleDateChange}
        />
      </MuiPickersUtilsProvider>
      <IconHolder onClick={handleForwardMonth}>
        <i className="material-icons">keyboard_arrow_right</i>
      </IconHolder>
    </MonthlyDateSwitcher>
  );
};

export default React.memo(MonthSwitcher);
