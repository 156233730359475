import * as React from 'react';
import styled from 'styled-components';

const UnreadSpanner = styled.p<{priority: boolean}>`
  text-align: center;
  text-transform: uppercase;
  color: ${(props) => (props.priority ? props.theme.redPink : 'grey')};
  position: relative;
  font-size: 14px;
  padding-top: 20px;
  border-bottom: 1px solid ${(props) => (props.priority ? props.theme.redPink : 'lightgrey')};
  margin-bottom: 30px;
  & > span {
    display: block;
    transform: translate(-50%, -50%);
    position: absolute;
    left: 50%;
    right: 50%;
    width: 30%;
    max-width: 180px;
    padding: 10px;
    background-color: white;
  }
`;

interface Props {
  messageId: string | number;
  lastUnreadMessageId: number;
  unreadMessageCount: number;
  unreadPriorityMessageCount: number;
}

class UnreadMessageBanner extends React.PureComponent<Props> {
  public render() {
    const {messageId, lastUnreadMessageId, unreadMessageCount, unreadPriorityMessageCount} = this.props;

    if (
      messageId === lastUnreadMessageId &&
      (unreadMessageCount || unreadPriorityMessageCount) &&
      (unreadMessageCount > 1 || unreadPriorityMessageCount > 1)
    ) {
      return (
        <UnreadSpanner
          key={`unread-${lastUnreadMessageId}`}
          id={`unread-${lastUnreadMessageId}`}
          priority={unreadPriorityMessageCount >= 1}
        >
          <span>
            {unreadMessageCount >= 1 && unreadMessageCount + ` New Messages`}
            {unreadPriorityMessageCount >= 1 && unreadMessageCount >= 1 && <br />}
            {unreadPriorityMessageCount >= 1 && unreadPriorityMessageCount + `New Stat messages`}
          </span>
        </UnreadSpanner>
      );
    } else {
      return null;
    }
  }
}

export default UnreadMessageBanner;
