import {useMemo, useState} from 'react';
import moment from 'moment';

export const mapResponseLogToDataRow = (entry) => ({
  timestamp: entry.dateTime ? moment(entry.dateTime).format('YYYY-MM-DD hh:mm:ss A') : 'Failed to load date',
  event: entry.__typename,
  status: entry.eventStatus || entry.status || 'Failed to load status',
  sender: {
    id: entry.actor?.id || undefined,
    name: entry.actor ? `${entry.actor.firstName} ${entry.actor.lastName}` : 'Failed to load sender',
  },
  receiver: {
    id: entry.role?.id || entry.receiver?.id || undefined,
    isRole: !!entry.role,
    name:
      entry.role?.name ||
      (entry.receiver ? entry.receiver?.firstName + ' ' + entry.receiver?.lastName : 'Failed to load receiver'),
  },
  number: entry.address || 'N/A',
  message: entry.details || 'N/A',
});

export const timeToRealTime = (variables) => {
  switch (variables.startTime) {
    case '1 hour':
      return {
        startTime: moment().subtract(1, 'hour').format(),
        endTime: moment().format(),
      };
    case '12 hours':
      return {
        startTime: moment().subtract(12, 'hours').format(),
        endTime: moment().format(),
      };
    case '24 hours':
      return {
        startTime: moment().subtract(24, 'hours').format(),
        endTime: moment().format(),
      };
    case '48 hours':
      return {
        startTime: moment().subtract(48, 'hours').format(),
        endTime: moment().format(),
      };
    case '7 days':
      return {
        startTime: moment().subtract(7, 'days').format(),
        endTime: moment().format(),
      };
  }
  return variables;
};

export const createRequestVariables = (variables) => {
  return {
    ...variables,
    ...timeToRealTime(variables),
  };
};

const startTime = '1 hour';
const endTime = '1 hour';

export interface QuerySchema {
  logQuery: {
    eventTypes: 'sms_sent' | 'page_sent';
    logQueryFilters: {
      senderIds: string[];
      receivers: {
        userIds: string[];
        roleIds: string[];
      };
    };
  }[];
  startTime?: string;
  endTime?: string;
  continuationId?: string;
}

export const InitialQueryState: QuerySchema = {
  logQuery: [
    {
      eventTypes: 'sms_sent',
      logQueryFilters: {
        senderIds: [],
        receivers: {
          userIds: [],
          roleIds: [],
        },
      },
    },
    {
      eventTypes: 'page_sent',
      logQueryFilters: {
        senderIds: [],
        receivers: {
          userIds: [],
          roleIds: [],
        },
      },
    },
  ],
  startTime,
  endTime,
  continuationId: null,
  status: ['success', 'failure'],
};

export const useAuditLogQuery = (auditLogFilters, idToRoleMap) => {
  const {selectedEventIds, selectedStatusIds, selectedSenderIds, selectedReceiverIds, timeFilter} = auditLogFilters;

  const [currentVariables, setCurrentVariables] = useState<QuerySchema>(InitialQueryState);

  const futureVariables = useMemo(() => {
    const filters = {
      logQuery: [],
      startTime,
      endTime,
      continuationId: null,
      status: selectedStatusIds || [],
    };

    const logQueryFilters = {
      senderIds: selectedSenderIds,
      receivers: {
        userIds: selectedReceiverIds.filter((id) => !idToRoleMap?.has(id)),
        roleIds: selectedReceiverIds.filter((id) => idToRoleMap?.has(id)).map((x) => '' + x),
      },
    };

    if (selectedEventIds.length === 0 || selectedEventIds.length === 2) {
      filters.logQuery.push({
        eventTypes: 'sms_sent',
        logQueryFilters,
      });
      filters.logQuery.push({
        eventTypes: 'page_sent',
        logQueryFilters,
      });
    } else {
      filters.logQuery.push({
        eventTypes: selectedEventIds[0] === 'page' ? 'page_sent' : 'sms_sent',
        logQueryFilters,
      });
    }

    switch (timeFilter?.type) {
      case '1 hour':
        filters.startTime = filters.endTime = '1 hour';
        break;
      case '12 hours':
        filters.startTime = filters.endTime = '12 hours';
        break;
      case '24 hours':
        filters.startTime = filters.endTime = '24 hours';
        break;
      case '48 hours':
        filters.startTime = filters.endTime = '48 hours';
        break;
      case '7 days':
        filters.startTime = filters.endTime = '7 days';
        break;
      case 'custom':
        filters.startTime = timeFilter.customRange?.startDate;
        filters.endTime = timeFilter.customRange?.endDate;
        break;
    }
    return filters;
  }, [selectedEventIds, selectedStatusIds, selectedSenderIds, selectedReceiverIds, timeFilter]);

  const hasFiltersChanged = useMemo(
    () => JSON.stringify(currentVariables) !== JSON.stringify(futureVariables),
    [currentVariables, futureVariables],
  );

  return {
    currentVariables,
    setCurrentVariables,
    futureVariables,
    hasFiltersChanged,
  };
};
