import {HCFilterSimple} from 'src/@hc-ui/components/filters/variants/HCFilterSimple';
import Popup from 'reactjs-popup';
import React, {useState} from 'react';
import EventIcon from 'src/svgs/EventIcon';
import {NumberBadge, TableFilterContainer} from './TableFilters';
import ChevronUpIcon from 'src/svgs/ChevronUpIcon';
import ChevronDownIcon from 'src/svgs/ChevronDownIcon';

export const EventTypeFilter = ({auditLogFilters}) => {
  const {selectedEventIds, setSelectedEventIds} = auditLogFilters;
  const [isOpen, setIsOpen] = useState(false);

  const EventsFilter = React.forwardRef(({...props}, ref) => (
    <TableFilterContainer ref={ref as any} {...props} hasSelection={selectedEventIds.length > 0} isOpen={isOpen}>
      <EventIcon /> Event {selectedEventIds.length > 0 && <NumberBadge>{selectedEventIds.length}</NumberBadge>}
      {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
    </TableFilterContainer>
  ));

  return (
    <Popup
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      trigger={() => <EventsFilter />}
      position="bottom left"
      closeOnDocumentClick
    >
      {(close) => {
        return (
          <HCFilterSimple
            onClose={close}
            title="Event"
            options={[
              {
                title: 'Events',
                options: [
                  {
                    id: 'page',
                    label: 'Page',
                    value: 'page',
                  },
                  {
                    id: 'sms',
                    label: 'SMS',
                    value: 'sms',
                  },
                ],
              },
            ]}
            onToggle={(id: string) => {
              const found = selectedEventIds.find((selectedId) => selectedId === id);
              if (found) {
                setSelectedEventIds(selectedEventIds.filter((selectedId) => selectedId !== id));
              } else {
                setSelectedEventIds([...selectedEventIds, id]);
              }
            }}
            onClear={() => {
              setSelectedEventIds([]);
              close();
            }}
            selectedIds={selectedEventIds}
          />
        );
      }}
    </Popup>
  );
};
