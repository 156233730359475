import * as React from 'react';
import moment from 'moment';
import LoadingDiv from 'src/components/LoadingDiv';
import {useQuery} from 'react-apollo';
import MonthSwitcher from 'src/pages/SchedulingPage/schedule-layout/MonthSwitcher';
import BaseActionCalendarLayout from 'src/pages/SchedulingPage/action-calendar/BaseActionCalendarLayout';
import {useMarketplaceViewModelData} from 'src/pages/SchedulingPage/scheduling/hooks';
import {
  SchedulingVariableContextInterface,
  SchedulingVariableContext,
} from 'src/pages/SchedulingPage/scheduling/SchedulingVariableProvider';
import {getUserIdSetFromMarketplacePosts} from 'src/utils/scheduling/getUserIdSet';
import tryDispatchUserIdForColorAssignement from 'src/utils/scheduling/tryDispatchUserIdForColorAssignement';
import MarketplaceRefetchProvider from 'src/pages/SchedulingPage/action-calendar/marketplace-section/MarketplaceRefetchProvider';
import NewFetchMarketPlaceShiftsQuery from 'src/gql/query/NewFetchMarketPlaceShiftsQuery';

interface Props extends SchedulingVariableContextInterface {}

const MarketplaceLayout = ({currentDepartmentId, startOfMonthUTC, endOfMonthUTC, setScheduleQueryVariables}: Props) => {
  const fetchMarketPlaceShiftsResult = useQuery(NewFetchMarketPlaceShiftsQuery, {
    variables: {
      departmentId: currentDepartmentId,
      marketplaceFilter: {
        status: 'active',
        offerTypes: ['swap', 'give_away'],
        startDate: startOfMonthUTC,
        endDate: endOfMonthUTC,
      },
      month: moment(startOfMonthUTC).local().format('MMMM').toLowerCase(),
      year: moment(startOfMonthUTC).local().year(),
    },
    fetchPolicy: 'no-cache',
  });

  const {variables} = fetchMarketPlaceShiftsResult;
  const {startDate} = variables.marketplaceFilter;
  const {departmentId} = variables;

  const viewModelScheduleData = useMarketplaceViewModelData({
    fetchMarketPlaceShiftsResult,
    currentDepartmentId: departmentId,
  });

  if (!viewModelScheduleData) return <LoadingDiv />;

  const momentUTCStartDate = moment(startDate).utc();

  function onSelectMonth(startTime: string, endTime: string) {
    setScheduleQueryVariables((prevState) => ({
      ...prevState,
      startOfMonthUTC: startTime,
      endOfMonthUTC: endTime,
    }));
  }

  const {marketplacePosts} = fetchMarketPlaceShiftsResult.data.locating.department;
  tryDispatchUserIdForColorAssignement(getUserIdSetFromMarketplacePosts(marketplacePosts));

  return (
    <MarketplaceRefetchProvider refetch={fetchMarketPlaceShiftsResult.refetch}>
      <BaseActionCalendarLayout viewModelScheduleData={viewModelScheduleData}>
        <MonthSwitcher onSelectMonth={onSelectMonth} startDate={momentUTCStartDate} />
      </BaseActionCalendarLayout>
    </MarketplaceRefetchProvider>
  );
};

export default (props) => (
  <SchedulingVariableContext.Consumer>
    {(contextProps) => <MarketplaceLayout {...contextProps} {...props} />}
  </SchedulingVariableContext.Consumer>
);
