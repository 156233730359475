import * as React from 'react';
import {pathOr} from 'ramda';
import styled from 'styled-components';
import Badge from '@material-ui/core/Badge';
import {AuthContext} from 'src/auth/AuthProvider';
import {User, AuthPayload, WorkStatus, Assignee} from 'src/types';
import {getUpperCaseInitials} from 'src/utils/user';
import LockIcon from 'src/svgs/LockIcon';
import {withLDConsumer} from 'launchdarkly-react-client-sdk';

const LOCKEDSTATUS = 'locked';

interface Pic {
  size: number;
  fontSize?: number;
}

interface BadgeProps {
  status: WorkStatus | undefined;
}

interface Lock {
  size: number;
}

const ProfilePicImg = styled.img<Pic>`
  height: ${(props) => props.size}px;
  min-width: ${(props) => props.size}px;
  max-width: ${(props) => props.size}px;
  border-radius: ${(props) => props.size / 2}px;
  object-fit: cover;
`;

const LockIconWrapper = styled.div<Lock>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${(props) => props.size}px;
  min-width: ${(props) => props.size}px;
  max-width: ${(props) => props.size}px;
  border-radius: ${(props) => props.size / 2}px;
  color: white;
  background-color: ${(props) => props.theme.chatIconGrey};
`;

const InitialsPic = styled.div<Pic>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${(props) => props.size}px;
  min-width: ${(props) => props.size}px;
  max-width: ${(props) => props.size}px;
  border-radius: ${(props) => props.size / 2}px;
  font-size: ${(props) => props.fontSize}px;
  color: white;
  background-color: ${(props) => props.theme.chatIconGrey};
`;

const StyledBadge = styled(Badge)<BadgeProps>`
  .MuiBadge-dot {
    top: 85%;
    right: 5px;
    height: 10px;
    z-index: 0;
    width: 10px;
    ${(props) => (props.status === 'on_shift' ? `background-color: ${props.theme.appleGreen};` : undefined)}
    ${(props) => (props.status === 'busy' ? `background-color: ${props.theme.watermelon};` : undefined)}
    ${(props) => (props.status === 'unavailable' ? `background-color: ${props.theme.greyishBrown};` : undefined)}
  }
`;

interface Props {
  users?: User[] | Assignee[] | any;
  size?: number;
  chatImageUrl?: string;
  fontSize?: number;
  status?: string;
  flags?: {[key: string]: boolean};
  avatarLocked?: boolean;
}

interface State {
  imageErrored: boolean;
}

interface PropsWithAuth extends Props {
  authInfo: AuthPayload;
}

class ProfilePic extends React.PureComponent<PropsWithAuth, State> {
  public state = {
    imageErrored: false,
  };

  public render() {
    const {authInfo, users, size = 45, fontSize = 14, chatImageUrl, status, avatarLocked = true} = this.props;

    if (status === LOCKEDSTATUS && avatarLocked) {
      return (
        <LockIconWrapper size={size} data-testid="lock-icon-pic">
          <LockIcon color={'#fff'} width={'20px'} height={'20px'} />
        </LockIconWrapper>
      );
    }

    const {imageErrored} = this.state;

    if (!users) {
      return <InitialsPic size={size}>N/A</InitialsPic>;
    }

    const notMeUsers = (users as User[]).filter((user) => authInfo.user.id !== user.id);
    const me = (users as User[] | any).find((user) => authInfo.user.id === user.id);

    if ((notMeUsers.length > 1 || chatImageUrl) && !imageErrored) {
      return (
        // Group Chat
        <ProfilePicImg
          onError={this.onImageError}
          size={size}
          src={chatImageUrl ? chatImageUrl : '/assets/group-chat.svg'}
        />
      );
    }

    const singleUser = notMeUsers[0] || me;
    const url = pathOr('', ['avatar', 'url'], singleUser);
    // Single Chat
    if (url && !imageErrored) {
      return (
        <StyledBadge
          status={status === LOCKEDSTATUS ? undefined : singleUser?.workStatus?.value}
          variant="dot"
          data-testid="pic-with-url"
        >
          <ProfilePicImg onError={this.onImageError} src={url} size={size} />
        </StyledBadge>
      );
    }

    return (
      <StyledBadge
        status={status === LOCKEDSTATUS ? undefined : singleUser?.workStatus?.value}
        variant="dot"
        data-testid="pic-without-url"
      >
        <InitialsPic size={size} fontSize={fontSize}>
          {getUpperCaseInitials(singleUser)}
        </InitialsPic>
      </StyledBadge>
    );
  }

  private onImageError = () => this.setState({imageErrored: true});
}

const ProfilePicwithFeatureFlag = withLDConsumer()(ProfilePic);

export default (props: Props) => (
  <AuthContext.Consumer>
    {({authInfo}) => <ProfilePicwithFeatureFlag {...props} authInfo={authInfo} />}
  </AuthContext.Consumer>
);
