import React, {useState} from 'react';
import {Avatar, CircularProgress, ListItemAvatar, ListItemText} from '@material-ui/core';
import styled from 'styled-components';
import {getUpperCaseInitials} from 'src/utils/user';
import InfiniteScroll from 'react-infinite-scroller';
import {FetchPaginatedUsersQueryResponse, PaginatedUsers} from 'src/gql/v2/query/FetchPaginatedUsersQuery';
import {getOrganizationalUnitObject} from 'src/utils/organizationHelper/getOrganizationalUnitObject';
import {usePaginatedDataState} from 'src/pages/ContactsPage/ContactListSection/hooks/usePaginatedDataState';
import {PaginatedUsersType} from 'src/types/PaginatedTypes';
import {UserViewModel} from '../viewModels/UserViewModel';
import {SiteScopes} from 'src/data/repository/UserRepository';

const UserListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const UserItem = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 1em;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface Props {
  contactList: PaginatedUsersType[];
  fetchMorePaginatedUserData: any;
  paginatedUserData?: FetchPaginatedUsersQueryResponse;
  localSearchText: string;
  searchUserData: PaginatedUsers | null;
  setSearchUserData: React.Dispatch<React.SetStateAction<PaginatedUsers | null>>;
  isDoneRows: boolean;
  setIsDoneRows: React.Dispatch<React.SetStateAction<boolean>>;
  seenContinuationIds: string[];
  setSeenContinuationIds: React.Dispatch<React.SetStateAction<string[]>>;
  scopes: SiteScopes[];
}
const ContactListV2 = ({
  contactList,
  fetchMorePaginatedUserData,
  paginatedUserData,
  searchUserData,
  setSearchUserData,
  localSearchText,
  isDoneRows,
  setIsDoneRows,
  seenContinuationIds,
  setSeenContinuationIds,
  scopes,
}: Props) => {
  const {additionalRows, setAdditionalRows, isLoadingAdditionalData, setIsLoadingAdditionalData} =
    usePaginatedDataState();

  const newInvitedUserListData = [...contactList, ...(additionalRows || [])];

  const [continuationId, setContinuationId] = useState(
    paginatedUserData?.organizationalUnitQuery.organizationalUnit.paginatedMembersInScopes.continuationId,
  );

  const {getSearchUsers} = UserViewModel();

  const getMorePaginatedSearchData = async () => {
    const continuationId = searchUserData?.continuationId;

    if (!continuationId || seenContinuationIds.includes(continuationId)) {
      setIsDoneRows(true);
      return;
    }

    try {
      const result = await getSearchUsers({text: localSearchText, limit: 30, continuationId, scopes});

      if ('error' in result) {
        setIsDoneRows(true);
        return;
      } else {
        setSearchUserData({
          ...searchUserData,
          continuationId: result.continuationId,
          users: [...searchUserData.users, ...result.users],
        });

        if (result.continuationId === null) {
          setIsDoneRows(true);
        }
      }
    } finally {
      setSeenContinuationIds([...seenContinuationIds, continuationId]);
    }
  };

  const getMoreData = async () => {
    setIsLoadingAdditionalData(true);
    if (!continuationId || seenContinuationIds.includes(continuationId)) {
      setIsDoneRows(true);
      return;
    }

    try {
      await fetchMorePaginatedUserData({
        variables: {
          organizationalUnit: getOrganizationalUnitObject(),
          continuationId,
          direction: 'next',
        },
        updateQuery: (
          previousResult: FetchPaginatedUsersQueryResponse,
          {fetchMoreResult}: {fetchMoreResult: FetchPaginatedUsersQueryResponse},
        ) => {
          const newFetchedUsers = fetchMoreResult.organizationalUnitQuery.organizationalUnit.paginatedMembersInScopes;

          if (newFetchedUsers.continuationId === null) {
            setIsDoneRows(true);
          }

          setAdditionalRows([...additionalRows, ...newFetchedUsers.users]);
          setContinuationId(newFetchedUsers.continuationId);
        },
      });
    } finally {
      setSeenContinuationIds([...seenContinuationIds, continuationId]);
    }
  };

  return (
    <UserListWrapper>
      <div style={{maxHeight: `calc(100vh - 205px)`, overflow: 'auto'}} id="scrollableDiv">
        <InfiniteScroll
          hasMore={!isDoneRows}
          loadMore={localSearchText.length > 1 ? getMorePaginatedSearchData : getMoreData}
          initialLoad={false}
          useWindow={false}
        >
          {newInvitedUserListData.map((contact) => (
            <UserItem key={contact.id} onClick={() => window.routerHistory.push(`/contacts/${contact.id}`)}>
              <ListItemAvatar>
                {contact?.profilePic ? (
                  <Avatar src={contact?.profilePic.url} />
                ) : (
                  <Avatar>{getUpperCaseInitials(contact)}</Avatar>
                )}
              </ListItemAvatar>
              <ListItemText
                primary={`${contact?.firstname || ''} ${contact?.lastname || ''}`}
                secondary={contact?.role}
              />
            </UserItem>
          ))}
          {isLoadingAdditionalData && !isDoneRows && (
            <LoadingContainer>
              <CircularProgress />
            </LoadingContainer>
          )}
        </InfiniteScroll>
      </div>
    </UserListWrapper>
  );
};

export default ContactListV2;
