import React from 'react';
import appTheme from 'src/styles/theme';
import {FormikProps} from 'formik';
import InputLabel from '@material-ui/core/InputLabel';
import ErrorMessage, {StyledFormControl, StyledInputBase} from 'src/components/StepFormPartials';
import AnalyticsManager, {PAGE_VIEWS} from 'src/analytics/AnalyticsManager';

const JoinOrgStepZero = ({formikProps, isLoading, isJoinUsingInviteCode}) => {
  const {values, errors, touched, setFieldValue, isSubmitting, handleChange, handleBlur} =
    formikProps as FormikProps<any>;

  React.useEffect(() => {
    const urlSearch = window.location.search;
    if (urlSearch.indexOf('?') > -1 && urlSearch.includes('email')) {
      const emailAddress = urlSearch.substring(urlSearch.indexOf('=') + 1);
      setFieldValue('email', decodeURIComponent(emailAddress));

      window.history.replaceState({}, document.title, `${window.location.origin}/joinorganization`);
    }

    AnalyticsManager.recordPageVisited(PAGE_VIEWS.onboardingRequestEmailVerification);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasEmailError = touched.email && Boolean(errors.email);
  const hasInviteCodeError = touched.inviteCode && Boolean(errors.inviteCode);

  return (
    <div>
      <div>
        Input your {isJoinUsingInviteCode ? `organization code` : `institutional email`} for us to verify the
        organization you are part of.
      </div>
      <StyledFormControl disabled={isSubmitting || isLoading}>
        {isJoinUsingInviteCode ? (
          <React.Fragment>
            <InputLabel
              shrink
              htmlFor="inviteCode"
              style={hasInviteCodeError ? undefined : {color: appTheme.greyishBrown}}
              error={hasInviteCodeError}
            >
              Organization Code
            </InputLabel>
            <StyledInputBase
              fullWidth
              autoFocus
              id="inviteCode"
              name="inviteCode"
              placeholder="Enter organization code here"
              value={values.inviteCode}
              onBlur={handleBlur}
              onChange={handleChange}
              error={hasInviteCodeError}
            />
            <ErrorMessage name="inviteCode" />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <InputLabel
              shrink
              htmlFor="email"
              style={hasEmailError ? undefined : {color: appTheme.greyishBrown}}
              error={hasEmailError}
            >
              Email
            </InputLabel>
            <StyledInputBase
              fullWidth
              autoFocus
              id="email"
              name="email"
              placeholder="Enter email here"
              value={values.email}
              onBlur={handleBlur}
              onChange={handleChange}
              error={hasEmailError}
            />
            <ErrorMessage name="email" />
          </React.Fragment>
        )}
      </StyledFormControl>
    </div>
  );
};

export default JoinOrgStepZero;
