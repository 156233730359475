import React from 'react';
import {DepartmentScope, EscalationLadder, EscalationType} from 'src/types';

export interface ISelectionContext {
  selectedDepartment: DepartmentScope;
  selectedEscalationLadder: EscalationLadder;
}

export interface EscalationModalStepVariableContextInterface {
  escalatingMessageText: string;
  escalationType: EscalationType;
  selectionContext: ISelectionContext;
  setEscalationType: React.Dispatch<React.SetStateAction<EscalationType>>;
  setSelectionContext: React.Dispatch<React.SetStateAction<ISelectionContext>>;
}

export const EscalationModalStepVariableContext = React.createContext<EscalationModalStepVariableContextInterface>({
  escalatingMessageText: '',
  escalationType: null,
  selectionContext: null,
  setEscalationType: null,
  setSelectionContext: null,
});

interface Props {
  message: string;
  children?: React.ReactNode;
}

const EscalationModalStepProvider = ({message, children}: React.PropsWithChildren<Props>) => {
  const [escalationType, setEscalationType] = React.useState<EscalationType>(null);
  const [selectionContext, setSelectionContext] = React.useState<ISelectionContext>({
    selectedDepartment: null,
    selectedEscalationLadder: null,
  });

  return (
    <EscalationModalStepVariableContext.Provider
      value={{
        escalatingMessageText: message,
        escalationType,
        setEscalationType,
        selectionContext,
        setSelectionContext,
      }}
    >
      {children}
    </EscalationModalStepVariableContext.Provider>
  );
};

export default EscalationModalStepProvider;
