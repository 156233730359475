import store from 'src/redux';
import {actions} from 'src/redux/actions/messages';
import {Message, MessageType} from 'src/types';

export default function removeOptimisticMessage(newMessage: Message, chatId: string) {
  const {optimisticMessages} = store.getState().messages;

  let optimisticMessagesArray = optimisticMessages[chatId] as Message[];

  if (optimisticMessagesArray) {
    const firstOccurrenceMessage = optimisticMessagesArray.find((msg) => {
      switch (msg.type as MessageType) {
        case 'image': // note: image type is depercated, checks for backward compatible reason
          return newMessage.attachment.fileName === msg.attachment.fileName;
        case 'attachment':
          return newMessage.attachment.fileName === msg.attachment.fileName;
        case 'consult': // compare json ?
          return (
            newMessage.data.details === msg.data.details &&
            newMessage.data.firstname === msg.data.firstname &&
            newMessage.data.lastname === msg.data.lastname &&
            newMessage.data.mrn === msg.data.mrn
          );
        case 'text':
          return newMessage.message === msg.message;
        default:
          return newMessage.message === msg.message;
      }
    });

    if (firstOccurrenceMessage) {
      store.dispatch(actions.removeOptimisticMessage(firstOccurrenceMessage.id, chatId));
    }
  }
}
