import React from 'react';
import {DEFAULT_MESSAGES_FETCHED} from 'src/constants/chats';
import {Chat, GetSearchMessageInChatResult, Message, MessageInChat} from 'src/types';
import InfiniteScroll from 'react-infinite-scroller';
import LoadingDiv, {SmallLoader} from 'src/components/LoadingDiv';
import styled from 'styled-components';
import MessageBodyUpdateReceiptRequestProvider, {
  UpdateReceiptContext,
} from 'src/pages/MessengerPage/messenger/messages-layout/UpdateReceiptRequestProvider';
import {
  useFetchMoreRequests,
  usePullMessagesInterval,
} from 'src/pages/MessengerPage/messenger/messages-layout/body-section/hooks';
import MessagesListViewController from 'src/pages/MessengerPage/messenger/messages-layout/body-section/MessagesListViewController';
import MessagesBodyFabController from 'src/pages/MessengerPage/messenger/messages-layout/body-section/MessagesBodyFabViewController';
import throttle from 'lodash.throttle';
import {ApolloQueryResult} from 'apollo-client';
import {
  DEBOUNCE_TIME,
  DIRECTION_NEXT,
  DIRECTION_PREVIOUS,
  DIRECTION_BOTH,
  INDIVIDUAL_MESSAGE,
  SEARCH_MESSAGE,
  NEXT_SEARCH,
  DEFAULT_MESSAGE_LOAD_LIMIT,
  SCROLL_DOWN,
  SCROLL_UP,
} from 'src/constants/messageTypes';
import debounce from 'lodash/debounce';
import GetSearchMessageInChat from 'src/gql/query/GetSearchMessageInChat';
import client from 'src/apollo';
import getMessages from 'src/utils/messengerHelper/getMessagesFromChat';
import GetChatQuery from 'src/gql/query/GetChatQuery';
import {StyledIsearchFab, StyledJump} from 'src/styles/styled-components/StyledMaterialComponents';
import {formatSearchPayload, handleSearchMixpanel} from 'src/utils/messengerHelper/searchChat';
import SearchMessageContainer from 'src/pages/MessengerPage/messenger/messages-layout/SearchMessageContainer';
import {useStateWithCallback} from 'src/utils/useStateWithCallback';

const Container = styled.div`
  flex: 1;
  padding: 1.5em 2em;
  overflow-y: auto;
`;

const LoaderMessage = styled(StyledIsearchFab)`
  position: absolute !important;
  width: fit-content !important;
  height: fit-content !important;
  padding-right: 24px !important;
  left: 40% !important;
  align-items: center !important;
  top: 36% !important;
  z-index: 1 !important;
`;

const MessageContainer = styled.div`
  width: 45px !important;
  height: 45px !important;
`;

interface Props {
  chatId: string;
  isSingleChat: boolean;
  fetchMore: any;
  refetch: (variables?: Record<string, any>) => Promise<ApolloQueryResult<any>>;
  messages: Message[];
  isSearch: boolean;
  setIsSearch: (isSearch) => void;
  handleIsSearch: (isVisible: boolean) => void;
  jumpToMessage: MessageInChat;
  isSideSearchActive: boolean;
  cachedData: any;
  handleJumpToLatestMessageOnSearch: (searchText: string) => void;
  resetSearchChat: boolean;
  setResetSearchChat: (isResetSearch: boolean) => void;
  lastMessage: any;
  lastUnreadMessage: any;
  searchThroughChat: boolean;
  handleIsJumpToMessageEnable: (isJumpToMessageEnable: boolean) => void;
}

const MessageBodyViewController = ({
  chatId,
  fetchMore,
  refetch,
  messages,
  isSearch,
  isSingleChat,
  setIsSearch,
  handleIsSearch,
  jumpToMessage,
  isSideSearchActive,
  cachedData,
  handleJumpToLatestMessageOnSearch,
  resetSearchChat,
  setResetSearchChat,
  lastMessage,
  lastUnreadMessage,
  searchThroughChat,
  handleIsJumpToMessageEnable,
}: Props) => {
  const [searchText, setSearchText] = React.useState('');
  const [searchMessageData, setSearchMessageData] = React.useState([]);
  const [totalSearchMessages, setTotalSearchMessages] = React.useState(0);
  const [searchMessage, setSearchMessage] = React.useState(null);
  const [position, setPosition] = React.useState(0);
  const [directionDisable, setDirectionDisable] = React.useState(DIRECTION_BOTH);
  const [isLoadingMessage, setIsLoadingMessage] = React.useState(false);
  const [isLoadingSearchMessage, setIsLoadingSearchMessage] = React.useState(false);
  const [messageContinuationId, setMessageContinuationId] = React.useState(null);
  const [isBottomLoading, setIsBottomLoading] = React.useState(false);
  const [scrollCount, setScrollCount] = useStateWithCallback(0);

  const {fetchMoreRequest, potentiallyMoreMessageToFetch} = useFetchMoreRequests({
    chatId,
    fetchMore,
    messages,
  });

  const {handleUpdateAllReadReceipts} = React.useContext(UpdateReceiptContext);

  const scrollRef = React.useRef(null);
  const isScrollbarAtBottom = React.useRef(true);
  const searchLastMessageId = React.useRef(null);
  const currentMessages = React.useRef(messages);
  const isApiCallDisabled = React.useRef(false);
  const directionPosition = React.useRef(null);

  // BUG: this is a temp fix; investigate why inactivity randomly results in chat not sync with chatList's last message
  usePullMessagesInterval({
    refetch,
    chatId,
    lastMessageId: messages[0] ? messages[0].id : null,
    isSearch,
    jumpToMessage,
  });

  const potentiallyMoreMessages = messages.length >= DEFAULT_MESSAGES_FETCHED;
  const hasMore = potentiallyMoreMessages && potentiallyMoreMessageToFetch;

  /**
   * initial mount effects
   */
  React.useLayoutEffect(() => {
    // automatically scroll to bottom when first entering the chat
    scrollToBottom();

    // if messages does not fill up entire height, call fetchMore
    if (potentiallyMoreMessages && !isSaturated()) {
      fetchMoreRequest()
        .then(() => {
          scrollToBottom();
        })
        .catch((e) => {
          console.error('fetch more messages failed', e);
        });
    }

    // when regain focus, try update all read receipts
    if (window.document.hasFocus()) {
      handleUpdateAllReadReceipts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useLayoutEffect(() => {
    const scrollContainer = scrollRef.current;
    const handleScroll = () => {
      let scrollYBottom = scrollContainer.scrollHeight - scrollContainer.scrollTop - scrollContainer.clientHeight;
      if (scrollYBottom > 100) {
        isScrollbarAtBottom.current = false;
      } else {
        isScrollbarAtBottom.current = true;
      }
    };
    scrollContainer.addEventListener('scroll', throttle(handleScroll, 300));
    return () => scrollContainer.removeEventListener('scroll', throttle(handleScroll, 300));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useLayoutEffect(() => {
    const firstMessage: Message = messages[0];
    if ((firstMessage && firstMessage.isOptimistic) || isScrollbarAtBottom.current) {
      scrollToBottom();
    }

    const handleWindowGainFocus = () => {
      handleUpdateAllReadReceipts();
    };

    window.addEventListener('focus', handleWindowGainFocus);
    return () => window.removeEventListener('focus', handleWindowGainFocus);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  React.useLayoutEffect(() => {
    const scrollContainer = scrollRef.current;
    const handleScrollToFetchMessages = async () => {
      if ((isSearch && searchText) || jumpToMessage) {
        let scrollYBottom = scrollContainer.scrollHeight - scrollContainer.scrollTop - scrollContainer.clientHeight;
        if (scrollYBottom <= 1 && !isBottomLoading) {
          if (!isApiCallDisabled.current) {
            await setScrollCount(scrollCount + 1, async (prevValue, newValue) => {
              if (prevValue >= newValue) {
                await setScrollCount(0);
                return;
              }
              if (newValue === 1) {
                const bottomInnerLoader = document.getElementById('bottomInnerLoader');
                setIsBottomLoading(true);
                await setScrollCount(newValue);
                setIsLoadingMessage(true);
                handleIsJumpToMessageEnable(false);
                const chatQuery = await getMessagesFromCache();
                if (chatQuery) {
                  const chatLastMessageId = chatQuery.chat.lastMessage.id;
                  const allMessages = getMessages(chatQuery.chat);
                  currentMessages.current = allMessages;
                  const lastMessageId = allMessages[0].id;
                  if (chatLastMessageId !== allMessages[0].id) {
                    setDirectionDisable(DIRECTION_BOTH);
                    if (bottomInnerLoader) {
                      bottomInnerLoader.style.display = 'block';
                    }
                    isApiCallDisabled.current = true;
                    await fetchMoreRequest(lastMessageId, SCROLL_DOWN).then(async (response) => {
                      if (response && response['status']) {
                        setDirectionDisable(directionPosition.current);
                        setIsLoadingMessage(false);
                        handleIsJumpToMessageEnable(true);
                        searchLastMessageId.current = null;
                        setTimeout(() => {
                          isApiCallDisabled.current = false;
                        }, 500);
                        setTimeout(() => {
                          setIsBottomLoading(false);
                          if (bottomInnerLoader) {
                            bottomInnerLoader.style.display = 'none';
                          }
                        }, 1000);
                        return false;
                      }
                    });
                  } else {
                    await gapScrollToBottom(20);
                    setTimeout(() => {
                      setIsLoadingMessage(false);
                      handleIsJumpToMessageEnable(true);
                      setDirectionDisable(directionPosition.current);
                      setIsBottomLoading(false);
                    }, 500);
                  }
                }
              }
            });
          } else {
            await gapScrollToBottom(10);
            setDirectionDisable(directionPosition.current);
            setIsBottomLoading(false);
          }
        } else {
          setDirectionDisable(directionPosition.current);
        }
      }
    };
    scrollContainer.addEventListener('scroll', throttle(handleScrollToFetchMessages, 1000));
    return () => scrollContainer.removeEventListener('scroll', throttle(handleScrollToFetchMessages, 1000));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages, isSearch, searchText, jumpToMessage]);

  const loadMore = async () => {
    if ((isSearch && searchText) || jumpToMessage) {
      if (!isApiCallDisabled.current) {
        isApiCallDisabled.current = true;
        setIsLoadingMessage(true);
        handleIsJumpToMessageEnable(false);
        const chatQuery = await getMessagesFromCache();
        if (chatQuery) {
          const allMessages = getMessages(chatQuery.chat);
          const lastMessageId = allMessages[allMessages.length - 1].id;
          await fetchMoreRequest(lastMessageId, SCROLL_UP).then(async (response) => {
            if (response) {
              searchLastMessageId.current = null;
              isApiCallDisabled.current = false;
            }
          });
          setIsLoadingMessage(false);
          handleIsJumpToMessageEnable(true);
        }
      } else {
        const scrollContainer = scrollRef.current;
        scrollContainer.scrollTop = 10;
      }
    } else {
      await fetchMoreRequest();
    }
  };

  const gapScrollToBottom = (offset) => {
    const scrollContainer = scrollRef.current;
    const lastMessageElementId = currentMessages.current[0].id;
    const bottomLoader = document.getElementById('bottomLoader');
    if (bottomLoader) {
      const bottomLoaderHeight = bottomLoader.clientHeight + offset;
      const scrollOffset = bottomLoaderHeight;
      const lastMessageElement = document.getElementById(lastMessageElementId);
      if (lastMessageElement) {
        const scrollToPosition =
          lastMessageElement.offsetTop - scrollContainer.clientHeight + lastMessageElement.clientHeight;
        scrollContainer.scrollTop = scrollToPosition + scrollOffset;
      }
    }
  };

  function isSaturated() {
    if (scrollRef && scrollRef.current) {
      return scrollRef.current.scrollHeight > scrollRef.current.clientHeight;
    }
    return false;
  }

  function scrollToBottom(bottomSpace = null) {
    if (scrollRef) {
      const node = scrollRef.current;
      if (node) {
        const {scrollHeight, clientHeight: height} = node;
        const maxScrollTop = scrollHeight - height;
        node.scrollTop = maxScrollTop > 0 ? (bottomSpace ? maxScrollTop - 20 : maxScrollTop) : 0;
      }
    }
  }

  React.useEffect(() => {
    if (jumpToMessage) {
      const handleIndividualMessage = async () => {
        await searchIndividualMessage(jumpToMessage);
      };

      handleIndividualMessage();
    }
  }, [jumpToMessage]);

  React.useEffect(() => {
    if (!isSearch) {
      setSearchText('');
    }
  }, [isSearch]);

  React.useEffect(() => {
    setIsSearch(false);
  }, [chatId]);

  React.useEffect(() => {
    if (!searchThroughChat) {
      handleCancelSearch();
    }
  }, [searchThroughChat]);

  React.useEffect(() => {
    if (resetSearchChat) {
      let isMounted = true;
      const clearSearchOnNewMessage = async () => {
        if (isMounted) {
          setIsLoadingMessage(true);
          refetch().then(async () => {
            await handleCancelSearch();
            setIsLoadingMessage(false);
            scrollToBottom();
          });
        }
      };
      clearSearchOnNewMessage();
      return () => {
        isMounted = false;
      };
    }
  }, [resetSearchChat]);

  const getMessagesFromCache = () => {
    return client.readQuery({
      query: GetChatQuery,
      variables: {chatId},
    }) as {chat: Chat};
  };

  const loadMoreMessages = async (functionName, lastMessageId, value) => {
    let direction = null;
    if (functionName === NEXT_SEARCH) {
      direction = value;
    }

    await fetchMoreRequest(lastMessageId, null, direction, functionName).then(async (response) => {
      if (response && response['status']) {
        switch (functionName) {
          case INDIVIDUAL_MESSAGE:
            await searchIndividualMessage(value);
            break;
          case SEARCH_MESSAGE:
            await fetchSearchMessage(value);
            break;
          case NEXT_SEARCH:
            await handleNextSearch(value);
            break;
          default:
            break;
        }
      }
    });
  };

  const searchIndividualMessage = async (jumpToMessage: MessageInChat) => {
    isApiCallDisabled.current = true;
    const messageId = jumpToMessage.message.id;
    const searchMessageId = messageId + 1;
    const chatQuery = await getMessagesFromCache();
    if (chatQuery) {
      const allMessages = getMessages(chatQuery.chat);
      currentMessages.current = allMessages;
      const isExist = allMessages.find((message) => {
        return message.id === messageId;
      });
      if (isExist) {
        setIsBottomLoading(true);
        searchLastMessageId.current = jumpToMessage.message;
        setSearchMessage(jumpToMessage);
        await searchMessageScroll(jumpToMessage.message).then((response) => {
          if (response === 'done') {
            setTimeout(() => {
              isApiCallDisabled.current = false;
              setIsBottomLoading(false);
            }, 500);
          }
        });
        setIsLoadingSearchMessage(false);
        handleIsJumpToMessageEnable(true);
        const scrollContainer = scrollRef.current;
        var hasVerticalScrollbar = scrollContainer.scrollHeight > scrollContainer.clientHeight;
        if (currentMessages.current.length > 0 && !hasVerticalScrollbar) {
          var elem = document.getElementById('scrollContainer');
          var topOffset = parseInt(window.getComputedStyle(elem).paddingTop);
          await loadBottomMessages();
          scrollContainer.scrollTop = topOffset;
        }
      } else {
        handleIsJumpToMessageEnable(false);
        setIsLoadingSearchMessage(true);
        await loadMoreMessages(INDIVIDUAL_MESSAGE, searchMessageId, jumpToMessage);
      }
    }
  };

  const loadBottomMessages = async () => {
    const lastMessageId = currentMessages.current[0].id;
    setIsBottomLoading(true);
    handleIsJumpToMessageEnable(false);
    const bottomInnerLoader = document.getElementById('bottomInnerLoader');
    if (bottomInnerLoader) {
      bottomInnerLoader.style.display = 'block';
    }
    await fetchMoreRequest(lastMessageId, SCROLL_DOWN).then(async (response) => {
      if (response && response['status']) {
        handleIsJumpToMessageEnable(true);
        setTimeout(() => {
          setIsBottomLoading(false);
          if (bottomInnerLoader) {
            bottomInnerLoader.style.display = 'none';
          }
        }, 1000);
        return false;
      }
    });
  };

  const handleChatSearch = async (event: React.ChangeEvent<any>) => {
    const searchText = event.target.value;
    if (searchText) {
      setSearchText(searchText);
      const searchChatText = await formatSearchPayload(searchText);
      if (searchChatText) {
        searchMessageInChat(searchChatText);
        handleSearchMixpanel(searchChatText);
      }
    } else {
      handleSearchClear();
    }
  };

  const handleKeyPress = async (e) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      const searchText = e.target.value;
      e.preventDefault();
      e.target.blur();

      const searchChatText = await formatSearchPayload(searchText);
      if (searchChatText) {
        searchMessageInChat(searchChatText);
        handleSearchMixpanel(searchChatText);
      }
    }
  };

  const handleSearchClear = () => {
    setSearchText('');
    setSearchMessageData([]);
    setSearchMessage(null);
    setPosition(0);
    setTotalSearchMessages(0);
    setDirectionDisable(DIRECTION_BOTH);
    setIsLoadingMessage(false);
  };

  const searchMessageInChat = React.useCallback(
    debounce(async (searchValue) => {
      await fetchSearchInMessage(searchValue);
    }, DEBOUNCE_TIME),
    [],
  );

  const fetchSearchMessageRecords = async (searchValue: string, continuationId: number = null) => {
    let variables = {
      text: searchValue,
      chatId: chatId,
      direction: 'previous',
    };
    if (continuationId) variables['continuationId'] = continuationId;
    const response = await client.query<GetSearchMessageInChatResult>({
      query: GetSearchMessageInChat,
      variables,
    });

    return response?.data?.searchQuery.searchMessagesInChat;
  };

  const fetchSearchInMessage = async (searchValue: string) => {
    setSearchMessageData([]);
    setSearchMessage(null);
    setPosition(0);
    setTotalSearchMessages(0);
    setDirectionDisable(DIRECTION_BOTH);
    await setIsLoadingSearchMessage(true);
    const data = await fetchSearchMessageRecords(searchValue);
    const messages = data?.messages;
    if (messages.length > 0) {
      fetchSearchMessage(data);
    } else {
      await setIsLoadingSearchMessage(false);
    }
    handleJumpToLatestMessageOnSearch(searchValue);
  };

  const fetchSearchMessage = async (data) => {
    try {
      isApiCallDisabled.current = true;
      const searchMessages = data?.messages;
      const continuationId = data?.continuationId;
      const currentSearchMessage = searchMessages[0];
      const messageId = currentSearchMessage.message.id;
      const searchMessageId = messageId + 1;
      directionPosition.current = searchMessages.length === 1 ? DIRECTION_BOTH : DIRECTION_NEXT;
      const isExist = document.getElementById(messageId);
      if (isExist) {
        setIsBottomLoading(true);
        setTotalSearchMessages(data.totalResultsCount);
        setPosition(data.totalResultsCount);
        setSearchMessageData(searchMessages);
        setSearchMessage(currentSearchMessage);
        setMessageContinuationId(continuationId);
        searchMessageScroll(searchMessages[0].message).then((response) => {
          if (response === 'done') {
            setTimeout(() => {
              isApiCallDisabled.current = false;
              setIsBottomLoading(false);
            }, 500);
          }
        });
        await setIsLoadingSearchMessage(false);
      } else {
        await setIsLoadingSearchMessage(true);
        await loadMoreMessages(SEARCH_MESSAGE, searchMessageId, data);
      }

      if (searchMessages.length === 1) {
        setDirectionDisable(DIRECTION_BOTH);
      } else {
        setDirectionDisable(DIRECTION_NEXT);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleNextSearch = async (action: string) => {
    try {
      isApiCallDisabled.current = true;
      const currentSearchMessage = jumpToSearchMessage(action);
      const currentPosition = action === DIRECTION_NEXT ? position + 1 : position - 1;
      const messageId = currentSearchMessage?.message.id;
      if (messageId) {
        const isExist = document.getElementById(messageId);
        if (isExist) {
          setIsBottomLoading(true);
          if (messageId === messageContinuationId) {
            await setIsLoadingSearchMessage(true);
            await loadMoreSearchMessages();
            await setIsLoadingSearchMessage(false);
          }
          searchLastMessageId.current = currentSearchMessage.message;
          setSearchMessage(currentSearchMessage);
          if (currentPosition > 0 && currentPosition <= totalSearchMessages) {
            setPosition(currentPosition);
          }
          await handleDirectionDisable(currentPosition);
          setIsLoadingSearchMessage(false);
          searchMessageScroll(currentSearchMessage.message).then(async (response) => {
            if (response === 'done') {
              setTimeout(() => {
                isApiCallDisabled.current = false;
                setIsBottomLoading(false);
              }, 500);
            }
          });
        } else {
          const searchMessageId = action === DIRECTION_NEXT ? messageId + DEFAULT_MESSAGE_LOAD_LIMIT : messageId + 1;
          setIsLoadingSearchMessage(true);
          await loadMoreMessages(NEXT_SEARCH, searchMessageId, action);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const loadMoreSearchMessages = async () => {
    const searchChatText = await formatSearchPayload(searchText);
    const data = await fetchSearchMessageRecords(searchChatText, messageContinuationId);
    const {messages, continuationId} = data;
    const updatedMessages = [...searchMessageData, ...messages];
    setSearchMessageData(updatedMessages);
    setMessageContinuationId(continuationId);
  };

  const handleDirectionDisable = (currentPosition: number) => {
    switch (currentPosition) {
      case 1:
        directionPosition.current = DIRECTION_PREVIOUS;
        setDirectionDisable(DIRECTION_PREVIOUS);
        break;
      case totalSearchMessages:
        directionPosition.current = DIRECTION_NEXT;
        setDirectionDisable(DIRECTION_NEXT);
        break;
      default:
        directionPosition.current = '';
        setDirectionDisable('');
    }
  };

  const searchMessageScroll = (message, noAnimation = true) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        let activeMessage = document.getElementById(message.id);
        if (activeMessage) {
          activeMessage.style.animation = '';
          activeMessage.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'nearest',
          });

          if (noAnimation) {
            activeMessage.style.animation = 'wobble 1s';
          }
        }
        resolve('done');
      }, 500);
    });
  };

  const jumpToSearchMessage = (action: string) => {
    const indexForSelectedUser = searchMessageData.findIndex(
      (message) => message.message.id === searchMessage.message.id,
    );

    const jumpTo = action === DIRECTION_NEXT ? indexForSelectedUser - 1 : indexForSelectedUser + 1;

    return searchMessageData[jumpTo];
  };

  const handleCancelSearch = () => {
    setSearchText('');
    setSearchMessageData([]);
    setSearchMessage(null);
    setIsSearch(false);
    handleIsSearch(false);
    setPosition(0);
    setTotalSearchMessages(0);
    setIsLoadingMessage(false);
    setResetSearchChat(false);
  };

  return (
    <>
      {searchThroughChat && isSearch && (
        <SearchMessageContainer
          searchText={searchText}
          position={position}
          totalSearchMessages={totalSearchMessages}
          isLoadingSearchMessage={isLoadingSearchMessage}
          isLoadingMessage={isLoadingMessage}
          directionDisable={directionDisable}
          handleKeyPress={handleKeyPress}
          handleChatSearch={handleChatSearch}
          handleSearchClear={handleSearchClear}
          handleNextSearch={handleNextSearch}
          handleCancelSearch={handleCancelSearch}
        />
      )}
      <Container ref={scrollRef} id="scrollContainer">
        <MessagesBodyFabController
          messages={messages}
          scrollToBottom={scrollToBottom}
          jumpToMessage={jumpToMessage}
          isSearch={isSearch}
          searchMessageData={searchMessageData}
          setIsLoadingMessage={setIsLoadingMessage}
          refetch={refetch}
          lastUnreadMessage={lastUnreadMessage}
        />
        <InfiniteScroll
          hasMore={hasMore}
          loadMore={loadMore}
          loader={
            (isSearch && searchText) || jumpToMessage ? (
              isLoadingMessage ? (
                <LoadingDiv key={`loader${0}`} />
              ) : isLoadingSearchMessage ? (
                <LoaderMessage
                  key={`loaderMessage${0}`}
                  color="primary"
                  aria-label="loading..."
                  disableTouchRipple
                  size="medium"
                >
                  <StyledJump> Loading... </StyledJump> <SmallLoader />
                </LoaderMessage>
              ) : (
                <MessageContainer key={`topLoadHolder${0}`} />
              )
            ) : hasMore && loadMore ? (
              <LoadingDiv key={`loader${0}`} />
            ) : (
              <div key={`loadHolder${0}`} />
            )
          }
          isReverse={true}
          initialLoad={false}
          useWindow={false}
          threshold={(isSearch && searchText) || jumpToMessage ? 1 : 200}
        >
          <MessagesListViewController
            chatId={chatId}
            isSingleChat={isSingleChat}
            messages={messages}
            searchMessage={searchMessage}
            searchMessageData={searchMessageData}
            jumpToMessage={jumpToMessage}
            isSideSearchActive={isSideSearchActive}
            searchText={searchText}
            scrollToBottom={scrollToBottom}
            isSearch={isSearch}
          />
        </InfiniteScroll>
      </Container>
    </>
  );
};
export default (props: Props) => (
  <MessageBodyUpdateReceiptRequestProvider chatId={props.chatId}>
    <MessageBodyViewController {...props} />
  </MessageBodyUpdateReceiptRequestProvider>
);
