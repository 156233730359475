import {Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import React from 'react';
import AlertModal from 'src/pages/MessengerPage/messenger/messages-layout/message-template/AlertModal';
import {muteChat, unMuteChat} from 'src/pages/MessengerPage/messenger/MuteChatHelper';

const useStyles = makeStyles((_) => ({
  modalContent: {
    marginBottom: '24px',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
  },
}));

interface Props {
  chatId: string;
  isOpen: boolean;
  handleClose: (val: boolean) => void;
  isChatMuted: boolean;
}

export const MuteNotificationModal = ({chatId, isOpen, handleClose, isChatMuted}: Props) => {
  const classes = useStyles();

  return (
    <AlertModal
      closeAlertModal={handleClose}
      width="xs"
      title={isChatMuted ? `Turn ON Regular Notifications?` : `Turn OFF Regular Notifications`}
      titleFontSize="24px"
      isAlertModalVisible={isOpen}
      showCloseButton={false}
      alertModalButtons={[
        {
          type: 'secondary',
          buttonLabel: 'Nevermind',
          onClickHandler: () => handleClose(false),
          id: 'nevermind-btn',
        },
        {
          type: 'primary',
          buttonLabel: 'Yes',
          onClickHandler: () => {
            isChatMuted ? unMuteChat(chatId) : muteChat(chatId);
            handleClose(false);
          },
          id: 'yes-btn',
        },
      ]}
      modalContent={
        <Typography classes={{root: classes.modalContent}}>
          {isChatMuted
            ? `By unmuting notifications you will recieve notifications for this chat on any devices you're logged into`
            : `By muting notifications you will no longer receive regular notifications for this chat on any devices you're
           logged into. Notifications for Urgent & STAT messages will still go through`}
        </Typography>
      }
    />
  );
};
