import React, {CSSProperties} from 'react';
import Select, {createFilter} from 'react-select';
// import { useTheme } from '@material-ui/core/styles';
import {ValueType} from 'react-select/src/types';
import overriddenComponents, {useStyles} from 'src/pages/MessengerPage/auto-select/ReactSelectMaterialUI';
import {UserSelectOptionType} from 'src/types';
import {
  NoCircleOption as Option,
  AutoHeightMenuList as MenuList,
} from 'src/pages/MessengerPage/auto-select/ReactSelectSharedComponents';

/**
 * Search by label and role
 * display by name(@username)
 */
const stringify = (option) => {
  return option.label + option.data.role;
};
const filterOption = createFilter({ignoreCase: true, stringify});
const getOptionLabel = (option) => `${option.label} (@${option.username})`;

const AddAlternateContactSelect = ({onClickColleague, suggestions, proxyUserProps}) => {
  const classes = useStyles({});
  // const theme = useTheme();
  const [selectedColleague, setSelectedColleague] = React.useState<ValueType<UserSelectOptionType>>([proxyUserProps]);

  function handleChangeSingle(value: ValueType<UserSelectOptionType>) {
    onClickColleague(value);
    setSelectedColleague(value);
  }

  const selectStyles = {
    input: (base: CSSProperties) => ({
      ...base,
      color: 'darkgrey !important',
      '& input': {
        font: 'inherit',
      },
    }),
    menuPortal: (base) => ({...base, zIndex: 9999}),
  };

  return (
    // tslint:disable jsx-no-lambda
    <div className={classes.root} onClick={(e) => e.stopPropagation()}>
      <Select
        classes={classes}
        styles={selectStyles}
        inputId="react-select-single"
        TextFieldProps={{
          InputLabelProps: {
            htmlFor: 'react-select-single',
            shrink: true,
          },
        }}
        placeholder={'Select alternate contact'}
        options={suggestions}
        menuPortalTarget={document.body}
        components={{
          ...overriddenComponents,
          Option,
          MenuList,
        }}
        isClearable={true}
        getOptionLabel={getOptionLabel}
        value={selectedColleague}
        onChange={handleChangeSingle}
        filterOption={filterOption}
      />
    </div>
  );
};

export default AddAlternateContactSelect;
