import * as React from 'react';
import styled from 'styled-components';
import Exclamation from 'src/svgs/Exclamation';
import UrgentPriorityIcon from 'src/svgs/UrgentPriorityIcon';
import StatPriorityIcon from 'src/svgs/StatPriorityIcon';
import theme from 'src/styles/theme';
import FileSelector from 'src/components/FileSelector';
import Consult from 'src/svgs/Consult';
import PaperClipIcon from 'src/svgs/PaperClipIcon';
import {PriorityType, MessagePriorityViewSize} from 'src/types';
import MessagePriority from 'src/components/MessagePriority';
import {Popover} from '@material-ui/core';
interface StyledIconButtonProps {
  onClick: () => void;
  color: string;
  strokefill: string;
  priority: number;
}

const iconWidth = '40px';

const StyledExclamation = styled(Exclamation)<StyledIconButtonProps>`
  width: ${iconWidth};
  &:hover circle {
    fill: grey;
  }
`;

const FileUploadButtonWrapper = styled.label`
  position: relative;
  overflow: hidden;
  display: inline-block;
  input[type='file'] {
    cursor: pointer;
    width: ${iconWidth};
    height: ${iconWidth};
    position: absolute;
    left: 5px;
    top: 0;
    opacity: 0;
  }
  &:hover div {
    background-color: grey !important;
    cursor: pointer;
  }
`;

const StyledConsult = styled(Consult)`
  cursor: pointer;
  width: ${iconWidth};
  &:hover rect {
    fill: grey;
  }
`;

const FileAttachIconWrapper = styled.div`
  border-radius: 50%;
  background-color: ${(props) => props.theme.messageActionIconGrey};
  width: ${iconWidth};
  height: ${iconWidth};
  margin-left: 5px;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  svg {
    width: 22px;
    height: 22px;
    transform: rotate(-45deg);
    color: white;
  }
`;

const Buttons = styled.div`
  display: flex;
  justify-content: space-around;
  display: flex;
  min-width: 120px;
`;

const IconButtonLabel = styled.label`
  user-select: none;
  cursor: pointer;
`;

const PopoverWrapper = styled.div`
  padding: 5px;
`;

const PopoverLabel = styled.div`
  text-align: center;
  padding: 10px;
  font-weight: bold;
`;

interface Props {
  messagePriority: PriorityType;
  onExclamationButtonClick: (priority) => void;
  showConsultMessageModal: () => void;
  handleFileUpload: (files: File[]) => Promise<void>;
}

const MessageInputOptions = ({
  messagePriority,
  onExclamationButtonClick,
  showConsultMessageModal,
  handleFileUpload,
}: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [priorityIndex, setPriorityIndex] = React.useState(2);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePrioritySelection = (index: number, event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setPriorityIndex(index);
    handleClose();
    onExclamationButtonClick(index);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <Buttons>
        <label title="Send Consult Message" onClick={showConsultMessageModal}>
          <StyledConsult />
        </label>

        <FileUploadButtonWrapper title="Attach File or Image">
          <FileAttachIconWrapper>
            <PaperClipIcon />
          </FileAttachIconWrapper>
          <FileSelector onSelectFiles={handleFileUpload} />
        </FileUploadButtonWrapper>
        <IconButtonLabel aria-describedby={id} title="Send Stat Message" onClick={handleClick}>
          {messagePriority === PriorityType.stat ? (
            <StatPriorityIcon />
          ) : messagePriority === PriorityType.urgent ? (
            <UrgentPriorityIcon />
          ) : (
            <StyledExclamation color={theme.iconGrey} strokefill={'white'} />
          )}
        </IconButtonLabel>
      </Buttons>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <PopoverWrapper>
          <PopoverLabel>Send Message as...</PopoverLabel>
          <MessagePriority
            size={MessagePriorityViewSize.small}
            messagePriority={messagePriority}
            prioritySelectionHandler={handlePrioritySelection}
          />
        </PopoverWrapper>
      </Popover>
    </>
  );
};

export default MessageInputOptions;
