import React, {useState} from 'react';
import {Box, IconButton, Icon, Typography, makeStyles, Theme} from '@material-ui/core';
import {Closemark} from 'src/svgs/Closemark';
import styled from 'styled-components';
import AlertTriangleIcon from 'src/svgs/AlertTriangleIcon';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '28px',
  },
  backButton: {
    color: 'black',
    width: 40,
    height: 40,
    position: 'absolute',
  },
  title: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: '21px',
    fontWeight: 700,
    color: '#222222',
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '16px',
  },
  description: {
    textAlign: 'center',
    fontFamily: "'Open Sans', sans-serif",
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '24px',
    color: '#4A4A4A',
  },
  descriptionContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  descriptionItems: {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #D8D8D8',
    borderRadius: '40px',
    width: 'fit-content',
    height: '40px',
    padding: '10px 12px 10px 12px',
    gap: '8px',
  },
  avatar: {
    width: '27px',
    height: '27px',
    backgroundColor: '#BCBCBC',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
  },
}));

const WarningWrapper = styled.div`
  margin: 24px auto;
  display: flex;
  text-align: left;
  padding: 1em 0.5em;
  opacity: 0.8;
  border-radius: 3px;
  background-color: rgba(255, 62, 85, 0.2);
`;

const WarningIconWrapper = styled.div`
  margin-right: 1em;
  margin-left: 0.5em;
  padding-top: 4px;
  svg {
    width: 32px;
    height: 32px;
    color: ${(props) => props.theme.watermelon};
  }
`;

const WarningDetailTitle = styled.div`
  font-size: 14px;
  line-height: 1.5em;
  font-weight: bold;
  color: ${(props) => props.theme.blackFontColor};
  a {
    color: ${(props) => props.theme.chatTeal};
    &:hover {
      filter: opacity(0.8);
    }
  }
`;

const WarningDetailSubInfo = styled(WarningDetailTitle)`
  font-weight: normal;
  font-size: 12px;
`;

interface IStepHeaderProps {
  title: string;
  description?: string | JSX.Element;
  onBackButtonClick?: () => void;
  isAccountLocked: boolean;
  loginAttempts: number;
}

const NewLoginFormPasswordHeader: React.FC<IStepHeaderProps> = ({
  isAccountLocked,
  loginAttempts,
  title,
  description,
  onBackButtonClick,
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Box display="flex" position="relative" flexGrow={1}>
        <IconButton className={classes.backButton} onClick={onBackButtonClick}>
          <Icon>arrow_back</Icon>
        </IconButton>

        <Typography variant="h5" className={classes.title}>
          {title}
        </Typography>
      </Box>

      <Box className={classes.descriptionContainer}>
        <Box className={classes.descriptionItems}>
          <Box className={classes.avatar}> {`${description[0]}`}</Box>
          <Typography className={classes.description}>{description}</Typography>
        </Box>
      </Box>

      {!isAccountLocked && loginAttempts > 2 && (
        <WarningWrapper>
          <WarningIconWrapper>
            <AlertTriangleIcon />
          </WarningIconWrapper>
          <div>
            <WarningDetailTitle>You have entered an incorrect password multiple times.</WarningDetailTitle>
            <WarningDetailSubInfo>
              If your username have 10 unsuccessful attempt in record, you will be locked out of your account and will
              have to call support to get access.
              {` If you enter an incorrect password more than twice, your account will be temporarily locked 
              after 10 unsuccessful attempts. To regain access, please contact support.`}
            </WarningDetailSubInfo>
          </div>
        </WarningWrapper>
      )}

      {isAccountLocked && (
        <WarningWrapper>
          <WarningIconWrapper>
            <AlertTriangleIcon />
          </WarningIconWrapper>
          <div>
            <WarningDetailTitle>
              <span>Your session has been locked for your account security. Please </span>
              <a href="https://support.hypercare.com" rel="noopener noreferrer" target="_blank">
                contact Hypercare support
              </a>
              <span> for help.</span>
            </WarningDetailTitle>
          </div>
        </WarningWrapper>
      )}
    </Box>
  );
};

export default NewLoginFormPasswordHeader;
