import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import Chevron from 'src/svgs/Chevron';
import styled from 'styled-components';
import InvitedUserList from 'src/pages/ContactsPage/InvitedUserList';
import {SmallLoader} from 'src/components/LoadingDiv';
import useRefetchInvitedUsers from './hooks/useFetchInvitedUsers';

const UserListWrapper = styled.div`
  display: contents;
  max-height: 100%;
`;

const InviteSummary = styled.span`
  font-weight: bold;
  font-family: Nunito;
`;

const NoResult = styled.p`
  margin-top: 1em;
  font-size: 16px;
  color: ${(props) => props.theme.greyishBrown};
`;

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  expandWrapper: {
    boxShadow: 'none',
    margin: '0 !important',
  },
  summaryHeaderWrapper: {
    position: 'sticky',
    top: 0,
    padding: '4px 0.5em',
    zIndex: 1,
    backgroundColor: 'white',
  },
}));

interface Props {
  searchKeyword: string;
}

const InvitationUserListWrapper = ({searchKeyword}: Props) => {
  const classes = useStyles({});

  const {loading, error, invitationResultList} = useRefetchInvitedUsers({
    searchKeyword,
  });

  if (loading || error || !invitationResultList) return <SmallLoader />;

  const noSearchResult = invitationResultList.length === 0 && Boolean(searchKeyword);

  return (
    <UserListWrapper className={classes.root}>
      <ExpansionPanel defaultExpanded={true} className={classes.expandWrapper}>
        <ExpansionPanelSummary
          className={classes.summaryHeaderWrapper}
          expandIcon={<Chevron />}
          aria-controls="invite-summary"
        >
          <Typography className={classes.heading}>
            <InviteSummary>Pending Invites ({invitationResultList.length})</InviteSummary>
          </Typography>
        </ExpansionPanelSummary>
        {noSearchResult ? (
          <NoResult>No results found</NoResult>
        ) : (
          <ExpansionPanelDetails style={{padding: 0}}>
            <InvitedUserList invitedUserList={invitationResultList} />
          </ExpansionPanelDetails>
        )}
      </ExpansionPanel>
    </UserListWrapper>
  );
};

export default InvitationUserListWrapper;
