import React from 'react';
import styled from 'styled-components';

const MaintenanceContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  background: #f8fafc;
`;

const MaintenanceBox = styled.div`
  width: 90%;
  max-width: 600px;
  height: 426px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ImageStyle = styled.img`
  width: 257px;
  height: 194px;
  flex-shrink: 0;
  padding-bottom: 16px;
`;

const HeadingStyle = styled.div`
  font-family: 'Open Sans';
  font-weight: 700;
  font-size: 21px;
  margin: 0;
  color: #222;
  padding-bottom: 4px;
`;

const BodyStyle = styled.div`
  color: #4a4a4a;
  text-align: center;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  word-wrap: break-word;
`;

const BoldText = styled.span`
  font-weight: 600;
`;

const title = 'We’ll be back soon!';
const body1 = 'Hypercare is currently undergoing a system upgrade and will be unavailable ';
const body2 = 'until Aug 18, 2024 02:00 (EDT) / Aug 17, 2024 23:00 (PDT). ';
const body3 =
  'Please contact your system administrator for alternate arrangements during this period. We apologize for the inconvenience and appreciate your patience.';

const MaintenancePage: React.FC = () => {
  return (
    <MaintenanceContainer>
      <MaintenanceBox>
        <ImageStyle src="/assets/maintenance.png" alt="maintenance" />
        <HeadingStyle>{title}</HeadingStyle>
        <BodyStyle>
          {body1}
          <BoldText>{body2}</BoldText>
          {body3}
        </BodyStyle>
      </MaintenanceBox>
    </MaintenanceContainer>
  );
};

export default MaintenancePage;
