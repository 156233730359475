import React from 'react';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkmark from 'src/svgs/Checkmark';

const RoundedCheckMarkWrapper = styled.div`
  height: 22px;
  width: 22px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.chatTeal};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
`;

const EmptyMuiStepLabelIconPlaceholder = styled.div`
  height: 21px;
  width: 21px;
  background-color: transparent;
  border-radius: 50%;
  border: 1px solid grey;
`;

const TealedEmptyMuiStepLabelIconPlaceholder = styled(EmptyMuiStepLabelIconPlaceholder)<{
  isEscalationEndWithoutAnyAcknowledged: boolean;
}>`
  border: 4px solid ${(props) => (props.isEscalationEndWithoutAnyAcknowledged ? 'lightgrey' : props.theme.chatTeal)};
`;

const EmptyPlaceHolder = styled.div`
  width: 22px;
`;
interface Props {
  isResponded: boolean;
  isLessThanStartingLevel: boolean;
  isEscalationEndWithoutAnyAcknowledged: boolean;
  isEscalationActive: boolean;
  currentResponderLevel: number;
  level: number;
}

const StepIndicatorIcon = ({
  isEscalationEndWithoutAnyAcknowledged,
  isLessThanStartingLevel,
  isEscalationActive,
  isResponded,
  level,
  currentResponderLevel,
}: Props) => {
  if (isLessThanStartingLevel) return <EmptyPlaceHolder />;

  if (isResponded)
    return (
      <RoundedCheckMarkWrapper>
        <Checkmark />
      </RoundedCheckMarkWrapper>
    );

  if (level <= currentResponderLevel && isEscalationActive) return <CircularProgress size={18} />;

  if (level <= currentResponderLevel && !isEscalationActive)
    return (
      <TealedEmptyMuiStepLabelIconPlaceholder
        isEscalationEndWithoutAnyAcknowledged={isEscalationEndWithoutAnyAcknowledged}
      />
    );

  return <EmptyMuiStepLabelIconPlaceholder />;
};

export default StepIndicatorIcon;
