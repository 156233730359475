import React from 'react';
import styled from 'styled-components';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import client from 'src/apollo';
import UpdateAddressAccessMutation from 'src//gql/mutation/UpdateAddressAccessMutation';
import {toast} from 'react-toastify';
import {GetSelfProfileWithAddresses} from 'src/gql/query/GetSelfProfile';
import {User, UserAddress} from 'src/types';
import {IsFeatureFlagEnabled} from 'src/utils/FeatureFlagManager';

const StyledFormControl = styled(FormControl)<{
  $isDisabled: boolean;
}>`
  width: 100px !important;
  margin-left: 10px !important;
  .MuiNativeSelect-icon {
    display: ${(props) => (props.$isDisabled ? `none` : `inherit`)};
  }
`;

interface Props {
  isInputDisabled: boolean;
  index: number;
  setFieldValue: (filedId: string, fieldValue: string) => void;
  currentAddress: UserAddress;
  isEditProfileFormLoading: boolean;
  setEditProfileFormLoading: (flag: boolean) => void;
}

const ChangeAccessActionInput = ({
  isInputDisabled,
  index,
  setFieldValue,
  currentAddress,
  isEditProfileFormLoading,
  setEditProfileFormLoading,
}: Props) => {
  const {address, type} = currentAddress;
  const orderPreferenceContacts = IsFeatureFlagEnabled('orderPreferenceContacts');

  const handleChangeAccess = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    setEditProfileFormLoading(true);
    const updatedAccess = e.target.value;
    e.target.blur();

    try {
      await client.mutate({
        mutation: UpdateAddressAccessMutation,
        variables: {
          address,
          addressType: type,
          accessType: updatedAccess,
        },
      });

      const selfProfileQuery = client.readQuery({
        query: GetSelfProfileWithAddresses,
      });
      const selfProfile: User = selfProfileQuery.me;

      const updatedUserAddresses = selfProfile.addresses.map((existingAddress) => {
        return {
          ...existingAddress,
          access:
            existingAddress.address === currentAddress.address && existingAddress.type === currentAddress.type
              ? updatedAccess
              : existingAddress.access,
        };
      });

      client.writeQuery({
        query: GetSelfProfileWithAddresses,
        data: {
          me: {
            ...selfProfileQuery.me,
            addresses: [...updatedUserAddresses],
          },
        },
      });

      setFieldValue(`addresses.${index}.access`, updatedAccess);

      toast.success('Address visibility has been changed');
    } catch (e) {
      console.error(e);
      toast.error('Failed to change address visibility, please check your internet connection and try again');
    } finally {
      setEditProfileFormLoading(false);
    }
  };

  return (
    <StyledFormControl $isDisabled={Boolean(isInputDisabled || isEditProfileFormLoading)}>
      {!orderPreferenceContacts && (
        <InputLabel shrink htmlFor={`addresses.${index}.access`}>
          Visibility
        </InputLabel>
      )}
      <NativeSelect
        value={currentAddress.access}
        disabled={isInputDisabled || isEditProfileFormLoading}
        onChange={handleChangeAccess}
        inputProps={{
          id: `addresses.${index}.access`,
          name: `addresses.${index}.access`,
        }}
        disableUnderline={orderPreferenceContacts}
      >
        <option value={'public'}>Public</option>
        <option value={'private'}>Private</option>
      </NativeSelect>
    </StyledFormControl>
  );
};

export default ChangeAccessActionInput;
