import {LocatingCodeTeam, LocatingRole} from 'src/types';
import store from '../../redux';

interface Props {
  searchKeyword: string;
  roleList: LocatingRole[];
  teamList: LocatingCodeTeam[];
}

export default ({searchKeyword, roleList, teamList = []}: Props) => {
  const roleListResult = (() => {
    roleList?.sort((a, b) => a.name?.toLowerCase().localeCompare(b.name?.toLowerCase()));
    if (Boolean(searchKeyword)) {
      let filteredRoles = roleList?.filter((role: LocatingRole) => {
        return (
          role.name.toLowerCase().includes(searchKeyword) ||
          (role.currentShift && (role.currentShift.user.firstname as string)?.toLowerCase().includes(searchKeyword)) ||
          (role.currentShift && (role.currentShift.user.lastname as string)?.toLowerCase().includes(searchKeyword)) ||
          (role.nextShift && (role.nextShift.user.firstname as string)?.toLowerCase().includes(searchKeyword)) ||
          (role.nextShift && (role.nextShift.user.lastname as string)?.toLowerCase().includes(searchKeyword))
        );
      });
      return filteredRoles;
    }
    return roleList;
  })();

  const teamsListResult = (() => {
    if (Boolean(searchKeyword) && teamList) {
      return teamList.filter(
        (team) =>
          team.name?.toLowerCase().includes(searchKeyword) ||
          Boolean(
            team.members.find(
              (member) =>
                member.user &&
                ((member.user.firstname as String)?.toLowerCase().includes(searchKeyword) ||
                  (member.user.lastname as String)?.toLowerCase().includes(searchKeyword)),
            ),
          ),
      );
    }
    return teamList || [];
  })();

  return {
    roleListResult,
    teamsListResult,
  };
};
