import * as React from 'react';

export default (props: {style: React.CSSProperties}) => (
  <svg width="65" height="64" viewBox="0 0 65 64" fill="none" xmlns="http://www.w3.org/2000/svg" style={props.style}>
    <circle cx="32.5" cy="32" r="32" fill="#F6F6F9" />
    <g clip-path="url(#clip0_488_78830)">
      <path
        d="M47.1258 45.0339L38.6296 36.5378C40.6712 34.0868 41.6892 30.9431 41.472 27.7607C41.2547 24.5782 39.8189 21.6021 37.4632 19.4513C35.1075 17.3005 32.0133 16.1407 28.8243 16.2132C25.6352 16.2856 22.5969 17.5848 20.3413 19.8404C18.0858 22.0959 16.7866 25.1343 16.7141 28.3233C16.6417 31.5123 17.8015 34.6065 19.9522 36.9622C22.103 39.3179 25.0792 40.7538 28.2617 40.971C31.4441 41.1883 34.5878 40.1702 37.0388 38.1287L45.5349 46.6248L47.1258 45.0339ZM19.0008 28.6248C19.0008 26.6222 19.5946 24.6647 20.7071 22.9996C21.8197 21.3346 23.401 20.0368 25.2511 19.2705C27.1012 18.5042 29.137 18.3037 31.101 18.6943C33.0651 19.085 34.8692 20.0493 36.2852 21.4653C37.7012 22.8813 38.6655 24.6854 39.0562 26.6495C39.4469 28.6135 39.2464 30.6493 38.48 32.4994C37.7137 34.3496 36.416 35.9309 34.7509 37.0434C33.0859 38.156 31.1283 38.7498 29.1258 38.7498C26.4414 38.7468 23.8677 37.6791 21.9696 35.7809C20.0714 33.8828 19.0037 31.3092 19.0008 28.6248Z"
        fill="#00859A"
      />
    </g>
    <defs>
      <clipPath id="clip0_488_78830">
        <rect width="36" height="36" fill="white" transform="translate(14.5 14)" />
      </clipPath>
    </defs>
  </svg>
);
