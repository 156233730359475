import axios, {AxiosRequestConfig} from 'axios';
import getParsedAuthInfo from 'src/utils/localStorageHandler';
import AnalyticsManager, {EVENTS} from 'src/analytics/AnalyticsManager';
import checkIsJSONString from 'src/utils/checkIsJSONString';
import {USER_AUTH_EXPIRATION_NAMES} from 'src/constants/user';
import {AuthHelper} from 'src/auth';
import moment from 'moment-timezone';
import {IS_EXPIRED} from 'src/constants/storageKeys';
import {v4 as uuid} from 'uuid';

const formatGqlQueryForAnalytics = (data: any) => {
  let gqlQuery: string;
  if (checkIsJSONString(data)) {
    let parsedResponseData = JSON.parse(data);
    if (parsedResponseData['query']) {
      gqlQuery = parsedResponseData.query;
    } else if (parsedResponseData['mutation']) {
      gqlQuery = parsedResponseData.mutation;
    }
  }
  return gqlQuery;
};

// doc: https://github.com/axios/axios
const axiosConfig = (): void => {
  const parsedAuthInfo = getParsedAuthInfo();
  const {scopeToken = ''} = parsedAuthInfo || {};

  // Request
  axios.interceptors.request.use(
    (config) => {
      config.headers = {
        'Cache-Control': 'no-cache,no-store,must-revalidate,private',
        Pragma: 'no-cache',
        Expires: 0,
        'hc-timezone': moment.tz.guess(),
        'X-Timezone': moment.tz.guess(),
        'hypercare-scope': scopeToken ? `${scopeToken}` : '',
        'X-Request-ID': uuid(),
        ...config.headers,
      };
      config['start'] = new Date().getTime();
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );
  // Response
  axios.interceptors.response.use(
    (response) => {
      if (response.request) {
        let gqlQuery = formatGqlQueryForAnalytics(response.config.data);
        const {responseURL, status} = response.request;

        AnalyticsManager.applyAnalytics({
          eventName: EVENTS.networkRequest,
          params: {
            status_code: status,
            query: gqlQuery,
            duration: new Date().getTime() - response.config['start'],
            error_code: '',
            url_path: responseURL,
            trace_id: response.config.headers['X-Request-ID'],
          },
        });
      }

      return response;
    },
    (error) => {
      // #Handling Errors
      let errorCode: string;
      let gqlQuery: string;
      let status: string;
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        // console.log(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
        status = error.response.status;
        if (error.response.data.errors && error.response.data.errors[0]) {
          errorCode = error.response.data.errors[0].name;
        }
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        // console.log(error.request);
        errorCode = 'client did not receive response';
      } else {
        // Something happened in setting up the request that triggered an Error
        // console.log('Error', error.message);
        errorCode = error.message;
      }

      gqlQuery = formatGqlQueryForAnalytics(error.config?.data);
      AnalyticsManager.applyAnalytics({
        eventName: EVENTS.networkRequest,
        params: {
          status_code: status,
          query: gqlQuery,
          duration: new Date().getTime() - error.response?.config['start'],
          error_code: errorCode,
          url_path: error.config?.url,
          trace_id: error.response?.config.headers['X-Request-ID'],
        },
      });

      // catch access token error and retry
      if (
        error.response?.data &&
        ((error.response?.data.inner && USER_AUTH_EXPIRATION_NAMES.includes(error.response?.data.inner.statusCode)) ||
          USER_AUTH_EXPIRATION_NAMES.includes(error.response?.data.name))
      ) {
        const originalRequest: AxiosRequestConfig = error.config;
        return AuthHelper.refreshAccessToken()
          .then(() => {
            originalRequest.headers = {
              ...originalRequest.headers,
              Authorization: `Bearer ${getParsedAuthInfo().accessToken}`,
            };
            return axios(originalRequest);
          })
          .catch(() => {
            AuthHelper.logout();
            sessionStorage.setItem(IS_EXPIRED, 'expired by network error');
            window.location.reload();
          });
      } else {
        return Promise.reject(error);
      }
    },
  );
};

export default axiosConfig;
