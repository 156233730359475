import * as React from 'react';
import {withRouter, RouteComponentProps, matchPath} from 'react-router-dom';
import styled from 'styled-components';
import {CONSULT, IMAGE, SYSTEM, ATTACHMENT, TEMPLATE} from 'src/constants/messageTypes';
import {Chat, AuthPayload, Message} from 'src/types';
import theme from 'src/styles/theme';
import Oval from 'src/svgs/Oval';
import parseDate from 'src/utils/parseDate';
import ProfilePic from 'src/components/ProfilePic';
import getChatTitle from 'src/utils/messengerHelper/getChatTitle';
import getPrettyReadableDate from 'src/utils/getPrettyReadableDate';
import getLatestMessage from 'src/utils/messengerHelper/getLastMessage';
import hasUnreadPriorityMessages from 'src/utils/messengerHelper/hasUnreadPriorityMessages';
import Chevron from 'src/svgs/Chevron';
import {Manager, Reference, Popper} from 'react-popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import {ARCHIVED, MESSENGER} from 'src/constants/routerPathName';
import AnalyticsManager, {EVENTS} from 'src/analytics/AnalyticsManager';
import {MenuButton, OptionsBox, Option} from 'src/styles/styled-components/PopoverMenuComponents';
import {StatMessagePriority, UrgentMessagePriority} from 'src/constants/messageTypes';
import StatDoubleExclamation from 'src/svgs/StatDoubleExclamation';
import UrgentSingleExclamation from 'src/svgs/UrgentSingleExclamation';
import LockIcon from 'src/svgs/LockIcon';
import {withLDConsumer} from 'launchdarkly-react-client-sdk';
import NotificationBellOn from 'src/svgs/NotificationBellOn';
import NotificationBellOff from 'src/svgs/NotificationBellOff';
import ArchiveChat from 'src/svgs/ArchiveChat';
import LeaveChat from 'src/svgs/LeaveChat';
import {leaveChat} from 'src/pages/MessengerPage/messenger/leaveChatHelper';
import {archiveChat, unArchiveChat} from 'src/pages/MessengerPage/messenger/archiveChatHelper';
import {MuteNotificationModal} from 'src/pages/MessengerPage/messenger/chat-info-layout/MuteNotificationModal';

interface StyledChatProps {
  hasUnreadPriorityMessages?: boolean;
  hasUnreadMessage?: boolean;
  isActiveChat?: boolean;
  priorityType?: string;
}

const ChatContainer = styled.div<StyledChatProps>`
  display: flex;
  align-items: center;
  padding: 10px 5px;
  cursor: pointer;
  user-select: none;
  position: relative;
  color: ${({priorityType}) => (priorityType === StatMessagePriority ? 'white' : 'black')};
  &:hover {
    div {
      opacity: 1;
    }
  }

  background-color: ${(props) => {
    if (props.hasUnreadPriorityMessages) {
      return props.priorityType === UrgentMessagePriority ? props.theme.priorityYellow : props.theme.statRed;
    }
    if (props.isActiveChat) {
      return props.theme.lightGrey;
    }
    return 'white';
  }};
`;

const LockIconWrapper = styled.div`
  left: 18.75%;
  right: 18.75%;
  top: 6.25%;
  bottom: 6.25%;
`;

const NotificationBellIconWrapper = styled.div`
  position: absolute;
  width: 16px;
  height: 16px;
  right: 36px;
  top: 36px;
`;

const Arrow = styled.div`
  position: absolute;
  bottom: 28px;
  left: -10px !important;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid black;
`;

const StyledChevron = styled.div`
  opacity: 0;
  position: absolute;
  padding: 0 9px;
  right: 0;
`;

const LeftSection = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  padding-right: 5px;
`;

const StyledOptionsBox = styled(OptionsBox)`
  z-index: 9 !important;
  top: 20px !important;
`;

const MCFWStyledOptionsBox = styled(OptionsBox)`
  position: absolute !important;
  top: 20px !important;
  background-color: white;
  box-shadow: 0px 18px 28px rgba(9, 30, 66, 0.15), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px;
  width: 204px;
`;

const StyledOptions = styled.div`
  width: 100%;
  height: 44px;
  color: black;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  gap: 18px;
  &:hover {
    background-color: #f6f6f9;
  }
  & .styled-options-textfield {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    align-items: center;
    color: #222222;
    height: 24px;
  }
`;

const NotifWrapper = styled.div`
  display: flex;
  height: 23px;
  border-radius: 50%;
  width: 23px;
  align-items: center;
  justify-content: center;
`;

const LastMessageWrapper = styled.div`
  min-width: 0;
  overflow: hidden;
  line-height: 1.5em;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const NotificationIconDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
`;

const RightSection = styled.div`
  flex: 4;
  min-width: 0;
  padding-right: 20px;
`;

const MessageDetails = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const MessageWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ChatTitle = styled.div<{priorityType: string; hasUnreadPriorityMessagesInChat: boolean}>`
  min-width: 0;
  overflow: hidden;
  line-height: 1.5em;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 600;
  color: ${(props) => {
    if (props.hasUnreadPriorityMessagesInChat)
      return props.priorityType === StatMessagePriority ? 'white' : props.theme.chatTextTitle;
    else {
      return props.theme.chatTextTitle;
    }
  }};
`;

const MessageContent = styled.div<StyledChatProps>`
  margin-right: 30px;
  min-width: 0;
  overflow: hidden;
  font-size: 13px;
  line-height: 1.5em;
  text-overflow: ellipsis;
  display: flex;
  white-space: nowrap;
  justify-content: space-between;
  font-weight: ${(props) => (props.hasUnreadMessage ? 600 : 'normal')};
  color: ${(props) => {
    if (props.hasUnreadMessage) return props.priorityType === StatMessagePriority ? 'white' : props.theme.chatTextTitle;
    else return props.theme.labelGrey;
  }};
`;

const Time = styled.div<StyledChatProps>`
  font-size: 13px;
  font-weight: ${(props) => (props.hasUnreadMessage ? 600 : 'normal')};
  white-space: nowrap;
  color: ${(props) => {
    if (props.hasUnreadMessage) return props.priorityType === StatMessagePriority ? 'white' : props.theme.chatTextTitle;
    else return props.theme.labelGrey;
  }};
`;

interface Props extends RouteComponentProps<{}> {
  chat: Chat | any;
  authInfo: AuthPayload | any;
  flags?: {[key: string]: boolean};
}

class ChatItem extends React.Component<Props> {
  public state = {
    isMenuOpen: false,
    notificationConfirmationModal: false,
  };

  private handleModalChange = (value: boolean) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        notificationConfirmationModal: value,
      };
    });
  };

  public render() {
    const {chat, history} = this.props;
    const {isMenuOpen, notificationConfirmationModal} = this.state;
    const isArchive = history.location.pathname.includes(ARCHIVED);
    const isActiveChat = this.isActiveChat();
    const lastMessage: Message | null = getLatestMessage(chat);

    const hasUnreadMessage = !this.isRead();
    const hasUnreadPriorityMessagesInChat = hasUnreadPriorityMessages(chat);

    const muteWebNotificationsFlag = this.props.flags.muteWebNotifications;
    const isGroupChat = chat.type === 'group';

    const isChatMuted = chat.muted?.length >= 1 ? true : false;

    return (
      <ChatContainer
        hasUnreadPriorityMessages={hasUnreadPriorityMessagesInChat}
        hasUnreadMessage={hasUnreadMessage}
        isActiveChat={isActiveChat}
        onClick={this.openChat}
        priorityType={lastMessage?.priorityType}
      >
        <LeftSection>
          <NotificationIconDiv>
            {!this.isRead() && !hasUnreadPriorityMessagesInChat && <Oval color={theme.warmGrey} />}
          </NotificationIconDiv>
          <ProfilePic
            users={chat.members}
            chatImageUrl={isGroupChat && chat.image ? chat.image.url : undefined}
            status={chat.status}
          />
        </LeftSection>

        <RightSection>
          <MessageDetails>
            {this.titleToShow()}
            <Time hasUnreadMessage={hasUnreadMessage} priorityType={lastMessage?.priorityType}>
              {lastMessage ? getPrettyReadableDate(parseDate(lastMessage.dateCreated)) : ''}
            </Time>
          </MessageDetails>
          <MessageWrapper>
            <MessageContent hasUnreadMessage={hasUnreadMessage} priorityType={lastMessage?.priorityType}>
              <LastMessageWrapper>{lastMessage ? `${this.lastMessageHandler(lastMessage)} ` : ''}</LastMessageWrapper>
              <div>
                {hasUnreadPriorityMessagesInChat && lastMessage.priorityType === StatMessagePriority ? (
                  <StatDoubleExclamation />
                ) : hasUnreadPriorityMessagesInChat && lastMessage.priorityType === UrgentMessagePriority ? (
                  <UrgentSingleExclamation />
                ) : null}
              </div>
            </MessageContent>
            <LockIconWrapper>
              {chat.status === 'locked' && <LockIcon width={'16px'} height={'16px'} data-testid="lock-icon" />}
            </LockIconWrapper>
          </MessageWrapper>
          <NotificationBellIconWrapper>
            {isChatMuted && <NotificationBellOff fill="#767676" width={'16px'} height={'16px'} />}
          </NotificationBellIconWrapper>
        </RightSection>
        <Manager>
          <Reference>
            {({ref}) => (
              <MenuButton onClick={this.onClickMenu} ref={ref}>
                <StyledChevron>
                  <Chevron />
                </StyledChevron>
              </MenuButton>
            )}
          </Reference>
          <Popper placement="bottom-start">
            {muteWebNotificationsFlag
              ? ({ref, style, placement}) =>
                  isMenuOpen && (
                    <ClickAwayListener onClickAway={this.clickedOutside}>
                      <MCFWStyledOptionsBox
                        ref={ref}
                        style={style}
                        data-placement={placement}
                        className="chat_list_dropdown"
                      >
                        <StyledOptions
                          style={{display: chat.status === 'locked' && 'none'}}
                          onClick={() => this.handleModalChange(true)}
                        >
                          <NotifWrapper>
                            {isChatMuted ? <NotificationBellOff fill="black" /> : <NotificationBellOn fill="black" />}
                          </NotifWrapper>
                          <span className="styled-options-textfield">{isChatMuted ? 'Unmute chat' : 'Mute chat'}</span>
                        </StyledOptions>
                        <StyledOptions
                          onClick={(e) => (isArchive ? unArchiveChat(e, chat.id, chat) : archiveChat(e, chat.id, chat))}
                        >
                          <NotifWrapper>
                            <ArchiveChat fill="black" />{' '}
                          </NotifWrapper>
                          <span className="styled-options-textfield">
                            {' '}
                            {isArchive ? 'Unarchive chat' : 'Archive chat'}{' '}
                          </span>
                        </StyledOptions>
                        {isGroupChat && (
                          <StyledOptions onClick={() => leaveChat(chat.id)}>
                            {' '}
                            <NotifWrapper>
                              <LeaveChat fill="black" />
                            </NotifWrapper>
                            <span className="styled-options-textfield">Leave chat</span>
                          </StyledOptions>
                        )}
                        <MuteNotificationModal
                          isChatMuted={isChatMuted}
                          chatId={chat.id}
                          isOpen={notificationConfirmationModal}
                          handleClose={this.handleModalChange}
                        />
                      </MCFWStyledOptionsBox>
                    </ClickAwayListener>
                  )
              : ({ref, style, placement, arrowProps}) =>
                  isMenuOpen && (
                    <ClickAwayListener onClickAway={this.clickedOutside}>
                      <StyledOptionsBox ref={ref} style={style} data-placement={placement}>
                        <Arrow ref={arrowProps.ref} data-placement={placement} style={arrowProps.style} />
                        <Option
                          onClick={(e) => (isArchive ? unArchiveChat(e, chat.id, chat) : archiveChat(e, chat.id, chat))}
                        >
                          {isArchive ? 'Unarchive Chat' : 'Archive Chat'}
                        </Option>
                      </StyledOptionsBox>
                    </ClickAwayListener>
                  )}
          </Popper>
        </Manager>
      </ChatContainer>
    );
  }

  private clickedOutside = () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        isMenuOpen: false,
      };
    });
  };

  private onClickMenu = (e) => {
    e.stopPropagation();
    this.setState((prevState) => {
      return {
        ...prevState,
        isMenuOpen: !this.state.isMenuOpen,
      };
    });
  };

  private lastMessageHandler = (lastMessage) => {
    const isMe: boolean = lastMessage.sender.id === this.props.authInfo.user.id;
    const isGroupChat = this.props.chat.members.length > 1;
    const notMe = isGroupChat ? lastMessage.sender.firstname + ': ' : '';
    switch (lastMessage.type) {
      case CONSULT:
        return `${isMe ? 'Me: ' : notMe} Sent a consult message`;
      case TEMPLATE:
        return `${isMe ? 'Me: ' : notMe} Sent a template message`;
      case IMAGE:
        return `${isMe ? 'Me: ' : notMe} Sent an image`;
      case ATTACHMENT:
        return `${isMe ? 'Me: ' : notMe} Sent an attachment`;
      case SYSTEM:
        return lastMessage.message;
      default:
        return `${isMe ? 'Me: ' : notMe} ${lastMessage.message}`;
    }
  };

  private isRead = () => {
    const {authInfo, chat} = this.props;
    const user = authInfo?.user;
    const lastMessage = getLatestMessage(chat);
    if (!lastMessage) {
      return true;
    } else {
      if (lastMessage.readBy.every((receipt) => receipt.user.id !== user.id)) {
        return false;
      }
      return true;
    }
  };

  private openChat = () => {
    const isChatListDropdownModalOpen = document.querySelector('.chat_list_dropdown');
    if (isChatListDropdownModalOpen) return;

    const {
      chat,
      history,
      match: {params},
    } = this.props;
    // @ts-ignore
    if (params && params.chatId === chat.id) return;
    const isArchive = history.location.pathname.includes(ARCHIVED);

    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.openChat,
      params: {
        source: AnalyticsManager.pageViews[isArchive ? 'archivedChatList' : 'chatList'],
        chat_id: chat.id,
        chat_type: chat.type,
      },
    });

    const routeName = isArchive ? ARCHIVED : MESSENGER;
    history.push(`/${routeName}/${chat.id}`);
  };

  private titleToShow() {
    const {chat} = this.props;
    const hasUnreadPriorityMessagesInChat = hasUnreadPriorityMessages(chat);
    return (
      <ChatTitle
        hasUnreadPriorityMessagesInChat={hasUnreadPriorityMessagesInChat}
        priorityType={chat?.lastMessage?.priorityType}
      >
        {getChatTitle(chat)}
      </ChatTitle>
    );
  }

  private isActiveChat = () => {
    const {history, chat} = this.props;
    const routePath = history.location.pathname.includes(ARCHIVED) ? `/${ARCHIVED}/:chatId` : `/${MESSENGER}/:chatId`;

    const match = matchPath(history.location.pathname, {
      path: routePath,
      exact: true,
    }) as {params: {chatId: string}};

    const activeChatId = match && match.params && match.params.chatId;
    if (activeChatId === chat.id) return true;
    return false;
  };
}

export default withLDConsumer()(withRouter(ChatItem));
