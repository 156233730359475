import * as React from 'react';
import * as R from 'ramda';
import {QueryResult} from 'react-apollo';
import {GetChatEscalations} from 'src/gql/query/GetChatQuery';
import GenericError from 'src/components/GenericError';
import MessageDropZone from 'src/pages/MessengerPage/messenger/MessagesDropZone';
import LoadingDiv from 'src/components/LoadingDiv';
import MessageBodyViewController from 'src/pages/MessengerPage/messenger/messages-layout/MessageBodyViewController';
import EscalationPreviewBanner from 'src/components/EscalationPreviewBanner';
import AckEscalationChatButton from 'src/pages/MessengerPage/messenger/messages-layout/AckEscalationChatButton';
import {useQuery} from '@apollo/react-hooks';
import {Message, Chat, MessageInChat} from 'src/types';
import getParsedAuthInfo from 'src/utils/localStorageHandler';
import AnalyticsManager, {EVENTS} from 'src/analytics/AnalyticsManager';
import ChatBodyMessageInputSection from './ChatBodyMessageInputSection';
import OptimizedFetchMessages from 'src/gql/query/OptimizedFetchMessages';

type Data = 'loading' | 'error' | 'wrongId' | Chat;

const ChatBodyMessagesContainer = ({
  chatId,
  isSearch,
  setIsSearch,
  handleIsSearch,
  jumpToMessage,
  isSideSearchActive,
  handleIsJumpToMessageEnable,
}: {
  chatId: string;
  isSearch: boolean;
  setIsSearch: (isSearch) => void;
  handleIsSearch: (isVisible: boolean) => void;
  jumpToMessage: MessageInChat;
  isSideSearchActive: boolean;
  handleIsJumpToMessageEnable: (isJumpToMessageEnable: boolean) => void;
}) => {
  const [jumpToLatestMessageOnSearch, setJumpToLatestMessageOnSearch] = React.useState(false);
  const [resetSearchChat, setResetSearchChat] = React.useState(false);
  const [lastMessage, setLastMessage] = React.useState(null);
  const [lastUnreadMessage, setLastUnreadMessage] = React.useState(null);
  const {loading, error, data, refetch, fetchMore}: QueryResult = useQuery(OptimizedFetchMessages, {
    variables: {chatId},
  });

  // TODO: remove refreshedChat when escalation socket are ready
  const {data: escalationData}: QueryResult = useQuery(GetChatEscalations, {
    variables: {chatId},
    fetchPolicy: 'cache-and-network',
  });

  const refreshedChatEscalation = R.pathOr({}, ['chat'], escalationData) as Chat;

  const parsedAuthInfo = getParsedAuthInfo();
  const selfID = parsedAuthInfo ? parsedAuthInfo.user.id : null;
  const memorizedMessageLength = React.useRef<number>(0);
  const memorizedLastMessageID = React.useRef(null);
  const memorizedChat = React.useRef<Chat>(null);

  const handleJumpToLatestMessageOnSearch = (searchText: string) => {
    if (searchText) {
      setJumpToLatestMessageOnSearch(true);
    }
  };

  const clearSearchOnNewMessage = () => {
    setResetSearchChat(true);
  };

  // avoid duplicate message render:
  // __typename for interface GeneralUser changed and cause messages to re-render
  // i.e. for User -> workStatusProxy -> __typename changed from 'GeneralUser' to 'ChatMember'
  const cachedData: Data = React.useMemo(() => {
    if (loading) return 'loading';
    if (error) return 'error';

    const chat = R.pathOr({}, ['chat'], data) as Chat;
    setLastMessage(chat.lastMessage);
    setLastUnreadMessage(chat.lastUnreadMessage);

    if (!Boolean(chat.id)) return 'wrongId';
    const messages: Message[] = R.pathOr([], ['messages', 'messages'], chat);

    if (
      memorizedMessageLength.current === messages.length &&
      chat.lastMessage &&
      chat.lastMessage.id === memorizedLastMessageID.current &&
      chat.lastMessage.id === messages[0].id &&
      !isSearch
    ) {
      return memorizedChat.current;
    }

    memorizedMessageLength.current = messages.length;
    memorizedLastMessageID.current = chat.lastMessage ? chat.lastMessage.id : null;
    memorizedChat.current = chat;

    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.messageRead,
      params: {
        chat_id: chatId,
        message_count: memorizedMessageLength.current,
      },
    });

    return chat;
  }, [loading, error, data]);

  return React.useMemo(() => {
    if (cachedData === 'error' || cachedData === 'wrongId') {
      return <GenericError error={error} />;
    }
    if (cachedData === 'loading') return <LoadingDiv />;

    const chat = cachedData ? (cachedData as Chat) : (data.chat as Chat);

    // TODO-bug: missing messages in a short gap?
    const messages = R.pathOr([], ['messages', 'messages'], chat);
    chat.escalation = refreshedChatEscalation.escalation;

    const isCurrentEscalationResponder =
      refreshedChatEscalation &&
      refreshedChatEscalation.escalation &&
      refreshedChatEscalation.escalation.state === 'active' &&
      refreshedChatEscalation.escalation.escalatedUsers &&
      refreshedChatEscalation.escalation.escalatedUsers.find((escalatedUser) => escalatedUser.user.id === selfID);

    const isEscalationChat = !!refreshedChatEscalation.escalation;

    const isSingleChat = chat.type === 'single';

    return (
      <MessageDropZone>
        {isEscalationChat && (
          <EscalationPreviewBanner
            hasActivatedLevel={!!chat.escalation.activatedLevel}
            activeEscalation={chat.escalation}
            isInChat={true}
          />
        )}

        <MessageBodyViewController
          chatId={chatId}
          isSingleChat={isSingleChat}
          messages={messages}
          isSearch={isSearch}
          fetchMore={fetchMore}
          refetch={refetch}
          setIsSearch={setIsSearch}
          handleIsSearch={handleIsSearch}
          jumpToMessage={jumpToMessage}
          isSideSearchActive={isSideSearchActive}
          cachedData={cachedData}
          handleJumpToLatestMessageOnSearch={handleJumpToLatestMessageOnSearch}
          resetSearchChat={resetSearchChat}
          setResetSearchChat={setResetSearchChat}
          lastMessage={lastMessage}
          lastUnreadMessage={lastUnreadMessage}
          searchThroughChat={true}
          handleIsJumpToMessageEnable={handleIsJumpToMessageEnable}
        />

        {isCurrentEscalationResponder ? (
          <AckEscalationChatButton chat={chat} />
        ) : (
          <ChatBodyMessageInputSection
            chat={chat}
            chatId={chatId}
            isSingleChat={isSingleChat}
            refetch={refetch}
            setIsSearch={setIsSearch}
            jumpToLatestMessageOnSearch={jumpToLatestMessageOnSearch}
            clearSearchOnNewMessage={clearSearchOnNewMessage}
          />
        )}
      </MessageDropZone>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    cachedData,
    refreshedChatEscalation,
    isSearch,
    jumpToLatestMessageOnSearch,
    resetSearchChat,
    setIsSearch,
    handleIsSearch,
    setResetSearchChat,
    lastMessage,
    lastUnreadMessage,
    jumpToMessage,
    handleIsJumpToMessageEnable,
  ]);
};

export default ChatBodyMessagesContainer;
