import styled from 'styled-components';
import getColor from 'src/utils/getColor';

interface InputProps {
  hasUnderline?: boolean;
}

export default styled.input<InputProps>`
  width: calc(100% - 4px);
  font-size: 0.9em;
  height: 28px;
  outline: 0;
  ::placeholder {
    color: ${getColor('placeholder')};
  }

  ${(props) =>
    props.hasUnderline
      ? `
    border-bottom: 2px solid ${props.theme['disabledLight']};
    border-width: 0 0 2px;
    border-color: ${props.theme['disabledLight']};
    background: transparent;
    transition: border-color 0.2s;

    &:focus {
      border-color: ${props.theme.warmGrey};
    }
  `
      : `
    border-width: 0;
    background: transparent;
  `}
`;
