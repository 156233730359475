import * as React from 'react';
import {PageBody, PageContainer} from 'src/styles/styled-components/CommonPage';
import Helmet from 'react-helmet';
import {AuthContext} from 'src/auth/AuthProvider';
import Header from 'src/pages/MessengerPage/messenger/PageHeader';
import styled from 'styled-components';
import appTheme from 'src/styles/theme';
import {ThemeProvider} from '@material-ui/styles';
import {createTheme} from '@material-ui/core/styles';
import InvitationSearchLayout from 'src/pages/ContactsPage/InvitationSearchLayout';
import PageBanner from 'src/components/PageBanner';
import {useParams} from 'react-router-dom';
import {ProfileLayoutContainer} from '../../LocatingPage/locating/ProfileLayoutContainer';
import InvitationSearchLayoutV2 from '../InvitationSearchLayout/InvitationSearchLayoutV2';
import {IsFeatureFlagEnabled} from 'src/utils/FeatureFlagManager';
import {FeatureFlagResult} from 'src/utils/FeatureFlags';

const SearchPanelWrapper = styled.div`
  display: flex;
  position: relative;
  box-sizing: border-box;
  flex-direction: column;
  width: 33vw;
  min-width: 440px;
  max-height: calc(100vh - 60px);
  overflow: hidden;
  border-right: 1px solid ${(props) => props.theme.borderColor};
  .MuiList-padding {
    padding: 0;
  }
`;

const invitePageTheme = createTheme({
  palette: {
    primary: {main: appTheme.chatTeal},
    secondary: {main: appTheme.redPink},
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        padding: '14px 18px',
        fontSize: '14px',
        color: 'white',
        fontFamily: 'Nunito',
      },
    },
    MuiChip: {
      root: {
        borderRadius: '4px',
      },
    },
  },
});

interface ContactRoutingParams {
  userId?: string;
}
const InvitationPage = () => {
  let params = useParams() as ContactRoutingParams;
  const paginatedContactsFeatureFlag = IsFeatureFlagEnabled(FeatureFlagResult.paginatedContacts);

  if (paginatedContactsFeatureFlag === undefined) {
    return null;
  }

  return (
    <PageBody>
      <ThemeProvider theme={invitePageTheme}>
        <SearchPanelWrapper>
          {!paginatedContactsFeatureFlag ? <InvitationSearchLayout /> : <InvitationSearchLayoutV2 />}
        </SearchPanelWrapper>
        {params.userId && <ProfileLayoutContainer />}
      </ThemeProvider>
    </PageBody>
  );
};

export default () => (
  <AuthContext.Consumer>
    {({authInfo, logout, authRegion}) => (
      <PageContainer>
        <Helmet>
          <title>Contacts - Hypercare</title>
        </Helmet>
        <Header user={authInfo.user} logout={logout} authRegion={authRegion} />
        <PageBanner />
        <InvitationPage />
      </PageContainer>
    )}
  </AuthContext.Consumer>
);
