import React from 'react';
import styled from 'styled-components';
import {FormikProps} from 'formik';
import convertTypeToReadbleText from 'src/utils/locating/convertAddressTypeToText';
import VerificationCodeInput from 'src/components/VerificationCodeInput';
import ApiHelper from 'src/api';
import formatNationalNumber from 'src/utils/locating/formatNationalNumber';
import {toast} from 'react-toastify';
import {formatPhoneNumber} from 'src/utils/formatPhoneNumber';
import {RequestVerificationPayload} from 'src/types';
import {TOO_MANY_CHALLENGES} from 'src/constants/networkError';

const ErrorMessage = styled.p`
  color: red;
  font-size: 14px;
  margin: 0;
`;

const ValidationHelperTextHolder = styled.div`
  margin-top: 0.5em;
  strong {
    color: ${(props) => props.theme.chatTeal};
  }
`;

interface Props {
  formikProps: FormikProps<any>;
  isLoading: boolean;
  setLoading: (flag: boolean) => void;
  validationErrorMsg: string;
  setValidationErrorMsg: (msg: string) => void;
  validationCodeArray: string[];
  setValidationCodeArray: React.Dispatch<React.SetStateAction<string[]>>;
  setChallengeId: (boolean) => void;
}

const AddAddressStepOne = ({
  formikProps,
  isLoading,
  setLoading,
  validationErrorMsg,
  setValidationErrorMsg,
  validationCodeArray,
  setValidationCodeArray,
  setChallengeId,
}: Props) => {
  const {values} = formikProps;

  const resendValidationCode = async () => {
    try {
      setLoading(true);
      const formattedAddressPayload =
        values.type === 'phoneNumber' ? `+1${formatPhoneNumber(values.address)}` : values.address;
      const requestVerificationPayload: RequestVerificationPayload = {
        address: formattedAddressPayload,
        type: values.type,
      };

      const result = await ApiHelper.PrivateEndpoints.addressVerificationRequest(requestVerificationPayload);
      if (result && result?.data?.response) {
        setChallengeId(result.data.response.challengeId);
        setLoading(false);
        toast.success(`Successfully resent verification code`);
      } else {
        if (result?.data?.errors && result?.data?.errors[0]) {
          let errorCode = result?.data?.errors[0].name;
          if (errorCode === TOO_MANY_CHALLENGES) {
            throw {errors: [result?.data?.errors[0].message]};
          }
          throw {
            errors: ['Unknown error occurred, please check your internet connection and try again'],
          };
        } else {
          throw {
            errors: ['Failed to resend verification code, please check your internet connection and try again'],
          };
        }
      }
    } catch (err) {
      setLoading(false);
      setValidationErrorMsg(err.errors[0]);
    }
  };

  return (
    <React.Fragment>
      <ValidationHelperTextHolder>
        Please verify your {convertTypeToReadbleText(values.type)}
        {values.type !== 'email' && ' number'} by entering the 6-digit code sent to&nbsp;
        <strong>{values.type === 'phoneNumber' ? formatNationalNumber(values.address) : values.address}</strong>
      </ValidationHelperTextHolder>
      <VerificationCodeInput
        length={6}
        size="sm"
        value={validationCodeArray.join('')}
        disabled={isLoading}
        onChange={(value) => setValidationCodeArray(value.split(''))}
        handleResend={resendValidationCode}
        renderError={validationErrorMsg && <ErrorMessage>{validationErrorMsg}</ErrorMessage>}
      />
    </React.Fragment>
  );
};

export default AddAddressStepOne;
