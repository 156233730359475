import React from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import {EscalationModalStepVariableContext} from 'src/pages/MessengerPage/escalation-modal/EscalationModalStepProvider';
import {ActionButtonWrapper} from 'src/styles/styled-components/Button';
import {ActiveEscalation, EscalationLadder} from 'src/types';
import {toast} from 'react-toastify';
import client from 'src/apollo';
import ActivateDepartmentsEscalationMutation from 'src/gql/mutation/ActivateDepartmentsEscalationMutation';
import TriggerEscalationPreview from 'src/components/TriggerEscalationPreview';
import updateActiveEscalationsQueryList from 'src/utils/updateActiveEscalationsQueryList';
import sleep from 'src/utils/sleep';
import GetChatQuery from 'src/gql/query/GetChatQuery';

const IntroText = styled.div`
  line-height: 24px;
`;

interface Props {
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  chatId: string;
  messageId: string;
  closeModal: () => void;
}

interface ActivateDepartmentsEscalationMutationResult {
  escalation: {
    ladder: {
      activate: ActiveEscalation;
    };
  };
}

interface EscalationProgressReviewStepProps extends Props {
  selectedEscalationLadder: EscalationLadder;
}

const EscalationProgressReviewStep = ({
  setCurrentStep,
  chatId,
  messageId,
  selectedEscalationLadder,
  closeModal,
}: EscalationProgressReviewStepProps) => {
  const [startingLevel, setStartingLevel] = React.useState(1);
  const [isLoading, setLoading] = React.useState(false);

  const handleSendEscalation = async () => {
    try {
      setLoading(true);

      const result = await client.mutate<ActivateDepartmentsEscalationMutationResult>({
        mutation: ActivateDepartmentsEscalationMutation,
        variables: {
          escalationLadderId: selectedEscalationLadder.id,
          startingLevel,
          activatedChatId: chatId,
          messageId: messageId,
        },
      });
      const resultChatId = result.data.escalation.ladder.activate.chat.id;
      const resultEscalationID = result.data.escalation.ladder.activate.id;

      updateActiveEscalationsQueryList(resultEscalationID);

      closeModal();
      window.routerHistory.push(`/messenger/${resultChatId}`);

      // TODO: remove this when backend get the socket working
      await sleep(5000);

      client.query({
        query: GetChatQuery,
        variables: {chatId: resultChatId},
        fetchPolicy: 'network-only',
      });
    } catch (e) {
      console.error(e);
      if (e?.networkError?.result?.errors && e?.networkError?.result?.errors[0]?.code === 'EscalationInProgress') {
        toast.error('This department already has an active escalation');
      } else {
        toast.error('Failed to send escalation, please check your internet connection and try again');
      }
      setLoading(false);
    }
  };

  const handleBack = () => setCurrentStep((prev) => prev - 1);

  return (
    <React.Fragment>
      <IntroText>
        Your escalation will start from <strong>level {startingLevel}</strong>. To pick a new starting point, click on
        the desired level.
      </IntroText>

      <TriggerEscalationPreview
        selectedEscalationLadder={selectedEscalationLadder}
        startingLevel={startingLevel}
        setStartingLevel={setStartingLevel}
      />

      <ActionButtonWrapper>
        <Button variant="outlined" disabled={isLoading} onClick={handleBack}>
          Previous
        </Button>
        <Button disabled={isLoading} variant="contained" color="secondary" onClick={handleSendEscalation}>
          {isLoading ? 'Sending...' : 'Send escalation'}
        </Button>
      </ActionButtonWrapper>
    </React.Fragment>
  );
};

export default (props: Props) => (
  <EscalationModalStepVariableContext.Consumer>
    {({selectionContext}) => (
      <EscalationProgressReviewStep selectedEscalationLadder={selectionContext.selectedEscalationLadder} {...props} />
    )}
  </EscalationModalStepVariableContext.Consumer>
);
