import * as React from 'react';
import {AppDispatch, typedUseSelector} from '../../../redux';
import {useDispatch} from 'react-redux';
import {User} from '../../../types';
import {UserViewModel} from '../../ContactsPage/viewModels/UserViewModel';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import {getFullName} from '../../../utils/user';
import {AutocompleteRenderInputParams} from '@material-ui/lab/Autocomplete';
import Chevron from '../../../svgs/Chevron';
import {AutoCompleteDropDown} from '../../../@hc-ui/components/autocomplete/AutoCompleteDropDown';
import {SCHEDULING_PAGE_USER_SELECTOR_INPUT_PLACEHOLDER} from 'src/constants/strings';
import {FilterOptionsState} from '@material-ui/lab';

interface UserSwitcherProps {
  departmentId: number;
}

const dropdownWidth = 300;
const lineHeight = 45;

const MenuItem = styled.div`
  width: ${dropdownWidth}px;
  height: ${lineHeight}px;
  line-height: ${lineHeight}px;
  padding: 0 1em;
  user-select: none;
  font-size: 16px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover {
    background-color: whitesmoke;
  }
`;

export const UserSwitcher = ({departmentId}: UserSwitcherProps) => {
  const filterByUser = typedUseSelector((state) => state.tradeShift.filterByUser);

  const dispatch: AppDispatch = useDispatch();
  const userViewModel = UserViewModel();

  const {data, loading, error} = userViewModel.fetchDepartmentUsers({departmentId});

  const handleClearUser = () => dispatch({type: 'tradeShift/CLEAR_TARGET_USER'});

  const handleFilterByUser = (user: User | null) => {
    if (!user || user.id === 'user-all') {
      handleClearUser();
    } else {
      dispatch({
        type: 'tradeShift/SET_TARGET_USER',
        payload: {user},
      });
    }
  };

  if (loading || error) {
    return <TextField id="dorpdownInput" disabled value={'loading...'} variant="outlined" />;
  }

  const users =
    data?.locating?.department?.members?.sort((userA, userB) =>
      getFullName(userA).toLowerCase().localeCompare(getFullName(userB).toLowerCase()),
    ) || [];

  const userListWithAllOption = [{id: 'user-all', firstname: 'All', lastname: ''} as User, ...users];

  const renderUserListItem = (user: User) => {
    if (user.id === 'user-all') {
      return (
        <MenuItem
          key="user-all"
          onKeyDown={() => handleFilterByUser(null)}
          onClick={() => handleFilterByUser(null)}
          title="All"
        >
          All
        </MenuItem>
      );
    }

    return (
      <MenuItem
        key={`user-${user.id}`}
        onKeyDown={() => handleFilterByUser(user)}
        onClick={() => handleFilterByUser(user)}
        title={getFullName(user)}
      >
        {getFullName(user)}
      </MenuItem>
    );
  };

  const renderInputField = (params: AutocompleteRenderInputParams) => (
    <TextField
      {...params}
      color={'primary'}
      variant="outlined"
      placeholder={SCHEDULING_PAGE_USER_SELECTOR_INPUT_PLACEHOLDER}
      inputProps={{
        ...params.inputProps,
        autoComplete: 'new-password',
      }}
    />
  );

  const handleChange = (event, value) => {
    handleFilterByUser(value);
  };

  const filterDropdownUserItems = (options: User[], state: FilterOptionsState<User>) => {
    const inputValue = state.inputValue.split(' ');

    return options.filter((optionItem) => {
      const combinedName = optionItem.firstname + ' ' + optionItem.lastname;

      return inputValue.every((value: string) => combinedName.toLowerCase().includes(value.toLowerCase()));
    });
  };

  return (
    <div>
      {users && (
        <AutoCompleteDropDown
          filterOptions={filterDropdownUserItems}
          options={userListWithAllOption}
          currentOption={filterByUser ? filterByUser : userListWithAllOption[0]}
          renderOptions={renderUserListItem}
          renderInput={renderInputField}
          popupIcon={<Chevron />}
          renderOptionLabel={(option) => getFullName(option)}
          onChange={handleChange}
        />
      )}
    </div>
  );
};
