import React from 'react';
import {HCLabelOne} from 'src/components/HypercareComponents';
import {exhaustiveGuard} from 'src/utils/exhaustiveGuard';
import styled from 'styled-components/macro';
import {FormFieldInputDropdown, FormFieldInputDropdownProps} from './FormFieldInput/FormFieldInputDropdown';
import {FormFieldInputText} from './FormFieldInput/FormFieldInputText';
import {FormFieldInputPhone} from './FormFieldInput/FormFieldInputPhone';
import {FormFieldInputDate} from './FormFieldInput/FormFieldInputDate';
import {FormFieldSyncWrapper} from './FormFieldInput/FormFieldSyncWrapper';
import {CPF_PLACEHOLDER_LABEL_TEXT} from 'src/constants/strings';

type FormFieldType = 'text' | 'number' | 'date' | 'phone' | 'email' | 'url' | 'dropdown';

type FormFieldBaseProps = {
  fieldType: FormFieldType;
  fieldId: string;
  mode: 'view' | 'edit';
  disabled: boolean;
  error?: string;
  onChangeLabel(newLabel: string): void;
  isRequired: boolean;
  placeholder: string;
  label: string;
  labelOptions: string[];
  isSynced: boolean;
  isManagedUser: boolean;
};

type FormFieldDropdownProps<TOption> = {
  fieldType: 'dropdown';
  value?: TOption;
  onChange: (selectedOption: TOption) => void;
} & FormFieldInputDropdownProps<TOption>;

type FormFieldTextProps = {
  fieldType: 'text';
  value: string;
  onChange(newValue: string): void;
  onBlur(): void;
};

type FormFieldNumberProps = {
  fieldType: 'number';
  value: string;
  onChange(newValue: number): void;
  onBlur(): void;
};

type FormFieldDateProps = {
  fieldType: 'date';
  value?: Date;
  onChange(newDate: Date): void;
};

type FormFieldPhoneProps = {
  fieldType: 'phone';
  value: string;
  onChange(newValue: string): void;
  onBlur(): void;
};

type FormFieldEmailProps = {
  fieldType: 'email';
  value: string;
  onChange(newValue: string): void;
  onBlur(): void;
};

type FormFieldURLProps = {
  fieldType: 'url';
  value: string;
  onChange(newValue: string): void;
  onBlur(): void;
};

export type FormFieldProps<TOption = null> = FormFieldBaseProps &
  (
    | FormFieldDropdownProps<TOption>
    | FormFieldTextProps
    | FormFieldNumberProps
    | FormFieldDateProps
    | FormFieldPhoneProps
    | FormFieldEmailProps
    | FormFieldURLProps
  );

type FormFieldLabelProps = {
  fieldId: string;
  label: string;
  labelOptions: string[];
  onChange(newLabel: string): void;
  disabled: boolean;
  mode: 'view' | 'edit';
  isSynced: boolean;
  isManagedUser: boolean;
};

const FormFieldContainer = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  padding: 12px 0;
`;

const FormFieldLabelContainer = styled.div`
  flex-basis: 100%;
`;

const FormFieldInputContainer = styled.div`
  flex-basis: 100%;
`;

const FormFieldLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const FormFieldLabel = (props: FormFieldLabelProps) => {
  const shouldShowSynced = props.isSynced && props.isManagedUser;
  if (props.mode === 'view' || shouldShowSynced) {
    return (
      <FormFieldLabelWrapper>
        <HCLabelOne>{props.label}</HCLabelOne>
        {shouldShowSynced && <FormFieldSyncWrapper showIconWrapper={true} />}
      </FormFieldLabelWrapper>
    );
  }

  switch (props.labelOptions.length) {
    case 0:
      // Free form profile field label
      return (
        <FormFieldInputText
          inputType="text"
          onChange={props.onChange}
          value={props.label}
          disabled={props.disabled}
          isRequired={false}
          mode="edit"
          placeholder={'Select a label...'}
          fieldId={props.fieldId}
          onBlur={() => {}}
        />
      );
    case 1:
      // Static profile field label
      return <HCLabelOne>{props.label}</HCLabelOne>;
    default:
      // Dropdown profile field label
      return (
        <FormFieldInputDropdown
          value={props.label}
          options={props.labelOptions}
          getOptionId={(o) => o}
          getIsOptionSelected={(o) => o === props.label}
          onOptionClick={props.onChange}
          onClickAway={() => {}}
          renderOptionContent={(o) => o}
          mode="edit"
          placeholder={'Select a label...'}
          isRequired={false}
          renderValue={(o) => o}
          disabled={props.disabled}
        />
      );
  }
};

const FormFieldInput = <TOption,>(props: FormFieldProps<TOption>) => {
  switch (props.fieldType) {
    case 'date':
      return <FormFieldInputDate {...props} />;
    case 'number':
      return <FormFieldInputText inputType="number" {...props} onChange={(v) => props.onChange(parseInt(v))} />;
    case 'text':
      return <FormFieldInputText inputType="text" {...props} />;
    case 'email':
      return <FormFieldInputText inputType="email" {...props} />;
    case 'url':
      return <FormFieldInputText inputType="url" {...props} />;
    case 'phone':
      return <FormFieldInputPhone {...props} />;
    case 'dropdown':
      return <FormFieldInputDropdown {...props} onOptionClick={(o) => props.onChange(o)} />;
    default:
      return exhaustiveGuard(props);
  }
};

export const FormField = <TOption,>(props: FormFieldProps<TOption>) => {
  return (
    <FormFieldContainer>
      <FormFieldLabelContainer>
        <FormFieldLabel {...props} onChange={props.onChangeLabel} />
        <FormFieldInput {...props} />
      </FormFieldLabelContainer>
      <FormFieldInputContainer></FormFieldInputContainer>
    </FormFieldContainer>
  );
};
