import React from 'react';
import {WideWidthStyleModal, CloseBtn, Header} from 'src/styles/styled-components/ModalStyleComponents';
import EscalationModalStepProvider from 'src/pages/MessengerPage/escalation-modal/EscalationModalStepProvider';
import {
  EscalationLadderSelectionStep,
  EscalationProgressReviewStep,
  EscalationTypeSelectionStep,
} from 'src/pages/MessengerPage/escalation-modal/EscalationTriggerSteps';
import IndividualEscalationDoubleConfirmationModal from 'src/pages/MessengerPage/escalation-modal/IndividualEscalationDoubleConfirmationModal';
import AppTheme from 'src/styles/theme';
import {ThemeProvider} from '@material-ui/styles';
import {createTheme} from '@material-ui/core/styles';
import getParsedAuthInfo from 'src/utils/localStorageHandler';
import client from 'src/apollo';
import {tryReadChatQuery} from 'src/utils/messengerHelper/getChatFromId';
import {Chat} from 'src/types';
import getChatTitle from 'src/utils/messengerHelper/getChatTitle';
import {MessageWrapper, MessageWrapperText, MessageWrapperTitle} from 'src/styles/styled-components/Escalation';

const escalationTheme = createTheme({
  palette: {
    primary: {main: AppTheme.chatTeal},
    secondary: {main: AppTheme.redPink},
  },
});

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  messageId: string;
  chatId: string;
  message: string;
}

const EscalationModalContainer = ({isOpen, closeModal, message, chatId, messageId}: Props) => {
  const [showIndividualEscalationModal, setShowIndividualEscalationModal] = React.useState(false);
  const [currentStep, setCurrentStep] = React.useState<number>(0);

  const parsedAuthInfo = getParsedAuthInfo();
  const selfID = parsedAuthInfo ? parsedAuthInfo.user.id : null;

  const chatQuery: {chat: Chat} = tryReadChatQuery(client, chatId);
  const targetUser = chatQuery.chat.members.find((user) => user.id !== selfID);
  const chatTitle = getChatTitle(chatQuery.chat);

  const generateTitle: string = React.useMemo(() => {
    switch (currentStep) {
      case 0:
        return 'Escalate a message';
      case 1:
        return 'Select a department escalation';
      case 2:
        return 'Start a department escalation';
      default:
        return '';
    }
  }, [currentStep]);

  return (
    <EscalationModalStepProvider message={message}>
      <ThemeProvider theme={escalationTheme}>
        <WideWidthStyleModal
          isOpen={isOpen}
          shouldCloseOnEsc={false}
          ariaHideApp={false}
          shouldCloseOnOverlayClick={false}
          onRequestClose={closeModal}
        >
          <Header>
            <span>{generateTitle}</span>
            <CloseBtn onClick={closeModal} />
          </Header>

          <MessageWrapper>
            <MessageWrapperTitle>Escalating this message</MessageWrapperTitle>
            <MessageWrapperText>{message}</MessageWrapperText>
          </MessageWrapper>

          {currentStep === 0 && (
            <EscalationTypeSelectionStep
              setCurrentStep={setCurrentStep}
              closeModal={closeModal}
              chatTitle={chatTitle}
              showIndividualEscalationModal={() => setShowIndividualEscalationModal(true)}
            />
          )}
          {currentStep === 1 && (
            <EscalationLadderSelectionStep
              setCurrentStep={setCurrentStep}
              targetUser={targetUser}
              showIndividualEscalationModal={() => setShowIndividualEscalationModal(true)}
            />
          )}
          {currentStep === 2 && (
            <EscalationProgressReviewStep
              closeModal={closeModal}
              setCurrentStep={setCurrentStep}
              chatId={chatId}
              messageId={messageId}
            />
          )}
        </WideWidthStyleModal>
        {showIndividualEscalationModal && (
          <IndividualEscalationDoubleConfirmationModal
            isOpen={showIndividualEscalationModal}
            closeAll={closeModal}
            targetUser={targetUser}
            closeModal={() => setShowIndividualEscalationModal(false)}
            chatTitle={chatTitle}
            messageId={messageId}
            chatId={chatId}
          />
        )}
      </ThemeProvider>
    </EscalationModalStepProvider>
  );
};

export default EscalationModalContainer;
