import React from 'react';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import styled from 'styled-components';
import {QueryResult} from 'react-apollo';
import {useQuery} from '@apollo/react-hooks';
import {FetchDepartmentUsersResult, User} from 'src/types';
import FetchDepartmentUsers from 'src/gql/query/FetchDepartmentUsers';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Chevron from 'src/svgs/Chevron';
import {getFullName} from 'src/utils/user';
import {typedUseSelector, AppDispatch} from 'src/redux';
import {useDispatch} from 'react-redux';
import getParsedAuthInfo from 'src/utils/localStorageHandler';

const lineHeight = 45;

const DropdownWrapper = styled.div``;

const StyledPopper = styled(Popper)`
  max-width: 300px;
  margin-top: 5px;
  z-index: 3;
`;

const StyledTextField = styled(TextField)`
  width: 100%;
  .MuiInputBase-root {
    position: relative;
    height: ${lineHeight}px;
    .MuiOutlinedInput-input {
      padding: 0 1em;
      height: min-content;
      line-height: ${lineHeight}px;
    }
  }
`;

const StyledMenuPaper = styled(Paper)<{width: number}>`
  width: ${(props) => `${props.width}px`};
  position: relative;
  max-height: 50vh;
  overflow-y: auto;
  overflow-x: hidden;
`;

const MenuItem = styled.div<{isCurrent: boolean}>`
  height: ${lineHeight}px;
  line-height: ${lineHeight}px;
  padding: 0 1em;
  user-select: none;
  font-size: 16px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: ${(props) => (props.isCurrent ? 'lightgrey' : 'unset')};
  &:hover {
    background-color: whitesmoke;
  }
`;

const UserOptionsDropdown = ({departmentId}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement>(null);
  const parsedAuthInfo = getParsedAuthInfo();
  const selfID = parsedAuthInfo && parsedAuthInfo.user.id;

  const filterByUser = typedUseSelector((state) => state.tradeShift.filterByUser);
  const dispatch: AppDispatch = useDispatch();
  const handleClearUser = () => dispatch({type: 'tradeShift/CLEAR_TARGET_USER'});

  const handleFilterByUser = (user: User) => {
    setAnchorEl(null);
    if (!user) {
      handleClearUser();
    } else {
      dispatch({
        type: 'tradeShift/SET_TARGET_USER',
        payload: {user},
      });
    }
  };

  const {loading, error, data}: QueryResult<FetchDepartmentUsersResult> = useQuery(FetchDepartmentUsers, {
    variables: {
      departmentId: departmentId,
    },
    fetchPolicy: 'no-cache',
  });

  if (loading || error) {
    return <StyledTextField id="dorpdownInput" disabled value={'loading...'} variant="outlined" />;
  }

  const users = data?.locating?.department?.members?.sort((userA, userB) =>
    getFullName(userA).toLowerCase().localeCompare(getFullName(userB).toLowerCase()),
  );

  const handleInputClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClickAway = () => {
    setAnchorEl(null);
    document.getElementById('dorpdownInput').blur();
  };

  return (
    <DropdownWrapper id="dorpdownInputWrapper">
      <StyledTextField
        id="dorpdownInput"
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <Chevron />
            </InputAdornment>
          ),
        }}
        value={filterByUser ? getFullName(filterByUser) : 'All'}
        onClick={handleInputClick}
        variant="outlined"
      />
      <StyledPopper open={Boolean(anchorEl)} anchorEl={anchorEl} placement={'bottom-start'} transition>
        {({TransitionProps}) => (
          <ClickAwayListener onClickAway={handleClickAway}>
            <Fade {...TransitionProps} timeout={350}>
              <StyledMenuPaper width={document.getElementById('dorpdownInputWrapper').clientWidth}>
                <MenuItem isCurrent={!filterByUser} key="user-all" onClick={() => handleFilterByUser(null)} title="All">
                  All
                </MenuItem>
                {users.map(
                  (user) =>
                    user.id !== selfID && (
                      <MenuItem
                        isCurrent={filterByUser && filterByUser.id === user.id}
                        key={`user-${user.id}`}
                        onClick={() => handleFilterByUser(user)}
                        title={getFullName(user)}
                      >
                        {getFullName(user)}
                      </MenuItem>
                    ),
                )}
              </StyledMenuPaper>
            </Fade>
          </ClickAwayListener>
        )}
      </StyledPopper>
    </DropdownWrapper>
  );
};

export default UserOptionsDropdown;
