import React, {useEffect, useState, useRef} from 'react';
import Chevron from 'src/svgs/Chevron';
import Check from 'src/svgs/Check';
import styled from 'styled-components';
import {UserOrganizationSwitcherPayload, OrganizationScope} from 'src/types';
import FetchUnreadChatCount from 'src/gql/query/FetchUnreadChatCount';
import {useQuery} from '@apollo/react-hooks';
import AnalyticsManager, {EVENTS, PAGE_VIEWS} from 'src/analytics/AnalyticsManager';
import {QueryResult} from 'react-apollo';
import {ApolloClient} from 'apollo-client';
import client from 'src/apollo';
import {useFetchUsersOrganizations} from 'src/utils/fetchUsersOrganizations';
import OrganizationDropdownLoader from 'src/components/loaders/OrganizationDropdownLoader';

const itemHeight = 40;

const DropdownToggler = styled.div`
  display: flex;
  margin-left: 20px;
  cursor: pointer;
  align-items: center;
  user-select: none;
  justify-content: center;
`;

const ChevronHolder = styled.div`
  display: flex;
  align-items: center;
  svg {
    transition: transform 200ms ease-in-out;
  }
`;

const DropdownMenu = styled.ul`
  list-style: none;
  padding: 0;
  z-index: 9;
  position: absolute;
  top: 0;
  left: 50px;
  height: auto;
  border-radius: 6px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.25);
  background-color: white;
  margin-top: 50px;
  min-height: ${itemHeight}px;
  max-height: 600px;
  overflow-y: auto;
`;

const DropdownItem = styled.li`
  line-height: ${itemHeight}px;
  position: relative;
  font-size: 16px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: left;
  min-height: ${itemHeight}px;
  cursor: pointer;
  border-top: solid 0.5px lightgrey;
  display: flex;
  align-items: center;
  &:active {
    opacity: 0.5;
  }
  &:hover {
    color: black;
    background-color: ${(props) => props.theme.lightGrey};
  }
  &:first-of-type {
    border-top: none !important;
  }
  svg {
    width: 14px !important;
    height: 14px !important;
  }
`;

const OrganizationName = styled.div`
  padding-right: 20px;
`;

const OrganizationLogoHolder = styled.div`
  height: 14px;
  padding-right: 10px;
  display: flex;
  width: 80px;
  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
`;

const UnreadChatCount = styled.div`
  margin-left: auto;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  width: 22px;
  min-width: 22px;
  height: 22px;
  background-color: #ff3e55;
  font-size: 12px;
  color: white;
`;

interface Props {
  onSelect: (option: UserOrganizationSwitcherPayload, isAcceptingTOS?: boolean) => Promise<void>;
  client?: ApolloClient<any>;
  currentOrganization: UserOrganizationSwitcherPayload;
}

// TODO: in future track unread count somewhere else and reflects the changes base on message read and incoming message socket event;
interface SelfUnreadChatCountResult {
  me: {
    unreadChatCount: {
      count: number;
      organizationId: number;
    }[];
  };
}

const SwitchOrganizationDropdown = ({onSelect, currentOrganization}: Props) => {
  const node = useRef(null);
  const [isOpen, setOpen] = useState(false);

  const {data}: QueryResult<SelfUnreadChatCountResult> = useQuery(FetchUnreadChatCount);

  const {fetchUsersOrganizations, organizationListData, loading} = useFetchUsersOrganizations();

  const unreadList = data && data.me ? data.me.unreadChatCount : null;

  const getCountFromUnreadList = (orgId: number): string => {
    if (!unreadList) return null;
    const target = unreadList.find((list) => list.organizationId === orgId);
    return target ? (target.count > 99 ? '99+' : `${target.count}`) : null;
  };

  const handleClickAway = (e) => {
    if (node && node.current && node.current.contains(e.target)) return;
    setOpen(false);
  };

  const handleChange = (option: UserOrganizationSwitcherPayload, index: number) => {
    setOpen(false);
    onSelect(option);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickAway);
    return () => {
      document.removeEventListener('mousedown', handleClickAway);
    };
  }, []);

  useEffect(() => {
    if (isOpen) {
      AnalyticsManager.recordPageVisited(PAGE_VIEWS.switchOrganizationView);
      client.query({
        query: FetchUnreadChatCount,
        fetchPolicy: 'network-only',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleSwitchOrganizationDropdown = () => {
    setOpen(!isOpen);

    fetchUsersOrganizations();

    if (!isOpen) {
      AnalyticsManager.applyAnalytics({
        eventName: EVENTS.switchOrganizationMenuOpened,
      });
    }
  };

  const sortedOrganizationList = organizationListData?.me?.organizations.sort((a, b) =>
    a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
  );

  return (
    <DropdownToggler ref={node} title="switch organization" onClick={() => handleSwitchOrganizationDropdown()}>
      <React.Fragment>
        {currentOrganization && currentOrganization.name
          ? currentOrganization.name
          : sortedOrganizationList && sortedOrganizationList?.length > 0
          ? 'Click here to select an organization'
          : ''}

        <ChevronHolder>{isOpen ? <Chevron transform={180} /> : <Chevron />}</ChevronHolder>
      </React.Fragment>
      {isOpen && (
        <DropdownMenu>
          {loading && <OrganizationDropdownLoader width="350px" height="300px" />}
          {sortedOrganizationList &&
            !loading &&
            sortedOrganizationList.map((organization, i) => {
              let unreadChatCount = getCountFromUnreadList(organization.id);
              return (
                <DropdownItem
                  key={`${organization.id}-${i}`}
                  onClick={() =>
                    handleChange(
                      {
                        name: organization.name,
                        type: organization.__typename,
                        organizationId: organization.id,
                        image: organization.image,
                        isAdmin: organization.isAdmin,
                      } as UserOrganizationSwitcherPayload,
                      i,
                    )
                  }
                >
                  <OrganizationLogoHolder>
                    {organization.image && (
                      <img
                        src={organization.image}
                        alt="organization logo"
                        // @ts-ignore TODO: need fall back image
                        onError={(e) => (e.target.style.display = 'none')}
                      />
                    )}
                  </OrganizationLogoHolder>

                  <OrganizationName>{organization.name}</OrganizationName>

                  {currentOrganization.organizationId === organization.id &&
                  currentOrganization.type === 'Organization' ? (
                    <Check />
                  ) : (
                    unreadList && parseInt(unreadChatCount) > 0 && <UnreadChatCount>{unreadChatCount}</UnreadChatCount>
                  )}
                </DropdownItem>
              );
            })}
        </DropdownMenu>
      )}
    </DropdownToggler>
  );
};

export default SwitchOrganizationDropdown;
