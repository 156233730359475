import * as React from 'react';
import styled from 'styled-components';
import {usePopper} from 'react-popper';
import TripleDot from 'src/svgs/TripleDot';
import {
  OptionPopperBox,
  MessageBubblePopperArrow,
  MessageBubblePopperContainer,
} from 'src/pages/MessengerPage/messenger/messages-layout/message-item/message-option-popper/MessageOptionPopperStyles';

const OptionIconHolder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2em;
`;

interface Props {
  handleMoreOptionClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const MessageDetailOptionPopper = ({handleMoreOptionClick}: Props) => {
  const [isMessageDetailOptionHovered, setMessageDetailOptionHovered] = React.useState(false);
  const [referenceElement, setReferenceElement] = React.useState(null);
  const [popperElement, setPopperElement] = React.useState(null);
  const [arrowElement, setArrowElement] = React.useState(null);

  const {styles, attributes} = usePopper(referenceElement, popperElement, {
    modifiers: [
      {
        name: 'arrow',
        options: {
          element: arrowElement,
        },
      },
    ],
    placement: 'top',
  });

  const handleOnClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    handleMoreOptionClick(e);
  };

  return (
    <React.Fragment>
      <OptionPopperBox
        className={'MessageOptions'}
        onClick={handleOnClick}
        onMouseEnter={() => setMessageDetailOptionHovered(true)}
        onMouseLeave={() => setMessageDetailOptionHovered(false)}
        ref={setReferenceElement}
      >
        <OptionIconHolder>
          <TripleDot />
        </OptionIconHolder>
      </OptionPopperBox>

      {isMessageDetailOptionHovered && (
        <MessageBubblePopperContainer ref={setPopperElement} style={styles.popper} {...attributes.popper}>
          More
          <MessageBubblePopperArrow ref={setArrowElement} style={styles.arrow} className="arrow" />
        </MessageBubblePopperContainer>
      )}
    </React.Fragment>
  );
};

export default MessageDetailOptionPopper;
