import React from 'react';
import styled from 'styled-components';
import {UserAddress} from 'src/types';
import {TrashBinIcon} from 'src/svgs/TrashBinIcon';
import HypercareSelfProfileViewModel from 'src/pages/LocatingPage/viewModels/HypercareSelfProfileViewModel';
import {toast} from 'react-toastify';
import {PROFILE_ADDRESS_DELETE_LAST_ADDRESS_ERROR, SYNCED_INFORMATION} from 'src/constants/strings';
import AnalyticsManager, {EVENTS} from 'src/analytics/AnalyticsManager';
import {FormFieldSyncWrapper} from 'src/components/@hc-ui/components/FormField/FormFieldInput/FormFieldSyncWrapper';
import {StyledTooltipWhite} from 'src/components/StyledTooltip';

const DeleteRowIconHolder = styled.button`
  color: grey;
  position: relative;
  margin-left: 3px;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface Props {
  currentAddress: UserAddress;
  setCurrentAddresses?: React.Dispatch<React.SetStateAction<UserAddress[]>>;
  addresses: UserAddress[];
}

const DeleteAddressActionIcon = ({currentAddress, addresses, setCurrentAddresses}: Props) => {
  const {handleUpdateDeleteAddress} = HypercareSelfProfileViewModel();

  const handleDeleteInfo = async () => {
    if (addresses.length <= 1) {
      toast.error(PROFILE_ADDRESS_DELETE_LAST_ADDRESS_ERROR, {
        toastId: 'failed1',
      });
      return;
    }

    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.editProfileRemoveButtonPressed,
    });

    const newAddressArray = addresses.filter((address) => address.id !== currentAddress.id);
    setCurrentAddresses?.(newAddressArray);
    handleUpdateDeleteAddress(currentAddress);
  };

  return (
    <DeleteRowIconHolder type="button" disabled={currentAddress?.syncStatus} onClick={handleDeleteInfo}>
      {currentAddress.syncStatus ? (
        <StyledTooltipWhite arrow title={SYNCED_INFORMATION} placement="bottom-start" className="styledPopper">
          <div>
            <FormFieldSyncWrapper showIconWrapper={false} />
          </div>
        </StyledTooltipWhite>
      ) : (
        <TrashBinIcon color={'#FF3E55'} />
      )}
    </DeleteRowIconHolder>
  );
};

export default DeleteAddressActionIcon;
