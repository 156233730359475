import {HCFilterSearchable} from 'src/@hc-ui/components/filters/variants/HCFilterSearchable';
import {getFullName} from '../../../../utils/user';
import Popup from 'reactjs-popup';
import React, {useState} from 'react';
import ReceipientIcon from 'src/svgs/ReceipientIcon';
import {NumberBadge, TableFilterContainer} from './TableFilters';
import ChevronUpIcon from 'src/svgs/ChevronUpIcon';
import ChevronDownIcon from 'src/svgs/ChevronDownIcon';

export const EventReceiverFilter = ({auditLogFilters, userOptions, roleOptions}) => {
  const {selectedReceiverIds, setSelectedReceiverIds} = auditLogFilters;
  const [isOpen, setIsOpen] = useState(false);

  const ReceiverFilter = React.forwardRef(({...props}, ref) => (
    <TableFilterContainer ref={ref as any} {...props} hasSelection={selectedReceiverIds.length > 0} isOpen={isOpen}>
      <ReceipientIcon /> Receiver{' '}
      {selectedReceiverIds.length > 0 && <NumberBadge>{selectedReceiverIds.length}</NumberBadge>}
      {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
    </TableFilterContainer>
  ));
  return (
    <Popup
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      trigger={() => <ReceiverFilter />}
      position="bottom left"
      closeOnDocumentClick
    >
      {(close) => (
        <HCFilterSearchable
          title="Receiver"
          onClose={close}
          options={[
            {
              title: 'Users',
              options: userOptions,
            },
            {
              title: 'Roles',
              options: roleOptions,
            },
          ]}
          onToggle={(id: string) => {
            const found = selectedReceiverIds.find((selectedId) => selectedId === id);
            if (found) {
              setSelectedReceiverIds([...selectedReceiverIds.filter((selectedId) => selectedId !== id)]);
            } else {
              setSelectedReceiverIds([...selectedReceiverIds, id]);
            }
          }}
          optionRender={(option) => {
            return (
              <div style={{display: 'flex', flexDirection: 'column'}}>
                <div style={{paddingBottom: 4}}>{option.label}</div>
                <div style={{fontWeight: 400, fontSize: 14, color: '#4A4A4A'}}>{option.value.role}</div>
              </div>
            );
          }}
          onSearch={(query, row) => {
            const contact = row.value;
            if (contact.role) {
              return (
                contact.role?.toLowerCase().includes(query.toLowerCase()) ||
                getFullName(contact).toLowerCase().includes(query.toLowerCase())
              );
            } else {
              return row.label?.toLowerCase().includes(query.toLowerCase());
            }
          }}
          onClear={() => {
            setSelectedReceiverIds([]);
            close();
          }}
          selectedIds={selectedReceiverIds}
        />
      )}
    </Popup>
  );
};
