import React from 'react';
import * as R from 'ramda';
import {toast} from 'react-toastify';
import client from 'src/apollo';
import {AckEscalationResult, Chat, GetAllActiveEscalationsResult} from 'src/types';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';
import AcknowledgeEscalationMutation from 'src/gql/mutation/AcknowledgeEscalationMutation';
import GetChatQuery from 'src/gql/query/GetChatQuery';
import {CheckActiveEscalationExists} from 'src/gql/query/FetchAllActiveEscalations';

const AckBtnWrapper = styled.div`
  width: 100%;
  padding: 1em;
`;

const StyledButton = styled(Button)`
  text-transform: unset !important;
  height: 44px;
  width: 100%;
  background-color: ${(props) => props.theme.redPink} !important;
`;

interface Props {
  chat: Chat;
}

const AckEscalationChatButton = ({chat}: Props) => {
  const [isLoading, setLoading] = React.useState(false);

  const tryAckEscalation = async () => {
    try {
      setLoading(true);
      const {data} = await client.mutate({
        mutation: AcknowledgeEscalationMutation,
        variables: {
          activatedEscalationId: chat.escalation.id,
        },
      });

      const result: AckEscalationResult = data;
      const chatId = chat.id;

      // update query under cached chat
      const chatQuery = client.readQuery({
        query: GetChatQuery,
        variables: {chatId},
      });

      const newChatQuery = R.evolve({
        chat: {
          escalation: result.escalation.activated.acknowledge,
        },
      })(chatQuery);

      client.writeQuery({
        query: GetChatQuery,
        variables: {chatId},
        data: newChatQuery,
      });

      const allActiveEscalationsQuery: GetAllActiveEscalationsResult = client.readQuery({
        query: CheckActiveEscalationExists,
      });

      // update query under cached me
      const newAllActiveEscalationsQuery = R.evolve({
        me: {
          activeEscalations: allActiveEscalationsQuery.me.activeEscalations.filter(
            (escalation) => escalation.id === result.escalation.activated.acknowledge.id,
          ),
        },
      })(allActiveEscalationsQuery);

      client.writeQuery({
        query: CheckActiveEscalationExists,
        data: newAllActiveEscalationsQuery,
      });
    } catch (e) {
      console.error(e);
      setLoading(false);
      toast.error('Failed to acknowledge the escalation, please check your internet connection and try again');
    }
  };

  return (
    <AckBtnWrapper>
      <StyledButton
        disabled={isLoading}
        disableTouchRipple
        variant="contained"
        color="secondary"
        onClick={tryAckEscalation}
      >
        Click here to acknowledge and end escalation
      </StyledButton>
    </AckBtnWrapper>
  );
};

export default AckEscalationChatButton;
