import React, {VFC} from 'react';
import styled from 'styled-components';
import {StyledTextButton} from './filters/TableFilters';
import NoSearchResultsIcon from '../../../svgs/NoSearchResultsIcon';

const Container = styled.div`
  width: 100%;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
`;

interface Props {
  onClearFilters: () => void;
  numFiltersSelected: number;
}

const Title = styled.div`
  font-weight: 700;
  padding-bottom: 17px;
  line-height: 24px;
  font-family: Nunito Sans;
`;

const Subtitle = styled.div`
  padding-bottom: 15px;
  font-family: Open Sans;
  font-size: 16px;
  line-height: 24px;
  color: #4a4a4a;
`;

export const MuiSpan = styled.span`
  font-family: 'Nunito Sans';
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
`;

export const EmptyTable: VFC<Props> = ({onClearFilters, numFiltersSelected}) => {
  return (
    <Container>
      <div style={{paddingBottom: 20}}>
        <NoSearchResultsIcon />
      </div>
      <Title>No results found</Title>
      <Subtitle>Try modifying the filters or refresh the page and try again.</Subtitle>
      {numFiltersSelected > 0 && (
        <StyledTextButton
          color="inherit"
          variant="text"
          onClick={() => onClearFilters()}
          disableTouchRipple
          disableRipple
          style={{
            textTransform: 'none',
            color: numFiltersSelected > 0 && '#00859A',
          }}
        >
          <MuiSpan>Clear all filters ({numFiltersSelected})</MuiSpan>
        </StyledTextButton>
      )}
    </Container>
  );
};
