import styled from 'styled-components';

export const MessageWrapper = styled.div`
  background-color: ${(props) => props.theme.priorityYellow};
  display: flex;
  flex-direction: column;
  text-align: center;
  border-radius: 10px;
  padding: 16px 8px;
  margin-bottom: 24px;
`;

export const MessageWrapperText = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  display: -webkit-box;
  overflow-wrap: anywhere;
  line-break: strict;
  line-height: 1.3em;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const MessageWrapperTitle = styled.div`
  font-size: 12px;
  text-transform: uppercase;
  line-height: 18px;
  margin-bottom: 4px;
`;

export const EscalationType = styled.div`
  font-size: 14px;
  color: ${(props) => props.theme.greyishBrown};
`;

export const EscalationBannerTitle = styled.div`
  font-weight: bold;
  line-height: 1.5em;
  color: ${(props) => props.theme.blackFontColor};
  margin: 1em 0;
  display: -webkit-box;
  overflow-wrap: anywhere;
  line-break: strict;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const EscalationLadderPreviewWrapper = styled.div<{
  $isActive: boolean;
}>`
  display: flex;
  border-radius: 8px;
  background-color: ${(props) => (props.$isActive ? props.theme.priorityYellow : props.theme.ivory)};
  padding: 1em;
`;

export const EscalationLadderPreviewInfoHolder = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const EscalationLadderSubTextHolder = styled.div`
  font-size: 12px;
  color: ${(props) => props.theme.greyishBrown};
`;

export const OverrideEscalationLadderPreviewWrapper = styled(EscalationLadderPreviewWrapper)`
  margin: 1em;
  cursor: pointer;
  box-shadow: 1px 4px 5px 2px rgba(196, 196, 196, 0.21);
`;
