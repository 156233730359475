import * as React from 'react';
import {pathOr} from 'ramda';
import {Query, QueryResult} from 'react-apollo';
import {ApolloClient} from 'apollo-client';
import styled from 'styled-components';
import {HeaderDiv} from 'src/styles/styled-components/StyledMessagesHeader';
import GetColleaguesQuery from 'src/gql/query/GetColleaguesQuery';
import {User, UserSelectOptionType} from 'src/types';
import {connect} from 'react-redux';
import {actions} from 'src/redux/actions/colleagues';
import AddChatMemberListSelect from 'src/pages/MessengerPage/messenger/new-chat-layout/CreateNewChatInputDropdown';

const SendMessageTo = styled.span`
  color: ${(props) => props.theme.warmGrey};
  font-size: 0.8em;
  white-space: nowrap;
  padding-right: 0.5em;
`;

const CreateChatHeaderDiv = styled(HeaderDiv)`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  height: max-content;
  padding: 10px;
  justify-content: flex-start;
`;

interface Props {
  colleagues: User[];
  client: ApolloClient<any>;
  selectedColleagues: Set<{id: string; label: string; role?: string}>;
  setSelectedColleagues: (colleagues) => void;
}

class CreateNewChatHeader extends React.PureComponent<Props> {
  public suggestions: any;

  public componentDidMount() {
    this.setSelectSuggestion();
  }

  public render() {
    const {selectedColleagues, setSelectedColleagues} = this.props;
    if (!this.suggestions) this.setSelectSuggestion();
    const reduxStoreSelectedColleagues = Array.from(selectedColleagues).map((colleague) => {
      return {
        value: colleague.id,
        label: colleague.label,
        role: colleague.role,
      } as UserSelectOptionType;
    });
    return (
      <CreateChatHeaderDiv>
        <SendMessageTo>Send message to:</SendMessageTo>
        <AddChatMemberListSelect
          suggestions={this.suggestions}
          reduxStoreSelectedColleagues={reduxStoreSelectedColleagues}
          setReduxSelectedColleagues={setSelectedColleagues}
        />
      </CreateChatHeaderDiv>
    );
  }

  private setSelectSuggestion = () => {
    const {colleagues} = this.props;
    this.suggestions = Array.from(colleagues).map((colleague) => {
      return {
        value: colleague.id,
        label: colleague.firstname + ' ' + colleague.lastname,
        role: colleague.role,
      } as UserSelectOptionType;
    });
  };
}

const mapStateToProps = (state: any) => {
  return {
    selectedColleagues: state.colleagues.selectedColleagues,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedColleagues: (colleagues) => {
      dispatch(actions.setSelectedColleagues(colleagues));
    },
  };
};

const ConnectedNewChatHeader = connect(mapStateToProps, mapDispatchToProps)(CreateNewChatHeader);

export default (props) => (
  <Query query={GetColleaguesQuery}>
    {({loading, error, data, client}: QueryResult) => {
      if (error || loading) return <HeaderDiv />;
      const colleagues = pathOr([], ['colleagues'], data);

      const filterOutShellColleagues = colleagues.filter((colleague) => colleague.status !== 'shell');

      return <ConnectedNewChatHeader {...props} client={client} colleagues={filterOutShellColleagues} />;
    }}
  </Query>
);
