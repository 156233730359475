import React from 'react';
import {IconButton, makeStyles, Slide} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';

import {DialogTitleTypography} from 'src/styles/styled-components/StyledMaterialComponents';
import {Closemark} from 'src/svgs/Closemark';
import {TransitionProps} from '@material-ui/core/transitions';
import styled from 'styled-components';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(2),
  },
  muiDialogTitle: {
    padding: '16px 24px 0 24px',
    fontFamily: 'Nunito Sans',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '21px',
    lineHeight: '29px',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: 'red',
  },
}));

export const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {children?: React.ReactElement<any, any>},
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface MaterialDialogHeaderProps {
  loading?: boolean;
  dialogTitle: string;
  closeIconButtonClickHandler: () => void;
  showCloseIconButton?: boolean;
  titleFontSize?: string;
  titleColor?: string;
}
interface HCCustomComponentsProps {
  color: string;
  padding: string;
  margin: string;
  fontWeight: number;
  fontFamily: string;
  fontSize: number;
  lineHeight: string;
}
/**
 * Reusable Fn. component for material ui dialog header
 * @param {boolean} tells if the loading is true then disable close button
 * @param dialogTitle {string} title of the material ui dialog
 * @param closeIconButtonClickHandler function to execute on click of close icon button
 * @param showCloseIconButton {boolean} to show or hide the close button, default is true to show the icon button.
 */
export const MaterialDialogHeader = ({
  loading = false,
  dialogTitle,
  closeIconButtonClickHandler,
  showCloseIconButton = true,
  titleFontSize,
  titleColor,
}: MaterialDialogHeaderProps) => {
  const classes = useStyles();
  return (
    <MuiDialogTitle disableTypography classes={{root: classes.muiDialogTitle}}>
      <DialogTitleTypography titleColor={titleColor} titleFontSize={titleFontSize} variant="h6">
        {dialogTitle}
      </DialogTitleTypography>
      {showCloseIconButton && (
        <IconButton
          disabled={loading}
          aria-label="close"
          className={classes.closeButton}
          onClick={closeIconButtonClickHandler}
        >
          <Closemark />
        </IconButton>
      )}
    </MuiDialogTitle>
  );
};

export const HCHeadingOne = styled.h1.attrs((props) => ({...props}))`
  font-family: 'Nunito Sans';
  font-weight: 700;
  font-size: 40px;
  color: ${(props) => (props.color ? props.color : '#FF3E55')};
`;

export const HCHeadingTwo = styled.h2.attrs((props: HCCustomComponentsProps) => ({...props}))`
  font-family: 'Nunito Sans';
  margin: ${(props) => (props.margin ? props.margin : '0')};
  padding: ${(props) => (props.padding ? props.padding : '0')};
  font-weight: 600;
  font-size: 28px;
  color: ${(props) => (props.color ? props.color : '#FF3E55')};
`;

export const HCHeadingThree = styled.h3.attrs((props) => ({...props}))`
  font-family: 'Nunito Sans';
  font-weight: 700;
  font-size: 21px;
  margin: 0;
  color: ${(props) => (props.color ? props.color : '#FF3E55')};
`;

export const HCHeadingFour = styled.h4.attrs((props: HCCustomComponentsProps) => ({...props}))`
  font-family: 'Nunito Sans';
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 600)} !important;
  font-size: 17px;
  line-height: 24px;
  margin: ${(props) => (props.margin ? props.margin : '0')} !important;
  padding: ${(props) => (props.padding ? props.padding : '0')} !important;
  color: ${(props) => (props.color ? props.color : '#FF3E55')};
`;

export const HCBodyOne = styled.div.attrs((props) => ({...props}))`
  font-family: 'Open Sans';
  font-weight: 700;
  font-size: 16px;
  color: ${(props) => (props.color ? props.color : '#222222')};
`;

export const HCBodyTwo = styled.div.attrs((props: HCCustomComponentsProps) => ({...props}))`
  font-family: 'Open Sans';
  font-weight: 400;
  font-size: 14px;
  color: ${(props) => (props.color ? props.color : '#222222')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '')};
`;

export const HCLabelOne = styled.div.attrs((props: HCCustomComponentsProps) => ({...props}))`
  font-family: 'Open Sans';
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 400)};
  margin: ${(props) => (props.margin ? props.margin : '0')};
  padding: ${(props) => (props.padding ? props.padding : '0')};
  font-size: 16px;
  color: ${(props) => (props.color ? props.color : '#222222')};
  line-height: 24px;
`;

export const HCLabelTwo = styled.div.attrs((props: HCCustomComponentsProps) => ({...props}))`
  font-family: 'Open Sans';
  font-weight: 400;
  font-size: 14px;
  color: ${(props) => (props.color ? props.color : '#222222')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '')};
`;

export const HCLabelThree = styled.div.attrs((props) => ({...props}))`
  font-family: 'Open Sans';
  font-weight: 400;
  font-size: 13px;
  color: ${(props) => (props.color ? props.color : '#222222')};
  line-height: 18px;
`;

export const HCLabelFour = styled.div.attrs((props: HCCustomComponentsProps) => ({...props}))`
  font-family: 'Open Sans';
  font-weight: 400;
  font-size: 12px;
  color: ${(props) => (props.color ? props.color : '#222222')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '')};
`;

export const HCTextContext = styled.div.attrs((props: HCCustomComponentsProps) => ({...props}))`
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 16px;
  color: ${(props) => (props.color ? props.color : '#222222')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '')};
  margin: ${(props) => (props.margin ? props.margin : '0')} !important;
  padding: ${(props) => (props.padding ? props.padding : '0')} !important;
`;

export const HCTextContextTwo = styled.div.attrs((props: HCCustomComponentsProps) => ({...props}))`
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 14px;
  color: ${(props) => (props.color ? props.color : '#222222')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '')};
`;

export const HCTextContextThree = styled.div.attrs((props: HCCustomComponentsProps) => ({...props}))`
  font-family: 'Open Sans';
  font-weight: 400;
  font-size: 14px;
  color: ${(props) => (props.color ? props.color : '#222222')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '')};
`;

export const HCTextSpanContextThree = styled.span.attrs((props: HCCustomComponentsProps) => ({...props}))`
  font-family: 'Open Sans', serif;
  font-weight: 400;
  font-size: 14px;
  color: ${(props) => (props.color ? props.color : '#222222')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '')};
`;

export const HCCustomSpan = styled.span<{color?: string; fontWeight?: string}>`
  font-family: 'Open Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : '400')};
  line-height: 24px;
  color: ${(props) => (props.color ? props.color : '')};
`;
