import React from 'react';
import {toast} from 'react-toastify';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import {useQuery} from '@apollo/react-hooks';
import {QueryResult} from 'react-apollo';
import {GetActiveEscalationByIDResult, UserOrganizationSwitcherPayload} from 'src/types';
import LoadingDiv from 'src/components/LoadingDiv';
import FetchActiveEscalationByID from 'src/gql/query/FetchActiveEscalationByID';
import EscalationPreviewBanner from 'src/components/EscalationPreviewBanner';
import GetChatQuery from 'src/gql/query/GetChatQuery';

import EscalationDetailContent from './views/EscalationDetailContent';
import ConfirmCancelEscalationModal from './views/ConfirmCancelEscalationModal';
import StepIndicatorIcon from './views/StepIndicatorIcon';
import useCancelEscalation from './hooks/useCancelEscalation';
import getParsedAuthInfo from 'src/utils/localStorageHandler';
import stringifyNumberSuffix from 'src/utils/stringifyNumberSuffix';

const EscalationViewChatButtonHolder = styled.div`
  width: 100%;
  display: flex;
  margin-top: 1em;
  justify-content: flex-end;
  button {
    text-transform: capitalize;
    margin-left: 1em;
    width: 160px;
  }
  button#cancelEscalation {
    color: ${(props) => props.theme.chatTeal};
    background-color: white;
  }
  button:disabled {
    color: grey !important;
  }
`;

const ProgressDetailStepper = styled.div`
  overflow-y: auto;
  max-height: 440px;
  margin-top: 1em;
  margin-bottom: 1em;
  .MuiStepper-vertical {
    padding-left: 0.5em;
    padding-bottom: 0;
  }
  .MuiStepLabel-iconContainer {
    margin-left: 2px;
    margin-right: 2px;
  }
  .MuiStepConnector-root {
    display: none !important;
  }
  .MuiStepContent-last {
    border: unset !important;
  }
`;

interface StyledProps {
  $isLessThanStartingLevel: boolean;
  $isLessThanResponderLevel: boolean;
  $isEscalationEndWithoutAnyAcknowledged: boolean;
}

const StyledProgressDetailStep = styled(Step)<StyledProps>`
  .MuiStepContent-root {
    border-left: ${(props) =>
      props.$isLessThanStartingLevel
        ? 'unset !important'
        : props.$isLessThanResponderLevel
        ? `1px solid ${props.$isEscalationEndWithoutAnyAcknowledged ? 'lightgrey' : props.theme.chatTeal}`
        : `1px dashed grey`};
    padding-bottom: 4px;
    margin-bottom: 8px;
  }
`;

const StyledStepLabel = styled(StepLabel)<{$flag: boolean}>`
  filter: ${(props) => (props.$flag ? `opacity(0.5)` : 'unset')};
`;

const ResponderLabel = styled.div`
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  width: 98%;
`;

const StartingLevelLabel = styled.div`
  font-weight: bold;
  color: ${(props) => props.theme.chatTeal};
`;

interface Props {
  targetEscalationID: number;
  closeModal: () => void;
  handleViewChat: (chatId: string, organizationPayload: UserOrganizationSwitcherPayload) => void;
}

const EscalationProgressDetail = ({closeModal, targetEscalationID, handleViewChat}: Props) => {
  const [isConfirmationModalOpen, setConfirmationModalOpen] = React.useState(false);

  const {loading, error, data}: QueryResult<GetActiveEscalationByIDResult> = useQuery(FetchActiveEscalationByID, {
    variables: {
      activeEscalationId: targetEscalationID,
    },
    fetchPolicy: 'cache-and-network',
  });

  const chatId = data?.me?.activeEscalation?.chat?.id;

  // TODO: remove this when escalation socket events are connected and working
  useQuery(GetChatQuery, {
    variables: {
      chatId,
    },
    errorPolicy: 'ignore',
    fetchPolicy: 'cache-and-network',
    skip: !chatId,
  });

  const {isRequesting, tryCancelEscalation} = useCancelEscalation({
    activatedEscalationId: targetEscalationID,
    chatId: data?.me?.activeEscalation?.chat?.id,
  });

  if (loading || error || !data.me) return <LoadingDiv />;

  const {activeEscalation} = data.me;
  const {
    chat,
    startingLevel,
    activatedBy,
    activatedLevel,
    activatedLevels,
    endedBy,
    state,
    escalatedUsers,
    escalationLadder,
  } = activeEscalation;

  const {
    organization: {id: organizationId, name: organizationName},
  } = escalationLadder;

  const parsedAuthInfo = getParsedAuthInfo();
  const selfID = parsedAuthInfo ? parsedAuthInfo.user.id : null;

  const ladderOrganizationSwitcherPayload: UserOrganizationSwitcherPayload = {
    organizationId,
    name: organizationName,
    image: null,
    type: 'Organization',
  };

  const canCancelEscalation = activatedBy.id === selfID && state === 'active';
  const currentResponderLevel = activatedLevel ? activatedLevel.level : 0;

  // if escalation is responded, activatedLevel will be the latest level thus the level thats responded
  const isResponded = (levelId: number) => {
    if (!activatedLevel) return false;
    return state === 'acknowledged' && levelId === activatedLevel.id;
  };

  const acknowledgedAt = escalatedUsers.find(
    (escalatedUser) => endedBy && escalatedUser.user && escalatedUser.user.id === endedBy.id,
  )?.acknowledgedAt;

  const sortedEscalationLevels = escalationLadder.escalationLevels.sort((a, b) =>
    a.level > b.level ? 1 : b.level > a.level ? -1 : 0,
  );

  const handleCancelEscalation = async () => {
    try {
      await tryCancelEscalation();
      toast.success('The escalation has been cancelled');
      const isAnyModalOpen = document.querySelector('.ReactModal__Content');
      if (isAnyModalOpen) {
        setConfirmationModalOpen(false);
        closeModal();
      }
    } catch (e) {
      toast.error('Failed to cancel the escalation, please check your internet connection and try again');
    }
  };

  return (
    <React.Fragment>
      <EscalationPreviewBanner
        hasActivatedLevel={!!activeEscalation.activatedLevel}
        activeEscalation={activeEscalation}
        isInChat={false}
      />
      <ProgressDetailStepper>
        <Stepper activeStep={currentResponderLevel} orientation="vertical">
          {sortedEscalationLevels.map((escalationLevel, index) => {
            const isGreaterThanResponderLevel = escalationLevel.level > currentResponderLevel;
            const isLessThanResponderLevel = escalationLevel.level < currentResponderLevel;
            const isLevelResponded = isResponded(escalationLevel.id);
            const isLessThanStartingLevel = escalationLevel.level < startingLevel;
            const isEscalationActive = state === 'active';
            const isEscalationEndWithoutAnyAcknowledged = state !== 'active' && state !== 'acknowledged';

            return (
              <StyledProgressDetailStep
                key={index}
                active={true}
                $isEscalationEndWithoutAnyAcknowledged={isEscalationEndWithoutAnyAcknowledged}
                $isLessThanStartingLevel={isLessThanStartingLevel}
                $isLessThanResponderLevel={isLessThanResponderLevel}
              >
                <StyledStepLabel
                  $flag={Boolean(isGreaterThanResponderLevel || isLessThanStartingLevel)}
                  StepIconComponent={() => (
                    <StepIndicatorIcon
                      isEscalationEndWithoutAnyAcknowledged={isEscalationEndWithoutAnyAcknowledged}
                      level={escalationLevel.level}
                      isResponded={isLevelResponded}
                      isLessThanStartingLevel={isLessThanStartingLevel}
                      isEscalationActive={isEscalationActive}
                      currentResponderLevel={currentResponderLevel}
                    />
                  )}
                >
                  <ResponderLabel>
                    <div>{stringifyNumberSuffix(escalationLevel.level)} level responder</div>
                    {startingLevel === escalationLevel.level && <StartingLevelLabel>Starting level</StartingLevelLabel>}
                  </ResponderLabel>
                </StyledStepLabel>

                <StepContent>
                  <EscalationDetailContent
                    acknowledgedAt={acknowledgedAt}
                    selfLevel={escalationLevel}
                    activatedLevel={activatedLevel}
                    activatedLevels={activatedLevels}
                    isGreaterThanResponderLevel={isGreaterThanResponderLevel}
                    isResponded={isLevelResponded}
                    isEscalationActive={isEscalationActive}
                    isLessThanStartingLevel={isLessThanStartingLevel}
                  />
                </StepContent>
              </StyledProgressDetailStep>
            );
          })}
        </Stepper>
      </ProgressDetailStepper>

      <EscalationViewChatButtonHolder>
        {canCancelEscalation && (
          <Button
            id="cancelEscalation"
            variant="contained"
            onClick={() => setConfirmationModalOpen(true)}
            disabled={isRequesting}
            disableTouchRipple
          >
            end escalation
          </Button>
        )}
        <Button
          id="viewChat"
          variant="contained"
          onClick={() => handleViewChat(chat.id, ladderOrganizationSwitcherPayload)}
          color="secondary"
          disableTouchRipple
        >
          view chat
        </Button>
      </EscalationViewChatButtonHolder>

      {isConfirmationModalOpen && (
        <ConfirmCancelEscalationModal
          isOpen={isConfirmationModalOpen}
          isRequesting={isRequesting}
          handleCancelEscalation={handleCancelEscalation}
          closeModal={() => setConfirmationModalOpen(false)}
        />
      )}
    </React.Fragment>
  );
};

export default EscalationProgressDetail;
