import React from 'react';
import {isEmpty} from 'ramda';
import styled from 'styled-components';

import StatDoubleExclamation from 'src/svgs/StatDoubleExclamation';
import UrgentSingleExclamation from 'src/svgs/UrgentSingleExclamation';
import {StatMessagePriority, UrgentMessagePriority} from 'src/constants/messageTypes';

const UrgentMessageHeader = styled.div<{isImageMessage: boolean}>`
  font-weight: 600;
  color: red;
  font-size: 14px;
  ${(props) =>
    props.isImageMessage &&
    `
    margin: 5px 0 5px 0;
  `}
`;

const StatMessageHeader = styled.div<{isImageMessage: boolean}>`
  font-weight: 600;
  font-size: 14px;
  color: #fff;
  ${(props) =>
    props.isImageMessage &&
    `
    margin: 5px 0 5px 0;
  `}
`;

const ConsultMessageHeader = styled.div`
  font-weight: 600;
  font-size: 14px;
  display: flex;
  & > div {
    margin-top: -5px;
    align-self: center;
    margin-left: 5px;
    color: white;
  }
`;

const PriorityIndicator = ({
  priorityType,
  isImageMessage = false,
  isConsultMessage = false,
  isTemplateMessage = false,
  template = null,
}) => {
  const templateRequestName = `${template?.formTitle} ${
    !isEmpty(template?.requestTitle) ? `- ${template?.requestTitle}` : ''
  }`;
  if (priorityType === StatMessagePriority) {
    return (
      <StatMessageHeader isImageMessage={isImageMessage}>
        <StatDoubleExclamation /> STAT {isConsultMessage && <>CONSULT</>}{' '}
        {isTemplateMessage && <>{templateRequestName}</>}
      </StatMessageHeader>
    );
  } else if (priorityType === UrgentMessagePriority) {
    return (
      <UrgentMessageHeader isImageMessage={isImageMessage}>
        <UrgentSingleExclamation /> URGENT {isConsultMessage && <>CONSULT</>}{' '}
        {isTemplateMessage && <>{templateRequestName}</>}
      </UrgentMessageHeader>
    );
  } else if (isConsultMessage)
    return (
      <ConsultMessageHeader>
        <img src="/assets/consult-notactive.svg" alt="consult message icon" />
        <div>CONSULT</div>
      </ConsultMessageHeader>
    );
  else if (isTemplateMessage)
    return (
      <ConsultMessageHeader>
        <img src="/assets/consult-notactive.svg" alt="template message icon" />
        <div>{templateRequestName}</div>
      </ConsultMessageHeader>
    );
  else return null;
};

export default PriorityIndicator;
