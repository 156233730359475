import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import Tooltip from '@material-ui/core/Tooltip';
import {InvitedUser, InviteStatus} from 'src/types';
import {IsFeatureFlagEnabled} from 'src/utils/FeatureFlagManager';
import {
  DAY,
  INVITED,
  PENDING_ADMIN_APPROVAL,
  PENDING_INVITEE_RESPONSE,
  REINVITE_SENT,
  REINVITED_TODAY,
  REQUESTED,
  USER_ITEM_DETAIL_WRAPPER_NO_EMAILL_TOOLTIP,
} from 'src/constants/strings';

const UserItemDetailWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  min-width: 0;
  margin-left: 0.5em;
  flex-direction: column;
  flex-wrap: wrap;
`;

const DetailTitle = styled.div`
  line-height: 1.5em;
  margin-bottom: 2px;
  overflow-wrap: anywhere;
  color: ${(props) => props.theme.blackFontColor};
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
`;

const DetailDate = styled.div`
  font-size: 14px;
  line-height: 1.5em;
  color: ${(props) => props.theme.greyishBrown};
`;

interface Props {
  invitedUser: InvitedUser;
}

const UserItemDetail = ({invitedUser}: Props) => {
  const coSigningInvitesFF = IsFeatureFlagEnabled('coSigningInvites');
  const {inviteStatus} = invitedUser;
  const historyDate = invitedUser?.history?.filter((item) => item.action === 'resend_invite');
  let latestReinvite = moment.max(historyDate.map((item) => moment(item.createdAt)));
  let isReinvitedToday = moment(latestReinvite).isSame(moment(), DAY);

  return (
    <UserItemDetailWrapper>
      <Tooltip
        title={invitedUser.address ? invitedUser.address.address : USER_ITEM_DETAIL_WRAPPER_NO_EMAILL_TOOLTIP}
        placement={'top-end'}
      >
        <DetailTitle>
          {invitedUser.user ? (
            <React.Fragment>
              <span>{invitedUser.user.firstname} </span>
              <strong>{invitedUser.user.lastname}</strong>
            </React.Fragment>
          ) : (
            invitedUser.address?.address
          )}
        </DetailTitle>
      </Tooltip>

      {coSigningInvitesFF ? (
        <>
          <DetailDate>
            {inviteStatus === InviteStatus.pending_admin_approval ? REQUESTED : INVITED}{' '}
            {moment(invitedUser.createdAt).format('DD/MMM/YYYY')}
          </DetailDate>
          {historyDate.length !== 0 && (
            <DetailDate>
              {isReinvitedToday ? REINVITED_TODAY : `${REINVITE_SENT} ${latestReinvite.format('DD/MMM/YYYY')}`}
            </DetailDate>
          )}
        </>
      ) : (
        <DetailDate>Invited on {moment(invitedUser.createdAt).format('ddd, MM/DD/YY')}</DetailDate>
      )}

      {coSigningInvitesFF ? (
        <DetailDate>
          {inviteStatus === InviteStatus.pending_user_acceptance ? PENDING_INVITEE_RESPONSE : PENDING_ADMIN_APPROVAL}
        </DetailDate>
      ) : null}
    </UserItemDetailWrapper>
  );
};

export default UserItemDetail;
