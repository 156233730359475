import React from 'react';
import {Field, getIn} from 'formik';
import InputBase, {InputBaseProps} from '@material-ui/core/InputBase';
import {alpha, withStyles} from '@material-ui/core/styles';
import FormControl, {FormControlProps} from '@material-ui/core/FormControl';
import styled from 'styled-components';
import localTheme from 'src/styles/theme';

// styles
const StyledErrorSpan = styled.span`
  font-size: 12px;
  color: ${(props) => props.theme.errorRed};
`;

interface ErrorMessageProps {
  name: string;
}

export const StyledFormControl = styled(FormControl)`
  width: 100%;
  margin-top: 15px !important;
  padding: 0.5em 1em !important;
` as React.ComponentType<FormControlProps>;

export const StyledInputBase = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  error: {
    borderRadius: 4,
    border: `1px solid ${localTheme.errorRed}`,
    '& input': {
      boxShadow: `${alpha(localTheme.errorRed, 0.25)} 0 0 0 0.2rem !important`,
      borderColor: `${localTheme.errorRed} !important`,
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      boxShadow: `${alpha(localTheme.chatTeal, 0.25)} 0 0 0 0.2rem`,
      // borderColor: theme.palette.primary.main
      borderColor: localTheme.chatTeal,
    },
  },
}))(InputBase) as React.ComponentType<InputBaseProps>;

// Views
const ErrorMessage = (props: ErrorMessageProps) => {
  const {name} = props;
  return (
    <Field name={name}>
      {({form}) => {
        const error = getIn(form.errors, name);
        const touch = getIn(form.touched, name);
        // console.log(form)
        return touch && error ? <StyledErrorSpan>{error}</StyledErrorSpan> : null;
      }}
    </Field>
  );
};

export default ErrorMessage;
