import React from 'react';
import styled from 'styled-components';
import {makeStyles} from '@material-ui/core/styles';
import {Grid} from '@material-ui/core';
import generateSSOLink from 'src/utils/generateSSOLink';
import {StyledInputBase} from 'src/components/StepFormPartials';
import {PrimaryButton, SecondaryButton} from 'src/styles/styled-components/StyledMaterialComponents';
import {DEBUG} from 'src/constants/storageKeys';
import {withLDConsumer} from 'launchdarkly-react-client-sdk';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    padding: theme.spacing(2),
    textAlign: 'left',
    width: '50%',
    marginTop: '24px',
    boxShadow: '0px 8px 12px rgba(9, 30, 66, 0.15), 0px 0px 1px rgba(9, 30, 66, 0.31)',
    borderRadius: '8px',
  },
}));

const StyledContainer = styled.div`
  text-align: -webkit-center;
`;

const StyledLabel = styled.div`
  margin-bottom: 4px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: flex-end;
  color: #4a4a4a;
`;

const StyledInput = styled(StyledInputBase)<{edit}>`
  width: 100%;
  input {
    ${(props) =>
      props.edit === 'false'
        ? `
      background: #f6f6f9;
    `
        : null}
    border: 1px solid #d8d8d8;
    border-radius: 4px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #222222;
  }
`;

const DebugList = (props) => {
  const classes = useStyles();
  const debugValues = JSON.parse(sessionStorage.getItem(DEBUG));

  const initialAppURL = debugValues ? debugValues.appURL : process.env.REACT_APP_API_URL;
  const initialSocketURL = debugValues ? debugValues.socketURL : process.env.REACT_APP_WS_URL;
  const initialPublicURL = debugValues ? debugValues.publicURL : generateSSOLink();

  const [appURL, setAppURL] = React.useState(initialAppURL);
  const [socketURL, setSocketAppURL] = React.useState(initialSocketURL);
  const [publicURL, setPublicAppURL] = React.useState(initialPublicURL);
  const [isEdit, setIsEdit] = React.useState(false);

  const updateDebugger = () => {
    setIsEdit(!isEdit);
    if (isEdit) {
      const debug = {appURL: appURL, socketURL: socketURL, publicURL: publicURL};
      sessionStorage.setItem(DEBUG, JSON.stringify(debug));
    }
  };

  const resetDebug = () => {
    const resetDebug = {
      appURL: process.env.REACT_APP_API_URL,
      socketURL: process.env.REACT_APP_WS_URL,
      publicURL: generateSSOLink(),
    };
    sessionStorage.setItem(DEBUG, JSON.stringify(resetDebug));
    setAppURL(process.env.REACT_APP_API_URL);
    setSocketAppURL(process.env.REACT_APP_WS_URL);
    setPublicAppURL(generateSSOLink());
    setIsEdit(false);
  };

  const handleBack = () => {
    window.routerHistory.replace(`/login`);
  };

  return (
    <>
      <StyledContainer className={classes.root}>
        <Grid container spacing={3} className={classes.container}>
          <Grid item xs={12}>
            <StyledLabel>API URL</StyledLabel>
            <StyledInput
              edit={isEdit.toString()}
              disabled={!isEdit}
              value={appURL}
              onChange={(e) => setAppURL(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <StyledLabel>Socket server URL</StyledLabel>
            <StyledInput
              edit={isEdit.toString()}
              disabled={!isEdit}
              value={socketURL}
              onChange={(e) => setSocketAppURL(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <StyledLabel>Public uploads URL</StyledLabel>
            <StyledInput
              edit={isEdit.toString()}
              disabled={!isEdit}
              value={publicURL}
              onChange={(e) => setPublicAppURL(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <SecondaryButton type="submit" onClick={() => resetDebug()}>
              Reset
            </SecondaryButton>
            <PrimaryButton type="button" onClick={() => updateDebugger()} style={{float: 'right'}}>
              {isEdit ? 'Update' : 'Edit'}
            </PrimaryButton>
            {isEdit && (
              <SecondaryButton
                type="submit"
                onClick={() => setIsEdit(false)}
                style={{float: 'right', marginRight: '8px'}}
              >
                Cancel
              </SecondaryButton>
            )}
            <SecondaryButton type="submit" onClick={() => handleBack()} style={{float: 'right', marginRight: '8px'}}>
              Back
            </SecondaryButton>
          </Grid>
        </Grid>
      </StyledContainer>
    </>
  );
};

export default withLDConsumer()(DebugList);
