import React from 'react';
import {toast} from 'react-toastify';
import styled from 'styled-components';
import {typedUseSelector} from 'src/redux';
import SearchBarInput from 'src/components/SearchBarInput';
import {CONTACTS} from 'src/constants/routerPathName';
import InvitationUserListWrapper from 'src/pages/ContactsPage/InvitedUserSection/InvitationUserListWrapper';
import InviteColleaguesModal from 'src/components/InviteColleaguesModal';
import ContactListWrapper from 'src/pages/ContactsPage/ContactListSection/ContactListWrapper';
import FAQSection from './views/FAQSection';
import TopPanelBanner from './views/TopPanelBanner';
import useRefetchInvitedUsers from 'src/pages/ContactsPage/InvitedUserSection/hooks/useFetchInvitedUsers';
import HypercareSelfProfileViewModel from 'src/pages/LocatingPage/viewModels/HypercareSelfProfileViewModel';
import {CONTACTS_PAGE_SEARCH_FIELD_PLACEHOLDER} from 'src/constants/strings';
import {IsFeatureFlagEnabled} from 'src/utils/FeatureFlagManager';
import {FeatureFlagResult} from 'src/utils/FeatureFlags';

const LayoutWrapper = styled.div`
  height: calc(100vh - 60px - 60px);
  padding: 0 1em;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
`;

const InvitationSearchLayout = () => {
  const [searchKeyword, setSearchKeyWord] = React.useState<string>('');
  const [showInviteColleagueModal, setInviteColleagueModal] = React.useState(false);
  const {useGetOrganizationAllowInviteStatus} = HypercareSelfProfileViewModel();
  const {refetch} = useRefetchInvitedUsers({
    searchKeyword: '',
  });

  const organizationId = typedUseSelector((store) => store.organization.organizationId);
  const urlSearch = window.location.search;

  React.useEffect(() => {
    if (organizationId) {
      if (urlSearch.indexOf('?') > -1 && urlSearch.includes('open')) {
        setInviteColleagueModal(true);
        window.history.replaceState({}, document.title, `${window.location.origin}/${CONTACTS}`);
      }
    } else {
      setInviteColleagueModal(false);
      toast.warn(`Please select an organization first in order to invite`);
    }
  }, [organizationId, urlSearch]);

  function handleClickInviteColleagues() {
    if (organizationId) {
      setInviteColleagueModal(true);
    } else {
      toast.warn(`Please select an organization first in order to invite`);
    }
  }

  const handleOnComplete = React.useCallback(() => {
    try {
      if (refetch) refetch();
    } catch (e) {
      console.error(e);
    }
  }, [refetch]);

  const {data: organizationAllowInviteStatus} = useGetOrganizationAllowInviteStatus();

  return (
    <React.Fragment>
      <TopPanelBanner
        allowInvites={organizationAllowInviteStatus}
        handleClickInviteColleagues={handleClickInviteColleagues}
      />

      <SearchBarInput placeholder={CONTACTS_PAGE_SEARCH_FIELD_PLACEHOLDER} setSearchKeyWord={setSearchKeyWord} />

      <LayoutWrapper>
        <FAQSection
          allowInvites={organizationAllowInviteStatus}
          handleClickInviteColleagues={handleClickInviteColleagues}
        />

        <InvitationUserListWrapper searchKeyword={searchKeyword} />

        <ContactListWrapper searchKeyword={searchKeyword} />

        {showInviteColleagueModal && (
          <InviteColleaguesModal
            isOpen={showInviteColleagueModal}
            closeModal={() => setInviteColleagueModal(false)}
            handleOnComplete={handleOnComplete}
          />
        )}
      </LayoutWrapper>
    </React.Fragment>
  );
};

export default InvitationSearchLayout;
