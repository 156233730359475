import React, {useEffect, useState} from 'react';
import store, {typedUseSelector} from 'src/redux';
import styled from 'styled-components';
import SiteDropdown from 'src/pages/LocatingPage/locating/search-section/SiteDropdown';
import SearchBarInput from 'src/components/SearchBarInput';
import DepartmentsExpansionWrapper from 'src/pages/LocatingPage/locating/search-section/DepartmentsExpansionWrapper';

import {LocatingRole, OnCallDepartmentScope} from 'src/types';
import SearchPanelTitleBanner from './SearchPanelTitleBanner';
import HypercareSchedulingViewModel from 'src/pages/LocatingPage/viewModels/HypercareSchedulingViewModel';
import DepartmentListLoader from 'src/components/loaders/DepartmentListLoader';
import {HypercareOnCallActions} from '../../../redux/actions/onCall';
import RoleFavourites from './search-section/RoleFavourites';
import {IsFeatureFlagEnabled} from '../../../utils/FeatureFlagManager';
import {FeatureFlagResult} from '../../../utils/FeatureFlags';
import DepartmentsExpansionWrapperV2 from './search-section/DepartmentsExpansionWrapperV2';
import {ON_CALL_PAGE_SEARCH_DEPARTMENT_PLACEHOLDER, SEARCH_ROLE_OR_PERSON_BY_NAME} from '../../../constants/strings';
import {FETCH_ORGANIZATION_SITES_ERROR} from '../../../constants/networkError';
import {MinimalSiteData} from '../../../gql/query/FetchSitesForOrganization';
import {SiteListLoader} from '../../../components/loaders/SiteListLoader';

const SearchPanelWrapper = styled.div`
  display: flex;
  position: relative;
  box-sizing: border-box;
  flex-direction: column;
  width: 33vw;
  min-width: 440px;
  overflow-y: auto;
  overflow-x: hidden;
  border-right: 1px solid ${(props) => props.theme.borderColor};
  .MuiList-padding {
    padding: 0;
  }
`;

const TopControllerWrapper = styled.div`
  padding: 0px 12px 10px 12px;
`;

const OverflowWrapper = styled.div`
  display: flex;
  position: relative;
  box-sizing: border-box;
  flex-direction: column;
  padding: 0px 12px;
  min-height: 30px;
  width: 100%;
  overflow-y: auto;
`;

interface Props {
  searchKeyword: string;
  departmentLists: OnCallDepartmentScope[];
  selectedSite: MinimalSiteData;
}

const FavouritedRolesAndDepartmentsRoles = ({searchKeyword, departmentLists, selectedSite}: Props) => {
  const {getFavouriteRolesForSite} = HypercareSchedulingViewModel();

  const [favouriteRolesList, setFavouriteRolesList] = useState<LocatingRole[]>([]);

  const optimizeOnCallNetworkRequestFF = IsFeatureFlagEnabled(FeatureFlagResult.optimizeOnCallNetworkRequest);

  useEffect(() => {
    const getAndSetFavouriteRolesForSite = async () => {
      const {result} = await getFavouriteRolesForSite(selectedSite.id);

      setFavouriteRolesList(result);
    };

    getAndSetFavouriteRolesForSite();
  }, [selectedSite.id]);

  return (
    <OverflowWrapper>
      <RoleFavourites
        searchKeyword={searchKeyword}
        favouriteRolesList={favouriteRolesList}
        setFavouriteRolesList={setFavouriteRolesList}
      />

      {optimizeOnCallNetworkRequestFF ? (
        <DepartmentsExpansionWrapperV2
          setFavouriteRolesList={setFavouriteRolesList}
          searchKeyword={searchKeyword}
          favouriteRolesList={favouriteRolesList}
          departmentLists={departmentLists}
        />
      ) : (
        <DepartmentsExpansionWrapper
          setFavouriteRolesList={setFavouriteRolesList}
          searchKeyword={searchKeyword}
          favouriteRolesList={favouriteRolesList}
          departmentLists={departmentLists}
        />
      )}
    </OverflowWrapper>
  );
};

const RenderDepartmentsAndRoles = ({
  searchKeyword,
  selectedSite,
}: {
  searchKeyword: string;
  selectedSite: MinimalSiteData;
}) => {
  const {getDepartmentsAndRolesForSite, getDepartmentsForSite} = HypercareSchedulingViewModel();
  const optimizeOnCallNetworkRequestFF = IsFeatureFlagEnabled(FeatureFlagResult.optimizeOnCallNetworkRequest);

  const departmentLists = typedUseSelector((state) => state.onCall.OnCallDepartments);
  const siteList = typedUseSelector((state) => state.onCall.OnCallSites);

  const finalDepartmentList = optimizeOnCallNetworkRequestFF ? siteList[selectedSite.id] : departmentLists;

  const getDepartments = async (siteId: number) => {
    await getDepartmentsForSite(siteId);
  };

  const getSiteSchedule = async (siteId: number) => {
    await getDepartmentsAndRolesForSite(siteId);
  };

  useEffect(() => {
    optimizeOnCallNetworkRequestFF ? getDepartments(selectedSite.id) : getSiteSchedule(selectedSite.id);
  }, [selectedSite.id]);

  return (
    <>
      {!finalDepartmentList || finalDepartmentList?.length === 0 ? (
        <div style={{width: '100%', height: '100%'}}>
          <DepartmentListLoader viewBox="0 0 550 575" />
        </div>
      ) : (
        <FavouritedRolesAndDepartmentsRoles
          searchKeyword={searchKeyword}
          departmentLists={finalDepartmentList}
          selectedSite={selectedSite}
        />
      )}
    </>
  );
};

const SearchPanelContainer = () => {
  const [selectedSite, setSelectedSite] = useState<MinimalSiteData>(null);
  const [siteFullScope, setSiteFullScope] = useState<MinimalSiteData[]>(null);
  const [searchKeyword, setSearchKeyWord] = useState<string>('');
  const [isFetchSiteLoading, setIsFetchSitesLoading] = useState(false);
  const [error, setError] = useState(false);
  const optimizeOnCallNetworkRequestFF = IsFeatureFlagEnabled(FeatureFlagResult.optimizeOnCallNetworkRequest);
  const currentOrganizationId = typedUseSelector((state) => state.organization.organizationId) || 0;

  const {getSitesForOrganization} = HypercareSchedulingViewModel();

  useEffect(() => {
    const getSelfOrganizationsData = async () => {
      setIsFetchSitesLoading(true);
      const result = await getSitesForOrganization(currentOrganizationId);

      if (result?.error) {
        setError(true);
      }

      if (result) {
        const {defaultSelectedSite, siteFullScope} = result;

        if (defaultSelectedSite) {
          setSelectedSite(defaultSelectedSite);
          store.dispatch(HypercareOnCallActions.setCurrentSelectedSite(defaultSelectedSite));
        }

        if (siteFullScope) {
          setSiteFullScope(siteFullScope);
        }
      }

      setIsFetchSitesLoading(false);
    };

    getSelfOrganizationsData();
  }, []);

  const defaultSelectedSite = selectedSite ? selectedSite : siteFullScope?.[0];

  if (error) {
    return <>{FETCH_ORGANIZATION_SITES_ERROR}</>;
  }

  return (
    <SearchPanelWrapper>
      <SearchPanelTitleBanner sites={siteFullScope} selectedSite={defaultSelectedSite} />
      <TopControllerWrapper>
        {isFetchSiteLoading && (
          <div style={{width: '100%', height: '100%'}}>
            <SiteListLoader viewBox="0 0 550 900" />
          </div>
        )}
        {siteFullScope && defaultSelectedSite && (
          <SiteDropdown setSelectedSite={setSelectedSite} selectedSite={defaultSelectedSite} siteList={siteFullScope} />
        )}{' '}
        {siteFullScope && siteFullScope.length > 0 && (
          <SearchBarInput
            placeholder={
              optimizeOnCallNetworkRequestFF
                ? ON_CALL_PAGE_SEARCH_DEPARTMENT_PLACEHOLDER
                : SEARCH_ROLE_OR_PERSON_BY_NAME
            }
            setSearchKeyWord={setSearchKeyWord}
          />
        )}
      </TopControllerWrapper>
      {defaultSelectedSite && (
        <RenderDepartmentsAndRoles selectedSite={defaultSelectedSite} searchKeyword={searchKeyword} />
      )}{' '}
    </SearchPanelWrapper>
  );
};

export default SearchPanelContainer;
