import styled from 'styled-components';
import React from 'react';
import PhoneInput, {PhoneInputProps} from 'react-phone-input-2';
import {Grid, TextField, colors} from '@material-ui/core';
import theme from 'src/styles/theme';

const StyledInputField = styled(PhoneInput)<{$isError: boolean}>`
  .form-control {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    padding-top: 10px;
    padding-bottom: 5px;
    border-color: ${(props) => (props.$isError ? '#f44336 !important' : 'transparent')};
    &:hover {
      border-color: ${(props) => (props.$isError ? '#f44336' : 'transparent')};
    }
  }

  .flag-dropdown {
    padding-top: 8px;
    padding-bottom: 5px;
  }

  .special-label {
    display: none;
    color: ${(props) => (props.$isError ? '#f44336' : '')};
  }
`;

type FormFieldInputPhoneProps = {
  value: string;
  placeholder: string;
  isRequired: boolean;
  disabled: boolean;
  mode: 'view' | 'edit';
  error?: string;
  fieldId: string;
  onBlur: () => void;
  onChange: (value: string) => void;
};

const getCompletePhoneNumber = (base: string, ext: string) => {
  return `${base}${!!ext ? 'x' : ''}${ext}`;
};

const StyledAnchor = styled.a`
  cursor: pointer;
  text-decoration: none;
  color: ${theme.chatTeal};
  &:hover {
    text-decoration: underline;
  }
`;

const ErrorMessage = styled.p`
  font-size: 0.75rem;
  margin-top: 3px;
  text-align: left;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  color: ${theme.errorRed};
`;

export const FormFieldInputPhone = (props: FormFieldInputPhoneProps) => {
  const baseValue = props.value.split('x')[0];
  const extensionValue = props.value.split('x')?.[1] ?? '';

  const onChange: PhoneInputProps['onChange'] = (value, data, e, formattedValue) => {
    props.onChange(getCompletePhoneNumber(value, extensionValue));
  };

  const onExtensionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(getCompletePhoneNumber(baseValue, e.target.value));
  };

  if (props.mode === 'view') {
    const phoneValueWithoutPlusPrefixIfExists = props.value.slice(props.value.startsWith('+') ? 1 : 0);
    const formattedPhoneNumberForBrowser = phoneValueWithoutPlusPrefixIfExists.split('x').join(';');
    const formattedPhoneNumberForViewing = phoneValueWithoutPlusPrefixIfExists.split('x').join(' ext. ');

    return (
      <StyledAnchor href={`tel:+${formattedPhoneNumberForBrowser}`}>
        {`+${formattedPhoneNumberForViewing}`}
      </StyledAnchor>
    );
  }

  return (
    <Grid container xs={12} justify="space-between">
      <Grid item xs={8}>
        <StyledInputField
          $isError={!!props.error}
          disabled={props.disabled}
          disableCountryGuess={false}
          country="ca"
          placeholder={props.placeholder}
          inputProps={{
            name: 'phoneNumber',
            helperText: props.error,
            disableUnderline: !props.error,
          }}
          defaultErrorMessage={props.error}
          value={baseValue}
          onChange={onChange}
          inputStyle={{
            width: '100%',
          }}
          enableSearch={true}
        />
        {props.error && <ErrorMessage>{props.error}</ErrorMessage>}
      </Grid>
      <Grid item xs={3}>
        <TextField
          fullWidth
          type="number"
          disabled={props.disabled}
          InputProps={{disableUnderline: true}}
          style={{position: 'relative', top: 2}}
          value={extensionValue}
          onChange={onExtensionChange}
          placeholder="ext (optional)"
        />
      </Grid>
    </Grid>
  );
};
