import React, {useState, useEffect, useCallback, CSSProperties} from 'react';
import Select, {createFilter} from 'react-select';
import {UserViewModel} from 'src/pages/ContactsPage/viewModels/UserViewModel';
import {UserSelectOptionType} from 'src/types';
import {useTheme} from '@material-ui/core/styles';
import {ValueType} from 'react-select/src/types';
import * as R from 'ramda';
import overriddenComponents, {
  useStyles,
  NoUnderlineControl as Control,
} from 'src/pages/MessengerPage/auto-select/ReactSelectMaterialUI';
import {
  CircleOptionV2 as Option,
  AutoHeightMenuListV2 as MenuList,
} from 'src/pages/MessengerPage/auto-select/ReactSelectSharedComponents';
import debounce from 'lodash/debounce';
import {PaginatedUsersType} from 'src/types/PaginatedTypes';
import {SiteScopes} from 'src/data/repository/UserRepository';

type CreateNewChatInputDropdownV2Props = {
  reduxStoreSelectedColleagues: UserSelectOptionType[];
  setReduxSelectedColleagues: (reduxStoreSelectedColleagues) => void;
  scopes: SiteScopes[];
};

const CreateNewChatInputDropdownV2 = ({
  reduxStoreSelectedColleagues,
  setReduxSelectedColleagues,
  scopes,
}: CreateNewChatInputDropdownV2Props) => {
  const [options, setOptions] = useState<PaginatedUsersType[] | []>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [continuationId, setContinuationId] = useState<string | null>(null);

  const {getSearchUsers} = UserViewModel();
  const classes = useStyles({});
  const theme = useTheme();

  const [selectedColleague, setSelectedColleague] = React.useState<ValueType<UserSelectOptionType>>([]);

  // selectedColleage dependent on reduxStoreSelectedColleagues from reducer
  useEffect(() => {
    if (!R.equals(reduxStoreSelectedColleagues, selectedColleague)) {
      setSelectedColleague(reduxStoreSelectedColleagues);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reduxStoreSelectedColleagues]);

  function handleChangeMulti(value: ValueType<UserSelectOptionType>) {
    if (value || (reduxStoreSelectedColleagues && reduxStoreSelectedColleagues.length > 0)) {
      setReduxSelectedColleagues(value);
    }
  }

  const loadOptions = useCallback(async () => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    try {
      // Simulate fetching options from an API with pagination and search
      const response = await getSearchUsers({
        text: searchText,
        limit: 30,
        continuationId,
        options: {
          status: ['active', 'locked'],
        },
        scopes,
      });
      if ('error' in response) {
        setOptions([]);
      } else {
        const newOptions = response.users;
        if (response?.users?.length > 0) {
          const uniqueNewOptions = newOptions?.filter((newOption) => {
            return ![...options].some((existingOption) => existingOption.id === newOption.id);
          });
          setOptions([...options, ...uniqueNewOptions]);
          setContinuationId(response.continuationId);
        } else {
          setHasMore(false);
        }
      }
    } catch (error) {
      console.error('Error loading options:', error);
    } finally {
      setIsLoading(false);
    }
  }, [isLoading, continuationId, searchText]);

  const getSearchData = async (searchValue: string, scopes: SiteScopes[]) => {
    const searchResult = await getSearchUsers({
      text: searchValue,
      limit: 30,
      continuationId: null,
      options: {
        status: ['active', 'locked'],
      },
      scopes,
    });

    if ('error' in searchResult) {
      setOptions([]);
    } else {
      setOptions(searchResult.users);
    }

    setIsLoading(false);
  };

  const handleCombinedSearch = React.useCallback(
    debounce(async (searchValue: string, scopes: SiteScopes[]) => {
      await getSearchData(searchValue, scopes);
    }, 250),
    [],
  );

  const handleSearch = async (searchValue: string) => {
    setSearchText(searchValue);

    if (searchValue === '') {
      setOptions([]);
      return;
    }

    if (searchValue.length <= 1) {
      return;
    }

    setIsLoading(true);

    handleCombinedSearch(searchValue, scopes);
  };

  const handleInputChange = (inputValue) => {
    setHasMore(true);
    if (!inputValue) {
      setOptions([]);
      setContinuationId(null);
      setHasMore(true);
    }
    setSearchText(inputValue);
    if (inputValue.length > 1) {
      handleSearch(inputValue);
    }
  };

  const suggestions = options
    ? Array.from(options).map((colleague) => {
        return {
          value: colleague?.id,
          label: colleague?.firstname + ' ' + colleague?.lastname,
          firstName: colleague?.firstname,
          lastName: colleague?.lastname,
          role: colleague?.role,
          profilePic: colleague?.profilePic,
        } as UserSelectOptionType;
      })
    : [];

  const handleMenuScrollToBottom = () => {
    if (hasMore) {
      loadOptions();
    }
  };

  const selectStyles = {
    input: (base: CSSProperties) => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit',
      },
    }),
    dropdownIndicator: (base) => ({
      ...base,
      display: 'none',
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: 'none',
    }),
  };

  return (
    <div className={classes.root} onClick={(e) => e.stopPropagation()}>
      <Select
        options={suggestions}
        isLoading={isLoading}
        classes={classes}
        onInputChange={handleInputChange}
        onMenuScrollToBottom={handleMenuScrollToBottom}
        autoFocus
        styles={selectStyles}
        inputId="new-chat-dropdown"
        TextFieldProps={{
          InputLabelProps: {
            htmlFor: 'new-chat-dropdown',
            shrink: true,
          },
        }}
        placeholder={''}
        components={{
          ...overriddenComponents,
          Option,
          Control,
          MenuList,
        }}
        value={selectedColleague}
        filterOption={null}
        openMenuOnClick={true}
        hideSelectedOptions={false}
        closeMenuOnSelect={true}
        onChange={handleChangeMulti}
        isMulti
      />
    </div>
  );
};

export default CreateNewChatInputDropdownV2;
