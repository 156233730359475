import React from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import {EscalationModalStepVariableContext} from 'src/pages/MessengerPage/escalation-modal/EscalationModalStepProvider';
import {EscalationType} from 'src/types';
import {ActionButtonWrapper} from 'src/styles/styled-components/Button';

const EscalationTypeSelector = styled.div<{$isSelected: boolean; $shouldFade: boolean}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  align-items: flex-start;
  padding: 8px 16px;
  user-select: none;
  height: 64px;
  border: 1px solid #d8d8d8;
  border-radius: 3px;
  margin-bottom: 1em;
  background: ${(props) => (props.$isSelected ? '#F6F6F9' : 'transparent')};
  box-shadow: ${(props) => (props.$isSelected ? '0px 1px 4px rgba(0, 0, 0, 0.1)' : 'unset')};
  filter: ${(props) => (props.$shouldFade ? 'opacity(0.5)' : 'unset')};
`;

const PrimaryText = styled.span`
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: ${(props) => props.theme.blackFontColor};
`;

const SecondaryText = styled.span`
  font-size: 14px;
  line-height: 1.5em;
  color: ${(props) => props.theme.greyishBrown};
`;

const OptionLabel = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props.theme.blackFontColor};
  margin-bottom: 8px;
`;

interface Props {
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  showIndividualEscalationModal: () => void;
  closeModal: () => void;
  chatTitle: string;
}

interface EscalationTypeSelectionStepProps extends Props {
  escalationType: EscalationType;
  setEscalationType: React.Dispatch<React.SetStateAction<EscalationType>>;
}

const EscalationTypeSelectionStep = ({
  escalationType,
  chatTitle,
  setEscalationType,
  closeModal,
  showIndividualEscalationModal,
  setCurrentStep,
}: EscalationTypeSelectionStepProps) => {
  const handleNext = () => {
    if (escalationType === 'individual') {
      showIndividualEscalationModal();
    } else if (escalationType === 'department') {
      setCurrentStep((prev) => prev + 1);
    }
  };

  return (
    <React.Fragment>
      <OptionLabel>Select an escalation option:</OptionLabel>
      <EscalationTypeSelector
        $isSelected={escalationType === 'department'}
        $shouldFade={escalationType && escalationType !== 'department'}
        onClick={() => setEscalationType('department')}
      >
        <PrimaryText>Department Escalation</PrimaryText>
        <SecondaryText>Select a department in the next step </SecondaryText>
      </EscalationTypeSelector>

      <EscalationTypeSelector
        $isSelected={escalationType === 'individual'}
        $shouldFade={escalationType && escalationType !== 'individual'}
        onClick={() => setEscalationType('individual')}
      >
        <PrimaryText>Individual Escalation</PrimaryText>
        <SecondaryText>In this conversation: {chatTitle} </SecondaryText>
      </EscalationTypeSelector>

      <ActionButtonWrapper>
        <Button variant="outlined" onClick={closeModal}>
          Cancel
        </Button>
        <Button disabled={!escalationType} variant="contained" color="secondary" onClick={handleNext}>
          Next
        </Button>
      </ActionButtonWrapper>
    </React.Fragment>
  );
};

export default (props: Props) => (
  <EscalationModalStepVariableContext.Consumer>
    {({escalationType, setEscalationType}) => (
      <EscalationTypeSelectionStep escalationType={escalationType} setEscalationType={setEscalationType} {...props} />
    )}
  </EscalationModalStepVariableContext.Consumer>
);
