import React, {useState} from 'react';
import styled from 'styled-components';
import {UserAddressType} from 'src/types';
import PageCallbackModal from 'src/pages/LocatingPage/locating/profile-section/PageCallbackModalV2';
import AnalyticsManager, {PAGE_VIEWS} from 'src/analytics/AnalyticsManager';
import {UPLOAD_PROFILE_PICTURE} from 'src/constants/fileUpload';
import ChangeImageModal from 'src/components/ChangeImageModal';
import CameraIcon from 'src/svgs/CameraIcon';
import SubscriptionModal from 'src/components/SubscriptionModal';
import Warningicon from 'src/svgs/Warning-icon';
import GetOrganizationMember from 'src/gql/query/GetOrganizationMember';
import client from 'src/apollo';
import TextField from '@material-ui/core/TextField';
import store from 'src/redux';
import {IsFeatureFlagEnabled} from 'src/utils/FeatureFlagManager';
import PagerAlertModals from 'src/components/PagerAlertModalsV2';
import {PagerType} from 'src/constants/user';
import LoadingDiv from 'src/components/LoadingDiv';
import NotesProfileWrapper from 'src/pages/LocatingPage/locating/profile-section/notes-section/NotesProfileWrapper';
import NewProfilePic from 'src/components/NewProfilePic';
import ActionButtonsWrapperV2 from './ActionButtonsWrapperV2';
import EditProfileFormWrapperV2 from './EditProfileFormWrapperV2';
import SMSModal from 'src/components/SMSModal';
import {ProfileConfigurableFieldsForm} from './ProfileConfigurableFieldsForm';
import {StyledSyncDirectoryChip} from 'src/components/shared/StyledSyncDirectoryChip';
import {MANAGED_BY_DIRECTORY_SERVICE} from 'src/constants/strings';
import {FeatureFlagResult} from 'src/utils/FeatureFlags';
import HypercareSelfProfileViewModel from 'src/pages/LocatingPage/viewModels/HypercareSelfProfileViewModel';

const ProfileWrapper = styled.div`
  display: flex;
  padding: 2em;
  height: 100%;
  overflow-y: auto;
  color: ${(props) => props.theme.greyishBrown};
`;

export const UserImageWrapper = styled.div`
  max-height: 50px;
  position: relative;
  cursor: pointer;
`;

export const CameraIconWrapper = styled.div`
  display: flex;
  position: absolute;
  bottom: 3px;
  left: -2px;
  bottom: -5px;
  height: 23px;
  border-radius: 50%;
  width: 23px;
  background-color: white;
  align-items: center;
  justify-content: center;
`;

const UserDetailWrapper = styled.div`
  padding-left: 1em;
  padding-right: 50px;
  width: 100%;
`;

const FullnameAndUserNameSection = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: Nunito;
`;

const UserFullname = styled.span`
  font-size: 28px;
`;

const Username = styled.span`
  font-size: 18px;
  padding-left: 0.5em;
  color: ${(props) => props.theme.main};
`;

export const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 12px;
  width: fit-content;
  gap: 8px;
  height: 32px;
  margin-top: 8px;
  background: #ffd8dd;
  border: 1px solid #cb3e00;
  border-radius: 24px;
  font-weight: 600;
`;

export const WarningContent = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #222222;
`;

const ProfileDetailWrapper = styled.div`
  margin-top: 40px;
  input,
  textarea {
    color: ${(props) => props.theme.greyishBrown};
  }
  .MuiFormLabel-root {
    color: ${(props) => props.theme.greyishBrown} !important;
  }
`;

type Props = {
  userId: string;
};
const UserProfileWrapper = ({userId}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isOrganizationMember, setIsOrganizationMember] = useState(false);
  const [showCallbackPageModal, setCallbackPageModal] = useState(false);
  const [showChangePictureModal, setChangePictureModal] = useState(false);
  const [isEditMode, setEditMode] = useState(false);
  const [isCPFEditMode, setIsCPFEditMode] = useState(false);
  const [isHiddenNotesEditMode, setIsHiddenNotesEditMode] = useState(false);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [showPagerModal, setShowPagerModal] = useState(false);
  const {organizationId} = store.getState().organization;
  const LOCKED = 'locked';
  const [pagerSelected, setPagerSelected] = useState<UserAddressType>(PagerType.ALPHANUMERIC_PAGER);
  const [addressSelected, setAddressSelected] = useState(null);
  const [showSMSModal, setShowSMSModal] = useState(false);

  const hiddenNotes = IsFeatureFlagEnabled('hiddenNotes');
  const configurableProfileFields = IsFeatureFlagEnabled('configurableProfileFields');
  const ldapDirectorySyncFlag = IsFeatureFlagEnabled(FeatureFlagResult.ldapDirectorySync);

  const viewModel = HypercareSelfProfileViewModel();

  const {data: user, error, loading, refetch} = viewModel.useGetProfileInformation();

  const fetchUserOrganizationStatus = async () => {
    setIsLoading(true);
    const getOrgMemberResult = await client.query({
      query: GetOrganizationMember,
      variables: {
        userId: userId,
        organizationId: organizationId,
      },
    });
    getOrgMemberResult?.data?.organizationQuery?.organization?.user?.__typename === 'PublicUser'
      ? setIsOrganizationMember(false)
      : setIsOrganizationMember(true);
    setIsLoading(false);
  };

  React.useEffect(() => {
    AnalyticsManager.recordPageVisited(PAGE_VIEWS.profileView);
    fetchUserOrganizationStatus();
  }, [user]);

  if (loading) {
    return <LoadingDiv />;
  }

  if (error) {
    return <>'opps something went wrong'</>;
  }

  if (!user) {
    return null;
  }

  const handleSubscriptionModalClose = () => {
    setShowSubscriptionModal(false);
  };

  const {firstName, lastName, username} = user;

  const hasPublicPager: boolean = Boolean(user.addresses?.find((address) => address.type === 'pager'));

  const firstPulicPager = hasPublicPager ? user.addresses?.find((address) => address.type === 'pager')?.address : null;

  const changeProfileEditMode = (mode: 'view' | 'edit') => {
    if (mode === 'view') setEditMode(false);
    if (mode === 'edit') setEditMode(true);
  };

  return (
    <ProfileWrapper>
      <UserImageWrapper onClick={() => setChangePictureModal(true)}>
        <NewProfilePic
          size={50}
          fontSize={16}
          users={[user]}
          status={isOrganizationMember ? '' : LOCKED}
          avatarLocked={false}
          data-testid="profile-pic"
        />

        <CameraIconWrapper>
          <CameraIcon color={'dimgrey'} />
        </CameraIconWrapper>
      </UserImageWrapper>

      <UserDetailWrapper>
        <FullnameAndUserNameSection>
          <div>
            <UserFullname data-testid="userProfileWrapperName">
              {firstName} {lastName}
            </UserFullname>
            <Username data-testid="userProfileWrapperUsername">@{username}</Username>
          </div>
          {ldapDirectorySyncFlag && user?.isDirectorySynced && (
            <div>
              <StyledSyncDirectoryChip text={MANAGED_BY_DIRECTORY_SERVICE} valueClassName={'profileChip'} />
            </div>
          )}
        </FullnameAndUserNameSection>

        {isLoading ? (
          <LoadingDiv />
        ) : !isOrganizationMember ? (
          <>
            <Wrapper>
              <Warningicon />
              <WarningContent data-testid="user-profile-content">User has been removed</WarningContent>
            </Wrapper>
            <ProfileDetailWrapper>
              <TextField
                fullWidth
                disabled={true}
                margin="normal"
                id="firstname"
                name="firstname"
                label="First Name"
                value={user.firstName}
              />
              <TextField
                fullWidth
                disabled={true}
                margin="normal"
                id="lastname"
                name="lastname"
                label="Last Name"
                value={user.lastName}
              />
            </ProfileDetailWrapper>
          </>
        ) : (
          <>
            <ActionButtonsWrapperV2
              user={user}
              isSelf={true}
              isEditMode={isEditMode}
              hasPublicPager={hasPublicPager}
              setEditMode={() => setEditMode(true)}
              setShowCallbackPageModal={() => setCallbackPageModal(true)}
              handleSubscriptionModal={() => setShowSubscriptionModal(true)}
              setShowPagerModal={() => setShowPagerModal(true)}
              setPagerSelected={setPagerSelected}
              setAddressSelected={setAddressSelected}
              setShowSMSModal={() => setShowSMSModal(true)}
            />
            <EditProfileFormWrapperV2
              user={user}
              isSelf={true}
              refetch={refetch}
              isEditMode={isEditMode}
              isDisabled={isCPFEditMode || isHiddenNotesEditMode}
              onModeChange={changeProfileEditMode}
              updateAuthProviderUserInfo={(user) => {}}
              setCallbackPageModal={() => setCallbackPageModal(true)}
              setShowPagerModal={() => setShowPagerModal(true)}
              setPagerSelected={setPagerSelected}
              setAddressSelected={setAddressSelected}
            />
          </>
        )}
        {configurableProfileFields && (
          <ProfileConfigurableFieldsForm
            isSelf={true}
            user={user}
            isDisabled={isEditMode || isHiddenNotesEditMode}
            mode={isCPFEditMode ? 'edit' : 'view'}
            onModeChange={(mode) => {
              if (mode === 'view') setIsCPFEditMode(false);
              if (mode === 'edit') setIsCPFEditMode(true);
            }}
            onSubmitCompleted={() => {
              setIsCPFEditMode(false);
            }}
            onDiscard={() => setEditMode(false)}
          />
        )}
        {hiddenNotes && isOrganizationMember && (
          <NotesProfileWrapper
            user={user}
            data-testid="notesprofilewrapper"
            isSelf={true}
            isDisabled={isCPFEditMode || isEditMode}
          />
        )}
      </UserDetailWrapper>

      {showCallbackPageModal && (
        <PageCallbackModal
          showModal={showCallbackPageModal}
          selectedUser={user}
          selectedRole={null}
          displayNumber={firstPulicPager ?? null}
          handleCloseModal={() => setCallbackPageModal(false)}
        />
      )}
      {showChangePictureModal && (
        <ChangeImageModal
          uploadType={UPLOAD_PROFILE_PICTURE}
          uploadPayload={null}
          closeModal={() => setChangePictureModal(false)}
          isOpen={showChangePictureModal}
        />
      )}
      {showSubscriptionModal && (
        <SubscriptionModal openModal={showSubscriptionModal} handleCloseModal={handleSubscriptionModalClose} />
      )}
      {showSMSModal && (
        <SMSModal
          showSMSModal={showSMSModal}
          user={user}
          address={addressSelected ?? undefined}
          setShowSMSModal={setShowSMSModal}
        />
      )}

      <PagerAlertModals
        user={user}
        showCallbackPageModal={showPagerModal}
        setShowCallbackPageModal={setShowPagerModal}
        pagerSelected={pagerSelected}
        addressSelected={addressSelected ?? undefined}
        data-testid="alpha-numeric-pager-modal"
      />
    </ProfileWrapper>
  );
};

export default React.memo(UserProfileWrapper);
