import React from 'react';
import VerificationCodeInput from 'src/components/VerificationCodeInput';
import styled from 'styled-components';
import {FormikProps} from 'formik';
import AnalyticsManager, {PAGE_VIEWS} from 'src/analytics/AnalyticsManager';

export const ErrorMessage = styled.p`
  color: red;
  font-size: 14px;
  margin: 0;
`;

export const UserEmail = styled.span`
  font-family: Nunito;
  font-weight: bold;
  display: inline-block;
  word-wrap: break-word;
`;

const JoinOrgStepOne = ({formikProps, validationCodeProps, isLoading, resendCode}) => {
  const {values} = formikProps as FormikProps<any>;

  const {validationCodeArray, validationErrorMsg, setValidationCodeArray} = validationCodeProps;

  React.useEffect(() => {
    AnalyticsManager.recordPageVisited(PAGE_VIEWS.recoverPasswordToken);
  }, []);

  return (
    <div>
      <div>
        We just sent you a 6-digit confirmation code to <UserEmail>{values.email}</UserEmail>
      </div>
      <VerificationCodeInput
        length={6}
        size="sm"
        value={validationCodeArray.join('')}
        disabled={isLoading}
        onChange={(value) => setValidationCodeArray(value.split(''))}
        handleResend={resendCode}
        renderError={validationErrorMsg && <ErrorMessage>{validationErrorMsg}</ErrorMessage>}
      />
    </div>
  );
};

export default JoinOrgStepOne;
