import moment from 'moment';
import React, {useState} from 'react';
import styled from 'styled-components';
import {Button, ThemeProvider} from '@material-ui/core';
import {Mutation, MutationFunction} from 'react-apollo';
import {createTheme} from '@material-ui/core/styles';

import store from 'src/redux';
import AppTheme from 'src/styles/theme';
import {AuthContext} from 'src/auth/AuthProvider';
import {RespondTemplateMessageMutation} from 'src/gql/mutation/SendMessageMutation';
import {StatMessagePriority, UrgentMessagePriority} from 'src/constants/messageTypes';
import {actions as MessageTemplateActions} from 'src/redux/actions/messageTemplates';
import AlertModal from 'src/pages/MessengerPage/messenger/messages-layout/message-template/AlertModal';
import PriorityIndicator from 'src/pages/MessengerPage/messenger/messages-layout/message-item/PriorityIndicator';
import ReviewConsultModal from 'src/pages/MessengerPage/messenger/messages-layout/message-template/ReviewConsultModal';
import ResponseTemplateDetail from 'src/pages/MessengerPage/messenger/messages-layout/message-template/ResponseTemplateDetail';
import {AuthPayload, Message, MessageTemplateStatus, SendMessageMutationVariables, TemplateElements} from 'src/types';
import DetailResponseTemplateForm from 'src/pages/MessengerPage/messenger/messages-layout/message-template/DetailResponseTemplateForm';
import RespondedTemplateMessageResponse from 'src/pages/MessengerPage/messenger/messages-layout/message-template/RespondedTemplateMessageResponse';

const TemplateMessageBubbleWrapper = styled.div<{priorityType: string}>`
  padding: 12px;
  cursor: pointer;
  background-color: ${(props) =>
    props.priorityType === UrgentMessagePriority
      ? props.theme.priorityYellow
      : props.priorityType === StatMessagePriority
      ? props.theme.statRed
      : props.theme.greyishBrown};
  border: ${(props) =>
    [StatMessagePriority, UrgentMessagePriority].includes(props.priorityType) ? `1px solid white` : `unset`};
  border-radius: 5px;
  color: ${(props) => (props.priorityType === UrgentMessagePriority ? 'black' : 'white')};
`;

const TemplateDetailWrapper = styled.div`
  margin-top: 16px;
`;

const TemplateFieldTitle = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  text-transform: uppercase;
`;

const MessageTime = styled.div`
  font-size: 12px;
  align-self: flex-end;
  text-align: right;
  margin-top: 16px;
`;

const TemplateFieldData = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const FieldWrapper = styled.div`
  margin-top: 8px;
  margin-bottom: 8px;
`;

const TemplateBubbleButton = styled(Button)<{priority: string}>`
  text-transform: none !important;
  border: 1px solid ${(props) =>
    props.priority === UrgentMessagePriority
      ? props.theme.warmGrey
      : props.priority === StatMessagePriority
      ? '#fff'
      : '#fff'} !important;
  padding: 6px 12px !important;
  width: 100%;
  span {
    font-family: 'Nunito Sans', sans-serif !important;
    font-size: 17px;
    color: ${(props) =>
      props.priority === UrgentMessagePriority
        ? '#222222'
        : props.priority === StatMessagePriority
        ? '#fff'
        : '#fff'} }; !important;
  }
`;

const Divider = styled.div<{priority: string}>`
  width: 100%;
  height: 0.5px;
  background-color: ${(props) =>
    props.priority === UrgentMessagePriority
      ? props.theme.warmGrey
      : props.priority === StatMessagePriority
      ? '#fff'
      : '#fff'} };
`;

interface TemplateMessageBubbleProps {
  message: Message;
  authInfo: AuthPayload;
  chatId: string;
  isSelf: boolean;
  respondTemplateMessageMutation: MutationFunction<{}, SendMessageMutationVariables>;
}
const TemplateMessageBubble = ({
  message,
  chatId,
  isSelf,
  authInfo,
  respondTemplateMessageMutation,
}: TemplateMessageBubbleProps) => {
  const [respondMessageTemplate, setRespondMessageTemplate] = useState<boolean>(false),
    [detailResponseTemplateForm, setDetailResponseTemplateForm] = useState<boolean>(false),
    [reviewConsultDetailForm, setReviewConsultDetailForm] = useState<boolean>(false),
    [quitConfirmationDialog, setQuitConfirmationDialog] = useState<boolean>(false),
    [respondedModal, setRespondedModal] = useState<boolean>(false),
    {priorityType, template, isOptimistic} = message;

  let templateFields = isOptimistic ? template.fields : template?.request?.children;
  templateFields = templateFields.filter((element) => {
    return element.type !== TemplateElements.Label && element.type !== TemplateElements.Attachments;
  });
  templateFields = templateFields.slice(0, 3);

  // Function to execute on click of Provide Response button in Review response modal
  const reviewResponseDetailResponseBtnHandler = () => {
    store.dispatch(MessageTemplateActions.resetToInitalState());
    setRespondMessageTemplate(false);
    setDetailResponseTemplateForm(true);
  };

  // Function to execute on click of Review consult button on detail response edit template form modal.
  const detailResponseEditViewReviewConsultBtnHandler = () => {
    setDetailResponseTemplateForm(false);
    setReviewConsultDetailForm(true);
  };

  // Function to execute on click of Back to editing button on review consult detail form
  const reviewConsultBackToEditingBtnHandler = () => {
    setReviewConsultDetailForm(false);
    setDetailResponseTemplateForm(true);
  };

  const viewRequestDetailsBtnHandler = () => {
    setRespondedModal(false);
    setRespondMessageTemplate(true);
  };

  const responseTemplateDetailCloseHandler = () => {
    store.dispatch(MessageTemplateActions.resetToInitalState());
    setRespondMessageTemplate(false);
  };

  const templateMessageBubbleTheme = createTheme({
    palette: {
      primary: {main: AppTheme.chatTeal},
      secondary: {main: AppTheme.watermelon},
    },
    typography: {
      fontFamily: 'Open Sans',
    },
  });

  return (
    <ThemeProvider theme={templateMessageBubbleTheme}>
      <TemplateMessageBubbleWrapper priorityType={priorityType}>
        {!message.repliedTo && (
          <PriorityIndicator priorityType={priorityType} isTemplateMessage={true} template={template} />
        )}
        <TemplateDetailWrapper>
          {templateFields.map((element: any, index) => {
            const {
              title: elementTitle,
              type: elementType,
              selectedOption,
              options,
              state,
              toggleState,
              dateTime,
            } = element;
            let fieldData: string = '';

            if (elementType === TemplateElements.Textfield || elementType === TemplateElements.MultilineTextfield)
              fieldData = element.text;
            else if (elementType === TemplateElements.Dropdown || elementType === TemplateElements.Radio)
              fieldData = options[selectedOption];
            else if (elementType === TemplateElements.Checkbox) {
              options.forEach((option, index) => {
                let optionString = index + 1 === options.length ? option : option + ', ';
                fieldData = state[index] ? fieldData.concat(optionString) : '';
              });
            } else if (elementType === TemplateElements.Toggle) fieldData = toggleState?.toString();
            else if (elementType === TemplateElements.Datepicker) fieldData = moment(dateTime).format('DD MMM YYYY');

            return (
              <React.Fragment key={index}>
                <FieldWrapper>
                  <TemplateFieldTitle>{elementTitle}</TemplateFieldTitle>
                  <TemplateFieldData>{fieldData}</TemplateFieldData>
                </FieldWrapper>
                <Divider priority={priorityType} />
              </React.Fragment>
            );
          })}
          <FieldWrapper>
            <TemplateFieldTitle>Status</TemplateFieldTitle>
            <TemplateFieldData>
              {template?.status === MessageTemplateStatus.pending_response
                ? 'Unread'
                : template?.status === MessageTemplateStatus.response_not_required
                ? 'Response not required'
                : 'Responded'}
            </TemplateFieldData>
          </FieldWrapper>
        </TemplateDetailWrapper>

        <TemplateBubbleButton
          priority={priorityType}
          variant={'outlined'}
          onClick={() => {
            if (isSelf || template?.status === MessageTemplateStatus.pending_response) setRespondMessageTemplate(true);
            else if (template?.status === MessageTemplateStatus.responded) setRespondedModal(true);
            else if (template?.status === MessageTemplateStatus.response_not_required) setRespondMessageTemplate(true);
          }}
        >
          View detail {template?.status === MessageTemplateStatus.pending_response && !isSelf && 'and respond'}
        </TemplateBubbleButton>
        <MessageTime>{moment(message.dateCreated).format('hh:mm A')}</MessageTime>
      </TemplateMessageBubbleWrapper>
      {/* Modal to show response detail of a template message */}
      {respondMessageTemplate && (
        <ResponseTemplateDetail
          isSelf={isSelf}
          chatId={chatId}
          message={message}
          authInfo={authInfo}
          openResponseTemplateDetail={respondMessageTemplate}
          respondTemplateMessageMutation={respondTemplateMessageMutation}
          closeResponseTemplateDetail={responseTemplateDetailCloseHandler}
          reviewResponseDetailResponseBtnHandler={reviewResponseDetailResponseBtnHandler}
        />
      )}
      {respondedModal && (
        <RespondedTemplateMessageResponse
          chatId={chatId}
          message={message}
          respondedModal={respondedModal}
          setRespondedModal={setRespondedModal}
          viewRequestDetailsBtnHandler={viewRequestDetailsBtnHandler}
        />
      )}
      {/* Modal to provide detailed response to a template message, gives a edit form for the user to fill */}
      {detailResponseTemplateForm && (
        <DetailResponseTemplateForm
          message={message}
          openDetailResponseTemplateForm={detailResponseTemplateForm}
          closeDetailResponseTemplateForm={setDetailResponseTemplateForm}
          detailResponseEditViewReviewConsultBtnHandler={detailResponseEditViewReviewConsultBtnHandler}
        />
      )}
      {/* Modal to review the form filled for detailed response type */}
      {reviewConsultDetailForm && (
        <ReviewConsultModal
          chatId={chatId}
          message={message}
          authInfo={authInfo}
          openReviewConsultModal={reviewConsultDetailForm}
          setConfirmationDialogOnOff={setQuitConfirmationDialog}
          setReviewConsultDetailForm={setReviewConsultDetailForm}
          backToEditingBtnHandler={reviewConsultBackToEditingBtnHandler}
          respondTemplateMessageMutation={respondTemplateMessageMutation}
        />
      )}
      {quitConfirmationDialog && (
        <AlertModal
          title={'Quit editing?'}
          subtitle={'All the information that you have entered so far will not be saved. This action cannot be undone.'}
          closeAlertModal={setQuitConfirmationDialog}
          isAlertModalVisible={quitConfirmationDialog}
          alertModalButtons={[
            {
              buttonLabel: 'Cancel',
              type: 'secondary',
              onClickHandler: () => setQuitConfirmationDialog(false),
            },
            {
              buttonLabel: 'Quit anyway',
              type: 'primary',
              onClickHandler: () => {
                setQuitConfirmationDialog(false);
                setRespondMessageTemplate(false);
                setDetailResponseTemplateForm(false);
                setReviewConsultDetailForm(false);
              },
            },
          ]}
        />
      )}
    </ThemeProvider>
  );
};

const TemplateMessageBubbleManager = React.memo(TemplateMessageBubble);

export default (props) => (
  <AuthContext.Consumer>
    {({authInfo}) => (
      <Mutation mutation={RespondTemplateMessageMutation}>
        {(respondTemplateMessageMutation) => (
          <TemplateMessageBubbleManager
            {...props}
            respondTemplateMessageMutation={respondTemplateMessageMutation}
            authInfo={authInfo}
          />
        )}
      </Mutation>
    )}
  </AuthContext.Consumer>
);
