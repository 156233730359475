import React, {useMemo, VFC} from 'react';
import styled from 'styled-components';
import useFetchContactList from '../../../ContactsPage/ContactListSection/hooks/useFetchContactList';
import Button from '@material-ui/core/Button';
import FilterIcon from '../../../../svgs/FilterIcon';
import {EventTimeRangeFilter} from './EventTimeRangeFilter';
import {EventTypeFilter} from './EventTypeFilter';
import {EventSenderFilter} from './EventSenderFilter';
import {EventReceiverFilter} from './EventReceiverFilter';
import {MuiSpan} from '../EmptyTable';
import {EventStatusFilter} from './EventStatusFilter';
// import {EventStatusFilter} from './EventStatusFilter';

const Container = styled.button`
  background: linear-gradient(0deg, #ffffff, #ffffff);
  display: flex;
  border: 1px solid #d8d8d8;
  box-shadow: 0 1px 2px 0 #00000026;
  width: 100%;
  padding: 16px;
  align-items: center;
  justify-content: space-between;
  cursor: default;
`;

const StyledButton = styled(Button)`
  background: #00859a;
  color: white;
  text-transform: none;
`;

export const StyledTextButton = styled(Button)`
  color: #00859a;
`;

export const TableFilterContainer = styled.button<{hasSelection?: boolean; isOpen?: boolean}>`
  height: 40px;
  padding: 8px 12px 8px 12px;
  border-radius: 4px;
  background: linear-gradient(0deg, #ffffff, #ffffff);
  background: ${({hasSelection, isOpen}) => (hasSelection && !isOpen ? '#E3F2F4' : isOpen ? '#CCE7EB' : '')};
  border: ${({isOpen, hasSelection}) =>
    isOpen && !hasSelection ? '1px solid #00859A' : hasSelection ? '' : '1px solid #d8d8d8'};
  margin-left: 14px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  font-size: 15px;
  line-height: 20px;
  font-family: 'Nunito Sans', serif;

  :hover {
    border: ${({isOpen, hasSelection}) => !isOpen && !hasSelection && '1px solid black'};
    background: ${({hasSelection}) => hasSelection && '#CCE7EB'};
  }
`;

export const NumberBadge = styled.span`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-family: 'Nunito Sans';
  font-weight: 600;
  color: white;
  margin-left: 5px;
  padding: 0 6px 0 6px;
  border-radius: 39px;
  background: #00859a;
`;

const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 15px;
  line-height: 20px;
  font-family: 'Nunito Sans';
  flex-wrap: wrap;
  row-gap: 10px;
`;

const FilterActionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
`;

const FilterIconContainer = styled.div`
  margin-right: 10px;
`;

export const TableFilters: VFC<{
  auditLogFilters;
  hasFiltersChanged: boolean;
  idToRoleMap: Map<string, string>;
  onClear: () => void;
}> = ({auditLogFilters, hasFiltersChanged, idToRoleMap, onClear}) => {
  const {applyFilters, hasAnyFilterSelected, numFiltersSelected} = auditLogFilters;

  const {contactResultList} = useFetchContactList({searchKeyword: ''});

  const userOptions = useMemo(() => {
    return contactResultList.map((contact) => ({
      id: contact.id,
      label: `${contact.firstname} ${contact.lastname}`,
      value: contact,
    }));
  }, [contactResultList]);

  const roleOptions = useMemo(() => {
    const roles = [];
    for (let [key, value] of idToRoleMap) {
      roles.push({
        id: key,
        value: key,
        label: value,
      });
    }
    return roles;
  }, [idToRoleMap]);

  return (
    <Container>
      <FiltersContainer>
        <FilterIconContainer>
          <FilterIcon />
        </FilterIconContainer>
        Filter by
        <EventTypeFilter auditLogFilters={auditLogFilters} />
        <EventStatusFilter auditLogFilters={auditLogFilters} />
        <EventSenderFilter auditLogFilters={auditLogFilters} userOptions={userOptions} />
        <EventReceiverFilter auditLogFilters={auditLogFilters} userOptions={userOptions} roleOptions={roleOptions} />
        <EventTimeRangeFilter auditLogFilters={auditLogFilters} />
      </FiltersContainer>
      <FilterActionsContainer>
        <StyledButton
          color="inherit"
          disabled={!hasFiltersChanged}
          variant="contained"
          onClick={() => applyFilters()}
          disableTouchRipple
          style={{
            textTransform: 'none',
            background: hasFiltersChanged && '#00859a',
            color: 'white',
            marginRight: 12,
          }}
        >
          <MuiSpan>Apply Filters</MuiSpan>
        </StyledButton>
        <StyledTextButton
          color="inherit"
          disabled={!hasAnyFilterSelected}
          variant="text"
          onClick={() => onClear()}
          disableTouchRipple
          disableRipple
          style={{
            textTransform: 'none',
            color: numFiltersSelected > 0 && '#00859A',
          }}
        >
          <MuiSpan>Clear all filters ({numFiltersSelected})</MuiSpan>
        </StyledTextButton>
      </FilterActionsContainer>
    </Container>
  );
};
