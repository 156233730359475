import React from 'react';
import {toast} from 'react-toastify';
import styled from 'styled-components';
import {MutationFunction} from 'react-apollo';
import {Dialog, DialogContent, Divider, Grid, LinearProgress} from '@material-ui/core';

import store from 'src/redux';
import {AuthPayload, Message, MessageRequestType, SendMessageMutationVariables, TemplateViews} from 'src/types';
import {actions} from 'src/redux/actions/messageTemplates';
import {
  PrimaryButton,
  SecondaryButton,
  StyledDialogActions,
} from 'src/styles/styled-components/StyledMaterialComponents';
import MessageTemplateFactory from './MessageTemplateFactory';
import sendTemplateMessage from 'src/utils/messengerHelper/SendTemplateMessage';
import {UserAlreadyRespondedToTemplateMessage} from 'src/constants/networkError';
import {MaterialDialogHeader, Transition} from 'src/components/HypercareComponents';
import AlertModal from './AlertModal';

const RequestName = styled.div`
  font-weight: 700;
  font-size: 21px;
  line-height: 28px;
  font-family: 'Nunito Sans', sans-serif;
`;

interface ReviewConsultModalProps {
  chatId: string;
  message: Message;
  authInfo: AuthPayload;
  openReviewConsultModal: boolean;
  backToEditingBtnHandler: () => void;
  setReviewConsultDetailForm: (boolean) => void;
  setConfirmationDialogOnOff: (boolean) => void;
  respondTemplateMessageMutation: MutationFunction<{}, SendMessageMutationVariables>;
}
const ReviewConsultModal = ({
  chatId,
  message,
  authInfo,
  openReviewConsultModal,
  setReviewConsultDetailForm,
  setConfirmationDialogOnOff,
  backToEditingBtnHandler,
  respondTemplateMessageMutation,
}: ReviewConsultModalProps) => {
  const {
      template: {formTitle},
    } = message,
    {formValues} = store.getState().messageTemplate,
    [loadingStatus, setLoadingStatus] = React.useState({isLoading: false, loadingMessage: ''}),
    [showAlreadyRespondedModal, setAlreadyRespondedModal] = React.useState(false),
    requestTitle = message?.template?.requestTitle || null;

  const sendMessageHandler = () => {
    setConfirmationDialogOnOff(false);
    const responseData = Object.assign({}, {children: formValues});
    sendTemplateMessage(
      MessageRequestType.respondingtoRequest,
      chatId,
      authInfo,
      respondTemplateMessageMutation,
      responseData,
      message,
      setLoadingStatus,
    )
      .then((_) => {
        store.dispatch(actions.resetToInitalState());
        setReviewConsultDetailForm(false);
      })
      .catch((e) => {
        console.error(e);
        if (e?.graphQLErrors[0].name === UserAlreadyRespondedToTemplateMessage) {
          setAlreadyRespondedModal(true);
        } else toast.error('Failed to make the network request, please check your internet connection and try again');
      });
  };

  return (
    <React.Fragment>
      <Dialog maxWidth={'sm'} fullWidth={true} open={openReviewConsultModal} TransitionComponent={Transition}>
        {loadingStatus.isLoading && <LinearProgress />}
        <MaterialDialogHeader
          loading={loadingStatus.isLoading}
          dialogTitle={'Review response'}
          closeIconButtonClickHandler={() => {
            store.dispatch(actions.resetToInitalState());
            setConfirmationDialogOnOff(true);
          }}
        />
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item container>
              <Grid item lg={12}>
                <RequestName>
                  {formTitle} {`${requestTitle ? `- ${requestTitle}` : ''}`}
                </RequestName>
              </Grid>
            </Grid>
            {new MessageTemplateFactory(message.template, TemplateViews.DetailResponseReview).createUIWithJSONParser()}
          </Grid>
        </DialogContent>
        <Divider />
        <StyledDialogActions>
          <SecondaryButton disabled={loadingStatus.isLoading} onClick={() => backToEditingBtnHandler()}>
            Back to editing
          </SecondaryButton>
          <PrimaryButton disabled={loadingStatus.isLoading} onClick={() => sendMessageHandler()}>
            {loadingStatus.isLoading ? loadingStatus.loadingMessage : 'Send Response'}
          </PrimaryButton>
        </StyledDialogActions>
      </Dialog>
      {showAlreadyRespondedModal && (
        <AlertModal
          title={'Error: Another person has responded to this message template already'}
          subtitle={
            'Sorry, your response cannot be sent because another user in your group chat has already has responded to it'
          }
          closeAlertModal={setAlreadyRespondedModal}
          isAlertModalVisible={showAlreadyRespondedModal}
          alertModalButtons={[
            {
              buttonLabel: 'OK, got it',
              type: 'primary',
              onClickHandler: () => {
                setAlreadyRespondedModal(false);
                setReviewConsultDetailForm(false);
              },
            },
          ]}
        />
      )}
    </React.Fragment>
  );
};
export default ReviewConsultModal;
