import React from 'react';
import {FetchFavouriteRolesForSiteResult, FetchRoleNotesForDepartmentResult} from 'src/types/HiddenNotes';

import client from 'src/apollo';
import {FETCH_DEPARTMENT_ROLES_NOTES} from 'src/gql/v2/query/FetchRoleNotesForDepartment';
import {ApolloQueryResult} from 'apollo-client';
import {FETCH_CODE_TEAM_FOR_DEPARTMENT} from 'src/gql/query/FetchCodeTeamForDepartment.ts';
import {
  FetchCodeTeamForDepartmentResult,
  FetchDepartmentsAndRolesResult,
  FetchDepartmentsMetadataForSiteResult,
} from 'src/types';
import {
  FETCH_DEPARTMENTS_AND_ROLES,
  FETCH_DEPARTMENTS_METADATA_FOR_SITE,
} from 'src/gql/query/FetchDepartmentsAndRolesQuery';
import moment from 'moment-timezone';
import {FETCH_FAVOURITE_ROLE} from 'src/gql/v2/query/FetchFavouriteRolesForSite';

import {
  FETCH_SCHEDULE_FOR_DEPARTMENT,
  FetchDepartmentScheduleInput,
  FetchDepartmentScheduleResponse,
} from '../../gql/query/FetchDepartmentSchedule';
import {
  FETCH_SITES_FOR_ORGANIZATION,
  FetchSitesForOrganizationRequestPayload,
  FetchSitesForOrganizationResponse,
} from '../../gql/query/FetchSitesForOrganization';

export default () => {
  const fetchRoleNotesForDepartment = async (scheduleId: number, departmentId: number) => {
    try {
      const result: ApolloQueryResult<FetchRoleNotesForDepartmentResult> = await client.query({
        query: FETCH_DEPARTMENT_ROLES_NOTES,
        variables: {
          scheduleId,
          organizationalUnit: {
            id: departmentId,
            type: 'department',
          },
        },
        fetchPolicy: 'no-cache',
      });
      return Promise.resolve({error: null, result});
    } catch (err) {
      return Promise.resolve({error: err.message, result: null});
    }
  };
  const fetchCodeTeamsForDepartment = async (departmentId: number) => {
    try {
      const result: ApolloQueryResult<FetchCodeTeamForDepartmentResult> = await client.query({
        query: FETCH_CODE_TEAM_FOR_DEPARTMENT,
        variables: {
          departmentId,
        },
        fetchPolicy: 'no-cache',
      });
      return Promise.resolve({error: null, result: result.data});
    } catch (err) {
      return Promise.resolve({error: err.message, result: null});
    }
  };

  const fetchDepartmentsAndRoleForSite = async (siteId: number) => {
    try {
      const result: ApolloQueryResult<FetchDepartmentsAndRolesResult> = await client.query({
        query: FETCH_DEPARTMENTS_AND_ROLES,
        variables: {
          siteId,
          startDate: moment().startOf('month').toISOString(),
          endDate: moment().endOf('month').millisecond(0o00).toISOString(),
          month: moment().startOf('month').format('MMMM').toLowerCase(),
          year: moment().year(),
        },
        fetchPolicy: 'no-cache',
      });
      return Promise.resolve({errorMessage: null, result, success: true});
    } catch (err) {
      return Promise.resolve({errorMessage: err.message, result: null, success: false});
    }
  };

  const fetchDepartmentsForSite = async (siteId: number) => {
    try {
      const result = await client.query<FetchDepartmentsMetadataForSiteResult>({
        query: FETCH_DEPARTMENTS_METADATA_FOR_SITE,
        variables: {
          siteId,
        },
        fetchPolicy: 'no-cache',
      });

      return {error: null, result};
    } catch (error: any) {
      return {error: error.message, result: null};
    }
  };

  const fetchFavouriteRolesForSite = async (siteId: number) => {
    try {
      const result: ApolloQueryResult<FetchFavouriteRolesForSiteResult> = await client.query({
        query: FETCH_FAVOURITE_ROLE,
        variables: {
          organizationalUnit: {
            id: siteId,
            type: 'site',
          },
        },
        fetchPolicy: 'no-cache',
      });
      return Promise.resolve({error: null, result});
    } catch (err) {
      return Promise.resolve({error: err.message, result: null});
    }
  };

  const fetchScheduleForDepartment = async ({siteId, departmentId}: FetchDepartmentScheduleInput) => {
    try {
      const result = await client.query<FetchDepartmentScheduleResponse>({
        query: FETCH_SCHEDULE_FOR_DEPARTMENT,
        variables: {
          siteId,
          departmentId,
          startDate: moment().startOf('month').toISOString(),
          endDate: moment().endOf('month').millisecond(0o00).toISOString(),
          month: moment().startOf('month').format('MMMM').toLowerCase(),
          year: moment().year(),
        },
        fetchPolicy: 'no-cache',
      });
      return Promise.resolve({result});
    } catch (err) {
      return Promise.resolve({error: err.message as string});
    }
  };

  const fetchSitesForOrg = async (organizationId: number, filterStatus: boolean) => {
    try {
      const result = await client.query<FetchSitesForOrganizationResponse, FetchSitesForOrganizationRequestPayload>({
        query: FETCH_SITES_FOR_ORGANIZATION,
        variables: {
          organizationId,
          filterStatus,
        },
      });
      return Promise.resolve({result});
    } catch (err) {
      return Promise.resolve({error: err.message as string});
    }
  };

  return {
    fetchRoleNotesForDepartment,
    fetchCodeTeamsForDepartment,
    fetchDepartmentsAndRoleForSite,
    fetchFavouriteRolesForSite,
    fetchScheduleForDepartment,
    fetchDepartmentsForSite,
    fetchSitesForOrg,
  };
};
