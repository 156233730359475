import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {SmallLoader} from 'src/components/LoadingDiv';
import styled from 'styled-components';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Chevron from 'src/svgs/Chevron';
import Typography from '@material-ui/core/Typography';
import ContactListV2 from 'src/pages/ContactsPage/ContactListSection/ContactListV2';
import {UserViewModel} from 'src/pages/ContactsPage/viewModels/UserViewModel';
import {CONTACT_LIST_ERROR, NO_USERS} from 'src/constants/user';
import {PaginatedUsers} from 'src/gql/v2/query/FetchPaginatedUsersQuery';
import {NoSearchResultsFound} from './NoSearchResultsFound';
import {SiteScopes} from 'src/data/repository/UserRepository';
import {CircularProgress} from '@material-ui/core';

const ContactsListWrapper = styled.div`
  display: contents;
  max-height: 100%;
`;

const ContactListSummary = styled.span`
  font-weight: bold;
  font-family: Nunito;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  expandWrapper: {
    boxShadow: 'none',
    margin: '0 !important',
  },
  summaryHeaderWrapper: {
    position: 'sticky',
    top: 0,
    padding: '4px 0.5em',
    zIndex: 1,
    backgroundColor: 'white',
  },
}));

interface ContactListWrapperProps {
  searchLoading: boolean;
  localSearchText: string;
  searchUserData: PaginatedUsers | null;
  setSearchUserData: React.Dispatch<React.SetStateAction<PaginatedUsers | null>>;
  isDoneRows: boolean;
  setIsDoneRows: React.Dispatch<React.SetStateAction<boolean>>;
  seenContinuationIds: string[];
  setSeenContinuationIds: React.Dispatch<React.SetStateAction<string[]>>;
  scopes: SiteScopes[];
}

const ContactListWrapperV2 = ({
  localSearchText,
  searchLoading,
  searchUserData,
  setSearchUserData,
  isDoneRows,
  setIsDoneRows,
  seenContinuationIds,
  setSeenContinuationIds,
  scopes,
}: ContactListWrapperProps) => {
  const classes = useStyles({});
  const userViewModel = UserViewModel();

  const paginatedUserDataQuery = userViewModel.fetchPaginatedUsers({
    continuationId: null,
    direction: 'next',
    scopes,
  });

  const contactResultList =
    localSearchText.length > 0
      ? searchUserData?.users || []
      : paginatedUserDataQuery?.data?.organizationalUnitQuery?.organizationalUnit?.paginatedMembersInScopes?.users ||
        [];

  const ContactsListCount =
    localSearchText.length === 0
      ? paginatedUserDataQuery?.data?.organizationalUnitQuery?.organizationalUnit?.paginatedMembersInScopes
          ?.totalResultsCount || 0
      : searchUserData?.totalResultsCount || 0;

  const loader = localSearchText.length === 0 ? paginatedUserDataQuery?.loading : searchLoading;
  if (loader)
    return (
      <LoadingContainer>
        <CircularProgress />
      </LoadingContainer>
    );
  if (paginatedUserDataQuery?.error) return <p>{CONTACT_LIST_ERROR}</p>;
  return (
    <ContactsListWrapper className={classes.root}>
      <ExpansionPanel defaultExpanded={true} className={classes.expandWrapper} TransitionProps={{timeout: 300}}>
        <ExpansionPanelSummary
          className={classes.summaryHeaderWrapper}
          expandIcon={<Chevron />}
          aria-controls="invite-summary"
        >
          <Typography className={classes.heading}>
            <ContactListSummary>All Contacts ({ContactsListCount})</ContactListSummary>
          </Typography>
        </ExpansionPanelSummary>
        <div style={{alignContent: 'center'}}>
          {searchLoading ? (
            <LoadingContainer>
              <CircularProgress />
            </LoadingContainer>
          ) : !contactResultList?.length ? (
            <NoSearchResultsFound />
          ) : (
            !paginatedUserDataQuery?.data?.organizationalUnitQuery?.organizationalUnit?.paginatedMembersInScopes?.users
              .length && <p>{NO_USERS}</p>
          )}
        </div>
        <ExpansionPanelDetails style={{padding: 0}}>
          <ContactListV2
            contactList={contactResultList}
            paginatedUserData={paginatedUserDataQuery?.data}
            fetchMorePaginatedUserData={paginatedUserDataQuery?.fetchMore}
            localSearchText={localSearchText}
            searchUserData={searchUserData}
            setSearchUserData={setSearchUserData}
            isDoneRows={isDoneRows}
            setIsDoneRows={setIsDoneRows}
            seenContinuationIds={seenContinuationIds}
            setSeenContinuationIds={setSeenContinuationIds}
            scopes={scopes}
          />
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </ContactsListWrapper>
  );
};

export default ContactListWrapperV2;
