import * as React from 'react';
import styled from 'styled-components';
import {typedUseSelector, AppDispatch} from 'src/redux';
import {Message} from 'src/types';
import {CloseBtn} from 'src/styles/styled-components/ModalStyleComponents';
import {useDispatch} from 'react-redux';
import ReplyMessagePreviewWrapper from 'src/pages/MessengerPage/messenger/messages-layout/ReplyMessagePreviewWrapper';
import {Actions} from 'src/redux/reducers';

const ReplyPreviewWrapper = styled.div`
  display: flex;
  position: relative;
  overflow-x: auto;
  padding: 1em;
  display: 'inline-flex';
  border-radius: 2px;
  border-top: 1px solid lightgrey;
  width: 100%;
  height: max-content;
  min-height: 90px;
  overflow: hidden;
`;

const PreviewInnerContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 1em;
  margin-right: 0.5em;
  border-radius: 15px;
  background-color: ${(props) => props.theme.lightGrey};
`;

const PreviewCloseBtn = styled(CloseBtn)`
  position: absolute;
  right: 1em;
  top: 1.5em;
  opacity: 0.5;
`;

interface Props {
  chatId: string;
}

const ReplyMessagePreview = ({chatId}: Props) => {
  const chatDraft = typedUseSelector((state) => state.drafts.draftMsgToChatId[chatId]);
  const dispatch: AppDispatch = useDispatch();

  const handleClosePreview = () => {
    dispatch({
      type: 'drafts/CLEAR_REPLY',
      payload: {chatId},
    } as Actions);
  };

  if (chatDraft && chatDraft.repliedTo) {
    const draftMessage: Message = chatDraft.repliedTo;

    return (
      <ReplyPreviewWrapper>
        <PreviewInnerContainer>
          <PreviewCloseBtn onClick={handleClosePreview} />
          <ReplyMessagePreviewWrapper isInMessageBubble={false} chatId={chatId} message={draftMessage} />
        </PreviewInnerContainer>
      </ReplyPreviewWrapper>
    );
  }

  return null;
};

export default ReplyMessagePreview;
