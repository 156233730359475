import React, {useState} from 'react';
import {
  AuthRegionMap,
  CHANGE_REGION_MODAL_DESCRIPTION_TEXT,
  LOGIN_PAGE_CHANGE_REGION_MODAL_SWITCH_BUTTON,
  CHANGE_REGION_MODAL_TITLE_TEXT,
  SIGN_UP_PAGE_CHANGE_REGION_MODAL_SWITCH_BUTTON,
  SIGNUP,
} from 'src/constants/strings';
import styled from 'styled-components';
import {AuthRegion, IAuthRegionData} from 'src/types';

import AnalyticsManager, {EVENTS} from 'src/analytics/AnalyticsManager';
import {ChangeRegionViewModel} from './ChangeRegionViewModel';
import AlertModal from 'src/pages/MessengerPage/messenger/messages-layout/message-template/AlertModal';
import {toast} from 'react-toastify';
import {HCLabelOne, HCTextContext} from '../HypercareComponents';
import theme from 'src/styles/theme';
import {LOGIN} from '../../constants/strings';
import {callNative} from '../../nativeBridge';
import {WebViewCallBacks} from '../../types/sta';

const ChangeRegionModalBodyTextContainer = styled.div`
  margin: 0 0 24px 0;
`;

const ChangeRegionModalAuthRegionItems = styled.ul`
  margin: 0 0 12px 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: auto;
  cursor: pointer;
`;

const RegionSelectorItem = styled.li<{$isSelected: boolean}>`
  background: ${(props) => (props.$isSelected ? '#F6F6F9' : 'transparent')};
  box-shadow: ${(props) => (props.$isSelected ? '0px 1px 4px rgba(0, 0, 0, 0.1)' : 'unset')};

  padding: 16px;
  gap: 10px;
  border: 1px solid #d8d8d8;
  display: flex;
  align-items: center;
  border-radius: 4px;
`;

interface IChangeRegionModal {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  currentScreen: string;
  handleNext?: () => void;
}

const ChangeRegionModal = ({isOpen, setIsOpen, currentScreen, handleNext}: IChangeRegionModal) => {
  const {currentAuthRegion, AuthRegionData, submitRegionChange} = ChangeRegionViewModel();

  const [selectedRegion, setSelectedRegion] = useState<AuthRegion>(currentAuthRegion);

  const closeChangeRegionModal = () => {
    setIsOpen(false);
  };

  const handleRegionChange = (option: IAuthRegionData) => {
    setSelectedRegion(option.region);
  };

  const handleSubmitRegionChange = () => {
    const result = submitRegionChange(selectedRegion);
    if (result.success) {
      callNative(WebViewCallBacks.SWITCH_REGION, {regionCode: selectedRegion});
      closeChangeRegionModal();

      if (currentScreen === SIGNUP) {
        handleNext();
      }
      AnalyticsManager.applyAnalytics({
        eventName: EVENTS.switchRegionPressed,
        params: {
          region_name: selectedRegion,
        },
      });
    } else {
      toast.error(result.error, {
        className: 'Toast-Container',
        autoClose: 10000,
      });
    }
  };

  return (
    <AlertModal
      width="sm"
      titleColor={theme.watermelon}
      title={CHANGE_REGION_MODAL_TITLE_TEXT}
      titleFontSize="21px"
      isAlertModalVisible={isOpen}
      showCloseButton={true}
      closeAlertModal={() => setIsOpen(false)}
      alertModalButtons={[
        {
          type: 'secondary',
          buttonLabel: 'Cancel',
          onClickHandler: () => setIsOpen(false),
          id: 'logout-btn',
          color: 'black',
        },
        {
          type: 'primary',
          buttonLabel:
            currentScreen === LOGIN
              ? LOGIN_PAGE_CHANGE_REGION_MODAL_SWITCH_BUTTON(selectedRegion, AuthRegionMap)
              : SIGN_UP_PAGE_CHANGE_REGION_MODAL_SWITCH_BUTTON(selectedRegion, AuthRegionMap),
          onClickHandler: () => handleSubmitRegionChange(),
          id: 'continue-session-btn',
        },
      ]}
      modalContent={
        <>
          <ChangeRegionModalBodyTextContainer>
            <HCLabelOne color="#4A4A4A">{CHANGE_REGION_MODAL_DESCRIPTION_TEXT}</HCLabelOne>
          </ChangeRegionModalBodyTextContainer>

          <ChangeRegionModalAuthRegionItems>
            {AuthRegionData.map((opt, index) => (
              <RegionSelectorItem
                key={`${index}-${opt.regionName}`}
                $isSelected={selectedRegion === opt.region}
                onClick={() => handleRegionChange(opt)}
              >
                <img width="24px" height="24px" src={opt?.flagIcon} alt={opt?.regionName} />
                <HCTextContext>{opt.regionName}</HCTextContext>
              </RegionSelectorItem>
            ))}
          </ChangeRegionModalAuthRegionItems>
        </>
      }
    />
  );
};

export default ChangeRegionModal;
