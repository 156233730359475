import * as React from 'react';
import styled from 'styled-components';
import InputChecker, {Format} from 'src/utils/changePasswordInputChecker';
import getColor from 'src/utils/getColor';

const ReminderTitle = styled.p`
  color: ${getColor('greyishBrown')};
  font-size: 14px;
  margin-bottom: 0.25em;
  line-height: 1.71;
`;

const PwdTodoWrapper = styled.ul`
  margin-top: 0;
`;

const PwdTodoItems = styled.li<ShouldLinethrough>`
  padding-bottom: 0.25em;
  color: ${getColor('greyishBrown')};
  font-size: 14px;
  ${(props) => (props.linethrough ? `text-decoration: line-through;` : `text-decoration: none;`)}
`;

interface ShouldLinethrough {
  linethrough?: boolean;
}

interface Props {
  newpassword: string;
  showConfirm: boolean;
  confirmpassword?: string; // for change password
}

class PasswordChecklist extends React.Component<Props> {
  public render() {
    const {newpassword, confirmpassword, showConfirm} = this.props;
    return (
      <React.Fragment>
        {showConfirm && <ReminderTitle>We enforce:</ReminderTitle>}
        <PwdTodoWrapper>
          <PwdTodoItems linethrough={newpassword.length >= 8}>A minimum length of 8 characters</PwdTodoItems>
          <PwdTodoItems linethrough={Format.test(newpassword)}>Include special characters (ie. % or &)</PwdTodoItems>
          {showConfirm && (
            <PwdTodoItems linethrough={InputChecker(newpassword, confirmpassword ? confirmpassword : '')}>
              Both passwords match
            </PwdTodoItems>
          )}
        </PwdTodoWrapper>
      </React.Fragment>
    );
  }
}

export default PasswordChecklist;
