import React from 'react';
import styled from 'styled-components';
import {StyledModal, CloseBtn, Header} from 'src/styles/styled-components/ModalStyleComponents';
import Button from '@material-ui/core/Button';

const ButtonsHolder = styled.div`
  width: 100%;
  display: flex;
  margin-top: 1.5em;
  justify-content: flex-end;
  button {
    text-transform: capitalize;
    margin-left: 1em;
    width: 160px;
  }
  button:first-child {
    color: ${(props) => props.theme.androidGreen};
  }
  button:disabled {
    color: grey !important;
  }
`;

interface Props {
  isOpen: boolean;
  isRequesting: boolean;
  handleCancelEscalation: () => Promise<void>;
  closeModal: () => void;
}

const ConfirmCancelEscalationModal = ({isRequesting, isOpen, closeModal, handleCancelEscalation}: Props) => {
  return (
    <StyledModal
      isOpen={isOpen}
      shouldCloseOnEsc={!isRequesting}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={!isRequesting}
      onRequestClose={closeModal}
    >
      <Header>
        <span>End this escalation?</span>
        <CloseBtn onClick={closeModal} />
      </Header>

      <div>Escalation is still in progress. Once proceed, this action cannot be undone.</div>

      <ButtonsHolder>
        <Button variant="outlined" onClick={closeModal} disabled={isRequesting} disableTouchRipple>
          cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleCancelEscalation}
          disabled={isRequesting}
          color="secondary"
          disableTouchRipple
        >
          proceed anyway
        </Button>
      </ButtonsHolder>
    </StyledModal>
  );
};

export default ConfirmCancelEscalationModal;
