import React from 'react';
import styled from 'styled-components';
import {WorkStatus} from 'src/types';
import {ON_SHIFT, BUSY, UNAVAILABLE} from 'src/constants/workStatus';
import {
  ListItemText,
  Select,
  Typography,
  ListItemSecondaryAction,
  MenuItem,
  InputLabel,
  makeStyles,
} from '@material-ui/core';
import Check from 'src/svgs/Check';

const StatusDot = styled.div<{status: string}>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 10px;
  background-color: red;
  ${(props) => (props.status === ON_SHIFT ? `background: ${props.theme.appleGreen}` : null)}
  ${(props) => (props.status === BUSY ? `background: ${props.theme.watermelon}` : null)}
  ${(props) => (props.status === UNAVAILABLE ? `background: ${props.theme.greyishBrown}` : null)}
`;

const FieldInputLabel = styled(InputLabel)`
  color: ${(props) => props.theme.greyishBrown} !important;
  margin-bottom: 4px;
  margin-top: 8px;
  font-size: 14px !important;
  font-family: Open Sans !important;
  font-style: normal !important;
  font-weight: normal !important;
  line-height: 22px !important;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    marginBottom: 10,
  },
  statusTypography: {
    marginTop: 10,
    fontFamily: 'Nunito Sans',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '17px',
    lineHeight: '24px',
  },
  dropdownSelectDiv: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#222222',
  },
  optionsListText: {
    maxWidth: '40ch',
    overflow: 'hidden',
    whiteSpace: 'normal',
  },
}));

const transformListValue = (status: WorkStatus) => {
  if (status === ON_SHIFT) return 'Available';
  if (status === BUSY) return 'Busy';
  if (status === UNAVAILABLE) return 'Unavailable';
  return '';
};

const StatusCheckList = ({workStatus, setWorkStatus}) => {
  const classes = useStyles({});
  const [checked, setChecked] = React.useState([workStatus]);

  const handleToggle = (event: React.ChangeEvent<{value: unknown}>) => {
    const {value} = event.target;
    if (checked[0] === value) return;
    const newChecked = [value];
    setChecked(newChecked);
    setWorkStatus(value);
  };

  const StatusArray = [
    {
      status: ON_SHIFT,
      subtext: 'You will receive all message notifications',
    },
    {
      status: BUSY,
      subtext: 'You will only receive notifications for Urgent and STAT messages',
    },
    {
      status: UNAVAILABLE,
      subtext:
        'You will not receive any notifications, and will not be able to send or read messages until status is turned back to available or busy',
    },
  ];

  return (
    <>
      <Typography variant={'h6'} className={classes.statusTypography}>
        Status
      </Typography>
      <FieldInputLabel htmlFor="statusDropdown">Select status</FieldInputLabel>
      <Select
        id={'statusDropdown'}
        className={classes.root}
        variant={'outlined'}
        value={checked[0]}
        onChange={handleToggle}
        renderValue={(value) => (
          <div className={classes.dropdownSelectDiv}>
            <StatusDot status={checked[0]} />
            <div>{transformListValue(value as WorkStatus)}</div>
          </div>
        )}
      >
        {StatusArray.map(({status, subtext}, index) => {
          const labelId = `checkbox-list-label-${status}`;
          return (
            <MenuItem key={index} value={status}>
              <StatusDot status={status} />
              <ListItemText
                id={labelId}
                className={classes.optionsListText}
                primary={transformListValue(status as WorkStatus)}
                secondary={subtext}
              />
              <ListItemSecondaryAction>{checked.includes(status) && <Check />}</ListItemSecondaryAction>
            </MenuItem>
          );
        })}
      </Select>
    </>
  );
};

export default StatusCheckList;
