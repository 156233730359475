import * as React from 'react';
import moment from 'moment';
import {ClickAwayListener, Fade} from '@material-ui/core';
import {toast} from 'react-toastify';
import {useDispatch} from 'react-redux';

import {
  MonthlyCalendarShift,
  MonthlyCalendar,
  AssigneeWithRole,
  Assignee,
  ShiftRequestType,
  ScheduleLayoutRouterParams,
  AssigneeProp,
} from 'src/types';
import {AssigneeWrapper} from 'src/pages/SchedulingPage/scheduling/ActionCalendarGridSystem';
import GridCellAssignee from 'src/pages/SchedulingPage/action-calendar/GridCellAssignee';
import {AppDispatch} from 'src/redux';
import SwapShiftIcon from 'src/svgs/SwapShiftIcon';
import MarketplaceIcon from 'src/svgs/MarketplaceIcon';
import getParsedAuthInfo from 'src/utils/localStorageHandler';
import store from 'src/redux';
import {useShiftsFilter} from 'src/pages/SchedulingPage/scheduling/hooks';
import {MenuItem, StyledMenuPaper, StyledPopper} from 'src/pages/SchedulingPage/scheduling/SharedPopperStyles';
import {Actions} from 'src/redux/reducers';
import {SCHEDULING, SCHEDULINGQUERYPARAM} from 'src/constants/routerPathName';
import {SWAPSCHEDULINGVIEW, GIVEAWAYSHIFTVIEW} from 'src/constants/scheduler';
import {useParams, useLocation} from 'react-router-dom';
import OfferMarketplaceModal from 'src/pages/SchedulingPage/schedule-layout/OfferMarketplaceModal';
import {typedUseSelector} from 'src/redux';
import {CalenderShifts} from '@hypercare/hc-web-sdk';

interface Props {
  shifts: MonthlyCalendarShift[];
  monthlyCalendar: MonthlyCalendar;
  userToColorCodes: {
    [userId: string]: string;
  };
}

const SwapActionCalendarAssignees = ({shifts, monthlyCalendar, userToColorCodes}: Props) => {
  const dispatch: AppDispatch = useDispatch();
  const {search} = useLocation();
  let params = useParams() as ScheduleLayoutRouterParams;
  let urlSearchParams = new URLSearchParams(search);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement>(null);
  const [role, setRole] = React.useState(null);
  const [targetShift, setTargetShift] = React.useState<Assignee>(null);
  const [showOfferModal, setOfferModal] = React.useState(false);
  const parsedAuthInfo = getParsedAuthInfo();
  const selfID = parsedAuthInfo ? parsedAuthInfo.user.id : null;

  const assigneeToRole = useShiftsFilter({shifts, calendarType: 'swap'});
  const {selfShift, otherShift} = typedUseSelector((state) => state.tradeShift);
  const isSwap = true;
  const selectedSelfShift = isSwap ? selfShift : null;
  const selectedOtherShift = isSwap ? otherShift : null;

  const handleClickAway = (event: React.MouseEvent<Document, MouseEvent>) => {
    // @ts-ignore
    if (anchorEl && anchorEl.contains(event.target)) return;
    setAnchorEl(null);
  };

  const handleClick = (type: ShiftRequestType) => {
    if (type === SWAPSCHEDULINGVIEW) onSwapShift();
    if (type === GIVEAWAYSHIFTVIEW) onOfferMarketplace();
  };

  const onOfferMarketplace = () => {
    setAnchorEl(null);
    setOfferModal(true);
  };

  const onSwapShift = () => {
    const assignee = targetShift;

    const assigneeWithRole = {
      assignee,
      role,
    } as AssigneeWithRole;

    dispatch({
      type: selfID === assignee.userId ? 'tradeShift/SET_SELF' : 'tradeShift/SET_OTHER',
      payload: {shift: assigneeWithRole},
    } as Actions);

    window.routerHistory.push(`/${SCHEDULING}/${SWAPSCHEDULINGVIEW}`);
  };

  const handleSetShift = (e: React.MouseEvent<HTMLElement>, payload: AssigneeWithRole, isSelf: boolean) => {
    const {selfShift, otherShift} = store.getState().tradeShift;
    const selfShiftAlreadySelected = isSelf && selfShift && selfShift.assignee.shiftId === payload.assignee.shiftId;
    const otherShiftAlreadySelected = !isSelf && otherShift && otherShift.assignee.shiftId === payload.assignee.shiftId;

    if ((selfShiftAlreadySelected || otherShiftAlreadySelected) && params?.actionType === SWAPSCHEDULINGVIEW) {
      dispatch({
        type: isSelf ? 'tradeShift/CLEAR_SELF' : 'tradeShift/CLEAR_OTHER',
      });
      return;
    }

    if (moment() > payload.assignee.startTime) {
      toast.dismiss();
      toast.error('The selected shift has already passed, please select a different shift');
      return;
    }

    if (params?.actionType === SWAPSCHEDULINGVIEW) {
      dispatch({
        type: isSelf ? 'tradeShift/SET_SELF' : 'tradeShift/SET_OTHER',
        payload: {
          shift: payload,
        },
      });
    }

    if (!urlSearchParams.has(SCHEDULINGQUERYPARAM) && params?.actionType !== SWAPSCHEDULINGVIEW) {
      setAnchorEl(e.currentTarget);
      setTargetShift(payload.assignee);
      setRole(payload.role);
    }
  };

  return (
    <AssigneeWrapper>
      {assigneeToRole.map((monthlyCalendarShift, index) => {
        const {assignee, role} = monthlyCalendarShift;
        const payload = {assignee, role};
        const isSelf = selfID === assignee.userId;
        const isSelecting =
          (selectedSelfShift && selectedSelfShift.assignee.shiftId === assignee.shiftId) ||
          (selectedOtherShift && selectedOtherShift.assignee.shiftId === assignee.shiftId);

        const shouldGreyOut = !isSelecting && ((isSelf && selectedSelfShift) || (!isSelf && selectedOtherShift));
        const AssigneeData: AssigneeProp = {
          endTime: assignee.endTime,
          startTime: assignee.startTime,
          userId: assignee.userId,
          userFullName: assignee.userFullName,
          shiftId: assignee.shiftId,
          offerTypes: assignee.offerTypes,
        };

        const menuItem = [];
        return (
          <React.Fragment key={`swap-${index}-${role.roleId}-${assignee.userId}`}>
            <CalenderShifts
              key={`swap-${index}-${role.roleId}-${assignee.userId}`}
              isSelf={isSelf}
              assignee={AssigneeData}
              roleName={role.roleName}
              onCellClick={(e) => handleSetShift(e, payload, isSelf)}
              userToColorCodes={userToColorCodes}
              menuItem={menuItem}
              isSelecting={isSelecting}
              shouldGreyOut={Boolean(shouldGreyOut)}
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              params={params}
              variant="TimeWithNameVariant"
            />

            <StyledPopper open={Boolean(anchorEl)} anchorEl={anchorEl} placement={'bottom-start'} transition>
              {({TransitionProps}) => (
                <ClickAwayListener onClickAway={(e) => handleClickAway(e)}>
                  <Fade {...TransitionProps} timeout={350}>
                    <StyledMenuPaper>
                      <MenuItem onClick={() => handleClick(SWAPSCHEDULINGVIEW)}>
                        <SwapShiftIcon />{' '}
                        <span>{targetShift && targetShift.userId === selfID ? 'Swap Shift' : 'Request to swap'}</span>
                      </MenuItem>
                      {targetShift && targetShift.userId === selfID && (
                        <MenuItem onClick={() => handleClick(GIVEAWAYSHIFTVIEW)}>
                          <MarketplaceIcon /> <span>Offer on marketplace</span>
                        </MenuItem>
                      )}
                    </StyledMenuPaper>
                  </Fade>
                </ClickAwayListener>
              )}
            </StyledPopper>

            {showOfferModal && (
              <OfferMarketplaceModal
                isOpen={showOfferModal}
                closeModal={() => setOfferModal(false)}
                selfShift={
                  {
                    assignee: targetShift,
                    role,
                  } as AssigneeWithRole
                }
                userToColorCodes={userToColorCodes}
              />
            )}
          </React.Fragment>
        );
      })}
    </AssigneeWrapper>
  );
};

export default SwapActionCalendarAssignees;
