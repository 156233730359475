import React, {Component} from 'react';
import styled from 'styled-components';
import SchedulePageCallbackForm from 'src/pages/LocatingPage/locating/profile-section/CallbackModalForm';
import {toast} from 'react-toastify';
import client from 'src/apollo';
import {LocatingRole, FullOrganizationMember} from 'src/types';
import PageCallbackNumberUserMutation from 'src/gql/mutation/PageCallbackNumberUserMutation';
import PageCallbackNumberRoleMutation from 'src/gql/mutation/PageCallbackNumberRoleMutation';
import {StyledModal, CloseBtn, Header} from 'src/styles/styled-components/ModalStyleComponents';
import AnalyticsManager, {EVENTS} from 'src/analytics/AnalyticsManager';

const DisplayNumber = styled.div`
  color: ${(props) => props.theme.greyishBrown};
  padding-bottom: 10px;
`;

interface Props {
  showModal: boolean;
  displayNumber: String | null;
  selectedUser: FullOrganizationMember;
  selectedRole: LocatingRole | null;
  handleCloseModal: () => void;
}

class PageCallbackModal extends Component<Props> {
  public handleModalFormSubmission = (values: {pageNumber: string}) => {
    const {handleCloseModal, selectedUser, selectedRole} = this.props;

    const onSuccess = () => {
      selectedUser ? this.successPageUser(selectedUser.id) : this.successPageRole(selectedRole.id);
    };

    return new Promise(async (resolve, reject) => {
      const mutation = selectedUser ? PageCallbackNumberUserMutation : PageCallbackNumberRoleMutation;
      let roleIdToString = selectedRole?.id;

      if (typeof selectedRole?.id === 'string') {
        roleIdToString = parseInt(selectedRole?.id);
      }

      const variables = selectedUser
        ? {
            userId: selectedUser.id,
            callback: values.pageNumber,
          }
        : {
            roleId: roleIdToString,
            callback: values.pageNumber,
          };

      try {
        await client.mutate({
          mutation,
          variables,
        });
        resolve('done!');
        onSuccess();
        handleCloseModal();
      } catch (e) {
        console.error(e);
        reject('unknown error');
        this.errorPageUser();
      }
    });
  };

  public successPageUser = (userId: string) => {
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.pageUser,
      params: {
        user_id: userId,
        source: AnalyticsManager.pageViews['profileView'],
      },
    });

    this.props.handleCloseModal();
    toast.success('Page has been sent');
  };

  public successPageRole = (roleId: string | number) => {
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.pageRole,
      params: {
        role_id: roleId,
        source: AnalyticsManager.pageViews['profileView'],
      },
    });

    this.props.handleCloseModal();
    toast.success('Page has been sent');
  };

  public errorPageUser = () => {
    toast.error('Failed to send page');
  };

  public render() {
    const {showModal, handleCloseModal, selectedUser, selectedRole, displayNumber} = this.props;

    return (
      <StyledModal
        isOpen={showModal}
        shouldCloseOnEsc={true}
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        onRequestClose={handleCloseModal}
      >
        <Header>
          <span>Page {selectedRole ? `Role` : `User`}</span>
          <CloseBtn onClick={handleCloseModal} />
        </Header>

        <DisplayNumber>
          {selectedRole ? `${selectedRole.name} ` : `${selectedUser.firstName} ${selectedUser.lastName} `}(
          {displayNumber})
        </DisplayNumber>

        <SchedulePageCallbackForm handleModalFormSubmission={this.handleModalFormSubmission} />
      </StyledModal>
    );
  }
}

export default PageCallbackModal;
