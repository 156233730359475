import React, {useState} from 'react';
import styled from 'styled-components';
import {makeStyles, createStyles, Theme} from '@material-ui/core/styles';
import {InvitedUser, InviteStatus} from 'src/types';
import {toast} from 'react-toastify';
import {CircularProgress, IconButton} from '@material-ui/core';

import UserAvatar from './views/UserAvatar';
import CloseIcon from 'src/svgs/CloseIcon';
import UserItemButton from './views/UserItemButton';
import UserItemDetail from './views/UserItemDetail';
import useReInviteUser from './hooks/useReInviteUser';
import useCancelInvite from './hooks/useCancelInvite';
import AlertModal from 'src/pages/MessengerPage/messenger/messages-layout/message-template/AlertModal';
import useRefetchInvitedUsers from '../InvitedUserSection/hooks/useFetchInvitedUsers';
import {IsFeatureFlagEnabled} from 'src/utils/FeatureFlagManager';
import {usePaginatedPendingDataState} from './hooks/usePaginatedPendingDataState';
import {FetchPaginatedInvitesQueryResponse} from 'src/gql/v2/query/FetchPaginatedInvites';
import {getOrganizationalUnitObject} from 'src/utils/organizationHelper/getOrganizationalUnitObject';
import InfiniteScroll from 'react-infinite-scroller';
import {SmallLoader} from 'src/components/LoadingDiv';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inviteToast: {
      background: '#4a4a4a',
      color: '#fff',
    },
  }),
);

const UserListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: 100%;
`;

const Ellipsis = styled.div`
  font-size: 30px;
  :after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    -webkit-animation: ellipsis steps(4, end) 900ms infinite;
    animation: ellipsis steps(4, end) 900ms infinite;
    content: '\\2026';
    width: 0;
  }
  @keyframes ellipsis {
    to {
      width: 1.25em;
    }
  }
  @-webkit-keyframes ellipsis {
    to {
      width: 1.25em;
    }
  }
`;

const UserItem = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 1em;
  align-items: center;
  justify-content: space-between;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface Props {
  invitedUserList: InvitedUser[];
  fetchMorePaginatedInvitesData: any;
  paginatedPendingInvitesData?: FetchPaginatedInvitesQueryResponse;
}

const InvitationListV2 = ({invitedUserList, fetchMorePaginatedInvitesData, paginatedPendingInvitesData}: Props) => {
  const classes = useStyles();
  const coSigningInvitesFF = IsFeatureFlagEnabled('coSigningInvites');
  const [closingInvite, setClosingInvite] = useState<InvitedUser | null>(null);
  const {tryCancelInvite, cancelInviteLoadingList} = useCancelInvite();
  const {succeedList, loadingList, tryResendInvitation} = useReInviteUser();
  const [showConfirmCancelInvite, setConfirmCancelInvite] = React.useState<boolean>(false);
  const [continuationId, setContinuationId] = useState(
    paginatedPendingInvitesData?.organizationalUnitQuery.organizationalUnit.paginatedInvites.continuationId,
  );
  const {
    isLoadingPendingAdditionalData,
    setIsLoadingPendingAdditionalData,
    isDonePendingRows,
    setIsDonePendingRows,
    additionalPendingRows,
    setAdditionalPendingRows,
    seenPendingContinuationIds,
    setSeenPendingContinuationIds,
  } = usePaginatedPendingDataState();

  const newInvitedUserListData = [...invitedUserList, ...(additionalPendingRows || [])];

  const getMoreData = async () => {
    setIsLoadingPendingAdditionalData(true);

    if (!continuationId || seenPendingContinuationIds.includes(continuationId)) {
      setIsDonePendingRows(true);
      return;
    }

    try {
      await fetchMorePaginatedInvitesData({
        variables: {
          organizationalUnit: getOrganizationalUnitObject(),
          continuationId,
          direction: 'next',
        },
        updateQuery: (
          previousResult: FetchPaginatedInvitesQueryResponse,
          {fetchMoreResult}: {fetchMoreResult: FetchPaginatedInvitesQueryResponse},
        ) => {
          const newFetchedUsers = fetchMoreResult.organizationalUnitQuery.organizationalUnit.paginatedInvites;

          if (newFetchedUsers.continuationId === null) {
            setIsDonePendingRows(true);
          }

          setAdditionalPendingRows([...additionalPendingRows, ...newFetchedUsers.invites]);
          setContinuationId(newFetchedUsers.continuationId);
        },
      });
    } finally {
      setSeenPendingContinuationIds([...seenPendingContinuationIds, continuationId]);
    }
  };

  const {refetch} = useRefetchInvitedUsers({
    searchKeyword: '',
  });

  const handleOnComplete = React.useCallback(() => {
    try {
      if (refetch) refetch();
    } catch (e) {
      console.error(e);
    }
  }, [refetch]);

  const handleResendInvitation = async (invitedUser: InvitedUser) => {
    try {
      const resultName = await tryResendInvitation(invitedUser, !!coSigningInvitesFF);

      toast.success(`Invite for "${resultName}" resent`, {
        autoClose: false,
        className: classes.inviteToast,
      });
    } catch (e) {
      console.error(e);
      toast.error('Failed to resend invitation, please check your internet connection and try again');
    }
    handleOnComplete();
  };

  const handleCancelInvite = async (invitedUser: InvitedUser) => {
    try {
      await tryCancelInvite(invitedUser);
      setConfirmCancelInvite(false);
      toast.success(
        `Invite for "${
          invitedUser?.user
            ? `${invitedUser?.user?.firstname} ${invitedUser?.user?.lastname}`
            : `${invitedUser?.address?.address}" has been withdrawn`
        }`,

        {
          className: classes.inviteToast,
        },
      );
    } catch (e) {
      console.error(e);
      toast.error('Failed to cancel invitation, please check your internet connection and try again');
    } finally {
      handleOnComplete();
    }
  };

  return (
    <UserListWrapper>
      <div style={{maxHeight: `calc(100vh - 205px)`, overflow: 'auto'}} id="scrollableDiv">
        <InfiniteScroll hasMore={!isDonePendingRows} loadMore={getMoreData} initialLoad={false} useWindow={false}>
          {newInvitedUserListData.map((invitedUser) => (
            <UserItem key={invitedUser.id}>
              <UserAvatar invitedUser={invitedUser} />

              <UserItemDetail invitedUser={invitedUser} />

              {coSigningInvitesFF ? (
                invitedUser.inviteStatus === InviteStatus.pending_user_acceptance ? (
                  <UserItemButton
                    invitedUser={invitedUser}
                    succeedList={succeedList}
                    loadingList={loadingList}
                    handleResendInvitation={handleResendInvitation}
                  />
                ) : cancelInviteLoadingList.includes(invitedUser.id) ? (
                  <Ellipsis />
                ) : (
                  <IconButton
                    onClick={() => {
                      setClosingInvite(invitedUser);
                      setConfirmCancelInvite(true);
                    }}
                  >
                    <CloseIcon width={10} height={10} color={'#000000'} />
                  </IconButton>
                )
              ) : (
                <UserItemButton
                  invitedUser={invitedUser}
                  succeedList={succeedList}
                  loadingList={loadingList}
                  handleResendInvitation={handleResendInvitation}
                />
              )}
            </UserItem>
          ))}
          {isLoadingPendingAdditionalData && !isDonePendingRows && (
            <LoadingContainer>
              <CircularProgress />
            </LoadingContainer>
          )}
        </InfiniteScroll>
      </div>

      {showConfirmCancelInvite && (
        <AlertModal
          title={`Withdraw request for "${
            closingInvite?.user
              ? `${closingInvite?.user?.firstname} ${closingInvite?.user?.lastname}`
              : `${closingInvite?.address?.address}"?`
          }`}
          subtitle={''}
          width={'sm'}
          titleFontSize={'21px'}
          closeAlertModal={() => setConfirmCancelInvite(false)}
          isAlertModalVisible={showConfirmCancelInvite}
          alertModalButtons={[
            {
              type: 'secondary',
              buttonLabel: 'Cancel',
              onClickHandler: () => setConfirmCancelInvite(false),
            },
            {
              type: 'primary',
              buttonLabel: 'Confirm Cancellation',
              onClickHandler: () => closingInvite && handleCancelInvite(closingInvite),
            },
          ]}
        />
      )}
    </UserListWrapper>
  );
};

export default InvitationListV2;
