import store from 'src/redux';
import {UserOrganizationSwitcherPayload} from 'src/types';
import {updateScopeToken} from 'src/utils/localStorageHandler';
import ApiHelper from 'src/api';
import {ARCHIVED, MESSENGER, LOCATING, JOINORGANIZATION} from 'src/constants/routerPathName';
import {actions} from 'src/redux/actions/organization';
import AnalyticsManager, {EVENTS} from 'src/analytics/AnalyticsManager';
import getParsedAuthInfo from 'src/utils/localStorageHandler';
import sleep from 'src/utils/sleep';
import {PREVIOUS_ORGANIZATION} from 'src/constants/storageKeys';

const tryDirectRoute = (targetUrl?: string) => {
  if (targetUrl) {
    window.routerHistory.push(targetUrl);
    return;
  }

  if (window.routerHistory.location.pathname.includes(MESSENGER)) {
    window.routerHistory.push(`/${MESSENGER}/home`);
  } else if (window.routerHistory.location.pathname.includes(ARCHIVED)) {
    window.routerHistory.push(`/${ARCHIVED}/home`);
  } else if (window.routerHistory.location.pathname.includes(LOCATING)) {
    window.routerHistory.push(`/${LOCATING}`);
  } else if (window.routerHistory.location.pathname.includes(JOINORGANIZATION)) {
    window.routerHistory.push(`/${MESSENGER}/home`);
  }
};

const trySwitchOrganization = async (
  option: UserOrganizationSwitcherPayload,
  isAcceptingTOS?: boolean,
  targetUrl?: string,
) => {
  const {organizationId, name} = option;
  const currentOrganization = store.getState().organization;
  const currentOrganizationId = currentOrganization.organizationId;
  if (currentOrganizationId && organizationId.toString() === currentOrganizationId.toString()) return;
  sessionStorage.setItem(PREVIOUS_ORGANIZATION, JSON.stringify(currentOrganization));

  tryDirectRoute(targetUrl);
  store.dispatch(actions.setCurrentOrganization(option));

  updateScopeToken(option);

  if (isAcceptingTOS) {
    // no needs to catch tos error
    await sleep(2000); // wait for potential replication delay
    await ApiHelper.PrivateEndpoints.acceptingTOS(organizationId);
  }

  AnalyticsManager.applyAnalytics({
    eventName: EVENTS.switchOrganizations,
    params: {
      organization_id: organizationId,
      organization_name: name,
    },
  });

  const parsedAuthInfo = getParsedAuthInfo();
  const selfID = parsedAuthInfo ? parsedAuthInfo.user.id : null;
  if (selfID) {
    AnalyticsManager.identifyUserOrg({
      userId: selfID,
      company: name,
      company_id: organizationId,
    });
  }

  // await client.resetStore();
  window.location.reload();
};

export default trySwitchOrganization;
