import styled from 'styled-components';
import React from 'react';
import AlertTriangleIcon from 'src/svgs/AlertTriangleIcon';
import theme from 'src/styles/theme';
import {GROUP_CHAT_BUSY_OR_UNAVAILABLE_MEMBERS_BANNER_TITLE} from 'src/constants/strings';
import {ArrowRight, ChevronRight} from '@material-ui/icons';
import {Typography} from '@material-ui/core';

const BannerHolder = styled.div`
  display: flex;
  padding: 10px;
  align-items: center;
  color: ${(props) => props.theme.greyishBrown};
  background-color: ${(props) => props.theme.etherealMoodBlue};
  font-family: Nunito;
  font-size: 14px;
  margin: 10px;
  border-radius: 5px;
  cursor: pointer;
`;

export const ChatMembersBusyOrUnavailableBanner = ({
  busyOrUnavailableChatMembersCount,
  onClick,
}: {
  busyOrUnavailableChatMembersCount: number;
  onClick: () => void;
}) => {
  if (busyOrUnavailableChatMembersCount === 0) return null;

  return (
    <BannerHolder onClick={onClick}>
      <AlertTriangleIcon stroke={theme.primary} />
      <Typography variant="body2" style={{paddingLeft: 8}}>
        {GROUP_CHAT_BUSY_OR_UNAVAILABLE_MEMBERS_BANNER_TITLE(busyOrUnavailableChatMembersCount)}
      </Typography>
      <ChevronRight />
    </BannerHolder>
  );
};
