import React from 'react';
import client from 'src/apollo';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import {StyledModal, CloseBtn, Header} from 'src/styles/styled-components/ModalStyleComponents';
import CircularProgress from '@material-ui/core/CircularProgress';
import {toast} from 'react-toastify';
import Checkmark from 'src/svgs/Checkmark';
import {SCHEDULING} from 'src/constants/routerPathName';
import GridCellAssignee from 'src/pages/SchedulingPage/action-calendar/GridCellAssignee';
import {
  CustomToastFlexContainer,
  ModalBodyWrapper,
  TextareaWrapper,
  WarningText,
  ErrorText,
  ButtonsWrapper,
  IndicatorTitle,
} from 'src/styles/styled-components/SharedModalStyles';
import {AssigneeWithRole, ShiftRequestType} from 'src/types';
import {MARKETPLACE} from 'src/constants/scheduler';
import {PostToMarketplaceMutation} from 'src/gql/mutation/OfferMarketplaceMutation';
import RequestTypeCheckboxPicker from 'src/pages/SchedulingPage/schedule-layout/RequestTypeCheckboxPicker';
import {POST_NOT_AVAILABLE, POST_SHIFT_EXPIRED} from 'src/constants/networkError';

const IndicatorHolder = styled.div`
  width: 66%;
  * {
    cursor: auto;
  }
`;

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  selfShift: AssigneeWithRole;
  userToColorCodes: {
    [userId: string]: string;
  };
}

const OfferMarketplaceModal = ({isOpen, closeModal, selfShift, userToColorCodes}: Props) => {
  const [noteValue, setNoteValue] = React.useState('I want to offer because ');
  const [isLoading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');
  const [offerTypes, setOfferTypes] = React.useState<Set<ShiftRequestType>>(() => {
    const initialSet: Set<ShiftRequestType> = new Set();
    initialSet.add('swap');
    return initialSet;
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNoteValue(e.target.value);
  };

  const handleSetOfferTypes = (type: ShiftRequestType) => {
    setOfferTypes((prevTypes) => {
      let newSet = new Set(prevTypes);
      newSet.has(type) ? newSet.delete(type) : newSet.add(type);
      return newSet;
    });
  };

  const handleSendRequest = async () => {
    if (offerTypes.size === 0) {
      setError('At least one offer type has to be selected');
      return;
    }
    try {
      setLoading(true);
      setError('');
      await client.mutate({
        mutation: PostToMarketplaceMutation,
        variables: {
          note: noteValue,
          shiftId: selfShift.assignee.shiftId,
          offerTypes: Array.from(offerTypes),
        },
      });
      toast.info(CustomizedActionToast(), {
        autoClose: false,
      });
      closeModal();
    } catch (e) {
      console.error(e);
      setLoading(false);
      if (e.networkError && e.networkError.result && e.networkError.result.errors && e.networkError.result.errors[0]) {
        let errorCodeName = e.networkError.result.errors[0].code;
        if (errorCodeName === POST_SHIFT_EXPIRED || errorCodeName === POST_NOT_AVAILABLE) {
          setError('The shift you are trying to offer is either unavailable or has been expired');
        } else {
          setError(`Unable to complete your request due to unknown error (${errorCodeName})`);
        }
      } else {
        setError('Failed to offer shift, please check your internet connection and try again');
      }
    }
  };

  const CustomizedActionToast = () => {
    const handleClick = () => {
      window.routerHistory.push(`/${SCHEDULING}/${MARKETPLACE}`);
      toast.dismiss();
    };
    return (
      <CustomToastFlexContainer>
        <div>
          <Checkmark />
          Your shift has been offered on the marketplace
        </div>
        <button onClick={handleClick}>View marketplace</button>
      </CustomToastFlexContainer>
    );
  };

  return (
    <StyledModal
      isOpen={isOpen}
      ariaHideApp={false}
      shouldCloseOnEsc={!isLoading}
      shouldCloseOnOverlayClick={!isLoading}
      onRequestClose={closeModal}
    >
      <Header>
        <span>Offer on marketplace</span>
        {!isLoading && <CloseBtn onClick={closeModal} />}
      </Header>
      <ModalBodyWrapper>
        <IndicatorHolder>
          <IndicatorTitle>I want to offer this shift</IndicatorTitle>
          <GridCellAssignee
            isSwap={false}
            showOfferTypes={false}
            timeformat="MMM DD, YYYY / HH:mm"
            isSelf={true}
            assignee={selfShift.assignee}
            role={selfShift.role}
            onCellClick={() => null}
            userToColorCodes={userToColorCodes}
          />
        </IndicatorHolder>

        <RequestTypeCheckboxPicker offerTypes={offerTypes} handleSetOfferTypes={handleSetOfferTypes} />

        <TextareaWrapper>
          <TextField
            variant="outlined"
            multiline={true}
            rows={4}
            fullWidth
            value={noteValue}
            onChange={handleChange}
            label="Reason for request"
          />
        </TextareaWrapper>

        <WarningText>
          <span>Note: You are still responsible for this shift until someone has taken this offer</span>
        </WarningText>

        <ErrorText>{error}</ErrorText>

        <ButtonsWrapper>
          <Button variant="contained" disabled={isLoading} disableTouchRipple onClick={closeModal}>
            cancel
          </Button>

          <Button
            variant="contained"
            onClick={handleSendRequest}
            disabled={isLoading}
            color="secondary"
            disableTouchRipple
          >
            {isLoading ? <CircularProgress size={20} /> : <span>Offer</span>}
          </Button>
        </ButtonsWrapper>
      </ModalBodyWrapper>
    </StyledModal>
  );
};

export default OfferMarketplaceModal;
