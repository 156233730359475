import React from 'react';
import apptheme from 'src/styles/theme';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import AppBar from '@material-ui/core/AppBar';
import {makeStyles, useTheme, withStyles, Theme, createStyles} from '@material-ui/core/styles';
import MessageItem from 'src/pages/MessengerPage/messenger/search-chat-list-layout/MessageItem';
import {SINGLE, USERS, GROUP, GROUPS, MESSAGES} from 'src/constants/user';
import styled from 'styled-components';
import LoadingDiv from 'src/components/LoadingDiv';
import {SearchChatTypes, SearchUserTypes} from 'src/constants/messageTypes';
import SearchNoRecordFound from 'src/pages/MessengerPage/messenger/SearchNoRecordFound';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
}));

const tabProps = (index) => {
  return {
    id: `search-chat-tab-${index}`,
    'aria-controls': `search-chat-tabpanel-${index}`,
  };
};

const FetchMoreLoadingWrapper = styled.div`
  height: 28rem;
  padding: 5px;
`;

const StyledAppBar = withStyles({
  root: {
    boxShadow: 'none',
    borderBottom: '1px solid #d1d1d1',
  },
})(AppBar);

const ChatTypeTabs = withStyles({
  root: {
    padding: '0 20px',
    backgroundColor: 'white',
    borderBottom: `0.5px solid #dbdbdb`,
    '& div.MuiTabs-scroller': {
      '& .MuiTabs-flexContainer': {
        display: 'contents',
      },
    },
  },
  indicator: {
    backgroundColor: apptheme.androidGreen,
  },
})(Tabs);

const ChatTypeTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: 'capitalize',
      minWidth: 72,
      padding: '0px 0px 0px 0px',
      fontWeight: 400,
      marginRight: theme.spacing(2),
      fontFamily: 'Nunito',
      '&:hover': {
        color: apptheme.black,
        opacity: 1,
      },
      '&$selected': {
        color: apptheme.black,
        fontWeight: 700,
      },
      '&:focus': {
        color: apptheme.black,
      },
    },
    selected: {},
  }),
)(Tab);

const TabPanel = (props) => {
  const {
    value,
    index,
    searchUserData,
    searchGroupData,
    searchMessageData,
    searchSubMessageData,
    handleShowMatchedMessage,
    isSearching,
    handleJumpToMessage,
    handleViewAll,
    totalUsersCount,
    totalGroupsCount,
    totalMessageCount,
    handleChange,
    activeChat,
    isBack,
    setIsBack,
    selectedChat,
    setSelectedChat,
    selectedChatTitle,
    setSelectedChatTitle,
    handleSetActiveChat,
    createChat,
    isJumpToMessageEnable,
    ...other
  } = props;

  const usersChatList = searchUserData;
  const groupChatList = searchGroupData.filter((chat) => chat?.chat?.type === GROUP);
  const messages = isBack ? searchSubMessageData.searchMessages.messages : searchMessageData;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`search-chat-tabpanel-${index}`}
      aria-labelledby={`search-chat-tab-${index}`}
      {...other}
    >
      {value === index && (
        <>
          {value === 0 ? (
            usersChatList.length === 0 && groupChatList.length === 0 ? (
              <SearchNoRecordFound tabValue={value} handleChange={handleChange} />
            ) : (
              <>
                <MessageItem
                  chats={usersChatList}
                  title={USERS}
                  type={SINGLE}
                  createChat={createChat}
                  totalUsersCount={totalUsersCount}
                  totalGroupsCount={totalGroupsCount}
                  handleViewAll={handleViewAll}
                  selectedChat={selectedChat}
                  setSelectedChat={setSelectedChat}
                  selectedChatTitle={selectedChatTitle}
                  setSelectedChatTitle={setSelectedChatTitle}
                  activeChat={activeChat}
                  handleSetActiveChat={handleSetActiveChat}
                />
                <MessageItem
                  chats={groupChatList}
                  title={GROUPS}
                  type={GROUP}
                  totalUsersCount={totalUsersCount}
                  totalGroupsCount={totalGroupsCount}
                  handleViewAll={handleViewAll}
                  selectedChat={selectedChat}
                  setSelectedChat={setSelectedChat}
                  selectedChatTitle={selectedChatTitle}
                  setSelectedChatTitle={setSelectedChatTitle}
                  activeChat={activeChat}
                  handleSetActiveChat={handleSetActiveChat}
                />
              </>
            )
          ) : messages.length === 0 ? (
            <SearchNoRecordFound tabValue={value} handleChange={handleChange} />
          ) : (
            <MessageItem
              chats={messages}
              title={MESSAGES}
              isBack={isBack}
              setIsBack={setIsBack}
              handleJumpToMessage={handleJumpToMessage}
              showMatchedMessage={handleShowMatchedMessage}
              selectedChat={selectedChat}
              setSelectedChat={setSelectedChat}
              selectedChatTitle={selectedChatTitle}
              setSelectedChatTitle={setSelectedChatTitle}
              activeChat={activeChat}
              handleSetActiveChat={handleSetActiveChat}
              totalMessageCount={totalMessageCount}
              isJumpToMessageEnable={isJumpToMessageEnable}
            />
          )}
        </>
      )}
    </div>
  );
};

const SearchTabItem = (props) => {
  const {
    tabValue,
    searchUserData,
    searchGroupData,
    searchMessageData,
    searchSubMessageData,
    fetchMessageList,
    handleShowMatchedMessage,
    isSearching,
    handleJumpToMessage,
    handleViewAll,
    totalUsersCount,
    totalGroupsCount,
    totalMessageCount,
    activeChat,
    isBack,
    setIsBack,
    handleChange,
    handleChangeIndex,
    setSelectedChat,
    selectedChat,
    selectedChatTitle,
    setSelectedChatTitle,
    handleSetActiveChat,
    createChat,
    isJumpToMessageEnable,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const searchTabs = SearchChatTypes;

  return (
    <div className={classes.root}>
      <StyledAppBar position="static" color="default">
        <ChatTypeTabs value={tabValue} onChange={handleChange} variant="fullWidth" aria-label="search-chat-list">
          {searchTabs.map((type, i) => {
            return (
              <ChatTypeTab
                key={i}
                label={type}
                {...tabProps(i)}
                onClick={() => (i === 1 ? fetchMessageList() : null)}
              />
            );
          })}
        </ChatTypeTabs>
      </StyledAppBar>
      <div onChange={handleChangeIndex}>
        {isSearching ? (
          <FetchMoreLoadingWrapper>
            <LoadingDiv />
          </FetchMoreLoadingWrapper>
        ) : (
          searchTabs.map((type, i) => {
            return (
              <TabPanel
                key={i}
                value={tabValue}
                index={i}
                searchUserData={searchUserData}
                searchGroupData={searchGroupData}
                searchMessageData={searchMessageData}
                searchSubMessageData={searchSubMessageData}
                handleShowMatchedMessage={handleShowMatchedMessage}
                isSearching={isSearching}
                handleJumpToMessage={handleJumpToMessage}
                dir={theme.direction}
                handleViewAll={handleViewAll}
                totalUsersCount={totalUsersCount}
                totalGroupsCount={totalGroupsCount}
                totalMessageCount={totalMessageCount}
                activeChat={activeChat}
                isBack={isBack}
                handleChange={handleChange}
                setIsBack={setIsBack}
                setSelectedChat={setSelectedChat}
                selectedChat={selectedChat}
                selectedChatTitle={selectedChatTitle}
                setSelectedChatTitle={setSelectedChatTitle}
                handleSetActiveChat={handleSetActiveChat}
                createChat={createChat}
                isJumpToMessageEnable={isJumpToMessageEnable}
              />
            );
          })
        )}
      </div>
    </div>
  );
};

export default SearchTabItem;
