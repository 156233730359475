import React, {useState} from 'react';
import styled from 'styled-components';
import {FullOrganizationMember, User, UserAddress} from 'src/types';
import {Formik, FormikState} from 'formik';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {profileFormValidationSchema} from 'src/utils/validations';
import EditProfileFormContactInfoList from 'src/pages/LocatingPage/locating/profile-section/EditProfileFormContactInfoList';
import CircularProgress from '@material-ui/core/CircularProgress';
import {AuthContext} from 'src/auth/AuthProvider';
import {toast} from 'react-toastify';
import HypercareSelfProfileViewModel from 'src/pages/LocatingPage/viewModels/HypercareSelfProfileViewModel';
import {
  CANCEL,
  FIRST_NAME,
  FIRST_NAME_PLACEHOLDER,
  JOB_TITLE,
  LAST_NAME,
  LAST_NAME_PLACEHOLDER,
  PROFILE_ADDRESS_UPDATE_SUCCESS,
  SYNCED_INFORMATION,
  JOB_TITLE_PLACEHOLDER,
  ABOUT,
  PROFILE_PAGE_EMPTY_LABEL_TOAST_MESSAGE,
} from 'src/constants/strings';
import {SuccessToast} from 'src/components/CustomToasts';
import AlertModal from 'src/pages/MessengerPage/messenger/messages-layout/message-template/AlertModal';
import AnalyticsManager, {EVENTS} from 'src/analytics/AnalyticsManager';
import {StyledTooltipWhite} from 'src/components/StyledTooltip';
import {HCTextContextTwo} from 'src/components/HypercareComponents';
import {Grid, IconButton} from '@material-ui/core';
import {EditOutlined} from '@material-ui/icons';
import {ProfileInformationLabelWrapper} from 'src/pages/LocatingPage/components/ProfileInformationLabelWrapper';
import {ProfilePageAboutSection} from 'src/pages/LocatingPage/types';
import {IsFeatureFlagEnabled} from '../../../../utils/FeatureFlagManager';
import {FeatureFlagResult} from '../../../../utils/FeatureFlags';

const FormButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-top: 1em;
  margin-bottom: 1em;
  padding-bottom: 10px;

  button {
    width: 150px;
    min-width: 150px;
    margin-left: 1em;
  }
`;

const StyledEditProfileForm = styled.form`
  input,
  textarea {
    color: ${(props) => props.theme.greyishBrown};
  }
  .MuiFormLabel-root {
    color: ${(props) => props.theme.greyishBrown} !important;
  }
  position: relative;
`;
const DisabledOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  z-index: 1;
`;

interface Props {
  user: FullOrganizationMember;
  isEditMode: boolean;
  onModeChange: (mode: 'view' | 'edit') => void;
  isDisabled: boolean;
  refetch: () => void;
  setCallbackPageModal: (bool: boolean) => void;
  updateAuthProviderUserInfo: (user: User) => void;
  setShowPagerModal?: () => void;
  setPagerSelected?: (string) => void;
  setAddressSelected?: (string) => void;
  isSelf: boolean;
}

const EditProfileForm = ({
  user,
  isEditMode,
  isDisabled,
  onModeChange,
  setCallbackPageModal,
  refetch,
  setShowPagerModal,
  setPagerSelected,
  setAddressSelected,
  isSelf,
}: Props) => {
  const [isLoading, setLoading] = useState(false);
  const [showDiscardModal, setShowDiscardModal] = useState(false);
  const {handleUpdateProfile, resetContextState} = HypercareSelfProfileViewModel();
  const [currentAddresses, setCurrentAddresses] = useState<UserAddress[]>(user?.addresses ?? []);
  const {role, firstName, lastName} = user;
  const [isToolTipOpenOne, setIsToolTipOpenOne] = React.useState(false);
  const [isToolTipOpenTwo, setIsToolTipOpenTwo] = React.useState(false);
  const [isToolTipOpenThree, setIsToolTipOpenThree] = React.useState(false);
  const profileFieldLabelFeatureFlag = IsFeatureFlagEnabled(FeatureFlagResult.profileAddressLabel);
  const initialValues = {
    firstname: firstName,
    lastname: lastName,
    role,
  };

  const handleDiscardModal = (resetForm: () => void) => {
    resetForm();
    setCurrentAddresses(user?.addresses ?? []);
    onModeChange('view');
    resetContextState();
    setShowDiscardModal(false);
  };

  const handleFormSubmit = async (
    values: ProfilePageAboutSection,
    resetForm: (nextState?: Partial<FormikState<ProfilePageAboutSection>>) => void,
  ) => {
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.editProfileDoneButtonPressed,
    });

    const findAddressWithEmptyLabel = currentAddresses.some((address) => !address.label);

    if (findAddressWithEmptyLabel && profileFieldLabelFeatureFlag) {
      toast.error(PROFILE_PAGE_EMPTY_LABEL_TOAST_MESSAGE, {toastId: 'emptyLabelToastMessage'});
      return;
    }
    try {
      const result = await handleUpdateProfile(values, currentAddresses);

      if (result.success) {
        refetch();
        resetContextState();
        toast.success(<SuccessToast title={PROFILE_ADDRESS_UPDATE_SUCCESS} />, {
          className: 'toast-message',
          autoClose: 5000,
        });
      }
      if (result.error) {
        toast.error(result.error);
      }
    } catch (err) {
      toast.error(err);
      refetch();
    } finally {
      resetForm();
      onModeChange('view');
    }
  };

  const isEditButtonVisible = !isEditMode && !isDisabled;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={profileFormValidationSchema}
      onSubmit={async (values, {resetForm}) => {
        await handleFormSubmit(values, resetForm);
      }}
    >
      {(props) => {
        const {values, handleChange, handleBlur, touched, errors, resetForm, handleSubmit} = props;
        return (
          <StyledEditProfileForm onSubmit={handleSubmit}>
            {isDisabled && <DisabledOverlay />}
            <Grid container xs={12} alignItems="center">
              <HCTextContextTwo style={{textTransform: 'uppercase'}}>{ABOUT}</HCTextContextTwo>
              {isEditButtonVisible && isSelf && (
                <IconButton style={{marginLeft: 8}} size="small" onClick={() => onModeChange('edit')}>
                  <EditOutlined fontSize="small" />
                </IconButton>
              )}
            </Grid>
            <StyledTooltipWhite
              title={SYNCED_INFORMATION}
              className="styledPopper"
              placement="bottom"
              open={!user?.isDirectorySynced ? false : isToolTipOpenOne}
              onMouseEnter={() => setIsToolTipOpenOne(true)}
              onMouseLeave={() => setIsToolTipOpenOne(false)}
              disableFocusListener
              disableHoverListener
              disableTouchListener
            >
              <TextField
                fullWidth
                style={isEditMode && user?.isDirectorySynced ? {opacity: 0.5, cursor: 'not-allowed'} : {}}
                disabled={!isEditMode || isLoading || user?.isDirectorySynced}
                margin="normal"
                id="firstname"
                name="firstname"
                label={
                  <ProfileInformationLabelWrapper
                    isDirectorySynced={!!user?.isDirectorySynced}
                    labelText={FIRST_NAME}
                  />
                }
                placeholder={FIRST_NAME_PLACEHOLDER}
                value={values.firstname}
                onBlur={handleBlur}
                onChange={handleChange}
                helperText={touched.firstname ? errors.firstname : ''}
                error={touched.firstname && Boolean(errors.firstname)}
              />
            </StyledTooltipWhite>
            <StyledTooltipWhite
              title={SYNCED_INFORMATION}
              className="styledPopper"
              placement="bottom"
              open={!user?.isDirectorySynced ? false : isToolTipOpenTwo}
              onMouseEnter={() => setIsToolTipOpenTwo(true)}
              onMouseLeave={() => setIsToolTipOpenTwo(false)}
              disableFocusListener
              disableHoverListener
              disableTouchListener
            >
              <TextField
                fullWidth
                style={isEditMode && user?.isDirectorySynced ? {opacity: 0.5, cursor: 'not-allowed'} : {}}
                disabled={!isEditMode || isLoading || user?.isDirectorySynced}
                margin="normal"
                id="lastname"
                name="lastname"
                label={
                  <ProfileInformationLabelWrapper isDirectorySynced={!!user?.isDirectorySynced} labelText={LAST_NAME} />
                }
                placeholder={LAST_NAME_PLACEHOLDER}
                InputLabelProps={{
                  shrink: true,
                }}
                value={values.lastname}
                onBlur={handleBlur}
                onChange={handleChange}
                helperText={touched.lastname ? errors.lastname : ''}
                error={touched.lastname && Boolean(errors.lastname)}
              />
            </StyledTooltipWhite>
            <StyledTooltipWhite
              title={SYNCED_INFORMATION}
              className="styledPopper"
              placement="bottom"
              open={!user?.isDirectorySynced ? false : isToolTipOpenThree}
              onMouseEnter={() => setIsToolTipOpenThree(true)}
              onMouseLeave={() => setIsToolTipOpenThree(false)}
              disableFocusListener
              disableHoverListener
              disableTouchListener
            >
              <TextField
                fullWidth
                style={isEditMode && user?.isDirectorySynced ? {opacity: 0.5, cursor: 'not-allowed'} : {}}
                disabled={!isEditMode || isLoading || user?.isDirectorySynced}
                margin="normal"
                id="role"
                name="role"
                label={
                  <ProfileInformationLabelWrapper isDirectorySynced={!!user?.isDirectorySynced} labelText={JOB_TITLE} />
                }
                placeholder={JOB_TITLE_PLACEHOLDER}
                InputLabelProps={{
                  shrink: true,
                }}
                value={values.role || ''}
                onBlur={handleBlur}
                onChange={handleChange}
                helperText={touched.role ? errors.role : ''}
                error={touched.role && Boolean(errors.role)}
              />
            </StyledTooltipWhite>
            <EditProfileFormContactInfoList
              setCurrentAddresses={setCurrentAddresses}
              addresses={currentAddresses}
              isEditMode={isEditMode}
              isLoading={isLoading}
              setEditProfileFormLoading={setLoading}
              setShowCallbackPageModal={() => setCallbackPageModal(true)}
              setShowPagerModal={() => setShowPagerModal && setShowPagerModal()}
              setPagerSelected={setPagerSelected}
              onModeChange={onModeChange}
              isEditButtonVisible={isEditButtonVisible}
              setAddressSelected={setAddressSelected}
              isSelf={isSelf}
            />

            {isEditMode && (
              <FormButtonsWrapper>
                <Button variant="contained" color="secondary" type="submit" disabled={isLoading}>
                  {isLoading ? <CircularProgress size={20} /> : 'update profile'}
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => setShowDiscardModal(true)}
                  disabled={isLoading}
                >
                  {CANCEL}
                </Button>
              </FormButtonsWrapper>
            )}
            {showDiscardModal && (
              <AlertModal
                id="discard-modal"
                width="xs"
                title="Discard unsaved changes?"
                titleFontSize="21px"
                subtitle=""
                closeAlertModal={() => setShowDiscardModal(false)}
                isAlertModalVisible={showDiscardModal}
                alertModalButtons={[
                  {
                    type: 'secondary',
                    buttonLabel: 'Keep editing',
                    onClickHandler: () => setShowDiscardModal(false),
                    id: 'discard-nevermind',
                  },
                  {
                    type: 'primary',
                    buttonLabel: 'Discard',
                    onClickHandler: () => handleDiscardModal(resetForm),
                    id: 'discard-confirm',
                  },
                ]}
              />
            )}
          </StyledEditProfileForm>
        );
      }}
    </Formik>
  );
};

const EditProfileFormWrapper = (props: Props) => (
  <AuthContext.Consumer>
    {({updateAuthUserInfo}) => <EditProfileForm {...props} updateAuthProviderUserInfo={updateAuthUserInfo} />}
  </AuthContext.Consumer>
);

export default EditProfileFormWrapper;
