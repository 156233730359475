import * as React from 'react';
import Spinner from 'react-spinkit';
import styled from 'styled-components';
import theme from 'src/styles/theme';

const LoadingDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const LoadingInfiniteScrollDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BigSpinner = styled(Spinner)`
  width: 45px !important;
  height: 45px !important;
`;

const SmallSpinner = styled(Spinner)`
  width: 25px !important;
  height: 25px !important;
`;

const LoadingBottomDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  margin-bottom: 16px;
  height: 2rem;
`;

const LoadingBottomInnerDiv = styled.div`
  display: none;
`;

export const CustomLoader = styled.div`
  border: 3px solid white;
  border-top: 3px solid ${(props) => props.theme.greyishBrown};
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 1.5s linear infinite;
`;

export const SmallBottomLoader = (props) => (
  <LoadingBottomDiv id="bottomLoader" key={'loader'}>
    <LoadingBottomInnerDiv id="bottomInnerLoader">
      <BigSpinner name="circle" fadeIn="none" color={theme.main} />
    </LoadingBottomInnerDiv>
  </LoadingBottomDiv>
);

export const SmallLoader = () => (
  <LoadingDiv key={'loader'}>
    <SmallSpinner name="circle" fadeIn="none" color={theme.main} />
  </LoadingDiv>
);

export default (props) => (
  <LoadingDiv key={'loader'}>
    <BigSpinner name="circle" fadeIn="none" color={theme.main} />
  </LoadingDiv>
);
