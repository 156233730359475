import React, {useEffect, useState} from 'react';
import {DragDropContext, Droppable} from 'react-beautiful-dnd';
import styled from 'styled-components';

import {AddressType, UserAddress} from 'src/types';
import InfoIcon from 'src/svgs/InfoIcon';
import {
  PROFILE_PAGE_ADD_ANOTHER_ADDRESS_BUTTON_TEXT,
  PROFILE_PAGE_NO_ADDRESS_MESSAGE,
  SHOW_LESS,
  SHOW_MORE,
} from 'src/constants/strings';
import {useParams} from 'react-router-dom';
import getParsedAuthInfo from 'src/utils/localStorageHandler';
import store from 'src/redux';
import {TextButton} from 'src/styles/styled-components/StyledMaterialComponents';
import {HCLabelOne} from 'src/components/HypercareComponents';
import theme from 'src/styles/theme';
import ChevrUp from 'src/svgs/ChevrUp';
import ChevrDown from 'src/svgs/ChevrDown';
import PlusButtonIcon from 'src/svgs/PlusButton';
import {HypercareProfileAddressTableHeader} from './HypercareProfileAddressTableHeader';
import {SingleAddressView} from './SingleAddressView';
import {HypercareProfileAddressTableRows} from './HypercareProfileAddressTableRows';
import {IsFeatureFlagEnabled} from 'src/utils/FeatureFlagManager';
import AnalyticsManager, {EVENTS} from 'src/analytics/AnalyticsManager';
import {PagerType, PagerTypeEvent} from 'src/constants/user';
import {FeatureFlagResult} from 'src/utils/FeatureFlags';

const reorder = (list, startIndex, endIndex) => {
  const result: UserAddress[] = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getListStyle = (isDraggingOver) => ({
  width: '100%',
  background: isDraggingOver ? '' : '',
  padding: '8px',
});

const CustomTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const UserEmptyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  gap: 8px;
  height: 56px;
  background: #ffffff;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  margin-top: 8px;
`;

const UserEmptyWrapperNote = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #4a4a4a;
`;

const ShowMoreWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px;
  gap: 8px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
  height: 42px;

  &:hover {
    text-decoration: underline;
  }
`;

const AddContactMethodButton = styled.div`
  height: 42px;
  &:hover {
    background-color: #f6f6f9 !important;
  }
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px;
  gap: 8px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
`;

interface MyProps {
  addresses: UserAddress[];
  isEditMode: boolean;
  isInputDisabled: boolean;
  arrayHelpers: any;
  setCurrentAddresses: React.Dispatch<React.SetStateAction<UserAddress[]>>;
  setAddressModal: React.Dispatch<React.SetStateAction<Boolean>>;
  setShowCallbackPageModal: () => void;
  setShowPagerModal: () => void;
  setPagerSelected: (addressType: string) => void;
  setAddressSelected: (address: string) => void;
  onModeChange: (mode: 'view' | 'edit') => void;
}

export const ProfileAddressView: React.FC<MyProps> = ({
  addresses,
  isEditMode,
  isInputDisabled,
  setCurrentAddresses,
  setAddressModal,
  setShowCallbackPageModal,
  setShowPagerModal,
  setPagerSelected,
  setAddressSelected,
  onModeChange,
}) => {
  const {userId: routeUserId} = useParams<{userId: string}>();
  const {user} = getParsedAuthInfo();

  const isSelf = routeUserId === user.id;

  const [showMore, setShowMore] = useState(false);

  const isAdmin = store.getState().organization.isAdmin || false;

  const onDragEnd = async (result) => {
    if (!result.destination) return;

    const newItems: UserAddress[] = reorder(addresses, result.source.index, result.destination.index);
    setCurrentAddresses?.(newItems);

    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.editProfileOrderChanged,
    });
  };

  const handleAddContactMethodButtonPressed = () => {
    setAddressModal(true);
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.editProfileAddAnotherMethodButtonPressed,
    });
  };

  const changeShowMore = () => {
    AnalyticsManager.applyAnalytics({
      eventName: showMore ? EVENTS.contactsProfileShowLessButtonPressed : EVENTS.contactsProfileShowMoreButtonPressed,
    });
    setShowMore((prevValue) => !prevValue);
  };

  useEffect(() => {
    if (isEditMode || isSelf || isAdmin) {
      setShowMore(true);
    }
  }, [isEditMode, isSelf]);

  const handlePagerMixpanel = (pagerType: string, address: string) => {
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.contactsSpecificPagerButtonPressed,
      params: {
        address: address,
        addressType:
          pagerType === PagerType.ALPHANUMERIC_PAGER ? PagerTypeEvent.ALPHANUMERIC_PAGER : PagerTypeEvent.NUMERIC_PAGER,
      },
    });
  };

  const handlePhoneMixpanel = (address: string) => {
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.phoneButtonPressed,
      params: {
        address: address,
      },
    });
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.contactsSpecificPhoneButtonPressed,
      params: {
        address: address,
      },
    });
  };

  const handleContactMethodsAddressAnchor = (address: UserAddress) => {
    switch (address.type) {
      case PagerType.NUMERIC_PAGER:
      case PagerType.ALPHANUMERIC_PAGER:
        setShowPagerModal();
        setPagerSelected(address.type);
        setAddressSelected?.(address.address);
        handlePagerMixpanel(address.type, address.address);

        break;
      case AddressType.PHONE_NUMBER:
        handlePhoneMixpanel(address.address);
        break;
      default:
        break;
    }
  };

  if (addresses?.length === 0 && !isEditMode) {
    return (
      <UserEmptyWrapper>
        <InfoIcon />
        <UserEmptyWrapperNote>{PROFILE_PAGE_NO_ADDRESS_MESSAGE}</UserEmptyWrapperNote>
      </UserEmptyWrapper>
    );
  }

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <CustomTable ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
              <HypercareProfileAddressTableHeader isAdmin={isAdmin} isSelf={isSelf} isEditMode={isEditMode} />
              <tbody style={{width: '100%'}}>
                {showMore ? (
                  addresses.map((address, index) => (
                    <HypercareProfileAddressTableRows
                      address={address}
                      addresses={addresses}
                      index={index}
                      isAdmin={isAdmin}
                      onModeChange={onModeChange}
                      isSelf={isSelf}
                      isEditMode={isEditMode}
                      isInputDisabled={isInputDisabled}
                      setCurrentAddresses={setCurrentAddresses}
                      handleContactMethodsAddressAnchor={handleContactMethodsAddressAnchor}
                    />
                  ))
                ) : (
                  <SingleAddressView
                    onModeChange={onModeChange}
                    isSelf={isSelf}
                    isAdmin={isAdmin}
                    isEditMode={isEditMode}
                    address={addresses[0]}
                    handleContactMethodsAddressAnchor={handleContactMethodsAddressAnchor}
                  />
                )}
                {provided.placeholder}
              </tbody>
            </CustomTable>
          )}
        </Droppable>
      </DragDropContext>
      {!isEditMode && addresses?.length > 1 && (
        <ShowMoreWrapper onClick={changeShowMore}>
          {showMore ? <ChevrUp color="black" /> : <ChevrDown color="black" />}
          <span style={{padding: '8px'}}>{showMore ? SHOW_LESS : SHOW_MORE}</span>
        </ShowMoreWrapper>
      )}

      {isEditMode && (
        <AddContactMethodButton>
          <PlusButtonIcon />
          <TextButton onClick={() => handleAddContactMethodButtonPressed()}>
            <HCLabelOne color={theme.primary}>{PROFILE_PAGE_ADD_ANOTHER_ADDRESS_BUTTON_TEXT}</HCLabelOne>
          </TextButton>
        </AddContactMethodButton>
      )}
    </>
  );
};
