import * as React from 'react';
import styled from 'styled-components';

const SearchListIconContainer = styled.div`
  text-align: center;
`;
const SearchLogoContainer = styled.div`
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 16px;
  width: 50%;
`;

const SearchImage = styled.img`
  justify-content: center;
`;

const SearchHelpText = styled.span`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  text-align: center;
  color: #4a4a4a;
`;

const SearchText = styled.span`
  font-weight: bold;
`;

const SearchListContainer = (props) => {
  const {searchText} = props;

  return (
    <>
      <SearchListIconContainer>
        <SearchLogoContainer>
          <SearchImage alt="complex" src="/assets/search-logo.svg" />
        </SearchLogoContainer>
        <SearchHelpText>
          Press Enter to see all results for “<SearchText>{searchText}</SearchText>”
        </SearchHelpText>
      </SearchListIconContainer>
    </>
  );
};

export default SearchListContainer;
