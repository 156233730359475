import React, {PureComponent} from 'react';
import * as R from 'ramda';
import {Query, QueryResult} from 'react-apollo';
import styled from 'styled-components';
import * as ReactModal from 'react-modal';
import LoadingDiv from 'src/components/LoadingDiv';
import {TextOnlyButton} from 'src/styles/styled-components/Button';
import {User, Chat, UserSelectOptionType} from 'src/types';
import GetColleaguesQuery from 'src/gql/query/GetColleaguesQuery';
import {ApolloClient} from 'apollo-client';
import AddMembersMutation from 'src/gql/mutation/AddMembersMutation';
import {StyledModal, Layout} from 'src/styles/styled-components/ModalStyleComponents';
import AddChatMemberListSelect from 'src/pages/MessengerPage/messenger/chat-info-layout/ChatInformationUserDropdown';
import AnalyticsManager, {EVENTS} from 'src/analytics/AnalyticsManager';

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
  margin-bottom: 15px;
`;

const Title = styled.h2`
  font-weight: 500;
`;

const OverflowWrapper = styled.div`
  height: 620px;
  overflow-y: auto;
  .MuiPaper-root {
    max-width: unset !important;
  }
`;

interface Props {
  closeModal: () => void;
  colleagues: User[];
  reactModalProps: ReactModal.Props;
  client: ApolloClient<any>;
  chat: Chat;
  shouldNotCloseModal: () => void;
  shouldCloseModal: () => void;
}

class HelperModal extends PureComponent<Props> {
  public state = {
    shouldClose: true,
  };
  public render() {
    const {reactModalProps, closeModal} = this.props;
    const {isOpen} = reactModalProps;
    const {shouldClose} = this.state;
    return (
      <StyledModal
        isOpen={isOpen}
        ariaHideApp={false}
        shouldCloseOnEsc={shouldClose}
        shouldCloseOnOverlayClick={shouldClose}
        onRequestClose={closeModal}
      >
        <Query query={GetColleaguesQuery}>
          {({loading, error, data, client}: QueryResult) => {
            if (error || loading)
              return (
                <Layout>
                  <LoadingDiv />
                </Layout>
              );
            const colleagues = R.pathOr([], ['colleagues'], data);
            const filterOutShellColleagues = colleagues.filter((colleague) => colleague.status !== 'shell');

            return (
              <ChatInformationAddUserModal
                {...this.props}
                shouldCloseModal={this.shouldCloseModal}
                shouldNotCloseModal={this.shouldNotCloseModal}
                client={client}
                colleagues={filterOutShellColleagues}
              />
            );
          }}
        </Query>
      </StyledModal>
    );
  }
  public shouldCloseModal = () => {
    this.setState({shouldClose: true});
  };
  public shouldNotCloseModal = () => {
    this.setState({shouldClose: false});
  };
}

class ChatInformationAddUserModal extends PureComponent<any> {
  public state = {
    isAdding: false,
  };
  public selectedColleagues: Set<UserSelectOptionType> = new Set();

  public render() {
    const {isAdding} = this.state;
    const filteredColleagues = this.getFilteredColleagues();
    const suggestions = filteredColleagues.map(
      (colleague) =>
        ({
          value: colleague.id,
          label: colleague.firstname + ' ' + colleague.lastname,
          role: colleague.role,
        } as UserSelectOptionType),
    );

    return (
      <Layout>
        <Header>
          {isAdding ? <div /> : <TextOnlyButton onClick={this.tryCloseModal}>Cancel</TextOnlyButton>}
          <Title>Add Group Members</Title>
          {isAdding ? <div /> : <TextOnlyButton onClick={this.onDone}>Add</TextOnlyButton>}
        </Header>
        <OverflowWrapper>
          {isAdding ? (
            <LoadingDiv />
          ) : (
            <AddChatMemberListSelect suggestions={suggestions} onClickColleague={this.onClickColleague} />
          )}
        </OverflowWrapper>
      </Layout>
    );
  }

  private getFilteredColleagues = () => {
    const {chat, colleagues} = this.props;
    const colleaguesNotInChat = colleagues.filter((colleague) => {
      return !chat.members.find((member) => colleague.id === member.id);
    });
    return colleaguesNotInChat;
  };

  private tryCloseModal = () => {
    const {isAdding} = this.state;
    const {closeModal} = this.props;
    if (!isAdding) closeModal();
  };

  private onDone = () => {
    const {isAdding} = this.state;
    const {chat, client, closeModal, shouldCloseModal, shouldNotCloseModal} = this.props;

    if (this.selectedColleagues.size === 0 || isAdding) return;

    this.setState(
      {
        isAdding: true,
      },
      async () => {
        try {
          await shouldNotCloseModal();
          const userIds = Array.from(this.selectedColleagues).map((colleague) => {
            return colleague.value;
          });

          await client.mutate({
            mutation: AddMembersMutation,
            variables: {
              chatId: chat.id,
              userIds,
            },
          });

          AnalyticsManager.applyAnalytics({
            eventName: EVENTS.addMembers,
            params: {
              count: this.selectedColleagues.size,
              chat_id: chat.id,
            },
          });
        } catch (e) {
          console.error(e);
        }
        this.setState({
          isAdding: false,
        });
        shouldCloseModal();
        closeModal();
      },
    );
  };

  private onClickColleague = (colleagues: any) => {
    const selectedColleagues = new Set(colleagues);
    this.selectedColleagues = selectedColleagues as Set<UserSelectOptionType>;
  };
}

export default (props) => <HelperModal {...props} />;
