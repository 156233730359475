import React from 'react';
import SyncIcon from 'src/svgs/SyncIcon';
import styled from 'styled-components';

interface Props {
  valueClassName: string;
  text: string;
}

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const SyncStyleChip = styled.div`
  align-items: center;
  background: #ffffff;
  border-radius: 19px;
  display: inline-flex;
  gap: 4px;
  padding: 2px 8px;
  position: relative;
  border: 1px solid #d8d8d8;
  margin-left: 10px;

  & .icon-wrapper-instance {
    flex: 0 0 auto !important;
  }

  & .sync-instance {
    height: 12px !important;
    position: relative !important;
    width: 12px !important;
  }

  & .value {
    color: #4a4a4a;
    font-family: Open Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
`;

export const StyledSyncDirectoryChip = ({valueClassName, text = 'Tag'}: Props): JSX.Element => {
  return (
    <SyncStyleChip className={'SyncDirectoryChip'}>
      <IconWrapper>
        <SyncIcon />
      </IconWrapper>
      <div className={`value ${valueClassName}`}>{text}</div>
    </SyncStyleChip>
  );
};
