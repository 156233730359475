import React, {useState} from 'react';
import RedirectingToADFSStep from 'src/pages/SignupPage/steps/redirecting-to-adfs.step';
import HypercareSignupRepository from 'src/pages/newSignupFlow/HypercareSignupRepository';

import {AuthContext} from 'src/auth';
import {Link, Redirect} from 'react-router-dom';
import styled from 'styled-components';
import HypercareLogoSVG from 'src/svgs/HypercareLogoSVG';
import HypercareSignupPersonalDetailsViewModel from 'src/pages/newSignupFlow/HypercareSignupPersonalDetails/HypercareSignupPersonalDetailsViewModel';
import {AutoLogin} from 'src/pages/newSignupFlow/components/AutoLogin';
import CreateAccountViewModel from './CreateAccountViewModel';
import AlertModal from 'src/pages/MessengerPage/messenger/messages-layout/message-template/AlertModal';
import {SignupPersonalDetailsView} from './SignUpPersonalDetailsView';
import {SignupJobTitleView} from './SignupJobTitleView';
import JoinCreateOrg from 'src/microfrontend/joinCreateOrg/JoinCreateOrg';
import JoinOrgView from './JoinOrgView';
import JoinOrgViewModel from './JoinOrgViewModel';
import CreateNewOrgViewModel from './CreateNewOrgViewModel';
import CreateNewOrgView from './CreateNewOrgView';
import {CreateAccountView} from './CreateAccountView';
import {SignupEmailView} from './SignupEmailView';
import {SignupVerifyEmailAddressView} from './SignupVerifyAddressView';
import SignupEmailViewModel from './SignupEmailViewModel';
import SignupVerifyAddressViewModel from './SignupVerifyAddressViewModel';

const SignupPageContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  overflow-x: hidden;
`;

const SignupPageHypercareLogoContainer = styled.div`
  margin-top: 5%;
  width: 250px !important;
  height: 48px !important;
`;

const FooterText = styled.div`
  margin-top: -40px;
  height: 28px;
  line-height: 2em;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: ${(props) => props.theme.greyishBrown};
  a {
    color: ${(props) => props.theme.chatTeal};
    text-decoration: none;
    font-weight: bold;
  }
`;

const SignupCoordinator = ({isLoggedIn}) => {
  const [currentStep, setCurrentStep] = useState(1);

  const [autoLogin, setAutoLogin] = useState(false);
  const [auth0Id, setAuth0Id] = useState();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [emailVerificationChallengeId, setEmailVerificationChallengeId] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [challengeId, setChallengeId] = useState('');
  const [createOrgOption, setCreateOrgOption] = useState(false);
  const [isOrgBound, setIsOrgBound] = useState(false);
  const [showExitModal, setShowExitModal] = useState(false);
  const [orgInviteCode, setOrgInviteCode] = useState('');
  const [orgName, setOrgName] = useState('');

  const changeExitModal = () => {
    setShowExitModal((prevState) => !prevState);
  };

  const handleContinueSignupEmail = (values) => {
    setChallengeId(values.challengeId);
    setEmail(values.email);
    if (values.auth0Id) {
      setAuth0Id(values.auth0Id);
    } else {
      setCurrentStep((prevValue) => prevValue + 1);
    }
  };

  const handleContinueVerifyEmail = (values) => {
    setEmailVerificationChallengeId(values.challengeId);
    setCurrentStep((prevValue) => prevValue + 1);
  };

  const handleContinueSignupPhoneNumber = (values) => {
    setPhoneNumber(values.phoneNumber);
    setCurrentStep((prevValue) => prevValue + 1);
  };

  const handleContinuePersonalDetails = (values) => {
    setFirstName(values.firstName);
    setLastName(values.lastName);
    setPhoneNumber(values.phoneNumber);
    isOrgBound ? setCurrentStep((prevValue) => prevValue + 1) : setCurrentStep(4);
  };

  const handleContinueVerifyOrgInviteCode = (values) => {
    console.log(values);
    setOrgInviteCode(values);
    setCurrentStep(5);
  };

  const handleContinueVerifyOrgName = (values) => {
    console.log(values);
    setOrgName(values);
    setCurrentStep(6);
  };

  const handleContinueJobTitle = (values) => {
    setJobTitle(values.jobTitle);
    setCurrentStep((prevValue) => prevValue + 1);
  };

  const handleGoBackADFS = () => {
    setCurrentStep(1);
    setAuth0Id(null);
  };

  const handleGoBack = () => {
    console.log('currentStep', currentStep);
    switch (currentStep) {
      case 1:
        window.routerHistory.push('/login');
        break;
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
        setCurrentStep((prevValue) => prevValue - 1);
        break;
      default:
        break;
    }
  };

  const handleLogin = (values) => {
    setUsername(values.username);
    setPassword(values.password);
    setAutoLogin(true);
  };

  const showSignupEmailView = () => {
    const EmailViewModel = new SignupEmailViewModel(handleContinueSignupEmail);
    const repository = new HypercareSignupRepository();
    EmailViewModel.repository = repository;
    return <SignupEmailView email={email} viewModel={EmailViewModel} onBackButtonClick={handleGoBack} />;
  };

  const showSignupEmailValidationView = () => {
    const EmailValidationViewModel = new SignupVerifyAddressViewModel(handleContinueVerifyEmail);
    const repository = new HypercareSignupRepository();
    EmailValidationViewModel.repository = repository;
    return (
      <SignupVerifyEmailAddressView
        inputData={{email}}
        viewModel={EmailValidationViewModel}
        onBackButtonClick={handleGoBack}
        setChallengeId={setChallengeId}
        challengeId={challengeId}
      />
    );
  };

  const showSignupPersonalDetailsView = () => {
    const AboutYourselfViewModel = new HypercareSignupPersonalDetailsViewModel(handleContinuePersonalDetails);
    const repository = new HypercareSignupRepository();
    AboutYourselfViewModel.repository = repository;

    return (
      <SignupPersonalDetailsView
        onBackButtonClick={handleGoBack}
        viewModel={AboutYourselfViewModel}
        inputData={{firstName, lastName, phoneNumber}}
      />
    );
  };

  const showOptionToJoinCreateOrgView = () => {
    return (
      <JoinCreateOrg
        onBackButtonClick={handleGoBack}
        onOptionOneClick={() => {
          setCurrentStep(5);
          setCreateOrgOption(false);
        }}
        onOptionTwoClick={() => {
          setCurrentStep(5);
          setCreateOrgOption(true);
        }}
      />
    );
  };

  const showJoinOrgWithCodeView = () => {
    const JoinOrgCodeViewModel = new JoinOrgViewModel(handleContinueVerifyOrgInviteCode);
    return <JoinOrgView onBackButtonClick={handleGoBack} viewModel={JoinOrgCodeViewModel} />;
  };

  const showCreateNewOrgView = () => {
    const CreateNewOrganizationViewModel = new CreateNewOrgViewModel(handleContinueVerifyOrgName);
    return <CreateNewOrgView onBackButtonClick={handleGoBack} viewModel={CreateNewOrganizationViewModel} />;
  };

  const showSignupJobTitleView = () => {
    const AboutYourselfViewModel = new HypercareSignupPersonalDetailsViewModel(handleContinueJobTitle);
    const repository = new HypercareSignupRepository();
    AboutYourselfViewModel.repository = repository;

    return (
      <SignupJobTitleView
        onBackButtonClick={handleGoBack}
        viewModel={AboutYourselfViewModel}
        inputData={{firstName, lastName, phoneNumber}}
      />
    );
  };

  const showCreateAccountView = () => {
    const CreateAccount = new CreateAccountViewModel(handleLogin);
    const repository = new HypercareSignupRepository();
    CreateAccount.repository = repository;

    return (
      <CreateAccountView
        inputData={{
          email,
          phoneNumber,
          firstName,
          lastName,
          jobTitle,
          emailVerificationChallengeId,
          orgInviteCode,
          orgName,
        }}
        viewModel={CreateAccount}
        onBackButtonClick={handleGoBack}
      />
    );
  };

  if (isLoggedIn) {
    return <Redirect to="/messenger/home" />;
  }

  if (autoLogin) {
    return (
      <>
        <AuthContext.Consumer>
          {({login}) => <AutoLogin login={login} username={username} password={password} />}
        </AuthContext.Consumer>
      </>
    );
  }

  return (
    <SignupPageContainer>
      <SignupPageHypercareLogoContainer>
        <HypercareLogoSVG />
      </SignupPageHypercareLogoContainer>

      {currentStep === 1 && !Boolean(auth0Id) && showSignupEmailView()}
      {Boolean(auth0Id) && (
        <RedirectingToADFSStep
          onBackButtonClick={handleGoBackADFS}
          onContinue={() => null}
          inputData={{
            email,
          }}
          auth0Id={`${auth0Id}`}
        />
      )}
      {currentStep === 2 && showSignupEmailValidationView()}
      {currentStep === 3 && showSignupPersonalDetailsView()}

      {currentStep === 4 && showOptionToJoinCreateOrgView()}
      {currentStep === 5 && createOrgOption && showCreateNewOrgView()}
      {currentStep === 5 && !createOrgOption && showJoinOrgWithCodeView()}
      {currentStep === 6 && showSignupJobTitleView()}
      {currentStep === 7 && showCreateAccountView()}

      {showExitModal ? (
        <AlertModal
          id="exit-modal"
          width="xs"
          title="Leave this page?"
          titleFontSize="21px"
          subtitle="Information you have entered will not be saved if you leave this page."
          isAlertModalVisible={showExitModal}
          closeAlertModal={() => setShowExitModal(false)}
          alertModalButtons={[
            {
              type: 'secondary',
              buttonLabel: 'Stay',
              onClickHandler: () => changeExitModal(),
              id: 'exit-stay',
            },
            {
              type: 'primary',
              buttonLabel: 'Leave',
              onClickHandler: () => window.location.assign('/'),
              id: 'exit-leave',
            },
          ]}
        />
      ) : null}

      {currentStep === 1 && (
        <FooterText>
          Already using Hypercare? <Link to="/login">Log in</Link>
        </FooterText>
      )}
    </SignupPageContainer>
  );
};

export default () => (
  <AuthContext.Consumer>{({isLoggedIn}) => <SignupCoordinator isLoggedIn={isLoggedIn} />}</AuthContext.Consumer>
);
