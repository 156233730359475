import React, {VFC} from 'react';
import {toast} from 'react-toastify';
import styled from 'styled-components';
import {ToastCloseBtn} from 'src/styles/styled-components/SharedModalStyles';
import {StyledToastWrapper} from 'src/styles/styled-components/NotesProfilePage';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

interface SuccessToastProps {
  title: string;
}

const ToastContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 4px;
  svg {
    margin-right: 1em;
    margin-top: 4px;
  }
`;

const ToastCentralContent = styled.div`
  line-height: 1.5em;
  margin-top: -4px;
  * {
    font-family: 'Nunito';
  }
  span {
    display: inline-block;
    padding-top: 4px;
  }
`;

/**
 * Generic toast customization component
 * @param title {string} title of the toast
 * @param subtitle {string} subtitle of the toast
 * @param ToastIcon Icon to be shown on the left side of the toast can be matererial icon or svg.
 */
export const DetailedToast = ({title, subtitle, ToastIcon}) => {
  return (
    <ToastContainer onClick={() => toast.dismiss()}>
      <ToastIcon />
      <ToastCentralContent>
        <strong>{title}</strong> <br />
        <span>{subtitle}</span>
      </ToastCentralContent>
      <ToastCloseBtn />
    </ToastContainer>
  );
};

export const SuccessToast = ({title}: SuccessToastProps) => {
  return (
    <StyledToastWrapper onClick={() => toast.dismiss()}>
      <CheckCircleIcon />
      {title}
    </StyledToastWrapper>
  );
};
