import {HCFilterSearchable} from 'src/@hc-ui/components/filters/variants/HCFilterSearchable';
import {getFullName} from '../../../../utils/user';
import Popup from 'reactjs-popup';
import React, {useState} from 'react';
import ReceipientIcon from 'src/svgs/ReceipientIcon';
import {NumberBadge, TableFilterContainer} from './TableFilters';
import ChevronUpIcon from 'src/svgs/ChevronUpIcon';
import ChevronDownIcon from 'src/svgs/ChevronDownIcon';

export const EventSenderFilter = ({auditLogFilters, userOptions}) => {
  const {selectedSenderIds, setSelectedSenderIds} = auditLogFilters;
  const [isOpen, setIsOpen] = useState(false);

  const SenderFilter = React.forwardRef(({...props}, ref) => (
    <TableFilterContainer ref={ref as any} {...props} hasSelection={selectedSenderIds.length > 0} isOpen={isOpen}>
      <ReceipientIcon /> Sender {selectedSenderIds.length > 0 && <NumberBadge>{selectedSenderIds.length}</NumberBadge>}
      {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
    </TableFilterContainer>
  ));

  return (
    <Popup
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      trigger={() => <SenderFilter />}
      position="bottom left"
      closeOnDocumentClick
    >
      {(close) => (
        <HCFilterSearchable
          title="Sender"
          onClose={close}
          options={[
            {
              title: 'Sender',
              options: userOptions,
            },
          ]}
          onToggle={(id: string) => {
            const found = selectedSenderIds.find((selectedId) => selectedId === id);
            if (found) {
              setSelectedSenderIds([...selectedSenderIds.filter((selectedId) => selectedId !== id)]);
            } else {
              setSelectedSenderIds([...selectedSenderIds, id]);
            }
          }}
          optionRender={(option) => {
            return (
              <div style={{display: 'flex', flexDirection: 'column'}}>
                <div style={{paddingBottom: 4}}>{option.label}</div>
                <div style={{fontWeight: 400, fontSize: 14, color: '#4A4A4A'}}>{option.value.role}</div>
              </div>
            );
          }}
          onSearch={(query, row) => {
            const contact = row.value;
            return (
              contact.role?.toLowerCase().includes(query.toLowerCase()) ||
              getFullName(contact).toLowerCase().includes(query.toLowerCase())
            );
          }}
          onClear={() => {
            setSelectedSenderIds([]);
            close();
          }}
          selectedIds={selectedSenderIds}
        />
      )}
    </Popup>
  );
};
