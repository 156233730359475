import React from 'react';
import {HCHeadingThree} from 'src/components/HypercareComponents';
import theme from 'src/styles/theme';
import styled from 'styled-components';

const CardContainer = styled.section`
  display: flex;
  flex-direction: column;
  padding: 12px 24px;
  background: ${theme.messageWhite};
  border: 1px solid ${theme.borderColor};
  border-radius: 4px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
`;

const CardHeader = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
`;

const CardContent = styled.div`
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
`;

type CardProps = {
  className?: string;
  title?: string;
  children: React.ReactNode;
};

export const Card = (props: CardProps) => (
  <CardContainer className={props.className}>
    {props.title && (
      <CardHeader>
        <HCHeadingThree color={theme.greyishBrown}>{props.title}</HCHeadingThree>
      </CardHeader>
    )}
    <CardContent>{props.children}</CardContent>
  </CardContainer>
);
