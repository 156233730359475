import React from 'react';
import store from 'src/redux';
import client from 'src/apollo';
import {actions} from 'src/redux/actions/messages';
import getParsedAuthInfo from 'src/utils/localStorageHandler';
import {getUnreadMessages} from 'src/utils/messengerHelper/getUnreadMessagesFromChat';
import updateChatReceipts from 'src/utils/messengerHelper/updateChatReceipts';
import GetChatQuery from 'src/gql/query/GetChatQuery';
import getMessages from 'src/utils/messengerHelper/getMessagesFromChat';
import {StatMessagePriority, UrgentMessagePriority} from 'src/constants/messageTypes';

interface UpdateReceipts {
  handleUpdateAllReadReceipts: () => void;
  handleReadReceiptsByMsgId: (messageId: string, priorityType: string) => void;
}

export const UpdateReceiptContext = React.createContext<UpdateReceipts>({
  handleUpdateAllReadReceipts: () => {},
  handleReadReceiptsByMsgId: () => {},
});

const UpdateReceiptRequestProvider = (props: {chatId: string; children: React.ReactElement}) => {
  const {chatId, children} = props;

  const isReadAll = React.useRef(false);

  const {user} = getParsedAuthInfo();

  const handleUpdateAllReadReceipts = async () => {
    const unreadMessageIds: string[] = [];
    const unreadPriorityMessageIds: string[] = [];

    const {chat} = client.readQuery({
      query: GetChatQuery,
      variables: {
        chatId: chatId,
      },
    });

    const messages = getMessages(chat);

    getUnreadMessages(messages, user).forEach((msg) => {
      unreadMessageIds.push(msg.id);
      if (msg.priority) unreadPriorityMessageIds.push(msg.id);
    });

    if (unreadMessageIds.length > 0) {
      isReadAll.current = true;
      await updateChatReceipts(chatId, unreadMessageIds, user.id);
    }

    if (unreadPriorityMessageIds.length > 0) {
      store.dispatch(
        actions.readPriorityMessages(
          unreadMessageIds.map((id) => Number(id)),
          chatId,
        ),
      );
    }
    isReadAll.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const handleReadReceiptsByMsgId = (messageId: string, priorityType: string) => {
    if (isReadAll.current) return;

    updateChatReceipts(chatId, [messageId], user.id);
    if ([StatMessagePriority, UrgentMessagePriority].includes(priorityType)) {
      store.dispatch(actions.readPriorityMessages([Number(messageId)], chatId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  return (
    <UpdateReceiptContext.Provider
      value={{
        handleUpdateAllReadReceipts,
        handleReadReceiptsByMsgId,
      }}
    >
      {children}
    </UpdateReceiptContext.Provider>
  );
};

export default UpdateReceiptRequestProvider;
