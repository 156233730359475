import * as React from 'react';
import styled from 'styled-components';
import {ReadReceipt} from 'src/types';
import parseDate from 'src/utils/parseDate';
import getPrettyReadableDate from 'src/utils/getPrettyReadableDate';
import {RouteComponentProps, withRouter} from 'react-router-dom';

const SeenByDiv = styled.div`
  margin-left: auto;
  margin-right: 0;
  align-items: center;
  font-size: 0.7em;
  padding-bottom: 5px;
  color: ${(props) => props.theme.warmGrey};
  cursor: pointer;
  width: fit-content;
`;

interface Props extends RouteComponentProps<{}> {
  receipts: ReadReceipt[];
}

class MessageReadReceipt extends React.PureComponent<Props> {
  public componentDidMount() {
    const messageBody = document.getElementById('scrollContainer');
    if (messageBody) messageBody.scrollTop = messageBody.scrollTop + 20;
  }

  public render() {
    const {receipts} = this.props;
    if (receipts.length === 1) {
      return (
        <SeenByDiv
          onClick={() => {
            this.props.history.push(`/messenger/user/${receipts[0].user.id}`);
          }}
        >{`Seen by ${receipts[0].user.firstname}`}</SeenByDiv>
      );
    }
    if (receipts.length > 1) {
      const originalMappedReceipt = receipts.map((receipt) => receipt.user.firstname);
      const isAtVisibleLimit = originalMappedReceipt.length > 3;

      const slicedMappedReceipt = isAtVisibleLimit ? originalMappedReceipt.slice(0, 3) : originalMappedReceipt;

      const lengthDifference = originalMappedReceipt.length - slicedMappedReceipt.length;

      return (
        <SeenByDiv>
          {`Seen by ${[
            slicedMappedReceipt.slice(0, isAtVisibleLimit ? slicedMappedReceipt.length : -1).join(', '),
            isAtVisibleLimit
              ? `${lengthDifference} other${lengthDifference === 1 ? '' : 's'}`
              : slicedMappedReceipt.slice(-1)[0],
          ].join(originalMappedReceipt.length < 2 ? '' : ' and ')}`}
        </SeenByDiv>
      );
    }
    return null;
  }

  private renderDate(receipt: ReadReceipt) {
    return getPrettyReadableDate(parseDate(receipt.timestamp));
  }
}

export default withRouter(MessageReadReceipt);
