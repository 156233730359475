import React from 'react';
import {ActiveEscalation} from 'src/types';
import styled from 'styled-components';
import moment from 'moment';
import EscalationProgressModal from 'src/components/EscalationProgressModal';
import stringifyNumberSuffix from 'src/utils/stringifyNumberSuffix';
import {getFullName} from 'src/utils/user';
import {CONSULT, IMAGE, ATTACHMENT} from 'src/constants/messageTypes';
import getParsedAuthInfo from 'src/utils/localStorageHandler';
import {
  EscalationLadderPreviewWrapper,
  OverrideEscalationLadderPreviewWrapper,
  EscalationLadderPreviewInfoHolder,
  EscalationType,
  EscalationBannerTitle,
  EscalationLadderSubTextHolder,
} from 'src/styles/styled-components/Escalation';
import parseDate from 'src/utils/parseDate';
import {getDetailedPrettyReadableDate} from 'src/utils/getPrettyReadableDate';

import ActiveEscalationPlaceholderBanner from './views/ActiveEscalationPlaceholderBanner';

const NextAttemptTime = styled.div`
  font-size: 32px;
  color: ${(props) => props.theme.blackFontColor};
  font-weight: bold;
`;

const EscalationLadderAttemptTimeHolder = styled.div`
  width: 128px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.greyishBrown};
  font-size: 12px;
`;

interface Props {
  isInChat: boolean;
  hasActivatedLevel: boolean;
  activeEscalation: ActiveEscalation;
  handleOnClick?: () => void;
}

const EscalationPreviewBanner = ({handleOnClick, isInChat, activeEscalation}: Props) => {
  const {escalationLadder, type, activatedLevel, state, endedAt, endedBy, message} = activeEscalation;

  const parsedAuthInfo = getParsedAuthInfo();
  const selfID = parsedAuthInfo ? parsedAuthInfo.user.id : null;

  const {level, currentAttempt, nextAttemptAt} = activatedLevel;

  const isEndedBySelf = !!endedBy && endedBy.id === selfID;

  const DynamicWrapperTag = isInChat ? OverrideEscalationLadderPreviewWrapper : EscalationLadderPreviewWrapper;

  const isEscalationActive = state === 'active';

  const messagePlaceholderText: string = React.useMemo(() => {
    if (!message || message.type) return '--';
    switch (message.type) {
      case IMAGE:
        return `Sent an image.`;
      case CONSULT:
        return `Sent a consult message.`;
      case ATTACHMENT:
        return `Sent an attachment.`;
      default:
        return `--`;
    }
  }, [message]);

  return (
    <DynamicWrapperTag onClick={handleOnClick} $isActive={isEscalationActive}>
      <EscalationLadderPreviewInfoHolder>
        <EscalationType>
          {type === 'department' ? 'DEPARTMENT' : 'INDIVIDUAL'} ESCALATION
          {type === 'department' && ` - ${escalationLadder.department.name}`}
        </EscalationType>

        <EscalationBannerTitle>
          {message && message.message ? message.message : messagePlaceholderText}
        </EscalationBannerTitle>

        <EscalationLadderSubTextHolder>
          {isEscalationActive && `Level ${level} - ${stringifyNumberSuffix(currentAttempt)} attempt`}
          {state === 'cancelled' &&
            (!!endedBy
              ? `${
                  isEndedBySelf ? 'You have' : `${getFullName(endedBy)} has`
                } ended the escalation at ${getDetailedPrettyReadableDate(parseDate(endedAt))}`
              : `Escalation has ended at ${getDetailedPrettyReadableDate(parseDate(endedAt))}`)}
          {state === 'failed' && 'escalation failed due to no acknowledgement'}
          {state === 'acknowledged' &&
            (!!endedBy
              ? `${
                  isEndedBySelf ? 'You have' : `${getFullName(endedBy)} has`
                } has acknowledged the escalation at ${getDetailedPrettyReadableDate(parseDate(endedAt))}`
              : `Escalation has acknowledged at ${getDetailedPrettyReadableDate(parseDate(endedAt))}`)}
        </EscalationLadderSubTextHolder>
      </EscalationLadderPreviewInfoHolder>

      {isEscalationActive && (
        <EscalationLadderAttemptTimeHolder>
          <div>next attempt at</div>
          <NextAttemptTime>{moment(nextAttemptAt).format('HH:mm')}</NextAttemptTime>
        </EscalationLadderAttemptTimeHolder>
      )}
    </DynamicWrapperTag>
  );
};

export default (props: Props) => {
  const [isModalOpen, setModalOpen] = React.useState(false);

  const handleOnClick = React.useCallback(() => {
    if (!props.isInChat) return;
    setModalOpen(true);
  }, [props.isInChat]);

  return (
    <React.Fragment>
      {props.hasActivatedLevel ? (
        <EscalationPreviewBanner {...props} handleOnClick={handleOnClick} />
      ) : (
        <ActiveEscalationPlaceholderBanner
          isInChat={props.isInChat}
          handleOnClick={handleOnClick}
          type={props.activeEscalation.type}
          isActive={props.activeEscalation.state === 'active'}
          departmentName={props.activeEscalation.escalationLadder?.department?.name}
          message={props.activeEscalation.message?.message}
        />
      )}
      {isModalOpen && (
        <EscalationProgressModal
          activeEscalationID={props.activeEscalation.id}
          isOpen={isModalOpen}
          closeModal={() => setModalOpen(false)}
        />
      )}
    </React.Fragment>
  );
};
