import * as R from 'ramda';
import {createAction, ActionType, getType} from 'typesafe-actions';
import {PriorityType, MessageTemplate} from 'src/types';

export const actions = {
  setInitialMessageTemplateFormValues: createAction(
    'messageTemplates/INITIAL_MESSAGE_TEMPLATE_FORM_VALUES',
    (resolve) => (payload: FieldValues) => resolve({payload}),
  ),
  setMessageTemplateFormValues: createAction(
    'messageTemplates/MESSAGE_TEMPLATE_FORM_VALUES',
    (resolve) => (values: FieldValues) => resolve({values}),
  ),
  resetFormValues: createAction('messageTemplates/RESET_MESSAGE_TEMPLATE_FORM_VALUES'),
  setMessageTemplatePriority: createAction(
    'messageTemplates/SET_MESSAGE_TEMPLATE_PRIORITY',
    (resolve) => (priorityIndex: number) => resolve({priorityIndex}),
  ),
  setSelectedTemplate: createAction(
    'messageTemplates/SET_SELECTED_MESSAGE_TEMPLATE',
    (resolve) => (messageTemplate: MessageTemplate) => resolve({messageTemplate}),
  ),
  setRequestTitle: createAction(
    'messageTemplates/SET_MESSAGE_TEMPLATE',
    (resolve) => (title: string) => resolve({title}),
  ),
  resetToInitalState: createAction('messageTemplates/RESET_TO_INITIAL_STATE'),
  setValidationErrors: createAction(
    'messageTemplates/VALIDATE_TEMPLATE_FIELD',
    (resolve) => (validationErrors) => resolve({validationErrors}),
  ),
};

export type MessageTemplateActions = ActionType<typeof actions>;

interface FieldValues {
  id: string;
  text?: string | string[];
  state?: any;
  dateTime?: any;
  selectedOption?: number;
  attachmentIds?: [];
  toggleState?: boolean;
}

export interface MessageTemplateState {
  messageTemplatePriority: number;
  initialFormValues: Array<FieldValues>;
  formValues: Array<FieldValues>;
  selectedTemplate: MessageTemplate;
  requestTitle: string | null;
  templateFormErrors: Array<{id: string; errorMsg: string} | null>;
}

export const initialState: MessageTemplateState = {
  formValues: [],
  requestTitle: '',
  initialFormValues: [],
  selectedTemplate: null,
  templateFormErrors: [],
  messageTemplatePriority: PriorityType.regular,
};

export default (state: MessageTemplateState = initialState, action: MessageTemplateActions) => {
  switch (action.type) {
    case getType(actions.setInitialMessageTemplateFormValues):
      let initialFormValueIndex = state.initialFormValues.filter((value) => value.id === action.payload.payload.id);
      if (initialFormValueIndex.length === 0)
        return {
          ...state,
          initialFormValues: R.clone([...state.initialFormValues, action.payload.payload]),
          formValues: R.clone([...state.formValues, action.payload.payload]),
        };
      else return {...state};

    case getType(actions.setMessageTemplateFormValues):
      const values = action.payload.values;
      let updatedState;
      let formValueIndex = state.formValues.findIndex((value) => value.id === values.id);
      if (formValueIndex >= 0) {
        let formValuesCopy = [...state.formValues];
        formValuesCopy[formValueIndex] = {...values};
        updatedState = formValuesCopy;
      } else {
        updatedState = [...state.formValues, values];
      }
      return {
        ...state,
        formValues: updatedState,
      };

    case getType(actions.setValidationErrors):
      return {
        ...state,
        templateFormErrors: action.payload.validationErrors,
      };

    case getType(actions.setSelectedTemplate):
      return {
        ...state,
        selectedTemplate: action.payload.messageTemplate,
      };

    case getType(actions.setRequestTitle):
      return {
        ...state,
        requestTitle: action.payload.title,
      };

    case getType(actions.resetFormValues):
      let initialFormValuesState = [...state.initialFormValues];
      return {
        ...state,
        requestTitle: state?.selectedTemplate?.requestTitle || '',
        formValues: initialFormValuesState,
      };

    case getType(actions.setMessageTemplatePriority):
      return {
        ...state,
        messageTemplatePriority: action.payload.priorityIndex,
      };

    case getType(actions.resetToInitalState):
      return initialState;

    default:
      return state;
  }
};
