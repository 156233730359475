import React from 'react';
import {
  ShiftHolderWrapper,
  ShiftRoleAndStartTimeHolder,
  ShiftDepartmentName,
  ShiftRoleName,
  ShiftRoleHolderTitleTime,
  ShiftDeptAndEndTimeHolder,
} from 'src/pages/SchedulingPage/action-calendar/mycalendar-section/MyCalendarGridSystem';
import moment from 'moment';
import isEndTimeMinutesBeforeStartTime from 'src/utils/scheduling/isEndTimeMinutesBeforeStartTime';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import 'src/pages/SchedulingPage/action-calendar/mycalendar-section/style.css';
import {FetchMyCalendarShiftsResponse, MyShift} from 'src/types';

const timeformat = 'HH:mm';

interface EventData {
  view: object;
  el: HTMLElement;
  event: {
    extendedProps: {
      startDate: string;
      endDate: string;
    };
  };
}

const MyCalendarActionCalendarLayout = ({
  endDateISOstring,
  shifts,
}: {
  endDateISOstring: string;
  shifts: Array<MyShift>;
}) => {
  const fullCalendarRef = React.useRef(null);

  /**
   * UseEffect used to change the full calendar month on change of month using
   * MonthSwitcher
   */
  React.useEffect(() => {
    fullCalendarRef.current.getApi().gotoDate(endDateISOstring);
  }, [endDateISOstring]);

  /**
   * Function to customize event view for FullCalendar
   * @param _shift event Object
   */
  function renderMyShift(_shift) {
    const {startDate, role, endDate} = _shift.event.extendedProps;
    const isCrossDay = isEndTimeMinutesBeforeStartTime(moment(startDate), moment(endDate));
    return (
      <ShiftHolderWrapper>
        <ShiftRoleAndStartTimeHolder>
          <ShiftRoleHolderTitleTime>{moment(startDate).format(timeformat)}</ShiftRoleHolderTitleTime>
          <ShiftRoleName>{role.name}</ShiftRoleName>
        </ShiftRoleAndStartTimeHolder>

        <ShiftDeptAndEndTimeHolder>
          <ShiftRoleHolderTitleTime>
            {moment(endDate).format(timeformat)}
            {isCrossDay && <sup>+1</sup>}
          </ShiftRoleHolderTitleTime>

          <ShiftDepartmentName>{role.department.name}</ShiftDepartmentName>
        </ShiftDeptAndEndTimeHolder>
      </ShiftHolderWrapper>
    );
  }

  function eventClassName(_shift) {
    const {startDate, endDate} = _shift.event.extendedProps,
      isCrossDay: boolean = isEndTimeMinutesBeforeStartTime(moment(startDate), moment(endDate)),
      isEndOfMonth: boolean = moment(startDate).isSame(moment(endDateISOstring).endOf('month'), 'day');

    const showTrailingArrow: boolean =
      (moment(startDate).weekday() === 6 && isCrossDay) || (isEndOfMonth && isCrossDay);
    const showLeadingArror: boolean = moment(endDate).weekday() === 0 && isCrossDay;

    if (showTrailingArrow) return 'trailingArrowClass';
    else if (showLeadingArror) return 'leadingArrowClass';
    else return '';
  }

  /**
   * Function that works similar to jquery closest method. Find the specified closest
   * parent element. Here it finds the parent td element of the events anchor element.
   * @param el Element to find parent of
   * @param tagName Find this tag in el parent node.
   * @returns Returns the parent HTMLElement which is similar to tagName
   */
  function findClosestElement(el: any, tagName: string): HTMLElement {
    tagName = tagName.toLowerCase();
    while (el && el.parentNode) {
      el = el.parentNode;
      if (el.tagName && el.tagName.toLowerCase() === tagName) {
        return el;
      }
    }

    return null;
  }

  /**
   * Function called after events are mounted on the UI. Events spanning multiple
   * segments calls this callback multiple times
   * @param eventData Object that contains event object and html element.
   */
  function afterEventMountHandler(eventData) {
    const {startDate, endDate} = eventData.event.extendedProps;
    const isCrossDay: boolean = isEndTimeMinutesBeforeStartTime(moment(startDate), moment(endDate));
    let leadingArrowElement: HTMLElement = eventData.el;
    let dateTdEl: HTMLElement = findClosestElement(leadingArrowElement, 'td');
    let eventDate: string = dateTdEl.getAttribute('data-date');
    const showLeadingArror: boolean =
      moment(endDate).weekday() === 0 && isCrossDay && moment(eventDate).weekday() === 0;

    if (showLeadingArror) {
      leadingArrowElement.classList.remove('trailingArrowClass');
      leadingArrowElement.classList.add('leadingArrowClass');
    }
  }

  return (
    <FullCalendar
      ref={fullCalendarRef}
      plugins={[dayGridPlugin]}
      headerToolbar={{
        start: '',
        center: '',
        end: '',
      }}
      initialView="dayGridMonth"
      events={shifts.map((s) => ({...s, id: String(s.id)}))}
      eventContent={renderMyShift}
      eventBackgroundColor={'#f2ece9'}
      eventBorderColor={'#f2ece9'}
      eventTextColor={'#000'}
      eventClassNames={eventClassName}
      forceEventDuration={true}
      showNonCurrentDates={false}
      eventDidMount={afterEventMountHandler}
    />
  );
};

export default MyCalendarActionCalendarLayout;
