import {createAction, ActionType, getType} from 'typesafe-actions';

export const actions = {
  toggleSidebar: createAction('sidebar/TOGGLE'),
  closeSidebar: createAction('sidebar/CLOSE'),
  toggleStatusSettings: createAction(
    'sidebar/statusSettings/TOGGLE',
    (resolve) => (visibility: boolean) => resolve({visibility}),
  ),
};

export type SidebarActions = ActionType<typeof actions>;

export const initialState: SidebarState = {
  isExpanded: false,
  showStatusSettings: false,
};

export interface SidebarState {
  isExpanded: boolean;
  showStatusSettings: boolean;
}

export default (state: SidebarState = initialState, action: SidebarActions) => {
  switch (action.type) {
    case getType(actions.toggleSidebar):
      return {
        ...state,
        isExpanded: !state.isExpanded,
      };
    case getType(actions.closeSidebar):
      return {
        ...state,
        isExpanded: false,
      };
    case getType(actions.toggleStatusSettings):
      const {visibility} = action.payload;
      return {
        ...state,
        showStatusSettings: visibility,
      };
    default:
      return state;
  }
};
