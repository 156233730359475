import client from 'src/apollo';
import {MESSAGES_DELIVERED_WO_CHATID_MUTATION} from 'src/gql/v2/mutation/MessagesDeliveredWOChatIdMutation';

export type FetchMessageDeliveredInput = {
  messageIds: string[];
};

export const ChatRepository = () => {
  const messageDelivered = async ({messageIds}: FetchMessageDeliveredInput) => {
    try {
      const result = await client.mutate({
        mutation: MESSAGES_DELIVERED_WO_CHATID_MUTATION,
        variables: {
          messageIds,
        },
        fetchPolicy: 'no-cache',
      });

      return Promise.resolve({error: null, result});
    } catch (err) {
      return Promise.resolve({error: err.message, result: null});
    }
  };

  return {
    messageDelivered,
  };
};
