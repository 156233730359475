import {Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Typography} from '@material-ui/core';
import React from 'react';
import {PrimaryButton} from 'src/styles/styled-components/StyledMaterialComponents';
import {LOGIN_PAGE_SSO_ERROR_MODAL_TITLE} from 'src/constants/login';

export const LoginErrorSSOModal = (props: {description: string}) => (
  <Dialog open={true}>
    <DialogTitle id="alert-dialog-title">
      <Typography variant="h6" color="secondary">
        {LOGIN_PAGE_SSO_ERROR_MODAL_TITLE}
      </Typography>
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">{props.description}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <PrimaryButton type="button" onClick={() => window.routerHistory.push('/login')}>
        Dismiss
      </PrimaryButton>
    </DialogActions>
  </Dialog>
);
