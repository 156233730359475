import * as React from 'react';
import styled from 'styled-components';

const SearchListIconContainer = styled.div`
  display: inherit;
  height: 200px;
  padding: 10px;
  vertical-align: middle;
  position: relative;
  text-align: center;
  top: 80px;
`;

const HeadingText = styled.div`
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 29px;
  color: #222222;
`;

const SubHeadingText = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #4a4a4a;
  margin-bottom: 20px;
  margin-top: 8px;
`;

const SwitchMessageTab = styled.div`
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: grid;
  align-items: center;
  text-align: center;
  letter-spacing: -0.24px;
  color: #00859a;
  cursor: pointer;
`;

const SearchNoRecordFound = (props) => {
  const {tabValue, handleChange} = props;
  const newValue = tabValue ? 0 : 1;

  return (
    <>
      <SearchListIconContainer>
        <HeadingText>No results found</HeadingText>
        <SubHeadingText>Please type another keyword and try again.</SubHeadingText>
        <SwitchMessageTab onClick={(e) => handleChange(e, newValue)}>
          {tabValue ? `Search users & groups` : `Search messages`}
        </SwitchMessageTab>
      </SearchListIconContainer>
    </>
  );
};

export default SearchNoRecordFound;
