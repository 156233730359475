import * as React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import styled from 'styled-components';

const StyledTableHead = styled(TableHead)`
  background-color: ${(props) => props.theme.lightGrey};
`;

const TableHeader = () => {
  return (
    <StyledTableHead>
      <TableRow>
        <TableCell align="left">Request details</TableCell>
        <TableCell align="left" valign="middle">
          Date requested
          <span className="material-icons">arrow_downward</span>
        </TableCell>
        <TableCell align="left">Requested by</TableCell>
        <TableCell align="left">Reason for request</TableCell>
        <TableCell align="left">Action</TableCell>
      </TableRow>
    </StyledTableHead>
  );
};

export default TableHeader;
