import * as React from 'react';
import store from 'src/redux';
import MessagesItem from 'src/pages/MessengerPage/messenger/messages-layout/message-item/MessagesItem';
import {Message, AuthPayload, SendMessageMutationVariables, PriorityType, MessageInChat} from 'src/types';
import {AuthContext} from 'src/auth/AuthProvider';
import {Mutation, MutationFunction} from 'react-apollo';
import parseDate from 'src/utils/parseDate';
import moment from 'moment';
import getPrettyReadableDate from 'src/utils/getPrettyReadableDate';
import ReadReceipt from 'src/pages/MessengerPage/messenger/messages-layout/message-item/ReadReceipt';
import {connect} from 'react-redux';
import {actions} from 'src/redux/actions/messages';
import SendMessageMutation from 'src/gql/mutation/SendMessageMutation';
import {tryFileUpload, trySendMessage} from 'src/utils/messengerHelper/sendMessage';
import {UpdateReceiptContext} from 'src/pages/MessengerPage/messenger/messages-layout/UpdateReceiptRequestProvider';
import MessageItemPresenter from 'src/pages/MessengerPage/messenger/messages-layout/message-item/MessageItemPresenter';

interface Props {
  chatId: string;
  isSingleChat: boolean;
  currentMessage: Message;
  searchMessage: MessageInChat | null;
  searchMessageData: MessageInChat[] | [];
  prevMessage: Message | null;
  nextMessage: Message | null;
  scrollToBottom: () => void;
  jumpToMessage: MessageInChat;
  sendMessage: MutationFunction<{}, SendMessageMutationVariables>;
  addOptimisticMessage: (message: Message, chatId: string) => void;
  removeOptimisticMessage: (messageId: string, chatId: string) => void;
  failOptimisticMessageMark: (messageId: string, chatId: string) => void;
  authInfo: AuthPayload;
  isSideSearchActive: boolean;
  searchText: string;
}

const MessagesItemManager = ({
  chatId,
  isSingleChat,
  currentMessage,
  searchMessage,
  searchMessageData,
  prevMessage,
  nextMessage,
  scrollToBottom,
  sendMessage,
  jumpToMessage,
  addOptimisticMessage,
  removeOptimisticMessage,
  failOptimisticMessageMark,
  authInfo,
  isSideSearchActive,
  searchText,
}: Props) => {
  const {id, type, isFailing, isOptimistic, priorityType} = currentMessage;

  const {user} = authInfo;
  const isSelf = currentMessage.sender.id === user.id;

  const {handleReadReceiptsByMsgId} = React.useContext(UpdateReceiptContext);

  const [subscribedMessage, setSubscribedMessage] = React.useState<Message>(null);
  const presenter = React.useRef<MessageItemPresenter>(null);

  const message = subscribedMessage ? subscribedMessage : currentMessage;

  const viewController = {
    update: (message) => setSubscribedMessage(message),
  };

  React.useEffect(() => {
    if (isOptimistic || isFailing) return;
    presenter.current = new MessageItemPresenter(viewController, chatId, id, isSelf);
    presenter.current.init();
    return () => {
      if (presenter.current) {
        presenter.current.destory();
        presenter.current = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * send message read as soon as the chat item is mounted
   */
  React.useEffect(() => {
    if (
      !isSelf &&
      window.document.hasFocus() &&
      !Boolean(message.readBy.find((recipients) => recipients.user.id === user.id))
    ) {
      handleReadReceiptsByMsgId(id, priorityType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useLayoutEffect(() => {
    if (isOptimistic) scrollToBottom();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const shouldRenderTimeStamp = (): boolean => {
    if (prevMessage) {
      const curMsgDate = parseDate(message.dateCreated);
      const prevMsgDate = parseDate(prevMessage.dateCreated);
      if (moment(curMsgDate).diff(moment(prevMsgDate), 'minutes') > 5) {
        return true;
      }
    }
    return false;
  };

  const renderReadReceipts = () => {
    const filteredReceipt = message.readBy.filter((receipt) => receipt.user.id !== authInfo.user.id);
    if (filteredReceipt.length > 0) return <ReadReceipt receipts={filteredReceipt} />;
    return null;
  };

  const getTimestampString = () => {
    const date = parseDate(message.dateCreated);
    const now = moment(new Date()).startOf('day');
    const givenDate = moment(date);
    const daysSince = now.diff(givenDate.startOf('day'), 'days');

    const dayString = daysSince === 0 ? 'Today' : getPrettyReadableDate(date);
    return (
      <span>
        <strong>{dayString}</strong>, {moment(date).format('h:mma')}
      </span>
    );
  };

  const isLastInConsecutiveBySender = () => {
    return !nextMessage || message.sender.id !== nextMessage.sender.id;
  };

  const reSendMessage = async () => {
    try {
      const {optimisticMessages} = store.getState().messages;
      let repliedToMessage = null;
      let targetMessage;
      if (optimisticMessages[chatId]) {
        targetMessage = optimisticMessages[chatId].find((msg) => msg.id === message.id);
        if (targetMessage.repliedTo) repliedToMessage = targetMessage.repliedTo;
      }
      removeOptimisticMessage(message.id, chatId);
      if (message.file) {
        let filesMessageObject = Object.assign(targetMessage, {
          uniqueId: targetMessage.id,
          files: [targetMessage.file],
        });
        await tryFileUpload({
          filesMessageObject,
          sendMessageMutation: sendMessage,
          retryRepliedTo: repliedToMessage,
        });
      } else {
        await trySendMessage(
          Object.assign(targetMessage, {
            uniqueId: targetMessage.id,
            messagePriority: PriorityType[targetMessage.priorityType],
            consultData: targetMessage.data ? JSON.stringify(targetMessage.data) : null,
          }),
          {
            sendMessage,
            addOptimisticMessage,
            failOptimisticMessageMark,
            removeOptimisticMessage,
          },
          repliedToMessage,
        );
      }
    } catch (e) {
      console.error(e);
      scrollToBottom();
    }
  };

  // TODO: the message prop has been updated from subscribedMessage ? subscribedMessage : message => currentMessage for template message feature because the template message update after responding to a template message was not working
  return (
    <MessagesItem
      chatId={chatId}
      isSingleChat={isSingleChat}
      message={currentMessage}
      prevMessage={prevMessage}
      isLastInConsecutiveBySender={isLastInConsecutiveBySender()}
      renderTimeStamp={shouldRenderTimeStamp()}
      isOptimistic={isOptimistic}
      isFailing={isFailing}
      getTimestampString={getTimestampString}
      type={type}
      isSelf={isSelf}
      reSendMessage={reSendMessage}
      renderReadReceipts={renderReadReceipts}
      searchMessage={searchMessage}
      searchMessageData={searchMessageData}
      jumpToMessage={jumpToMessage}
      isSideSearchActive={isSideSearchActive}
      searchText={searchText}
    />
  );
};

const RenderMessagesItemManager = React.memo(MessagesItemManager);

export default connect<{}, {}, any>(null, {
  addOptimisticMessage: actions.addOptimisticMessage,
  removeOptimisticMessage: actions.removeOptimisticMessage,
  failOptimisticMessageMark: actions.failOptimisticMessageMark,
})((props) => (
  <AuthContext.Consumer>
    {({authInfo}) => (
      <Mutation mutation={SendMessageMutation}>
        {(sendMessage) => <RenderMessagesItemManager {...props} authInfo={authInfo} sendMessage={sendMessage} />}
      </Mutation>
    )}
  </AuthContext.Consumer>
));
