import React, {useState} from 'react';
import styled from 'styled-components';
import PageCallbackModal from 'src/pages/LocatingPage/locating/profile-section/PageCallbackModal';
import {LocatingRole} from 'src/types';
import NewPagerIcon from 'src/svgs/NewPagerIcon';
import {StyledTooltipBlack} from 'src/components/StyledTooltip';
import {ROTATING_PAGER_TOOLTIP_TEXT} from 'src/constants/strings';
import theme from 'src/styles/theme';
const RotatingPagerContainer = styled.div`
  margin-left: auto;
  margin-right: 16px;
  font-size: 14px;

  padding-bottom: 0;
  &:hover {
    color: ${(props) => props.theme.primary};
  }
`;

const RotatingPagerItemContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin: auto;
  width: fit-content;
  justify-content: flex-end;
`;
interface Props {
  role: LocatingRole;
}

const RotatingPagerDropdownListener = ({role}: Props) => {
  const [showCallbackPageModal, setCallbackPageModal] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  function handleClickRolePagerNumber(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    e.stopPropagation();
    e.preventDefault();
    setCallbackPageModal(true);
  }

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <React.Fragment>
      <RotatingPagerContainer onClick={handleClickRolePagerNumber}>
        <StyledTooltipBlack title={ROTATING_PAGER_TOOLTIP_TEXT} placement="top">
          <RotatingPagerItemContainer onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <NewPagerIcon style={{height: 20, width: 20, color: isHovered ? theme.primary : '#4A4A4A'}} />{' '}
            <span>{role.pagerNumber}</span>
          </RotatingPagerItemContainer>
        </StyledTooltipBlack>
      </RotatingPagerContainer>

      {showCallbackPageModal && (
        <PageCallbackModal
          showModal={showCallbackPageModal}
          selectedUser={null}
          displayNumber={role.pagerNumber}
          selectedRole={role}
          handleCloseModal={() => setCallbackPageModal(false)}
        />
      )}
    </React.Fragment>
  );
};

export default RotatingPagerDropdownListener;
