import * as React from 'react';
import styled from 'styled-components';
import {usePopper} from 'react-popper';
import {
  OptionPopperBox,
  MessageBubblePopperArrow,
  MessageBubblePopperContainer,
} from 'src/pages/MessengerPage/messenger/messages-layout/message-item/message-option-popper/MessageOptionPopperStyles';
import ReplyIcon from 'src/svgs/ReplyIcon';
import store from 'src/redux';
import {actions} from 'src/redux/actions/drafts';
import {CHAT_INPUT} from 'src/constants/elementIds';
import {Message} from 'src/types';

const OptionIconHolder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2em;
`;

interface Props {
  chatId: string;
  message: Message;
}

const MessageReplyOptionPopper = ({chatId, message}: Props) => {
  const [isReplyMessageOptionHovered, setReplyMessageOptionHovered] = React.useState(false);
  const [referenceElement, setReferenceElement] = React.useState(null);
  const [popperElement, setPopperElement] = React.useState(null);
  const [arrowElement, setArrowElement] = React.useState(null);

  const {styles, attributes} = usePopper(referenceElement, popperElement, {
    modifiers: [
      {
        name: 'arrow',
        options: {
          element: arrowElement,
        },
      },
    ],
    placement: 'top',
  });

  const showReplyMessagePreview = () => {
    store.dispatch(actions.updateDraftRepliedTo(chatId, message));
    if (document.getElementById(CHAT_INPUT)) document.getElementById(CHAT_INPUT).focus();
  };

  return (
    <React.Fragment>
      <OptionPopperBox
        className={'MessageOptions'}
        onClick={showReplyMessagePreview}
        onMouseEnter={() => setReplyMessageOptionHovered(true)}
        onMouseLeave={() => setReplyMessageOptionHovered(false)}
        ref={setReferenceElement}
      >
        <OptionIconHolder>
          <ReplyIcon />
        </OptionIconHolder>
      </OptionPopperBox>

      {isReplyMessageOptionHovered && (
        <MessageBubblePopperContainer ref={setPopperElement} style={styles.popper} {...attributes.popper}>
          Reply
          <MessageBubblePopperArrow ref={setArrowElement} style={styles.arrow} className="arrow" />
        </MessageBubblePopperContainer>
      )}
    </React.Fragment>
  );
};

export default MessageReplyOptionPopper;
