import React from 'react';
import {Dialog, DialogContent, Divider, Grid} from '@material-ui/core';

import store from 'src/redux';
import AlertModal from './AlertModal';
import MessageTemplateFactory from './MessageTemplateFactory';
import {
  StyledDialogActions,
  PrimaryButton,
  SecondaryButton,
  TextButton,
} from 'src/styles/styled-components/StyledMaterialComponents';
import {actions as FiledropActions} from 'src/redux/actions/filedrop';
import {MaterialDialogHeader, Transition} from 'src/components/HypercareComponents';
import {actions as MessageTemplateActions} from 'src/redux/actions/messageTemplates';
import {TemplateViews, Message, TemplateMessageType, typedUseSelector} from 'src/types';
import validateTemplateMessageForm from 'src/utils/messengerHelper/validateTemplateMessageForm';

interface DetailResponseTemplateFormProps {
  message: Message;
  openDetailResponseTemplateForm: boolean;
  closeDetailResponseTemplateForm: (boolean) => void;
  detailResponseEditViewReviewConsultBtnHandler: () => void;
}

/**
 * Template form modal to show detail response form when the response type is detailed
 * @param openDetailResponseTemplateForm show/hide detail reponse template form
 * @param closeDetailResponseTemplateForm state hoook function to close reponse template form
 * @param responseMessageObject message template object to parse and generate form
 * @param detailResponseEditViewReviewConsultBtnHandler Review consult button handler callback fn.
 * @constructor
 */
const DetailResponseTemplateForm = ({
  message,
  openDetailResponseTemplateForm,
  closeDetailResponseTemplateForm,
  detailResponseEditViewReviewConsultBtnHandler,
}: DetailResponseTemplateFormProps) => {
  const {
    template: {requestTitle, formTitle},
  } = message;
  const [resetFormAlert, setResetFormAlert] = React.useState<boolean>(false),
    selectedTemplate = message?.template,
    {formValues} = typedUseSelector((state) => state.messageTemplate);
  return (
    <React.Fragment>
      <Dialog maxWidth={'sm'} fullWidth={true} open={openDetailResponseTemplateForm} TransitionComponent={Transition}>
        <MaterialDialogHeader
          dialogTitle={`Provide response for ${requestTitle && requestTitle !== '' ? requestTitle : formTitle}`}
          closeIconButtonClickHandler={() => {
            store.dispatch(MessageTemplateActions.resetToInitalState());
            store.dispatch(FiledropActions.clearDroppedFiles());
            closeDetailResponseTemplateForm(false);
          }}
        />
        <DialogContent>
          <Grid container spacing={2}>
            {new MessageTemplateFactory(
              message.template,
              TemplateViews.DetailResponseEditView,
            ).createUIWithJSONParser()}
          </Grid>
        </DialogContent>
        <Divider />
        <StyledDialogActions>
          <Grid container justify={'space-between'}>
            <Grid item>
              <TextButton onClick={() => setResetFormAlert(true)}>Reset form</TextButton>
            </Grid>
            <Grid item>
              <SecondaryButton
                style={{marginRight: 8}}
                onClick={() => {
                  store.dispatch(MessageTemplateActions.resetToInitalState());
                  store.dispatch(FiledropActions.clearDroppedFiles());
                  closeDetailResponseTemplateForm(false);
                }}
              >
                Cancel
              </SecondaryButton>
              <PrimaryButton
                onClick={() => {
                  let templateFormErrors = validateTemplateMessageForm(
                    selectedTemplate,
                    formValues,
                    TemplateMessageType.response,
                  );
                  if (templateFormErrors.length <= 0) detailResponseEditViewReviewConsultBtnHandler();
                }}
              >
                Review response
              </PrimaryButton>
            </Grid>
          </Grid>
        </StyledDialogActions>
      </Dialog>
      {resetFormAlert && (
        <AlertModal
          title={'Reset form?'}
          closeAlertModal={setResetFormAlert}
          isAlertModalVisible={resetFormAlert}
          alertModalButtons={[
            {
              buttonLabel: 'Back to editing',
              type: 'secondary',
              onClickHandler: () => setResetFormAlert(false),
            },
            {
              buttonLabel: 'Reset anyway',
              type: 'primary',
              onClickHandler: () => {
                setResetFormAlert(false);
                store.dispatch(FiledropActions.clearDroppedFiles());
                store.dispatch(MessageTemplateActions.resetFormValues());
              },
            },
          ]}
          subtitle={'This will clear all the information that you have entered so far. This action cannot be undone.'}
        />
      )}
    </React.Fragment>
  );
};

export default React.memo(DetailResponseTemplateForm);
