import React from 'react';
import {Box, IconButton, Icon, Typography, makeStyles, Theme} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 32,
  },
  backButton: {
    color: 'black',
    backgroundColor: '#F2F2F2',
    width: 40,
    height: 40,
  },
  title: {
    fontFamily: "'Nunito Sans', sans-serif",
    marginBottom: 16,
    marginTop: 16,
    fontSize: 32,
    fontWeight: 700,
    color: '#222222',
  },
  description: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontWeight: 400,
    fontSize: 16,
    color: '#4A4A4A',
  },
}));

interface IStepHeaderProps {
  title: string;
  isDisabled: boolean;
  description?: string | JSX.Element;
  onBackButtonClick?: () => void;
}

const StepHeader: React.FC<IStepHeaderProps> = ({title, description, onBackButtonClick, isDisabled}) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      {isDisabled ? (
        <IconButton className={classes.backButton} disabled={true} />
      ) : (
        <IconButton className={classes.backButton} onClick={onBackButtonClick}>
          <Icon>arrow_back</Icon>
        </IconButton>
      )}
      <Typography variant="h5" className={classes.title}>
        {title}
      </Typography>
      <Typography className={classes.description}>{description}</Typography>
    </Box>
  );
};

export default StepHeader;
