import React from 'react';
import styled from 'styled-components';
import Fab from '@material-ui/core/Fab';
import Chevron from 'src/svgs/Chevron';
import Badge from '@material-ui/core/Badge';
import Fade from '@material-ui/core/Fade';
import throttle from 'lodash.throttle';
import {ApolloQueryResult} from 'apollo-client';
import {AuthContext} from 'src/auth/AuthProvider';
import {User, Message, MessageInChat} from 'src/types';
import {StyledIsearchFab, StyledJump} from 'src/styles/styled-components/StyledMaterialComponents';

const StyledBadge = styled(Badge)`
  z-index: 1;
  position: absolute !important;
  bottom: 12em !important;
  transform: translateX(-50%) !important;
  left: 50% !important;
  .MuiBadge-badge {
    transform: translateX(4px);
    background-color: ${(props) => props.theme.main};
    ${(props) => (props.invisible ? `display: none` : undefined)}
  }
`;

const StyledFab = styled(Fab)`
  background-color: ${(props) => props.theme.lightGrey} !important;
`;

const StyledChevron = styled(Chevron)`
  margin-right: 12px !important;
`;

interface Props {
  messages: Message[];
  scrollToBottom: (bottomSpace?: number) => void;
  user: User;
  jumpToMessage: any;
  isSearch: boolean;
  searchMessageData: MessageInChat[] | [];
  setIsLoadingMessage: (isLoading: boolean) => void;
  refetch: (variables?: Record<string, any>) => Promise<ApolloQueryResult<any>>;
  lastUnreadMessage: any;
}

const MessagesBodyFabViewController = ({
  messages,
  scrollToBottom,
  user,
  jumpToMessage,
  isSearch,
  searchMessageData,
  setIsLoadingMessage,
  refetch,
  lastUnreadMessage,
}: Props) => {
  const [showFab, setShowFab] = React.useState<boolean>(false);
  const [unreadSinceLastScroll, setUnreadSinceLastScroll] = React.useState<number>(0);

  const isMounted = React.useRef(false);
  const newestMessage = React.useRef(messages[0]);

  React.useEffect(() => {
    isMounted.current = true;
    return () => (isMounted.current = false);
  }, []);

  React.useLayoutEffect(() => {
    // setup scroll event listener
    const scrollContainer = document.getElementById('scrollContainer');
    const handleScroll = () => {
      if (!isMounted.current) return;

      // when at bottom scrollYBottom will be 0
      let scrollYBottom = scrollContainer.scrollHeight - scrollContainer.scrollTop - scrollContainer.clientHeight;
      if (scrollYBottom > 700) {
        setShowFab(true);
      } else {
        setUnreadSinceLastScroll(0);
        setShowFab(false);
      }
    };
    scrollContainer.addEventListener('scroll', throttle(handleScroll, 1000));
    return () => scrollContainer.removeEventListener('scroll', throttle(handleScroll, 1000));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useLayoutEffect(() => {
    if (
      showFab &&
      newestMessage.current &&
      messages[0].id !== newestMessage.current.id &&
      messages[0].sender.id !== user.id &&
      lastUnreadMessage
    ) {
      setUnreadSinceLastScroll((prevCount) => prevCount + 1);
    }
    newestMessage.current = messages[0];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages.length]);

  const handleScrollToBottomClick = async () => {
    if (jumpToMessage) {
      setIsLoadingMessage(true);
      await refetch();
      scrollToBottom(20);
      setIsLoadingMessage(false);
    } else {
      scrollToBottom();
    }
    setShowFab(false);
    setUnreadSinceLastScroll(0);
  };

  const handleSearchScrollToBottomClick = async () => {
    setIsLoadingMessage(true);
    await refetch();
    scrollToBottom(20);
    setShowFab(false);
    setUnreadSinceLastScroll(0);
    setIsLoadingMessage(false);
  };

  return (
    <Fade in={showFab}>
      <StyledBadge badgeContent={unreadSinceLastScroll} invisible={!(unreadSinceLastScroll > 0)} color="secondary">
        {isSearch && searchMessageData.length > 0 ? (
          <>
            <StyledIsearchFab
              color="primary"
              aria-label="scroll down"
              disableTouchRipple
              size="medium"
              onClick={handleSearchScrollToBottomClick}
            >
              <StyledJump> Jump to latest messages </StyledJump> <StyledChevron />
            </StyledIsearchFab>
          </>
        ) : (
          <StyledFab
            color="primary"
            aria-label="scroll down"
            disableTouchRipple
            size="medium"
            onClick={handleScrollToBottomClick}
          >
            <Chevron />
          </StyledFab>
        )}
      </StyledBadge>
    </Fade>
  );
};

export default (props) => (
  <AuthContext.Consumer>
    {({authInfo}) => <MessagesBodyFabViewController {...props} user={authInfo.user} />}
  </AuthContext.Consumer>
);
