import * as React from 'react';
import {Assignee, Role, ScheduleLayoutRouterParams} from 'src/types';
import {
  RoleHolderWrapper,
  RoleHolderDetail,
  RoleHolderTitle,
  RoleHolderTitleName,
  RoleHolderTitleTime,
  SwapShiftIconHolder,
  RoleIconHolder,
  MarketplaceIconHolder,
} from 'src/pages/SchedulingPage/scheduling/ActionCalendarGridSystem';
import hexToRgba from 'src/utils/scheduling/hexToRgba';
import {typedUseSelector} from 'src/redux';
import isEndTimeMinutesBeforeStartTime from 'src/utils/scheduling/isEndTimeMinutesBeforeStartTime';
import SwapShiftIcon from 'src/svgs/SwapShiftIcon';
import {useParams} from 'react-router-dom';
import GiveAwayShiftIcon from 'src/svgs/GiveAwayShiftIcon';
import {SWAPSCHEDULINGVIEW, GIVEAWAYSHIFTVIEW} from 'src/constants/scheduler';

interface Props {
  assignee: Assignee;
  role: Role;
  onCellClick: (event?: React.MouseEvent<HTMLElement>) => any;
  userToColorCodes: {
    [userId: string]: string;
  };
  showOfferTypes: boolean;
  isSwap: boolean;
  isSelf: boolean;
  timeformat: 'HH:mm' | 'MMM DD, YYYY / HH:mm';
}

const GridCellAssignee = ({
  assignee,
  role,
  userToColorCodes,
  onCellClick,
  isSwap,
  isSelf,
  showOfferTypes,
  timeformat,
}: Props) => {
  const selectedSelfShift = isSwap ? typedUseSelector((state) => state.tradeShift.selfShift) : null;
  const selectedOtherShift = isSwap ? typedUseSelector((state) => state.tradeShift.otherShift) : null;
  const params = useParams() as ScheduleLayoutRouterParams;
  const {shiftId} = assignee;
  const assignedColor = userToColorCodes[assignee.userId];
  const assignedColorBackground = hexToRgba(userToColorCodes[assignee.userId], 0.1);

  const isSelecting =
    (selectedSelfShift && selectedSelfShift.assignee.shiftId === assignee.shiftId) ||
    (selectedOtherShift && selectedOtherShift.assignee.shiftId === assignee.shiftId);

  const shouldGreyOut = !isSelecting && ((isSelf && selectedSelfShift) || (!isSelf && selectedOtherShift));
  const isCrossDay = isEndTimeMinutesBeforeStartTime(assignee.startTime, assignee.endTime);

  return (
    <RoleHolderWrapper
      shouldGreyOut={params?.actionType === SWAPSCHEDULINGVIEW && !!shouldGreyOut}
      isSelecting={isSelecting}
      assignedColorBackground={assignedColorBackground}
      assignedColor={assignedColor}
      className={`${shiftId}`}
      onClick={onCellClick}
      title={assignee.userFullName}
    >
      <RoleHolderTitle>
        <RoleHolderTitleName>{isSelf ? 'ME' : assignee.userFullName}</RoleHolderTitleName>

        <RoleHolderTitleTime>
          {`${assignee.startTime.format(timeformat)}-${assignee.endTime.format('HH:mm')}`}
          {isCrossDay && <sup>+1</sup>}
        </RoleHolderTitleTime>
      </RoleHolderTitle>

      <RoleIconHolder>
        <RoleHolderDetail>{role.roleName}</RoleHolderDetail>
        {showOfferTypes && assignee.offerTypes && (
          <MarketplaceIconHolder>
            {assignee.offerTypes.includes(SWAPSCHEDULINGVIEW) && <SwapShiftIcon />}
            {assignee.offerTypes.includes(GIVEAWAYSHIFTVIEW) && <GiveAwayShiftIcon />}
          </MarketplaceIconHolder>
        )}
        {isSelecting && params?.actionType === SWAPSCHEDULINGVIEW && (
          <SwapShiftIconHolder>
            <SwapShiftIcon />
          </SwapShiftIconHolder>
        )}
      </RoleIconHolder>
    </RoleHolderWrapper>
  );
};

export default GridCellAssignee;
