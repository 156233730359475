import {Box, LinearProgress, makeStyles, Theme} from '@material-ui/core';
import React from 'react';
import {SignupProgressCopy} from './signup-progress.component-copy';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flex: '1',
    position: 'relative',
    width: '600px',
  },
  inner: (stylingProps: PropsStyle) => ({
    maxHeight: '700px',
    overflowY: stylingProps.showOverflow,
    backgroundColor: '#FFFFFF',
    borderRadius: '8px',
    padding: '44px 32px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    filter: 'drop-shadow(0px 3px 5px rgba(9, 30, 66, 0.2)) drop-shadow(0px 0px 1px rgba(9, 30, 66, 0.31))',
    marginBottom: '50px',

    [theme.breakpoints.down('xs')]: {
      padding: 16,
    },
  }),
  dotContainer: {
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: 34,

    '& > *': {
      borderRadius: '8px 8px 0px 0px',
    },
  },
}));

interface PropsStyle {
  showOverflow: 'scroll' | 'initial';
}

export const StepContainerCopy = (props) => {
  const {children, progress, currentStep, showOverflow} = props;

  const stylingProps: PropsStyle = {
    showOverflow: showOverflow ? 'scroll' : 'initial',
  };

  const classes = useStyles(stylingProps);
  return (
    <Box className={classes.root}>
      <Box className={classes.inner}>{children}</Box>
      {progress ? (
        <Box className={classes.dotContainer}>
          <LinearProgress color="secondary" />
        </Box>
      ) : (
        <Box className={classes.dotContainer}>
          <SignupProgressCopy value={(currentStep * 600) / 5} />
        </Box>
      )}
    </Box>
  );
};
