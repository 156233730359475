import React from 'react';
import client from 'src/apollo';
import {UPDATE_ADDRESS_PREFERENCE} from 'src/gql/v2/mutation/UpdateAddressPreferenceMutation';
import {
  GetSelfOrganizationsResult,
  UserAddress,
  UpdateUserProfileV2Result,
  VerifyAddressPayload,
  VerifyAddressValidationPayload,
} from 'src/types';
import UpdateAddressAccessMutation, {ModifyAddressAccessResult} from 'src/gql/mutation/UpdateAddressAccessMutation';
import RemoveAddressMutation from 'src/gql/mutation/RemoveAddressMutation';
import GetSelfOrganizations from 'src/gql/query/GetUserOrganizations';
import {ApolloQueryResult} from 'apollo-client';
import {UPDATE_PROFILE_FIELDS} from 'src/gql/v2/mutation/UpdateProfileFieldsMutation';
import {useQuery} from 'react-apollo';
import {
  FETCH_SELF_ORG_DIR_SYNC_MEMBER,
  FETCH_SELF_ORG_MEMBER,
  FetchSelfOrgMemberResult,
} from 'src/gql/v2/query/FetchSelfOrgMember';
import {IsFeatureFlagEnabled} from 'src/utils/FeatureFlagManager';
import {FeatureFlagResult} from 'src/utils/FeatureFlags';
import {getOrganizationalUnitObject} from 'src/utils/organizationHelper/getOrganizationalUnitObject';
import {OrganizationalUnitInputType, UserIdInputType} from 'src/gql/v2/types/input';
import {
  FETCH_USER_ADDRESSES_QUERY,
  FETCH_USER_PROFILE_QUERY,
  FetchUserAddressQueryResult,
  FetchUserProfileQueryResult,
} from 'src/gql/v2/query/FetchUserProfileQuery';
import {
  REQUEST_ADDRESS_VERIFICATION,
  RequestAddressVerificationResponse,
} from 'src/gql/mutation/RequestAddressVerificationPrivate';
import {
  VERIFY_ADDRESS_VALIDATION_CODE,
  VerifyAddressValidationCodeResponse,
} from 'src/gql/mutation/VerifyAddressMutation';
import {
  FETCH_ORG_ALLOW_INVITE_STATUS,
  FetchOrganizationAllowInviteStatusResponse,
} from 'src/gql/v2/query/FetchOrganizationAllowInviteStatus';
import {
  FETCH_ORGANIZATION_ADDRESS_LABEL_OPTIONS,
  FetchOrganizationAddressLabelOptionsResponse,
} from 'src/gql/v2/query/FetchOrganizationAddressLabelOptions';
import {
  CHANGE_ADDRESS_LABEL_MUTATION,
  ChangeAddressLabelMutationResponse,
} from 'src/gql/mutation/ChangeAddressLabelMutation';
import {ProfilePageAboutSection, UpdateAddressLabelPayload} from 'src/pages/LocatingPage/types';

type FetchOrganizationLabelOptionError = null | 'networkError' | 'anotherError(complete me)';
type FetchSelfProfileError = null | 'networkError' | 'anotherError(complete me)';
type FetchUserProfileError = null | 'networkError' | 'anotherError(complete me)';
type UpdateUserAddressLabelError = null | 'networkError' | 'anotherError(complete me)';
type AddAddressError = null | 'networkError' | 'anotherError(complete me)';
type RequestAddressVerificationCode = null | 'networkError' | 'anotherError(complete me)';
type VerifyAddressVerificationCode = null | 'networkError' | 'anotherError(complete me)';
type FetchOrganizationAllowInviteStatus = null | 'networkError' | 'anotherError(complete me)';

const LocatingPageRepository = () => {
  const ldapDirectorySyncFlag = IsFeatureFlagEnabled(FeatureFlagResult.ldapDirectorySync);
  const profileAddressLabelFlag = IsFeatureFlagEnabled(FeatureFlagResult.profileAddressLabel);

  const useFetchOrganizationAllowInviteStatus = () => {
    const result = useQuery<FetchOrganizationAllowInviteStatusResponse, OrganizationalUnitInputType>(
      FETCH_ORG_ALLOW_INVITE_STATUS,
      {
        variables: {
          organizationalUnit: getOrganizationalUnitObject(),
        },
        fetchPolicy: 'no-cache',
      },
    );

    let error: FetchOrganizationAllowInviteStatus = null;
    if (result?.error?.networkError) {
      error = 'networkError';
    }

    return {
      ...result,
      error,
    };
  };

  const useFetchSelfProfile = () => {
    const result = useQuery<
      FetchSelfOrgMemberResult,
      OrganizationalUnitInputType & {isProfileAddressLabelFlagEnabled: boolean}
    >(ldapDirectorySyncFlag ? FETCH_SELF_ORG_DIR_SYNC_MEMBER : FETCH_SELF_ORG_MEMBER, {
      variables: {
        organizationalUnit: getOrganizationalUnitObject(),
        isProfileAddressLabelFlagEnabled: profileAddressLabelFlag,
      },
      fetchPolicy: 'no-cache',
    });

    let error: FetchSelfProfileError = null;
    if (result?.error?.networkError) {
      error = 'networkError';
    }

    return {
      ...result,
      error,
    };
  };

  const useFetchUserProfile = (userId: string) => {
    const result = useQuery<
      FetchUserProfileQueryResult,
      OrganizationalUnitInputType & UserIdInputType & {isProfileAddressLabelFlagEnabled: boolean}
    >(FETCH_USER_PROFILE_QUERY, {
      variables: {
        userId,
        organizationalUnit: getOrganizationalUnitObject(),
        isProfileAddressLabelFlagEnabled: profileAddressLabelFlag,
      },
      fetchPolicy: 'no-cache',
    });

    let error: FetchUserProfileError = null;
    if (result?.error?.networkError) {
      error = 'networkError';
    }

    return {
      ...result,
      error,
    };
  };

  const useFetchUserAddresses = (userId: string) => {
    const result = useQuery<FetchUserAddressQueryResult, OrganizationalUnitInputType & UserIdInputType>(
      FETCH_USER_ADDRESSES_QUERY,
      {
        variables: {
          userId,
          organizationalUnit: getOrganizationalUnitObject(),
        },
        fetchPolicy: 'no-cache',
      },
    );

    let error: FetchUserProfileError = null;
    if (result?.error?.networkError) {
      error = 'networkError';
    }

    return {
      ...result,
      error,
    };
  };

  const updateAddressLabel = async ({address, type, updatedLabel}: UpdateAddressLabelPayload) => {
    try {
      const result = await client.mutate<ChangeAddressLabelMutationResponse>({
        mutation: CHANGE_ADDRESS_LABEL_MUTATION,
        variables: {
          address,
          addressType: type,
          addressLabel: updatedLabel,
        },
      });

      return Promise.resolve({error: null, result});
    } catch (err) {
      return Promise.resolve({error: err.message, result: null});
    }
  };

  const useAddAddress = async () => {};
  const useRequestAddressVerificationCode = async ({address, type}: VerifyAddressPayload) => {
    const result = useQuery<RequestAddressVerificationResponse, VerifyAddressPayload>(REQUEST_ADDRESS_VERIFICATION, {
      variables: {
        address,
        type,
      },
      fetchPolicy: 'no-cache',
    });

    let error: RequestAddressVerificationCode = null;
    if (result?.error?.networkError) {
      error = 'networkError';
    }

    return {
      ...result,
      error,
    };
  };
  const useVerifyAddressValidationCode = async ({address, type, token}: VerifyAddressValidationPayload) => {
    const result = useQuery<VerifyAddressValidationCodeResponse, VerifyAddressValidationPayload>(
      VERIFY_ADDRESS_VALIDATION_CODE,
      {
        variables: {
          address,
          type,
          token,
        },
        fetchPolicy: 'no-cache',
      },
    );

    let error: VerifyAddressVerificationCode = null;
    if (result?.error?.networkError) {
      error = 'networkError';
    }

    return {
      ...result,
      error,
    };
  };

  const useFetchOrganizationLabelOptions = () => {
    const result = useQuery<FetchOrganizationAddressLabelOptionsResponse, OrganizationalUnitInputType>(
      FETCH_ORGANIZATION_ADDRESS_LABEL_OPTIONS,
      {
        variables: {
          organizationalUnit: getOrganizationalUnitObject(),
        },
      },
    );
    let error: FetchOrganizationLabelOptionError = null;
    if (result?.error?.networkError) {
      error = 'networkError';
    }

    return {
      ...result,
      error,
    };
  };

  const updateAddressVisibility = async (address: string, type: string, updatedAccess: string) => {
    try {
      const result = await client.mutate<ModifyAddressAccessResult>({
        mutation: UpdateAddressAccessMutation,
        variables: {
          address,
          addressType: type,
          accessType: updatedAccess,
        },
      });

      return Promise.resolve({error: null, result});
    } catch (err) {
      return Promise.resolve({error: err.message, result: null});
    }
  };

  const updateProfilePreference = async (addresses: string[]) => {
    try {
      const result = await client.mutate({
        mutation: UPDATE_ADDRESS_PREFERENCE,
        variables: {
          address: addresses,
        },
        errorPolicy: 'all',
      });
      return Promise.resolve({error: null, result});
    } catch (err) {
      return Promise.resolve({error: err.message, result: null});
    }
  };

  const updateProfileFormFields = async (values: ProfilePageAboutSection) => {
    const {firstname: firstName, lastname: lastName, role} = values;
    try {
      const result = await client.mutate<UpdateUserProfileV2Result>({
        mutation: UPDATE_PROFILE_FIELDS,
        variables: {
          details: {
            firstName,
            lastName,
            role,
          },
        },
      });
      return Promise.resolve({error: null, result});
    } catch (err) {
      return Promise.resolve({error: err.message, result: null});
    }
  };

  const deleteAddress = async (currentAddress: UserAddress) => {
    try {
      const result = await client.mutate({
        mutation: RemoveAddressMutation,
        variables: {
          address: currentAddress.address,
          type: currentAddress.type,
        },
      });
      return Promise.resolve({error: null, result});
    } catch (err) {
      return Promise.resolve({error: err.message, result: null});
    }
  };

  const fetchSelfOrganizations = async () => {
    try {
      const result: ApolloQueryResult<GetSelfOrganizationsResult> = await client.query({
        query: GetSelfOrganizations,
        fetchPolicy: 'no-cache',
      });
      return Promise.resolve({error: null, result: result.data});
    } catch (err) {
      return Promise.resolve({error: err.message, result: null});
    }
  };

  return {
    updateProfilePreference,
    updateProfileFormFields,
    updateAddressVisibility,
    deleteAddress,
    fetchSelfOrganizations,
    useRequestAddressVerificationCode,
    updateAddressLabel,
    useVerifyAddressValidationCode,
    useAddAddress,
    useFetchOrganizationLabelOptions,
    useFetchSelfProfile,
    useFetchUserProfile,
    useFetchOrganizationAllowInviteStatus,
    useFetchUserAddresses,
  };
};

export default LocatingPageRepository;
