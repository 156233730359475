import React, {useState} from 'react';
import {ACCESS, VISIBILITY} from 'src/constants/hiddenNotes';
import {MenuItem, Select, makeStyles} from '@material-ui/core';
import {HCLabelOne} from 'src/components/HypercareComponents';
import Chevron from 'src/svgs/Chevron';
import {StyledInputBase} from 'src/components/StepFormPartials';
import HypercareSelfProfileViewModel from 'src/pages/LocatingPage/viewModels/HypercareSelfProfileViewModel';
import {UserAddress, UserAddressAccess} from 'src/types';
import theme from 'src/styles/theme';
import AnalyticsManager, {EVENTS} from 'src/analytics/AnalyticsManager';

const selectUseStyles = makeStyles(() => {
  return {
    root: {
      border: 'none',
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
      alignSelf: 'stretch',
      padding: 0,
      width: '180px',
      '&:hover': {
        backgroundColor: theme.ghostWhite,
      },
    },

    select: {
      '&&': {
        paddingRight: 0,
      },
      '& ul': {
        borderRadius: '4px',
        backgroundColor: theme.messageWhite,
        display: 'flex',
        padding: '8px',
        flexDirection: 'column',
        alignItems: 'flexStart',
      },
      '& li': {
        padding: '10px 8px',
      },
    },
    selected: {
      borderRadius: '4px',
      backgroundColor: theme.ghostWhite,
    },
  };
});

interface Props {
  isInputDisabled: boolean;
  index: number;
  setFieldValue: (filedId: string, fieldValue: string) => void;
  currentAddress: UserAddress;
  isEditProfileFormLoading: boolean;
  setEditProfileFormLoading: (flag: boolean) => void;
  setCurrentAddresses?: React.Dispatch<React.SetStateAction<UserAddress[]>>;
  addresses: UserAddress[];
}

const ChangeAccessActionInput = ({
  isInputDisabled,
  index,
  currentAddress,
  addresses,
  isEditProfileFormLoading,
  setEditProfileFormLoading,
  setCurrentAddresses,
}: Props) => {
  const {access, id} = currentAddress;
  const [currentAddressAccess, setCurrentAddressAccess] = useState<string>(access);
  const selectClasses = selectUseStyles();
  const classProp = {classes: {select: selectClasses.select}};
  const selectProps = !currentAddressAccess ? {IconComponent: () => null, ...classProp} : {};
  const {handleAddressVisibilityChange} = HypercareSelfProfileViewModel();

  const handleChangeAccess = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    setEditProfileFormLoading(true);
    const updatedAccess = e.target.value as UserAddressAccess;
    AnalyticsManager.applyAnalytics({
      eventName:
        updatedAccess === ACCESS.PRIVATE
          ? EVENTS.editProfileVisibilityPrivateOptionPressed
          : EVENTS.editProfileVisibilityPublicOptionPressed,
    });
    setCurrentAddressAccess(updatedAccess);
    handleAddressVisibilityChange(currentAddress, updatedAccess);

    const newAddressArray = addresses.map((address) =>
      address.id === currentAddress.id ? {...address, access: updatedAccess} : address,
    );
    setCurrentAddresses(newAddressArray);
  };

  const handleSelectOpen = () => {
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.editProfileChangeVisibilityOptionPressed,
    });
  };

  return (
    <Select
      onClick={handleSelectOpen}
      id={id}
      inputProps={{
        id: `addresses.${index}.access`,
        name: `addresses.${index}.access`,
      }}
      disabled={isInputDisabled || isEditProfileFormLoading}
      variant="outlined"
      classes={{
        root: selectClasses.root,
      }}
      value={currentAddressAccess}
      onChange={handleChangeAccess}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        getContentAnchorEl: null,
        disablePortal: true,
        classes: {paper: selectClasses.select},
      }}
      IconComponent={!isInputDisabled ? Chevron : () => null}
      input={<StyledInputBase />}
      {...selectProps}
    >
      <MenuItem value={'public'} classes={{selected: selectClasses.selected}}>
        <HCLabelOne>{VISIBILITY.PUBLIC}</HCLabelOne>
      </MenuItem>
      <MenuItem value={'private'} classes={{selected: selectClasses.selected}}>
        <HCLabelOne>{VISIBILITY.PRIVATE}</HCLabelOne>
      </MenuItem>
    </Select>
  );
};

export default ChangeAccessActionInput;
