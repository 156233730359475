import * as React from 'react';
import styled from 'styled-components';
import {SYSTEM} from 'src/constants/messageTypes';
import MessageBubbleWrapper from 'src/pages/MessengerPage/messenger/messages-layout/message-item/MessageBubbleWrapper';
import {Message, MessageInChat, MessageType} from 'src/types';
import {media} from 'src/styles/utils';

const SelfMessageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row-reverse;
  white-space: pre-wrap;
  flex-wrap: wrap;
`;

const SystemMessageContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const OtherMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
`;

const OtherMessageContainerChildWrapper = styled.div`
  display: flex;
  max-width: 55%;
  align-items: center;

  ${media.widescreen`
    max-width: 85%;
  `}
`;

const MessageContainer = styled.div`
  margin: 2px;
  &:hover .MessageOptions {
    opacity: 1 !important;
  }
`;

const NameTag = styled.div`
  font-size: 10px;
  margin-bottom: 2px;
  color: #808080;
`;

const Timestamp = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 0.7em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  color: #808080;
`;

const ResendMessage = styled.div`
  text-decoration: underline;
  text-align: right;
  color: ${(props) => props.theme.redPink};
  &::first-letter {
    text-decoration: none;
  }
  &:hover {
    cursor: pointer;
  }
  &::before {
    content: '!';
    transform: scale(0.6);
    display: inline-block;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    border: 2px solid ${(props) => props.theme.redPink};
    color: ${(props) => props.theme.redPink};
    text-align: center;
  }
`;

const PhoneNumberWrapper = styled.span`
  text-decoration: underline;
  &:hover {
    color: blue;
  }
`;

interface Props {
  chatId: string;
  isSingleChat: boolean;
  message: Message;
  searchMessage: MessageInChat | null;
  searchMessageData: MessageInChat[] | [];
  jumpToMessage: MessageInChat;
  isSideSearchActive: boolean;
  searchText: string;
  prevMessage: Message;
  isLastInConsecutiveBySender: boolean;
  renderTimeStamp: boolean;
  isOptimistic: boolean;
  isFailing: boolean;
  getTimestampString: () => React.ReactElement<any>;
  type: MessageType;
  isSelf: boolean;
  reSendMessage: () => void;
  renderReadReceipts: () => void;
}

const MessagesItem = ({
  chatId,
  isSingleChat,
  message,
  searchMessage,
  searchMessageData,
  jumpToMessage,
  isSideSearchActive,
  searchText,
  prevMessage,
  isLastInConsecutiveBySender,
  renderTimeStamp,
  isOptimistic,
  isFailing,
  getTimestampString,
  type,
  isSelf,
  reSendMessage,
  renderReadReceipts,
}: Props) => {
  const renderOther = (): React.ReactElement<any> => {
    let nametag: React.ReactElement<any> | null = null;
    const currentSender = message.sender;
    const prevSender = prevMessage && prevMessage.sender;
    if (prevSender === null || (prevSender && currentSender.username !== prevSender.username)) {
      nametag = (
        <NameTag>
          {currentSender.firstname} {currentSender.lastname}{' '}
        </NameTag>
      );
    }

    return (
      <OtherMessageContainer>
        {nametag}
        <OtherMessageContainerChildWrapper>{renderMessage(false)}</OtherMessageContainerChildWrapper>
      </OtherMessageContainer>
    );
  };

  const renderSystem = (): React.ReactElement<any> => {
    return <SystemMessageContainer>{renderMessage(true)}</SystemMessageContainer>;
  };

  const renderSelf = (): React.ReactElement<any> => {
    return <SelfMessageContainer>{renderMessage(true)}</SelfMessageContainer>;
  };

  const renderMessage = (isSelf: boolean) => {
    return (
      <MessageBubbleWrapper
        chatId={chatId}
        isSingleChat={isSingleChat}
        isLastInConsecutiveBySender={isLastInConsecutiveBySender}
        message={message}
        searchText={searchText}
        searchMessage={searchMessage}
        searchMessageData={searchMessageData}
        jumpToMessage={jumpToMessage}
        isSideSearchActive={isSideSearchActive}
        isSelfMessage={isSelf}
      >
        {message.message.split('\n').map((msg, i) => (
          <div key={i}>{msg}</div>
        ))}
      </MessageBubbleWrapper>
    );
  };

  return (
    <React.Fragment>
      {renderTimeStamp && <Timestamp>{getTimestampString()}</Timestamp>}
      {isOptimistic && !isFailing ? (
        <MessageContainer style={{pointerEvents: 'none'}}>{renderSelf()}</MessageContainer>
      ) : (
        <MessageContainer>{type === SYSTEM ? renderSystem() : isSelf ? renderSelf() : renderOther()}</MessageContainer>
      )}
      {isFailing ? (
        <ResendMessage onClick={reSendMessage}>Fail to send message, please click here to try again</ResendMessage>
      ) : null}

      {isSelf && renderReadReceipts()}
    </React.Fragment>
  );
};

export default React.memo(MessagesItem);
