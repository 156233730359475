import {Formik, FormikProps} from 'formik';
import React, {Component} from 'react';
import {withRouter, RouteComponentProps} from 'react-router-dom';
import Spinner from 'react-spinkit';
import styled from 'styled-components';
import {DefaultButton} from 'src/styles/styled-components/Button';
import Input from 'src/styles/styled-components/Input';
import PlaceholderLabel from 'src/styles/styled-components/PlaceholderLabel';
import {ApolloClient} from 'apollo-client';
import {ApolloConsumer} from 'react-apollo';
import ChangePasswordMutation from 'src/gql/mutation/ChangePasswordMutation';
import InputChecker from 'src/utils/changePasswordInputChecker';
import {AuthContext} from 'src/auth';
import {toast} from 'react-toastify';
import {determineError} from 'src/utils/errors/changePasswordErrors';
import AnalyticsManager, {EVENTS} from 'src/analytics/AnalyticsManager';

const sharedWidth = `width: 160px;`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  padding-bottom: 80px;
  margin-top: 54px;
`;

const InputContainer = styled.div`
  margin-top: 2em;
  margin-bottom: 2em;
`;

const BackButtonContainer = styled.div`
  & > button {
    ${sharedWidth}
    background-color: white;
    border: solid 1.5px ${(props) => props.theme.secondary};
    color: ${(props) => props.theme.secondary};
    font-family: Nunito;
    font-weight: bold;
  }
`;

const ChangePwdButtonContainer = styled.div`
  & > button {
    ${sharedWidth}
    height: 100%;
  }
`;

const ErrorMessage = styled.p`
  color: ${(props) => props.theme.main};
`;

interface Values {
  oldpassword: string;
  newpassword: string;
  confirmpassword: string;
}

interface Props extends RouteComponentProps<{}> {
  client: ApolloClient<any>;
  handleOnChange: (event: React.FormEvent<HTMLInputElement>) => void;
  logout: () => void;
}

class PasswordForm extends Component<Props> {
  public oldpasswordRef: React.RefObject<HTMLInputElement>;
  public newpasswordRef: React.RefObject<HTMLInputElement>;
  public confirmpasswordRef: React.RefObject<HTMLInputElement>;
  public state = {
    error: null,
    isLoading: false,
  };
  public constructor(props) {
    super(props);
    this.confirmpasswordRef = React.createRef();
    this.oldpasswordRef = React.createRef();
    this.newpasswordRef = React.createRef();
  }
  public render() {
    return (
      <Formik initialValues={{oldpassword: '', newpassword: '', confirmpassword: ''}} onSubmit={this.onSubmit}>
        {(props) => this.renderForm(props)}
      </Formik>
    );
  }
  // tslint:disable jsx-no-lambda
  private renderForm: any = ({handleSubmit, values, handleChange}: FormikProps<Values>) => {
    const {error, isLoading} = this.state;
    const {history} = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <InputContainer>
          <PlaceholderLabel onClick={() => this.oldpasswordRef.current!.focus()}>Old Password</PlaceholderLabel>
          <Input
            ref={this.oldpasswordRef}
            hasUnderline={true}
            value={values.oldpassword}
            name="oldpassword"
            type="password"
            autoFocus={true}
            onChange={(e) => {
              handleChange(e);
              this.props.handleOnChange(e);
            }}
          />
        </InputContainer>
        <InputContainer>
          <PlaceholderLabel onClick={() => this.newpasswordRef.current!.focus()}>New Password</PlaceholderLabel>
          <Input
            ref={this.newpasswordRef}
            hasUnderline={true}
            value={values.newpassword}
            name="newpassword"
            type="password"
            onChange={(e) => {
              handleChange(e);
              this.props.handleOnChange(e);
            }}
          />
        </InputContainer>
        <InputContainer>
          <PlaceholderLabel onClick={() => this.confirmpasswordRef.current!.focus()}>Confirm Password</PlaceholderLabel>
          <Input
            ref={this.confirmpasswordRef}
            hasUnderline={true}
            value={values.confirmpassword}
            name="confirmpassword"
            type="password"
            onChange={(e) => {
              handleChange(e);
              this.props.handleOnChange(e);
            }}
          />
        </InputContainer>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <ButtonsWrapper>
          <BackButtonContainer>
            <DefaultButton type="button" onClick={() => history.goBack()}>
              Back
            </DefaultButton>
          </BackButtonContainer>
          <ChangePwdButtonContainer>
            <DefaultButton type="submit" disabled={this.isButtonDisabled(values) || isLoading}>
              {isLoading ? <Spinner name="circle" color="#fff" /> : 'Submit'}
            </DefaultButton>
          </ChangePwdButtonContainer>
        </ButtonsWrapper>
      </form>
    );
  };
  private onSubmit = async (values: Values) => {
    const {client, history} = this.props;
    try {
      this.setState({
        isLoading: true,
        error: null,
      });
      await client.mutate({
        mutation: ChangePasswordMutation,
        variables: {
          oldPassword: values.oldpassword,
          newPassword: values.newpassword,
        },
      });
      this.setState({isLoading: false});

      toast.success('Your password has been changed!', {
        autoClose: false,
      });

      AnalyticsManager.applyAnalytics({
        eventName: EVENTS.changePassword,
      });

      this.logout();
      history.push('/login');
    } catch (e) {
      const errorMsg = determineError(e);
      if (!errorMsg) {
        this.logout();
        history.push('/login');
        toast.error('Password change failed, please try to login again');
      } else {
        toast.error('Password change failed');
        this.setState({
          isLoading: false,
          error: errorMsg,
        });
      }
    }
  };
  private isButtonDisabled = ({oldpassword, newpassword, confirmpassword}: Values) => {
    return !(InputChecker(newpassword, confirmpassword) && oldpassword.length > 0);
  };
  private logout = () => this.props.logout();
}

const WithRouterComponent = withRouter(PasswordForm);

export default (props) => (
  <ApolloConsumer>
    {(client) => (
      <AuthContext.Consumer>
        {({logout}) => <WithRouterComponent {...props} logout={logout} client={client} />}
      </AuthContext.Consumer>
    )}
  </ApolloConsumer>
);
