import React from 'react';
import {SESSION_TIMEOUT_LOGINPAGE_MODAL} from 'src/constants/sessionTimeout';
import {makeStyles, createStyles} from '@material-ui/core/styles';
import {Typography} from '@material-ui/core';
import AlertModal from 'src/pages/MessengerPage/messenger/messages-layout/message-template/AlertModal';
interface Props {
  isOpen: boolean;
  setIsOpen: (arg: boolean) => void;
  timeoutValue: number;
}

const useStyles = makeStyles((_) =>
  createStyles({
    modalContent: {
      marginBottom: '24px',
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
    },
  }),
);

const LogOutModal = ({isOpen, setIsOpen, timeoutValue}: Props) => {
  const classes = useStyles();

  const handleModalClose = () => {
    sessionStorage.setItem(SESSION_TIMEOUT_LOGINPAGE_MODAL, 'false');
    setIsOpen(false);
  };

  return (
    <AlertModal
      width="xs"
      title={`Session expired`}
      titleFontSize="24px"
      isAlertModalVisible={isOpen}
      showCloseButton={false}
      alertModalButtons={[
        {
          type: 'primary',
          buttonLabel: 'OK',
          onClickHandler: () => handleModalClose(),
          id: 'ok-btn',
        },
      ]}
      modalContent={
        <Typography classes={{root: classes.modalContent}}>
          You have been signed out for security purposes because you were idle for {timeoutValue / 60000} minutes
        </Typography>
      }
    />
  );
};

export const LogOutModalWrapper = React.memo(LogOutModal);
