import React, {useContext, useEffect, useState} from 'react';
import styled from 'styled-components';
import HypercareLogoSVG from 'src/svgs/HypercareLogoSVG';
import HypercareLoginAddressViewModel from './HypercareLoginAddress/HypercareLoginAddressViewModel';
import HypercareLoginRepository from './HypercareLoginRepository';
import HypercareLoginAddressView from './HypercareLoginAddress/HypercareLoginAddressView';
import {Link, Redirect} from 'react-router-dom';
import AnalyticsManager, {PAGE_VIEWS} from 'src/analytics/AnalyticsManager';
import {LogOutModalWrapper} from 'src/components/SessionTimeout/sessionTimeoutModals/logoutModal';
import {IDLE_TIMEOUT, LAST_ACTIVE_TIME, SESSION_TIMEOUT_LOGINPAGE_MODAL} from 'src/constants/sessionTimeout';
import {DEBUG, DESIRED_ROUTE} from 'src/constants/storageKeys';
import {AuthContext} from 'src/auth';
import {HypercareLoginPasswordView} from './HypercareLoginPassword/HypercareLoginPasswordView';
import HypercareLoginPasswordViewModel from './HypercareLoginPassword/HypercareLoginPasswordViewModel';
import {LinearProgress} from '@material-ui/core';
import ChangeRegionModal from 'src/components/ChangeRegion/ChangeRegionModal';
import HypercareAuthRegionContext from 'src/contexts/HypercareLoginCoordinatorContext';
import {LOGIN} from 'src/constants/strings';
import {HandleContinueLoginAddressParams} from 'src/types';

const SignupPageContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  overflow-y: hidden;
`;

const SignupPageHypercareLogoContainer = styled.div`
  width: 250px !important;
  height: 48px !important;
`;

const FooterText = styled.div`
  position: relative;
  height: 28px;
  line-height: 2em;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 18px;
  color: ${(props) => props.theme.greyishBrown};
  a {
    padding-top: 8px;
    padding-bottom: 8px;
    color: ${(props) => props.theme.chatTeal};
    text-decoration: none;
    font-weight: bold;
  }
`;

interface LoginFormWrapperProps {
  loading?: string;
}

const SignupPageContentContainer = styled.div<LoginFormWrapperProps>`
  opacity: ${(props) => (props.loading ? '0.5' : '')};
  position: relative;
`;

const ProgressContainer = styled.div`
  z-index: 2;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 600px;
  & * {
    border-radius: 8px 8px 0px 0px;
  }
`;

const LOGIN_STEP_ONE = 1;
const LOGIN_STEP_TWO = 2;

const LoginCoordinator = ({isLoggedIn, auth0props, login}) => {
  const [currentStep, setCurrentStep] = useState(LOGIN_STEP_ONE);
  const [username, setUsername] = useState<string | undefined>('');
  const [loading, setLoading] = useState(false);

  const [idleTimeoutModal, setIdleTimeoutModal] = useState<boolean>(false);

  const idleTimeoutValue = parseInt(window.sessionStorage.getItem(IDLE_TIMEOUT) || '0');

  const {changeRegionModalOpenStatus, setChangeRegionModalOpenStatus} = useContext(HypercareAuthRegionContext);

  useEffect(() => {
    AnalyticsManager.recordPageVisited(PAGE_VIEWS.loginScreen);
  }, []);

  useEffect(() => {
    const modalCloseValues = JSON.parse(window.sessionStorage.getItem(SESSION_TIMEOUT_LOGINPAGE_MODAL));
    const initialModalValue = !!modalCloseValues;
    setIdleTimeoutModal(initialModalValue);
  }, [idleTimeoutModal]);

  if (isLoggedIn) {
    const desiredRoute = sessionStorage.getItem(DESIRED_ROUTE);
    return <Redirect to={desiredRoute ? desiredRoute : '/messenger/home'} />;
  }

  const handleContinueLoginAddress = ({address, auth0Id, workOS}: HandleContinueLoginAddressParams) => {
    window.localStorage.removeItem(LAST_ACTIVE_TIME);
    setLoading(true);
    if (workOS) {
      return;
    } else if (auth0Id) {
      auth0props.loginWithRedirect({
        connection: auth0Id,
        scope: 'openid profile email',
      });
    } else {
      setUsername(address);
      setCurrentStep((prevValue) => prevValue + 1);
    }
    setLoading(false);
  };

  const showDebugMenu = () => {
    window.routerHistory.replace(`/${DEBUG}`);
  };

  const handleGoToPrevious = () => {
    setCurrentStep((prevValue) => prevValue - 1);
  };

  const showHypercareLoginAddressView = () => {
    const repository = new HypercareLoginRepository();
    const LoginAddressViewModel = new HypercareLoginAddressViewModel(handleContinueLoginAddress, repository);
    return (
      <HypercareLoginAddressView
        viewModel={LoginAddressViewModel}
        isOpen={changeRegionModalOpenStatus}
        isLoading={loading}
      />
    );
  };

  const showHypercareLoginPasswordView = () => {
    const repository = new HypercareLoginRepository();
    const LoginPasswordViewModel = new HypercareLoginPasswordViewModel(repository, login);

    return (
      <HypercareLoginPasswordView
        username={username}
        viewModel={LoginPasswordViewModel}
        handleBackButtonClick={handleGoToPrevious}
      />
    );
  };

  return (
    <SignupPageContainer>
      <SignupPageHypercareLogoContainer onClick={showDebugMenu}>
        <HypercareLogoSVG />
      </SignupPageHypercareLogoContainer>

      <SignupPageContentContainer loading={loading ? 'true' : undefined}>
        {loading && (
          <ProgressContainer>
            <LinearProgress color="secondary" />
          </ProgressContainer>
        )}
        {currentStep === LOGIN_STEP_ONE && showHypercareLoginAddressView()}
        {currentStep === LOGIN_STEP_TWO && showHypercareLoginPasswordView()}
      </SignupPageContentContainer>

      {idleTimeoutModal && (
        <LogOutModalWrapper isOpen={idleTimeoutModal} setIsOpen={setIdleTimeoutModal} timeoutValue={idleTimeoutValue} />
      )}

      {changeRegionModalOpenStatus && (
        <ChangeRegionModal
          isOpen={changeRegionModalOpenStatus}
          setIsOpen={setChangeRegionModalOpenStatus}
          currentScreen={LOGIN}
        />
      )}

      <FooterText>
        New to Hypercare? <Link to="/signup">Sign up</Link>
      </FooterText>
    </SignupPageContainer>
  );
};

const HypercareLoginCoordinator = () => {
  return (
    <AuthContext.Consumer>
      {({isLoggedIn, auth0props, login}) => (
        <LoginCoordinator isLoggedIn={isLoggedIn} auth0props={auth0props} login={login} />
      )}
    </AuthContext.Consumer>
  );
};

export default HypercareLoginCoordinator;
