import styled from 'styled-components';
import {Link} from 'react-router-dom';

interface OptionProp {
  color?: string;
  backgroundColor?: string;
}

export const OptionsBox = styled('div')`
  z-index: 9;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.popperBackground};
  border-radius: 5px;
  color: #ffffff;
  font-size: 0.8em;
`;

export const Option = styled.div`
  display: flex;
  align-items: center;
  padding: 0.55em 0.6em;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background-color: #333333;
  }
`;

export const OptionContainer = styled.ul`
  height: auto;
  list-style: none;
  width: 100%;
  margin: 0px 0px 0px 0px;
  padding: 0px;
`;

export const OptionMain = styled.li`
  width: 100%;
  clear: both;
  display: inline-block;
  &:hover {
    color: #ffffff;
    ul {
      display: block;
    }
  }
  span {
    float: right;
  }
`;

export const SubOptionContainer = styled.ul`
  display: none;
  position: absolute;
  background-color: #333333;
  min-width: 180px;
  list-style-type: none;
  width: 125px;
  padding: 5px;
  margin-top: -20px;
  margin-left: 0px;
  left: -180px;

  &:hover {
    display: block;
  }
`;

export const SubOption = styled.li`
  position: relative;
  display: block;
  top: 0;
  left: 0%;
  width: 100%;
  min-width: 180px;
  white-space: nowrap;
  z-index: 1;
`;

export const DisabledOption = styled(Option)`
  pointer-events: none;
  user-select: none;
`;

export const DisabledOptionOpacity = styled(Option)`
  pointer-events: none;
  user-select: none;
  opacity: 0.5;
`;

export const MenuButton = styled.div`
  cursor: pointer;
  user-select: none;
  padding-bottom: 5px;
`;

export const MenuButtonVisibility = styled.div<{backgroundColor?: string}>`
  cursor: pointer;
  user-select: none;
  padding-bottom: 5px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 12px;
  gap: 8px;
  height: 28px;
  left: 503px;
  top: 682px;
  border-radius: 19px;
  ${(props) => (props.backgroundColor ? `background-color : ${props.backgroundColor};` : '#DCE1E6')}
`;

export const StyledLink = styled(Link)`
  color: #ffffff;
  width: 100%;
  text-decoration: none;
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

export const StyledHrefLink = styled.a`
  color: #00859a;
  text-decoration: none;
  &:hover {
    text-decoration: underline; /* You can use any hover styles you want */
  }
`;

export const OptionsBoxNotes = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px;
  left: 1212px;
  top: 646px;
  background: #ffffff;
  box-shadow: 0px 18px 28px rgba(9, 30, 66, 0.15), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  margin-top: 8px;
  margin-left: 15px;
  z-index: 3;
`;

export const OptionNotes = styled.div<OptionProp>`
  display: flex;
  align-items: center;
  padding: 0.55em 0.6em;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background-color: #f6f6f9;
  }
  color: ${(props) => props.color};
  width: 100%;
  background-color: ${(props) => props.backgroundColor};
  height: 44px;
  gap: 6px;
`;
