import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import styled from 'styled-components';
import {LocatingCodeTeam} from 'src/types';
import CodeStemiModal from 'src/pages/LocatingPage/locating/CodeStemiModal';
import ProfileGroup from 'src/pages/LocatingPage/locating/search-section/ProfileGroup';
import {IsFeatureFlagEnabled} from 'src/utils/FeatureFlagManager';
import {HCLabelOne} from 'src/components/HypercareComponents';

const TeamHolder = styled.div`
  min-width: 0;
  width: 100%;
  overflow: hidden;
  padding-right: 2em;
`;

const TeamListItemContainer = styled.div<{roleHiddenNotes: boolean}>`
  border-radius: 4px;
  border: 0.5px solid #d8d8d8;
  padding: 4px;
  margin: 10px;
`;

interface Props {
  codeTeam: LocatingCodeTeam;
}

const RoleListItems = ({codeTeam}: Props) => {
  const [showCodeStemiModal, setCodeStemiModal] = React.useState(false);
  const roleHiddenNotes = IsFeatureFlagEnabled('roleHiddenNotes');
  // either user or its role onShift, it cannot be both but can be neither, filtered out empty teams member
  const usersList = codeTeam.members
    .map((member) =>
      member.user ? member.user : member.role && member.role.currentShift ? member.role.currentShift.user : null,
    )
    .filter((user) => user);

  return (
    <TeamListItemContainer roleHiddenNotes={roleHiddenNotes}>
      <ListItem disableGutters onClick={() => setCodeStemiModal(true)} button disableRipple alignItems={'flex-start'}>
        <IconButton disabled aria-label="star" style={{padding: '0px 12px 12px 12px'}}>
          <i className="material-icons" style={{color: 'red'}}>
            error_outline
          </i>
        </IconButton>
        <TeamHolder>
          <HCLabelOne fontWeight={600}>{codeTeam.name}</HCLabelOne>
          <ProfileGroup users={usersList} maxDisplay={6} />
        </TeamHolder>
      </ListItem>
      {showCodeStemiModal && (
        <CodeStemiModal
          showModal={showCodeStemiModal}
          handleCloseModal={() => setCodeStemiModal(false)}
          codeId={codeTeam.id}
          codeName={codeTeam.name}
        />
      )}
    </TeamListItemContainer>
  );
};

export default React.memo(RoleListItems);
