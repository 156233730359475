import React from 'react';
import styled from 'styled-components';
import {WideWidthStyleModal, CloseBtn, Header} from 'src/styles/styled-components/ModalStyleComponents';
import {typedUseSelector} from 'src/redux';
import InvitationTabView from 'src/components/InvitationTabView';
import {IS_FINISHING_CREATE_ORGANIZATION} from 'src/constants/storageKeys';
import {IsFeatureFlagEnabled} from 'src/utils/FeatureFlagManager';
import {FeatureFlagResult} from 'src/utils/FeatureFlags';

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  handleOnComplete?: () => void;
}

const HeaderTitle = styled(Header)`
  font-size: 21px;
  span {
    font-family: 'Nunito' !important;
  }
`;

const InviteColleaguesModal = ({isOpen, closeModal, handleOnComplete}: Props) => {
  const coSigningInvitesFF = IsFeatureFlagEnabled('coSigningInvites');
  const currentOrganization = typedUseSelector((store) => store.organization);

  // auto prompts when finishing create organization, remove this when revamp create organization flow
  React.useLayoutEffect(() => {
    if (sessionStorage.getItem(IS_FINISHING_CREATE_ORGANIZATION))
      sessionStorage.removeItem(IS_FINISHING_CREATE_ORGANIZATION);
  }, []);

  return (
    <WideWidthStyleModal
      isOpen={isOpen}
      shouldCloseOnEsc={true}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={true}
      onRequestClose={closeModal}
    >
      {coSigningInvitesFF ? (
        <HeaderTitle>
          <span>Invite Colleagues to {currentOrganization.name}</span>
          <CloseBtn onClick={closeModal} />
        </HeaderTitle>
      ) : (
        <Header>
          <span>Invite Colleagues to {currentOrganization.name}</span>
          <CloseBtn onClick={closeModal} />
        </Header>
      )}
      <InvitationTabView closeModal={closeModal} handleOnComplete={handleOnComplete} />
    </WideWidthStyleModal>
  );
};

export default InviteColleaguesModal;
