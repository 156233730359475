import React, {VFC} from 'react';
import {FooterContainer, HCFilterBase, HCFilterBaseProps, OutlineLink} from './HCFilterBase';
import styled from 'styled-components';

export interface HCFilterClearableProps extends HCFilterBaseProps {
  children?: React.ReactNode;
  onClear?: () => void;
}

export const ClearFilterButton = styled.div<{isAnySelected: boolean}>`
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 12px 6px 12px;
  border-radius: 4px;
  :hover {
    background: ${({isAnySelected}) => isAnySelected && '#f6f6f9'};
    cursor: ${({isAnySelected}) => isAnySelected && 'pointer'};
  }
`;

export const HCFilterClearableBase: VFC<HCFilterClearableProps> = ({children, selectedIds, onClear, ...rest}) => {
  return (
    <HCFilterBase {...rest}>
      {children}
      <FooterContainer>
        <ClearFilterButton isAnySelected={selectedIds.length > 0}>
          <span
            onClick={selectedIds.length > 0 && onClear}
            style={{
              color: selectedIds.length > 0 ? '#00859A' : '#BCBCBC',
              fontFamily: 'Nunito Sans',
              userSelect: 'none',
              fontSize: 15,
              lineHeight: '20px',
            }}
          >
            Clear filters
          </span>
        </ClearFilterButton>
      </FooterContainer>
    </HCFilterBase>
  );
};
