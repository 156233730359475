import React, {useState, VFC} from 'react';
import styled from 'styled-components';
import {FilterItem, FilterOptionsContainer, HCFilterOption} from './HCFilterSimple';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import CloseButtonIcon from '../../../../svgs/CloseButtonIcon';
import {CloseContainer, PopupTopContainer, TitleContainer} from '../base/HCFilterBase';
import ChevronLeftIcon from '../../../../svgs/ChevronLeftIcon';
import {ClearFilterButton} from '../base/HCFilterClearableBase';

export interface HCFilterBaseProps {
  title: string;
  children?: React.ReactNode;
  onToggle?: (id: string) => void;
  onCustomRangeToggle?: (from: moment.Moment, to: moment.Moment) => void;
  selectedIds?: string[];
  onClear?: () => void;
  onClose?: () => void;
  defaultFromDate?: moment.Moment;
  defaultToDate?: moment.Moment;
}

export const Container = styled.div`
  width: 350px;
  border-radius: 8px;
  background: white;
  box-shadow: 0 18px 28px 0 #091e4226;
  border: 1px solid #d8d8d8;
  position: absolute;
`;

export const FooterContainer = styled.div`
  border-top: 1px solid #d8d8d8;
  padding: 8px;
  padding-right: 16px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`;

export const OutlineLink = styled.a`
  cursor: pointer;
  color: #00859a;
`;

const TimeOptions: HCFilterOption[] = [
  {
    id: '1 hour',
    label: 'Past 1 hour',
    value: '1 hour',
  },
  {
    id: '12h',
    label: 'Past 12 hours',
    value: '12 hours',
  },
  {
    id: '24h',
    label: 'Past 24 hours',
    value: '24 hours',
  },
  {
    id: '48h',
    label: 'Past 48 hours',
    value: '48 hours',
  },
  {
    id: '7d',
    label: 'Past 7 days',
    value: '7 days',
  },
  {
    id: 'custom',
    label: 'Custom',
    value: 'custom',
  },
];

const CustomFilterBackButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding-right: 6px;
`;

const DateContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  padding-bottom: 10px;
`;

const DateLabelContainer = styled.div`
  color: #767676;
  font-weight: 600;
`;

const WarningContainer = styled.div`
  color: red;
  padding: 20px;
`;

const defaultDateFormat = 'yyyy-MM-ddThh:mm:ss';

export const HCTimeFilter: VFC<HCFilterBaseProps> = ({
  onClose,
  selectedIds,
  title,
  onToggle,
  onCustomRangeToggle,
  onClear,
  defaultFromDate,
  defaultToDate,
}) => {
  const [isCustomRangeSelected, setIsCustomRangeSelected] = useState(false);

  const [fromDate, setFromDate] = useState(defaultFromDate || moment().subtract(7, 'day'));
  const [toDate, setToDate] = useState(defaultToDate || moment());

  const lowerBound = moment().subtract('7', 'days').subtract(1, 'minute');

  const isInRange = fromDate.isAfter(lowerBound) && toDate.isAfter(lowerBound);
  const isValid = fromDate.isBefore(toDate);

  const allowApply = isCustomRangeSelected && isInRange && isValid;

  const onApply = () => {
    if (isInRange && fromDate && toDate && isValid) {
      onCustomRangeToggle(fromDate, toDate);
    }
  };

  return (
    <Container>
      <PopupTopContainer>
        <TitleContainer>
          {isCustomRangeSelected && (
            <CustomFilterBackButton onClick={() => setIsCustomRangeSelected(false)}>
              <ChevronLeftIcon />
            </CustomFilterBackButton>
          )}
          {isCustomRangeSelected ? 'Custom' : title}
        </TitleContainer>
        {!isCustomRangeSelected && (
          <CloseContainer onClick={onClose}>
            <CloseButtonIcon />
          </CloseContainer>
        )}
      </PopupTopContainer>
      <FilterOptionsContainer>
        {isCustomRangeSelected ? (
          <div>
            <DateContainer>
              <DateLabelContainer>From</DateLabelContainer>
              <TextField
                type="datetime-local"
                value={fromDate.format('YYYY-MM-DDTHH:mm')}
                onChange={(e) => setFromDate(moment(e.target.value.replace('T', ' '), 'YYYY-MM-DD HH:mm'))}
                InputLabelProps={{shrink: true}}
              />
            </DateContainer>
            <DateContainer>
              <DateLabelContainer>To</DateLabelContainer>
              <TextField
                type="datetime-local"
                value={toDate.format('YYYY-MM-DDTHH:mm')}
                onChange={(e) => setToDate(moment(e.target.value.replace('T', ' '), 'YYYY-MM-DD HH:mm'))}
                InputLabelProps={{shrink: true}}
              />
            </DateContainer>
          </div>
        ) : (
          TimeOptions.map((timeOption) => (
            <FilterItem
              isSelected={selectedIds.length && timeOption.value === selectedIds[0]}
              option={timeOption}
              onToggle={() => {
                if (timeOption.id === 'custom') {
                  setIsCustomRangeSelected(true);
                } else {
                  onToggle(timeOption.value);
                }
              }}
            />
          ))
        )}
      </FilterOptionsContainer>
      {isCustomRangeSelected && (
        <>
          {!isInRange && (
            <WarningContainer>
              You can only view events from up to the last 7 days. Please contact Hypercare support.
            </WarningContainer>
          )}
          {!isValid && <WarningContainer>From date must be before to date.</WarningContainer>}
          <FooterContainer>
            <ClearFilterButton isAnySelected={allowApply} onClick={allowApply && onApply}>
              <span
                style={{
                  color: allowApply ? '#00859A' : '#BCBCBC',
                  fontFamily: 'Nunito Sans',
                  userSelect: 'none',
                  fontSize: 15,
                  lineHeight: '20px',
                }}
              >
                Apply
              </span>
            </ClearFilterButton>
          </FooterContainer>
        </>
      )}
    </Container>
  );
};
