import React from 'react';
import styled from 'styled-components';
import UserGroupPlusIcon from 'src/svgs/UserGroupPlusIcon';
import {FullOrganizationMember} from 'src/types';

const PanelBanner = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: 'Nunito', sans-serif;
  user-select: none;
  padding: 1em;
  align-items: center;
  height: 60px;
  min-height: 60px;
  border-bottom: 1px solid ${(props) => props.theme.borderColor};
  svg {
    cursor: pointer;
    * {
      fill: ${(props) => props.theme.redPink};
    }
  }
`;

const StyledH3 = styled.h3`
  font-family: 'Nunito';
  font-size: 18px;
  font-weight: 600;
`;

interface Props {
  allowInvites: boolean;
  handleClickInviteColleagues: () => void;
}

const TopPanelBanner = ({allowInvites, handleClickInviteColleagues}: Props) => {
  return (
    <PanelBanner>
      <StyledH3>Contacts</StyledH3>
      {allowInvites && (
        <span onClick={handleClickInviteColleagues}>
          <UserGroupPlusIcon />
        </span>
      )}
    </PanelBanner>
  );
};

export default TopPanelBanner;
