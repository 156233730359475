import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import Chevron from 'src/svgs/Chevron';
import styled from 'styled-components';
import {SmallLoader} from 'src/components/LoadingDiv';
import {UserViewModel} from 'src/pages/ContactsPage/viewModels/UserViewModel';
import {CONTACT_LIST_ERROR, NO_USERS} from 'src/constants/user';
import InvitationListV2 from '../InvitedUserList/InvitationListV2';
import {CircularProgress} from '@material-ui/core';

const UserListWrapper = styled.div`
  display: contents;
  max-height: 100%;
`;

const InviteSummary = styled.span`
  font-weight: bold;
  font-family: Nunito;
`;

const NoResult = styled.p`
  margin-top: 1em;
  font-size: 16px;
  color: ${(props) => props.theme.greyishBrown};
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  expandWrapper: {
    boxShadow: 'none',
    margin: '0 !important',
  },
  summaryHeaderWrapper: {
    position: 'sticky',
    top: 0,
    padding: '4px 0.5em',
    zIndex: 1,
    backgroundColor: 'white',
  },
}));

const InvitationUserListWrapperV2 = () => {
  const classes = useStyles({});

  const userViewModel = UserViewModel();

  const paginatedPendingInvitesDataQuery = userViewModel.fetchPaginatedInvites({
    continuationId: null,
    direction: 'next',
  });

  const pendingInvitesResultList =
    paginatedPendingInvitesDataQuery?.data?.organizationalUnitQuery?.organizationalUnit?.paginatedInvites?.invites;

  const pendingUserInvitesListCount =
    paginatedPendingInvitesDataQuery?.data?.organizationalUnitQuery?.organizationalUnit?.paginatedInvites
      ?.totalResultsCount;
  if (paginatedPendingInvitesDataQuery?.loading)
    return (
      <LoadingContainer>
        <CircularProgress />
      </LoadingContainer>
    );
  if (!pendingInvitesResultList) return <p>{NO_USERS}</p>;
  if (paginatedPendingInvitesDataQuery?.error) return <p>{CONTACT_LIST_ERROR}</p>;

  return (
    <UserListWrapper className={classes.root}>
      <ExpansionPanel defaultExpanded={false} className={classes.expandWrapper}>
        <ExpansionPanelSummary
          className={classes.summaryHeaderWrapper}
          expandIcon={<Chevron />}
          aria-controls="invite-summary"
        >
          <Typography className={classes.heading}>
            <InviteSummary>Pending Invites ({pendingUserInvitesListCount})</InviteSummary>
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{padding: 0}}>
          <InvitationListV2
            invitedUserList={pendingInvitesResultList}
            paginatedPendingInvitesData={paginatedPendingInvitesDataQuery?.data}
            fetchMorePaginatedInvitesData={paginatedPendingInvitesDataQuery?.fetchMore}
          />
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </UserListWrapper>
  );
};

export default InvitationUserListWrapperV2;
