import React from 'react';
import {Formik, FormikErrors, FormikTouched} from 'formik';
import * as yup from 'yup';
import {IStepProps, ITellUsAboutYourSelfStepData} from 'src/types';
import {firstNameSchema, lastNameSchema, phoneNumberOptionalValidationSchema} from 'src/utils/validations';
import AnalyticsManager, {EVENTS, PAGE_VIEWS} from 'src/analytics/AnalyticsManager';
import store from 'src/redux';
import {StepContainerCopy} from '../components/step-container-copy';
import NewStepHeader from '../components/step-header-copy';
import NewNextStepButton from '../components/NextStepButton';
import {StyledTextField} from 'src/styles/styled-components/StyledInputField';
import NewStepFooter from '../components/step-footer-copy';
import {CANADA_ABBREVIATION, EUROPE_ABBREVIATION, UNITED_STATES_ABBREVIATION} from 'src/constants/strings';
import styled from 'styled-components';
import PhoneInput from 'react-phone-input-2';
import SignupPage from 'src/microfrontend/signup/SignupPage';

const validationSchema = yup.object().shape<ITellUsAboutYourSelfStepData>({
  firstName: firstNameSchema,
  lastName: lastNameSchema,
  phoneNumber: phoneNumberOptionalValidationSchema,
});

const StyledInputField = styled(PhoneInput)<{$isError: boolean}>`
  .form-control {
    border-color: ${(props) => (props.$isError ? '#f44336' : '')};
    &:hover {
      border-color: ${(props) => (props.$isError ? '#f44336' : '')};
    }
  }
  .special-label {
    color: ${(props) => (props.$isError ? '#f44336' : '')};
  }
`;

interface IPhoneInputField {
  value: string;
  name: string;
  setFieldValue?: (field: string, value: string) => void;
  touched?: FormikTouched<{phoneNumber: boolean}>;
  errors?: FormikErrors<{phoneNumber: string}>;
}

const PhoneInputField = ({value, name, touched, errors, setFieldValue}: IPhoneInputField) => {
  const currentAuthRegion = store.getState().hypercareRegion.currentAuthRegion;
  const displayCurrentRegionFlag = () => {
    let currentRegion: string;
    switch (currentAuthRegion) {
      case CANADA_ABBREVIATION:
        currentRegion = 'ca';
        break;
      case UNITED_STATES_ABBREVIATION:
        currentRegion = 'us';
        break;
      case EUROPE_ABBREVIATION:
        currentRegion = 'es';
        break;
      default:
        currentRegion = 'ca';
        break;
    }
    return currentRegion;
  };

  const onValueChange = (phoneNumber: string) => {
    setFieldValue(name, phoneNumber);
  };
  return (
    <StyledInputField
      $isError={touched.phoneNumber && Object.keys(errors).length > 0}
      disableCountryGuess={true}
      specialLabel="Phone number (Optional)"
      placeholder="123-456-7890"
      inputProps={{
        name: 'phoneNumber',
      }}
      containerStyle={{marginTop: '16px'}}
      country={displayCurrentRegionFlag()}
      value={value}
      onChange={onValueChange}
      inputStyle={{
        width: '100%',
      }}
      enableSearch={true}
    />
  );
};

export const SignupPersonalDetailsView = ({viewModel, inputData, onBackButtonClick}) => {
  console.log(inputData);
  React.useEffect(() => {
    AnalyticsManager.recordPageVisited(PAGE_VIEWS.signupProfile);
  }, []);

  const transformName = (value: string) => {
    if (!value || typeof value !== 'string') return value;
    return value.replace(/[^a-zA-Z ]/, '');
  };

  const onNextClick = (values: {firstName: string; lastName: string; phoneNumber: string}) => {
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.signupPersonalInfoNextPressed,
      params: {
        first_name: values.firstName,
        last_name: values.lastName,
        phone_number: values.phoneNumber,
      },
    });
  };

  return (
    <SignupPage
      currentStep={4}
      isDisabled={false}
      title="About you"
      description={'Let us know how to properly address you!'}
      onBackButtonClick={onBackButtonClick}
      content={
        <Formik
          initialValues={{
            firstName: inputData.firstName,
            lastName: inputData.lastName,
            phoneNumber: inputData.phoneNumber,
          }}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            console.log(values);
            viewModel.handleNextButtonPressed(values);
          }}
        >
          {({handleSubmit, values, isValid, handleChange, handleBlur, setFieldValue, touched, errors}) => (
            <form onSubmit={handleSubmit}>
              <StyledTextField
                fullWidth={true}
                margin="normal"
                variant="outlined"
                label="First Name"
                value={values.firstName}
                name="firstName"
                placeholder="First Name"
                onChange={(event) => setFieldValue('firstName', transformName(event.target.value))}
                onBlur={handleBlur}
                helperText={touched.firstName && errors.firstName}
                error={touched.firstName && Boolean(errors.firstName)}
              />
              <StyledTextField
                fullWidth={true}
                margin="normal"
                variant="outlined"
                value={values.lastName}
                label="Last Name"
                name="lastName"
                placeholder="Last Name"
                onChange={(event) => setFieldValue('lastName', transformName(event.target.value))}
                onBlur={handleBlur}
                helperText={touched.lastName && errors.lastName}
                error={touched.lastName && Boolean(errors.lastName)}
              />
              <PhoneInputField
                value={values.phoneNumber}
                name="phoneNumber"
                setFieldValue={setFieldValue}
                touched={touched}
                errors={errors}
              />
              <NewNextStepButton label="Next" onClick={() => onNextClick(values)} disabled={!isValid}>
                Next
              </NewNextStepButton>
            </form>
          )}
        </Formik>
      }
    />
  );
};
