import React, {useCallback} from 'react';
import 'react-image-crop/dist/ReactCrop.css';
import {useDropzone} from 'react-dropzone';
import {IMAGEFILETYPES} from 'src/constants/fileUpload';
import styled from 'styled-components';

const DropzoneContainer = styled.div<{isPhotoBeenSelected: boolean}>`
  ${(props) =>
    props.isPhotoBeenSelected
      ? `
      cursor: pointer;
      font-weight: bold;
      color: grey;
      &:hover {
        text-decoration: underline;
        color: ${props.theme.greyishBrown};
      }
    `
      : `
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px;
      border-width: 2px;
      border-radius: 2px;
      border-color: #eeeeee;
      border-style: dashed;
      background-color: #fafafa;
      color: lightgrey;
      outline: none;
      transition: border .25s ease-in-out;
    `}
`;

const ImageDropzone = ({onSelectFile, isPhotoBeenSelected, isLoading}) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      onSelectFile(acceptedFiles[0]);
    },
    [onSelectFile],
  );

  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    accept: IMAGEFILETYPES.split(', '),
    onDrop,
    multiple: false,
    disabled: isLoading,
    noDragEventsBubbling: true,
  });

  return (
    <DropzoneContainer isPhotoBeenSelected={isPhotoBeenSelected} {...getRootProps()}>
      <input {...getInputProps()} />
      {isPhotoBeenSelected ? (
        <p>Select a different photo</p>
      ) : isDragActive ? (
        <p>Drop the photo here...</p>
      ) : (
        <p>Drag or select a photo from your computer</p>
      )}
    </DropzoneContainer>
  );
};

export default ImageDropzone;
