import {Button} from '@material-ui/core';
import React, {ReactNode, useState} from 'react';
import {HCLabelOne} from 'src/components/HypercareComponents';
import theme from 'src/styles/theme';
import ChevrDown from 'src/svgs/ChevrDown';
import ChevrUp from 'src/svgs/ChevrUp';
import styled from 'styled-components/macro';
import {Dropdown, DropdownProps} from '../../Dropdown/Dropdown';

export type FormFieldInputDropdownProps<TOption> = Pick<
  DropdownProps<TOption>,
  'options' | 'onOptionClick' | 'renderOptionContent' | 'getOptionId' | 'getIsOptionSelected' | 'onClickAway'
> & {
  value?: TOption;
  renderValue: (value?: TOption) => ReactNode;
  placeholder: string;
  isRequired: boolean;
  disabled: boolean;
  mode: 'view' | 'edit';
  error?: string;
};

const FormFieldInputDropdownContainer = styled.div`
  flex: 1;
  width: 100%;
`;

export function FormFieldInputDropdown<TOption>(props: FormFieldInputDropdownProps<TOption>) {
  const [isOpen, setIsOpen] = useState(false);
  const [anchorElement, setAnchorElement] = useState<HTMLButtonElement | null>(null);

  const endIcon = isOpen ? <ChevrUp color="black" /> : <ChevrDown color="black" />;

  const onDropdownClickAway = () => {
    setIsOpen(false);
    props.onClickAway();
  };

  const onButtonClick = () => {
    setIsOpen(!isOpen);
  };

  const onOptionClick = (o: TOption) => {
    setIsOpen(false);
    props.onOptionClick(o);
  };

  return (
    <FormFieldInputDropdownContainer>
      {props.mode === 'edit' && (
        <>
          <Button
            variant="text"
            disabled={props.disabled}
            style={{textTransform: 'none'}}
            endIcon={endIcon}
            ref={setAnchorElement}
            onClick={onButtonClick}
          >
            {props.value ? (
              <HCLabelOne>{props.renderValue(props.value)}</HCLabelOne>
            ) : (
              <HCLabelOne color={theme.textLightTertiary}>{props.placeholder}</HCLabelOne>
            )}
          </Button>
          <Dropdown
            options={props.options}
            getIsOptionSelected={props.getIsOptionSelected}
            getOptionId={props.getOptionId}
            renderOptionContent={props.renderOptionContent}
            onOptionClick={onOptionClick}
            onClickAway={onDropdownClickAway}
            anchorElement={anchorElement}
            isOpen={isOpen}
          />
        </>
      )}

      {props.mode === 'view' && <HCLabelOne>{props.renderValue(props.value)}</HCLabelOne>}
    </FormFieldInputDropdownContainer>
  );
}
