import * as React from 'react';
import styled from 'styled-components';
import {connect} from 'react-redux';
import Fab from '@material-ui/core/Fab';
import {Redirect} from 'react-router';
import createNewChat from 'src/utils/messengerHelper/createNewChat';
import {toast} from 'react-toastify';
import {StyledKbd} from 'src/styles/styled-components/StyledKBD';
import Tooltip from '@material-ui/core/Tooltip';
import getOperatingSystem from 'src/utils/getOperatingSystem';
import AnalyticsManager, {EVENTS} from 'src/analytics/AnalyticsManager';
import {ActionEvents} from 'src/constants/hotkeyEvents';

const StyledTooltipHolder = styled.div`
  padding: 3px;
`;

const StyledFAB = styled(Fab)`
  position: absolute !important;
  max-width: 300px;
  bottom: 30px;
  right: 30px;
  z-index: 9;
  font-size: 0.8em !important;
  color: white !important;
  background-color: ${(props) => props.theme.watermelon} !important;
  &:disabled {
    background-color: ${(props) => props.theme.disabled} !important;
  }
`;

class CreateNewChatFAB extends React.PureComponent<any> {
  public state = {
    isCreating: false,
    redirectId: '',
  };

  public keysHeldDown = {};
  public currentOS = getOperatingSystem();

  public primaryKey = this.currentOS === 'MacOS' ? 'Meta' : 'Control';
  public secondaryKey = 'Enter';

  public componentDidMount() {
    document.addEventListener('keydown', this.enterTryCreateNewChatKeyListener);
    document.addEventListener('keyup', this.exitTryCreateNewChatKeyListener);
  }
  public componentWillUnmount() {
    document.removeEventListener('keydown', this.enterTryCreateNewChatKeyListener);
    document.removeEventListener('keyup', this.exitTryCreateNewChatKeyListener);
  }
  public render() {
    const {redirectId, isCreating} = this.state;
    const {selectedColleagues} = this.props;

    if (redirectId) {
      return <Redirect to={`/messenger/${redirectId}`} />;
    }
    return (
      <Tooltip
        placement="bottom"
        title={
          <StyledTooltipHolder>
            Start Chat <StyledKbd>{this.currentOS === 'MacOS' ? 'CMD' : 'CTRL'}</StyledKbd>
            <StyledKbd>{this.currentOS === 'MacOS' ? 'RETURN' : 'ENTER'}</StyledKbd>
          </StyledTooltipHolder>
        }
        aria-label="Messages"
      >
        <StyledFAB
          variant="extended"
          onClick={this.tryCreateNewChat}
          disabled={selectedColleagues.size === 0 || isCreating}
        >
          {isCreating ? 'Sending Request...' : 'Start New Chat'}
        </StyledFAB>
      </Tooltip>
    );
  }

  /**
   * Meta key (src: https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/metaKey)
   * On Mac, the meta key is the 'Command/Cmd' Key
   * On Windows, the meta key is the 'Windows' key
   */
  private exitTryCreateNewChatKeyListener = (e: KeyboardEvent) => {
    if (e.key === this.primaryKey || e.key === this.secondaryKey) this.clearKeysHeldDown();
  };

  private enterTryCreateNewChatKeyListener = (e: KeyboardEvent) => {
    const isAnyModalOpen = document.querySelector('.ReactModal__Content');
    const menuDropdownElement = document.getElementById('dropdownMenuList');
    const {isCreating} = this.state;
    const {selectedColleagues} = this.props;

    if (menuDropdownElement || isAnyModalOpen || isCreating || selectedColleagues.size === 0) return;

    if (e.key === this.secondaryKey && this.keysHeldDown[this.primaryKey]) this.keysHeldDown[e.key] = true;
    if (e.key === this.primaryKey) this.keysHeldDown[e.key] = true;

    if (this.keysHeldDown[this.primaryKey] && this.keysHeldDown[this.secondaryKey]) {
      AnalyticsManager.applyAnalytics({
        eventName: EVENTS.hotkeyActivated,
        params: {
          keys: `${this.primaryKey}+${this.secondaryKey}`,
          action: ActionEvents.START_CHAT,
        },
      });
      this.tryCreateNewChat();
    }
  };

  private clearKeysHeldDown = () => {
    this.keysHeldDown = {};
  };

  private tryCreateNewChat = () => {
    this.setState(
      {
        isCreating: true,
      },
      async () => {
        try {
          const {selectedColleagues} = this.props;
          const newChatId = await createNewChat(selectedColleagues);
          if (!newChatId) throw new Error('failed');
          this.setState({
            redirectId: newChatId,
            isCreating: false,
          });
        } catch (e) {
          console.error(e);
          toast.error('Failed to start new chat, please check your internet connection and try again');
          this.setState({
            isCreating: false,
            redirectId: '',
          });
        }
      },
    );
  };
}

const mapStateToProps = (state: any) => {
  return {
    selectedColleagues: state.colleagues.selectedColleagues,
  };
};

export default connect(mapStateToProps)(CreateNewChatFAB);
