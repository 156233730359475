import {
  INVALID_CREDENTIALS_MSG,
  LOCKED_ACCOUNT_MSG,
  LOGIN_ERROR_CODE_INVALID_USER,
  LOGIN_ERROR_MESSAGE_INVALID_USER,
  LOGIN_ERROR_CODE_INVALID_PASSWORD,
  LOGIN_ERROR_MESSAGE_INVALID_PASSWORD,
  LOGIN_ERROR_CODE_ACCOUNT_LOCKED,
  LOGIN_ERROR_MESSAGE_ACCOUNT_LOCKED,
  LOGIN_ERROR_CODE_INVALID_CHALLENGE,
  LOGIN_ERROR_MESSAGE_INVALID_CHALLENGE,
} from 'src/constants/login';
import {HypercareLoginRepositoryProps} from 'src/pages/LoginPage/HypercareLoginRepository';
import {LAST_ACTIVE_TIME} from 'src/constants/sessionTimeout';

class HypercareLoginPasswordViewModel {
  login;
  repository: HypercareLoginRepositoryProps;

  constructor(repository, login) {
    this.repository = repository;
    this.login = login;
  }

  handleNextButtonPressed = async (username: string, password: string) => {
    window.localStorage.removeItem(LAST_ACTIVE_TIME);
    const result = await this.login(username, password);
    const error = result?.error?.code ?? result.error;
    if (error) {
      switch (error) {
        case LOGIN_ERROR_CODE_INVALID_USER:
          return {
            error: {
              message: LOGIN_ERROR_MESSAGE_INVALID_USER,
              code: LOGIN_ERROR_CODE_INVALID_USER,
            },
          };
        case LOGIN_ERROR_CODE_INVALID_PASSWORD:
          return {
            error: {
              message: LOGIN_ERROR_MESSAGE_INVALID_PASSWORD,
              code: LOGIN_ERROR_CODE_INVALID_PASSWORD,
            },
          };
        case LOGIN_ERROR_CODE_ACCOUNT_LOCKED:
          return {
            error: {
              message: LOGIN_ERROR_MESSAGE_ACCOUNT_LOCKED,
              code: LOGIN_ERROR_CODE_ACCOUNT_LOCKED,
            },
          };
        case LOGIN_ERROR_CODE_INVALID_CHALLENGE:
          return {
            error: {
              message: LOGIN_ERROR_MESSAGE_INVALID_CHALLENGE,
              code: LOGIN_ERROR_CODE_INVALID_CHALLENGE,
            },
          };
        default:
          return {
            error: {
              message: 'Network error, please try again',
              code: 'DefaultError',
            },
          };
      }
    }
  };
}

export default HypercareLoginPasswordViewModel;
