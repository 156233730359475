import {HCLabelOne, HCLabelThree} from '../../../../components/HypercareComponents';
import theme from '../../../../styles/theme';
import {
  LOGIN_PAGE_CANT_FIND_ORG_TEXT,
  LOGIN_PAGE_CANT_FIND_ORG_TOOLTIP_TEXT,
  LOGIN_PAGE_ENTER_ORG_URL_TEXT,
  LOGIN_PAGE_SEARCH_BY_ORG_NAME_TEXT,
  LOGIN_PAGE_SIGNUP_SUB_TEXT,
  LOGIN_PAGE_SIGNUP_TEXT,
  LOGIN_PAGE_UNKNOWN_HYPERCARE_URL,
  LOGIN_PAGE_VISIT_SUPPORT_CENTER,
} from '../../../../constants/strings';
import {DescriptionColumn, PreferenceCellWrapper, PreferencesCellIconWrapper} from '../../styled/login.styled';
import {StyledTooltipBlack} from '../../../../components/StyledTooltip';
import {BlackInfoIcon} from '../../../../svgs/BlackInfoIcon';
import {LoginContainer} from '../../../../microfrontend/styled/login.styled';
import {SavedAccountActionButtons} from '../../../../microfrontend/login/SavedAccountOrganizationRowItems';
import SearchIcon from '../../../../svgs/SearchIcon';
import {LinkIcon} from '../../../../microfrontend/svgs/LinkIcon';
import {HospitalIcon} from '../../../../microfrontend/svgs/HospitalIcon';
import {ExternalLinkIcon} from '../../../../microfrontend/svgs/ExternalLinkIcon';
import React from 'react';

export interface LoginPageFooterActionsProps {
  showOrgUrlView: boolean;
  setShowOrgUrlView: (show: boolean) => void;
}

export const LoginPageFooterActions = ({showOrgUrlView, setShowOrgUrlView}: LoginPageFooterActionsProps) => (
  <div>
    {showOrgUrlView ? (
      <HCLabelOne color={theme.text} fontWeight={600}>
        {LOGIN_PAGE_UNKNOWN_HYPERCARE_URL}
      </HCLabelOne>
    ) : (
      <PreferenceCellWrapper>
        <HCLabelOne color={theme.text} fontWeight={600}>
          {LOGIN_PAGE_CANT_FIND_ORG_TEXT}
        </HCLabelOne>
        <StyledTooltipBlack
          arrow
          title={LOGIN_PAGE_CANT_FIND_ORG_TOOLTIP_TEXT}
          className="styledPopper"
          placement="top"
        >
          <PreferencesCellIconWrapper>
            <BlackInfoIcon />
          </PreferencesCellIconWrapper>
        </StyledTooltipBlack>
      </PreferenceCellWrapper>
    )}
    <LoginContainer width={'550px'}>
      {showOrgUrlView ? (
        <SavedAccountActionButtons
          onIconClick={() => setShowOrgUrlView(!showOrgUrlView)}
          icon={<SearchIcon />}
          description={
            <HCLabelOne color={theme.text} lineHeight="24px">
              {LOGIN_PAGE_SEARCH_BY_ORG_NAME_TEXT}
            </HCLabelOne>
          }
        />
      ) : (
        <SavedAccountActionButtons
          onIconClick={() => setShowOrgUrlView(true)}
          icon={<LinkIcon />}
          description={
            <HCLabelOne color={theme.text} lineHeight="24px">
              {LOGIN_PAGE_ENTER_ORG_URL_TEXT}
            </HCLabelOne>
          }
        />
      )}

      <SavedAccountActionButtons
        onIconClick={() => setShowOrgUrlView(true)}
        icon={<HospitalIcon />}
        description={
          <DescriptionColumn>
            <HCLabelOne color={theme.text} lineHeight="24px">
              {LOGIN_PAGE_SIGNUP_TEXT}
            </HCLabelOne>
            <HCLabelThree color={theme.text}>{LOGIN_PAGE_SIGNUP_SUB_TEXT}</HCLabelThree>
          </DescriptionColumn>
        }
      />
      <SavedAccountActionButtons
        onIconClick={() => setShowOrgUrlView(true)}
        icon={<ExternalLinkIcon />}
        description={
          <HCLabelOne color={theme.text} lineHeight="24px">
            {LOGIN_PAGE_VISIT_SUPPORT_CENTER}
          </HCLabelOne>
        }
      />
    </LoginContainer>
  </div>
);
