import React, {PureComponent} from 'react';
import styled from 'styled-components';
import ProfilePic from 'src/components/ProfilePic';
import {User} from 'src/types';
import {Layout, UserDescription, UserName, UserRole} from 'src/pages/MessengerPage/messenger/StyledUserList';

export const StyledLayout = styled(Layout)`
  max-width: 300px;
`;

interface UserListItemV2Props {
  colleague: User;
  onClick?: (colleague: User) => void;
}

const UserListItemV2 = ({colleague, onClick}: UserListItemV2Props) => {
  const handleClick = () => {
    if (onClick) {
      onClick(colleague);
    }
  };

  return colleague ? (
    <StyledLayout onClick={handleClick}>
      <ProfilePic users={[colleague.data]} />
      <UserDescription>
        <UserName>{colleague.data.label}</UserName>
        <UserRole>{colleague.data.role}</UserRole>
      </UserDescription>
    </StyledLayout>
  ) : null;
};

export default UserListItemV2;
