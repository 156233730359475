import React from 'react';
import {Dialog, DialogContent, makeStyles, Divider, Typography, Grid} from '@material-ui/core';

import {
  SecondaryButton,
  PrimaryButton,
  StyledDialogActions,
} from 'src/styles/styled-components/StyledMaterialComponents';
import {MaterialDialogHeader, Transition} from 'src/components/HypercareComponents';

enum AlertModalButtonTypes {
  Primary = 'primary',
  Secondary = 'secondary',
  LeftOutlined = 'leftOutlined',
}

const useStyles = makeStyles((_) => ({
  alertTitle: {
    marginBottom: '24px',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
  },
  paperWidthMd: {
    maxWidth: '50vw',
  },
}));

interface AlertModalButton {
  buttonLabel: string;
  type: string;
  onClickHandler: (args?) => void;
  disabled?: boolean;
  id?: string;
  color?: string;
}

interface AlertModalProps {
  title: string;
  subtitle?: string;
  closeAlertModal?: (arg: boolean) => void;
  isAlertModalVisible: boolean;
  showCloseButton?: boolean;
  alertModalButtons: Array<AlertModalButton>;
  width?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  titleFontSize?: string;
  modalContent?: any;
  id?: string;
  titleColor?: string;
}

const DialogActionsView = ({alertModalButtons}) => {
  const modalButtonsLength = alertModalButtons.length;
  return (
    <React.Fragment>
      {alertModalButtons.map((button, index) => {
        const {type, buttonLabel, onClickHandler, disabled, id, color} = button;
        if (type === AlertModalButtonTypes.Secondary)
          return (
            <SecondaryButton
              key={index}
              style={{marginRight: modalButtonsLength > 2 ? 8 : 0}}
              onClick={onClickHandler}
              disabled={disabled}
              data-testid={id}
              color={color}
            >
              {buttonLabel}
            </SecondaryButton>
          );
        else if (type === AlertModalButtonTypes.Primary)
          return (
            <PrimaryButton key={index} onClick={onClickHandler} disabled={disabled} data-testid={id}>
              {buttonLabel}
            </PrimaryButton>
          );
        else return null;
      })}
    </React.Fragment>
  );
};

const CustomDialogActionsView = ({alertModalButtons}) => {
  let alertModalLeftButton = alertModalButtons.find((button) => button.type === AlertModalButtonTypes.LeftOutlined),
    {onClickHandler, buttonLabel, type, disabled = false} = alertModalLeftButton;
  return (
    <Grid container justifyContent={'space-between'}>
      <Grid item>
        <SecondaryButton key={type} onClick={onClickHandler} disabled={disabled}>
          {buttonLabel}
        </SecondaryButton>
      </Grid>
      <Grid item>
        <DialogActionsView alertModalButtons={alertModalButtons} />
      </Grid>
    </Grid>
  );
};

/**
 * Reusable Alert modal, works for confirmation modals, alert dialogs
 * @param title Title of the Alert Modal
 * @param subtitle Subtitle of the Alert Modal
 * @param closeAlertModal {(boolean) => void} Reference to a function to call for closing the alert modal
 * @param isAlertModalVisible Sets the dialog open or close
 * @param alertModalButtons [AlertModalButton] Provide an array of objects to inflate two material ui buttons primary and secondary
 * @param showCloseButton
 * @constructor
 */
const AlertModal = ({
  title,
  subtitle,
  closeAlertModal,
  isAlertModalVisible,
  alertModalButtons,
  width,
  titleFontSize,
  showCloseButton = true,
  modalContent,
  id,
  titleColor,
}: AlertModalProps) => {
  const classes = useStyles({});
  return (
    <Dialog
      fullWidth={true}
      open={isAlertModalVisible}
      TransitionComponent={Transition}
      classes={{paperWidthMd: classes.paperWidthMd}}
      onClose={closeAlertModal ? () => closeAlertModal(false) : null}
      maxWidth={width ? width : alertModalButtons.length > 2 ? 'md' : 'xs'}
      data-testid={id}
    >
      <MaterialDialogHeader
        titleFontSize={titleFontSize}
        titleColor={titleColor}
        showCloseIconButton={showCloseButton}
        dialogTitle={title}
        closeIconButtonClickHandler={() => closeAlertModal(false)}
      />
      <DialogContent>
        {modalContent ? modalContent : <Typography classes={{root: classes.alertTitle}}>{subtitle}</Typography>}
      </DialogContent>
      <Divider />
      <StyledDialogActions>
        {alertModalButtons.length > 2 ? (
          <CustomDialogActionsView alertModalButtons={alertModalButtons} />
        ) : (
          <DialogActionsView alertModalButtons={alertModalButtons} />
        )}
      </StyledDialogActions>
    </Dialog>
  );
};

export default AlertModal;
