import React, {useState} from 'react';
import {Formik} from 'formik';
import * as yup from 'yup';
import {emailValidationSchema} from 'src/utils/validations';
import {IADFSOrganization} from 'src/types';
import {ADDRESS_IN_USE} from 'src/constants/networkError';
import {makeStyles, Theme, Typography} from '@material-ui/core';
import {Link} from 'react-router-dom';
import localTheme from 'src/styles/theme';
import AnalyticsManager, {EVENTS, PAGE_VIEWS} from 'src/analytics/AnalyticsManager';

import {StyledTextField} from 'src/styles/styled-components/StyledInputField';
import NewNextStepButton from 'src/pages/newSignupFlow/components/NextStepButton';
import {StepContainerCopy} from '../components/step-container-copy';
import NewStepHeader from '../components/step-header-copy';
import NewStepFooter from '../components/step-footer-copy';

const useStyles = makeStyles((theme: Theme) => ({
  recoveryHelpText: {
    marginTop: 16,
    fontFamily: "'Nunito Sans', sans-serif",
    fontWeight: 400,
    fontSize: 16,
    color: '#4A4A4A',
    '& a': {
      color: localTheme.primary,
    },
  },
}));

const validationSchema = yup.object().shape<ISignupEmailStepOutputData>({
  email: emailValidationSchema,
});

export interface ISignupEmailStepOutputData {
  email: string;
  adfsOrganization?: IADFSOrganization;
}

export const HypercareSignupEmailView = ({email, onBackButtonClick, viewModel}) => {
  const classes = useStyles();
  const [showRecoveryMessage, setShowRecoveryMessage] = useState(false);

  React.useEffect(() => {
    AnalyticsManager.recordPageVisited(PAGE_VIEWS.signupRequestAddressVerification);
  }, []);

  const onNextClick = (email: string) => {
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.signupEmailNextPressed,
      params: {
        email,
      },
    });
  };

  const onLoginInstead = (email: string) => {
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.signUpExistLoginInstead,
      params: {
        email,
      },
    });
  };

  return (
    <StepContainerCopy currentStep={1}>
      <NewStepHeader
        isDisabled={false}
        title="Step 1 of 5: Enter your email address"
        description="We highly recommend registering with your organizations credentials or work email for faster and safer verification."
        onBackButtonClick={onBackButtonClick}
      />
      <Formik
        initialValues={{email}}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={async (values, actions) => {
          const result = await viewModel.handleNextButtonPressed(values.email);
          if (result?.error) {
            if (result.error.code === ADDRESS_IN_USE) setShowRecoveryMessage(true);
            actions.setFieldError('email', result.error.message);
          }
        }}
      >
        {({handleSubmit, values, isValid, handleChange, handleBlur, touched, errors, isSubmitting}) => (
          <form onSubmit={handleSubmit}>
            <StyledTextField
              variant="outlined"
              fullWidth={true}
              margin="normal"
              disabled={isSubmitting}
              autoFocus={false}
              value={values.email}
              name="email"
              label="Email"
              placeholder="you@acme-hospital.com"
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.email && errors.email}
              error={touched.email && Boolean(errors.email)}
            />
            {showRecoveryMessage && (
              <Typography className={classes.recoveryHelpText}>
                Looks like there is already an account associated with this email, would you like to&nbsp;
                <Link
                  onClick={() => onLoginInstead(values.email)}
                  to="/login"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  login instead
                </Link>
                ?
              </Typography>
            )}
            <NewNextStepButton
              label={'Next'}
              onClick={() => onNextClick(values.email)}
              disabled={isSubmitting}
              loading={isSubmitting}
              loadingLabel="Verifying availability..."
            />
          </form>
        )}
      </Formik>
      <NewStepFooter />
    </StepContainerCopy>
  );
};
