import React from 'react';
import {ScrollStyleModal, CloseBtn, Header} from 'src/styles/styled-components/ModalStyleComponents';
import styled from 'styled-components';
import AppTheme from 'src/styles/theme';
import {ThemeProvider} from '@material-ui/styles';
import {createTheme} from '@material-ui/core/styles';
import {LocatingEscalationPreviewStep, LocatingEscalationSelectionStep} from './steps';
import {DepartmentScope, EscalationLadder, SiteScope} from 'src/types';
import {MessageWrapper, MessageWrapperText, MessageWrapperTitle} from 'src/styles/styled-components/Escalation';

const StyledHeader = styled(Header)`
  font-size: 24px;
`;

const escalationTheme = createTheme({
  palette: {
    primary: {main: AppTheme.chatTeal},
    secondary: {main: AppTheme.redPink},
  },
});

interface Props {
  isOpen: boolean;
  sites: SiteScope[];
  currentSite: SiteScope;
  closeModal: () => void;
}

const LocatingEscalationModal = ({isOpen, closeModal, sites, currentSite}: Props) => {
  const [message, setMessage] = React.useState('');
  const [selectedSite, setSelectedSite] = React.useState<SiteScope>(currentSite);
  const [selectedDepartment, setSelectedDepartment] = React.useState<DepartmentScope>(
    currentSite?.departments
      ? currentSite.departments.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))[0]
      : null,
  );
  const [selectedEscalationLadder, setSelectedEscalationLadder] = React.useState<EscalationLadder>(null);

  const [currentStep, setCurrentStep] = React.useState<number>(1);

  const handlePrev = () => setCurrentStep((prev) => prev - 1);
  const handleNext = () => setCurrentStep((prev) => prev + 1);

  return (
    <ThemeProvider theme={escalationTheme}>
      <ScrollStyleModal
        isOpen={isOpen}
        shouldCloseOnEsc={false}
        ariaHideApp={false}
        shouldCloseOnOverlayClick={false}
        onRequestClose={closeModal}
      >
        <StyledHeader>
          <span>Start a departmental escalation</span>
          <CloseBtn onClick={closeModal} />
        </StyledHeader>
        {currentStep === 1 && (
          <LocatingEscalationSelectionStep
            selectedSite={selectedSite}
            setSelectedSite={setSelectedSite}
            siteSelectionList={sites}
            selectedDepartment={selectedDepartment}
            setSelectedDepartment={setSelectedDepartment}
            message={message}
            setMessage={setMessage}
            selectedEscalationLadder={selectedEscalationLadder}
            setSelectedEscalationLadder={setSelectedEscalationLadder}
            handleNext={handleNext}
            closeModal={closeModal}
          />
        )}
        {currentStep === 2 && (
          <React.Fragment>
            <MessageWrapper>
              <MessageWrapperTitle>Escalating this message</MessageWrapperTitle>
              <MessageWrapperText>{message}</MessageWrapperText>
            </MessageWrapper>
            <LocatingEscalationPreviewStep
              message={message}
              selectedEscalationLadder={selectedEscalationLadder}
              handlePrev={handlePrev}
              closeModal={closeModal}
            />
          </React.Fragment>
        )}
      </ScrollStyleModal>
    </ThemeProvider>
  );
};

export default LocatingEscalationModal;
