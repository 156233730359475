import {User} from 'src/types';

export function getInitials(user: User): string {
  if (!user) {
    return 'N/A';
  }
  const userFirstName = (user.firstName || user.firstname || '')[0] || '';
  const userLastName = (user.lastName || user.lastname || '')[0] || '';

  return `${userFirstName}${userLastName}`;
}
export function getUpperCaseInitials(user: User): string {
  if (!user) {
    return 'N/A';
  }

  const userFirstname = (user.firstName || user.firstname || '')[0] || '';
  const userLastname = (user.lastName || user.lastname || '')[0] || '';

  if (!userFirstname && !userLastname) {
    return 'N/A';
  }

  return `${userFirstname?.toUpperCase()}${userLastname?.toUpperCase()}`;
}

export function getFullName(user: User): string {
  if (!user) return '';
  const userFirstName = user.firstName || user.firstname || '';
  const userLastName = user.lastName || user.lastname || '';

  return `${userFirstName} ${userLastName}`;
}

export function sortUsersByLastName(user1: User, user2: User) {
  return (user1?.lastname || '') >= (user2?.lastname || '') ? 1 : -1;
}
