import * as React from 'react';
import {Role} from 'src/types';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import styled from 'styled-components';
import {typedUseSelector, AppDispatch} from 'src/redux';
import {useDispatch} from 'react-redux';
import FilterPreferredRolesListItem from 'src/pages/SchedulingPage/action-calendar/filter-section/FilterPreferredRolesListItem';
import {IsFeatureFlagEnabled} from 'src/utils/FeatureFlagManager';
import {useEffect} from 'react';
import HypercareSchedulingViewModel from 'src/pages/LocatingPage/viewModels/HypercareSchedulingViewModel';

const StyledFormGroup = styled(FormGroup)`
  user-select: none;
  color: ${(props) => props.theme.greyishBrown};
`;

interface Props {
  roles: Role[];
  departmentId?: number;
  scheduleId?: number;
}

const PreferredRolesList = ({roles, departmentId, scheduleId}: Props) => {
  const filteringRoles = typedUseSelector((state) => state.tradeShift.filterByRoles);
  const roleHiddenNotes = IsFeatureFlagEnabled('roleHiddenNotes');

  React.useEffect(() => {
    // TODO: switch month should remmeber the previous role selection
    handleSetAllRole();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatch: AppDispatch = useDispatch();
  const handleSetRole = (role: Role) => dispatch({type: 'tradeShift/SET_ROLE', payload: {role}});
  const handleClearRole = (role: Role) => dispatch({type: 'tradeShift/CLEAR_ROLE', payload: {role}});
  const handleSetAllRole = () => dispatch({type: 'tradeShift/SET_ALL_ROLE', payload: {roles}});
  const handleClearAllRole = () => dispatch({type: 'tradeShift/CLEAR_ALL_ROLE'});

  const {getRoleNotesForDepartment} = HypercareSchedulingViewModel();

  const handleToggleAll = () => {
    if (allChecked) {
      handleClearAllRole();
    } else {
      handleSetAllRole();
    }
  };

  const handleChange = (role: Role) => {
    if (isChecked(role)) {
      handleClearRole(role);
    } else {
      handleSetRole(role);
    }
  };

  const allChecked = roles && roles.length === filteringRoles.length;
  const isChecked = (role: Role) => Boolean(filteringRoles.find((filteredRole) => filteredRole.roleId === role.roleId));
  const atLeastOneSelected = filteringRoles.length >= 1;

  useEffect(() => {
    const getData = async () => {
      await getRoleNotesForDepartment(scheduleId, departmentId);
    };
    getData();
  }, []);

  return (
    <StyledFormGroup>
      <FormControlLabel
        control={
          <Checkbox
            color="primary"
            indeterminate={!allChecked && atLeastOneSelected}
            checked={allChecked || atLeastOneSelected}
            name={'All'}
            onChange={handleToggleAll}
          />
        }
        label={'All'}
      />
      {roles.map((role, index) =>
        !roleHiddenNotes ? (
          <FormControlLabel
            key={`${role.roleId}-${index}`}
            id={`${role.roleId}`}
            control={
              <Checkbox
                color="primary"
                checked={isChecked(role)}
                onChange={() => handleChange(role)}
                name={role.roleName}
              />
            }
            label={role.roleName}
          />
        ) : (
          <FilterPreferredRolesListItem
            key={`${role.roleId}-${index}`}
            isChecked={isChecked}
            handleChange={handleChange}
            role={role}
          />
        ),
      )}
    </StyledFormGroup>
  );
};

export default PreferredRolesList;
