import React, {Component} from 'react';
import styled from 'styled-components';

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px;
`;

const StyledH1 = styled.h1`
  font-family: 'Open Sans', sans-serif;
  margin-bottom: 1em;
`;

interface Props {
  error: Error;
}

class GenericError extends Component<Props> {
  public render() {
    return (
      <ErrorContainer>
        <StyledH1>
          Sorry, something went wrong{' '}
          <span role="img" aria-label="dizzy">
            😵
          </span>
        </StyledH1>
        <StyledH1>Please check your internet connection with correct URL and try again.</StyledH1>
      </ErrorContainer>
    );
  }
}

export default GenericError;
