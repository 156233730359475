import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import useRefetchContactList from './hooks/useFetchContactList';
import {SmallLoader} from 'src/components/LoadingDiv';
import styled from 'styled-components';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Chevron from 'src/svgs/Chevron';
import Typography from '@material-ui/core/Typography';
import ContactList from 'src/pages/ContactsPage/ContactListSection/ContactList';

const ContactsListWrapper = styled.div`
  display: contents;
  max-height: 100%;
`;

const ContactListSummary = styled.span`
  font-weight: bold;
  font-family: Nunito;
`;

const NoResult = styled.p`
  margin-top: 1em;
  font-size: 16px;
  color: ${(props) => props.theme.greyishBrown};
`;

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  expandWrapper: {
    boxShadow: 'none',
    margin: '0 !important',
  },
  summaryHeaderWrapper: {
    position: 'sticky',
    top: 0,
    padding: '4px 0.5em',
    zIndex: 1,
    backgroundColor: 'white',
  },
}));

interface ContactListWrapperProps {
  searchKeyword: string;
}
const ContactListWrapper = ({searchKeyword}: ContactListWrapperProps) => {
  const classes = useStyles({});

  const {loading, error, contactResultList} = useRefetchContactList({
    searchKeyword,
  });

  if (loading || error || !contactResultList) return <SmallLoader />;

  const noSearchResult = contactResultList.length === 0 && Boolean(searchKeyword);

  return (
    <ContactsListWrapper className={classes.root}>
      <ExpansionPanel defaultExpanded={true} className={classes.expandWrapper} TransitionProps={{timeout: 300}}>
        <ExpansionPanelSummary
          className={classes.summaryHeaderWrapper}
          expandIcon={<Chevron />}
          aria-controls="invite-summary"
        >
          <Typography className={classes.heading}>
            <ContactListSummary>All Contacts ({contactResultList.length})</ContactListSummary>
          </Typography>
        </ExpansionPanelSummary>
        {noSearchResult ? (
          <NoResult>No results found</NoResult>
        ) : (
          <ExpansionPanelDetails style={{padding: 0}}>
            <ContactList contactList={contactResultList} />
          </ExpansionPanelDetails>
        )}
      </ExpansionPanel>
    </ContactsListWrapper>
  );
};

export default ContactListWrapper;
