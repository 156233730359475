import styled from 'styled-components';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

export const SelectionLabel = styled.div`
  font-size: 14px;
  line-height: 22px;
  color: ${(props) => props.theme.greyishBrown};
`;

export const SelectionFlexContainer = styled.div`
  display: flex;
  max-width: min-content;
  align-items: center;
  margin-bottom: 4px;
  height: auto;
  position: relative;
  white-space: nowrap;
  svg {
    margin-left: 0.5em;
  }
`;

export const StyledSelectionMenuItem = styled(MenuItem)`
  min-height: 36px;
  &.Mui-selected {
    color: ${(props) => props.theme.chatTeal} !important;
    background-color: transparent !important;
    font-weight: bold !important;
  }
`;

export const SelectionWrapper = styled.div`
  margin-bottom: 1em;
`;

interface FormControlProps {
  $isDisabled: boolean;
  $shouldHighlight: boolean;
}

export const StyledFormControl = styled(FormControl)<FormControlProps>`
  .MuiSelect-root {
    background: ${(props) => (props.$isDisabled ? 'whitesmoke' : 'unset')};
    color: ${(props) => (props.$shouldHighlight ? props.theme.greyishBrown : 'auto')};
    background: ${(props) => (props.$shouldHighlight ? 'transparent' : 'auto')};
    &[aria-expanded='true'] ~ svg {
      transform: rotate(180deg);
    }
    &[aria-expanded='true'] {
      border-color: ${(props) => props.theme.chatTeal};
    }
  }
`;
