import React from 'react';
import {ApolloQueryResult} from 'apollo-client';
import {FetchMyCalendarShiftsResponse} from 'src/types';

export type MycalendarShiftsRefetchRequestType = (
  variables?: Record<string, any>,
) => Promise<ApolloQueryResult<FetchMyCalendarShiftsResponse>>;

export interface MycalendarRefetchContextInterface {
  refetch: MycalendarShiftsRefetchRequestType;
}

export const MycalendarRefetchContext = React.createContext<MycalendarRefetchContextInterface>({
  refetch: null,
});

const MycalendarRefetchProvider = (props: {
  refetch: MycalendarShiftsRefetchRequestType;
  children: React.ReactElement;
}) => {
  const {refetch, children} = props;

  return <MycalendarRefetchContext.Provider value={{refetch}}>{children}</MycalendarRefetchContext.Provider>;
};

export default MycalendarRefetchProvider;
