import * as React from 'react';
import styled from 'styled-components';
import {Option, DisabledOption} from 'src/styles/styled-components/PopoverMenuComponents';
import {WorkStatus} from 'src/types';
import {ON_SHIFT, BUSY, UNAVAILABLE} from 'src/constants/workStatus';

const StatusDot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
  margin-left: 10px;
`;

const UnavailableStatusDot = styled(StatusDot)`
  background-color: ${(props) => props.theme.greyishBrown};
`;

const AvailableStatusDot = styled(StatusDot)`
  background-color: ${(props) => props.theme.appleGreen};
`;

const BusyStatusDot = styled(StatusDot)`
  background-color: ${(props) => props.theme.watermelon};
`;

interface Props {
  openChangeStatusModal: (status: WorkStatus) => void;
  clickedOutside: () => void;
}

class ChangeStatusOptions extends React.PureComponent<Props> {
  public render() {
    return (
      // tslint:disable jsx-no-lambda
      <React.Fragment>
        <DisabledOption>Set yourself as...</DisabledOption>
        <Option onClick={() => this.handleChangeUserStatus(ON_SHIFT)}>
          <AvailableStatusDot />
          Available
        </Option>
        <Option onClick={() => this.handleChangeUserStatus(BUSY)}>
          <BusyStatusDot />
          Busy
        </Option>
        <Option onClick={() => this.handleChangeUserStatus(UNAVAILABLE)}>
          <UnavailableStatusDot />
          Unavailable
        </Option>
      </React.Fragment>
    );
  }

  private handleChangeUserStatus(status: WorkStatus) {
    this.props.clickedOutside();
    this.props.openChangeStatusModal(status);
  }
}

export default ChangeStatusOptions;
