import React from 'react';
import styled from 'styled-components';
import {Message} from 'src/types';
import {CONSULT, IMAGE, ATTACHMENT, TEMPLATE} from 'src/constants/messageTypes';
import DefaultFileIcon from 'src/svgs/DefaultFileIcon';
import FileErrorIcon from 'src/svgs/FileErrorIcon';
import mapMimeTypeToIconName from 'src/utils/mapMimeTypeToIconName';
import ImageIcon from 'src/svgs/ImageSending';
import isValidImageAttachment from 'src/utils/messengerHelper/isValidImageAttachment';
import setDownloadAttachment from 'src/utils/messengerHelper/setDownloadAttachment';

const StyledIcon = styled.img`
  padding-left: 2px;
  width: 18px;
  height: 18px;
  margin-top: -20px;
  margin-left: 20px;
`;

const MessageText = styled.div`
  display: -webkit-box;
  overflow-wrap: anywhere;
  line-break: strict;
  line-height: 1.3em;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const IconNameContainer = styled.div<{isAttachment?: boolean}>`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.greyishBrown};
  ${(props) =>
    props.isAttachment &&
    `
    width: 100%;
    justify-content: space-between;
  `}
`;

const FileName = styled.a`
  color: black;
  cursor: pointer;
  text-decoration: underline;
  word-break: break-all;
  font-size: 18px;
`;

const FileIcon = styled(StyledIcon)``;

const ConsultIcon = styled(StyledIcon)`
  filter: invert(1);
`;

const ConsultName = styled.div`
  cursor: pointer;
  padding-left: 0.5em;
  text-transform: capitalize;
`;

interface Props {
  chatId: string;
  message: Message;
}

const ReplyMessagePreviewContent = ({chatId, message}: Props) => {
  const RenderImagePreview = () => {
    return (
      <IconNameContainer>
        <ImageIcon color={'black'} />
        Photo
      </IconNameContainer>
    );
  };

  const onFileClick = () => {
    if (message.attachment?.fileName) {
      setDownloadAttachment({
        chatId,
        messageId: message.id,
      });
    }
  };

  if (message.type === IMAGE && (message.image || message.attachment)) {
    return RenderImagePreview();
  }

  if (message.type === CONSULT) {
    return (
      <IconNameContainer isAttachment={true}>
        <ConsultName>consult</ConsultName>
        <ConsultIcon src="/assets/consult-notactive.svg" alt="consult message icon" />
      </IconNameContainer>
    );
  }

  if (message.type === TEMPLATE) {
    const templateRequestName = `${message?.template?.formTitle} ${
      !!message?.template?.requestTitle ? `- ${message?.template?.requestTitle}` : ''
    }`;
    return <div>{templateRequestName}</div>;
  }

  if (message.type === ATTACHMENT) {
    if (isValidImageAttachment(message)) {
      return RenderImagePreview();
    } else {
      return (
        <IconNameContainer isAttachment={true}>
          <FileName onClick={onFileClick} rel="noopener noreferrer">
            {message.attachment?.fileName || 'Attachment not found.'}
          </FileName>
          &nbsp;
          {message.attachment && Boolean(mapMimeTypeToIconName[message.attachment.mimeType]) ? (
            <FileIcon alt="file_type_icon" src={`/assets/${mapMimeTypeToIconName[message.attachment.mimeType]}.svg`} />
          ) : message.attachment ? (
            <DefaultFileIcon />
          ) : (
            <FileErrorIcon />
          )}
        </IconNameContainer>
      );
    }
  }

  return <MessageText>{message.message}</MessageText>;
};

export default ReplyMessagePreviewContent;
