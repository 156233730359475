import React from 'react';
import styled from 'styled-components';
import {QueryResult, useQuery} from 'react-apollo';
import LoadingDiv from 'src/components/LoadingDiv';
import FetchAllActiveEscalations from 'src/gql/query/FetchAllActiveEscalations';

import EscalationLadderPreviewCard from './views/EscalationLadderPreviewCard';
import {GetAllActiveEscalationsResult, UserOrganizationSwitcherPayload} from 'src/types';

const EscalationCardListHolder = styled.div`
  overflow-y: auto;
`;

interface Props {
  setTargetEscalationID: React.Dispatch<React.SetStateAction<number>>;
  handleViewChat: (chatId: string, organizationPayload: UserOrganizationSwitcherPayload) => void;
}

const EscalationLadderPreview = ({setTargetEscalationID, handleViewChat}: Props) => {
  const {loading, error, data}: QueryResult<GetAllActiveEscalationsResult> = useQuery(FetchAllActiveEscalations, {
    fetchPolicy: 'cache-and-network',
  });

  if (loading || error || !data.me) return <LoadingDiv />;

  const activeEscalations = data.me.activeEscalations.filter((activeEscalation) => activeEscalation.state === 'active');

  return (
    <EscalationCardListHolder>
      {activeEscalations.map((activeEscalation) => {
        const {
          escalationLadder: {
            organization: {id: organizationId, name: organizationName},
          },
        } = activeEscalation;

        const userOrganizationSwitcherPayload: UserOrganizationSwitcherPayload = {
          organizationId,
          name: organizationName,
          image: null,
          type: 'Organization',
        };

        const setEscalationID = () => setTargetEscalationID(activeEscalation.id);
        const setViewChat = () => handleViewChat(activeEscalation.chat.id, userOrganizationSwitcherPayload);

        return (
          <EscalationLadderPreviewCard
            key={activeEscalation.id}
            activeEscalation={activeEscalation}
            setTargetEscalationID={setEscalationID}
            handleViewChat={setViewChat}
          />
        );
      })}
    </EscalationCardListHolder>
  );
};

export default EscalationLadderPreview;
