import React, {useState} from 'react';
import AddButton from 'src/svgs/AddButton';
import {User} from 'src/types';
import styled from 'styled-components';
import NoteCard from 'src/pages/LocatingPage/locating/profile-section/notes-section/NoteCard';
import LoadingDiv from 'src/components/LoadingDiv';
import store from 'src/redux';
import InfoIcon from 'src/svgs/InfoIcon';
import appTheme from 'src/styles/theme';
import AnalyticsManager, {EVENTS} from 'src/analytics/AnalyticsManager';
import HiddenNotesViewModel from './HiddenNotesViewModel';
import {ADD_NOTE, HN_NETWORK_ERROR, NOTES, NO_NOTES_FOUND} from 'src/constants/hiddenNotes';
import {HypercareUI} from 'src/components/@hc-ui';
import {PROFILE_PAGE_EMPTY_NOTES_SUBTITLE, PROFILE_PAGE_EMPTY_NOTES_TITLE} from 'src/constants/strings';
import {HCTextContextTwo} from 'src/components/HypercareComponents';
import {HiddenNotes} from 'src/types/HiddenNotes';

const MAXNOTES = 25;

const Wrapper = styled.div`
  margin-top: 40px;
  padding-bottom: 22px;
  position: relative;
`;

const DisabledOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  z-index: 1;
`;

const NotesWrapper = styled.div`
  width: 100%;
  height: 18px;
  left: 504px;
  top: 516px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
`;

const AddNotesWrapper = styled.div<{disabled?: boolean}>`
  width: fit-content;
  height: 24px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-top: 11px;
  display: flex;
  gap: 20px;
  margin-bottom: 12px;
  ${(props) => (props.disabled ? `cursor: no-drop` : `cursor: pointer`)};
  padding: 4px;
`;

const EmptyAddNotesWrapper = styled.div`
  width: 100%;
  height: 24px;
  flex: none;
  order: 0;
  flex-grow: 0;
  display: flex;
  gap: 20px;
  cursor: pointer;
  margin-top: 16px;
`;

const AddNotes = styled.div<{disabled?: boolean}>`
  width: 100%;
  height: 24px;
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  ${(props) => (props?.disabled ? `color:${appTheme.silvergrey}` : `color:${appTheme.watermelon}`)}
`;

const EmptyNoteWrapper = styled(HypercareUI.Card)`
  margin-bottom: 16px;
  margin-top: 16px;
`;

const EmptyNoteContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const UserEmptyWrapper = styled(HypercareUI.Card)`
  flex-direction: row;
  align-items: center;
  gap: 8px;
  height: 56px;
`;

const UserEmptyWrapperNote = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #4a4a4a;
`;

interface Props {
  user: User | any;
  isSelf: boolean;
  isDisabled: boolean;
}

const NotesProfileWrapper = ({user, isSelf, isDisabled}: Props) => {
  const {notes, error, loading, handleDeleteProfileNote, handleCreateProfileNotes, handleUpdateProfileNote} =
    HiddenNotesViewModel(isSelf, user.id);

  const [isAddNoteMode, setIsAddNoteMode] = useState(false);
  const [currentNote, setCurrentNote] = useState<HiddenNotes | null>(null);
  const [isEditMode, setIsEditMode] = useState(false);

  const isAdmin = store.getState().organization.isAdmin;

  const handleAddAnotherNote = () => {
    if (notes?.length >= MAXNOTES) {
      return;
    }
    setIsAddNoteMode(true);
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.profileAddNoteButtonPressed,
    });
  };

  const handleAddInitialNote = () => {
    setIsEditMode(true);
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.profileAddNoteButtonPressed,
    });
  };

  if (loading) return <LoadingDiv />;

  const renderErrorContent = () => (
    <>
      <NotesWrapper>
        <HCTextContextTwo>{NOTES}</HCTextContextTwo>
      </NotesWrapper>
      <UserEmptyWrapper>
        <InfoIcon />
        <span>{HN_NETWORK_ERROR}</span>
      </UserEmptyWrapper>
    </>
  );

  const renderNotSelfEmptyNotesContent = () => (
    <>
      <NotesWrapper>
        <HCTextContextTwo>{NOTES}</HCTextContextTwo>
      </NotesWrapper>
      <UserEmptyWrapper>
        <InfoIcon />
        <UserEmptyWrapperNote>{NO_NOTES_FOUND}</UserEmptyWrapperNote>
      </UserEmptyWrapper>
    </>
  );

  const renderSelfEmptyNotesContent = () => (
    <>
      <NotesWrapper>
        <HCTextContextTwo>{NOTES}</HCTextContextTwo>
      </NotesWrapper>
      {!isEditMode ? (
        <EmptyNoteWrapper data-testid="emptynoteswrapper" title={PROFILE_PAGE_EMPTY_NOTES_TITLE}>
          <EmptyNoteContent>
            <p>{PROFILE_PAGE_EMPTY_NOTES_SUBTITLE}</p>
            <EmptyAddNotesWrapper onClick={handleAddInitialNote}>
              <AddButton />
              <AddNotes>{ADD_NOTE}</AddNotes>
            </EmptyAddNotesWrapper>
          </EmptyNoteContent>
        </EmptyNoteWrapper>
      ) : (
        <>
          <NoteCard
            handleDeleteProfileNote={(noteId) => handleDeleteProfileNote(noteId || '')}
            handleUpdateProfileNote={handleUpdateProfileNote}
            handleCreateProfileNotes={handleCreateProfileNotes}
            user={user}
            setIsEditMode={setIsEditMode}
            mode={true}
            isEditMode={isEditMode || isAddNoteMode}
            setCurrentNote={setCurrentNote}
            updateMode={currentNote !== null}
            isSelf={isSelf}
          />
        </>
      )}
    </>
  );

  const showAddNoteButton = isSelf && !isAddNoteMode && notes?.length <= MAXNOTES;

  const renderNonEmptyNotesContent = () => (
    <>
      <NotesWrapper data-testid="nonemptynoteswrapper">
        <HCTextContextTwo>{NOTES}</HCTextContextTwo>
      </NotesWrapper>
      {showAddNoteButton && (
        <AddNotesWrapper onClick={handleAddAnotherNote} data-testid="add-note-btn" disabled={notes.length >= MAXNOTES}>
          <AddButton
            styles={{marginTop: '2px', color: notes.length < MAXNOTES ? appTheme.watermelon : appTheme.silvergrey}}
          />
          <AddNotes disabled={notes.length >= MAXNOTES}>{ADD_NOTE}</AddNotes>
        </AddNotesWrapper>
      )}
      {(isAddNoteMode || currentNote != null) && (
        <NoteCard
          handleDeleteProfileNote={handleDeleteProfileNote}
          handleUpdateProfileNote={handleUpdateProfileNote}
          handleCreateProfileNotes={handleCreateProfileNotes}
          user={user}
          setIsEditMode={setIsAddNoteMode}
          mode={true}
          isEditMode={isEditMode || isAddNoteMode}
          noteData={currentNote ?? undefined}
          setCurrentNote={setCurrentNote}
          updateMode={currentNote !== null}
          isSelf={isSelf}
        />
      )}
      {notes?.map((note, index) => {
        if (note.id === currentNote?.id) return;
        return (
          <NoteCard
            handleDeleteProfileNote={handleDeleteProfileNote}
            handleUpdateProfileNote={handleUpdateProfileNote}
            handleCreateProfileNotes={handleCreateProfileNotes}
            noteData={note}
            user={user}
            setIsEditMode={setIsEditMode}
            key={`note-${index}`}
            dataid={`note-${index}`}
            type={isAdmin ? 'admin' : 'self'}
            isEditMode={isEditMode || isAddNoteMode}
            setCurrentNote={(item) => setCurrentNote(item)}
            updateMode={currentNote !== null}
            isSelf={isSelf}
          />
        );
      })}
    </>
  );

  const shouldRenderErrorContent = !!error;
  const shouldRenderNotSelfEmptyNotesContent = !shouldRenderErrorContent && !isSelf && !notes?.length;
  const shouldRenderSelfEmptyNotesContent = !shouldRenderErrorContent && isSelf && !notes?.length;
  const shouldRenderNotesContent = !shouldRenderErrorContent && !!notes?.length;

  return (
    <Wrapper data-testid="notesprofilewrapper">
      {isDisabled && <DisabledOverlay />}
      {shouldRenderErrorContent && renderErrorContent()}
      {shouldRenderNotSelfEmptyNotesContent && renderNotSelfEmptyNotesContent()}
      {shouldRenderSelfEmptyNotesContent && renderSelfEmptyNotesContent()}
      {shouldRenderNotesContent && renderNonEmptyNotesContent()}
    </Wrapper>
  );
};

export default NotesProfileWrapper;
