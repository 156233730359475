import gql from 'graphql-tag';
import {OrganizationLoginMethods} from '../../../types';

export type FetchOrganizationByURLResponse = {
  data: {
    findOrganizationByURL: {
      __typename: string;
      name: string;
      imageUrl: string;
      url: string;
      loginMethods: OrganizationLoginMethods[];
    };
  };
};

export const FETCH_ORGANIZATION_BY_URL_QUERY = gql`
  query GetOrganizationByURL($url: String!) {
    findOrganizationByURL(url: $url) {
      ...FindOrganizationResultFragment
    }
  }
  fragment FindOrganizationResultFragment on FindOrganizationResult {
    __typename
    ... on Error {
      __typename
      message
    }
    ...OrganizationFragment
  }

  fragment OrganizationFragment on Organization {
    __typename
    name
    imageUrl
    url
    loginMethods
  }
`;
