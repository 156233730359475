import React from 'react';
import * as Yup from 'yup';
import {CurrentSelectedOrganization} from '../types/login.types';
import {IVerifyEmailStepOutputData} from '../../pages/newSignupFlow/HypercareSignupVerifyEmailAddress/HypercareSignupVerifyAddressView';
import NewVerificationCodeInput from '../../pages/newSignupFlow/components/NewVerificationCodeInput';
import {CircularProgress} from '@material-ui/core';
import {Formik} from 'formik';
import {StyledInner, StyledStepContainer} from '../styled/signup.styled';
import {LoginHeader} from './LoginHeader';
import {HelperBox, InputsWrapper, StyledAnchor, StyledSubmitAddressButton} from '../styled/login.styled';
import {NEXT, VERIFYING_AVAILABILITY} from '../../constants/login';
import AnalyticsManager, {EVENTS} from '../../analytics/AnalyticsManager';

interface ILoginOTPViewProps {
  handleGoBack: () => void;
  handleNext: (code: string) => void;
  organization?: CurrentSelectedOrganization;
  resend: any;
  isResending: boolean;
  error: string;
  currentSelectedEmail: string;
}

const validationSchema = Yup.object().shape<IVerifyEmailStepOutputData>({
  code: Yup.string()
    .required('Verification code is required')
    .test('empty', 'Verification code is required', (value: string) => !!value && value.replace(/ /g, '').length > 0)
    .test(
      'len',
      'Verification code should be 6 digit',
      (value: string) => !!value && value.replace(/ /g, '').length === 6,
    ),
});

export const LoginOTPView = ({
  handleGoBack,
  handleNext,
  organization,
  resend,
  isResending,
  error,
  currentSelectedEmail,
}: ILoginOTPViewProps) => {
  return (
    <Formik
      initialValues={{code: ''}}
      validationSchema={validationSchema}
      onSubmit={async (values, actions) => {
        actions.setSubmitting(true);
        await handleNext(values.code);
        actions.setSubmitting(false);
      }}
    >
      {({handleSubmit, values, isSubmitting, setFieldValue, touched, errors}) => (
        <React.Fragment>
          <form onSubmit={handleSubmit} style={{width: '100%', maxWidth: '600px'}}>
            <StyledStepContainer>
              <StyledInner>
                <LoginHeader
                  title={'Check your email for code'}
                  description={`Please enter the 6-digit code sent to ${currentSelectedEmail}`}
                  onBackButtonClick={handleGoBack}
                  headerTitle={
                    <>
                      {organization?.imageUrl ? (
                        <img width={50} height={50} src={organization?.imageUrl} alt={organization?.name + 'logo'} />
                      ) : (
                        <>{organization?.name}</>
                      )}
                    </>
                  }
                />
                <InputsWrapper>
                  <NewVerificationCodeInput
                    length={6}
                    size="sm"
                    value={values.code}
                    disabled={isSubmitting || isResending}
                    onChange={(value) => setFieldValue('code', value)}
                    handleResend={resend}
                    renderError={!!error && <div style={{color: 'red'}}>{error}</div>}
                  />
                </InputsWrapper>
                <HelperBox>
                  <StyledAnchor
                    onClick={() => AnalyticsManager.applyAnalytics({eventName: EVENTS.loginContactSupport})}
                    href="https://www.hypercare.com/contact"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Having some issues?
                    <span>Contact us</span>
                  </StyledAnchor>
                </HelperBox>
                <StyledSubmitAddressButton
                  data-testid="submitButton"
                  type="submit"
                  disabled={isSubmitting || !values['code']}
                >
                  {isSubmitting && (
                    <>
                      <CircularProgress color="inherit" size={18} style={{marginRight: 16}} /> {'Submitting OTP'}
                    </>
                  )}
                  {!isSubmitting && 'Log in'}
                </StyledSubmitAddressButton>
              </StyledInner>
            </StyledStepContainer>
          </form>
        </React.Fragment>
      )}
    </Formik>
  );
};
