import React from 'react';
import {WideWidthStyleModal, CloseBtn, Header} from 'src/styles/styled-components/ModalStyleComponents';
import {OrganizationInvitation, User} from 'src/types';
import {getFullName} from 'src/utils/user';
import styled from 'styled-components';

import PendingInviteContainer from './views/PendingInviteContainer';
import useHandleInvitationAction from './hooks/useHandleInvitationAction';

const SendingText = styled.span`
  color: grey;
  font-size: 12px;
`;

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  invitationList: OrganizationInvitation[];
}

const PendingInvitesModal = ({isOpen, closeModal, invitationList}: Props) => {
  const [isLoading, setLoading] = React.useState(false);

  const {handleDecline, handleAccept} = useHandleInvitationAction({setLoading});

  function renderAdminsNames(admins: User[]) {
    const fullNameList = admins.map((admin) => getFullName(admin));
    return fullNameList.join(', ');
  }

  return (
    <WideWidthStyleModal
      isOpen={isOpen}
      shouldCloseOnEsc={true}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={true}
      onRequestClose={closeModal}
    >
      <Header>
        <span>Pending Invites</span>
        <CloseBtn onClick={closeModal} />
      </Header>

      <PendingInviteContainer
        renderAdminsNames={renderAdminsNames}
        handleDecline={handleDecline}
        handleAccept={handleAccept}
        isLoading={isLoading}
        invitationList={invitationList}
      />
      {isLoading && <SendingText>sending request...</SendingText>}
    </WideWidthStyleModal>
  );
};

export default PendingInvitesModal;
