import React from 'react';
import styled from 'styled-components';
import ProfilePic from 'src/components/ProfilePic';
import {User} from 'src/types';
import {Layout, UserDescription, UserName, UserRole} from 'src/pages/MessengerPage/messenger/StyledUserList';

export const StyledLayout = styled(Layout)`
  max-width: 200px;
`;

interface Props {
  colleague: User;
  onClick?: (colleague: User) => void;
}

const OptimizedUserListItemDetails = ({colleague, onClick}: Props) => {
  const {firstname, role, lastname} = colleague;

  return (
    <StyledLayout onClick={() => onClick && onClick(colleague)}>
      <ProfilePic users={[colleague]} status={'active'} />
      <UserDescription>
        <UserName>
          {firstname} {lastname}
        </UserName>
        <UserRole>{role}</UserRole>
      </UserDescription>
    </StyledLayout>
  );
};

export default OptimizedUserListItemDetails;
