import styled from 'styled-components';

const boxHeight = 1;
const triangleBoxBase = boxHeight * 0.5;
const triangleBoxSides = boxHeight * 0.4;
const triangleBase = triangleBoxBase * 0.5;

export const OptionPopperBox = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  border-radius: 5px;
  padding: 0.5em;
  text-align: center;
  font-size: 0.8em;
  opacity: 0;
  cursor: pointer;
  height: min-content;
  background-color: transparent;
`;

export const OptionIconHolder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2em;
`;

export const MessageBubblePopperArrow = styled.div`
  position: absolute;
  width: ${triangleBoxBase}em;
  height: ${triangleBoxSides}em;
  &::before {
    content: '';
    margin: auto;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
  }
`;

export const MessageBubblePopperContainer = styled.div`
  z-index: 2;
  height: 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.popperBackground};
  color: #ffffff;
  border-radius: 5px;
  padding: 0.5em;
  text-align: center;
  font-size: 0.8em;
  &[data-popper-placement*='bottom'] > .arrow {
    top: 0;
    left: 0;
    margin-top: -0.25em;
    width: ${triangleBoxBase}em;
    height: ${triangleBoxSides}em;
    &::before {
      border-width: 0 ${triangleBoxSides}em ${triangleBase}em ${triangleBoxSides}em;
      border-color: transparent transparent #232323 transparent;
    }
  }
  &[data-popper-placement*='top'] > .arrow {
    bottom: 0;
    left: 0;
    margin-bottom: -${triangleBoxSides}em;
    width: ${triangleBoxBase}em;
    height: ${triangleBoxSides}em;
    &::before {
      border-width: ${triangleBoxSides}em ${triangleBase}em 0 ${triangleBase}em;
      border-color: #232323 transparent transparent transparent;
    }
  }
  &[data-popper-placement*='right'] > .arrow {
    left: 0;
    margin-left: -${triangleBoxSides}em;
    height: ${triangleBoxBase}em;
    width: ${triangleBoxSides}em;
    &::before {
      border-width: ${triangleBase}em ${triangleBoxSides}em ${triangleBase}em 0;
      border-color: transparent #232323 transparent transparent;
    }
  }
  &[data-popper-placementt*='left'] > .arrow {
    right: 0;
    margin-right: -${triangleBoxSides}em;
    height: ${triangleBoxBase}em;
    width: ${triangleBoxSides}em;
    &::before {
      border-width: ${triangleBase}em 0 ${triangleBase}em ${triangleBoxSides}em;
      border-color: transparent transparent transparent #232323;
    }
  }
`;
