import React, {PureComponent} from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import {Manager, Reference, Popper} from 'react-popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import createNewChat from 'src/utils/messengerHelper/createNewChat';
import {ADMIN, REGULAR} from 'src/constants/privileges';
import {getFullName} from 'src/utils/user';
import ProfilePic from 'src/components/ProfilePic';
import {ApolloClient} from 'apollo-client';
import {ChatUser, Chat, User} from 'src/types';
import RemoveMemberMutation from 'src/gql/mutation/RemoveMemberMutation';
import PromoteMemberMutation from 'src/gql/mutation/PromoteMemberMutation';
import AdminCrown from 'src/svgs/AdminCrown';
import TripleDot from 'src/svgs/TripleDot';
import {MenuButton, OptionsBox, Option} from 'src/styles/styled-components/PopoverMenuComponents';
import {toast} from 'react-toastify';
import {CustomLoader} from 'src/components/LoadingDiv';
import AnalyticsManager, {EVENTS} from 'src/analytics/AnalyticsManager';
import {ARCHIVED, MESSENGER} from 'src/constants/routerPathName';
import GetChatMembersQuery from 'src/gql/query/GetChatMembersQuery';
import {Divider} from '@material-ui/core';

const StyledOptionsBox = styled(OptionsBox)`
  max-width: 250px;
  overflow-x: hidden;
`;

const StyledDivider = styled(Divider)`
  background-color: white !important;
  margin: 5px 0 !important;
`;

const MemberItemHolder = styled.div`
  padding-top: 14px;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const UserSection = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  overflow: hidden;
`;

const OptionSection = styled.div`
  display: flex;
  width: 40px;
  min-width: 40px;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  svg {
    margin-left: 5px;
    padding-top: 10px;
    height: 24px;
  }
  &:empty {
    width: 0;
  }
`;

const Title = styled.div`
  margin-left: 5px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Self = styled.div`
  font-size: 14px;
  text-align: center;
  width: 40px;
  color: ${(props) => props.theme.warmGrey};
`;

const Admin = styled.div`
  width: 40px;
  transform: scale(0.9);
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  color: ${(props) => props.theme.warmGrey};
  padding-left: 10px;
`;

const Role = styled.div`
  font-size: 0.8em;
  color: ${(props) => props.theme.warmGrey};
`;

const Menu = styled.div`
  padding-left: 10px;
  padding-right: 5px;
`;

interface Props {
  chat: Chat;
  client: ApolloClient<any>;
  member: ChatUser;
  isMe: boolean;
  canChangeUsers: boolean;
  myId: string;
}

interface State {
  isMenuOpen: boolean;
  isLoading: boolean;
}

class ChatInformationMemberListItem extends PureComponent<Props, State> {
  public state = {
    isMenuOpen: false,
    isLoading: false,
  };
  public render() {
    const {member, canChangeUsers, myId} = this.props;
    const {isMenuOpen, isLoading} = this.state;
    const routePath = window.location.href.includes(ARCHIVED) ? `/${ARCHIVED}/user` : `/${MESSENGER}/user`;

    return (
      <MemberItemHolder>
        <UserSection>
          <div>
            <ProfilePic size={50} users={[member]} />
          </div>
          <Title>
            <div>
              <div>{getFullName(member)}</div>
              <Role>{member.role}</Role>
            </div>
            {member.privilege === ADMIN ? (
              <Admin>
                <AdminCrown />
                Admin
              </Admin>
            ) : null}
          </Title>
        </UserSection>

        <OptionSection>
          {member.id !== myId ? (
            <Menu>
              <Manager>
                <Reference>
                  {({ref}) => (
                    <MenuButton onClick={this.onClickMenu} ref={ref}>
                      {isLoading ? <CustomLoader /> : <TripleDot />}
                    </MenuButton>
                  )}
                </Reference>
                <Popper placement="left">
                  {({ref, style, placement}) =>
                    isMenuOpen && (
                      <ClickAwayListener onClickAway={this.clickedOutside}>
                        <StyledOptionsBox ref={ref} style={style} data-placement={placement}>
                          <Option onClick={() => this.tryCreateNewChat([member])}>Message {getFullName(member)}</Option>
                          <Option onClick={() => window.routerHistory.push(`${routePath}/${member.id}`)}>
                            View {getFullName(member)}'s profile
                          </Option>
                          {canChangeUsers && (
                            <React.Fragment>
                              <StyledDivider />
                              {this.renderAdminOptions(member)}
                            </React.Fragment>
                          )}
                        </StyledOptionsBox>
                      </ClickAwayListener>
                    )
                  }
                </Popper>
              </Manager>
            </Menu>
          ) : (
            <Self>(You)</Self>
          )}
        </OptionSection>
      </MemberItemHolder>
    );
  }

  private clickedOutside = () => {
    this.setState({isMenuOpen: false});
  };

  private onClickMenu = () => {
    this.setState({isMenuOpen: !this.state.isMenuOpen});
  };

  private renderAdminOptions = (member) => {
    return (
      <React.Fragment>
        <Option onClick={this.removeUserFromChat}>Remove From Chat</Option>
        {member.privilege === ADMIN && <Option onClick={this.removeAdmin}>Demote Admin</Option>}
        {member.privilege !== ADMIN && <Option onClick={this.giveAdmin}>Make Admin</Option>}
      </React.Fragment>
    );
  };

  private removeUserFromChat = () => {
    const {member, client, chat} = this.props;
    const chatId = chat.id;
    this.setState(
      {
        isLoading: true,
        isMenuOpen: false,
      },
      async () => {
        try {
          await client.mutate({
            mutation: RemoveMemberMutation,
            variables: {
              chatId,
              userId: member.id,
            },
          });

          toast.success(`Succesfully removed ${member.firstname} ${member.lastname} from chat`);

          AnalyticsManager.applyAnalytics({
            eventName: EVENTS.removeMember,
            params: {
              chat_id: chatId,
              user_id: member.id,
            },
          });
        } catch (e) {
          toast.error('Failed to remove user');
          this.setState({
            isLoading: false,
          });
          console.error('Unable to remove user', e);
        }
      },
    );
  };

  private changePrivilege = (privilege: 'REGULAR' | 'ADMIN') => {
    const {member, client, chat} = this.props;
    const chatId = chat.id;
    this.setState(
      {
        isLoading: true,
        isMenuOpen: false,
      },
      async () => {
        try {
          await client.mutate({
            mutation: PromoteMemberMutation,
            variables: {
              chatId,
              userId: member.id,
              privilege,
            },
            update: (store, {data}) => {
              const chatQuery = store.readQuery({
                query: GetChatMembersQuery,
                variables: {chatId},
              }) as {chat: Chat};

              if (chatQuery) {
                const newChatQuery = R.evolve({
                  chat: {
                    members: R.map(
                      R.ifElse(
                        R.propEq('id', member.id),
                        R.evolve({
                          privilege: R.always(privilege),
                        }),
                        R.identity,
                      ),
                    ),
                  },
                })(chatQuery);

                store.writeQuery({
                  query: GetChatMembersQuery,
                  variables: {chatId},
                  data: newChatQuery,
                });
              }
            },
          });
          toast.success(`Successfully changed ${member.firstname}'s privilege`);

          AnalyticsManager.applyAnalytics({
            eventName: EVENTS.changePrivilege,
            params: {
              chat_id: chatId,
              privilege: privilege.toLowerCase(),
              user_id: member.id,
            },
          });
        } catch (e) {
          toast.error('Failed to change privilege');
          console.error(`Unable to change user: ${member.firstname}'s privilege to ${privilege}`, e);
        } finally {
          this.setState({
            isLoading: false,
          });
        }
      },
    );
  };

  private tryCreateNewChat = (selectedColleagues: User[]) => {
    this.setState(
      {
        isMenuOpen: false,
        isLoading: true,
      },
      async () => {
        try {
          const newChatId = await createNewChat(selectedColleagues);
          if (!newChatId) throw new Error('failed');

          window.routerHistory.push(`/messenger/${newChatId}`);
        } catch (e) {
          this.setState({isLoading: false});
          console.error(e);
          toast.error('Failed to start new chat, please check your internet connection and try again');
        }
      },
    );
  };

  private removeAdmin = () => {
    this.changePrivilege(REGULAR);
  };

  private giveAdmin = async () => {
    this.changePrivilege(ADMIN);
  };
}

export default ChatInformationMemberListItem;
