import {getParsedSessionID, trySetSessionID} from './localStorageHandler';
import ApiHelper from 'src/api';

export const registerDeviceId = async () => {
  if (!getParsedSessionID()) trySetSessionID();
  let object = {
    deviceId: getParsedSessionID(),
    type: 'web',
  };
  const result = await ApiHelper.PrivateEndpoints.registerDeviceId(object);
  if (result?.error) {
    const response = result.error?.response;
    if (response.data.errors[0].code === 'DeviceAlreadyRegistered') {
      console.error('This device has already been registered');
    }
  }
};

export const registerPushToken = async (token: string) => {
  let payload = {
    deviceId: getParsedSessionID(),
    token: token,
    pushService: 'fcm',
    environment: 'sandbox',
  };
  const result = await ApiHelper.PrivateEndpoints.registerPushNotification(payload);
  if (result && result.success) {
    const secret = result?.data?.selfMutation?.device?.registerPushToken?.secret;
    if (secret) sessionStorage.setItem('Secret', secret);
    return secret;
  } else {
    console.error(result.error?.response, 'err response');
  }
};

export const unRegisterPushToken = async () => {
  let payload = {
    deviceId: getParsedSessionID(),
  };
  const result = await ApiHelper.PrivateEndpoints.unRegisterPushNotification(payload);
  if (result.error) {
    console.error(result.error.response);
  }
};
