import React from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import StyledTooltip from 'src/components/StyledTooltip';
import LocatingEscalationModal from 'src/pages/LocatingPage/escalation-modal/LocatingEscalationModal';
import {toast} from 'react-toastify';
import {SiteScope} from 'src/types';
import {UpdatedLabel} from '../../../components/UpdatedLabel';
import moment from 'moment-timezone';
import {IsFeatureFlagEnabled} from '../../../utils/FeatureFlagManager';
import {FeatureFlagResult} from '../../../utils/FeatureFlags';
import {MinimalSiteData} from '../../../gql/query/FetchSitesForOrganization';

const TopTitleBanner = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: 'Nunito', sans-serif;
  user-select: none;
  padding: 1em;
  align-items: center;
  height: 60px;
  min-height: 60px;
  border-bottom: 1px solid ${(props) => props.theme.borderColor};
  span {
    font-family: 'Nunito';
    font-size: 18px;
    font-weight: 600;
  }
  button {
    text-transform: none;
    color: ${(props) => props.theme.chatTeal};
    span {
      font-size: 1em;
    }
  }
`;

interface Props {
  sites: MinimalSiteData[];
  selectedSite: MinimalSiteData;
}

const SearchPanelTitleBanner = ({sites, selectedSite}: Props) => {
  const [openModal, setOpenModal] = React.useState(false);
  const escalationFeatureFlag = IsFeatureFlagEnabled(FeatureFlagResult.escalationFeatureFlag);

  const handleStartEscalation = () => {
    if (sites?.length > 0) {
      setOpenModal(true);
    } else {
      toast.warn(`There are no sites in your organization, Please contact your admin or Hypercare Support for help`);
    }
  };

  return (
    <React.Fragment>
      <TopTitleBanner>
        <span>
          On-call
          <UpdatedLabel lastUpdated={moment()} />
        </span>
        {escalationFeatureFlag && (
          <StyledTooltip
            arrow
            title="Send an escalating message to a department"
            className="styledPopper"
            placement="bottom"
          >
            <Button variant="outlined" onClick={handleStartEscalation}>
              Start an escalation
            </Button>
          </StyledTooltip>
        )}
      </TopTitleBanner>
      {escalationFeatureFlag && openModal && (
        <LocatingEscalationModal
          sites={sites}
          currentSite={selectedSite}
          isOpen={openModal}
          closeModal={() => setOpenModal(false)}
        />
      )}
    </React.Fragment>
  );
};

export default SearchPanelTitleBanner;
