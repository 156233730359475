import React from 'react';
import {pathOr} from 'ramda';
import {withRouter, RouteComponentProps} from 'react-router-dom';
import styled from 'styled-components';
import {Query, QueryResult} from 'react-apollo';
import AnalyticsManager, {PAGE_VIEWS} from 'src/analytics/AnalyticsManager';
import GetChatMembersQuery from 'src/gql/query/GetChatMembersQuery';
import ChatInformationTitle from 'src/pages/MessengerPage/messenger/chat-info-layout/ChatInformationTitle';
import ChatInformationMembers from 'src/pages/MessengerPage/messenger/chat-info-layout/ChatInformationMembers';
import {connect} from 'react-redux';
import isActualChatId from 'src/utils/messengerHelper/isActualChatId';
import LoadingDiv from 'src/components/LoadingDiv';
import {Chat} from 'src/types';
import ChatinformationSingle from 'src/pages/MessengerPage/messenger/chat-info-layout/ChatInformationSingle';

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  border-left: 1px solid ${(props) => props.theme.borderColor};
  width: 350px;
  min-width: 350px;
  overflow-y: auto;
  overflow-x: hidden;
`;

interface Props
  extends RouteComponentProps<{
    chatId: string;
  }> {
  isExpanded: boolean;
  userId: string;
}

class ChatInformation extends React.Component<Props> {
  public componentDidMount() {
    const {isExpanded} = this.props;
    if (isExpanded) {
      AnalyticsManager.recordPageVisited(PAGE_VIEWS.chatSettings);
    }
  }

  public componentDidUpdate(prevProps) {
    const {isExpanded} = this.props;
    if (isExpanded !== prevProps.isExpanded && Boolean(isExpanded)) {
      AnalyticsManager.recordPageVisited(PAGE_VIEWS.chatSettings);
    }
  }

  public render() {
    const {
      match: {params},
      isExpanded,
      userId,
    } = this.props;
    const {chatId} = params;

    if (!isActualChatId(params.chatId) || !isExpanded) return <div />;

    return (
      <Query query={GetChatMembersQuery} variables={{chatId}} fetchPolicy="network-only">
        {({loading, error, data}: QueryResult) => {
          if (loading || error) {
            return (
              <Layout>
                <LoadingDiv />
              </Layout>
            );
          }
          const chat: Chat = pathOr({}, ['chat'], data);
          const {type} = chat;

          if (data && data.chat)
            return (
              <Layout>
                <ChatInformationTitle chat={chat} />
                {type === 'group' && <ChatInformationMembers chat={chat} />}
                {type === 'single' && (
                  <ChatinformationSingle
                    user={chat.members.filter((member) => member.id !== userId)[0]}
                    isSelf={false}
                  />
                )}
                {type === 'self' && <ChatinformationSingle user={chat.members[0]} isSelf={true} />}
              </Layout>
            );
          return <div />;
        }}
      </Query>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    isExpanded: state.sidebar.isExpanded,
  };
};

export default withRouter(connect(mapStateToProps)(ChatInformation));
