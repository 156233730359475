import React, {useEffect, useRef} from 'react';
import styled from 'styled-components';
import Check from 'src/svgs/Check';
import {CheckmarkV2, CheckCircleV2} from 'src/svgs/Check';
import {components} from 'react-select';
import UserListItem from 'src/pages/MessengerPage/messenger/UserListItem';
import {MaterialUiOption} from 'src/pages/MessengerPage/auto-select/ReactSelectMaterialUI';
import SearchIcon from 'src/svgs/SearchIcon';
import readGeneralUserFragmentById from 'src/utils/messengerHelper/readGeneralUserFragmentById';
import {FixedSizeList, VariableSizeList} from 'react-window';
import UserListItemV2 from '../messenger/UserListItemV2';
import {SmallLoader} from 'src/components/LoadingDiv';
import {NO_USERS_FOUND, NO_SEARCH_RESULT_FOUND_TITLE} from 'src/constants/strings';

const Layout = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 100%;
`;

const StyledMenuList = styled.div`
  border-radius: 6px;
  background-color: white;
  z-index: 9;
  box-shadow: 0 1.5px 3.5px 0 rgba(0, 0, 0, 0.3);
  & > div {
    border-radius: 6px;
  }
`;

const StyledMenuVariableSizeList = styled(VariableSizeList)`
  border-radius: 6px;
  background-color: white;
  z-index: 9;
  box-shadow: 0 1.5px 3.5px 0 rgba(0, 0, 0, 0.3);
  & > div {
    border-radius: 6px;
  }
`;

const StyledMenuVariableListNoUsersFound = styled.div`
  border-radius: 6px;
  background-color: white;
  z-index: 9;
  box-shadow: 0 1.5px 3.5px 0 rgba(0, 0, 0, 0.3);
  & > div {
    border-radius: 6px;
  }
  height: 70px;
  font-size: 16px;
  padding-left: 30px;
  line-height: 70px;
  color: grey;
`;

const StyledMenuListNoUsersFound = styled.div`
  height: 70px;
  font-size: 16px;
  padding-left: 30px;
  line-height: 70px;
  color: grey;
`;

const Circle = styled.div`
  border: 1px solid ${(props) => props.theme.slightlyGrey};
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin-right: 10px;
`;

const StyledCheck = styled(Check)`
  margin-right: 10px;
`;

const FetchMoreLoadingWrapper = styled.div`
  height: 70px;
  padding: 5px;
`;

const FetchMoreVariableListLoadingWrapper = styled.div`
  border-radius: 6px;
  background-color: white;
  z-index: 9;
  box-shadow: 0 1.5px 3.5px 0 rgba(0, 0, 0, 0.3);
  & > div {
    border-radius: 6px;
  }
  height: 70px;
  padding: 5px;
`;

/**
 * Used to return the dynamic size for the list based on the screen size
 * @returns The height of the menulist used by the Select component
 */
const getDynamicMenuListHeight = () => {
  const screenHeight = document.getElementById('root')?.clientHeight || window.screen.height;

  if (screenHeight > 1000) {
    return screenHeight * 0.27;
  } else {
    return screenHeight * 0.15;
  }
};

export const menuListMaxHeight = 540;
export const menuListInitialheight = 188;

/**
 * Custom react-select components
 */
export const FixedHeightMenuList = (props) => {
  const [menuHeight, setMenuHeight] = React.useState(0);
  const {options, children, getValue} = props;
  const [value] = getValue();
  const itemHeight = 70;
  const initialOffset = options.indexOf(value) * itemHeight;
  // Credit: https://codesandbox.io/s/bvaughnreactwindow-integrationwithreactselect-chsp4?autoresize=1&fontsize=12
  // render performance of react select workaround using react-window, and fix scrolling by useRef
  // Scroll to item API of `react-window`
  // See https://react-window.now.sh/#/api/FixedSizeList
  const scrollToIndex = children.length ? children.findIndex((child) => child.props.isFocused) : 0;
  // Use `useRef` hook to maintain the access to the menu list
  const listRef = useRef(null);
  // effect that happens after rendering to properly adjust the selection
  useEffect(() => {
    const searchHeight = document.getElementById('search-input-field').clientHeight;
    setMenuHeight(window.innerHeight - (menuListInitialheight + searchHeight));
  }, [menuHeight]);

  useEffect(() => {
    if (listRef.current) {
      // @ts-ignore
      listRef.current.scrollToItem(scrollToIndex);
    }
  }, [scrollToIndex]);

  return children.length ? (
    <FixedSizeList
      ref={listRef}
      height={menuHeight}
      itemCount={children.length}
      itemSize={itemHeight}
      initialScrollOffset={initialOffset}
    >
      {({index, style}) => <div style={style}>{children[index]}</div>}
    </FixedSizeList>
  ) : (
    <StyledMenuListNoUsersFound>{NO_USERS_FOUND}</StyledMenuListNoUsersFound>
  );
};

export const FixedHeightMenuListV2 = (props) => {
  const [menuHeight, setMenuHeight] = React.useState(0);
  const {options, children, getValue, isLoading, selectProps} = props;
  const [value] = getValue();
  const itemHeight = 70;
  const initialOffset = options.indexOf(value) * itemHeight;

  const listRef = useRef(null);
  useEffect(() => {
    const searchHeight = document.getElementById('search-input-field').clientHeight;
    setMenuHeight(window.innerHeight - (menuListInitialheight + searchHeight));
  }, [menuHeight]);

  const handleScroll = (e) => {
    selectProps.onMenuScrollToBottom();
  };

  return children.length ? (
    <FixedSizeList
      ref={listRef}
      height={menuHeight}
      itemCount={children.length}
      itemSize={itemHeight}
      initialScrollOffset={initialOffset}
      onScroll={handleScroll}
    >
      {({index, style}) => <div style={style}>{children[index]}</div>}
    </FixedSizeList>
  ) : isLoading ? (
    <FetchMoreLoadingWrapper>
      <SmallLoader />
    </FetchMoreLoadingWrapper>
  ) : (
    <StyledMenuListNoUsersFound>{NO_SEARCH_RESULT_FOUND_TITLE}</StyledMenuListNoUsersFound>
  );
};

// BUG: render performance issue https://github.com/JedWatson/react-select/issues/2850
export const AutoHeightMenuList = (props) => {
  const {options, children, getValue, isLoading} = props;
  const [value] = getValue();
  const itemHeight = 70; // same as Material UI MenuItem
  const menuListFixedHeight = 330;
  const menuListDynamicHeight = itemHeight * children.length;
  const initialOffset = options.indexOf(value) * itemHeight;

  const scrollToIndex = children.length ? children.findIndex((child) => child.props.isFocused) : 0;
  // Use `useRef` hook to maintain the access to the menu list
  const listRef = useRef(null);
  // effect that happens after rendering to properly adjust the selection
  useEffect(() => {
    if (listRef && listRef.current) {
      // @ts-ignore
      listRef.current.scrollToItem(scrollToIndex);
    }
  }, [scrollToIndex]);

  function getOptionSize(option) {
    if (option.options) {
      return option.options.length * itemHeight;
    }
    return itemHeight;
  }

  function getItemSize(i) {
    return getOptionSize(options[i]);
  }

  const totalHeight = options.reduce((height, option) => {
    return height + getOptionSize(option);
  }, 0);

  const estimatedItemSize = totalHeight / options.length;

  return (
    <StyledMenuList id="dropdownMenuList">
      {children.length ? (
        <VariableSizeList
          ref={listRef}
          height={Math.min(menuListFixedHeight, menuListDynamicHeight)}
          itemCount={children.length}
          itemSize={getItemSize}
          estimatedItemSize={estimatedItemSize}
          initialScrollOffset={initialOffset}
        >
          {({index, style}) => <div style={style}>{children[index]}</div>}
        </VariableSizeList>
      ) : isLoading ? (
        <FetchMoreLoadingWrapper>
          <SmallLoader />
        </FetchMoreLoadingWrapper>
      ) : (
        <StyledMenuListNoUsersFound>{NO_USERS_FOUND}</StyledMenuListNoUsersFound>
      )}
    </StyledMenuList>
  );
};

export const AutoHeightMenuListV2 = (props) => {
  const {options, children, getValue, isLoading, selectProps} = props;
  const [value] = getValue();
  const itemHeight = 70; // same as Material UI MenuItem
  const menuListFixedHeight = selectProps.isMulti ? 330 : getDynamicMenuListHeight();
  const menuListDynamicHeight = itemHeight * children.length;
  const initialOffset = options.indexOf(value) * itemHeight;

  // Use `useRef` hook to maintain the access to the menu list
  const listRef = useRef(null);

  function getOptionSize(option) {
    if (option.options) {
      return option.options.length * itemHeight;
    }
    return itemHeight;
  }

  function getItemSize(i) {
    return getOptionSize(options[i]);
  }

  const handleScroll = (e) => {
    selectProps.onMenuScrollToBottom();
  };

  const totalHeight = options.reduce((height, option) => {
    return height + getOptionSize(option);
  }, 0);

  const estimatedItemSize = totalHeight / options.length;

  return children.length ? (
    <StyledMenuVariableSizeList
      ref={listRef}
      height={Math.min(menuListFixedHeight, menuListDynamicHeight)}
      itemCount={children.length}
      itemSize={getItemSize}
      estimatedItemSize={estimatedItemSize}
      initialScrollOffset={initialOffset}
      onScroll={handleScroll}
    >
      {({index, style}) => <div style={style}>{children[index]}</div>}
    </StyledMenuVariableSizeList>
  ) : isLoading ? (
    <FetchMoreVariableListLoadingWrapper>
      <SmallLoader />
    </FetchMoreVariableListLoadingWrapper>
  ) : (
    <StyledMenuVariableListNoUsersFound>{NO_SEARCH_RESULT_FOUND_TITLE}</StyledMenuVariableListNoUsersFound>
  );
};

export const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <SearchIcon />
    </components.DropdownIndicator>
  );
};

export const CircleOption = (props) => {
  // BUG: render performance issue https://github.com/JedWatson/react-select/issues/2850
  const colleague = readGeneralUserFragmentById(props.value);
  return (
    <MaterialUiOption {...props}>
      <Layout>
        <UserListItem colleague={colleague} />
        {props.isSelected ? <StyledCheck /> : <Circle />}
      </Layout>
    </MaterialUiOption>
  );
};

export const CircleOptionV2 = (props) => {
  return (
    <MaterialUiOption {...props}>
      <Layout>
        <UserListItemV2 colleague={props} />
        {props.isSelected ? <CheckmarkV2 /> : <CheckCircleV2 />}
      </Layout>
    </MaterialUiOption>
  );
};

export const NoCircleOption = (props) => {
  const colleague = readGeneralUserFragmentById(props.value);
  return (
    <MaterialUiOption {...props}>
      <Layout>
        <UserListItem colleague={colleague} />
      </Layout>
    </MaterialUiOption>
  );
};

export const NoCircleOptionV2 = (props) => {
  return (
    <MaterialUiOption {...props}>
      <Layout>
        <UserListItemV2 colleague={props} />
      </Layout>
    </MaterialUiOption>
  );
};
