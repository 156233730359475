import React from 'react';
import styled from 'styled-components';
import theme from 'src/styles/theme';
import CloseIcon from 'src/svgs/CloseIcon';
import ArrowDown from 'src/svgs/ArrowDown';
import ArrowUp from 'src/svgs/ArrowUp';
import {makeStyles} from '@material-ui/core/styles';
import {StyledInputBase} from 'src/components/StepFormPartials';
import SearchIcon from 'src/svgs/SearchIcon';
import {DIRECTION_BOTH, DIRECTION_NEXT, DIRECTION_PREVIOUS} from 'src/constants/messageTypes';
import {Divider} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  searchBar: {
    width: '90.5%',
    margin: '8px 53px 8px 52px',
  },
  arrowDown: {
    marginRight: '47px',
    marginLeft: '34px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  arrowUp: {
    marginLeft: '24px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  arrowDirectionDisabled: {
    cursor: 'no-drop',
  },
  matchedCount: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#4A4A4A',
    display: 'flex',
    alignItems: 'center',
  },
  cancelBtn: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Nunito Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '17px',
    lineHeight: '24px',
    textAlign: 'center',
    color: '#FF3E55',
    cursor: 'pointer',
    marginRight: '20px',
    '&:hover': {
      opacity: '0.7',
    },
  },
  closeIcon: {
    marginRight: '31px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
}));

export const SearchBarContainer = styled.div`
  background: #f6f6f9;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.15);
`;

const StyledInput = styled(StyledInputBase)`
  input {
    padding-left: 2em;
    padding-right: calc(17em + 80px);
    :placeholder-shown {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      padding-right: 2em;
      color: #bcbcbc;
    }
  }
`;

const StyledSearchIcon = styled(SearchIcon)`
  position: absolute;
  left: 10px;
  z-index: 1;
  width: 1em;
  height: 1em;
`;

const SearchBardEndAdornment = styled.div`
  display: inherit;
  position: absolute;
  right: 1em;
  border-radius: 8px;
  opacity: 1;
  transition: 0.2s;
`;

const StyledDivider = styled(Divider)`
  border: 1px solid #d8d8d8 !important;
  box-sizing: border-box !important;
  height: 32px !important;
  margin-right: 18px !important;
`;

const SearchMessageContainer = (props) => {
  const {
    searchText,
    position,
    totalSearchMessages,
    isLoadingSearchMessage,
    isLoadingMessage,
    directionDisable,
    handleKeyPress,
    handleChatSearch,
    handleSearchClear,
    handleNextSearch,
    handleCancelSearch,
  } = props;
  const classes = useStyles();
  const isDisableUpDirection =
    isLoadingMessage ||
    isLoadingSearchMessage ||
    directionDisable === DIRECTION_PREVIOUS ||
    directionDisable === DIRECTION_BOTH;
  const isDisableDownDirection =
    isLoadingMessage ||
    isLoadingSearchMessage ||
    directionDisable === DIRECTION_NEXT ||
    directionDisable === DIRECTION_BOTH;

  return (
    <SearchBarContainer>
      <StyledInput
        className={classes.searchBar}
        value={searchText}
        type="text"
        autoFocus
        placeholder="Search in this conversation"
        startAdornment={<StyledSearchIcon />}
        onKeyPress={(e) => handleKeyPress(e)}
        endAdornment={
          <SearchBardEndAdornment>
            {searchText && (
              <div className={classes.closeIcon} onClick={() => handleSearchClear()}>
                <CloseIcon color="#4A4A4A" width="15" height="15" />
              </div>
            )}
            <StyledDivider />
            <div className={classes.matchedCount}>
              {position} of {totalSearchMessages}
            </div>
            <div
              className={`${classes.arrowUp} ${
                isLoadingMessage ||
                directionDisable === DIRECTION_PREVIOUS ||
                (directionDisable === DIRECTION_BOTH && classes.arrowDirectionDisabled)
              }`}
              onClick={() => (isDisableUpDirection ? null : handleNextSearch(DIRECTION_PREVIOUS))}
            >
              <ArrowUp color={isDisableUpDirection ? theme.warmGrey : theme.watermelon} />
            </div>
            <div
              className={`${classes.arrowDown} ${
                isLoadingMessage ||
                directionDisable === DIRECTION_NEXT ||
                (directionDisable === DIRECTION_BOTH && classes.arrowDirectionDisabled)
              }`}
              onClick={() => (isDisableDownDirection ? null : handleNextSearch(DIRECTION_NEXT))}
            >
              <ArrowDown color={isDisableDownDirection ? theme.warmGrey : theme.watermelon} />
            </div>
            <div className={classes.cancelBtn} onClick={() => handleCancelSearch()}>
              Cancel
            </div>
          </SearchBardEndAdornment>
        }
        onChange={(e) => handleChatSearch(e)}
      />
    </SearchBarContainer>
  );
};

export default SearchMessageContainer;
