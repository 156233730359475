import GetUserOrganizationQuery from 'src/gql/query/GetUserOrganizations';
import client from 'src/apollo';
import {AuthPayload} from 'src/types';
import {pick} from 'ramda';
import store from 'src/redux';
import getParsedAuthInfo, {getParsedCurrentOrganization, getParsedAuthRegion} from 'src/utils/localStorageHandler';
import {Mixpanel} from './Mixpanel';

type AnalyticsFunctionNames = 'recordMixpanelEvent';

// https://docs.google.com/spreadsheets/d/1F-OkWBv1GVaSRtBwvR5E4z8F_QUkM3dvLR3ak-RaF7g/edit#gid=241965627

const ONBOARDING_METRICS = {
  introSignupBtnPressed: 'intro_sign_up_button_pressed',
  introLoginBtnPressed: 'intro_login_button_pressed',
  signupPersonalInfoNextPressed: 'sign_up_personal_info_next_button_pressed',
  signupPhoneNextPressed: 'sign_up_phone_number_next_button_pressed',
  signupEmailNextPressed: 'sign_up_email_address_next_button_pressed',
  signupVerifyEmailNextPressed: 'sign_up_email_verify_code_next_button_pressed',
  signupAccountInfoNextPressed: 'sign_up_account_info_next_button_pressed',
  signUpLoginPressed: 'sign_up_login_button_pressed',
  signUpExistLoginInstead: 'sign_up_email_address_login_instead',
  signUpContactSupport: 'sign_up_contact_support',
  signUpResendVerifyCode: 'sign_up_email_verify_code_resend_code',
  welcomeOrganizationLogin: 'welcome_back_organization_login_button_pressed',
  welcomeLoginBtnPressed: 'welcome_back_login_button_pressed',
  welcomeSignupBtnPressed: 'welcome_back_sign_up_button_pressed',
  loginBtnPressed: 'login_login_button_pressed',
  loginOrgBtnPressed: 'login_organization_login_button_pressed',
  loginForgotPwdBtnPressed: 'login_forgot_password_button_pressed',
  loginContactSupport: 'login_contact_support_pressed',
  signupAccountInfoConfirmCheckboxPressed: 'sign_up_account_info_confirm_checkbox_pressed',
  termOfServiceButtonPressed: 'term_of_service_button_pressed',
  privacyPolicyButtonPressed: 'privacy_policy_button_pressed',
  clickHereToSwitchToTheUSAButtonPressed: 'click_here_to_switch_to_the_USA_button_pressed',
  clickHereToSwitchToTheCanadaButtonPressed: 'click_here_to_switch_to_the_Canada_button_pressed',
};

export const EVENTS = {
  signUpPhoneEntered: 'sign_up_phone_number_next_button_pressed',
  pageViewed: 'page_viewed',
  messageSent: 'message_sent',
  inviteAccepted: 'user_invitation_accepted',
  inviteSent: 'user_invitation_sent',
  messageSentFailed: 'message_sent_failed',
  chatCreated: 'chat_created',
  openChat: 'open_chat',
  archivedChat: 'archived_chat',
  unarchivedChat: 'unarchived_chat',
  addMembers: 'add_members',
  removeMember: 'remove_member',
  changePrivilege: 'change_member_privilege',
  mutedChat: 'muted_chat',
  unmutedChat: 'unmuted_chat',
  changeChatTitle: 'change_chat_title',
  changeStatus: 'change_status',
  changePassword: 'change_password',
  switchOrganizations: 'switch_organizations',
  profilePicChanged: 'profile_pic_changed',
  biometricsAuth: 'biometrics_auth',
  networkRequest: 'network_request',
  leaveChat: 'leave_chat',
  uploadFile: 'upload_file',
  pageUser: 'page_user',
  pageRole: 'page_role',
  agreeTOS: 'tos_agreed',
  agreeEULA: 'eula_agreed',
  userLogout: 'user_logout',
  alternativeContactFromBanner: 'message_alternate_contact_from_banner',
  alternativeContantPressed: 'dont_message_alert_alternate_contact_pressed',
  sendAnywayPressed: 'dont_message_alert_send_anyway_pressed',
  dontSendPressed: 'dont_message_alert_dont_send_pressed',
  messageRead: 'message_read',
  cancelInviteButtonPressed: 'cancel_invite_button_pressed',
  resendInviteButtonPressed: 'resend_invite_button_pressed',
  socketConnected: 'socket_connected',
  socketDisconnected: 'socket_disconnected',
  socketReconnected: 'socket_reconnected',
  hotkeyActivated: 'hotkey_activated',
  sessionTimeoutIdleDialogShown: 'session_timeout_idle_dialog_shown',
  sessionTimeoutIdleDialogContinue: 'session_timeout_idle_dialog_continue',
  sessionTimeoutIdleDialogLogout: 'session_timeout_idle_dialog_logout',
  sessionTimeoutIdleLogout: 'session_timeout_idle_logout',
  sessionTimeoutForceLogout: 'session_timeout_force_logout',
  searchQuerySubmitted: 'search_query_submitted',
  searchResultsUserOrGroupTabPressed: 'search_results_user_or_group_tab_pressed',
  searchResultsMessagesTabPressed: 'search_results_messages_tab_pressed',
  contactsGenericPagerButtonPressed: 'contacts_generic_pager_button_pressed',
  chatGenericPagerButtonPressed: 'chat_generic_pager_button_pressed',
  profileDropdownMenuOpened: 'profile_drop_down_menu_opened',
  scheduleTabPressed: 'schedule_tab_pressed',
  messagesTabPressed: 'messages_tab_pressed',
  onCallTabPressed: 'on_call_tab_pressed',
  contactsTabPressed: 'contacts_tab_pressed',
  switchOrganizationMenuOpened: 'switch_organization_menu_opened',
  FAQButtonPressed: 'FAQ_button_pressed',
  contactSupportButtonPressed: 'contact_support_button_pressed',
  aboutButtonPressed: 'about_button_pressed',
  exportTemplateMessageReportButtonPressed: 'export_tm_report_button_pressed',
  exportTemplateMessageReportQuitEditingButtonPressed: 'export_tm_report_quit_editing_button_pressed',
  phoneButtonPressed: 'phone_button_pressed',
  chatGenericPhoneButtonPressed: 'chat_generic_phone_button_pressed',
  chatSpecificPagerButtonPressed: 'chat_specific_pager_button_pressed',
  chatSpecificPhoneButtonPressed: 'chat_specific_phone_button_pressed',
  pageUserSent: 'page_user_sent',
  contactsSpecificPagerButtonPressed: 'contacts_specific_pager_button_pressed',
  contactsSpecificPhoneButtonPressed: 'contacts_specific_phone_button_pressed',
  profileAddNoteButtonPressed: 'profile_add_note_button_pressed',
  profileNoteEditMenuOptionPressed: 'profile_note_edit_menu_option_pressed',
  profileNoteDeleteMenuOptionPressed: 'profile_note_delete_menu_option_pressed',
  profileNoteCreated: 'profile_note_created',
  profileNoteUpdated: 'profile_note_updated',
  profileNoteDeleted: 'profile_note_deleted',
  profileNoteEditVisibilityOptionPressed: 'profile_note_edit_visibility_option_pressed',
  profileNoteNewVisibilityOptionPressed: 'profile_note_new_visibility_option_pressed',
  switchRegionPressed: 'switch_region_pressed',
  editProfileDoneButtonPressed: 'edit_profile_done_button_pressed',
  contactsProfileMessageOnHypercareButtonPressed: 'contacts_profile_message_on_hypercare_button_pressed',
  profileEditButtonPressed: 'profile_edit_button_pressed',
  editProfileOrderChanged: 'edit_profile_order_changed',
  editProfileRemoveButtonPressed: 'edit_profile_remove_button_pressed',
  editProfileChangeVisibilityOptionPressed: 'edit_profile_change_visibility_option_pressed',
  editProfileVisibilityPublicOptionPressed: 'edit_profile_visibility_public_option_pressed',
  editProfileVisibilityPrivateOptionPressed: 'edit_profile_visibility_private_option_pressed',
  editProfileAddAnotherMethodButtonPressed: 'edit_profile_add_another_method_button_pressed',
  contactsProfileShowMoreButtonPressed: 'contacts_profile_show_more_button_pressed',
  contactsProfileShowLessButtonPressed: 'contacts_profile_show_less_button_pressed',
  settingsProfileShowMoreButtonPressed: 'settings_profile_show_more_button_pressed',
  settingsProfileShowLessButtonPressed: 'settings_profile_show_less_button_pressed',
  newAddressAddded: 'new_address_added',
  ...ONBOARDING_METRICS,
};

export const PAGE_VIEWS = {
  chatList: 'HypercareChatListView',
  archivedChatList: 'HypercareArchivedChatListView',
  chatView: 'HypercareChatView',
  chatSettings: 'HypercareChatSettingsView',
  messageDetails: 'HypercareMessageDetailsView',
  contactsList: 'HypercareContactsListView',
  profileView: 'HypercareProfileView',
  locatingView: 'HypercareLocatingView',
  departmentView: 'HypercareDepartmentView',
  switchOrganizationView: 'HypercareSwitchOrganizationView',
  loginScreen: 'HypercareLoginView',
  changePassword: 'HypercareChangePasswordView',
  onboardingRequestEmailVerification: 'HypercareOnboardingRequestEmailVerificationView',
  onboardingVerifyEmail: 'HypercareOnboardingVerifyEmailView',
  onboardingJoinOrganization: 'HypercareOnboardingJoinOrganizationView',
  signupRequestAddressVerification: 'HypercareSignupRequestAddressVerificationView',
  signupVerifyAddress: 'HypercareSignupVerifyAddressView',
  signupProfile: 'HypercareSignupProfileView',
  signupCredentials: 'HypercareSignupCredentialsView',
  recoverPasswordUsername: 'HypercareForgotPasswordUsernameView',
  recoverPasswordToken: 'HypercareForgotPasswordVerifyTokenView',
  recoverPasswordNewPassword: 'HypercareForgotPasswordNewPasswordView',
  inviteCode: 'HypercareInviteCodeView',
  selectContactsList: 'HypercareSelectContactsListView',
  statusChange: 'HypercareStatusChangeView',
};

class AnalyticsManager {
  public static events = EVENTS;
  public static pageViews = PAGE_VIEWS;

  public static mixpanelLoginEvent(authInfo: AuthPayload) {
    try {
      const userClone = authInfo.user;

      const userOrganization = store.getState().organization;

      Mixpanel.identify(userClone.id);
      Mixpanel.people.set({
        $first_name: userClone.firstname,
        $last_name: userClone.lastname,
        username: userClone.username,
        id: userClone.id,
        $email: userClone.email,
        title: userClone.role,
        company: userOrganization.name,
        company_id: userOrganization.organizationId,
        platform: 'Web',
        region: getParsedAuthRegion(),
      });
    } catch (e) {
      console.error(e);
    }
  }

  public static identifyUserOrg({userId, company, company_id}) {
    Mixpanel.identify(userId);
    Mixpanel.people.set({
      company,
      company_id,
      region: getParsedAuthRegion(),
    });
  }

  public static recordMixpanelEvent({eventName, params}: {eventName: string; params: any}) {
    const userOrganization = store.getState().organization;

    if (params) {
      params['organization_id'] = userOrganization?.organizationId;
      params['organization'] = userOrganization?.name;
      params['company_id'] = userOrganization?.organizationId;
      params['region'] = getParsedAuthRegion();
    } else {
      params = {
        organization_id: userOrganization?.organizationId,
        organization: userOrganization?.name,
        company_id: userOrganization?.organizationId,
        region: getParsedAuthRegion(),
      };
    }
    Mixpanel.track(eventName, params);
  }

  public static recordPageVisited(pageKey: string) {
    Mixpanel.page(pageKey);
  }

  public static applyAnalytics(...args: {eventName: string; params?: any}[]) {
    this.recordMixpanelEvent.apply(this, Array.prototype.slice.call(args, 0));
  }

  public static resetMixpanel() {
    Mixpanel.reset();
  }

  private static getFilteredUserOrganizations() {
    const result = client.readQuery({query: GetUserOrganizationQuery});
    const userOrganizations = result.me.organizations;
    const allowedKey = ['id', 'name'];
    const filteredOrganizationInfo = userOrganizations.map((org) => pick(allowedKey, org));
    return filteredOrganizationInfo;
  }
}

export default AnalyticsManager;
