import * as React from 'react';
import styled from 'styled-components';
import * as ReactModal from 'react-modal';
import {Header, WideWidthStyleModal} from 'src/styles/styled-components/ModalStyleComponents';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

const EulaLabelHolder = styled.span`
  a {
    padding-left: 5px;
    font-weight: bold;
    color: inherit;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
  .MuiFormControlLabel-root span {
    user-select: none;
    font-size: 14px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledSubmitButton = styled(Button)``;
const StyledCancelButton = styled(Button)`
  margin-right: 6px !important;
  font-weight: bold !important;
`;

interface Props {
  closeModal: () => void;
  onAgreementSubmit: () => void;
  reactModalProps: ReactModal.Props;
  isLoading: boolean;
}

class EulaPopupModal extends React.PureComponent<Props> {
  public state = {
    isAgreementChecked: false,
  };

  public render() {
    const {reactModalProps, onAgreementSubmit, closeModal, isLoading} = this.props;
    const {isOpen} = reactModalProps;
    const {isAgreementChecked} = this.state;
    return (
      <WideWidthStyleModal
        isOpen={isOpen}
        ariaHideApp={false}
        shouldCloseOnEsc={false}
        shouldCloseOnOverlayClick={false}
        onRequestClose={closeModal}
      >
        <Header>
          <span>Hypercare Terms of Service</span>
        </Header>

        <p>
          By clicking 'SUBMIT', you agree to the Terms of Service set out by Hypercare. You also grant your organization
          ability to manage your account.
        </p>

        <FlexContainer>
          <FormControlLabel
            control={<Checkbox checked={isAgreementChecked} disabled={isLoading} onChange={this.handleCheckedChange} />}
            label={
              <EulaLabelHolder>
                &nbsp; I agree to these
                <a href="https://www.hypercare.com/terms-of-service/" rel="noopener noreferrer" target="_blank">
                  terms and conditions
                </a>
                &nbsp;
              </EulaLabelHolder>
            }
          />
          <ButtonContainer>
            <StyledCancelButton onClick={this.onAgreementCancel} disabled={isLoading}>
              cancel
            </StyledCancelButton>
            <StyledSubmitButton
              variant="contained"
              color="secondary"
              disabled={!isAgreementChecked || isLoading}
              onClick={onAgreementSubmit}
            >
              {isLoading ? <CircularProgress size={20} /> : 'submit'}
            </StyledSubmitButton>
          </ButtonContainer>
        </FlexContainer>
      </WideWidthStyleModal>
    );
  }

  private onAgreementCancel = () => {
    this.props.closeModal();
    window.location.reload();
  };

  private handleCheckedChange = () => {
    this.setState({
      isAgreementChecked: !this.state.isAgreementChecked,
    });
  };
}

export default EulaPopupModal;
