import React, {Component} from 'react';
import styled from 'styled-components';
import {Query, QueryResult} from 'react-apollo';
import ConnectionStatusQuery from 'src/gql/query/ConnectionStatusQuery';
import lowerAllStringExceptFirst from 'src/utils/lowerAllStringExceptFirst';
import {CONNECTED, DISCONNECTED, RECONNECTING} from 'src/constants/connectionStatuses';
import {CustomLoader} from 'src/components/LoadingDiv';

interface Props {
  connectionStatus: string;
}

interface State {
  showStatus: boolean;
  previousStatus: string;
}

const FlexCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ConnectionBanner = styled.div<{title: string}>`
  ${(props) =>
    props.title === CONNECTED
      ? `
    background: ${props.theme.appleGreen};
  `
      : null}
  ${(props) =>
    props.title === RECONNECTING
      ? `
    background: ${props.theme.black};
  `
      : null}
  ${(props) =>
    props.title === DISCONNECTED
      ? `
    background: ${props.theme.redPink};
  `
      : null}
  position: relative;
  color: white;
  width: 100%;
  height: 40px;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
`;

// const ConnectionCircle = styled.div`
//   background: ${props => props.theme.appleGreen};
//   border-radius: 50%;
//   width: 15px;
//   height: 15px;
// `;

class ConnectionStatusIndicator extends Component<Props, State> {
  // prevProps workaround - https://github.com/reactjs/rfcs/pull/40
  public static getDerivedStateFromProps(props: Props, prevState: State) {
    const {connectionStatus} = props;
    const {previousStatus} = prevState;
    if (connectionStatus !== previousStatus || connectionStatus === DISCONNECTED || connectionStatus === RECONNECTING) {
      return {
        showStatus: true,
        previousStatus: connectionStatus,
      };
    }
    return {showstatus: false};
  }
  public state = {
    showStatus: false,
    previousStatus: this.props.connectionStatus,
  };
  public timeOut: any;
  public componentDidUpdate() {
    clearTimeout(this.timeOut);
    if (this.props.connectionStatus === CONNECTED) this.timeoutStatus();
  }
  public componentDidMount() {
    if (this.props.connectionStatus === CONNECTED) this.setState({showStatus: true});
  }
  public componentWillUnmount() {
    clearTimeout(this.timeOut);
  }
  public render() {
    const {connectionStatus} = this.props;
    const {showStatus} = this.state;
    return showStatus ? (
      <ConnectionBanner title={connectionStatus}>
        <FlexCenter>
          {connectionStatus === RECONNECTING ? <CustomLoader /> : null}
          <span style={connectionStatus === RECONNECTING ? {paddingLeft: '10px'} : undefined}>
            {connectionStatus && lowerAllStringExceptFirst(connectionStatus)}
          </span>
        </FlexCenter>
      </ConnectionBanner>
    ) : null;
  }
  private timeoutStatus = () => {
    this.timeOut = window.setTimeout(() => {
      this.setState({showStatus: false});
    }, 5000);
  };
}

export default (props) => (
  <Query query={ConnectionStatusQuery} fetchPolicy="cache-only">
    {({data}: QueryResult) => {
      if (data?.connectionStatus) {
        const {connectionStatus} = data;
        return <ConnectionStatusIndicator {...props} connectionStatus={connectionStatus} />;
      }
      return null;
    }}
  </Query>
);
