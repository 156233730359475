import * as React from 'react';
import styled from 'styled-components';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import SendMessageIcon from 'src/svgs/SendMessageIcon';
import {SmallLoader} from 'src/components/LoadingDiv';
import {useDispatch} from 'react-redux';
import store, {AppDispatch} from 'src/redux';
import {CHAT_INPUT} from 'src/constants/elementIds';
import {Actions} from 'src/redux/reducers';
import {ConsultMessageData, PriorityType} from 'src/types';
import {ON_SHIFT} from 'src/constants/workStatus';
import {SendMessageWarningDialog} from 'src/components/StatusSettingsDialog';
import {useEscapeKeyEventListener} from 'src/utils/useEscapeKeyEventListener';
import {FILETYPEARRAY} from 'src/constants/fileUpload';
import {toast} from 'react-toastify';

const InputDiv = styled.div`
  position: relative;
  overflow: hidden;
  padding-left: 5px;
  flex: 5;
  min-height: 50px;
`;

const SendIconContainer = styled.div`
  position: absolute;
  padding: 10px;
  right: 8px;
  bottom: 6px;
  cursor: pointer;
  &:hover {
    filter: brightness(85%);
  }
`;

const Input = styled(TextareaAutosize)`
  border-color: ${(props) => props.theme.borderColor};
  /* max-height: calc(180px - 15px); */
  max-height: 150px;
  resize: none;
  min-height: 45px;
  width: 100%;
  box-sizing: border-box;
  font-size: 1em;
  border-radius: 15px;
  border-width: 1px;
  border-style: solid;
  border-image: initial;
  padding: 12px;
  outline: none;
  padding-right: 3em;
`;

interface Props {
  inputRef: HTMLTextAreaElement;
  setInputRef: (element: HTMLTextAreaElement) => void;
  trySendMessage: (message: string, consultData?: ConsultMessageData) => void;
  isDisabled: boolean;
  messagePriority: PriorityType;
  isFileUploading: boolean;
  chatId: string;
  handleFileUpload: (files: File[]) => Promise<void>;
}

const MessagesInputContainer = ({
  inputRef,
  setInputRef,
  messagePriority,
  trySendMessage,
  isDisabled,
  isFileUploading,
  chatId,
  handleFileUpload,
}: Props) => {
  const [input, setInput] = React.useState('');
  const [warningDialog, setWarningDialog] = React.useState(false);

  let inputTextRef = React.useRef('');
  const {targetUser} = store.getState().messages;

  const dispatch: AppDispatch = useDispatch();

  useEscapeKeyEventListener({watchKey: 'Escape'});

  React.useEffect(() => {
    const {draftMsgToChatId} = store.getState().drafts;
    let hasDraft = false;
    let draftInput = '';
    if (draftMsgToChatId[chatId] && draftMsgToChatId[chatId].input) {
      draftInput = draftMsgToChatId[chatId].input;
      hasDraft = true;
      setInput(draftInput);
    }
    return () => {
      if (hasDraft && inputTextRef.current.trim() === '') {
        dispatch({
          type: 'drafts/CLEAR_MESSAGE',
          payload: {chatId},
        } as Actions);
      } else if (inputTextRef.current && inputTextRef.current.trim() !== draftInput) {
        dispatch({
          type: 'drafts/UPDATE_MESSAGE',
          payload: {
            chatId,
            input: inputTextRef.current,
          },
        } as Actions);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    inputTextRef.current = input;
  }, [input]);

  const onChange = (e) => {
    const inputValue = e.target.value;
    setInput(inputValue);
  };

  const onKeyPress = (event) => {
    if (isDisabled) return;
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
    }
    if (event.key === 'Enter' && !event.shiftKey && inputRef) {
      if (targetUser === undefined || targetUser?.workStatus === ON_SHIFT) sendMessage();
      else setWarningDialog(true);
    }
  };

  const handleSendIconClicked = () => {
    if (isFileUploading) return;
    if (targetUser === undefined || targetUser?.workStatus === ON_SHIFT) sendMessage();
    else setWarningDialog(true);
  };

  const sendMessage = () => {
    trySendMessage(input);
    setInput('');
    inputRef.focus();
  };

  const closeSendMessageWarningDialog = () => {
    setWarningDialog(false);
  };

  const onPaste = (e) => {
    const clipboardItems = e.clipboardData.items;
    const items = [].slice.call(clipboardItems);
    if (items.length === 0) {
      return;
    }
    const fileArray = [];
    const fileErrorArray = [];
    items.map((item) => {
      const file = item.getAsFile();
      if (file === null) return;
      if (!FILETYPEARRAY.includes(file.type)) {
        fileErrorArray.push(file.name);
      } else {
        fileArray.push(file);
      }
    });
    if (fileErrorArray.length > 0) {
      toast.error(`These files are not supported: ${fileErrorArray.join(', ')}`, {
        autoClose: false,
      });
    }
    if (fileArray.length === 0) return;
    handleFileUpload(fileArray);
  };

  return (
    <InputDiv>
      <Input
        autoFocus
        id={`${CHAT_INPUT}`}
        value={input}
        ref={setInputRef}
        onKeyPress={onKeyPress}
        placeholder={
          messagePriority === PriorityType.stat
            ? 'Type a Stat Message'
            : messagePriority === PriorityType.urgent
            ? 'Type an urgent Message'
            : 'Type a message'
        }
        onChange={onChange}
        maxRows={5}
        onPaste={onPaste}
      />

      {targetUser && targetUser?.workStatus !== ON_SHIFT && (
        <SendMessageWarningDialog
          sendMessageWarningDialog={warningDialog}
          targetUser={targetUser}
          closeWarningDialog={closeSendMessageWarningDialog}
          sendMessage={sendMessage}
        />
      )}

      <SendIconContainer onClick={handleSendIconClicked}>
        {isFileUploading ? <SmallLoader /> : <SendMessageIcon />}
      </SendIconContainer>
    </InputDiv>
  );
};

export default MessagesInputContainer;
