import {Grid, Button, CircularProgress, IconButton} from '@material-ui/core';
import React, {useState} from 'react';
import {FormField} from 'src/components/@hc-ui/components/FormField/FormField';
import {CPFField, CustomFieldsViewModel, CPFSection} from './custom-fields-section/CustomFieldsViewModel';
import {ProfileConfigurableFieldsSection} from './ProfileConfigurableFieldsSection';
import {IsFeatureFlagEnabled} from 'src/utils/FeatureFlagManager';
import {Formik, FormikErrors} from 'formik';
import {ExecutionResult} from 'react-apollo';
import {UpdateSelfProfileCustomFieldMutationResult} from 'src/gql/v2/mutation/UpdateSelfProfileCustomFieldMutation';
import {ProfileFieldVisibility, FullOrganizationMember} from 'src/types';
import {CreateSelfProfileFieldsMutationResult} from 'src/gql/v2/mutation/CreateSelfProfileCustomFieldsMutation';
import {ProfileField} from './ProfileField';
import {DeleteSelfProfileCustomFieldMutationResult} from 'src/gql/v2/mutation/DeleteSelfProfileCustomFieldMutation';
import {
  CANCEL,
  PROFILE_ADDRESS_UPDATE_SUCCESS,
  PROFILE_PAGE_OTHER_INFORMATION_TITLE,
  PROFILE_PAGE_EMPTY_SECTION_SUBTITLE,
  FIELD_PLACEHOLDER_PHONE,
  FIELD_PLACEHOLDER_DATE,
  FIELD_PLACEHOLDER_EMAIL,
  FIELD_PLACEHOLDER_DROPDOWN,
  FIELD_PLACEHOLDER_TEXT,
  FIELD_PLACEHOLDER_URL,
  FIELD_PLACEHOLDER_NUMBER,
  PROFILE_PAGE_UPDATE_PROFILE_FIELDS_TEXT,
  PROFILE_PAGE_UPDATE_FAILED,
  CPF_PLACEHOLDER_LABEL_TEXT,
} from 'src/constants/strings';
import moment from 'moment';
import {toast} from 'react-toastify';
import {SuccessToast} from 'src/components/CustomToasts';

import {HCTextContextTwo} from 'src/components/HypercareComponents';
import store from 'src/redux';
import {EditOutlined} from '@material-ui/icons';
import styled from 'styled-components';
import AlertModal from '../../../MessengerPage/messenger/messages-layout/message-template/AlertModal';
import {useParams} from 'react-router-dom';
import {getOrganizationalUnitObject} from '../../../../utils/organizationHelper/getOrganizationalUnitObject';

type ProfileConfigurableFieldsFormProps = {
  isSelf: boolean;
  user: FullOrganizationMember;
  mode: 'view' | 'edit';
  isDisabled: boolean;
  onModeChange: (mode: 'view' | 'edit') => void;
  onSubmitCompleted: () => void;
  onDiscard: () => void;
};

const DisabledOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  z-index: 1;
`;

export const ProfileConfigurableFieldsForm = (props: ProfileConfigurableFieldsFormProps) => {
  const viewModel = CustomFieldsViewModel();

  const configurableProfileFieldsVisibility = IsFeatureFlagEnabled('configurableProfileFieldsVisibility');
  const [showDiscardModal, setShowDiscardModal] = useState(false);

  const {loading: isSchemaLoading, data: schemaData, error: schemaError} = viewModel.fetchProfileFieldsSchemaQuery();
  const schemaLoadedSuccessfully = !isSchemaLoading && !schemaError;

  let {userId} = useParams<{userId: string}>();

  const isUserAdmin = store.getState().organization.isAdmin;

  const {
    loading: isSelfFieldsDataLoading,
    data: selfFieldsData,
    error: selfFieldsDataError,
    refetch: refetchSelfFieldsData,
  } = viewModel.fetchUserProfileFieldsQuery(props.user.id);
  const selfFieldsDataLoadedSuccessfully = !isSelfFieldsDataLoading && !selfFieldsDataError;

  const {
    createProfileFields,
    loading: isCreateProfileFieldsMutationLoading,
    error: createProfileFieldsMutationError,
  } = viewModel.createProfileFieldsMutation();

  const {
    updateProfileField,
    loading: isUpdateProfileFieldMutationLoading,
    error: updateProfileFieldMutationError,
  } = viewModel.updateProfileFieldMutation();

  const {
    deleteProfileField,
    loading: isDeleteProfileFieldMutationLoading,
    error: deleteProfileFieldMutationError,
  } = viewModel.deleteProfileFieldMutation();

  const isSubmitting =
    isCreateProfileFieldsMutationLoading || isUpdateProfileFieldMutationLoading || isDeleteProfileFieldMutationLoading;

  const handleSubmit = async (values: CPFSection[]) => {
    try {
      const updateRequests: Promise<ExecutionResult<UpdateSelfProfileCustomFieldMutationResult>>[] = [];
      const createRequests: Promise<ExecutionResult<CreateSelfProfileFieldsMutationResult>>[] = [];
      const deleteRequests: Promise<ExecutionResult<DeleteSelfProfileCustomFieldMutationResult>>[] = [];

      for (const section of values) {
        const fieldsToCreate = section.fields.filter((f) => f.isNew && !f.isMarkedForDeletion);
        const fieldsToUpdate = section.fields.filter((f) => f.isDirty && !f.isNew && !f.isMarkedForDeletion);
        const fieldsToDelete = section.fields.filter((f) => f.isMarkedForDeletion);

        if (fieldsToCreate.length) {
          const createRequest = createProfileFields(section.id, fieldsToCreate);
          createRequests.push(createRequest);
          await createRequest;
        }

        for (const fieldToUpdate of fieldsToUpdate) {
          const updateRequest = updateProfileField(fieldToUpdate);
          updateRequests.push(updateRequest);
          await updateRequest;
        }

        for (const fieldToDelete of fieldsToDelete) {
          const deleteRequest = deleteProfileField(fieldToDelete);
          deleteRequests.push(deleteRequest);
          await deleteRequest;
        }
      }

      const completedUpdateRequests = await Promise.allSettled(updateRequests);
      const completeDeleteRequests = await Promise.allSettled(deleteRequests);
      const completedCreateRequests = await Promise.allSettled(createRequests);

      await refetchSelfFieldsData({organizationalUnit: getOrganizationalUnitObject(), userId});
      toast.success(<SuccessToast title={PROFILE_ADDRESS_UPDATE_SUCCESS} />, {
        className: 'toast-message',
        autoClose: 5000,
      });
      props.onSubmitCompleted();
    } catch (e) {
      toast.error(PROFILE_PAGE_UPDATE_FAILED);
    }
  };

  const validate = (sections: CPFSection[]) => {
    return sections
      .map((s) => s.fields)
      .flat()
      .reduce<
        FormikErrors<{
          [fieldId: string]: string;
        }>
      >((prevErrors, currentField) => {
        const error = viewModel.validateField(currentField)[0];

        if (!error) return prevErrors;

        return {
          ...prevErrors,
          [currentField.id]: error,
        };
      }, {});
  };
  return (
    <Formik<CPFSection[]>
      validate={validate}
      validateOnMount
      initialValues={selfFieldsData}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {(formikProps) => {
        // TODO: fix this abomination somehow.
        // Formik docs say that validate function determines the type of formikProps.errors but they lied. >:(
        // It seems that T where Formik<T> takes precedence, setting errors type to FormikErrors<T>.

        const errors = formikProps.errors as unknown as {[fieldId: string]: string};
        const fieldIdsWithErrors = Object.keys(errors);
        const hasErrors = fieldIdsWithErrors.length !== 0;
        const hasDirtyErrors =
          formikProps.values
            .map((s) => s.fields)
            .flat()
            .filter((f) => f.isDirty && fieldIdsWithErrors.includes(f.id)).length !== 0;

        const onFieldChange = (field: CPFField, section: CPFSection) => {
          const oldValues = formikProps.values;
          const newValues = oldValues.map((s) => {
            if (s.id !== section.id) return s;
            const newFields = s.fields.map((f) => {
              if (f.id !== field.id) return f;
              return field;
            });
            return {...s, fields: newFields};
          });
          formikProps.setValues(newValues);
        };

        const onAddField = (field: CPFField, section: CPFSection) => {
          const label = field.labelOptions.length > 1 ? '' : field.label;
          const oldValues = formikProps.values;
          const newValues = oldValues.map((s) => {
            if (s.id !== section.id) return s;
            return {...s, fields: s.fields.concat({...field, label, id: String(Date.now()), isNew: true})};
          });
          formikProps.setValues(newValues);
        };

        const onRemoveField = (field: CPFField, section: CPFSection) => {
          onFieldChange({...field, isMarkedForDeletion: true}, section);
        };

        const taintAllFieldsWithErrors = () => {
          formikProps.values.forEach((s) => {
            s.fields.forEach((f) => {
              if (!f.isDirty && fieldIdsWithErrors.includes(f.id)) {
                onFieldChange({...f, isDirty: true}, s);
              }
            });
          });
        };

        const isEditButtonVisible = props.mode === 'view';

        const updatedSectionArray = formikProps.values.reduce((acc: CPFSection[], section) => {
          const newFields = section.fields.filter((field) => {
            if (
              (field.visibility === ProfileFieldVisibility.PRIVATE && (isUserAdmin || props.isSelf)) ||
              field.visibility === ProfileFieldVisibility.PUBLIC
            ) {
              return true;
            }
            return false;
          });

          if (newFields.length > 0 || props.isSelf) {
            acc.push({...section, fields: newFields});
          }

          return acc;
        }, []);

        const hideOtherInformationSection = formikProps.values?.length === 0 || updatedSectionArray.length === 0;

        if (hideOtherInformationSection) {
          return null;
        }

        return (
          <Grid container direction="column" style={{position: 'relative'}}>
            {props.isDisabled && <DisabledOverlay />}
            <br />
            <br />
            <Grid item container alignItems="center">
              <HCTextContextTwo>{PROFILE_PAGE_OTHER_INFORMATION_TITLE}</HCTextContextTwo>
              {isEditButtonVisible && props.isSelf && (
                <IconButton style={{marginLeft: 8}} size="small" onClick={() => props.onModeChange('edit')}>
                  <EditOutlined fontSize="small" />
                </IconButton>
              )}
            </Grid>
            <br />
            <Grid item>
              {updatedSectionArray.map((section) => {
                const addFieldOptions = schemaData?.find((s) => s.sectionId === section.sectionId)?.fields ?? [];
                const isSectionEmpty = section.fields.filter((f) => !f.isMarkedForDeletion).length === 0;

                const hideSectionField = isSectionEmpty && !props.isSelf && !isUserAdmin;
                if (hideSectionField) return null;

                return (
                  <ProfileConfigurableFieldsSection
                    key={section.id}
                    title={section.label}
                    mode={props.mode}
                    user={props.user}
                    isAddFieldDisabled={section.fields.length === section.maxFields}
                    addFieldOptions={addFieldOptions}
                    onAddField={(f) => onAddField(f, section)}
                  >
                    {props.mode === 'view' && props.isSelf && isSectionEmpty && PROFILE_PAGE_EMPTY_SECTION_SUBTITLE}
                    {section.fields.map((field) => {
                      if (field.isMarkedForDeletion) return null;
                      if (!props.isSelf && field.visibility === ProfileFieldVisibility.PRIVATE && !isUserAdmin)
                        return null;

                      const getError = () => {
                        if (!field.isNew) return errors[field.id];
                        if (field.isDirty) return errors[field.id];
                      };

                      const isFieldDisabled =
                        isSubmitting ||
                        props.mode === 'view' ||
                        (field.isSynced && props.user.isDirectorySynced) ||
                        !field.isEditable;

                      const isFieldRemovable =
                        !isSubmitting &&
                        field.isRemovable &&
                        props.mode === 'edit' &&
                        !(field.isSynced && props.user.isDirectorySynced);

                      const shouldShowPlaceHolderLabel = field.label === '' && field.labelOptions.length > 1;
                      const label = shouldShowPlaceHolderLabel ? CPF_PLACEHOLDER_LABEL_TEXT : field.label;

                      const commonFormFieldProps = {
                        key: field.id,
                        fieldId: field.fieldId,
                        mode: props.mode,
                        disabled: isFieldDisabled,
                        isRequired: false,
                        label: label,
                        labelOptions: field.labelOptions,
                        onChangeLabel: (l: string) => onFieldChange({...field, label: l, isDirty: true}, section),
                        error: getError(),
                        isSynced: field.isSynced,
                        isManagedUser: props.user.isDirectorySynced,
                      };

                      const profileFieldProps = {
                        isSynced: field.isSynced && props.user.isDirectorySynced,
                        visibility: field.visibility,
                        onRemove: isFieldRemovable ? () => onRemoveField(field, section) : undefined,
                        onChangeVisibility:
                          configurableProfileFieldsVisibility && props.mode === 'edit'
                            ? (newVisibility: ProfileFieldVisibility) =>
                                onFieldChange({...field, visibility: newVisibility, isDirty: true}, section)
                            : undefined,
                        mode: props.mode,
                        isSelf: props.isSelf,
                      };

                      switch (field.fieldType) {
                        case 'phone':
                          return (
                            <ProfileField {...profileFieldProps}>
                              <FormField
                                {...commonFormFieldProps}
                                fieldType="phone"
                                placeholder={field.placeholder || FIELD_PLACEHOLDER_PHONE}
                                value={field.phoneNumber}
                                onChange={(v) => onFieldChange({...field, phoneNumber: v, isDirty: true}, section)}
                                onBlur={() => onFieldChange({...field, isDirty: true}, section)}
                              />
                            </ProfileField>
                          );
                        case 'date':
                          return (
                            <ProfileField {...profileFieldProps}>
                              <FormField
                                {...commonFormFieldProps}
                                fieldType="date"
                                placeholder={FIELD_PLACEHOLDER_DATE}
                                value={field.date ? moment(field.date).toDate() : undefined}
                                onChange={(v) =>
                                  onFieldChange({...field, date: v.toISOString(), isDirty: true}, section)
                                }
                              />
                            </ProfileField>
                          );
                        case 'number':
                          return (
                            <ProfileField {...profileFieldProps}>
                              <FormField
                                {...commonFormFieldProps}
                                fieldType="number"
                                placeholder={FIELD_PLACEHOLDER_NUMBER}
                                value={field.value}
                                onChange={(v) => onFieldChange({...field, value: String(v), isDirty: true}, section)}
                                onBlur={() => onFieldChange({...field, isDirty: true}, section)}
                              />
                            </ProfileField>
                          );
                        case 'url':
                          return (
                            <ProfileField {...profileFieldProps}>
                              <FormField
                                {...commonFormFieldProps}
                                fieldType="url"
                                placeholder={FIELD_PLACEHOLDER_URL}
                                value={field.url}
                                onChange={(v) => onFieldChange({...field, url: v, isDirty: true}, section)}
                                onBlur={() => onFieldChange({...field, isDirty: true}, section)}
                              />
                            </ProfileField>
                          );
                        case 'text':
                          return (
                            <ProfileField {...profileFieldProps}>
                              <FormField
                                {...commonFormFieldProps}
                                fieldType="text"
                                placeholder={FIELD_PLACEHOLDER_TEXT}
                                value={field.text}
                                onChange={(v) => onFieldChange({...field, text: v, isDirty: true}, section)}
                                onBlur={() => onFieldChange({...field, isDirty: true}, section)}
                              />
                            </ProfileField>
                          );
                        case 'dropdown':
                          return (
                            <ProfileField {...profileFieldProps}>
                              <FormField
                                {...commonFormFieldProps}
                                fieldType="dropdown"
                                placeholder={FIELD_PLACEHOLDER_DROPDOWN}
                                value={field.selectedOptions?.[0]}
                                onChange={(v) =>
                                  onFieldChange({...field, selectedOptions: v.split(','), isDirty: true}, section)
                                }
                                options={field.options}
                                renderValue={(v) => v}
                                renderOptionContent={(o) => o}
                                getIsOptionSelected={(o) => field.selectedOptions.includes(o)}
                                getOptionId={(o) => o}
                                onOptionClick={(v) =>
                                  onFieldChange({...field, selectedOptions: v.split(','), isDirty: true}, section)
                                }
                                onClickAway={() => onFieldChange({...field, isDirty: true}, section)}
                              />
                            </ProfileField>
                          );
                        case 'email':
                          return (
                            <ProfileField {...profileFieldProps}>
                              <FormField
                                {...commonFormFieldProps}
                                fieldType="email"
                                placeholder={FIELD_PLACEHOLDER_EMAIL}
                                value={field.email}
                                onChange={(v) => onFieldChange({...field, email: v, isDirty: true}, section)}
                                onBlur={() => onFieldChange({...field, isDirty: true}, section)}
                              />
                            </ProfileField>
                          );
                      }
                    })}
                  </ProfileConfigurableFieldsSection>
                );
              })}
            </Grid>
            {props.mode === 'edit' && (
              <Grid item container direction="row-reverse" spacing={2}>
                <Grid item>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      taintAllFieldsWithErrors();
                      return formikProps.submitForm();
                    }}
                    disabled={isSubmitting || hasErrors || !formikProps.dirty}
                  >
                    {isSubmitting ? <CircularProgress size={20} /> : PROFILE_PAGE_UPDATE_PROFILE_FIELDS_TEXT}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => setShowDiscardModal(true)}
                    disabled={isSubmitting}
                  >
                    {CANCEL}
                  </Button>
                </Grid>
              </Grid>
            )}
            {showDiscardModal && (
              <AlertModal
                id="discard-modal"
                width="xs"
                title="Discard unsaved changes?"
                titleFontSize="21px"
                subtitle=""
                closeAlertModal={() => setShowDiscardModal(false)}
                isAlertModalVisible={showDiscardModal}
                alertModalButtons={[
                  {
                    type: 'secondary',
                    buttonLabel: 'Keep editing',
                    onClickHandler: () => setShowDiscardModal(false),
                    id: 'discard-nevermind',
                  },
                  {
                    type: 'primary',
                    buttonLabel: 'Discard',
                    onClickHandler: () => {
                      props.onModeChange('view');
                      formikProps.resetForm();
                      setShowDiscardModal(false);
                    },
                    id: 'discard-confirm',
                  },
                ]}
              />
            )}
          </Grid>
        );
      }}
    </Formik>
  );
};
