import {HYPERCARE_REGION} from 'src/constants/storageKeys';
import {AuthRegion} from 'src/types';
import {createAction, ActionType, getType} from 'typesafe-actions';

export const actions = {
  setHypercareRegion: createAction(
    'authRegion/SET_HYPERCARE_REGION',
    (resolve) => (region: AuthRegion) => resolve({region}),
  ),
};

export type AuthRegionActions = ActionType<typeof actions>;

export interface HypercareRegionState {
  currentAuthRegion: AuthRegion;
}

export const initialState: HypercareRegionState = {
  currentAuthRegion: window.localStorage.getItem(HYPERCARE_REGION) as AuthRegion,
};

const hypercareRegionReducer = (state: HypercareRegionState = initialState, action: AuthRegionActions) => {
  switch (action.type) {
    case getType(actions.setHypercareRegion):
      return {currentAuthRegion: action.payload.region};
    default:
      return state;
  }
};

export default hypercareRegionReducer;
