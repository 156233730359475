import React, {Component} from 'react';
import * as Yup from 'yup';
import styled from 'styled-components';
import * as ReactModal from 'react-modal';
import {Message} from 'src/types';
import TextField from '@material-ui/core/TextField';
import {Formik, FormikProps} from 'formik';
import {firstNameSchema, lastNameSchema} from 'src/utils/validations';
import {DefaultButton} from 'src/styles/styled-components/Button';
import Spinner from 'react-spinkit';
import {StyledModal, Layout, CloseBtn, Header} from 'src/styles/styled-components/ModalStyleComponents';

const StyledOl = styled.ol`
  padding: 0;
  height: calc(100% - 25px - 15px);
  overflow: auto;
`;

const OverrideStyledModal = styled(StyledModal)`
  max-height: unset !important;
`;

const StyledLi = styled.li`
  list-style-type: none;
  margin: 15px 0;
  &:not(:last-child) {
    padding-bottom: 10px;
    border-bottom: 1px solid lightgrey;
  }
  & > span {
    font-size: 14px;
    display: block;
    overflow-wrap: break-word;
  }
  & > :first-child {
    font-size: 16px;
    margin-bottom: 0.5em;
    text-transform: uppercase;
    opacity: 0.75;
    color: ${(props) => props.theme.greyishBrown};
  }
`;

const ButtonsWrapper = styled.div`
  padding-top: 30px;
  text-align: center;
`;

const ErrorMessage = styled.p`
  margin: 0;
  color: ${(props) => props.theme.main};
`;

const StyledForm = styled.form`
  height: 100%;
  margin-top: 1.5em;
  position: relative;
`;

const consultValidationSchema = Yup.object().shape({
  mrn: Yup.string()
    .required('MRN is required!')
    .matches(/^[a-zA-Z0-9]+$/, 'Must be alphanumeric characters'),
  firstname: firstNameSchema,
  lastname: lastNameSchema,
  details: Yup.string().max(1024, 'A maximum length of 1024 characters'),
});

interface Props {
  message?: Message;
  closeModal: () => void;
  reactModalProps: ReactModal.Props;
  readOnly: boolean;
  trySendMessage?: (mesaage: string, values: string) => Promise<void>;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

interface Values {
  mrn: string;
  firstname: string;
  lastname: string;
  details?: string;
}

class ConsultMessageModal extends Component<Props> {
  public state = {
    error: null,
    isLoading: false,
    shouldClose: true,
  };
  public render() {
    const {readOnly, reactModalProps, closeModal} = this.props;
    const {shouldClose} = this.state;
    const {isOpen} = reactModalProps;
    return (
      <OverrideStyledModal
        isOpen={isOpen}
        shouldCloseOnEsc={shouldClose}
        ariaHideApp={false}
        shouldCloseOnOverlayClick={shouldClose}
        onRequestClose={closeModal}
      >
        <Layout>
          <Header>
            <span>Consult</span>
            {shouldClose ? <CloseBtn onClick={closeModal} /> : null}
          </Header>
          {!readOnly ? (
            <Formik
              initialValues={{
                mrn: '',
                firstname: '',
                lastname: '',
                details: '',
              }}
              validationSchema={consultValidationSchema}
              onSubmit={this.onSubmit}
            >
              {(props) => this.renderWriteConsultForm(props)}
            </Formik>
          ) : (
            this.renderReadOnlyModal()
          )}
        </Layout>
      </OverrideStyledModal>
    );
  }
  private renderWriteConsultForm = ({
    handleBlur,
    handleChange,
    handleSubmit,
    submitForm,
    values,
    touched,
    errors,
  }: FormikProps<Values>) => {
    const {error, isLoading} = this.state;
    return (
      <StyledForm onSubmit={handleSubmit}>
        <TextField
          fullWidth
          autoFocus
          InputLabelProps={{
            shrink: true,
          }}
          id="mrn"
          name="mrn"
          margin="normal"
          label="MEDICAL RECORD NUMBER"
          value={values.mrn}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={touched.mrn ? errors.mrn : ''}
          error={touched.mrn && Boolean(errors.mrn)}
        />
        <TextField
          fullWidth
          margin="normal"
          id="firstname"
          name="firstname"
          label="First Name"
          InputLabelProps={{
            shrink: true,
          }}
          value={values.firstname}
          onBlur={handleBlur}
          onChange={handleChange}
          helperText={touched.firstname ? errors.firstname : ''}
          error={touched.firstname && Boolean(errors.firstname)}
        />
        <TextField
          fullWidth
          margin="normal"
          id="lastname"
          name="lastname"
          label="Last Name"
          InputLabelProps={{
            shrink: true,
          }}
          value={values.lastname}
          onBlur={handleBlur}
          onChange={handleChange}
          helperText={touched.lastname ? errors.lastname : ''}
          error={touched.lastname && Boolean(errors.lastname)}
        />
        <TextField
          fullWidth
          multiline
          margin="normal"
          id="details"
          name="details"
          label="Details"
          InputLabelProps={{
            shrink: true,
          }}
          rows="4"
          rowsMax="4"
          value={values.details}
          onBlur={handleBlur}
          onChange={handleChange}
          helperText={touched.details ? errors.details : ''}
          error={touched.details && Boolean(errors.details)}
        />
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <ButtonsWrapper>
          <DefaultButton type="button" onClick={() => submitForm()} disabled={values.mrn.length === 0 || isLoading}>
            {isLoading ? <Spinner name="circle" color="#fff" /> : 'Submit'}
          </DefaultButton>
        </ButtonsWrapper>
      </StyledForm>
    );
  };

  private onSubmit = async (values: Values) => {
    const {trySendMessage, closeModal} = this.props;
    this.setState({
      isLoading: true,
      shouldClose: false,
      error: null,
    });
    const jsonValues = JSON.stringify(values);
    trySendMessage('', jsonValues)
      .then(() => {
        this.setState({
          isLoading: false,
          shouldClose: true,
        });
        closeModal();
      })
      .catch((e) => {
        console.error(e);
        this.setState({
          error: 'Failed to send consult message',
          isLoading: false,
          shouldClose: true,
        });
      });
  };

  private renderReadOnlyModal = () => {
    const {message} = this.props;
    return (
      <StyledOl>
        <StyledLi>
          <span>Medical record number</span>
          <span>{message!.data ? message!.data!.mrn : '--'}</span>
        </StyledLi>
        <StyledLi>
          <span>First Name</span>
          <span>{message!.data && message!.data!.firstname ? message!.data!.firstname : '--'}</span>
        </StyledLi>
        <StyledLi>
          <span>Last Name</span>
          <span>{message!.data && message!.data!.lastname ? message!.data!.lastname : '--'}</span>
        </StyledLi>
        <StyledLi>
          <span>Details</span>
          <span
            dangerouslySetInnerHTML={{
              __html: message!.data && message!.data!.details ? message!.data!.details.replace(/\n/g, '<br />') : '--',
            }}
          />
        </StyledLi>
      </StyledOl>
    );
  };
}

export default (props) => <ConsultMessageModal {...props} />;
