import React from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import ImageDropzone from 'src/components/ChangeImageModal/ImageDropzone';
import styled from 'styled-components';

// DOC: https://github.com/DominicTobias/react-image-crop

const ImageWrapper = styled.div`
  text-align: center;
`;

interface CropData {
  unit: '%' | 'px';
  width: number;
  height: number;
  aspect: number;
  x: number;
  y: number;
}

const ImageCropperWrapper = ({setImageBlob, isLoading}) => {
  const [fileSrc, setFileSrc] = React.useState(null);
  const [croppedImageUrl, setCroppedImageUrl] = React.useState(null);
  const [cropData, setCropData] = React.useState({
    unit: 'px',
    aspect: 1,
    width: 150,
    height: 150,
    x: 0,
    y: 0,
  });

  let imageRef = React.useRef(null);

  React.useEffect(() => {
    return () => {
      if (croppedImageUrl) window.URL.revokeObjectURL(croppedImageUrl);
    };
  }, [croppedImageUrl]);

  function onSelectFile(file: File) {
    if (file) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setFileSrc(reader.result));
      reader.readAsDataURL(file);
    }
  }

  function onImageLoaded(image: HTMLImageElement) {
    imageRef.current = image;
  }

  async function onCropComplete(crop: CropData) {
    if (imageRef.current && cropData.width && cropData.height) {
      const croppedImageUrl = await getCroppedImg(imageRef.current, crop);
      setCroppedImageUrl(croppedImageUrl);
    }
  }

  function onCropChange(crop: CropData, percentCrop: CropData) {
    // You could also use percentCrop: setCropData({ crop: percentCrop });
    setCropData(crop);
  }

  function getCroppedImg(image: HTMLImageElement, crop: CropData) {
    const fixWidth = 500;
    const fixHeight = 500;
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    // retain crop image quality
    // canvas.width = Math.ceil(crop.width*scaleX);
    // canvas.height = Math.ceil(crop.height*scaleY);
    canvas.width = fixWidth;
    canvas.height = fixHeight;

    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      fixWidth, // crop.width * scaleX
      fixHeight, // crop.height*scaleY
    );

    // As Base64 string
    // const base64Image = canvas.toDataURL('image/jpeg');

    // As a blob
    let fileUrl = '';
    return new Promise((resolve, reject) => {
      canvas.toBlob(
        async (blob) => {
          if (!blob) {
            //reject(new Error('Canvas is empty'));
            console.error('Canvas is empty');
            return;
          }
          window.URL.revokeObjectURL(fileUrl);
          fileUrl = window.URL.createObjectURL(blob);
          setImageBlob(blob);
          resolve(fileUrl);
        },
        'image/jpeg',
        1,
      );
    });
  }

  return (
    <ImageWrapper>
      <ImageDropzone isPhotoBeenSelected={Boolean(croppedImageUrl)} isLoading={isLoading} onSelectFile={onSelectFile} />
      {fileSrc && (
        <ReactCrop
          src={fileSrc}
          disabled={isLoading}
          crop={cropData}
          circularCrop={true}
          keepSelection={true}
          minWidth={150} // TODO: set minwidht and height of on styling
          minHeight={150}
          ruleOfThirds
          onImageLoaded={onImageLoaded}
          onComplete={onCropComplete}
          onChange={onCropChange}
        />
      )}
      {/* {croppedImageUrl && (
        <img 
          alt="Cropped" 
          style={{ maxWidth: "100%" }} 
          src={croppedImageUrl} 
        />
      )} */}
    </ImageWrapper>
  );
};

export default ImageCropperWrapper;
