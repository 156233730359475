import React from 'react';
import {Box, Grid, Container, Typography} from '@material-ui/core';
import {makeStyles, createStyles, Theme} from '@material-ui/core/styles';
import TemplateReportForm from './TemplateReportForm';
import client from 'src/apollo';
import {GET_TEMPLATE_AUDIT_REPORT} from 'src/gql/query/TemplateReportQuery';
import styled from 'styled-components';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    separator: {
      fontSize: '1.5rem',
      color: '#222222',
      userSelect: 'none',
      marginRight: '4px !important',
      cursor: 'pointer',
    },
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    large: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
    paper: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    container: {
      maxWidth: 'unset !important',
      paddingLeft: '5%',
      paddingRight: '2.5%',
    },
    link: {
      textDecoration: 'none !important',
      color: '#00859A',
      fontFamily: 'open sans',
      fontWeight: 400,
      fontSize: 14,
      fontStyle: 'normal',
      lineHeight: '20px',
      flex: 'none',
      order: 4,
      flexGrow: 0,
      margin: '0px 4px 4px 4px',
    },
    title: {
      fontFamily: 'Nunito Sans',
      fontSize: '21px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '29px',
      color: '#222222 !important',
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(2),
    },
  }),
);

export const DescriptionText = styled(Typography)`
  color: #767676 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  font-style: normal !important;
  font-family: 'Open Sans' !important;
`;

interface FormValue {
  templateIds: string[];
  startDate: string;
  endDate: string;
  senderUserIds: string[];
  recipientUserIds: string[];
}

interface Props {
  handleTemplateReport: (formValue: FormValue) => Promise<any>;
}

const ReportTabPanel = () => {
  const classes = useStyles();

  const handleTemplateReport = async ({
    templateIds,
    startDate,
    endDate,
    senderUserIds,
    recipientUserIds,
  }: FormValue) => {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await client.query({
          query: GET_TEMPLATE_AUDIT_REPORT,
          variables: {
            templateIds: templateIds,
            startDate: startDate,
            endDate: endDate,
            senderUserIds: senderUserIds,
            recipientUserIds: recipientUserIds,
          },
        });
        resolve('done!');
      } catch (e) {
        console.error(e); // TODO: proper error handling
        reject('network error');
      }
    });
  };

  return (
    <Container id="reportDemoPage" className={classes.container} style={{marginTop: 16}} maxWidth="xs">
      <div className={classes.paper}>
        <Grid item md={6} sm={12}>
          <Box>
            <Typography variant="body1" className={classes.title}>
              Template message report
            </Typography>
            <DescriptionText>
              Generate summary reports based on template messages that you did not personally send or receive (e.g. for
              a department). Reports will be exported as an XLS (Microsoft Excel) file, and sent to you on Hypercare as
              a message attachment.
            </DescriptionText>
            <TemplateReportForm handleTemplateReport={handleTemplateReport} />
          </Box>
        </Grid>
      </div>
    </Container>
  );
};

export default ReportTabPanel;
