import React, {useEffect} from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import * as ReactModal from 'react-modal';
import {Query, QueryResult} from 'react-apollo';
import {Message, MessageTemplateStatus} from 'src/types';
import UserListItemDetails from 'src/pages/MessengerPage/messenger/UserListItemDetails';
import moment from 'moment';
import {StyledModal, CloseBtn, Header, ScrollLayout} from 'src/styles/styled-components/ModalStyleComponents';
import GetMessageQuery from 'src/gql/query/GetMessageQuery';
import {withRouter, RouteComponentProps} from 'react-router-dom';
import LoadingDiv from 'src/components/LoadingDiv';
import AnalyticsManager, {PAGE_VIEWS} from 'src/analytics/AnalyticsManager';
import {TEMPLATE} from 'src/constants/messageTypes';
import {RESPONDED, RESPONSE_NOT_REQUIRED, UNREAD} from 'src/constants/detailMessageStatus';
import {IsFeatureFlagEnabled} from 'src/utils/FeatureFlagManager';
import {FeatureFlagResult} from 'src/utils/FeatureFlags';
import OptimizedUserListItemDetails from '../OptimizedUserListItemDetails';
import GetDetailMessageQuery from 'src/gql/query/GetDetailMessageQuery';

const MessageSection = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  color: dimgrey;
  margin-bottom: 2em;
`;

const MessageHolder = styled.div`
  word-wrap: break-word;
  font-size: 18px;
  white-space: pre-wrap;
  font-weight: 500;
  margin-bottom: 5px;
  color: ${(props) => props.theme.chatTextTitle};
`;

const RecipientSection = styled.div``;
const DelivertoRecipient = styled.div``;

const ReadbyRecipient = styled.div`
  margin-bottom: 20px;
`;

const SectionLabel = styled.div`
  color: ${(props) => props.theme.labelGrey};
  text-transform: uppercase;
  font-size: 13px;
  padding-bottom: 5px;
  border-bottom: 1px solid lightgrey;
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

const TimeStampHolder = styled.div`
  text-align: right;
  min-width: max-content;
  font-size: 11px;
  margin-left: auto;
  color: dimgrey;
`;

interface Props extends RouteComponentProps<{chatId: string}> {
  message: Message;
  closeModal: () => void;
  reactModalProps: ReactModal.Props;
}

const DetailMessageModal = ({message, reactModalProps, closeModal, match}: Props) => {
  const {chatId} = match.params;
  const messageId = message.id;
  const {isOpen} = reactModalProps;
  const optimizeChatGraphQLFragmentsFlag = IsFeatureFlagEnabled(FeatureFlagResult.optimizeChatGraphQLFragments);

  useEffect(() => {
    AnalyticsManager.recordPageVisited(PAGE_VIEWS.messageDetails);
  }, []);

  const formatTimeStamp = (timeStamp: string, hasAt: boolean) => {
    return moment(timeStamp).format(hasAt ? `MMMM DD, YYYY [at] hh:mm a` : `YYYY-MM-DD, hh:mm a`);
  };

  return (
    <StyledModal
      isOpen={isOpen}
      shouldCloseOnEsc={true}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={true}
      onRequestClose={closeModal}
    >
      <Header>
        <span>Message Detail</span>
        <CloseBtn onClick={closeModal} />
      </Header>
      <Query query={GetDetailMessageQuery} variables={{chatId, messageId}} fetchPolicy="network-only">
        {({loading, error, data}: QueryResult) => {
          if (loading || error) return <LoadingDiv />;
          const message = R.pathOr({}, ['chat', 'message'], data) as Message;
          const messageHeader = message?.type === TEMPLATE ? message?.template?.formTitle : message?.message;
          return (
            <>
              <ScrollLayout>
                <MessageSection>
                  <MessageHolder>{messageHeader}</MessageHolder>
                  {message?.type === TEMPLATE && (
                    <MessageHolder style={{display: 'flex'}}>
                      Status:&nbsp;
                      <div style={{fontWeight: 300}}>
                        {message?.template?.status === MessageTemplateStatus.pending_response
                          ? UNREAD
                          : message?.template?.status === MessageTemplateStatus.response_not_required
                          ? RESPONSE_NOT_REQUIRED
                          : RESPONDED}
                      </div>
                    </MessageHolder>
                  )}
                  <div>{formatTimeStamp(message.dateCreated, true)}</div>
                  <div>
                    Sent by {message.sender.firstname} {message.sender.lastname}
                  </div>
                </MessageSection>
                <RecipientSection>
                  <SectionLabel>Read By</SectionLabel>
                  <ReadbyRecipient>
                    {message.readBy.map((obj, i) => (
                      <FlexContainer key={`readBy-${i}`}>
                        {optimizeChatGraphQLFragmentsFlag ? (
                          <OptimizedUserListItemDetails colleague={obj.user} />
                        ) : (
                          <UserListItemDetails colleague={obj.user} />
                        )}
                        <TimeStampHolder>{formatTimeStamp(obj.timestamp, false)}</TimeStampHolder>
                      </FlexContainer>
                    ))}
                  </ReadbyRecipient>
                  <SectionLabel>Delivered To</SectionLabel>
                  <DelivertoRecipient>
                    {message.deliveredTo?.map((obj, j) => (
                      <FlexContainer key={`deliTo-${j}`}>
                        {optimizeChatGraphQLFragmentsFlag ? (
                          <OptimizedUserListItemDetails colleague={obj.user} />
                        ) : (
                          <UserListItemDetails colleague={obj.user} />
                        )}
                        <TimeStampHolder>{formatTimeStamp(obj.timestamp, false)}</TimeStampHolder>
                      </FlexContainer>
                    ))}
                  </DelivertoRecipient>
                </RecipientSection>
              </ScrollLayout>
            </>
          );
        }}
      </Query>
    </StyledModal>
  );
};

const ConnectedDetailMessageModal = withRouter(DetailMessageModal);

export default (props) => <ConnectedDetailMessageModal {...props} />;
