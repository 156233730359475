import {
  AccountSelectionTitleContainer,
  CenteredDiv,
  IconWrapperV2,
  NonWrappingSpan,
  RegionSwitchActionText,
} from '../styled/login.styled';
import {HCCustomSpan, HCHeadingTwo} from '../../components/HypercareComponents';
import theme from '../../styles/theme';
import {
  AuthRegionMap,
  LOGIN_BOX_DESCRIPTION_SWITCH_REGION_TEXT,
  LOGIN_BOX_DESCRIPTION_TEXT,
  LOGIN_PAGE_CURRENT_REGION_TITLE,
  LOGIN_PAGE_WELCOME_TITLE,
  UNITED_STATES,
  UNITED_STATES_ABBREVIATION,
} from '../../constants/strings';
import {AuthRegionFlag} from '../../components/shared/AuthRegionFlag';
import React from 'react';
import {BackArrowButton} from '../svgs/BackArrowButton';
import {AuthRegion} from '../../types';

interface SavedAccountsTitleProps {
  currentAuthRegion: AuthRegion;
  handleSwitchRegion: () => void;
}
interface EditAccountsTitleProps {
  onClick: () => void;
  title?: string;
  padding?: string;
}
interface CurrentRegionTextProps {
  currentRegion: AuthRegion;
  onClick: () => void;
}

export const AccountSelectionEditModeTitle = ({onClick, title, padding}: EditAccountsTitleProps) => {
  return (
    <div style={{display: 'flex', padding: padding ? padding : '24px 24px 20px 24px'}}>
      <IconWrapperV2 onClick={onClick}>
        <BackArrowButton />
      </IconWrapperV2>
      {title && (
        <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
          <HCHeadingTwo color={theme.black}>{title}</HCHeadingTwo>
        </div>
      )}
    </div>
  );
};

export const CurrentRegionText = ({currentRegion, onClick}: CurrentRegionTextProps) => {
  return (
    <NonWrappingSpan>
      <HCCustomSpan fontWeight={'600'} color={'#4A4A4A'}>
        {LOGIN_PAGE_CURRENT_REGION_TITLE}
      </HCCustomSpan>
      <AuthRegionFlag currentRegion={currentRegion} size="small" />
      {currentRegion === UNITED_STATES_ABBREVIATION ? (
        <HCCustomSpan fontWeight={'600'} color={'#4A4A4A'}>
          {UNITED_STATES}
        </HCCustomSpan>
      ) : (
        <HCCustomSpan fontWeight={'600'} color={'#4A4A4A'}>
          {AuthRegionMap[currentRegion]}
        </HCCustomSpan>
      )}
      {'. '}
      <HCCustomSpan color={'#4A4A4A'}>{LOGIN_BOX_DESCRIPTION_TEXT(currentRegion, AuthRegionMap)}</HCCustomSpan>
      <RegionSwitchActionText onClick={onClick}>
        <HCCustomSpan color={'#1B97AA'}>{LOGIN_BOX_DESCRIPTION_SWITCH_REGION_TEXT}</HCCustomSpan>
      </RegionSwitchActionText>
    </NonWrappingSpan>
  );
};

export const AccountSelectionTitle = ({currentAuthRegion, handleSwitchRegion}: SavedAccountsTitleProps) => {
  return (
    <AccountSelectionTitleContainer>
      <CenteredDiv>
        <HCHeadingTwo color={theme.black}>{LOGIN_PAGE_WELCOME_TITLE}</HCHeadingTwo>
      </CenteredDiv>
      <CurrentRegionText currentRegion={currentAuthRegion} onClick={handleSwitchRegion} />
    </AccountSelectionTitleContainer>
  );
};
