import {
  css,
  // ThemedStyledFunction
} from 'styled-components';

export const minAppWidth = 768;

const sizes = {
  xl: 1920,
  lg: 1280,
  widescreen: 1024,
  md: 960,
  minAppWidth,
  sm: 600,
  xs: 376,
};

// https://github.com/styled-components/styled-components/issues/430
// Iterate through the sizes and create a media template
export const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (literals: TemplateStringsArray, ...placeholders: any[]) => css`
    @media (max-width: ${sizes[label]}px) {
      ${css(literals, ...placeholders)};
    }
  `;
  return acc;
}, {} as Record<keyof typeof sizes, (l: TemplateStringsArray, ...p: any[]) => string>);
