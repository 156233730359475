import React, {useState} from 'react';
import styled from 'styled-components';
import {Chat} from 'src/types';
import ChatInformationMemberList from 'src/pages/MessengerPage/messenger/chat-info-layout/ChatInformationMemberList';
import AddMemberButtonIcon from 'src/svgs/AddMemberButtonIcon';
import ChatInformationAddUserModal from 'src/pages/MessengerPage/messenger/chat-info-layout/ChatInformationAddUserModal';
import ChatInformationOptionsButton from 'src/pages/MessengerPage/messenger/chat-info-layout/ChatInformationOptionsButton';
import {withLDConsumer} from 'launchdarkly-react-client-sdk';
import HypercareAccordion from 'src/components/HypercareAccordion';
import ChatInformationOptionsWrapper from 'src/pages/MessengerPage/messenger/chat-info-layout/ChatInformationOptionsWrapper';
import ChatInformationAddUserContainerV2 from './ChatInformationAddUserContainerV2';
import {IsFeatureFlagEnabled} from 'src/utils/FeatureFlagManager';
import {FeatureFlagResult} from 'src/utils/FeatureFlags';

const Layout = styled.div`
  flex: 1;
  position: relative;
  padding: 1em;
`;

const Header = styled.div`
  color: ${(props) => props.theme.warmGrey};
  padding-right: 0.4em;
  margin-top: -5px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const AddMember = styled.div`
  padding-top: 7px;
  margin-left: 8px;
  display: flex;
  max-width: max-content;
  align-items: center;
  cursor: pointer;
`;

const AddMemberSpan = styled.div`
  margin-left: 5px;
`;

interface Props {
  chat: Chat;
  flags?: {[key: string]: boolean};
}

const ChatInformationMembers: React.FC<Props> = ({chat, flags}) => {
  const [showModal, setShowModal] = useState(false);
  const {members} = chat;

  const paginatedContactsFeatureFlag = IsFeatureFlagEnabled(FeatureFlagResult.paginatedContacts);

  if (paginatedContactsFeatureFlag === undefined) {
    return null;
  }

  const closeAddMemberModal = () => {
    setShowModal(false);
  };

  const showAddMemberModal = () => {
    setShowModal(true);
  };

  return (
    <Layout>
      <Header>
        Group Members
        <ChatInformationOptionsButton chat={chat} />
      </Header>

      <AddMember onClick={showAddMemberModal}>
        <AddMemberButtonIcon />
        <AddMemberSpan>Add Member</AddMemberSpan>
      </AddMember>

      <ChatInformationMemberList chat={chat} members={members} />
      {showModal && !paginatedContactsFeatureFlag ? (
        <ChatInformationAddUserModal
          chat={chat}
          closeModal={closeAddMemberModal}
          reactModalProps={{isOpen: showModal}}
        />
      ) : (
        <ChatInformationAddUserContainerV2
          chat={chat}
          closeModal={closeAddMemberModal}
          reactModalProps={{isOpen: showModal}}
        />
      )}
    </Layout>
  );
};

export default withLDConsumer()(ChatInformationMembers);
