import {v4 as uuid} from 'uuid';
import {AuthPayload, UserOrganizationSwitcherPayload, User, AuthRegion} from 'src/types';
import store from 'src/redux';
import {updateTokenLastUpdatedAt} from 'src/redux/rootActions';
import {
  AUTH_INFO,
  CURRENT_ORGANIZATION,
  HYPERCARE_REGION,
  ORGANIZATION_ACCOUNTS_DATA,
  SESSION_ID,
} from 'src/constants/storageKeys';
import {CANADA_ABBREVIATION} from 'src/constants/strings';
import {localStorageService} from '../services/localStorageService';
import {OrganizationAccountsCacheData} from '../types/sta';
import {getCurrentLoggedInAccount} from './sta/staUtils';

export function updateScopeToken(payload: {organizationId: string} | UserOrganizationSwitcherPayload) {
  const parsedAuthInfo = getParsedAuthInfo();
  const {organizationId} = payload;
  const newScope = {organizationId};
  parsedAuthInfo!.scopeToken = btoa(JSON.stringify(newScope));
  localStorage.setItem(AUTH_INFO, JSON.stringify(parsedAuthInfo));
}

export function getParsedCurrentOrganization() {
  const currentOrganization = localStorage.getItem(CURRENT_ORGANIZATION);
  const parsedCurrentOrganization: {organization: UserOrganizationSwitcherPayload} | null = currentOrganization
    ? JSON.parse(currentOrganization)
    : null;
  return parsedCurrentOrganization;
}

export function getParsedSessionID() {
  const isInSessionStorage = sessionStorage.getItem(SESSION_ID);
  if (isInSessionStorage) return sessionStorage.getItem(SESSION_ID);
  return null;
}

export function isSessionIDAvailableInBothStorage() {
  const parsedAuthInfo: AuthPayload | null = getParsedAuthInfo();
  const isInSessionStorage = sessionStorage.getItem(SESSION_ID);
  return parsedAuthInfo && parsedAuthInfo.sessionID && isInSessionStorage;
}

export function trySetSessionID() {
  const parsedAuthInfo: AuthPayload | null = getParsedAuthInfo();
  const isInSessionStorage = sessionStorage.getItem(SESSION_ID);
  if (parsedAuthInfo) {
    if (parsedAuthInfo.sessionID && !isInSessionStorage) {
      sessionStorage.setItem(SESSION_ID, parsedAuthInfo.sessionID);
    } else {
      const sessionID = uuid();
      parsedAuthInfo.sessionID = sessionID;
      localStorage.setItem(AUTH_INFO, JSON.stringify(parsedAuthInfo));
      sessionStorage.setItem(SESSION_ID, sessionID);
    }
  }
}

export function updateLocalStorageTokenInfo(payload: AuthPayload) {
  const authInfo = localStorage.getItem(AUTH_INFO);
  const parsedAuthInfo: AuthPayload = JSON.parse(authInfo);

  const {accessToken, accessTokenExpiresAt, refreshToken, refreshTokenExpiresAt} = payload;

  const newParsedAuthInfo = {
    ...parsedAuthInfo,
    accessToken,
    accessTokenExpiresAt,
    refreshToken,
    refreshTokenExpiresAt,
  };
  localStorage.setItem(AUTH_INFO, JSON.stringify(newParsedAuthInfo));
  // @ts-ignore
  store.dispatch(updateTokenLastUpdatedAt());
}

export function updateLocalStorageUserInfo(user: User) {
  const authInfo = localStorage.getItem(AUTH_INFO);
  const parsedAuthInfo: AuthPayload = JSON.parse(authInfo);
  const newParsedAuthInfo = {
    ...parsedAuthInfo,
    user: {
      ...user,
    },
  };
  localStorage.setItem(AUTH_INFO, JSON.stringify(newParsedAuthInfo));
}

export function getParsedAuthRegion(): AuthRegion {
  const authRegionFromLocalStorage = window.localStorage.getItem(HYPERCARE_REGION) as AuthRegion;
  const authRegionFromReduxStore = store?.getState().hypercareRegion.currentAuthRegion;

  return authRegionFromLocalStorage ?? authRegionFromReduxStore ?? CANADA_ABBREVIATION;
}

export default function getParsedAuthInfo() {
  const authInfo = localStorage.getItem(AUTH_INFO);
  const parsedAuthInfo: AuthPayload | null = authInfo ? JSON.parse(authInfo) : null;
  return parsedAuthInfo;
}
