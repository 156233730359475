import React from 'react';
import moment from 'moment';

export interface SchedulingVariableContextInterface {
  currentDepartmentId: number;
  startOfMonthUTC: string;
  endOfMonthUTC: string;
  setScheduleQueryVariables: React.Dispatch<React.SetStateAction<ScheduleQueryVariables>>;
}

export interface ScheduleQueryVariables {
  currentDepartmentId: number;
  startOfMonthUTC: string;
  endOfMonthUTC: string;
}

export const SchedulingVariableContext = React.createContext<SchedulingVariableContextInterface>({
  currentDepartmentId: null,
  startOfMonthUTC: null,
  endOfMonthUTC: null,
  setScheduleQueryVariables: () => {},
});

const SchedulingVariableProvider = (props: {defaultDepartmentId: number; children: React.ReactElement}) => {
  const {children, defaultDepartmentId} = props;
  const [scheduleQueryVariables, setScheduleQueryVariables] = React.useState<ScheduleQueryVariables>({
    currentDepartmentId: defaultDepartmentId,
    startOfMonthUTC: moment().startOf('month').toISOString(),
    endOfMonthUTC: moment().endOf('month').millisecond(0o00).toISOString(),
  });

  const {currentDepartmentId, startOfMonthUTC, endOfMonthUTC} = scheduleQueryVariables;

  return (
    <SchedulingVariableContext.Provider
      value={{
        currentDepartmentId,
        startOfMonthUTC,
        endOfMonthUTC,
        setScheduleQueryVariables,
      }}
    >
      {children}
    </SchedulingVariableContext.Provider>
  );
};

export default SchedulingVariableProvider;
