import React from 'react';
import client from 'src/apollo';
import store from 'src/redux';
import {
  InviteUserByAddresses,
  InviteUserByIds,
  InviteUserByInviteId,
} from 'src/gql/mutation/InviteUserToOrganizationMutation';
import {InvitedUser} from 'src/types';
import {getFullName} from 'src/utils/user';
import AnalyticsManager, {EVENTS} from 'src/analytics/AnalyticsManager';

const resendInvitation = async (
  invitedUser: InvitedUser,
  organizationId: number | string,
  coSigningInvitesFF: boolean,
) => {
  try {
    let resultName: string;
    if (coSigningInvitesFF) {
      await client.mutate({
        mutation: InviteUserByInviteId,
        fetchPolicy: 'no-cache',
        variables: {
          inviteId: invitedUser.id,
        },
      });

      AnalyticsManager.applyAnalytics({
        eventName: EVENTS.resendInviteButtonPressed,
        params: {
          invite_id: invitedUser.id,
        },
      });

      if (invitedUser.address) resultName = invitedUser.address.address;
      else resultName = getFullName(invitedUser.user);
    } else {
      if (invitedUser.address) {
        resultName = invitedUser.address.address;
        const invitedUserPayload = (({address, type, access, ordinal}) => ({address, type, access, ordinal}))(
          invitedUser.address,
        );
        await client.mutate({
          mutation: InviteUserByAddresses,
          fetchPolicy: 'no-cache',
          variables: {
            addresses: [
              {
                ...invitedUserPayload,
                __typename: undefined,
              },
            ],
            organizationId,
          },
        });
      } else {
        resultName = getFullName(invitedUser.user);
        await client.mutate({
          mutation: InviteUserByIds,
          fetchPolicy: 'no-cache',
          variables: {
            userIds: [invitedUser.user.id],
            organizationId,
          },
        });
      }
    }
    return Promise.resolve(resultName);
  } catch (e) {
    return Promise.reject(e);
  }
};

const useReInviteUser = () => {
  const succeedRef = React.useRef([]);
  const [succeedList, setSucceedList] = React.useState<string[]>([]);
  const loadingRef = React.useRef([]);
  const [loadingList, setLoadingList] = React.useState<string[]>([]);

  const tryResendInvitation = React.useCallback(
    async (invitedUser: InvitedUser, coSigningInvites: boolean) => {
      try {
        loadingRef.current.push(invitedUser.id);
        setLoadingList([...loadingRef.current]);

        const {organizationId} = store.getState().organization;

        const resultName = await resendInvitation(invitedUser, organizationId, coSigningInvites);

        succeedRef.current.push(invitedUser.id);
        setSucceedList([...succeedRef.current]);

        return Promise.resolve(resultName);
      } catch (e) {
        return Promise.reject(e);
      } finally {
        loadingRef.current = loadingRef.current.filter((id) => id !== invitedUser.id);
        setLoadingList([...loadingRef.current]);
      }
    },
    [loadingRef, succeedRef],
  );

  return {
    tryResendInvitation,
    succeedList,
    loadingList,
  };
};

export default useReInviteUser;
