import React from 'react';
import {
  Box,
  Tab,
  Tabs,
  Dialog,
  Divider,
  TextField,
  IconButton,
  makeStyles,
  DialogContent,
  DialogActions,
  InputAdornment,
  ThemeProvider,
} from '@material-ui/core';
import {toast} from 'react-toastify';
import styled from 'styled-components';
import Rating from '@material-ui/lab/Rating';
import {createTheme} from '@material-ui/core/styles';
import {useMutation} from '@apollo/react-hooks';
import MuiDialogTitle from '@material-ui/core/DialogTitle';

import store, {typedUseSelector} from 'src/redux';
import Theme from 'src/styles/theme';
import {MessageTemplate} from 'src/types';
import {Closemark} from 'src/svgs/Closemark';
import SearchIcon from 'src/svgs/SearchIcon';
import LoadingDiv from 'src/components/LoadingDiv';
import {actions} from 'src/redux/actions/messageTemplates';
import {Transition} from 'src/components/HypercareComponents';
import messageSupportUser from 'src/utils/messengerHelper/createSupportChat';
import PreviewMessageTemplate from './message-template/PreviewMessageTemplate';
import FavouriteTemplateMessage, {UnfavouriteTemplateMessage} from 'src/gql/mutation/ToggleTemplateMessageFavourite';
import useTemplateListViewModalData from './message-template/hooks/useTemplateListViewModalData';
import useFavouriteTemplateListViewModal from './message-template/hooks/useFavouriteTemplateListViewModal';
import {SecondaryButton, DialogTitleTypography} from 'src/styles/styled-components/StyledMaterialComponents';
import MessageTemplateLayout from 'src/pages/MessengerPage/messenger/messages-layout/message-template/MessageTemplateLayout';
import PriorityMessageTemplate from 'src/pages/MessengerPage/messenger/messages-layout/message-template/PriorityMessageTemplate';
import MessageTemplateDisclaimerModal from 'src/pages/MessengerPage/messenger/messages-layout/message-template/MessageTemplateDisclaimerModal';

enum TabType {
  Favourites,
  All,
}

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(2),
  },
  listItemAvatar: {
    minWidth: 0,
    marginRight: 18,
  },
  tab: {
    minWidth: 0,
    textTransform: 'none',
    minHeight: 0,
  },
  tabs: {
    minHeight: 0,
  },
  templateSearchTextfield: {
    marginTop: 18,
  },
  muiDialogTitle: {
    padding: '16px 24px 0 24px',
  },
  divider: {
    marginTop: '1vh',
  },
  confirmDialogSubtext: {
    color: '#000',
    fontWeight: 'bold',
  },
  dialogActionRoot: {
    padding: '16px 24px 24px 0',
  },
  sendMessageDialogAction: {
    padding: '16px 24px 24px 24px',
  },
  templateDialog: {
    minHeight: '70vh',
    maxHeight: '80vh',
  },
  templateDescription: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
}));

const EmptyTemplateListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 60%;
  align-items: center;
  height: 30vh;
  justify-content: center;
`;

const BackdropLoader = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
`;

const TemplateListWrapper = styled.div`
  display: flex;
  &:hover {
    background-color: #f2f2f2;
  }
`;

const TemplateFavUnfavWrapper = styled.div`
  margin-right: 16px;
  align-self: center;
  margin-bottom: 1vh;
`;

const TemplateDetailsWrapper = styled.div`
  margin-top: 1vh;
  margin-bottom: 1vh;
  display: grid;
`;

const TemplateDetailDividerWrapper = styled.div`
  flex: auto !important;
  width: 100% !important;
  cursor: pointer;
`;

const TemplateTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`;

const TemplateDescription = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const EmptyListHeader = styled.h3`
  font-weight: 700;
  font-size: 21px;
  color: #000;
  margin-bottom: 8px;
`;

const EmptyListSubheader = styled.div`
  color: ${(props) => props.theme.greyishBrown};
  text-align: center;
  line-height: 24px;
`;

const EmptyViewButton = styled(SecondaryButton)`
  margin-top: 20px !important;
`;

interface EmptyTemplateListProps {
  selectedTabValue: number;
  changeTabSelection: (tabIndex: TabType) => void;
}
const EmptyTemplateList = ({selectedTabValue, changeTabSelection}: EmptyTemplateListProps) => {
  const EmptyTemplateStrings = {
    All: {
      header: 'No templates available',
      subheader: 'Contact your Administrators or Hypercare directly to help set this up.',
      buttonText: 'Contact Hypercare support',
      buttonOnClickHandler: () => messageSupportUser(),
    },
    Favourites: {
      header: 'No favourite templates',
      subheader: 'Tap on the star icon and the template will show up here, so it’s easier to get to.',
      buttonText: 'View all templates',
      buttonOnClickHandler: () => changeTabSelection(TabType.All),
    },
  };
  return (
    <EmptyTemplateListWrapper>
      <EmptyListHeader>{EmptyTemplateStrings[TabType[selectedTabValue]].header}</EmptyListHeader>
      <EmptyListSubheader>{EmptyTemplateStrings[TabType[selectedTabValue]].subheader}</EmptyListSubheader>
      <EmptyViewButton onClick={EmptyTemplateStrings[TabType[selectedTabValue]].buttonOnClickHandler}>
        {EmptyTemplateStrings[TabType[selectedTabValue]].buttonText}
      </EmptyViewButton>
    </EmptyTemplateListWrapper>
  );
};

interface MessageTemplateListModalProps {
  chatId: string;
  isSingleChat: boolean;
  showMessageTemplateListModal: boolean;
  openMessageTemplateListModal: () => void;
  closeMessageTemplateListModal: () => void;
}
const MessageTemplateListModal = ({
  chatId,
  isSingleChat,
  showMessageTemplateListModal,
  closeMessageTemplateListModal,
  openMessageTemplateListModal,
}: MessageTemplateListModalProps) => {
  const classes = useStyles({}),
    [selectedTabValue, setSelectedTabValue] = React.useState<TabType>(TabType.Favourites),
    [messageTemplateLayoutModal, setMessageTemplateLayoutModal] = React.useState<boolean>(false),
    [messageTemplateDisclaimerModal, setMessageTemplateDisclaimerModal] = React.useState<boolean>(false),
    [previewMessageTemplateModal, setPreviewMessageTemplateModal] = React.useState<boolean>(false),
    [priorityMessageTemplate, setPriorityMessageTemplate] = React.useState<boolean>(false),
    [searchMessageTemplateString, setSearchMessageTemplateString] = React.useState<string>(''),
    {selectedTemplate} = typedUseSelector((state) => state.messageTemplate),
    [markTemplateFavourite, {loading: favTemplateLoading}] = useMutation(FavouriteTemplateMessage),
    [markTemplateUnfavourite, {loading: unfavTemplateLoading}] = useMutation(UnfavouriteTemplateMessage),
    {allFilteredTemplates, allTemplateListLoadingState, allTemplateListRefetch} = useTemplateListViewModalData({
      chatId,
      searchMessageTemplateString,
      isSingleChat,
    }),
    {favouriteFilteredTemplates, favouriteTemplateListLoadingState, favouriteTemplateListRefetch} =
      useFavouriteTemplateListViewModal({chatId, searchMessageTemplateString, isSingleChat});

  const handleTabChange = (event: React.ChangeEvent<{}>, newTabValue: number) => {
    setSelectedTabValue(newTabValue);
  };

  // Edit Message Template form modal close icon click handler
  const handleMessageTemplateLayoutClose = () => {
    setMessageTemplateLayoutModal(false);
  };

  // Message Template Disclaimer Modal Close button handler
  const handleMessageTemplateDisclaimerModalClose = () => {
    setMessageTemplateDisclaimerModal(false);
  };

  // Message Template Disclaimer Modal Next button handler
  const disclaimerModalNextBtnHandler = () => {
    setMessageTemplateDisclaimerModal(false);
    setMessageTemplateLayoutModal(true);
  };

  // Edit message template Previous button handler
  const templateLayoutPreviousBtnHandler = () => {
    setMessageTemplateLayoutModal(false);
    if (selectedTemplate?.disclaimer) setMessageTemplateDisclaimerModal(true);
    else openMessageTemplateListModal();
  };

  // Edit Message template form Next button handler
  const templateLayoutNextBtnHandler = () => {
    setMessageTemplateLayoutModal(false);
    setPriorityMessageTemplate(true);
  };

  // Set message template priority modal Review Request button handler
  const templatePriorityReviewRequestBtnHandler = () => {
    setPriorityMessageTemplate(false);
    setPreviewMessageTemplateModal(true);
  };

  // Set message template priority modal previous button handler
  const templatePriorityPreviousBtnHandler = () => {
    setPriorityMessageTemplate(false);
    setMessageTemplateLayoutModal(true);
  };

  // Review message template form previous button click handler
  const previewTemplatePreviousBtnHandler = () => {
    setPreviewMessageTemplateModal(false);
    setPriorityMessageTemplate(true);
  };

  function tabsProps(index: number) {
    return {
      id: `message-template-tab-${index}`,
      'aria-controls': `message-template-tabpanel-${index}`,
    };
  }

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    selectedTabValue: number;
  }
  function TabPanel(props: TabPanelProps) {
    const {children, selectedTabValue, index, ...other} = props;

    return (
      <div
        role="tabpanel"
        hidden={selectedTabValue !== index}
        id={`template-tabpanel-${index}`}
        aria-labelledby={`template-tab-${index}`}
        {...other}
      >
        {selectedTabValue === index && <Box p={0}>{children}</Box>}
      </div>
    );
  }

  const FavouriteMessageTemplateList = () => {
    const handleFavouriteTemplateListItemClick = (selectedTemplate: MessageTemplate) => {
      closeMessageTemplateListModal();
      store.dispatch(actions.setSelectedTemplate(selectedTemplate));
      if (selectedTemplate?.disclaimer) setMessageTemplateDisclaimerModal(true);
      else setMessageTemplateLayoutModal(true);
    };

    return (
      <React.Fragment>
        {(favTemplateLoading || unfavTemplateLoading) && (
          <BackdropLoader>
            <LoadingDiv />
          </BackdropLoader>
        )}
        {!favouriteTemplateListLoadingState && favouriteFilteredTemplates?.length > 0 ? (
          favouriteFilteredTemplates.map((template, index) => {
            const {formTitle, description, id} = template;
            return (
              <React.Fragment key={index}>
                <TemplateListWrapper>
                  <TemplateFavUnfavWrapper>
                    <Rating
                      name="favourite-template"
                      max={1}
                      value={1}
                      precision={1}
                      onClick={(_) => {
                        markTemplateUnfavourite({variables: {chatId, templateId: id}})
                          .then((result) => {
                            favouriteTemplateListRefetch();
                            allTemplateListRefetch();
                          })
                          .catch((e) => {
                            console.error(e);
                            toast.error(
                              'Failed to mark template as Unfavourite, please check your internet connection and try again',
                            );
                          });
                      }}
                    />
                  </TemplateFavUnfavWrapper>
                  <TemplateDetailDividerWrapper
                    onClick={() => handleFavouriteTemplateListItemClick(favouriteFilteredTemplates[index])}
                  >
                    <TemplateDetailsWrapper>
                      <TemplateTitle>{formTitle}</TemplateTitle>
                      <TemplateDescription>{description}</TemplateDescription>
                    </TemplateDetailsWrapper>
                    <Divider className={classes.divider} />
                  </TemplateDetailDividerWrapper>
                </TemplateListWrapper>
              </React.Fragment>
            );
          })
        ) : !favouriteTemplateListLoadingState && favouriteFilteredTemplates?.length <= 0 ? (
          <EmptyTemplateList selectedTabValue={selectedTabValue} changeTabSelection={setSelectedTabValue} />
        ) : (
          <div style={{height: '30vh'}}>
            <LoadingDiv />
          </div>
        )}
      </React.Fragment>
    );
  };

  const AllMessageTemplateList = () => {
    const classes = useStyles();

    const handleListItemClick = (selectedTemplate: MessageTemplate) => {
      closeMessageTemplateListModal();
      store.dispatch(actions.setSelectedTemplate(selectedTemplate));
      if (selectedTemplate?.disclaimer) setMessageTemplateDisclaimerModal(true);
      else setMessageTemplateLayoutModal(true);
    };

    return (
      <React.Fragment>
        {(favTemplateLoading || unfavTemplateLoading) && (
          <BackdropLoader>
            <LoadingDiv />
          </BackdropLoader>
        )}
        {!allTemplateListLoadingState && allFilteredTemplates?.length > 0 ? (
          allFilteredTemplates.map((template, index) => {
            const {formTitle, description, id} = template;
            let isFav = favouriteFilteredTemplates.filter((favTemplate) => favTemplate.id === id);
            return (
              <React.Fragment key={index}>
                <TemplateListWrapper>
                  <TemplateFavUnfavWrapper>
                    <Rating
                      max={1}
                      precision={1}
                      name="all-template"
                      value={isFav.length}
                      onClick={() => {
                        if (isFav.length > 0) {
                          markTemplateUnfavourite({variables: {chatId, templateId: id}})
                            .then((_) => {
                              favouriteTemplateListRefetch();
                              allTemplateListRefetch();
                            })
                            .catch((e) => {
                              console.error(e);
                              toast.error(
                                'Failed to mark template as Unfavourite, please check your internet connection and try again',
                              );
                            });
                        } else {
                          markTemplateFavourite({variables: {chatId, templateId: id}})
                            .then((_) => {
                              favouriteTemplateListRefetch();
                              allTemplateListRefetch();
                            })
                            .catch((e) => {
                              console.error(e);
                              toast.error(
                                'Failed to mark template as Favourite, please check your internet connection and try again',
                              );
                            });
                        }
                      }}
                    />
                  </TemplateFavUnfavWrapper>
                  <TemplateDetailDividerWrapper onClick={() => handleListItemClick(allFilteredTemplates[index])}>
                    <TemplateDetailsWrapper>
                      <TemplateTitle>{formTitle}</TemplateTitle>
                      <TemplateDescription>{description}</TemplateDescription>
                    </TemplateDetailsWrapper>
                    <Divider className={classes.divider} />
                  </TemplateDetailDividerWrapper>
                </TemplateListWrapper>
              </React.Fragment>
            );
          })
        ) : !allTemplateListLoadingState && allFilteredTemplates?.length <= 0 ? (
          <EmptyTemplateList selectedTabValue={selectedTabValue} changeTabSelection={setSelectedTabValue} />
        ) : (
          <div style={{height: '30vh'}}>
            <LoadingDiv />
          </div>
        )}
      </React.Fragment>
    );
  };

  const messageTemplateTheme = createTheme({
    palette: {
      primary: {main: Theme.chatTeal},
      secondary: {main: Theme.watermelon},
    },
    typography: {
      fontFamily: 'Open Sans',
    },
  });

  return (
    <ThemeProvider theme={messageTemplateTheme}>
      <Dialog
        maxWidth={'sm'}
        fullWidth={true}
        onClose={closeMessageTemplateListModal}
        open={showMessageTemplateListModal}
        TransitionComponent={Transition}
        classes={{paper: classes.templateDialog}}
      >
        <MuiDialogTitle disableTypography classes={{root: classes.muiDialogTitle}}>
          <DialogTitleTypography variant="h6">Message templates</DialogTitleTypography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={() => {
              store.dispatch(actions.resetToInitalState());
              closeMessageTemplateListModal();
            }}
          >
            <Closemark />
          </IconButton>
          <TextField
            className={classes.templateSearchTextfield}
            id="template-search"
            type="text"
            color="secondary"
            size="small"
            fullWidth={true}
            variant="outlined"
            onChange={(event) => setSearchMessageTemplateString(event.target.value)}
            placeholder="Search"
            InputProps={{
              startAdornment: (
                <InputAdornment position={'start'}>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </MuiDialogTitle>
        <DialogContent>
          <Tabs
            value={selectedTabValue}
            onChange={handleTabChange}
            aria-label="message template tabs"
            TabIndicatorProps={{style: {backgroundColor: Theme.chatTeal}}}
            classes={{root: classes.tabs}}
          >
            <Tab label="Favourites" {...tabsProps(TabType.Favourites)} classes={{root: classes.tab}} />
            <Tab label="All" {...tabsProps(TabType.All)} classes={{root: classes.tab}} />
          </Tabs>
          <Divider />
          <TabPanel selectedTabValue={selectedTabValue} index={0}>
            <FavouriteMessageTemplateList />
          </TabPanel>
          <TabPanel selectedTabValue={selectedTabValue} index={1}>
            <AllMessageTemplateList />
          </TabPanel>
        </DialogContent>
        <DialogActions classes={{root: classes.dialogActionRoot}}>
          <SecondaryButton
            onClick={() => {
              store.dispatch(actions.resetToInitalState());
              closeMessageTemplateListModal();
            }}
          >
            Cancel
          </SecondaryButton>
        </DialogActions>
      </Dialog>

      {/* Instantiates Message Template Disclaimer Modal */}
      {disclaimerModalNextBtnHandler && (
        <MessageTemplateDisclaimerModal
          disclaimerModalNextBtnHandler={disclaimerModalNextBtnHandler}
          showMessageTemplateDisclaimerModal={messageTemplateDisclaimerModal}
          closeMessageTemplateDisclaimerModal={handleMessageTemplateDisclaimerModalClose}
        />
      )}

      {/* Instantiates Edit Message Template Form Modal */}
      {messageTemplateLayoutModal && (
        <MessageTemplateLayout
          showMessageTemplateLayoutModal={messageTemplateLayoutModal}
          templateLayoutNextBtnHandler={templateLayoutNextBtnHandler}
          closeMessageTemplateLayout={handleMessageTemplateLayoutClose}
          templateLayoutPreviousBtnHandler={templateLayoutPreviousBtnHandler}
        />
      )}

      {/* Instantiates Modal for setting priority for template message */}
      {priorityMessageTemplate && (
        <PriorityMessageTemplate
          openPriorityMessageTemplate={priorityMessageTemplate}
          closePriorityMessageTemplate={setPriorityMessageTemplate}
          templatePriorityPreviousBtnHandler={templatePriorityPreviousBtnHandler}
          templatePriorityReviewRequestBtnHandler={templatePriorityReviewRequestBtnHandler}
        />
      )}

      {/* Instantiates Review Message Template Modal */}
      {previewMessageTemplateModal && (
        <PreviewMessageTemplate
          chatId={chatId}
          openPreviewMessageTemplate={previewMessageTemplateModal}
          closePreviewTemplateModal={setPreviewMessageTemplateModal}
          previewTemplatePreviousBtnHandler={previewTemplatePreviousBtnHandler}
        />
      )}
    </ThemeProvider>
  );
};

export default MessageTemplateListModal;
