import React from 'react';
import VerificationCodeInput from 'src/components/VerificationCodeInput';
import styled from 'styled-components';
import AnalyticsManager, {PAGE_VIEWS} from 'src/analytics/AnalyticsManager';
import {ResetPasswordValidationCodeProps} from 'src/types';

const ErrorMessage = styled.p`
  color: red;
  font-size: 14px;
  margin: 0;
`;

interface Props {
  validationCodeProps: ResetPasswordValidationCodeProps;
  isLoading: boolean;
  resendCode: () => Promise<void>;
}

const ResetPasswordFormStepOne = ({validationCodeProps, isLoading, resendCode}: Props) => {
  const {validationCodeArray, validationErrorMsg, setValidationCodeArray, validationResultType} = validationCodeProps;

  React.useEffect(() => {
    AnalyticsManager.recordPageVisited(PAGE_VIEWS.recoverPasswordToken);
  }, []);

  return (
    <div>
      <div>
        Password reset code has been sent to your{' '}
        {validationResultType === 'email'
          ? 'email'
          : validationResultType === 'phoneNumber'
          ? 'phone number'
          : 'contact address'}
        .
      </div>
      <VerificationCodeInput
        length={6}
        size="sm"
        value={validationCodeArray.join('')}
        disabled={isLoading}
        onChange={(value) => setValidationCodeArray(value.split(''))}
        handleResend={resendCode}
        renderError={validationErrorMsg && <ErrorMessage>{validationErrorMsg}</ErrorMessage>}
      />
    </div>
  );
};

export default ResetPasswordFormStepOne;
