import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Chevron from 'src/svgs/Chevron';
import ExpansionPanelDetailsWrapper from 'src/pages/LocatingPage/locating/search-section/ExpansionPanelDetailsWrapper';
import styled from 'styled-components';
import {LocatingRole, OnCallDepartmentScope} from 'src/types';
import AnalyticsManager, {PAGE_VIEWS} from 'src/analytics/AnalyticsManager';
import locatingKeywordSearch from 'src/utils/locating/locatingKeywordSearch';
import {Accordion, AccordionSummary} from '@material-ui/core';
import HypercareSchedulingViewModel from 'src/pages/LocatingPage/viewModels/HypercareSchedulingViewModel';

const DepartmentsWrapper = styled.div``;

const StyledLabel = styled.span`
  font-size: 12px;
  color: grey;
  margin-top: 1em;
  margin-bottom: 8px;
`;

const DepartmentName = styled.span`
  font-weight: bold;
  font-family: Nunito;
  font-size: 0.9375rem;
  flex-shrink: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
`;

const NoResult = styled.p`
  margin-top: 1em;
  font-size: 16px;
  color: ${(props) => props.theme.greyishBrown};
`;

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  expandWrapper: {
    boxShadow: 'none',
    margin: '0 !important',
    '& .MuiAccordionSummary-root.Mui-expanded ': {
      borderBottom: 'none',
      minHeight: '40px !important',
      height: '40px !important',
    },
  },
  summaryHeaderWrapper: {
    position: 'sticky',
    top: 0,
    zIndex: 1,
    backgroundColor: 'white',
    borderBottom: '1px dashed lightgrey',
    '& .MuiAccordionSummary-content': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
}));

interface Props {
  searchKeyword: string;
  favouriteRolesList: LocatingRole[];
  departmentLists: OnCallDepartmentScope[];
  setFavouriteRolesList: React.Dispatch<React.SetStateAction<LocatingRole[]>>;
}

const DepartmentsExpansionWrapper = ({
  searchKeyword,
  favouriteRolesList,
  departmentLists,
  setFavouriteRolesList,
}: Props) => {
  const classes = useStyles({});
  /**
   * only allowing one exapnsion at at time, but if there exists search keyword
   * hide the chevron toggle and deafult expand all result
   */
  const [expanded, setExpanded] = React.useState('');

  const {getRoleNotesForDepartmentArray} = HypercareSchedulingViewModel();

  function onExpansionPanelClick(index) {
    let expandedToggleText = `panel-${index}`;
    if (expanded === expandedToggleText) {
      setExpanded('');
    } else {
      AnalyticsManager.recordPageVisited(PAGE_VIEWS.departmentView);

      setExpanded(expandedToggleText);
    }
  }

  function tryFilterDepartmentResult() {
    if (Boolean(searchKeyword)) {
      const filteredList = departmentLists?.filter((department) => {
        const {roleListResult, teamsListResult} = locatingKeywordSearch({
          roleList: department?.schedule?.roles || department?.roles || [],
          teamList: department?.teams,
          searchKeyword,
        });
        if (roleListResult?.length > 0 || teamsListResult?.length > 0) return department;
        return false;
      });
      return filteredList;
    }
    return departmentLists;
  }

  const departmentResultList = tryFilterDepartmentResult();

  const noSearchResult = departmentResultList.length === 0 && Boolean(searchKeyword);

  useEffect(() => {
    const getData = async () => {
      const departmentArray = departmentLists.map((department) => ({
        id: department.id,
        scheduleId: department.schedule?.id,
      }));
      await getRoleNotesForDepartmentArray(departmentArray);
    };

    getData();
  }, [departmentLists]);

  return (
    <React.Fragment>
      <StyledLabel>Departments</StyledLabel>
      <DepartmentsWrapper className={classes.root}>
        {noSearchResult && <NoResult>No results found</NoResult>}
        {departmentResultList && departmentResultList.length === 0 && !noSearchResult && (
          <NoResult>Please contact your organization administrator if you would like to create a department.</NoResult>
        )}
        {departmentResultList.map((department, i) => (
          <Accordion
            expanded={expanded === `panel-${i}` || Boolean(searchKeyword)}
            className={classes.expandWrapper}
            key={`department${department.id}`}
          >
            <AccordionSummary
              onClick={() => onExpansionPanelClick(i)}
              className={classes.summaryHeaderWrapper}
              expandIcon={Boolean(searchKeyword) ? <div /> : <Chevron />}
              aria-controls="department-content"
            >
              <DepartmentName>{department.name}</DepartmentName>
            </AccordionSummary>

            <ExpansionPanelDetailsWrapper
              isAllFavourite={false}
              setFavouriteRolesList={setFavouriteRolesList}
              roleList={department.schedule?.roles || department?.roles || []}
              teamList={department?.teams}
              searchKeyword={searchKeyword}
              favouriteRolesList={favouriteRolesList}
              departmentId={department.id}
            />
          </Accordion>
        ))}
      </DepartmentsWrapper>
    </React.Fragment>
  );
};

export default DepartmentsExpansionWrapper;
