import * as React from 'react';
import {withRouter, RouteComponentProps} from 'react-router-dom';
import styled from 'styled-components';
import CreateNewChatHeader from 'src/pages/MessengerPage/messenger/new-chat-layout/CreateNewChatHeader';
import isActualChatId from 'src/utils/messengerHelper/isActualChatId';
import CreateNewChatFAB from 'src/pages/MessengerPage/messenger/new-chat-layout/CreateNewChatFAB';
import AnalyticsManager, {PAGE_VIEWS} from 'src/analytics/AnalyticsManager';
import {ARCHIVED} from 'src/constants/routerPathName';
import MessagesHeader from 'src/pages/MessengerPage/messenger/messages-layout/header-section/MessagesHeader';
import ChatBodyContainer from 'src/pages/MessengerPage/messenger/ChatBodyMessagesContainer';
import {MessageInChat} from 'src/types';
import {ProfileLayoutContainer} from 'src/pages/LocatingPage/locating/ProfileLayoutContainer';
import CreateNewChatHeaderV2 from './new-chat-layout/CreateNewChatHeaderV2';
import {IsFeatureFlagEnabled} from 'src/utils/FeatureFlagManager';
import {FeatureFlagResult} from 'src/utils/FeatureFlags';

const MessageLayoutContainer = styled.div`
  display: flex;
  flex: 3;
  flex-direction: column;
  min-width: 330px;
`;

const NewChatContainer = styled(MessageLayoutContainer)`
  overflow-y: auto;
`;

interface Props extends RouteComponentProps<{chatId: string; userId: string}> {
  isSearch: any;
  handleIsSearch: (isVisible: boolean) => void;
  jumpToMessage: MessageInChat;
  isSideSearchActive: boolean;
  handleIsJumpToMessageEnable: (isJumpToMessageEnable: boolean) => void;
}

const CentralMessengerLayout: React.FC<Props> = (props) => {
  const {
    match: {params},
    history,
    isSearch,
    handleIsSearch,
    jumpToMessage,
    isSideSearchActive,
    handleIsJumpToMessageEnable,
  } = props;

  const {chatId, userId} = params;
  const isArchive = history.location.pathname.includes(ARCHIVED);

  const paginatedContactsFeatureFlag = IsFeatureFlagEnabled(FeatureFlagResult.paginatedContacts);

  if (paginatedContactsFeatureFlag === undefined) {
    return null;
  }

  const setIsSearch = (value: boolean) => {
    handleIsSearch(value);
  };

  if (userId) {
    return (
      <MessageLayoutContainer>
        <ProfileLayoutContainer />
      </MessageLayoutContainer>
    );
  }

  if (chatId && chatId === 'new' && !isArchive) {
    return (
      <NewChatContainer>
        {!paginatedContactsFeatureFlag ? <CreateNewChatHeader /> : <CreateNewChatHeaderV2 />}
        <CreateNewChatFAB />
      </NewChatContainer>
    );
  }

  // TODO: refer to the design of deafult home page
  // if (chatId && chatId === 'home') {
  //   return (
  //     <MessageLayoutContainer>
  //     </MessageLayoutContainer>
  //   );
  // }

  if (isActualChatId(chatId)) {
    AnalyticsManager.recordPageVisited(PAGE_VIEWS.chatView);
    return (
      <MessageLayoutContainer key={`container-${chatId}`}>
        <MessagesHeader chatId={chatId} isSearch={isSearch} setIsSearch={setIsSearch} handleIsSearch={handleIsSearch} />
        <ChatBodyContainer
          chatId={chatId}
          isSearch={isSearch}
          setIsSearch={setIsSearch}
          handleIsSearch={handleIsSearch}
          jumpToMessage={jumpToMessage}
          isSideSearchActive={isSideSearchActive}
          handleIsJumpToMessageEnable={handleIsJumpToMessageEnable}
        />
      </MessageLayoutContainer>
    );
  }

  return (
    <MessageLayoutContainer>
      <MessagesHeader chatId="" />
    </MessageLayoutContainer>
  );
};

export default withRouter(CentralMessengerLayout);
