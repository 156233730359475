import styled from 'styled-components';
import React, {VFC} from 'react';

type PillVariant = 'success' | 'danger' | 'warning';

const getColorStyling = (variant: PillVariant) => {
  switch (variant) {
    case 'success':
      return {
        color: '#00859A',
        border: '1px solid #00859A',
        background: '#CCE7EB',
      };
    case 'danger':
      return {
        color: '#FF3E55',
        border: '1px solid #FF3E55',
        background: '#FFD8DD',
      };
    case 'warning':
      return {
        color: 'yellow',
        border: '1px solid #BCBCBC',
        background: '#D8D8D8',
      };
    default:
      return {};
  }
};

const Container = styled.div<{variant: PillVariant}>`
  padding: 2px 8px 2px 8px;
  justify-content: center;
  border-radius: 19px;
  border: 1px;
  display: inline-block;
  ${(props) => ({...getColorStyling(props.variant)})};
  color: #222222;
  line-height: 18px;
  font-weight: 400;
  font-size: 13px;
`;

interface HCPillProps {
  variant: PillVariant;
  text: string;
}

export const HCPill: VFC<HCPillProps> = ({variant, text}) => {
  return <Container variant={variant}>{text}</Container>;
};
