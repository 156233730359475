import * as React from 'react';
import styled from 'styled-components';
import {SCHEDULES, REQUESTS, MARKETPLACE} from 'src/constants/scheduler';

const EmptyStateViewHolder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding: 0 25%;
  font-size: 20px;
  font-family: Nunito;
  color: ${(props) => props.theme.greyishBrown};
`;

const EmptyStateImageSchedules = styled.img`
  width: 456px;
  height: 345px;
`;

const EmptyStateImageRequests = styled.img`
  width: 476;
  height: 360px;
`;

const EmptyStateText = styled.div`
  font-size: 16px;
`;

const EmptyStateTitle = styled.h3`
  margin-top: 1em;
  margin-bottom: 10px;
`;

interface Props {
  view: 'schedules' | 'requests' | 'marketplace' | 'myCalendar';
  type?: 'site' | 'department';
}

const SchedulingEmptyStateView = ({view, type}: Props) => {
  if (view === SCHEDULES || view === MARKETPLACE) {
    return (
      <EmptyStateViewHolder>
        <EmptyStateImageSchedules src="/assets/empty-states-calendar.jpg" alt="empty-state-image" />
        <EmptyStateTitle>No schedules found… yet.</EmptyStateTitle>
        {view === SCHEDULES && (
          <EmptyStateText>
            No {type}s are set up for your organization. Please contact your admin or Hypercare Support to set up
            scheduling.
          </EmptyStateText>
        )}
        {view === MARKETPLACE && (
          <EmptyStateText>
            You are not a member of any departments in this organization. Please contact your admin or Hypercare Support to
            add you in.
          </EmptyStateText>
        )}
      </EmptyStateViewHolder>
    );
  }

  if (view === REQUESTS) {
    return (
      <EmptyStateViewHolder>
        <EmptyStateImageRequests src="/assets/empty-states-inbox.jpg" alt="empty-state-image" />
        <EmptyStateTitle>No requests found… yet.</EmptyStateTitle>
        <EmptyStateText>Schedule change requests will show up here.</EmptyStateText>
      </EmptyStateViewHolder>
    );
  }

  return null;
};

export default SchedulingEmptyStateView;
