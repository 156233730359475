import * as React from 'react';
import {MonthlyCalendarShift, AssigneeWithRole, ShiftRequestType, Assignee} from 'src/types';
import {typedUseSelector} from 'src/redux';
import getParsedAuthInfo from 'src/utils/localStorageHandler';
import {sortStartTimeTypeofAssigneeWithRole} from 'src/utils/scheduling/sortAssignee';

interface Props {
  shifts: MonthlyCalendarShift[];
  calendarType: ShiftRequestType;
}

const useShiftsFilter = ({shifts, calendarType}: Props) => {
  const filteringRoles = typedUseSelector((state) => state.tradeShift.filterByRoles);
  const filteringUser = typedUseSelector((state) => state.tradeShift.filterByUser);
  const filteringOfferTypes = typedUseSelector((state) => state.tradeShift.filterOfferTypes);

  const parsedAuthInfo = getParsedAuthInfo();
  const selfID = parsedAuthInfo ? parsedAuthInfo.user.id : null;

  const setFilteringFlag = (monthlyCalendarShift: MonthlyCalendarShift, assignee: Assignee) => {
    let shouldShowUser = !filteringUser || filteringUser.id === assignee.userId;
    let shouldShowRole = Boolean(filteringRoles.find((role) => role.roleId === monthlyCalendarShift.role.roleId));
    let shouldShowByType =
      assignee.offerTypes && Boolean(assignee.offerTypes.find((type) => filteringOfferTypes.has(type)));

    if (calendarType === 'swap') return shouldShowRole && shouldShowUser;
    if (calendarType === 'give_away') return shouldShowRole && shouldShowByType;
    return true;
  };

  const sortedAssigneeWithRole: AssigneeWithRole[] = React.useMemo(() => {
    const result = [];

    shifts.forEach((monthlyCalendarShift) => {
      if (monthlyCalendarShift.assignee.length === 0) return;
      monthlyCalendarShift.assignee.forEach((assignee) => {
        let filteringFlag = setFilteringFlag(monthlyCalendarShift, assignee);
        if (filteringFlag || selfID === assignee.userId) {
          result.push({
            assignee,
            role: monthlyCalendarShift.role,
          });
        }
      });
    });

    return result.sort(sortStartTimeTypeofAssigneeWithRole);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shifts, filteringRoles, filteringUser, filteringOfferTypes]);

  return sortedAssigneeWithRole;
};

export default useShiftsFilter;
