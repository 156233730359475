import {createAction, ActionType, getType} from 'typesafe-actions';
import {allowedSchedulingCellBackgroundColors} from 'src/pages/SchedulingPage/scheduling/FullCalendarGridSystem';
import getParsedAuthInfo from 'src/utils/localStorageHandler';
import apptheme from 'src/styles/theme';
import {HiddenNotes} from 'src/types/HiddenNotes';

export const actions = {
  setUserColor: createAction('scheduling/SET_COLOR', (resolve) => (userIdSet: Set<string>) => resolve({userIdSet})),
  resetUserColor: createAction('scheduling/RESET_COLOR'),
  setRoleNotes: createAction('scheduling/SET_ROLE_NOTES', (resolve) => (values) => resolve({values})),
  setCurrentSelectedRole: createAction(
    'scheduling/SET_CURRENT_SELECTED_ROLE',
    (resolve) =>
      ({roleId, roleName}) =>
        resolve({roleId, roleName}),
  ),
};

export type SchedulingActions = ActionType<typeof actions>;

export const initialState: SchedulingState = {
  userToHexColorCode: {},
  roleNotes: {},
  currentSelectedRole: null,
};

export interface SchedulingState {
  userToHexColorCode: {
    [userId: string]: string;
  };
  roleNotes: {
    [key: string]: {
      notes: HiddenNotes[];
      roleName: string;
    };
  };
  currentSelectedRole: {
    roleId: number;
    roleName: string;
  };
}

export default (state: SchedulingState = initialState, action: SchedulingActions) => {
  switch (action.type) {
    case getType(actions.setUserColor):
      let parsedAuthInfo = getParsedAuthInfo();
      let selfID = parsedAuthInfo ? parsedAuthInfo.user.id : null;
      let {userIdSet} = action.payload;
      let colorsArray = [...allowedSchedulingCellBackgroundColors];

      let userToColorCodeMap = {
        [selfID]: apptheme.androidGreen,
      };

      Array.from(userIdSet).forEach((id) => {
        if (id === selfID) return;
        let pickedColor = colorsArray.splice(Math.floor(Math.random() * colorsArray.length), 1)[0];
        if (!pickedColor) {
          pickedColor = '#' + Math.floor(Math.random() * 16777215).toString(16);
          while (pickedColor.length < 7) pickedColor = pickedColor + '0';
        }
        userToColorCodeMap[id] = pickedColor;
      });

      return {
        ...state,
        userToHexColorCode: {...userToColorCodeMap},
      };

    case getType(actions.resetUserColor):
      return {
        ...state,
        userToHexColorCode: {},
      };

    case getType(actions.setRoleNotes):
      return {
        ...state,
        roleNotes: {...action.payload.values},
      };

    case getType(actions.setCurrentSelectedRole):
      const {roleId, roleName} = action.payload;
      return {...state, currentSelectedRole: {roleId, roleName}};

    default:
      return state;
  }
};
