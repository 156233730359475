import * as React from 'react';
import styled from 'styled-components';
import ModalImage from 'react-modal-image';
import {StatMessagePriority, UrgentMessagePriority} from 'src/constants/messageTypes';
import moment from 'moment';
import {Message} from 'src/types';
import PriorityIndicator from 'src/pages/MessengerPage/messenger/messages-layout/message-item/PriorityIndicator';

export const MessageImageFixedHeight = 300;

const StyledHolder = styled.div`
  position: relative;
  align-self: start;
  padding-top: 5px;
  height: ${MessageImageFixedHeight}px;
  max-width: 400px;
  margin-bottom: 10px;
  display: contents;
  justify-content: center;
  align-items: center;
`;

const SelfImageHolder = styled(StyledHolder)`
  img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    object-fit: cover;
  }
  & > div {
    height: 100%;
    width: 100%;
  }
`;

const OtherImageHolder = styled(SelfImageHolder)`
  justify-content: flex-start;
`;

const OverrideModalImage = styled(ModalImage)`
  max-height: ${MessageImageFixedHeight}px !important;
`;

const PriorityImageContainer = styled.div<{priorityType: string}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${(props) =>
    props.priorityType === UrgentMessagePriority
      ? props.theme.priorityYellow
      : props.priorityType === StatMessagePriority
      ? props.theme.statRed
      : null};
  border-radius: 5px;
  padding: 5px 10px 5px 10px;
`;

const MessageTime = styled.div`
  font-size: 12px;
  align-self: flex-end;
  position: absolute;
  bottom: 12px;
  right: 12px;
`;

const ImageTimeWrapper = styled.div`
  position: relative;
`;

interface Props {
  priorityType: string;
  isSelfMessage: boolean;
  imgSrc: string;
  isOptimistic: boolean;
  originalFile: File;
  dateCreated: string;
  repliedTo: Message;
}

// TODO: upload progress indictor that overlays the optimistic image
class ImageMessageBubble extends React.PureComponent<Props> {
  public state = {
    previewImageURL: '',
  };

  public componentDidMount() {
    const {originalFile} = this.props;
    if (originalFile) {
      this.setState({
        previewImageURL: window.URL.createObjectURL(originalFile),
      });
    }
  }

  public componentWillUnmount() {
    // when incoming message socket event handler update the chat and removed optimistic message
    const {previewImageURL} = this.state;
    if (previewImageURL) {
      window.URL.revokeObjectURL(previewImageURL);
    }
  }

  public render() {
    const {priorityType, imgSrc, isSelfMessage, isOptimistic, dateCreated, repliedTo} = this.props;
    const {previewImageURL} = this.state;

    const ImageHolder = isSelfMessage ? SelfImageHolder : OtherImageHolder;

    return [StatMessagePriority, UrgentMessagePriority].includes(priorityType) ? (
      <PriorityImageContainer priorityType={priorityType}>
        <ImageHolder>
          {!repliedTo && <PriorityIndicator priorityType={priorityType} isImageMessage={true} />}
          <ImageTimeWrapper>
            <OverrideModalImage
              small={isOptimistic ? previewImageURL : imgSrc}
              large={isOptimistic ? previewImageURL : imgSrc}
              hideZoom={true}
              showRotate={true}
              imageBackgroundColor={'transparent'}
            />
            <MessageTime>{moment(dateCreated).format('hh:mm A')}</MessageTime>
          </ImageTimeWrapper>
        </ImageHolder>
      </PriorityImageContainer>
    ) : (
      <ImageHolder>
        <ImageTimeWrapper>
          <OverrideModalImage
            small={isOptimistic ? previewImageURL : imgSrc}
            large={isOptimistic ? previewImageURL : imgSrc}
            hideZoom={true}
            showRotate={true}
            imageBackgroundColor={'transparent'}
          />
          <MessageTime>{moment(dateCreated).format('hh:mm A')}</MessageTime>
        </ImageTimeWrapper>
      </ImageHolder>
    );
  }
}

export default ImageMessageBubble;
