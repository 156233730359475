import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import {ActiveEscalation} from 'src/types';
import stringifyNumberSuffix from 'src/utils/stringifyNumberSuffix';

const EscalationPreviewCard = styled.div`
  width: 100%;
  margin-bottom: 1em;
  padding: 16px 20px;
  border-radius: 10px;
  background-color: ${(props) => props.theme.lightGrey};
  .MuiDivider-root {
    margin-top: 1em;
    margin-bottom: 4px;
  }
`;

const EscalationType = styled.div`
  color: ${(props) => props.theme.greyishBrown};
`;

const EscalationName = styled.div`
  font-weight: bold;
  line-height: 1.5em;
  color: ${(props) => props.theme.blackFontColor};
  margin: 1em 0;
`;

const NextAttemptTime = styled.div`
  font-size: 32px;
  color: ${(props) => props.theme.blackFontColor};
  font-weight: bold;
`;

const EscalationProgressGlance = styled.div`
  color: ${(props) => props.theme.greyishBrown};
`;

const EscalationLadderPreviewWrapper = styled.div`
  display: flex;
`;

const EscalationLadderPreviewInfoHolder = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const EscalationLadderAttemptTimeHolder = styled.div`
  width: 128px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: grey;
  font-size: 12px;
`;

const EscalationPreviewCardButtons = styled.div`
  text-align: right;
  margin-top: 12px;
  button {
    font-weight: bold;
    font-family: Nunito;
    color: ${(props) => props.theme.chatTeal} !important;
    text-transform: capitalize;
  }
`;

interface Props {
  activeEscalation: ActiveEscalation;
  setTargetEscalationID: () => void;
  handleViewChat: () => void;
}

const EscalationLadderPreviewCard = ({activeEscalation, setTargetEscalationID, handleViewChat}: Props) => {
  const {escalationLadder, type, activatedLevel, message} = activeEscalation;

  return (
    <EscalationPreviewCard>
      <EscalationLadderPreviewWrapper>
        <EscalationLadderPreviewInfoHolder>
          <EscalationType>
            {type === 'department' ? 'DEPARTMENT' : 'INDIVIDUAL'} ESCALATION
            {type === 'department' && ` - ${escalationLadder.department.name}`}
          </EscalationType>
          <EscalationName>{!!message ? message.message : escalationLadder.name}</EscalationName>
          <EscalationProgressGlance>
            {activatedLevel
              ? `${stringifyNumberSuffix(activatedLevel.currentAttempt)} attempt to contact in progress - Level ${
                  activatedLevel.level
                }`
              : 'starting escalation...'}
          </EscalationProgressGlance>
        </EscalationLadderPreviewInfoHolder>
        {activatedLevel && (
          <EscalationLadderAttemptTimeHolder>
            <div>next attempt at</div>
            <NextAttemptTime>{moment(activatedLevel.nextAttemptAt).format('HH:mm')}</NextAttemptTime>
          </EscalationLadderAttemptTimeHolder>
        )}
      </EscalationLadderPreviewWrapper>

      <Divider />

      <EscalationPreviewCardButtons>
        <Button onClick={setTargetEscalationID} disableTouchRipple>
          View escalation status
        </Button>
        <Button onClick={handleViewChat} disableTouchRipple>
          View chat
        </Button>
      </EscalationPreviewCardButtons>
    </EscalationPreviewCard>
  );
};

export default EscalationLadderPreviewCard;
