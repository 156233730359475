import styled from 'styled-components';

interface TextOnlyButtonProps {
  fontWeight?: string;
}

export const TextOnlyButton = styled.button<TextOnlyButtonProps>`
  font-family: Nunito;
  font-weight: ${(props) => props.fontWeight || 'bold'};
  color: ${(props) => props.theme.secondary};

  &:disabled {
    cursor: not-allowed;
    color: ${(props) => props.theme.disabled};
  }
`;

export const DefaultButton = styled.button`
  border-radius: 25px;
  padding: 10px 40px;
  font-family: Nunito;
  font-weight: bold;
  color: white;
  background-color: ${(props) => props.theme.secondary};

  &:disabled {
    cursor: not-allowed;
    background-color: ${(props) => props.theme.disabled};
  }
`;

export const ActionButtonWrapper = styled.div`
  display: flex;
  margin-top: 1em;
  justify-content: flex-end;

  button {
    height: 40px;
    min-width: 108px;
    text-transform: capitalize;
    margin-left: 8px;
    font-family: Nunito;
    &:first-of-type {
      color: ${(props) => props.theme.chatTeal};
    }
    &:disabled {
      color: white;
    }
  }
`;
