import {createAction, ActionType, getType} from 'typesafe-actions';
import {toast} from 'react-toastify';
import {FILESIZELIMIT} from 'src/constants/fileUpload';

export const actions = {
  appendDroppedFiles: createAction('filedrop/APPEND', (resolve) => (droppedFiles) => resolve({droppedFiles})),
  removeDroppedFiles: createAction('filedrop/REMOVE', (resolve) => (index) => resolve({index})),
  clearDroppedFiles: createAction('filedrop/CLEAR'),
};

interface FileWithPreview extends File {
  preview: string;
}

export type FileDropActions = ActionType<typeof actions>;

export const initialState: DroppedFileState = {
  files: [],
};

export interface DroppedFileState {
  files: FileWithPreview[];
}

export default (state: DroppedFileState = initialState, action: FileDropActions) => {
  switch (action.type) {
    case getType(actions.appendDroppedFiles):
      let hasOversizedImage = false;
      const {droppedFiles} = action.payload;

      const newFiles = droppedFiles
        .filter((file) => {
          if (file.size > FILESIZELIMIT) hasOversizedImage = true;
          return file.size < FILESIZELIMIT;
        })
        .map((file) => {
          return Object.assign(file, {
            preview: window.URL.createObjectURL(file),
            filename: file.name,
            filesize: file.size,
            filetype: file.type,
          });
        });

      const newArr = [...state.files, ...newFiles];

      if (hasOversizedImage) {
        toast.error('Please ensure that no files are larger than 50MB');
      }

      return {
        ...state,
        files: newArr,
      };

    case getType(actions.removeDroppedFiles):
      const {index} = action.payload;
      let stateFiles = [...state.files];
      const removedFile = stateFiles.splice(index, 1)[0];
      window.URL.revokeObjectURL(removedFile.preview);
      return {
        ...state,
        files: stateFiles,
      };

    case getType(actions.clearDroppedFiles):
      const existingFiles = state.files;
      // Revoke the data uris to avoid memory leaks
      existingFiles.forEach((file) => {
        window.URL.revokeObjectURL(file.preview);
      });
      return initialState;

    default:
      return state;
  }
};
