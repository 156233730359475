import * as Yup from 'yup';

const nonASCIIRegex = /^[ A-Za-z0-9_@.,<>;:!$%^()/#&+-=*'"?]*$/;

const numberRegex = /^[0-9]+$/;

// Alphanumeric paging text field special character detection
const extractSpecialChar = (value) => {
  let nonASCIIChars = [];
  for (let i = 0; i < value.length; i++) {
    if (!nonASCIIRegex.test(value[i])) {
      nonASCIIChars.push(value[i]);
    }
  }
  return nonASCIIChars;
};

// Validation for Alphanumeric paging modal text field
export const inputValidation = (value) =>
  Yup.string()
    .trim()
    .required('This field cannot be empty')
    .matches(nonASCIIRegex, `Invalid characters "${extractSpecialChar(value)}"`)
    .max(235, 'Max. char limit: 235');

export const inputValidationNumericPager = Yup.string()
  .trim()
  .required('This field cannot be empty')
  .matches(numberRegex, `only numeric characters are allowed.`)
  .max(16, 'Max. char limit: 16');
