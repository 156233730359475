import React, {useState} from 'react';
import {AddressBanner} from '../../../microfrontend/components/AddressBanner';
import AddressBannerViewModel from './AddressBannerViewModel';
import ProfileAddAddressStepFormModal from '../../../pages/LocatingPage/locating/profile-section/ProfileAddAddressStepFormModalV2';

export const AddressBannerCoordinator = () => {
  const [addressModal, setAddressModal] = useState<boolean>(false);
  const viewModel = AddressBannerViewModel();

  const {type, error} = viewModel.getMissingAddressInformation();

  if (!type || error) {
    return null;
  }

  return (
    <>
      <AddressBanner type={type} setAddressModal={setAddressModal} />
      {addressModal && (
        <ProfileAddAddressStepFormModal
          addresses={[]}
          setCurrentAddresses={() => null}
          isOpen={addressModal}
          setParentAddress={() => null}
          closeModal={() => setAddressModal(false)}
          setDefaultType={type === 'phoneNumber' ? 'phoneNumber' : 'email'}
        />
      )}
    </>
  );
};
