import * as React from 'react';
import styled from 'styled-components';
import {usePopper} from 'react-popper';
import {SYSTEM} from 'src/constants/messageTypes';
import {Message, MessageInChat} from 'src/types';
import {getDetailedReadableDate} from 'src/utils/getPrettyReadableDate';
import parseDate from 'src/utils/parseDate';
import {
  MessageBubblePopperArrow,
  MessageBubblePopperContainer,
} from 'src/pages/MessengerPage/messenger/messages-layout/message-item/message-option-popper/MessageOptionPopperStyles';
import DetailMessageModal from 'src/pages/MessengerPage/messenger/messages-layout/DetailMessageModal';
import MessageBubbleRenderer from 'src/pages/MessengerPage/messenger/messages-layout/message-item/message-bubbles/MessageBubbleRenderer';
import {media} from 'src/styles/utils';
import {
  MessageDetailOptionPopper,
  MessageReplyOptionPopper,
  DetailOptionMoreOptions,
} from 'src/pages/MessengerPage/messenger/messages-layout/message-item/message-option-popper';
import EscalationModalContainer from 'src/pages/MessengerPage/escalation-modal/EscalationModalContainer';
import {StatMessagePriority, UrgentMessagePriority} from 'src/constants/messageTypes';

// TODO: message bubble width should response to message body width not the window media

const SelfMessageBubble = styled.div<BubbleProps>`
  display: flex;
  flex-direction: column;
  max-width: 55%;
  border-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: ${(props) => (props.isLastInConsecutiveBySender ? '0px' : '5.0px')};
  padding: 8px 12px;
  color: ${(props) =>
    props.priorityType === UrgentMessagePriority ? props.theme.messageBlack : props.theme.messageWhite};
  background-color: ${(props) =>
    props.priorityType === UrgentMessagePriority
      ? props.theme.priorityYellow
      : props.priorityType === StatMessagePriority
      ? props.theme.statRed
      : props.theme.chatTeal};
  word-break: break-word;
  line-height: 1.5em;

  ${media.widescreen`
    max-width: 75%;
  `}
`;

const SystemMessageBubble = styled.div<BubbleProps>`
  display: flex;
  color: grey;
  align-items: center;
  font-size: 0.9em;
  padding: 8px 12px;
  word-break: break-word;
  line-height: 1.5em;
  text-align: center;
`;

const ErrorIconHolder = styled.div`
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: red;
`;

const DotSpinner = styled.div`
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  & > div {
    width: 6px;
    height: 6px;
    margin-right: 2px;
    background-color: grey;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: bouncedelay 1.4s infinite ease-in-out both;
    animation: bouncedelay 1.4s infinite ease-in-out both;
  }
  & > .bounce1 {
    -webkit-animation-delay: -0.3s;
    animation-delay: -0.3s;
  }
  & > .bounce2 {
    -webkit-animation-delay: -0.15s;
    animation-delay: -0.15s;
  }
`;

const OtherMessageBubble = styled(SelfMessageBubble)`
  max-width: none !important;
  background-color: ${(props) =>
    props.priorityType === UrgentMessagePriority
      ? props.theme.priorityYellow
      : props.priorityType === StatMessagePriority
      ? props.theme.statRed
      : props.theme.chatGrey};
  color: ${(props) =>
    props.priorityType === StatMessagePriority ? props.theme.messageWhite : props.theme.messageBlack};
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: ${(props) => (props.isLastInConsecutiveBySender ? '0px' : '5.0px')};
`;

interface Props {
  chatId: string;
  isSingleChat: boolean;
  isSelfMessage: boolean;
  isLastInConsecutiveBySender: boolean;
  message: Message;
  searchText: string;
  searchMessageData: MessageInChat[] | [];
  jumpToMessage: MessageInChat;
  isSideSearchActive: boolean;
  searchMessage: MessageInChat | null;
  children?: React.ReactNode;
}

interface BubbleProps {
  priorityType: string;
  priority?: boolean;
  isLastInConsecutiveBySender: boolean;
  messageId: any;
  searchMessageId: string;
}

const MessageBubbleWrapper = ({
  chatId,
  isSingleChat,
  isSelfMessage,
  isLastInConsecutiveBySender,
  message,
  searchText,
  searchMessageData,
  jumpToMessage,
  isSideSearchActive,
  searchMessage,
  children = null,
}: React.PropsWithChildren<Props>) => {
  const [isHovered, setHovered] = React.useState(false);
  const [showMessageDetailModal, setShowMessageDetailModal] = React.useState(false);
  const [showEscalationTriggerModal, setShowEscalationTriggerModal] = React.useState(false);
  const [referenceElement, setReferenceElement] = React.useState(null);
  const [popperElement, setPopperElement] = React.useState(null);
  const [arrowElement, setArrowElement] = React.useState(null);
  const [moreOptionAnchorEl, setMoreOptionAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const {styles, attributes} = usePopper(referenceElement, popperElement, {
    modifiers: [
      {
        name: 'arrow',
        options: {
          element: arrowElement,
        },
      },
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
    ],
    placement: 'top',
  });

  const {dateCreated, type} = message;

  const isTextMessage = message.type === 'text';

  let MessageBubble: React.ReactNode;
  if (type === SYSTEM) {
    MessageBubble = SystemMessageBubble;
  } else {
    MessageBubble = isSelfMessage ? SelfMessageBubble : OtherMessageBubble;
  }

  const onMouseEnter = () => setHovered(true);

  const onMouseLeave = () => setHovered(false);

  const handleMoreOptionClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setMoreOptionAnchorEl(event.currentTarget);
  };

  const renderMessageBubbleBaseOnType = (messageBubbleStyledTagName: React.ReactNode) => {
    return (
      <React.Fragment>
        <MessageBubbleRenderer
          popperRef={setReferenceElement}
          message={message}
          chatId={chatId}
          isSelfMessage={isSelfMessage}
          isLastInConsecutiveBySender={isLastInConsecutiveBySender}
          messageBubbleStyledTagName={messageBubbleStyledTagName}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          children={children}
          searchText={searchText}
          searchMessage={searchMessage}
          searchMessageData={searchMessageData}
          jumpToMessage={jumpToMessage}
          isSideSearchActive={isSideSearchActive}
        />
        {isHovered && (
          <MessageBubblePopperContainer ref={setPopperElement} style={styles.popper} {...attributes.popper}>
            {getDetailedReadableDate(parseDate(dateCreated))}
            <MessageBubblePopperArrow ref={setArrowElement} style={styles.arrow} className="arrow" />
          </MessageBubblePopperContainer>
        )}
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      {renderMessageBubbleBaseOnType(MessageBubble)}

      {message && !message.isOptimistic ? (
        <React.Fragment>
          <MessageReplyOptionPopper chatId={chatId} message={message} />
          <MessageDetailOptionPopper handleMoreOptionClick={handleMoreOptionClick} />
          <DetailOptionMoreOptions
            moreOptionAnchorEl={moreOptionAnchorEl}
            setMoreOptionAnchorEl={setMoreOptionAnchorEl}
            isTextMessage={isTextMessage}
            isSingleChat={isSingleChat}
            isSelfMessage={isSelfMessage}
            setShowMessageDetailModal={setShowMessageDetailModal}
            setShowEscalationTriggerModal={setShowEscalationTriggerModal}
          />
        </React.Fragment>
      ) : message.isFailing ? (
        <ErrorIconHolder>
          <i className="material-icons">error</i>
        </ErrorIconHolder>
      ) : (
        <DotSpinner>
          <div className="bounce1" />
          <div className="bounce2" />
          <div className="bounce3" />
        </DotSpinner>
      )}

      {showMessageDetailModal && (
        <DetailMessageModal
          message={message}
          closeModal={() => setShowMessageDetailModal(false)}
          reactModalProps={{isOpen: showMessageDetailModal}}
        />
      )}
      {showEscalationTriggerModal && (
        <EscalationModalContainer
          messageId={message.id}
          message={message.message}
          chatId={chatId}
          closeModal={() => setShowEscalationTriggerModal(false)}
          isOpen={showEscalationTriggerModal}
        />
      )}
    </React.Fragment>
  );
};

export default MessageBubbleWrapper;
