import tryReadChatsQuery from 'src/utils/messengerHelper/tryReadCachedQuery';

export default function ensureChatListLoaded(ms: number) {
  let timeout = null;
  return new Promise<void>((resolve) => {
    (async function waitForQuery() {
      const chatResult = await tryReadChatsQuery();
      if (chatResult) {
        clearTimeout(timeout);
        resolve();
      } else {
        timeout = setTimeout(() => {
          waitForQuery();
        }, ms);
      }
    })();
  });
}
