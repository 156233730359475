import * as React from 'react';
import styled from 'styled-components';
import {AppDispatch, typedUseSelector} from 'src/redux';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import SwapConfirmationModal from 'src/pages/SchedulingPage/action-calendar/swap-section/SwapConfirmationModal';
import {ScheduleLayoutRouterParams} from 'src/types';
import {SWAPSCHEDULINGVIEW} from 'src/constants/scheduler';

const ButtonsWrapper = styled.div<{isSwap: boolean}>`
  bottom: 0;
  display: flex;
  z-index: 1;
  margin-top: 2em;
  background-color: white;
  position: sticky;
  padding: 1em 1em 1em 0;
  button:last-child {
    margin-left: ${(props) => (props.isSwap === true ? '1em' : 0)};
  }
  button:first-child {
    color: ${(props) => props.theme.androidGreen};
    background-color: white;
  }
  button:disabled {
    color: white;
    background-color: lightgrey;
  }
`;

interface Props {
  userToColorCodes: {
    [userId: string]: string;
  };
}

const FilterControlSection = ({userToColorCodes}: Props) => {
  const params = useParams() as ScheduleLayoutRouterParams;
  const [showConfirmationModal, setConfirmationModal] = React.useState(false);
  const selfShift = typedUseSelector((state) => state.tradeShift.selfShift);
  const otherShift = typedUseSelector((state) => state.tradeShift.otherShift);

  const dispatch: AppDispatch = useDispatch();

  const handleRest = () => {
    dispatch({type: 'tradeShift/RESET'});
  };

  const allSelected = Boolean(selfShift) && Boolean(otherShift);

  return (
    <React.Fragment>
      <ButtonsWrapper isSwap={params?.actionType === SWAPSCHEDULINGVIEW}>
        <Button variant="contained" disabled={false} disableTouchRipple onClick={handleRest}>
          reset
        </Button>
        {params?.actionType === SWAPSCHEDULINGVIEW && (
          <Button
            variant="contained"
            disabled={!allSelected}
            color="secondary"
            disableTouchRipple
            onClick={() => setConfirmationModal(true)}
          >
            request
          </Button>
        )}
      </ButtonsWrapper>
      {showConfirmationModal && (
        <SwapConfirmationModal
          isOpen={showConfirmationModal}
          closeModal={() => setConfirmationModal(false)}
          userToColorCodes={userToColorCodes}
        />
      )}
    </React.Fragment>
  );
};

export default FilterControlSection;
