import styled from 'styled-components';

export const Wrapper = styled.div<{mode?: boolean}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ${(props) => (props.mode ? 'padding: 16px;' : null)}
  gap: 8px;
  background: #ffffff;
  ${(props) =>
    props.mode ? 'border: 0.5px solid #D8D8D8; box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);border-radius: 4px;' : null}
  margin-bottom: 16px;
`;

export const AvatarMetaDataWrapper = styled.div`
  display: flex;
  gap: 8px;
`;
export const MetaDataWithName = styled.div`
  width: max-content;
  height: 43px;
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 24px;
  align-items: center;
  color: #222222;
  position: relative;
`;
export const UserName = styled.div`
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 24px;
`;

export const MetaData = styled.div`
  color: #767676;
  font-size: 14.5px;
  font-weight: 400;
  font-family: 'Open Sans';
  line-height: '20px';
`;

export const NoteData = styled.div`
  line-break: anywhere;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #222222;
  border-radius: 2px;
  margin-top: 12px;
  white-space: pre-line;
`;

export const NoteDataUser = styled.div`
  line-break: anywhere;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #222222;
  border-radius: 2px;
  white-space: pre-line;
`;

export const Visibility = styled.div<{backgroundColor?: string}>`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  flex: none;
  order: 2;
  flex-grow: 0;
  color: red;
  ${(props) => (props.backgroundColor ? `background-color : ${props.backgroundColor};` : null)}
`;

export const LockIconWrapper = styled.div``;
export const VisibilityData = styled.div`
  width: max-content;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  color: #4a4a4a;
  flex: none;
  order: 3;
  flex-grow: 0;
  display: inline-flex;
`;

export const VisibilityStatus = styled.span`
  font-weight: 600;
`;

export const ButtonWrapper = styled.div`
  gap: 8px;
  display: flex;
  justify-content: right;
  width: 100%;
`;

export const TextFeildLabel = styled.span`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #4a4a4a;
`;

export const StyledToastWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  gap: 16px;
`;
