import React, {useState} from 'react';
import {HypercareUI} from 'src/components/@hc-ui';
import {Grid, Button} from '@material-ui/core';
import {PROFILE_PAGE_ADD_ANOTHER_FIELD_TEXT} from 'src/constants/strings';
import PlusButtonIcon from 'src/svgs/PlusButton';
import {Dropdown} from 'src/components/@hc-ui/components/Dropdown/Dropdown';
import theme from 'src/styles/theme';
import styled from 'styled-components';
import {CPFField} from './custom-fields-section/CustomFieldsViewModel';
import {FullOrganizationMember} from 'src/types';

type ProfileConfigurableFieldsSectionProps = {
  title: string;
  user: FullOrganizationMember;
  mode: 'view' | 'edit';
  isAddFieldDisabled: boolean;
  addFieldOptions: CPFField[];
  onAddField: (field: CPFField) => void;
  children: React.ReactNode;
};

const PCFSectionCard = styled(HypercareUI.Card)`
  margin-bottom: 8px;
`;

export const ProfileConfigurableFieldsSection = (props: ProfileConfigurableFieldsSectionProps) => {
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [anchorElement, setAnchorElement] = useState<HTMLButtonElement | null>(null);

  return (
    <PCFSectionCard title={props.title}>
      <Grid container direction="column">
        <Grid item container direction="column" style={{padding: '8px 0'}}>
          {props.children}
        </Grid>
        <Grid item container style={{color: theme.primary}}>
          {props.mode === 'edit' && (
            <Button
              variant="text"
              color="inherit"
              startIcon={<PlusButtonIcon />}
              disabled={props.isAddFieldDisabled}
              onClick={() => setIsOptionsOpen(!isOptionsOpen)}
              ref={setAnchorElement}
            >
              {PROFILE_PAGE_ADD_ANOTHER_FIELD_TEXT}
            </Button>
          )}
          <Dropdown
            isOpen={isOptionsOpen}
            options={props.addFieldOptions}
            getOptionId={(o) => o.fieldId}
            getIsOptionDisabled={(o) => {
              if (!o.isEditable) return true;

              if (props.user.isDirectorySynced) {
                return o.isSynced;
              }

              return false;
            }}
            getIsOptionSelected={(_o) => false}
            onOptionClick={(o) => {
              props.onAddField(o);
              setIsOptionsOpen(false);
            }}
            onClickAway={() => setIsOptionsOpen(false)}
            renderOptionContent={(o) => o.label || o.fieldType}
            anchorElement={anchorElement}
          />
        </Grid>
      </Grid>
    </PCFSectionCard>
  );
};
