import styled from 'styled-components';
import theme from 'src/styles/theme';
import {
  Button,
  DialogActions,
  InputLabel,
  Typography,
  withStyles,
  Badge,
  Fab,
  ExtendButtonBaseTypeMap,
  ButtonBaseTypeMap,
} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import {Pic} from 'src/types/Message';
import {string} from 'prop-types';

const GeneralButton = styled(Button)`
  text-transform: none !important;
  border-radius: 4px !important;
  padding: 6px 12px !important;
  span {
    font-family: 'Nunito Sans', sans-serif !important;
    font-size: 17px;
  }
  &:disabled {
    color: white !important;
    background-color: ${(props) => props.theme.disabled} !important;
  }
`;

export const PrimaryButton = styled(GeneralButton).attrs(() => ({
  variant: 'outlined',
}))`
  background-color: ${(props) => props.theme.watermelon} !important;
  color: white !important;
  font-weight: 600 !important;
  &:disabled {
    background-color: ${(props) => props.theme.warmGrey} !important;
  }
`;

export const SecondaryButton = styled(GeneralButton).attrs((props) => ({
  variant: 'outlined',
  color: props.color,
}))`
  color: ${(props) => (props.color ? props.color : props.theme.chatTeal)} !important;
  font-weight: 600 !important;
`;

export const TextButton = styled(GeneralButton).attrs(() => ({
  variant: 'text',
}))`
  color: ${(props) => props.theme.chatTeal} !important;
  font-weight: 600 !important;
  font-size: 16px;
`;

export const FieldInputLabel = styled(InputLabel)<{fontcolor?: string}>`
  color: ${(props) => (props.fontcolor ? props.fontcolor : props.theme.greyishBrown)} !important;
  margin-bottom: 5px;
  font-size: 14px !important;
  line-height: 20px !important;
`;

export const DialogTitleTypography = styled(Typography)<{titleFontSize?: string; titleColor?: string}>`
  font-size: ${(props) => props.titleFontSize ?? '28px'} !important;
  font-weight: 700 !important;
  line-height: 38px !important;
  font-family: 'Nunito Sans', sans-serif !important;
  color: ${(props) => (props.titleColor ? props.titleColor : props.theme.secondary)} !important;
`;

export const CustomCheckbox = withStyles({
  root: {
    color: '#4a4a4a',
    '&$checked': {
      color: '#00859A',
    },
  },
  checked: {},
})(Checkbox);

export const StyledDialogActions = withStyles({
  root: {
    padding: '16px 24px 16px 16px',
  },
})(DialogActions);

export const ThemedCheckbox = withStyles({
  root: {
    color: theme.greyishBrown,
    '&$checked': {
      color: theme.chatTeal,
    },
  },
  checked: {},
})(Checkbox);

export const StyledBadge = styled(Badge)<{top: string; right: string}>`
  span {
    background-color: #ffcdd3;
    font-weight: inherit !important;
    color: ${(props) => props.theme.greyishBrown} !important;
    top: ${(props) => props.top} !important;
    right: ${(props) => props.right} !important;
  }
`;

export const ProfilePicImg = styled.img<Pic>`
  height: ${(props) => props.size}px;
  min-width: ${(props) => props.size}px;
  max-width: ${(props) => props.size}px;
  border-radius: ${(props) => props.size / 2}px;
  object-fit: cover;
`;

export const InitialsPic = styled.div<Pic>`
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  height: ${(props) => props.size}px;
  min-width: ${(props) => props.size}px;
  max-width: ${(props) => props.size}px;
  border-radius: ${(props) => props.size / 2}px;
  font-size: ${(props) => props.fontSize}px;
  color: white;
  background-color: ${(props) => props.theme.chatIconGrey};
`;

export const StyledIsearchFab = styled(Fab)`
  display: flex !important;
  width: 100% !important;
  height: 100% !important;
  background: #f6f6f9 !important;
  box-shadow: 0px 8px 12px rgb(9 30 66 / 15%), 0px 0px 1px rgb(9 30 66 / 31%) !important;
  border-radius: 43px !important;
  font-family: 'Open Sans' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  text-align: center !important;
  color: #000000 !important;
`;

export const StyledJump = styled.div`
  font-family: 'Open Sans' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  text-align: center !important;
  color: #000000 !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: flex-start !important;
  padding: 8px 8px 8px 24px !important;
  gap: 8px !important;
  text-transform: initial !important;
`;
