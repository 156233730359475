import * as React from 'react';
import * as R from 'ramda';
import store from 'src/redux';
import styled from 'styled-components';
import {Link, withRouter} from 'react-router-dom';
import {AllChatsQueryRP, ArchivedAllChatsQueryRP} from 'src/pages/MessengerPage/messenger/AllChatRenderProps';
import ColleagueListWrapper from 'src/pages/MessengerPage/messenger/chat-list-layout/ColleagueListWrapper';
import ChatListWrapper from 'src/pages/MessengerPage/messenger/chat-list-layout/ChatListWrapper';
import {StyledLink} from 'src/styles/styled-components/PopoverMenuComponents';
import {CloseBtn} from 'src/styles/styled-components/ModalStyleComponents';
import Helmet from 'react-helmet';
import ESCKeyListener from 'src/pages/MessengerPage/messenger/EscKeyListener';
import Tooltip from '@material-ui/core/Tooltip';
import {StyledKbd} from 'src/styles/styled-components/StyledKBD';
import {ARCHIVED, CONTACTS, MESSENGER} from 'src/constants/routerPathName';
import UserGroupPlusIcon from 'src/svgs/UserGroupPlusIcon';
import {RouteComponentProps} from 'react-router';
import HypercareSelfProfileViewModel from 'src/pages/LocatingPage/viewModels/HypercareSelfProfileViewModel';
import {IsFeatureFlagEnabled} from 'src/utils/FeatureFlagManager';
import {FeatureFlagResult} from 'src/utils/FeatureFlags';
import {UserViewModel} from '../../ContactsPage/viewModels/UserViewModel';
import {ARCHIVED_MESSAGES, CHAT_PANEL_INVITE_BANNER_TEXT, MESSAGES, NEW_CHAT} from 'src/constants/strings';
import CreateNewChat from 'src/svgs/CreateNewChatIcon';
import ChevronLeftIcon from 'src/svgs/ChevronLeftIcon';

const ChatPanel = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 300px;
  max-width: 400px;
  border-right: 1px solid ${(props) => props.theme.borderColor};
`;

const StyledTooltipHolder = styled.div`
  padding: 3px;
`;

const IconContainer = styled.div`
  position: relative;
  display: inline-block;

  &:hover::after {
    background-color: #f6f6f9;
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40px; /* Adjust size as needed */
    height: 40px; /* Adjust size as needed */
    border-radius: 50%;
    background-color: transparent;
    transition: background-color 0.3s ease;
    z-index: -1;
  }
`;

const ChatPanelBanner = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
  align-items: center;
  height: 60px;
  min-height: 60px;
  border-bottom: 1px solid ${(props) => props.theme.borderColor};
`;

const NewChatPanelBanner = styled.div`
  display: flex;
  padding: 15px;
  align-items: center;
  height: 60px;
  min-height: 60px;
  border-bottom: 1px solid ${(props) => props.theme.borderColor};
`;

const ChatPanelInviteBannerContainer = styled(ChatPanelBanner)`
  justify-content: left;
  color: ${(props) => props.theme.chatTeal};
  cursor: pointer;
  user-select: none;
  span {
    padding-left: 1em;
  }
`;

const MessagingH3 = styled.h3`
  font-family: 'Nunito';
  font-size: 18px;
  font-weight: 600;
`;

const OverriddenStyledLink = styled(StyledLink)`
  width: min-content;
`;

const OverriddenStyledBackLink = styled(StyledLink)`
  width: min-content;
  margin-right: 10px;
`;

interface ChatListLayoutProps extends RouteComponentProps {
  match;
  isSearch: boolean;
  handleIsSearch: () => void;
  handleJumpToMessage: () => void;
  isJumpToMessageEnable: boolean;
}

const ChatListLayout = ({
  match: {params},
  isSearch,
  handleIsSearch,
  handleJumpToMessage,
  isJumpToMessageEnable,
}: ChatListLayoutProps) => {
  const currentOrganization = store.getState().organization;
  const isArchive = window.location.href.includes(ARCHIVED);
  const isNewChat = params.chatId && params.chatId === 'new' && !isArchive;
  const DynamicQueryRPTag = isArchive ? ArchivedAllChatsQueryRP : AllChatsQueryRP;
  const {useGetOrganizationAllowInviteStatus} = HypercareSelfProfileViewModel();
  const {data: organizationAllowInviteStatus} = useGetOrganizationAllowInviteStatus();

  const userViewModel = UserViewModel();

  const {
    data: organizationTotalUserCount,
    error: organizationTotalUserCountError,
    loading: isOrganizationTotalUserCountLoading,
  } = userViewModel.fetchOrganizationTotalUsersCount();

  const paginatedContactsFeatureFlag = IsFeatureFlagEnabled(FeatureFlagResult.paginatedContacts);

  if (paginatedContactsFeatureFlag === undefined) {
    return null;
  }

  const ChatPanelInviteBanner = () => {
    if (isOrganizationTotalUserCountLoading && organizationTotalUserCountError) return null;

    const shouldShowChatPanelInviteBanner =
      currentOrganization.organizationId && organizationAllowInviteStatus && organizationTotalUserCount <= 25;

    if (shouldShowChatPanelInviteBanner) {
      return (
        <ChatPanelInviteBannerContainer onClick={() => window.routerHistory.push(`/${CONTACTS}?open=true`)}>
          <UserGroupPlusIcon />
          <span>{CHAT_PANEL_INVITE_BANNER_TEXT}</span>
        </ChatPanelInviteBannerContainer>
      );
    }
  };

  return (
    <ChatPanel>
      {isNewChat ? (
        <NewChatPanelBanner>
          <OverriddenStyledBackLink to="/messenger/home">
            <ESCKeyListener />
            <Tooltip
              title={
                <StyledTooltipHolder>
                  Go Back <StyledKbd>ESC</StyledKbd>
                </StyledTooltipHolder>
              }
              aria-label="Back"
            >
              <div>
                <ChevronLeftIcon />
              </div>
            </Tooltip>
          </OverriddenStyledBackLink>
          <MessagingH3>{NEW_CHAT}</MessagingH3>
        </NewChatPanelBanner>
      ) : (
        <ChatPanelBanner>
          <MessagingH3>{isArchive ? ARCHIVED_MESSAGES : MESSAGES}</MessagingH3>
          {isArchive ? (
            <OverriddenStyledLink to="/messenger/home">
              <ESCKeyListener />
              <Tooltip
                title={
                  <StyledTooltipHolder>
                    Go Back <StyledKbd>ESC</StyledKbd>
                  </StyledTooltipHolder>
                }
                aria-label="Back"
              >
                <CloseBtn />
              </Tooltip>
            </OverriddenStyledLink>
          ) : (
            <Link to={`/${MESSENGER}/new`}>
              <Tooltip
                title={
                  <StyledTooltipHolder>
                    New Chat <StyledKbd>c</StyledKbd>
                  </StyledTooltipHolder>
                }
                aria-label="New"
              >
                <IconContainer>
                  <CreateNewChat />
                </IconContainer>
              </Tooltip>
            </Link>
          )}
        </ChatPanelBanner>
      )}

      {ChatPanelInviteBanner()}

      <DynamicQueryRPTag>
        {({statPriorityChatRP, urgentPriorityChatRP, nonPriorityChatRP}) => {
          const {loading, error, data, fetchMore, networkStatus} = nonPriorityChatRP;
          const {error: statPriorityError, data: statPriorityData} = statPriorityChatRP;
          const {error: urgentPriorityError, data: urgentPriorityData} = urgentPriorityChatRP;
          if (error || statPriorityError || urgentPriorityError) {
            console.error(error || statPriorityError || urgentPriorityError);
          }
          const nonPriorityChats = R.pathOr([], [`${isArchive ? 'archived_chats' : 'chats'}`, 'chats'], data);
          const statPriorityChats = R.pathOr(
            [],
            [`${isArchive ? 'archived_chats' : 'chats'}`, 'chats'],
            statPriorityData,
          );
          const urgentPriorityChats = R.pathOr(
            [],
            [`${isArchive ? 'archived_chats' : 'chats'}`, 'chats'],
            urgentPriorityData,
          );
          // BUG: see JIRA @HWA-234 id overlap
          const priorityChats = R.unionWith(R.eqBy(R.prop('id')), statPriorityChats, urgentPriorityChats);
          const chats = R.unionWith(R.eqBy(R.prop('id')), priorityChats, nonPriorityChats);
          const continuationId = R.pathOr('', [`${isArchive ? 'archived_chats' : 'chats'}`, 'continuationId'], data);
          return (
            <React.Fragment>
              {isNewChat ? (
                <React.Fragment>
                  <Helmet defer={false}>
                    <title>Contacts - Hypercare</title>
                  </Helmet>

                  {!paginatedContactsFeatureFlag ? <ColleagueListWrapper /> : null}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <ChatListWrapper
                    loading={loading}
                    continuationId={continuationId}
                    fetchMore={fetchMore}
                    chats={chats}
                    networkStatus={networkStatus}
                    isSearch={isSearch}
                    handleIsSearch={handleIsSearch}
                    handleJumpToMessage={handleJumpToMessage}
                    isJumpToMessageEnable={isJumpToMessageEnable}
                  />
                </React.Fragment>
              )}
            </React.Fragment>
          );
        }}
      </DynamicQueryRPTag>
    </ChatPanel>
  );
};

export default withRouter(ChatListLayout);
