import {Box, makeStyles, Theme} from '@material-ui/core';
import React from 'react';
import localTheme from 'src/styles/theme';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: 10,
    backgroundColor: localTheme.lightGrey,
    borderRadius: '8px 8px 0px 0px',
  },
  progress: {
    height: 10,
    backgroundColor: localTheme.watermelon,
    borderRadius: '8px 8px 0px 0px',
    transition: 'width .8s',
  },
}));

interface ISignupProgressProps {
  value: number;
}

export const SignupProgressCopy: React.FC<ISignupProgressProps> = ({value}) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.progress} style={{width: `${value}px`}} />
    </Box>
  );
};
