import {
  ADDRESS_INVALID,
  ADDRESS_INVALID_MSG,
  ADDRESS_IN_USE,
  TOO_MANY_CHALLENGES,
  TOO_MANY_CHALLENGES_MSG,
  VALIDATION_CODE_FAILED_SEND_MSG,
} from 'src/constants/networkError';
import {HypercareSignupRepositoryProps} from '../HypercareSignupRepository';

interface Repository {}

interface HypercareSignupPhoneNumberViewModelInterface {
  repository: HypercareSignupRepositoryProps;
  nextButtonPressed?: (email: string) => Promise<any>;
  requestPhoneNumberValidationCode: ({phoneNumber}) => Promise<any>;
}
type addressType = 'email' | 'phoneNumber';
class HypercareSignupPhoneNumberViewModel implements HypercareSignupPhoneNumberViewModelInterface {
  repository: HypercareSignupRepositoryProps;
  nextButtonPressed;
  private type: addressType = 'phoneNumber';

  constructor(nextButtonPressed) {
    this.nextButtonPressed = nextButtonPressed;
  }

  handleNextButtonPressed = async (phoneNumber) => {
    // this.nextButtonPressed(values);
    const result = await this.requestPhoneNumberValidationCode(phoneNumber);

    if (result?.data.errors) {
      if (result?.data.errors[0].name === TOO_MANY_CHALLENGES) {
        return {
          success: false,
          error: {message: TOO_MANY_CHALLENGES_MSG, code: TOO_MANY_CHALLENGES},
        };
      } else if (result?.data.errors[0].name === ADDRESS_INVALID) {
        return {
          success: false,
          error: {message: ADDRESS_INVALID_MSG, code: ADDRESS_INVALID},
        };
      } else {
        return {
          success: false,
          error: {
            message: VALIDATION_CODE_FAILED_SEND_MSG,
            code: '',
          },
        };
      }
    }

    if (result?.data?.response) this.nextButtonPressed(phoneNumber);
  };

  requestPhoneNumberValidationCode = async ({phoneNumber}): Promise<any> => {
    const formatedPhoneNumber = phoneNumber.replace(/[()\-\s]/g, '');
    const result = await this.repository?.requestAddressToken(`+${formatedPhoneNumber}`, this.type);
    return result;
  };
}

export default HypercareSignupPhoneNumberViewModel;
