import React from 'react';
import styled from 'styled-components';
import {getInitials} from 'src/utils/user';
import {InvitedUser} from 'src/types';
import Avatar from '@material-ui/core/Avatar';
import DefaultUserIcon from 'src/svgs/DefaultUserIcon';

const UserItemAvatar = styled.div`
  margin-left: 4px;
`;
interface Props {
  invitedUser: InvitedUser;
}

const UserAvatar = ({invitedUser}: Props) => {
  return (
    <UserItemAvatar>
      {invitedUser.address ? (
        <Avatar>
          <DefaultUserIcon />
        </Avatar>
      ) : (
        <Avatar>{invitedUser.user ? getInitials(invitedUser.user) : null}</Avatar>
      )}
    </UserItemAvatar>
  );
};

export default UserAvatar;
