import React from 'react';
import useRepository from 'src/pages/SchedulingPage/SchedulingRepository';
import {sortByUpdatedAt} from 'src/utils/locating/sortByUpdatedAt';
import {
  BasicRoleField,
  DepartmentRoleNoteObject,
  FetchRoleNotesForDepartmentsResultItem,
  MultipleDepartmentRoleNoteObject,
} from 'src/types/HiddenNotes';
import store from 'src/redux';
import {actions as schedulingAction} from 'src/redux/actions/scheduling';
import {HypercareOnCallActions} from 'src/redux/actions/onCall';
import ApiHelper from 'src/api';
import moment from 'moment-timezone';
import {LocatingCodeTeam, LocatingDepartmentScope, LocatingRole, Schedule} from 'src/types';
import {FetchDepartmentScheduleInput} from '../../../gql/query/FetchDepartmentSchedule';
import {IsFeatureFlagEnabled} from '../../../utils/FeatureFlagManager';
import {FeatureFlagResult} from '../../../utils/FeatureFlags';
import {FETCH_ORGANIZATION_SITES_ERROR} from '../../../constants/networkError';

export default function HypercareSchedulingViewModel() {
  const {
    fetchRoleNotesForDepartment,
    fetchCodeTeamsForDepartment,
    fetchDepartmentsAndRoleForSite,
    fetchFavouriteRolesForSite,
    fetchDepartmentsForSite,
    fetchScheduleForDepartment,
    fetchSitesForOrg,
  } = useRepository();

  const getRoleNotesForDepartment = async (scheduleId: number, departmentId: number) => {
    const {result, error} = await fetchRoleNotesForDepartment(scheduleId, departmentId);

    if (error) {
      return {error};
    }

    if (result) {
      let roleResultObject = result.data?.organizationalUnitQuery?.organizationalUnit?.scheduling?.schedule?.roles;
      let departmentRoleNoteObject = buildDepartmentRoleNoteObject(roleResultObject);
      store.dispatch(schedulingAction.setRoleNotes(departmentRoleNoteObject));
    }

    return {result};
  };

  const buildDepartmentRoleNoteObject = (roles: BasicRoleField[]): DepartmentRoleNoteObject => {
    return roles.reduce((roleNoteObject, role) => {
      const sortedRoleNotesArray = sortByUpdatedAt(role.notes);

      roleNoteObject[role.id] = {
        notes: sortedRoleNotesArray,
        roleName: role.name,
      };

      return roleNoteObject;
    }, {} as DepartmentRoleNoteObject);
  };

  const fetchRoleNotes = async (scheduleId: number, departmentId: number) => {
    if (!scheduleId) return Promise.resolve({error: false, result: true});

    return fetchRoleNotesForDepartment(scheduleId, departmentId);
  };

  const buildMultipleDepartmentsRoleNoteObject = (result): MultipleDepartmentRoleNoteObject => {
    const roleNotesObject: {} | MultipleDepartmentRoleNoteObject = {};

    result.forEach((fetchRoleNoteResult: FetchRoleNotesForDepartmentsResultItem) => {
      const resultObject = fetchRoleNoteResult?.result?.data?.organizationalUnitQuery?.organizationalUnit;
      if (resultObject) {
        const departmentId = resultObject.id;

        resultObject.scheduling.schedule.roles.forEach((role: BasicRoleField) => {
          const roleId = role.id;
          const sortedRoleNotesArray = sortByUpdatedAt(role.notes);

          roleNotesObject[departmentId] = roleNotesObject[departmentId] || {};
          roleNotesObject[departmentId][roleId] = roleNotesObject[departmentId][roleId] || {
            notes: [],
            roleName: role.name,
          };

          roleNotesObject[departmentId][roleId].notes.push(...sortedRoleNotesArray);
        });
      }
    });

    return roleNotesObject;
  };

  const getRoleNotesForDepartmentArray = async (departments: {id: number; scheduleId: number}[]) => {
    if (!departments?.length) return Promise.resolve({error: false, result: true});

    const results = await Promise.all(
      departments.map((department) => fetchRoleNotes(department.scheduleId, department.id)),
    );

    const hasError = results.find((result) => result.error);

    if (hasError) {
      return {error: true, success: false};
    }

    const roleNotesObject = buildMultipleDepartmentsRoleNoteObject(results);
    store.dispatch(HypercareOnCallActions.setOnCallRoleNotes(roleNotesObject));

    return {error: null, success: true};
  };

  const fetchFavouriteRolesSiteData = async (siteId: number) => {
    const currentDay = moment().date();

    if (currentDay === 1) {
      return await fetchFavouriteRolesForFirstDay(siteId);
    } else {
      return await fetchFavouriteRolesForNonFirstDay(siteId);
    }
  };

  const fetchFavouriteRolesForFirstDay = async (siteId: number) => {
    const result = await ApiHelper.PrivateEndpoints.fetchFavouriteRolesMiddlewareAPIRequest({siteId});

    if (!result || !result.success) {
      return {error: true, success: false};
    }

    if (result) {
      let roleResultObject: BasicRoleField[] = result.data.site.favouritedRolesForMonth;
      let departmentRoleNoteObject = buildDepartmentRoleNoteObject(roleResultObject);

      store.dispatch(HypercareOnCallActions.setOnCallFavouriteRoleNotes(departmentRoleNoteObject));
    }

    return {result: result.data.site.favouritedRolesForMonth};
  };

  const fetchFavouriteRolesForNonFirstDay = async (siteId: number) => {
    const {result, error} = await fetchFavouriteRolesForSite(siteId);

    if (error) {
      return {error};
    }

    if (result) {
      let roleResultObject = result.data.organizationalUnitQuery?.organizationalUnit.favouritedRoles;
      let departmentRoleNoteObject = buildDepartmentRoleNoteObject(roleResultObject);

      store.dispatch(HypercareOnCallActions.setOnCallFavouriteRoleNotes(departmentRoleNoteObject));
    }

    return {result: result.data.organizationalUnitQuery.organizationalUnit.favouritedRoles};
  };

  const getCodeTeamsForDepartment = async (departmentId: number) => {
    try {
      const {result, error} = await fetchCodeTeamsForDepartment(departmentId);
      if (error) {
        return {error};
      }

      if (result) {
        return {result};
      }
    } catch (e) {
      console.error('An error occurred:', e);
    }
  };
  const fetchSiteData = async (siteId: number) => {
    const currentDay = moment().date();

    if (currentDay === 1) {
      return await fetchSiteDataForFirstDay(siteId);
    } else {
      return await fetchSiteDataForNonFirstDay(siteId);
    }
  };

  const getDepartmentsForSite = async (siteId: number) => {
    const departmentsMetadataRequest = await fetchDepartmentsForSite(siteId);

    if (departmentsMetadataRequest.error) {
      return [];
    }

    if (departmentsMetadataRequest && departmentsMetadataRequest.result) {
      const sortedDepartmentArray = departmentsMetadataRequest.result.data.locating.site.departments.sort((a, b) =>
        a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
      );

      const newSiteObject = {
        [siteId]: sortedDepartmentArray,
      };

      store.dispatch(HypercareOnCallActions.setOnCallSites(newSiteObject));
      return sortedDepartmentArray;
    }
  };

  const fetchSiteDataForFirstDay = async (siteId: number): Promise<LocatingDepartmentScope[]> => {
    const startDate = moment().startOf('day').toISOString();
    const endDate = moment().endOf('day').toISOString();

    const departmentsMetadataRequestPromise = fetchDepartmentsForSite(siteId);
    const departmentsDataRequestPromise = ApiHelper.PrivateEndpoints.fetchDepartmentsAndRolesMiddlewareAPIRequest({
      siteId,
      startDate,
      endDate,
    });

    const [departmentsMetadataResult, departmentsResult] = await Promise.all([
      departmentsMetadataRequestPromise,
      departmentsDataRequestPromise,
    ]);

    if (!departmentsResult || !departmentsResult.success || !departmentsResult.data) {
      console.error(departmentsResult.errorMessage);
      return [];
    }

    if (!departmentsMetadataResult.result) {
      console.error(departmentsMetadataResult.error);
      return [];
    }

    const departmentsMetadata = departmentsMetadataResult.result.data.locating.site.departments;
    const departments: LocatingDepartmentScope[] = departmentsResult.data.site.departments;

    departmentsMetadata.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
    departments.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

    return departmentsMetadata.map((d) => departments.find((currDep) => currDep.id === d.id) ?? {...d, teams: []});
  };

  const fetchSiteDataForNonFirstDay = async (siteId: number) => {
    const {result, errorMessage} = await fetchDepartmentsAndRoleForSite(siteId);

    if (errorMessage) {
      return [];
    }

    if (result) {
      const departmentList = result.data.locating.site.departments;
      departmentList?.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
      return [...departmentList];
    }
  };

  const getDepartmentsAndRolesForSite = async (siteId: number) => {
    try {
      const siteData = await fetchSiteData(siteId);

      const codeTeamsPromises = siteData.map((department) => getCodeTeamsForDepartment(department.id));

      const codeTeamsResults = await Promise.all(codeTeamsPromises);

      const hasError = codeTeamsResults.some((result) => result.error);

      if (hasError) {
        return {error: true, success: false};
      }

      codeTeamsResults.forEach(({result}) => {
        const teamArray = result.locating.department.teams;
        const id = siteData.findIndex((department) => department.id === result.locating.department.id);
        siteData[id].teams = teamArray;
      });

      store.dispatch(HypercareOnCallActions.setOnCallDepartments(siteData));

      return null;
    } catch (e) {
      console.error('An error occurred:', e);
    }
  };

  const getFavouriteRolesForSite = async (siteId: number) => {
    return await fetchFavouriteRolesSiteData(siteId);
  };

  const getScheduleForDepartment = async ({siteId, departmentId}: FetchDepartmentScheduleInput) => {
    let siteData = store.getState().onCall.OnCallSites[siteId];
    let currentSelectedDepartment = siteData.find((department) => department.id === departmentId);
    const startDate = moment().startOf('day').toISOString();
    const endDate = moment().endOf('day').toISOString();
    const currentDay = moment().date();

    if (!currentSelectedDepartment?.teams && !currentSelectedDepartment?.schedule) {
      const schedulePromise = fetchScheduleForDepartment({siteId, departmentId});

      const callMiddleWareTest = await ApiHelper.PrivateEndpoints.fetchScheduleDataForDepartment({
        departmentId,
        startDate,
        endDate,
      });

      const codeTeamsPromise = getCodeTeamsForDepartment(departmentId);

      const [scheduleResult, codeTeamResult] = await Promise.all([schedulePromise, codeTeamsPromise]);

      if ('result' in scheduleResult) {
        let departmentData =
          currentDay === 1 ? callMiddleWareTest.data?.department : scheduleResult.result.data.locating.site.department;
        if (departmentData?.schedule) {
          currentSelectedDepartment = {...departmentData};
        } else {
          currentSelectedDepartment = {...currentSelectedDepartment, schedule: []};
        }
      }

      if (codeTeamResult && codeTeamResult.result) {
        const teamArray = codeTeamResult.result.locating.department.teams;
        currentSelectedDepartment['teams'] = teamArray;
      }

      const newDepartmentData = siteData.map((department) =>
        department.id === departmentId ? currentSelectedDepartment : department,
      );

      const newSiteData = {
        [siteId]: newDepartmentData,
      };

      store.dispatch(HypercareOnCallActions.setOnCallSites(newSiteData));
    }
  };
  const getSitesForOrganization = async (organizationId: number, filterStatus: boolean) => {
    const result = await fetchSitesForOrg(organizationId, filterStatus);

    if ('error' in result) {
      return {error: FETCH_ORGANIZATION_SITES_ERROR};
    }

    if ('result' in result) {
      const siteFullScope = result.result.data.locating.organization.sites;

      siteFullScope?.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

      const defaultSelectedSite = siteFullScope?.[0];

      return {
        defaultSelectedSite,
        siteFullScope,
      };
    }
  };

  return {
    getRoleNotesForDepartment,
    getRoleNotesForDepartmentArray,
    getCodeTeamsForDepartment,
    getDepartmentsAndRolesForSite,
    getFavouriteRolesForSite,
    getDepartmentsForSite,
    getScheduleForDepartment,
    getSitesForOrganization,
  };
}
