import * as React from 'react';
import styled from 'styled-components';
import ProfilePic from 'src/components/ProfilePic';
import {User} from 'src/types';
import {getFullName} from 'src/utils/user';
import {NO_ONE_IS_AVAILABLE} from 'src/constants/strings';

const CustomAvatarGroup = styled.div`
  display: flex;
  height: 28px;
  margin-top: 4px;
`;

const ProfilePicWrapper = styled.div`
  margin-right: 4px;
`;

const NumberOverLayWrapper = styled.div`
  position: relative;
  .MuiBadge-badge {
    display: none;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 25px;
  border-radius: 50%;
  display: block;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
`;

const NoShift = styled.span`
  font-size: 14px;
  color: grey;
`;

interface Props {
  users: User[];
  maxDisplay?: number; // that is including overlay
}

const ProfileGroup = ({users, maxDisplay = 5}: Props) => {
  if (users.length === 0) return <NoShift>{NO_ONE_IS_AVAILABLE}</NoShift>;

  let usersCopy = [...users];
  const restUsersNumber = users.length - maxDisplay;
  const isMoreThanMaxUser = restUsersNumber > 0;

  if (isMoreThanMaxUser) {
    usersCopy.length -= restUsersNumber;
    usersCopy = usersCopy.filter((user) => user);
  }

  const restUsersNameArr = users.slice(maxDisplay - 1).map((user) => getFullName(user));

  return (
    <CustomAvatarGroup>
      {usersCopy.map((user, i) => {
        if (i === usersCopy.length - 1 && isMoreThanMaxUser) {
          return (
            <NumberOverLayWrapper key={i} title={restUsersNameArr.join(', ')}>
              <ProfilePic size={25} fontSize={12} users={[user]} />
              <Overlay>+{restUsersNumber + 1}</Overlay>
            </NumberOverLayWrapper>
          );
        }
        return (
          <ProfilePicWrapper key={i} title={getFullName(user)}>
            <ProfilePic size={25} fontSize={12} users={[user]} />
          </ProfilePicWrapper>
        );
      })}
    </CustomAvatarGroup>
  );
};

export default ProfileGroup;
