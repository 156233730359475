import React from 'react';
import client from 'src/apollo';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import {StyledModal, CloseBtn, Header} from 'src/styles/styled-components/ModalStyleComponents';
import CircularProgress from '@material-ui/core/CircularProgress';
import {AppDispatch} from 'src/redux';
import {useDispatch} from 'react-redux';
import {toast} from 'react-toastify';
import RequestSwapShiftMutation from 'src/gql/mutation/RequestSwapShiftMutation';
import {ButtonsWrapper, ModalBodyWrapper, ErrorText} from 'src/styles/styled-components/SharedModalStyles';

const ReasonText = styled.p`
  line-height: 1.5em;
  margin-bottom: 2em;
`;

const StyledButtonsWrapper = styled(ButtonsWrapper)`
  button {
    width: 180px !important;
  }
`;

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  closeAll: () => void;
  note: string;
  desiredShiftId: string;
  surrenderingShiftId: string;
  warningReason: string;
  customizedToast: () => JSX.Element;
}

const SwapDoubleConfirmationModal = ({
  isOpen,
  desiredShiftId,
  surrenderingShiftId,
  closeAll,
  closeModal,
  note,
  warningReason,
  customizedToast,
}: Props) => {
  const [isLoading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');
  const dispatch: AppDispatch = useDispatch();

  const handleSendRequest = async () => {
    try {
      setLoading(true);
      setError('');
      await client.mutate({
        mutation: RequestSwapShiftMutation,
        variables: {
          desiredShiftId,
          surrenderingShiftId,
          note: note,
          shouldIgnoreWarnings: true,
        },
      });
      toast.info(customizedToast(), {
        autoClose: false,
      });
      closeAll();
      dispatch({type: 'tradeShift/CLEAR_SELF'});
      dispatch({type: 'tradeShift/CLEAR_OTHER'});
    } catch (e) {
      console.error(e);
      setLoading(false);
      setError('Failed to request swap shift, please check your internet connection and try again');
    }
  };

  return (
    <StyledModal
      isOpen={isOpen}
      ariaHideApp={false}
      shouldCloseOnEsc={!isLoading}
      shouldCloseOnOverlayClick={!isLoading}
      onRequestClose={closeModal}
    >
      <Header>
        <span>Warning</span>
        {!isLoading && <CloseBtn onClick={closeModal} />}
      </Header>
      <ModalBodyWrapper>
        <ReasonText>{warningReason}. Do you still wish to proceed?</ReasonText>

        <ErrorText>{error}</ErrorText>

        <StyledButtonsWrapper>
          <Button variant="contained" disabled={isLoading} disableTouchRipple onClick={closeModal}>
            nevermind
          </Button>
          <Button
            variant="contained"
            onClick={handleSendRequest}
            disabled={isLoading}
            color="secondary"
            disableTouchRipple
          >
            {isLoading ? <CircularProgress size={20} /> : <span>proceed anyway</span>}
          </Button>
        </StyledButtonsWrapper>
      </ModalBodyWrapper>
    </StyledModal>
  );
};

export default SwapDoubleConfirmationModal;
