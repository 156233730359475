import React from 'react';
import client from 'src/apollo';
import styled from 'styled-components';
import UnreadMessageBanner from 'src/pages/MessengerPage/messenger/messages-layout/body-section/UnreadMessageBanner';
import MessagesItemViewController from 'src/pages/MessengerPage/messenger/messages-layout/message-item/MessagesItemViewController';
import {UnreadMsgPayload, Message, MessageInChat} from 'src/types';
import {connect} from 'react-redux';
import {RootState, PriorityType} from 'src/types';
import {OptimisticMessages} from 'src/redux/actions/messages';
import GetChatQuery from 'src/gql/query/GetChatQuery';
import {StatMessagePriority, UrgentMessagePriority} from 'src/constants/messageTypes';
import {SmallBottomLoader} from 'src/components/LoadingDiv';

const BottomMessageContainer = styled.div`
  height: 10% !important;
`;

interface Props {
  chatId: string;
  isSingleChat: boolean;
  messages: Message[];
  searchMessage: MessageInChat | null;
  searchMessageData: MessageInChat[] | [];
  optimisticMessages: OptimisticMessages;
  scrollToBottom: () => void;
  jumpToMessage: MessageInChat;
  isSideSearchActive: boolean;
  searchText: string;
  isSearch: boolean;
}

const defaultUnreadMsgPayload: UnreadMsgPayload = {
  lastUnreadMessageId: null,
  unreadMessageCount: 0,
  unreadPriorityMessageCount: 0,
};

const MessagesListViewController = ({
  chatId,
  messages,
  searchMessage,
  searchMessageData,
  optimisticMessages,
  scrollToBottom,
  isSingleChat,
  jumpToMessage,
  isSideSearchActive,
  searchText,
  isSearch,
}: Props) => {
  const [unreadMsgPayload, setUnreadMsgPayload] = React.useState<UnreadMsgPayload>(defaultUnreadMsgPayload);

  const scrollClearTimeOut = React.useRef(null);

  const {lastUnreadMessageId, unreadMessageCount, unreadPriorityMessageCount} = unreadMsgPayload;

  const optimisticMessagesForChat: Message[] = optimisticMessages[chatId] || [];

  /**
   * intend for show unread messages count banner, lastUnreadMessage only set by scoket event
   * only do so at first render, the banner will not show up when already in the chat
   */
  React.useLayoutEffect(() => {
    const {chat} = client.readQuery({
      query: GetChatQuery,
      variables: {
        chatId: chatId,
      },
    });
    // only set to first socket incoming event until it gets cleared
    if (chat.lastUnreadMessage) {
      // currently only set by frontend socket event not backend
      const lastUnreadMessageId = chat.lastUnreadMessage.id;
      let unreadPriorityMessageCount = messages.filter(
        (msg) =>
          [StatMessagePriority, UrgentMessagePriority].includes(PriorityType[msg.priority]) === true &&
          Number(msg.id) >= Number(lastUnreadMessageId),
      ).length;
      let unreadMessageCount =
        messages.filter((msg) => Number(msg.id) >= Number(lastUnreadMessageId)).length - unreadPriorityMessageCount;

      setUnreadMsgPayload({
        lastUnreadMessageId,
        unreadMessageCount,
        unreadPriorityMessageCount,
      });

      if (scrollClearTimeOut.current) {
        clearTimeout(scrollClearTimeOut.current);
      }
      scrollClearTimeOut.current = setTimeout(() => {
        setUnreadMsgPayload(defaultUnreadMsgPayload);
      }, 3500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * For clear last unread message banner
   */
  React.useLayoutEffect(() => {
    if (unreadMsgPayload.lastUnreadMessageId) {
      // automatically scroll to last unread message that being marked from socket event
      scrollToElement(unreadMsgPayload.lastUnreadMessageId);
      // clear last unread message id from apollo cache data source
      const {chat} = client.readQuery({
        query: GetChatQuery,
        variables: {
          chatId: chatId,
        },
      });

      // clear last unread indicator (currenlty was inited by socket events only)
      if (chat.lastUnreadMessage !== null) {
        const chatId = chat.id;
        chat.lastUnreadMessage = null;
        const newChatQuery = {chat};

        client.cache.writeQuery({
          query: GetChatQuery,
          variables: {chatId},
          data: newChatQuery,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unreadMsgPayload]);

  function scrollToElement(id: number) {
    const element = document.getElementById(`unread-${id.toString()}`);
    if (element) {
      element.scrollIntoView({
        behavior: 'auto',
        block: 'center',
        inline: 'nearest',
      });
    }
  }

  if (messages) {
    return (
      <React.Fragment>
        {[...optimisticMessagesForChat, ...messages]
          .map((message: Message, i: number) => (
            <React.Fragment key={`fragment-${message.id}`}>
              <UnreadMessageBanner
                messageId={message.id}
                lastUnreadMessageId={lastUnreadMessageId}
                unreadMessageCount={unreadMessageCount}
                unreadPriorityMessageCount={unreadPriorityMessageCount}
              />
              <MessagesItemViewController
                key={message.id}
                chatId={chatId}
                isSingleChat={isSingleChat}
                prevMessage={i < messages.length - 1 ? messages[i + 1] : null}
                currentMessage={message}
                searchMessage={searchMessage}
                searchMessageData={searchMessageData}
                nextMessage={i > 0 ? messages[i - 1] : null}
                scrollToBottom={scrollToBottom}
                jumpToMessage={jumpToMessage}
                isSideSearchActive={isSideSearchActive}
                searchText={searchText}
              />
            </React.Fragment>
          ))
          .reverse()}
        {(isSearch && searchText) || jumpToMessage ? <SmallBottomLoader key={`loader${0}`} /> : <></>}
      </React.Fragment>
    );
  }

  return null;
};
const mapStateToProps = (state: RootState) => ({
  optimisticMessages: state.messages.optimisticMessages,
});

export default connect(mapStateToProps)(MessagesListViewController);
