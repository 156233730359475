import styled from 'styled-components';

export const CustomToastFlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 1em;
  line-height: 1.5em;
  font-weight: lighter;
  * {
    font-family: sans-serif;
    font-weight: lighter;
  }
  svg {
    margin-right: 0.5em;
  }
  button {
    font-weight: normal;
    text-align: left;
    &:hover {
      color: black;
    }
  }
`;

export const ToastCloseBtn = styled.div`
  z-index: 1;
  cursor: pointer;
  position: relative;
  width: 22px;
  height: 22px;
  &:before,
  &:after {
    position: absolute;
    top: 2px;
    left: 10px;
    content: ' ';
    height: 20px;
    width: 2px;
    background-color: white;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
`;

export const ModalBodyWrapper = styled.div`
  position: relative;
  color: ${(props) => props.theme.greyishBrown};
`;

export const TextareaWrapper = styled.div`
  margin: 1em 0;
`;

export const WarningText = styled.div`
  margin-bottom: 1.5em;
  width: 100%;
  font-size: 14px;
  line-height: 1.5em;
  padding-left: 5px;
  color: dimgrey;
  svg {
    margin-right: 5px;
    transform: translateY(2px);
  }
`;

export const ErrorText = styled.div`
  font-size: 14px;
  margin-bottom: 10px;
  color: red;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  margin-top: 1em;
  justify-content: flex-end;
  button {
    margin-left: 1em;
  }
  button:first-child {
    color: ${(props) => props.theme.androidGreen};
    background-color: white;
  }
  button:last-child {
    width: 140px;
  }
  button:disabled {
    color: white;
    background-color: lightgrey;
  }
`;

export const IndicatorTitle = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  line-height: 1.5em;
  margin-bottom: 4px;
  color: ${(props) => props.theme.greyishBrown};
`;
