import moment from 'moment';
import {clone} from 'ramda';
import {Assignee, ShiftResultInfo, MonthlyCalendar, MarketplacePosts} from 'src/types';
import {REDUCER_DATE_FORMAT} from 'src/constants/scheduler';

export function checkAllZero(startDate: moment.Moment, endDate: moment.Moment) {
  return startDate.hour() === 0 && endDate.hour() === 0 && startDate.minute() === 0 && endDate.minute() === 0;
}

function getUserPartial(shift: ShiftResultInfo) {
  return {
    userId: shift.userId || shift.user.id,
    userFullName: shift.userFullName || shift.user.firstname + ' ' + shift.user.lastname,
    firstname: shift.user.firstname,
    lastname: shift.user.lastname,
    profilePic: shift.user.profilePic,
    shiftId: shift.shiftId || shift.id,
  };
}

function getNumDaysInBetween(startDate: moment.Moment, endDate: moment.Moment) {
  let date1 = startDate.date();
  let date2 = endDate.date();

  if (date2 < date1) date2 = moment().endOf('month').millisecond(0o00).date();

  return date2 - date1;
}

export const splitMarketplaceShift = (
  marketPlacePosts: MarketplacePosts[],
  schedule: MonthlyCalendar,
  roleId: string,
  index: number,
): MonthlyCalendar => {
  marketPlacePosts.forEach((post) => {
    const {shift, offerTypes, id} = post;
    if (shift.role.id !== roleId) return;

    const startDate = moment(shift.startDate);
    const endDate = moment(shift.endDate);
    const userPartial = getUserPartial(shift);

    if (schedule[startDate.format(REDUCER_DATE_FORMAT)]) {
      schedule[startDate.format(REDUCER_DATE_FORMAT)][index].assignee.push({
        ...userPartial,
        startTime: startDate,
        endTime: endDate,
        offerTypes,
        postId: id,
      } as Assignee);
    }
  });

  return schedule;
};

// note: shifts dates are still in UTC iso string,
// check if its in the month before split shift in schdule

function splitShifts(
  shifts: ShiftResultInfo[],
  schedule: MonthlyCalendar,
  index: number,
  preventSplitCrossDay: boolean,
): MonthlyCalendar {
  shifts.forEach((shift) => {
    const startDate = moment(shift.startDate);
    const endDate = moment(shift.endDate);
    const userPartial = getUserPartial(shift);

    const numDaysInBetween = getNumDaysInBetween(startDate, endDate);

    if (numDaysInBetween === 0 || preventSplitCrossDay) {
      if (schedule[startDate.format(REDUCER_DATE_FORMAT)]) {
        schedule[startDate.format(REDUCER_DATE_FORMAT)][index].assignee.push({
          ...userPartial,
          startTime: startDate,
          endTime: endDate,
        } as Assignee);
      }
    } else {
      let previousStart = startDate.clone();
      let previousEnd = endDate.clone();

      for (let i = 0; i < numDaysInBetween; i++) {
        previousEnd = previousStart.clone().endOf('day');

        const newShift = clone(shift);
        newShift.startDate = previousStart.toISOString();
        newShift.endDate = previousEnd.toISOString();

        if (schedule[previousStart.format(REDUCER_DATE_FORMAT)]) {
          schedule[previousStart.format(REDUCER_DATE_FORMAT)][index].assignee.push({
            ...userPartial,
            startTime: moment(newShift.startDate),
            endTime: moment(newShift.endDate),
          } as Assignee);
        }

        previousStart.add(1, 'days').startOf('day');
      }

      // avoid 00:00 - 00:00 on the next splited day (local time)
      if (endDate.hour() === 0 && endDate.minute() === 0) return;

      const newShift = clone(shift);
      newShift.startDate = previousStart.toISOString();

      if (schedule[moment(newShift.startDate).format(REDUCER_DATE_FORMAT)]) {
        schedule[moment(newShift.startDate).format(REDUCER_DATE_FORMAT)][index].assignee.push({
          ...userPartial,
          startTime: moment(newShift.startDate),
          endTime: moment(newShift.endDate),
        } as Assignee);
      }
    }
  });

  return schedule;
}

export default splitShifts;
