import React from 'react';
import {Dialog, DialogContent, Divider, Typography} from '@material-ui/core';

import store, {typedUseSelector} from 'src/redux';
import {MessagePriorityViewSize} from 'src/types';
import {actions as MessageTemplateActions} from 'src/redux/actions/messageTemplates';
import {actions as FiledropActions} from 'src/redux/actions/filedrop';
import MessagePriority from 'src/components/MessagePriority';
import {
  PrimaryButton,
  SecondaryButton,
  StyledDialogActions,
} from 'src/styles/styled-components/StyledMaterialComponents';
import {MaterialDialogHeader, Transition} from 'src/components/HypercareComponents';

interface PriorityMessageTemplateProps {
  openPriorityMessageTemplate: boolean;
  closePriorityMessageTemplate: (boolean) => void;
  templatePriorityPreviousBtnHandler: () => void;
  templatePriorityReviewRequestBtnHandler: () => void;
}
const PriorityMessageTemplate = ({
  openPriorityMessageTemplate,
  closePriorityMessageTemplate,
  templatePriorityPreviousBtnHandler,
  templatePriorityReviewRequestBtnHandler,
}: PriorityMessageTemplateProps) => {
  const {messageTemplatePriority} = typedUseSelector((state) => state.messageTemplate);

  const handlePrioritySelection = (index) => {
    store.dispatch(MessageTemplateActions.setMessageTemplatePriority(index));
  };

  return (
    <Dialog maxWidth={'sm'} fullWidth={true} open={openPriorityMessageTemplate} TransitionComponent={Transition}>
      <MaterialDialogHeader
        dialogTitle={'Set priority'}
        closeIconButtonClickHandler={() => {
          store.dispatch(MessageTemplateActions.resetToInitalState());
          store.dispatch(FiledropActions.clearDroppedFiles());
          closePriorityMessageTemplate(false);
        }}
      />
      <DialogContent>
        <Typography>Indicate the priority of your request</Typography>
        <MessagePriority
          messagePriority={messageTemplatePriority}
          size={MessagePriorityViewSize.large}
          prioritySelectionHandler={handlePrioritySelection}
        />
      </DialogContent>
      <Divider />
      <StyledDialogActions>
        <SecondaryButton onClick={() => templatePriorityPreviousBtnHandler()} style={{marginRight: 8}}>
          Previous
        </SecondaryButton>
        <PrimaryButton onClick={() => templatePriorityReviewRequestBtnHandler()}>Review request</PrimaryButton>
      </StyledDialogActions>
    </Dialog>
  );
};

export default PriorityMessageTemplate;
