import React from 'react';
import styled from 'styled-components';
import {toast} from 'react-toastify';
import StyledTooltip from 'src/components/StyledTooltip';
import InfoIcon from 'src/svgs/Info';
import {useQuery} from 'react-apollo';
import {StyledInputBase} from 'src/components/StepFormPartials';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import {ActionButtonWrapper} from 'src/styles/styled-components/Button';
import Chevron from 'src/svgs/Chevron';
import {
  DepartmentScope,
  EscalationLadder,
  EscalationType,
  FetchDepartmentsOnCallForResult,
  FetchEscalationLaddersByDepartmentResult,
  User,
} from 'src/types';
import {
  SelectionFlexContainer,
  SelectionLabel,
  StyledSelectionMenuItem,
  SelectionWrapper,
  StyledFormControl,
} from 'src/styles/styled-components/Selection';
import {
  EscalationModalStepVariableContext,
  ISelectionContext,
} from 'src/pages/MessengerPage/escalation-modal/EscalationModalStepProvider';
import FetchDepartmentsOnCallFor from 'src/gql/query/FetchDepartmentsOnCallFor';
import FetchEscalationLaddersByDepartmentID from 'src/gql/query/FetchEscalationLaddersByDepartmentID';

const StyledInfo = styled(InfoIcon)`
  width: 16px;
  height: 16px;
  overflow: visible;
`;

const StyledTooltipAlt = styled(StyledTooltip)`
  & .tooltip {
    max-width: 290px !important;
  }
`;

const ErrorMessageSection = styled.div`
  margin-bottom: 2em;
  color: ${(props) => props.theme.redPink};
`;

const StyledChevron = styled(Chevron)`
  position: absolute;
  right: 4px;
`;

const RedirectionSpan = styled.span`
  cursor: pointer;
  text-decoration: underline;
`;

interface Props {
  targetUser: User;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  showIndividualEscalationModal: () => void;
}

interface EscalationLadderSelectionStepProps extends Props {
  selectedDepartment: DepartmentScope;
  selectedEscalationLadder: EscalationLadder;
  setSelectionContext: React.Dispatch<React.SetStateAction<ISelectionContext>>;
  setEscalationType: React.Dispatch<React.SetStateAction<EscalationType>>;
}

const DISABLED_PLACEHOLDER = 'disabledPlaceholder';

const EscalationLadderSelectionStep = ({
  targetUser,
  setCurrentStep,
  selectedDepartment,
  selectedEscalationLadder,
  setSelectionContext,
  setEscalationType,
  showIndividualEscalationModal,
}: EscalationLadderSelectionStepProps) => {
  const departmentIDRef = React.useRef(selectedDepartment ? selectedDepartment.id : null);

  const {
    loading: loadingForDepartment,
    error: errorForDepartment,
    data: onCallDepartmentsData,
  } = useQuery<FetchDepartmentsOnCallForResult>(FetchDepartmentsOnCallFor, {
    variables: {
      userId: targetUser.id,
    },
    fetchPolicy: 'network-only',
  });

  const {
    loading: loadingForLadder,
    error: errorForLadder,
    data: escalationLadderData,
  } = useQuery<FetchEscalationLaddersByDepartmentResult>(FetchEscalationLaddersByDepartmentID, {
    variables: {
      departmentId: selectedDepartment && selectedDepartment.id,
    },
    skip: !onCallDepartmentsData || !selectedDepartment,
  });

  const fetchedDepartmentList: DepartmentScope[] = React.useMemo(() => {
    if (onCallDepartmentsData && onCallDepartmentsData.profile) {
      const sortedList = onCallDepartmentsData.profile.departmentsOnCall.sort((a, b) =>
        a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
      );
      return sortedList;
    }
    return [];
  }, [onCallDepartmentsData]);

  const fetchedEscalationLadderList: EscalationLadder[] = React.useMemo(() => {
    if (
      escalationLadderData &&
      escalationLadderData?.locating?.department &&
      escalationLadderData?.locating?.department.id === departmentIDRef.current
    ) {
      const sortedEscalationLadderList = escalationLadderData.locating.department.escalationLadders.sort((a, b) =>
        a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
      );

      return sortedEscalationLadderList;
    }
    return [];
  }, [escalationLadderData, departmentIDRef]);

  React.useEffect(() => {
    if (fetchedDepartmentList.length >= 1 && !selectedDepartment) {
      setSelectionContext((prev) => ({
        ...prev,
        selectedDepartment: fetchedDepartmentList[0],
        selectedEscalationLadder: null,
      }));
      departmentIDRef.current = fetchedDepartmentList[0].id;
    }
    return () => null;
  }, [fetchedDepartmentList, setSelectionContext, selectedDepartment]);

  React.useEffect(() => {
    if (fetchedEscalationLadderList.length >= 1) {
      if (selectedEscalationLadder && fetchedEscalationLadderList[0].id === selectedEscalationLadder.id) return;
      setSelectionContext((prev) => ({
        ...prev,
        selectedEscalationLadder: selectedEscalationLadder || fetchedEscalationLadderList[0],
      }));
    }
    if (fetchedEscalationLadderList.length === 0) {
      setSelectionContext((prev) => ({
        ...prev,
        selectedEscalationLadder: null,
      }));
    }
    return () => null;
  }, [fetchedEscalationLadderList, selectedEscalationLadder, selectedDepartment, setSelectionContext]);

  if (errorForDepartment || errorForLadder) {
    toast.error('Network request failed, please check your internet connection and try again');
  }

  const handleChangeDepartment = (event: React.ChangeEvent<HTMLInputElement>) => {
    let targetId = Number(event.target.value);
    let result = fetchedDepartmentList.find((department) => department.id === targetId);
    setSelectionContext((prev) => ({
      ...prev,
      selectedEscalationLadder: null,
      selectedDepartment: result,
    }));
    departmentIDRef.current = result?.id || null;
  };

  const handleChangeEscalationLadder = (event: React.ChangeEvent<HTMLInputElement>) => {
    let targetId = event.target.value;
    let result = fetchedEscalationLadderList.find((ladder) => ladder.id === targetId);
    setSelectionContext((prev) => ({
      ...prev,
      selectedEscalationLadder: result,
    }));
  };

  const handleNext = () => setCurrentStep((prev) => prev + 1);

  const handlePrevious = () => setCurrentStep((prev) => prev - 1);

  let shouldDepartmentSelectionDisabled =
    loadingForDepartment || !fetchedDepartmentList || fetchedDepartmentList.length <= 1;

  let shouldShowDepartmentPlaceholderOption =
    loadingForDepartment || !fetchedDepartmentList || fetchedDepartmentList.length === 0;

  let shouldLadderSelectionDisabled =
    loadingForDepartment || loadingForLadder || !fetchedEscalationLadderList || fetchedEscalationLadderList.length <= 1;

  let shouldLadderPlaceholderOption =
    loadingForDepartment ||
    loadingForLadder ||
    !fetchedEscalationLadderList ||
    fetchedEscalationLadderList.length === 0;

  let shouldShowSuggestionErrorMessage =
    !loadingForDepartment && fetchedDepartmentList && fetchedDepartmentList.length === 0;

  const generateLadderPlaceHolder = () => {
    if (shouldShowSuggestionErrorMessage) return 'No escalation policies available';
    if (!selectedDepartment) return 'Please select a department first';
    if (loadingForLadder) return 'Loading ...';
    return 'No escalation policies available';
  };

  const handleErrorRedirection = () => {
    setEscalationType('individual');
    handlePrevious();
    showIndividualEscalationModal();
  };

  return (
    <div>
      <SelectionWrapper>
        <SelectionFlexContainer>
          <SelectionLabel>Select a Department</SelectionLabel>
        </SelectionFlexContainer>
        <StyledFormControl
          fullWidth
          disabled={shouldDepartmentSelectionDisabled}
          $isDisabled={shouldDepartmentSelectionDisabled}
          $shouldHighlight={!!selectedDepartment}
        >
          <Select
            value={
              !!selectedDepartment
                ? selectedDepartment.id
                : fetchedDepartmentList[0]
                ? fetchedDepartmentList[0].id
                : DISABLED_PLACEHOLDER
            }
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
              style: {
                maxHeight: '480px',
              },
            }}
            onChange={handleChangeDepartment}
            IconComponent={() => (shouldDepartmentSelectionDisabled ? null : <StyledChevron />)}
            input={<StyledInputBase />}
          >
            {shouldShowDepartmentPlaceholderOption ? (
              <option value={DISABLED_PLACEHOLDER} disabled>
                {loadingForDepartment ? 'Loading...' : 'No departments with escalation policies available'}
              </option>
            ) : (
              fetchedDepartmentList.map((department) => (
                <StyledSelectionMenuItem value={department.id} key={`department-${department.id}`}>
                  {department.name}
                </StyledSelectionMenuItem>
              ))
            )}
          </Select>
        </StyledFormControl>
      </SelectionWrapper>

      <SelectionWrapper>
        <StyledTooltipAlt
          arrow
          title="Escalation ladder defines the order in which 
          users will be contacted (e.g. how much time 
          between attempts, how many attempts 
          before the next user is contacted)."
          className="styledPopper"
          placement="right"
        >
          <SelectionFlexContainer>
            <SelectionLabel>Select an Escalation Ladder</SelectionLabel>
            <StyledInfo stroke={'dimgrey'} circlefill={'white'} strokefill={'dimgrey'} />
          </SelectionFlexContainer>
        </StyledTooltipAlt>
        <StyledFormControl
          fullWidth
          disabled={shouldLadderSelectionDisabled}
          $isDisabled={shouldLadderSelectionDisabled}
          $shouldHighlight={!!selectedEscalationLadder}
        >
          <Select
            value={
              shouldLadderPlaceholderOption
                ? DISABLED_PLACEHOLDER
                : !!selectedEscalationLadder
                ? selectedEscalationLadder.id
                : fetchedEscalationLadderList[0]
                ? fetchedEscalationLadderList[0].id
                : DISABLED_PLACEHOLDER
            }
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
              style: {
                maxHeight: '320px',
              },
            }}
            onChange={handleChangeEscalationLadder}
            IconComponent={() => (shouldLadderSelectionDisabled ? null : <StyledChevron />)}
            input={<StyledInputBase />}
          >
            {shouldLadderPlaceholderOption ? (
              <option value={DISABLED_PLACEHOLDER} disabled>
                {generateLadderPlaceHolder()}
              </option>
            ) : (
              fetchedEscalationLadderList.map((escalationLadder) => (
                <StyledSelectionMenuItem value={escalationLadder.id} key={`ladder-${escalationLadder.id}`}>
                  {escalationLadder.name}
                </StyledSelectionMenuItem>
              ))
            )}
          </Select>
        </StyledFormControl>
      </SelectionWrapper>

      <ErrorMessageSection>
        {shouldShowSuggestionErrorMessage && (
          <span>
            Department escalation not available,{' '}
            <RedirectionSpan onClick={handleErrorRedirection}>
              Click here to send an individual escalation instead
            </RedirectionSpan>
          </span>
        )}
      </ErrorMessageSection>

      <ActionButtonWrapper>
        <Button variant="outlined" onClick={handlePrevious}>
          Previous
        </Button>
        <Button
          disabled={!selectedEscalationLadder || !selectedDepartment || loadingForDepartment || loadingForLadder}
          variant="contained"
          color="secondary"
          onClick={handleNext}
        >
          Next
        </Button>
      </ActionButtonWrapper>
    </div>
  );
};

export default (props: Props) => (
  <EscalationModalStepVariableContext.Consumer>
    {({selectionContext: {selectedEscalationLadder, selectedDepartment}, setSelectionContext, setEscalationType}) => (
      <EscalationLadderSelectionStep
        selectedDepartment={selectedDepartment}
        selectedEscalationLadder={selectedEscalationLadder}
        setSelectionContext={setSelectionContext}
        setEscalationType={setEscalationType}
        {...props}
      />
    )}
  </EscalationModalStepVariableContext.Consumer>
);
