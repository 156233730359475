import styled from 'styled-components';
import getColor from 'src/utils/getColor';

export default styled.p`
  margin: 0;
  height: 17px;
  width: 100%;
  opacity: 0.35;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: -0.1px;
  text-transform: uppercase;
  text-align: left;
  color: ${getColor('greyishBrown')};
`;
