import * as R from 'ramda';
import {createAction, ActionType, getType} from 'typesafe-actions';
import {Message, User} from 'src/types';

export const actions = {
  addOptimisticMessage: createAction(
    'messages/ADD_MESSAGE',
    (resolve) => (message: Message, chatId: string) => resolve({message, chatId}),
  ),
  removeOptimisticMessage: createAction(
    'messages/REMOVE_MESSAGE',
    (resolve) => (messageId: string, chatId: string) => resolve({messageId, chatId}),
  ),
  failOptimisticMessageMark: createAction(
    'messages/FAIL_MESSAGE',
    (resolve) => (messageId: string, chatId: string) => resolve({messageId, chatId}),
  ),
  readPriorityMessages: createAction(
    'messages/READ_PRIORITY_MESSAGE',
    (resolve) => (messageIds: number[], chatId: string) => resolve({messageIds, chatId}),
  ),
  targetUserStatus: createAction(
    'messages/TARGET_USER_STATUS',
    (resolve) => (targetUser: User) => resolve({targetUser}),
  ),
};

export type MessageActions = ActionType<typeof actions>;

export const initialState: MessagesState = {
  optimisticMessages: {},
  targetUser: null,
  priorityMessagesRead: {},
};

export interface OptimisticMessages {
  [key: string]: Message[];
}

export interface MessagesState {
  optimisticMessages: OptimisticMessages;
  targetUser: User;
  priorityMessagesRead: {
    [key: string]: number[];
  };
}

export default (state: MessagesState = initialState, action: MessageActions) => {
  switch (action.type) {
    case getType(actions.addOptimisticMessage):
      return R.mergeDeepWith(
        R.concat,
        {
          optimisticMessages: {
            [action.payload.chatId]: [action.payload.message],
          },
        },
        state,
      );

    case getType(actions.removeOptimisticMessage):
      return R.evolve({
        optimisticMessages: {
          [action.payload.chatId]: (messages: Message[]) =>
            messages.filter((message) => message.id !== action.payload.messageId),
        },
      })(state);

    case getType(actions.failOptimisticMessageMark):
      const messagesClone = R.clone(state.optimisticMessages[action.payload.chatId]);
      messagesClone.map((message) => {
        if (message.id === action.payload.messageId) message.isFailing = true;
        return message;
      });

      return {
        ...state,
        optimisticMessages: {
          ...state.optimisticMessages,
          [action.payload.chatId]: messagesClone,
        },
      };

    case getType(actions.readPriorityMessages):
      if (state.priorityMessagesRead[action.payload.chatId]) {
        return R.evolve({
          priorityMessagesRead: {
            [action.payload.chatId]: R.concat(action.payload.messageIds),
          },
        })(state);
      } else {
        return R.assocPath(['priorityMessagesRead', action.payload.chatId], action.payload.messageIds)(state);
      }

    case getType(actions.targetUserStatus):
      return {
        ...state,
        targetUser: action.payload.targetUser,
      };
    default:
      return state;
  }
};
