import React from 'react';
import styled from 'styled-components';
import {StyledFormControl, StyledInputBase} from 'src/components/StepFormPartials';
import SearchIcon from 'src/svgs/SearchIcon';

export const StyledSearchIcon = styled(SearchIcon)`
  position: absolute;
  left: 10px;
  z-index: 1;
  width: 1em;
  height: 1em;
`;

export const StyledInput = styled(StyledInputBase)`
  input {
    padding-left: 2em;
    padding-right: calc(1em + 80px);
    :placeholder-shown {
      padding-right: 2em;
    }
  }
`;

const SearchBardEndAdornment = styled.div`
  width: 80px;
  text-align: center;
  background-color: ${(props) => props.theme.chatTeal};
  color: white;
  position: absolute;
  right: 1em;
  line-height: 30px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 8px;
  opacity: 1;
  transition: 0.2s;
  &:hover {
    opacity: 0.7;
  }
`;

interface Props {
  setSearchKeyWord: React.Dispatch<React.SetStateAction<string>>;
  placeholder: string;
}

const SearchBarInput = ({setSearchKeyWord, placeholder}: Props) => {
  const [keyword, setKeyword] = React.useState('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === '') setSearchKeyWord('');
    if (event.target.value.trim() === '') {
      setKeyword('');
      return;
    }
    setKeyword(event.target.value);
  };

  const onSearchClicked = () => {
    const toSearch = keyword.trim().toLowerCase();
    if (keyword !== '') setSearchKeyWord(toSearch);
  };

  const handleKeyPress = (e: React.KeyboardEvent<any>) => {
    if (e.key === 'Enter' || e.keyCode === 13) onSearchClicked();
  };

  return (
    <StyledFormControl>
      <StyledInput
        value={keyword}
        type="text"
        autoFocus
        placeholder={placeholder}
        startAdornment={<StyledSearchIcon />}
        onKeyPress={handleKeyPress}
        endAdornment={
          keyword.length > 0 ? (
            <SearchBardEndAdornment onClick={onSearchClicked}>Search</SearchBardEndAdornment>
          ) : (
            <div />
          )
        }
        onChange={handleChange}
      />
    </StyledFormControl>
  );
};

export default SearchBarInput;
