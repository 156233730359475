import React from 'react';
import {Formik} from 'formik';
import * as yup from 'yup';
import {ITellUsAboutYourSelfStepData} from 'src/types';
import {roleSchema} from 'src/utils/validations';
import AnalyticsManager, {EVENTS, PAGE_VIEWS} from 'src/analytics/AnalyticsManager';

import {StepContainerCopy} from '../components/step-container-copy';
import NewStepHeader from '../components/step-header-copy';
import NewNextStepButton from '../components/NextStepButton';
import {StyledTextField} from 'src/styles/styled-components/StyledInputField';
import NewStepFooter from '../components/step-footer-copy';

const validationSchema = yup.object().shape<ITellUsAboutYourSelfStepData>({
  jobTitle: roleSchema,
});

export const SignupJobTitleView = ({viewModel, inputData, onBackButtonClick}) => {
  React.useEffect(() => {
    AnalyticsManager.recordPageVisited(PAGE_VIEWS.signupProfile);
  }, []);

  const onNextClick = (values: {firstName: string; lastName: string; jobTitle: string}) => {
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.signupPersonalInfoNextPressed,
      params: {
        job_title: values.jobTitle,
      },
    });
  };

  return (
    <StepContainerCopy currentStep={4}>
      <NewStepHeader
        isDisabled={false}
        title="Enter your job title"
        description="What’s your role at {{orgName}}?"
        onBackButtonClick={onBackButtonClick}
      />
      <Formik
        initialValues={{
          jobTitle: inputData.jobTitle,
        }}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          viewModel.handleNextButtonPressed(values);
        }}
      >
        {({handleSubmit, values, isValid, handleChange, handleBlur, setFieldValue, touched, errors}) => (
          <form onSubmit={handleSubmit}>
            <StyledTextField
              margin="normal"
              fullWidth={true}
              variant="outlined"
              value={values.jobTitle}
              label="Job Title"
              name="jobTitle"
              placeholder="Job Title (Suggested Format-> Physician/Cardiology)"
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.jobTitle && errors.jobTitle}
              error={touched.jobTitle && Boolean(errors.jobTitle)}
            />
            <NewNextStepButton label="Next" onClick={() => onNextClick(values)} disabled={!isValid}>
              Next
            </NewNextStepButton>
          </form>
        )}
      </Formik>
      <NewStepFooter />
    </StepContainerCopy>
  );
};
