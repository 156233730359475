import React, {CSSProperties, useState, useCallback} from 'react';
import Select, {createFilter} from 'react-select';
import AnalyticsManager, {PAGE_VIEWS} from 'src/analytics/AnalyticsManager';
import {ValueType} from 'react-select/src/types';
import overriddenComponents, {useStyles} from 'src/pages/MessengerPage/auto-select/ReactSelectMaterialUI';
import {UserSelectOptionType} from 'src/types';
import {
  NoCircleOptionV2 as Option,
  AutoHeightMenuListV2 as MenuList,
} from 'src/pages/MessengerPage/auto-select/ReactSelectSharedComponents';
import theme from 'src/styles/theme';
import {UserViewModel} from 'src/pages/ContactsPage/viewModels/UserViewModel';
import debounce from 'lodash/debounce';
import getParsedAuthInfo from 'src/utils/localStorageHandler';
import {PaginatedUsersType} from 'src/types/PaginatedTypes';

const getOptionLabel = (option) => `${option.label} (@${option.username})`;

const ChangeStatusModalUserDropdownV2 = ({workStatusProxyUser, onClickColleague}) => {
  const [options, setOptions] = useState<PaginatedUsersType[] | []>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [continuationId, setContinuationId] = useState(null);

  const parsedAuthInfo = getParsedAuthInfo();
  const selfID = parsedAuthInfo && parsedAuthInfo.user.id;

  const {getSearchUsers} = UserViewModel();
  const classes = useStyles({});

  React.useEffect(() => {
    AnalyticsManager.recordPageVisited(PAGE_VIEWS.selectContactsList);
  }, []);

  let proxyUserData;
  if (workStatusProxyUser && workStatusProxyUser.id) {
    const proxyUserFragment = options.find((colleague) => colleague.id === workStatusProxyUser.id);
    // BUG: proxy was from other organization will still showing
    if (proxyUserFragment) {
      proxyUserData = {
        value: proxyUserFragment.id,
        label: proxyUserFragment.firstname + ' ' + proxyUserFragment.lastname,
        username: proxyUserFragment.username,
        role: proxyUserFragment.role ? proxyUserFragment.role : '',
      } as UserSelectOptionType;
    }
  }

  const [selectedColleague, setSelectedColleague] = React.useState<ValueType<UserSelectOptionType>>([proxyUserData]);

  function handleChangeSingle(value: ValueType<UserSelectOptionType>) {
    onClickColleague(value);
    setSelectedColleague(value);
  }

  const loadOptions = useCallback(async () => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    try {
      // Simulate fetching options from an API with pagination and search
      const response = await getSearchUsers({text: searchText, limit: 30, continuationId});
      const newOptions = response.users;
      if (response?.users?.length > 0) {
        const uniqueNewOptions = newOptions.filter((newOption) => {
          return ![...options].some((existingOption) => existingOption.id === newOption.id);
        });
        setOptions([...options, ...uniqueNewOptions]);
        setContinuationId(response.continuationId);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error('Error loading options:', error);
    } finally {
      setIsLoading(false);
    }
  }, [isLoading, continuationId, searchText]);

  const getSearchData = async (searchValue: string) => {
    const searchResult = await getSearchUsers({
      text: searchValue,
      limit: 30,
      continuationId: null,
    });

    if ('error' in searchResult) {
      setOptions([]);
    } else {
      setOptions(searchResult.users);
    }

    setIsLoading(false);
  };

  const handleCombinedSearch = React.useCallback(
    debounce(async (searchValue: string) => {
      await getSearchData(searchValue);
    }, 250),
    [],
  );

  const handleSearch = async (searchValue: string) => {
    setSearchText(searchValue);

    if (searchValue === '') {
      setOptions([]);
      return;
    }

    if (searchValue.length <= 1) {
      return;
    }

    setIsLoading(true);

    handleCombinedSearch(searchValue);
  };

  const handleInputChange = (inputValue) => {
    setHasMore(true);
    if (!inputValue) {
      setOptions([]);
      setContinuationId(null);
      setHasMore(true);
    }
    setSearchText(inputValue);
    if (inputValue.length > 1) {
      handleSearch(inputValue);
    }
  };

  const suggestions = options
    ? Array.from(options)
        .filter((colleague) => colleague.id !== selfID)
        .map((colleague) => {
          return {
            value: colleague?.id,
            label: colleague?.firstname + ' ' + colleague?.lastname,
            role: colleague?.role,
            firstName: colleague?.firstname,
            lastName: colleague?.lastname,
            profilePic: colleague?.profilePic,
            username: colleague?.username,
          } as UserSelectOptionType;
        })
    : [];

  const handleMenuScrollToBottom = () => {
    if (hasMore) {
      loadOptions();
    }
  };

  const selectStyles = {
    input: (base: CSSProperties) => ({
      ...base,
      color: theme.primaryText,
      '& input': {
        font: 'inherit',
      },
    }),
    menuPortal: (base) => ({...base, zIndex: 9999}),
  };

  return (
    // tslint:disable jsx-no-lambda
    <div className={classes.root} onClick={(e) => e.stopPropagation()}>
      <Select
        classes={classes}
        styles={selectStyles}
        inputId="react-select-single"
        TextFieldProps={{
          InputLabelProps: {
            htmlFor: 'react-select-single',
            shrink: true,
          },
        }}
        placeholder={'Select alternate contact'}
        options={suggestions}
        menuPortalTarget={document.body}
        components={{
          ...overriddenComponents,
          Option,
          MenuList,
        }}
        isLoading={isLoading}
        onMenuScrollToBottom={handleMenuScrollToBottom}
        onInputChange={handleInputChange}
        isClearable={true}
        getOptionLabel={getOptionLabel}
        value={selectedColleague}
        onChange={handleChangeSingle}
        filterOption={null}
      />
    </div>
  );
};

export default ChangeStatusModalUserDropdownV2;
