import React from 'react';
import styled from 'styled-components';
import {List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, Typography} from '@material-ui/core';

import {CheckIcon} from 'src/svgs/Check';
import StatPriorityIcon from 'src/svgs/StatPriorityIcon';
import UrgentPriorityIcon from 'src/svgs/UrgentPriorityIcon';
import GeneralPriorityIcon from 'src/svgs/GeneralPriorityIcon';
import {MessagePriorityViewSize} from 'src/types';

const PopoverList = styled(List)<{size: MessagePriorityViewSize}>`
  width: 100%;
  max-width: ${(props) => (props.size === MessagePriorityViewSize.small ? '40ch' : null)};
`;

const priorityArray = [
  {
    primaryText: 'STAT',
    secondaryText: 'High priority, bypasses do-not-disturb and silent modes with a loud sound',
  },
  {
    primaryText: 'Urgent',
    secondaryText: 'Medium priority, bypasses do-not-disturb and silent modes',
  },
  {
    primaryText: 'General',
    secondaryText: 'Regular message, does not bypass do-not-disturb and silent modes',
  },
];

interface MessagePriorityProps {
  size: MessagePriorityViewSize;
  messagePriority: number;
  prioritySelectionHandler: (index) => void;
}
const MessagePriority = ({size, messagePriority, prioritySelectionHandler}: MessagePriorityProps) => {
  return (
    <PopoverList size={size}>
      {priorityArray.map((priority, index) => {
        return (
          <ListItem
            key={index}
            button
            selected={messagePriority === index}
            onClick={(_) => prioritySelectionHandler(index)}
          >
            <ListItemIcon>
              {index === 0 ? <StatPriorityIcon /> : index === 1 ? <UrgentPriorityIcon /> : <GeneralPriorityIcon />}
            </ListItemIcon>
            <ListItemText
              primary={<Typography style={{fontWeight: 600}}>{priority.primaryText}</Typography>}
              secondary={priority.secondaryText}
            />
            <ListItemSecondaryAction>{messagePriority === index && <CheckIcon />}</ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </PopoverList>
  );
};

export default MessagePriority;
