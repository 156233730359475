import React from 'react';
import styled from 'styled-components';
import theme from 'src/styles/theme';
import ExternalLinkIcon from 'src/svgs/ExternalLinkIcon';
import {IsFeatureFlagEnabled} from 'src/utils/FeatureFlagManager';
import Info from 'src/svgs/Info';
import HypercareSelfProfileViewModel from 'src/pages/LocatingPage/viewModels/HypercareSelfProfileViewModel';
import {FullOrganizationMember} from 'src/types';
const ExclamationWrapper = styled.div`
  padding-top: 2px;
  svg {
    width: 20px;
    height: 20px;
  }
`;

const FAQDetailWrapper = styled.div`
  margin-left: 1em;
`;

const FAQDetailTitle = styled.div`
  font-family: 'Nunito';
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props.theme.blackFontColor};
`;

const FAQDetailBody = styled.div`
  margin-top: 12px;
  font-size: 14px;
  line-height: 1.5em;
  span {
    color: ${(props) => props.theme.chatTeal};
    cursor: pointer;
    padding-left: 8px;
  }
`;

const FAQDetailFooter = styled.div`
  margin-top: 1.5em;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  color: ${(props) => props.theme.chatTeal};
`;

const FAQSectionWrapper = styled.div`
  display: flex;
  color: ${(props) => props.theme.greyishBrown};
  margin-top: 2em;
  margin-bottom: 1em;
  padding: 16px;
  opacity: 0.8;
  border-radius: 3px;
  background-color: ${(props) => props.theme.lightGrey};
`;

interface Props {
  allowInvites: boolean;
  handleClickInviteColleagues: () => void;
}

const FAQSection = ({allowInvites, handleClickInviteColleagues}: Props) => {
  const coSigningInvitesFF = IsFeatureFlagEnabled('coSigningInvites');

  return (
    <>
      {allowInvites && (
        <FAQSectionWrapper>
          <ExclamationWrapper>
            <Info circlefill={theme.chatTeal} stroke={'white'} strokefill={'white'} />
          </ExclamationWrapper>

          <FAQDetailWrapper>
            <FAQDetailTitle>How do invites work?</FAQDetailTitle>
            <FAQDetailBody>
              {coSigningInvitesFF
                ? 'You can request to invite colleagues by email and phone numbers. Invited users will be reviewed by organization admins. They will be prompted to approve the invitation.'
                : 'You can invite colleagues by providing their email addresses and/or phone numbers. We will send them instructions and a special invite code. They can use these to join your organization on Hypercare. Alternatively, you can choose to send them the special invite code yourself, if they do not require further instructions on how to use it.'}
              <span
                onClick={() =>
                  window.open(
                    'https://support.hypercare.com/hc/en-us/articles/360042908011-How-do-I-invite-colleagues-to-the-app-',
                    '_blank',
                  )
                }
              >
                Learn more <ExternalLinkIcon />
              </span>
            </FAQDetailBody>
            <FAQDetailFooter onClick={handleClickInviteColleagues}>Invite colleagues to Hypercare</FAQDetailFooter>
          </FAQDetailWrapper>
        </FAQSectionWrapper>
      )}
    </>
  );
};

export default FAQSection;
