import React from 'react';
import localTheme from 'src/styles/theme';
import {Box, Button, ButtonProps, Typography, makeStyles, Theme, CircularProgress} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    fontFamily: 'Nunito',
    textTransform: 'none',
    padding: '8px 12px',
    minWidth: 75,
    height: 50,
    backgroundColor: '#FF3E55',
    borderRadius: '4px',
    '& span': {
      color: '#FFFFFF',
      fontFamily: 'Nunito Sans',
      fontStyle: 'normal',
      fontSize: 15,
      fontWeight: 600,
      lineHeight: '24px',
      padding: 0,
    },
    '&:hover': {
      backgroundColor: '#FF3E55',
    },
  },
  label: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: 16,
    fontWeight: 400,
    marginLeft: theme.spacing(),
  },
  disabled: {
    backgroundColor: `${localTheme.warmGrey} !important`,
    color: 'white !important',
  },
}));

interface INextStepButtonProps extends ButtonProps {
  loading?: boolean;
  loadingLabel?: string;
  label?: string;
}

const NEXT_STEP_BTN_ID = 'next-step-btn';

const NewNextStepButton: React.FC<INextStepButtonProps> = ({
  children,
  loading,
  label,
  loadingLabel,
  disabled,
  ...rest
}) => {
  const classes = useStyles();

  React.useEffect(() => {
    const handleUserKeyPress = (event: KeyboardEvent) => {
      const {key} = event;
      const isAnyModalOpen = document.querySelector('.ReactModal__Content');
      if (isAnyModalOpen) return;
      if (key === 'Enter') {
        const elem = document.getElementById(NEXT_STEP_BTN_ID);
        if (elem) elem.focus();
      }
    };
    window.addEventListener('keydown', handleUserKeyPress);
    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  }, []);

  return (
    <Box display="flex" alignItems="center" mt={2}>
      <Button
        id={NEXT_STEP_BTN_ID}
        className={classes.button}
        classes={{disabled: classes.disabled}}
        disableElevation
        variant="contained"
        type="submit"
        size="large"
        disabled={loading || disabled}
        {...rest}
      >
        {loading && <CircularProgress color="inherit" size={18} style={{marginRight: 16}} />}
        {loading && loadingLabel}
        {!loading && (label || 'Create an account')}
      </Button>
      <Typography variant="body1" color="textSecondary" className={classes.label}>
        or press Enter
      </Typography>
    </Box>
  );
};

export default NewNextStepButton;
