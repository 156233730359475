import React, {useState} from 'react';
import {Manager, Reference, Popper} from 'react-popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import TripleDot from 'src/svgs/TripleDot';
import {OptionsBoxNotes, OptionNotes, MenuButtonVisibility} from 'src/styles/styled-components/PopoverMenuComponents';
import styled, {ThemeProvider} from 'styled-components';
import theme from 'src/styles/theme';
import 'src/components/index.css';
import {
  LockIconWrapper,
  Visibility,
  VisibilityData,
  VisibilityStatus,
} from 'src/styles/styled-components/NotesProfilePage';
import {ACCESS, VISIBILITY} from 'src/constants/hiddenNotes';
import store from 'src/redux';
import LockIcon from 'src/svgs/LockIconHiddenNotes';
import appTheme from 'src/styles/theme';
import EyeIcon from 'src/svgs/EyeIcon';
import ArrowDown from 'src/svgs/ArrowDown';
import AnalyticsManager, {EVENTS} from 'src/analytics/AnalyticsManager';

const OptionHolder = styled.div`
  cursor: pointer;
`;

const ChevronDownIcon = styled.div`
  margin-left: 6.5px;
  margin-top: 1px;
`;

interface Props {
  dataid?: String;
  disabled?: boolean;
  noteData?: any;
  visibility?: string;
  setVisibility: any;
  mode: boolean;
  updateMode: boolean;
}

const NoteVisibilityWrapper = ({
  dataid,
  disabled = false,
  noteData,
  visibility,
  setVisibility,
  mode,
  updateMode,
}: Props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const clickedOutside = () => {
    setIsMenuOpen(false);
  };
  const onClickMenu = () => {
    setIsMenuOpen(true);
  };

  const handleMakeNotePrivate = () => {
    setVisibilityContent(ACCESS.PRIVATE);
    setIsMenuOpen(false);

    if (mode && !updateMode) {
      AnalyticsManager.applyAnalytics({
        eventName: EVENTS.profileNoteNewVisibilityOptionPressed,
        params: {
          visibility: ACCESS.PRIVATE,
        },
      });
    } else {
      AnalyticsManager.applyAnalytics({
        eventName: EVENTS.profileNoteEditVisibilityOptionPressed,
        params: {
          visibility: ACCESS.PRIVATE,
        },
      });
    }
  };

  const handleMakeNotePublic = () => {
    setVisibilityContent(ACCESS.PUBLIC);
    setIsMenuOpen(false);

    if (mode && !updateMode) {
      AnalyticsManager.applyAnalytics({
        eventName: EVENTS.profileNoteNewVisibilityOptionPressed,
        params: {
          visibility: ACCESS.PUBLIC,
        },
      });
    } else {
      AnalyticsManager.applyAnalytics({
        eventName: EVENTS.profileNoteEditVisibilityOptionPressed,
        params: {
          visibility: ACCESS.PUBLIC,
        },
      });
    }
  };

  const [visibilityColor, setVisibilityColor] = useState(
    visibility === ACCESS.PRIVATE ? appTheme.paleBlue : appTheme.etherealMoodBlue,
  );
  const setVisibilityContent = (value) => {
    setVisibility(value);
    if (value === ACCESS.PRIVATE) {
      setVisibilityColor(appTheme.paleBlue);
    } else {
      setVisibilityColor(appTheme.etherealMoodBlue);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <>
        <ClickAwayListener onClickAway={clickedOutside}>
          <OptionHolder data-testid={`${dataid}-menubtn`}>
            <Manager>
              <Reference>
                {({ref}) => (
                  <MenuButtonVisibility ref={ref} backgroundColor={visibilityColor} onClick={!disabled && onClickMenu}>
                    {visibility === ACCESS.PRIVATE ? (
                      <Visibility backgroundColor={visibilityColor}>
                        <LockIconWrapper>
                          <LockIcon color={'#000'} width={'10px'} height={'14px'} />
                        </LockIconWrapper>
                        <VisibilityData>
                          <VisibilityStatus>{VISIBILITY.PRIVATE}</VisibilityStatus>: Note is visible to only me and
                          other Admins
                          <ChevronDownIcon>
                            <ArrowDown />
                          </ChevronDownIcon>
                        </VisibilityData>
                      </Visibility>
                    ) : (
                      <Visibility backgroundColor={visibilityColor}>
                        <LockIconWrapper>
                          <EyeIcon color={'#000'} width={'10px'} height={'14px'} />
                        </LockIconWrapper>
                        <VisibilityData>
                          <VisibilityStatus>{VISIBILITY.PUBLIC}</VisibilityStatus>: Note is visible to Everyone at{' '}
                          {store.getState().organization.name}
                          <ChevronDownIcon>
                            <ArrowDown />
                          </ChevronDownIcon>
                        </VisibilityData>
                      </Visibility>
                    )}
                  </MenuButtonVisibility>
                )}
              </Reference>
              <Popper>
                {({ref, style, placement}) =>
                  isMenuOpen && (
                    <OptionsBoxNotes ref={ref} style={style} data-placement={placement}>
                      <OptionNotes
                        onClick={handleMakeNotePrivate}
                        data-testid={`${dataid}-editbtn`}
                        backgroundColor={visibility === ACCESS.PRIVATE ? appTheme.paleBlue : ''}
                      >
                        <LockIconWrapper>
                          <LockIcon color={'#000'} width={'10px'} height={'14px'} />
                        </LockIconWrapper>
                        <VisibilityStatus>{VISIBILITY.PRIVATE}</VisibilityStatus>: Note is visible to only me and other
                        Admins
                      </OptionNotes>
                      <OptionNotes
                        onClick={handleMakeNotePublic}
                        backgroundColor={visibility === ACCESS.PUBLIC ? appTheme.etherealMoodBlue : ''}
                      >
                        <LockIconWrapper>
                          <EyeIcon color={'#000'} width={'10px'} height={'14px'} />
                        </LockIconWrapper>
                        <VisibilityStatus>{VISIBILITY.PUBLIC}</VisibilityStatus>: Note is visible to Everyone at{' '}
                        {store.getState().organization.name}
                      </OptionNotes>
                    </OptionsBoxNotes>
                  )
                }
              </Popper>
            </Manager>
          </OptionHolder>
        </ClickAwayListener>
      </>
    </ThemeProvider>
  );
};

export default NoteVisibilityWrapper;
